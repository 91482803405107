import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Customer } from '../../../../../../../models/customers';
import { checkAvailability } from '../../../../../../../services/public/register';
import { PHONE_CODE, YES_NO_OPTIONS } from '../../../../../../../consts/common';
import { getEmailSentDate, getSmsSentDate } from '../../../../../../../helpers/accessor';
import { getPhoneAndCode } from '../../../../../../../helpers/phone';
import { useState } from 'react';
import { getSelectCountryOptions } from '../../../../../../../helpers/select';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  customer?: Customer;
}

export function CustomerForm(props: Props) {
  const { customer, onSubmit, onCancel } = props;
  const isCustomerExist = typeof customer !== 'undefined';
  const isExistCustomerPhone = isCustomerExist && typeof customer.phone !== 'undefined';
  const isExistCustomerEmail = isCustomerExist && typeof customer.email !== 'undefined';
  const phoneObj = getPhoneAndCode(customer);

  const [phoneCode, setPhoneCode] = useState(isExistCustomerPhone ? phoneObj.code : PHONE_CODE.UK); // only needed for checkAvailability phone

  const CustomerSchema = isCustomerExist
    ? Yup.object().shape({
        email: Yup.string()
          .required('Required')
          .email('Must be valid email')
          .test('email', 'Duplicate email', value => {
            const currentEmail = isExistCustomerEmail ? customer.email : '';
            if (currentEmail !== value) {
              return checkAvailability({ email: value }).then(res => {
                return res.isAvailable;
              });
            } else {
              return true;
            }
          }),
        phone: Yup.string()
          .required('Required')
          .test('phone', 'Duplicate phone', value => {
            const phoneForCheck = `${phoneCode}${value}`;
            const currentPhone = isExistCustomerPhone ? customer.phone : '';
            if (currentPhone !== phoneForCheck) {
              return checkAvailability({ phone: phoneForCheck }).then(res => {
                return res.isAvailable;
              });
            } else {
              return true;
            }
          }),
      })
    : Yup.object().shape({
        email: Yup.string()
          .required('Required')
          .email('Must be valid email')
          .test('email', 'Duplicate email', value =>
            checkAvailability({ email: value }).then(res => {
              return res.isAvailable;
            })
          ),
        password: Yup.string().required('Required'),
        phone: Yup.string()
          .required('Required')
          .test('phone', 'Duplicate phone', value => {
            const phoneForCheck = `${phoneCode}${value}`;
            return checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            });
          }),
      });

  const customerForm = {
    email: isCustomerExist ? customer.email : '',
    password: '',
    firstName: isCustomerExist ? customer.firstName : '',
    lastName: isCustomerExist ? customer.lastName : '',
    phoneCode: phoneCode,
    phone: propz.get(phoneObj, ['phone'], ''),
  };

  const smsTokens = propz.get(customer, ['verification', 'tokens', 'sms'], '');
  const emailTokens = propz.get(customer, ['verification', 'tokens', 'email'], '');
  const smsSentAt = propz.get(customer, ['verification', 'tokens', 'smsSentAt']);
  const emailSentAt = propz.get(customer, ['verification', 'tokens', 'emailSentAt']);
  const isExistSmsTokens = typeof smsSentAt !== 'undefined';
  const isExistEmailTokens = typeof emailSentAt !== 'undefined';
  const smsTokenVerified =
    propz.get(customer, ['verification', 'status', 'sms']) === true ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;
  const emailTokenVerified =
    propz.get(customer, ['verification', 'status', 'email']) === true ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;

  const smsSentDate = getSmsSentDate(customer);
  const emailSentDate = getEmailSentDate(customer);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={customerForm}
            validationSchema={CustomerSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={values => {
              const { password, ...rest } = values;
              if (password === '') {
                onSubmit(rest);
              } else {
                onSubmit(values);
              }
            }}
          >
            {({ touched, errors, values, handleSubmit, setFieldValue }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>First Name</label>
                    <Field name="firstName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
                  </div>

                  <div className="form-group">
                    <label>Last Name</label>
                    <Field name="lastName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
                  </div>

                  <div className="form-group">
                    <label>Phone code</label>
                    <Field
                      name="phoneCode"
                      component="select"
                      className=""
                      onChange={event => {
                        setFieldValue('phoneCode', event.target.value);
                        setPhoneCode(event.target.value);
                      }}
                    >
                      {getSelectCountryOptions()}
                    </Field>
                    <div className="">
                      <Field name="phone" className="form-control" placeholder="Phone" />
                      <ErrorMessage component="div" className="alert alert-danger mRegistrationError" name="phone" />
                    </div>
                  </div>
                  {isCustomerExist && (
                    <>
                      {isExistSmsTokens ? (
                        <div className="form-group">
                          <label className="pr-2">Token: </label>
                          <div className="pr-4 d-inline-block">{smsTokens}</div>
                          <label className="pr-2">Sent: </label>
                          <div className="pr-4 d-inline-block">{smsSentDate}</div>
                          <label className="pr-2">Verified: </label>
                          <div className="pr-2 d-inline-block">{smsTokenVerified}</div>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className="pr-2">Token: </label>
                          <div className="pr-2 d-inline-block">NOT SENT</div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                  </div>

                  {isCustomerExist && (
                    <>
                      {isExistEmailTokens ? (
                        <div className="form-group">
                          <label className="pr-2">Token: </label>
                          <div className="pr-4 d-inline-block">{emailTokens}</div>
                          <label className="pr-2">Sent: </label>
                          <div className="pr-4 d-inline-block">{emailSentDate}</div>
                          <label className="pr-2">Verified: </label>
                          <div className="pr-2 d-inline-block">{emailTokenVerified}</div>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className="pr-2">Token: </label>
                          <div className="pr-2 d-inline-block">NOT SENT</div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="form-group">
                    <label>Password</label>
                    <Field name="password" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="password" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
