import * as Lazy from 'lazy.js';
import * as Moment from 'moment';
import { getImagePriceSetProductsPublic } from '../services/public/images';

export function isImageIndividual(photoTypeName) {
  return photoTypeName === 'Individual';
}

export function isImageGroup(photoTypeName) {
  return photoTypeName === 'Group';
}

export function isImagePanoramic(photoTypeName) {
  return photoTypeName === 'Panoramic';
}

export function getUniqueJobDate(imageTickets) {
  return Lazy(imageTickets)
    .map(ticket => Moment(ticket.jobDate).format('DD-MM-YYYY'))
    .uniq()
    .toArray();
}

export function sortImagesByPhotoType(imageTickets) {
  const panoramicImages = imageTickets.filter(({ photoTypeName }) => isImagePanoramic(photoTypeName));
  const groupImages = imageTickets.filter(({ photoTypeName }) => isImageGroup(photoTypeName));
  const individualImages = imageTickets.filter(({ photoTypeName }) => isImageIndividual(photoTypeName));
  const otherImages = imageTickets.filter(
    ({ photoTypeName }) =>
      !isImagePanoramic(photoTypeName) && !isImageGroup(photoTypeName) && !isImageIndividual(photoTypeName)
  );
  return [...panoramicImages, ...groupImages, ...individualImages, ...otherImages];
}

export function getImageProductTypes(imageId: string) {
  return getImagePriceSetProductsPublic(imageId).then(res => {
    const { products, schoolId, schoolName } = res;
    return Lazy(products)
      .map(product => ({
        name: product.productTypeName,
        id: product.productTypeId,
        schoolId,
        schoolName,
      }))
      .uniq('id')
      .toArray();
  });
}
