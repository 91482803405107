import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { History } from 'history';
import { FONT_COLOR } from '../../../../consts/fontColor';
import { ProductType } from '../../../../models/productTypes';
import { AppOneTimeCode } from '../../AppOrders';
import { PromoText } from './PromoText/PromoText';

interface Props {
  history: History;
  isAuthorizedCustomer: boolean;
  isAuthorizedOneTimeCode: boolean;
  selectedTicketIds: any[];
  oneTimeCode: AppOneTimeCode;
  productTypes: ProductType[];
}

export function TopMenu(props: Props) {
  const { isAuthorizedCustomer, isAuthorizedOneTimeCode, oneTimeCode, productTypes } = props;
  const { basketId } = oneTimeCode;
  const [isActiveMyBentleyDropdownMenu, setIsActiveMyBentleyDropdownMenu] = useState(false);

  const closeMyBentleyMenu = (): void => {
    setIsActiveMyBentleyDropdownMenu(false);
    document.removeEventListener('click', closeMyBentleyMenu);
  };

  const onMyBentleyClick = (): void => {
    if (!isActiveMyBentleyDropdownMenu) {
      document.addEventListener('click', closeMyBentleyMenu);
    }
    setIsActiveMyBentleyDropdownMenu(!isActiveMyBentleyDropdownMenu);
  };

  // the products menu item is disabled. Commented out in case you need it again

  // const [isActiveProductsDropdownMenu, setIsActiveProductsDropdownMenu] = useState(false);

  // const closeProductsMenu = (): void => {
  //   setIsActiveProductsDropdownMenu(false);
  //   document.removeEventListener('click', closeProductsMenu);
  // };

  // const onProductsClick = (): void => {
  //   if (!isActiveProductsDropdownMenu) {
  //     document.addEventListener('click', closeProductsMenu);
  //   }
  //   setIsActiveProductsDropdownMenu(!isActiveProductsDropdownMenu);
  // };

  return (
    <div className="bSubMenuWrapper">
      <PromoText />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="bSubMenu">
              <div className="dropdown">
                {/* <div
                  className={'nav-link eItemSubMenu'}
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  onClick={onProductsClick}
                >
                  Products
                </div> */}
                {/* <div className={`${isActiveProductsDropdownMenu ? 'dropdown-menu show' : 'dropdown-menu'}`}>
                  <NavLink
                    key={'top_menu_products_all'}
                    activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                    className={'dropdown-item'}
                    to={{ pathname: '/products' }}
                  >
                    All
                  </NavLink>

                  {productTypes.map((productType, index) => {
                    return (
                      <NavLink
                        key={`top_menu_products_${index}`}
                        activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                        className={'dropdown-item'}
                        to={{ pathname: `/products`, search: `?productType=${productType.id}` }}
                      >
                        {productType.name}
                      </NavLink>
                    );
                  })}
                </div> */}
              </div>

              <NavLink
                activeStyle={{ textDecoration: 'underline' }}
                className={'nav-link eItemSubMenu'}
                to={{ pathname: '/accessCode' }}
              >
                New Access Code
              </NavLink>
              <div className="dropdown">
                <div
                  className={'nav-link eItemSubMenu'}
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  onClick={onMyBentleyClick}
                >
                  My Bentley
                </div>
                <div className={`${isActiveMyBentleyDropdownMenu ? 'dropdown-menu show' : 'dropdown-menu'}`}>
                  <NavLink
                    activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                    className={
                      isAuthorizedCustomer || isAuthorizedOneTimeCode ? 'dropdown-item disabled' : 'dropdown-item'
                    }
                    to={{ pathname: '/login' }}
                  >
                    Log in
                  </NavLink>

                  {isAuthorizedCustomer && (
                    <NavLink
                      activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                      className="dropdown-item"
                      to={{ pathname: '/customer' }}
                    >
                      Preview
                    </NavLink>
                  )}

                  {isAuthorizedOneTimeCode && (
                    <NavLink
                      activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                      className="dropdown-item"
                      to={{ pathname: '/oneTimeCode' }}
                    >
                      Preview
                    </NavLink>
                  )}

                  {!isAuthorizedCustomer && !isAuthorizedOneTimeCode && (
                    <NavLink
                      activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                      className="dropdown-item disabled"
                      to={{ pathname: '/oneTimeCode' }}
                    >
                      Preview
                    </NavLink>
                  )}

                  {isAuthorizedCustomer && (
                    <NavLink
                      activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                      className="dropdown-item"
                      to={{ pathname: '/customer/photoGallery' }}
                    >
                      View Gallery
                    </NavLink>
                  )}

                  {isAuthorizedOneTimeCode && (
                    <NavLink
                      activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                      className={typeof basketId !== 'undefined' ? 'dropdown-item' : 'dropdown-item disabled'}
                      to={{ pathname: '/photoGallery' }}
                    >
                      View Gallery
                    </NavLink>
                  )}

                  {!isAuthorizedOneTimeCode && !isAuthorizedCustomer && (
                    <NavLink
                      activeStyle={{ background: 'white', color: FONT_COLOR.MAIN_COLOR }}
                      className="dropdown-item disabled"
                      to={{ pathname: '/photoGallery' }}
                    >
                      View Gallery
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
