import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customClass?: string;
  name: string;
}

export const Checkbox: FunctionComponent<Props> = props => {
  const { onChange, customClass, children, name } = props;
  return (
    <div className={customClass}>
      <input name={name} className="form-check-input" type="checkbox" onChange={onChange} />
      <label className="form-check-label">{children}</label>
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
