import * as React from 'react';
import { FunctionComponent } from 'react';
import { getIsNoAutoAcceptAvailable } from '../../../../../../../helpers/accessor';

interface Props {
  schoolStudents: any[];
}

export const SchoolStudentSummary: FunctionComponent<Props> = props => {
  const { schoolStudents } = props;

  const rows = schoolStudents.map((schoolStudent, index) => {
    const isNoAutoAcceptAvailable = getIsNoAutoAcceptAvailable(schoolStudent);
    return (
      <tr key={`school_students_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{schoolStudent.forename}</td>
        <td>{schoolStudent.surname}</td>
        <td>{schoolStudent.form.formName}</td>
        <td>{schoolStudent.school.schoolName}</td>
        <td>{schoolStudent.adminNo}</td>
        <td>{isNoAutoAcceptAvailable}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No students</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th />
          <th>Name</th>
          <th>Surname</th>
          <th>Form</th>
          <th>School</th>
          <th>AdminNo</th>
          <th>No auto accept</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

SchoolStudentSummary.displayName = 'SchoolStudentSummary';
