import * as React from 'react';

export function InfoBlockDelivery() {
  return (
    <div className="pt-5">
      <div className="bFAQsSubTitle">delivery information</div>
      <div className="bInfoBlockText">
        When your order is received, we will aim dispatch it within 7-10 days. Please allow 28 days at busy periods.
      </div>
    </div>
  );
}
