import * as React from 'react';
import * as classNames from 'classnames';
import { FunctionComponent } from 'react';

interface Props {
  onClick: (item?) => void;
  text: string | string[] | (string | React.ReactElement<any>)[];
  customClass?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

export const Button: FunctionComponent<Props> = props => {
  const { customClass, disabled, text, onClick, type } = props;
  const buttonClass = classNames('btn btn-primary', customClass);

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick} type={type}>
      {text}
    </button>
  );
};

Button.displayName = 'Button';

Button.defaultProps = {
  disabled: false,
  type: 'button',
};
