import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Package } from 'Src/models/packages';
import { Component } from 'react';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getName, searchFunctionProducts } from 'Src/helpers/autocomplete';
import { AppUser } from '../../../../../App';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  packageItem?: Package;
  user: AppUser;
}

interface State {
  products: any[];
}

const PackageSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export class PackageForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    const isPackageExist = typeof props.packageItem !== 'undefined';

    this.state = {
      products: isPackageExist
        ? props.packageItem.products.map(product => ({
            name: product.name,
            id: product.productId,
          }))
        : [],
    };
  }

  renderProducts(products, setFieldValue) {
    return products.map(product => {
      return (
        <div key={product.id} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={product}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const productsFiltered = products.filter(
                  p => p.id !== product.id
                );
                setFieldValue('products', productsFiltered);
                this.setState({
                  products: productsFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  getProducts = (text: string) => {
    const { products } = this.state;
    const { user } = this.props;

    return searchFunctionProducts(user, text, products);
  };

  render() {
    const { packageItem, onSubmit, onCancel } = this.props;
    const isPackageExist = typeof packageItem !== 'undefined';

    const packageForm = {
      name: isPackageExist ? packageItem.name : '',
      description: isPackageExist ? packageItem.description : '',
      pic: isPackageExist ? packageItem.pic || '' : '',
      products: isPackageExist
        ? packageItem.products.map(product => ({
            name: product.name,
            id: product.productId,
          }))
        : [],
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={packageForm}
              validationSchema={PackageSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, handleSubmit, values, setFieldValue }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Package Name</label>
                      <Field name="name" className="form-control mb-3" />
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger"
                        name="name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Package Description</label>
                      <Field name="description" className="form-control mb-3" />
                    </div>
                    <div className="form-group">
                      {typeof values.products !== 'undefined' &&
                        values.products.length > 0 && (
                          <label>Products in package</label>
                        )}
                      {this.renderProducts(values.products, setFieldValue)}

                      <div>Add products to package</div>
                      <div>
                        Notes: Start entering the product name to find a product
                        for adding to the package.
                      </div>
                      <Field name="products">
                        {() => (
                          <Autocomplete
                            searchFunction={this.getProducts}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={undefined}
                            onSelect={product => {
                              const nextProducts = [].concat(
                                values.products,
                                product
                              );
                              setFieldValue('products', nextProducts);
                              this.setState({
                                products: nextProducts,
                              });
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    {/*<div className="form-group">*/}
                    {/*  <label>Picture</label>*/}
                    {/*  <Field name="pic">*/}
                    {/*    {() => (*/}
                    {/*      <input*/}
                    {/*        type="file"*/}
                    {/*        className="form-control-file"*/}
                    {/*        onChange={event => {*/}
                    {/*          const { user } = this.props;*/}
                    {/*          const file = event.target.files[0];*/}
                    {/*          const formData = new FormData();*/}
                    {/*          formData.append('file', file);*/}

                    {/*          //const fileName = event.target.files[0].name;*/}

                    {/*          uploadFile(user, formData).then(data => {*/}
                    {/*            const key = data.key;*/}
                    {/*            setFieldValue('pic', key);*/}
                    {/*          });*/}
                    {/*        }}*/}
                    {/*      />*/}
                    {/*    )}*/}
                    {/*  </Field>*/}
                    {/*</div>*/}

                    <Button
                      onClick={onCancel}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
