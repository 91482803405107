import * as React from 'react';
import {
  ColumnDefinition,
  convertToFilterWithPlus,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from '../../../helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from '../../../consts/table';
import {
  getJobDate,
  getPhotoTypeName,
  getSchoolJobStatus,
  getUploadDate,
  getUploadUser,
} from '../../../helpers/accessor';
import { SchoolJob } from '../../../models/schoolJob';
import { PhotoType } from '../../../models/photoTypes';
import { AppSchoolsUser2 } from '../AppSchools2';
import { Component } from 'react';
import { getSchoolUserAllPhotoTypes } from '../../../services/schoolUser/photoTypes';
import { parse } from 'query-string';
import { History, Location } from 'history';
import * as propz from 'propz';
import {
  getSchoolUserAllSchoolJobs,
  getSchoolUserSchoolJobs,
  getSchoolUserSchoolJobsCount,
} from '../../../services/schoolUser/schoolJobs';
import { SCHOOL_JOB_STATUS, SCHOOL_JOB_TABS } from '../../../consts/schoolJob';
import { getSelectOptionForPhotoTypes } from '../../../helpers/select';
import { Grid } from '../../../components/Grid/Grid';
import { TABS } from '../../../consts/common';
import { GenericSummary } from '../../../components/GenericSummary/GenericSummary';
import { Button } from '../../../components/Button/Button';
import { Loader } from '../../../components/Loader/Loader';
import { ResizablePanel } from '../../../components/ResizablePanel/ResizablePanel';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { approveSchoolJob, unApproveSchoolJob } from '../../../services/schoolUser/schoolJobs';
import '../appSchools2.css';

interface State {
  items: SchoolJob[];
  currentPage: number;
  selectedItems: SchoolJob[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  photoTypes: PhotoType[];
  currentTabIndex: number;
  images: any[];
  isInfoModalOpen: boolean;
  infoMessage: string;
}

interface Props {
  user: AppSchoolsUser2;
  history: History;
  location: Location;
}

export class SchoolAppSchoolJobs2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      photoTypes: [],
      currentTabIndex: 0,
      images: [],
      isInfoModalOpen: false,
      infoMessage: '',
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.setAdditionalItems().then(() => {
      this.setItems();
    });
  }

  setAdditionalItems = () => {
    const { user } = this.props;
    const promises = [getSchoolUserAllPhotoTypes(user)];

    return Promise.all(promises).then(([photoTypes]) => {
      this.setState({ photoTypes });
      return true;
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({ isLoading: true });
      this.setItems();
    }
  }

  setItems = () => {
    const { history, user, location } = this.props;
    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(this.getColumns(), search);
    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);
    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getSchoolUserSchoolJobs(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolUserSchoolJobsCount(user, where);

    return Promise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      const jobId = propz.get(location, ['state', 'jobId']);
      if (typeof jobId !== 'undefined') {
        const job = items.find(item => item.id === jobId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName,
          isShowFilter,
          isDataFiltered,
          filters,
          items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof job !== 'undefined' ? [job] : [],
        });

        const state = { ...(history.location.state as any) };
        delete state.jobId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName,
          isShowFilter,
          isDataFiltered,
          filters,
          items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
        });
      }

      return true;
    });
  };

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];
    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);
    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({ selectedItems: selectedItemsUpdated });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({ isLoading: true });

    const user = this.props.user;
    const where = getServerFieldSectionWhere(filters);

    getSchoolUserAllSchoolJobs(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);
    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = this.getColumns().find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({ filters: nextFilters });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);
    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();
    this.setState({ isShowFilter: !this.state.isShowFilter });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();
    this.setState({ currentTabIndex: index });
  };

  onApproveJob = (item: SchoolJob) => {
    const { user } = this.props;
    approveSchoolJob(user, item.id)
      .then(() => {
        this.showSendingInfoModal('Job approved successfully.');
        this.setItems();
      })
      .catch(error => {
        console.error('Error approving job:', error);
        this.showSendingInfoModal('Error approving job.');
      });
  };

  onUnApproveJob = (item: SchoolJob) => {
    const { user } = this.props;
    unApproveSchoolJob(user, item.id)
      .then(() => {
        this.showSendingInfoModal('Job approval undone successfully.');
        this.setItems();
      })
      .catch(error => {
        console.error('Error undoing job approval:', error);
        this.showSendingInfoModal('Error undoing job approval.');
      });
  };

  getColumns = (): ColumnDefinition[] => [
    {
      text: 'School',
      field: 'nameOfSchool',
      isSort: true,
      type: FILTER_TYPE.TEXT,
      isShort: true,
      accessor: ['school', 'name'],
    },
    {
      text: 'Job Reference',
      field: 'jobReference',
      isSort: true,
      type: FILTER_TYPE.TEXT,
      accessor: ['jobReference'],
    },
    {
      text: 'Photo type',
      field: 'photoType',
      isSort: true,
      type: FILTER_TYPE.SELECT,
      accessor: getPhotoTypeName,
    },
    {
      text: 'Job date',
      field: 'jobDate',
      isSort: true,
      type: FILTER_TYPE.DATE_INTERVAL,
      accessor: getJobDate,
    },
    {
      text: 'Upload date',
      field: 'uploadDate',
      isSort: true,
      type: FILTER_TYPE.NONE,
      accessor: getUploadDate,
    },
    {
      text: 'Status',
      field: 'status',
      isSort: true,
      type: FILTER_TYPE.NONE,
      accessor: getSchoolJobStatus,
    },
    {
      text: 'Actions',
      field: 'actions',
      isSort: false,
      cell: (item: SchoolJob): JSX.Element => (
        <td className="custom-action-cell">
          <button
            onClick={() => this.onApproveJob(item)}
            className="custom-action-button"
            disabled={item.status !== 'NEW'}
          >
            <img src="/dist/images/icon/approve.png" alt="Approve" className="custom-edit-icon" />
            Approve
          </button>
          <button
            onClick={() => this.onUnApproveJob(item)}
            className="custom-action-button"
            disabled={item.status !== 'APPROVED_BY_SCHOOL'}
          >
            <img src="/dist/images/icon/unapprove.png" alt="Unapprove" className="custom-edit-icon" />
            Unapprove
          </button>
        </td>
      ),
    },
  ];

  renderGrid = () => {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      photoTypes,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
    } = this.state;

    const filterOptions = {
      photoType: getSelectOptionForPhotoTypes(photoTypes),
    };

    return (
      <div>
        <div className="page-header">
          <img src="/dist/images/icon/photo.png" alt="Images for Checking" className="header-icon" />
          <h1>Images for Checking</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Grid
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={this.getColumns()}
              actionItems={[]}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              rowClassName="custom-table-row"
              disableCsvButton={true}
            />
          </div>
        </div>
      </div>
    );
  };

  renderSummary = (): React.ReactNode => {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a job above</div>;
    }

    const tabs = this.getTabs();
    const currentTab = SCHOOL_JOB_TABS[currentTabIndex];
    const isShowImages = currentTab === TABS.SUMMARY;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={SCHOOL_JOB_TABS} currentTabIndex={currentTabIndex}>
        {isShowImages && (
          <button onClick={this.onShowImagesButtonClick} className="custom-button mt-3 mr-3 mb-3">
            <img src="/dist/images/icon/picture.png" alt="Show Images" className="custom-edit-icon" />
            Show images
          </button>
        )}
        {tabs}
      </GenericSummary>
    );
  };

  onShowImagesButtonClick = () => {
    const { selectedItems } = this.state;
    const job = selectedItems[0];
    const { id } = job;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/images`,
      search: `job=${id}`,
      state: { search, jobId: id },
    });
  };

  showSendingInfoModal = (text: string, type: 'success' | 'error' = 'success') => {
    this.setState({
      infoMessage: text,
      isInfoModalOpen: true,
    });
  };

  hideSendingInfoClick = () => {
    this.setState({ isInfoModalOpen: false, infoMessage: '', selectedItems: [] }, () => {
      this.setItems();
    });
  };

  renderSendingInfo = (): React.ReactNode => {
    const { infoMessage, isInfoModalOpen } = this.state;
    const modalTitle = infoMessage.includes('Error') ? 'Error' : 'Confirmation';

    return (
      <SimpleModal isOpen={isInfoModalOpen} customClass="custom-user-modal">
        <div className="custom-modal-header">
          <h5>{modalTitle}</h5>
          <button className="close" onClick={this.hideSendingInfoClick}>
            &times;
          </button>
        </div>

        <div className="custom-modal-body">{infoMessage}</div>

        <div className="custom-button-container" style={{ marginTop: '20px' }}>
          <button onClick={this.hideSendingInfoClick} className="custom-button">
            <img src="/dist/images/icon/cancel.png" alt="Cancel" className="custom-edit-icon" />
            Close
          </button>
        </div>
      </SimpleModal>
    );
  };

  getTabs = (): React.ReactNode => {
    const { currentTabIndex } = this.state;
    const tabs = SCHOOL_JOB_TABS;
    const currentTab = tabs[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <div />;
      default:
        return <div />;
    }
  };

  render() {
    const { isLoading, isInfoModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isInfoModalOpen ? 'mt-3' : 'mt-3';

    return (
      <div className="schoolAppContentWrapper2">
        {this.renderSendingInfo()}
        <div className="resizable-panel">
          <ResizablePanel>
            {this.renderGrid()}
            {this.renderSummary()}
          </ResizablePanel>
        </div>
      </div>
    );
  }
}
