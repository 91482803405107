import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Orders } from './Orders';
import { OrderImages } from './OrderImages/OrderImages';
import { OrderNotes } from './OrderNotes/OrderNotes';

export const OrdersRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/orders'} render={props => <Orders {...props} user={allProps.user} />} />

      <Route exact path={'/orders/images'} render={props => <OrderImages {...props} user={allProps.user} />} />

      <Route exact path={'/orders/notes'} render={props => <OrderNotes {...props} user={allProps.user} />} />
    </Switch>
  );
};

OrdersRouting.displayName = 'OrdersRouting';
