import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { UnsubscribedEmail } from '../../../../../../../models/unsubscribedEmails';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  unsubscribedEmail?: UnsubscribedEmail;
}

const UnsubscribedEmailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Must be valid email'),
});

export function UnsubscribedEmailForm(props: Props) {
  const { unsubscribedEmail, onSubmit, onCancel } = props;
  const isUnsubscribedEmailExist = typeof unsubscribedEmail !== 'undefined';
  const unsubscribedEmailForm = {
    email: isUnsubscribedEmailExist ? unsubscribedEmail.email : '',
    notes: isUnsubscribedEmailExist ? unsubscribedEmail.notes : '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={unsubscribedEmailForm} validationSchema={UnsubscribedEmailSchema} onSubmit={onSubmit}>
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                  </div>

                  <div className="form-group">
                    <label>Notes</label>
                    <Field component={'textarea'} name="notes" className="form-control mb-3" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
