import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import * as BPromise from 'bluebird';
import { History, Location } from 'history';
import { AppCustomer } from '../../../AppOrders';
import {
  createCustomerOrder,
  getCustomerBasket,
  setDeliveryCountryToCustomerBasket,
  setDeliveryOptionToCustomerBasket,
} from '../../../../../services/customer/basket';
import './CustomerDeliveryOptions.scss';
import {
  BASKET_DELIVERY_CHARGE_TYPE,
  DEFAULT_COUNTRY_OPTION_VALUE,
  DELIVERY_OPTION,
  EMPTY_DELIVERY_OPTION_TEXT,
  ORDER_DELIVERY_TYPE,
} from '../../../../../consts/order';
import { PAYMENT_CHANNEL, BUYER_TYPE } from '../../../../../consts/payment';
import { HomeDeliveryForm } from '../../../Basket/DeliveryOptions/HomeDeliveryForm';
import { getConfig } from '../../../../../services/public/config';
import { checkCustomerSession, getCustomerProfile } from '../../../../../services/customer/customer';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../../consts/common';
import { PAY360_RESULT } from '../../../../../consts/payment';
import {
  setPrimaryCustomerBillingAddress,
  setPrimaryCustomerDeliveryAddress,
  updateCustomerAddressBook,
} from '../../../../../services/customer/addressBook';
import { Loader } from '../../../../../components/Loader/Loader';
import { Order } from '../../../../../models/orders';
import { Profile } from '../../../../../models/profile';
import { CustomerAddressBook } from './CustomerAddressBook';
import { getCallback, getCustomerCallbackC, getCustomerCallbackY } from '../../../../../helpers/worldpay';
import { PublicConfig } from '../../../../../models/config';
import { payment360 } from '../../../../../services/public/payment360';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';

interface Props {
  history: History;
  location: Location;
  customer: AppCustomer;
  onLogoutClick: () => void;
}

export interface Country {
  code: string;
  name: string;
}

interface State {
  //delivery
  firstNameDeliveryAddress: string;
  lastNameDeliveryAddress: string;
  streetAndNumberDeliveryAddress: string;
  townDeliveryAddress: string;
  regionDeliveryAddress: string;
  postCodeDeliveryAddress: string;
  phoneDeliveryAddress: string;
  emailDeliveryAddress: string;
  selectedCountryDeliveryAddress: string;

  //billing
  firstNameBillingAddress: string;
  lastNameBillingAddress: string;
  streetAndNumberBillingAddress: string;
  townBillingAddress: string;
  regionBillingAddress: string;
  postCodeBillingAddress: string;
  phoneBillingAddress: string;
  emailBillingAddress: string;
  selectedCountryBillingAddress: string;

  selectedDeliveryOption: string;
  orderAmount: number;
  deliveryOptions: any;

  order: Order;
  config: PublicConfig;
  profile: Profile;

  isAddressBookOpen: boolean;
  isLoading: boolean;
  isEditDeliveryAddressMode: boolean;
  isEditBillingAddressMode: boolean;
  isBillingAddressDifferentDeliveryAddress: boolean;

  items: any[];
  isDisablePaymentButton: boolean;

  iFrameSrc: string | undefined;
  sessionId: string | undefined;

  isPaymentUnavailable: boolean;
}

const countries: Country[] = require('Src/services/countries.json');

export class CustomerHomeDeliveryForm extends Component<Props, State> {
  formRef: any;
  iframeRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      firstNameDeliveryAddress: '',
      lastNameDeliveryAddress: '',
      streetAndNumberDeliveryAddress: '',
      townDeliveryAddress: '',
      regionDeliveryAddress: '',
      postCodeDeliveryAddress: '',
      selectedCountryDeliveryAddress: DEFAULT_COUNTRY_OPTION_VALUE,
      phoneDeliveryAddress: '',
      emailDeliveryAddress: '',

      firstNameBillingAddress: '',
      lastNameBillingAddress: '',
      streetAndNumberBillingAddress: '',
      townBillingAddress: '',
      regionBillingAddress: '',
      postCodeBillingAddress: '',
      selectedCountryBillingAddress: DEFAULT_COUNTRY_OPTION_VALUE,
      phoneBillingAddress: '',
      emailBillingAddress: '',

      orderAmount: 0.0,
      selectedDeliveryOption: '',
      deliveryOptions: undefined,
      order: undefined,
      config: undefined,
      profile: undefined,
      isAddressBookOpen: false,
      isLoading: true,
      isEditDeliveryAddressMode: false,
      isEditBillingAddressMode: false,
      isBillingAddressDifferentDeliveryAddress: false,
      items: [],
      isDisablePaymentButton: false,
      iFrameSrc: undefined,
      sessionId: undefined,
      isPaymentUnavailable: false,
    };

    this.formRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.payment);
  }

  payment = event => {
    const { data } = event;

    const isDataPaid = data === PAY360_RESULT.PAID;
    const isDataCanceled = data === PAY360_RESULT.CANCELED;
    const isDataTimeout = data === PAY360_RESULT.TIMEOUT;
    if (isDataPaid || isDataCanceled || isDataTimeout) {
      this.props.history.push(`/customer?status=${data}`);
    }
  };

  componentDidMount() {
    const { customer, onLogoutClick } = this.props;
    const { sessionKey } = customer;

    window.addEventListener('message', this.payment);

    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    let profile: Profile;
    let config: PublicConfig;

    this.setState({
      isLoading: true,
    });

    getCustomerProfile(customer)
      .then(_profile => {
        profile = _profile;
        return getConfig();
      })
      .then(_config => {
        config = _config;
        return getCustomerBasket(customer);
      })
      .then(basket => {
        const { items } = basket;
        const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

        return isAllProductsDigital
          ? setDeliveryOptionToCustomerBasket(customer, { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT })
          : BPromise.resolve(basket);
      })
      .then(basket => {
        const { orderAmount, deliveryOptions, deliveryOption, items } = basket;

        const { addressBook } = profile;
        const primaryDeliveryAddress = addressBook.find(address => address.isDeliveryPrimary);
        const isPrimaryDeliveryAddressExist = typeof primaryDeliveryAddress !== 'undefined';

        switch (true) {
          case isPrimaryDeliveryAddressExist: {
            const {
              address1,
              town,
              region,
              postcode,
              country,
              firstName,
              lastName,
              phone,
              email,
            } = primaryDeliveryAddress;
            this.setState({
              profile,
              //form data
              townDeliveryAddress: town,
              regionDeliveryAddress: region,
              firstNameDeliveryAddress: firstName,
              lastNameDeliveryAddress: lastName,
              phoneDeliveryAddress: phone,
              emailDeliveryAddress: email,
              streetAndNumberDeliveryAddress: address1,
              postCodeDeliveryAddress: postcode,
              //basket data
              deliveryOptions,
              items,
              selectedDeliveryOption: deliveryOption,
              selectedCountryDeliveryAddress: country,
              orderAmount: orderAmount.toFixed(2),

              isLoading: false,
              config,
            });
            break;
          }
          default: {
            const { firstName, lastName, phone, email } = profile;

            this.setState({
              profile,
              //form data
              firstNameDeliveryAddress: firstName,
              lastNameDeliveryAddress: lastName,
              phoneDeliveryAddress: phone,
              emailDeliveryAddress: email,
              //basket data
              deliveryOptions,
              items,
              selectedDeliveryOption: deliveryOption,
              selectedCountryDeliveryAddress: DEFAULT_COUNTRY_OPTION_VALUE,
              orderAmount: orderAmount.toFixed(2),

              isLoading: false,
              config,
            });
          }
        }
      });
  }

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { selectedDeliveryOption, profile, items } = this.state;

    this.setState({
      isLoading: true,
      isDisablePaymentButton: true,
    });

    const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

    let order;

    if (selectedDeliveryOption !== '' || isAllProductsDigital) {
      const { customer } = this.props;
      const {
        firstNameDeliveryAddress,
        lastNameDeliveryAddress,
        streetAndNumberDeliveryAddress,
        townDeliveryAddress,
        regionDeliveryAddress,
        postCodeDeliveryAddress,
        selectedCountryDeliveryAddress,
        phoneDeliveryAddress,
        emailDeliveryAddress,
        firstNameBillingAddress,
        lastNameBillingAddress,
        streetAndNumberBillingAddress,
        townBillingAddress,
        regionBillingAddress,
        postCodeBillingAddress,
        selectedCountryBillingAddress,
        phoneBillingAddress,
        emailBillingAddress,
        isBillingAddressDifferentDeliveryAddress,
      } = this.state;

      let data;

      if (isBillingAddressDifferentDeliveryAddress) {
        data = {
          deliveryType: ORDER_DELIVERY_TYPE.HOME,
          delivery: {
            firstName: firstNameDeliveryAddress,
            lastName: lastNameDeliveryAddress,
            address1: streetAndNumberDeliveryAddress,
            town: townDeliveryAddress,
            region: regionDeliveryAddress,
            postcode: postCodeDeliveryAddress,
            country: selectedCountryDeliveryAddress,
            phone: phoneDeliveryAddress,
            email: emailDeliveryAddress,
          },
          invoice: {
            firstName: firstNameBillingAddress,
            lastName: lastNameBillingAddress,
            address1: streetAndNumberBillingAddress,
            town: townBillingAddress,
            region: regionBillingAddress,
            postcode: postCodeBillingAddress,
            country: selectedCountryBillingAddress,
            phone: phoneBillingAddress,
            email: emailBillingAddress,
          },
        };
      } else {
        data = {
          deliveryType: ORDER_DELIVERY_TYPE.HOME,
          delivery: {
            firstName: firstNameDeliveryAddress,
            lastName: lastNameDeliveryAddress,
            address1: streetAndNumberDeliveryAddress,
            town: townDeliveryAddress,
            region: regionDeliveryAddress,
            postcode: postCodeDeliveryAddress,
            country: selectedCountryDeliveryAddress,
            phone: phoneDeliveryAddress,
            email: emailDeliveryAddress,
          },
          invoice: {
            firstName: firstNameDeliveryAddress,
            lastName: lastNameDeliveryAddress,
            address1: streetAndNumberDeliveryAddress,
            town: townDeliveryAddress,
            region: regionDeliveryAddress,
            postcode: postCodeDeliveryAddress,
            country: selectedCountryDeliveryAddress,
            phone: phoneDeliveryAddress,
            email: emailDeliveryAddress,
          },
        };
      }

      const { addressBook } = profile;
      const isAddressBookExist = Array.isArray(addressBook) && addressBook.length > 0;

      const setDeliveryOptionPromise = isAllProductsDigital
        ? setDeliveryOptionToCustomerBasket(customer, { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT })
        : BPromise.resolve();

      setDeliveryOptionPromise
        .then(res => {
          return createCustomerOrder(customer, data);
        })
        .then(_order => {
          order = _order;

          return isAddressBookExist
            ? BPromise.resolve(true)
            : updateCustomerAddressBook(customer, {
                address1: streetAndNumberDeliveryAddress,
                town: townDeliveryAddress,
                region: regionDeliveryAddress,
                postcode: postCodeDeliveryAddress,
                country: selectedCountryDeliveryAddress,
                isDeliveryPrimary: true,
                isBillingPrimary: !isBillingAddressDifferentDeliveryAddress,
                email: emailDeliveryAddress,
                phone: phoneDeliveryAddress,
                firstName: firstNameDeliveryAddress,
                lastName: lastNameDeliveryAddress,
              });
        })
        .then(profile => {
          return isAddressBookExist || !isBillingAddressDifferentDeliveryAddress
            ? BPromise.resolve(true)
            : updateCustomerAddressBook(customer, {
                address1: streetAndNumberBillingAddress,
                town: townBillingAddress,
                region: regionBillingAddress,
                postcode: postCodeBillingAddress,
                country: selectedCountryBillingAddress,
                isDeliveryPrimary: false,
                isBillingPrimary: true,
                email: emailBillingAddress,
                phone: phoneBillingAddress,
                firstName: firstNameBillingAddress,
                lastName: lastNameBillingAddress,
              });
        })
        .then(() => {
          const { config } = this.state;
          const { payments } = config;
          const { type } = payments;

          const channel = PAYMENT_CHANNEL.WEB;
          const buyerType = BUYER_TYPE.CUSTOMER;

          this.setState(
            {
              order: order,
            },
            () => {
              switch (type) {
                case 'worldPay': {
                  this.setState(
                    {
                      isLoading: false,
                    },
                    () => {
                      this.formRef.current.submit();
                    }
                  );
                  break;
                }

                case 'pay360Hosted': {
                  const { order } = this.state;
                  const { id } = order;
                  payment360(id, channel, buyerType).then(res => {
                    const { redirectUrl } = res;

                    if (redirectUrl) {
                      window.open(redirectUrl, '_self');
                    } else {
                      this.setState({
                        isLoading: false,
                        isPaymentUnavailable: true,
                      });
                    }
                  });
                  break;
                }

                case 'pay360Iframe': {
                  const { order } = this.state;
                  const { id } = order;

                  payment360(id, channel, buyerType).then(res => {
                    const { redirectUrl, sessionId } = res;

                    if (redirectUrl && sessionId) {
                      this.setState({ isLoading: false, iFrameSrc: redirectUrl, sessionId });
                    } else {
                      this.setState({
                        isLoading: false,
                        isPaymentUnavailable: true,
                      });
                    }
                  });
                  break;
                }
              }
            }
          );
        });
    }
  };

  onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ firstNameDeliveryAddress: value });
  };

  onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ lastNameDeliveryAddress: value });
  };

  onStreetAndNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ streetAndNumberDeliveryAddress: value });
  };

  onTownChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ townDeliveryAddress: value });
  };

  onRegionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ regionDeliveryAddress: value });
  };

  onPostCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ postCodeDeliveryAddress: value });
  };

  onCountryOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const country = event.target.value;
    const { customer } = this.props;
    const { selectedDeliveryOption } = this.state;
    const isDigitalProduct = selectedDeliveryOption === BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT;

    if (country === DEFAULT_COUNTRY_OPTION_VALUE && !isDigitalProduct) {
      setDeliveryOptionToCustomerBasket(customer, { deliveryOption: '' }).then(basket => {
        const { orderAmount } = basket;
        this.setState({
          selectedCountryDeliveryAddress: country,
          selectedDeliveryOption: '',
          orderAmount: orderAmount.toFixed(2),
        });
      });
    }

    if (country !== DEFAULT_COUNTRY_OPTION_VALUE && !isDigitalProduct) {
      setDeliveryOptionToCustomerBasket(customer, { deliveryOption: DELIVERY_OPTION.INTERNATIONAL.toUpperCase() }).then(
        basket => {
          const { orderAmount } = basket;
          this.setState({
            selectedCountryDeliveryAddress: country,
            selectedDeliveryOption: DELIVERY_OPTION.INTERNATIONAL,
            orderAmount: orderAmount.toFixed(2),
          });
        }
      );
    }

    if (isDigitalProduct) {
      this.setState({
        selectedCountryDeliveryAddress: country,
      });
    }

    setDeliveryCountryToCustomerBasket(customer, { deliveryCountry: country });
  };

  onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phoneDeliveryAddress: value });
  };

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ emailDeliveryAddress: value });
  };

  onBillingAddressFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ firstNameBillingAddress: value });
  };

  onBillingAddressLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ lastNameBillingAddress: value });
  };

  onBillingAddressStreetAndNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ streetAndNumberBillingAddress: value });
  };

  onBillingAddressTownChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ townBillingAddress: value });
  };

  onBillingAddressRegionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ regionBillingAddress: value });
  };

  onBillingAddressPostCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ postCodeBillingAddress: value });
  };

  onBillingAddressCountryOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const country = event.target.value;
    const { customer } = this.props;

    this.setState({
      selectedCountryBillingAddress: country,
    });
  };

  onBillingAddressPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phoneBillingAddress: value });
  };

  onBillingAddressEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ emailBillingAddress: value });
  };

  onDeliveryOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { customer } = this.props;
    const value = event.target.value;
    setDeliveryOptionToCustomerBasket(customer, { deliveryOption: value }).then(basket => {
      const { orderAmount } = basket;
      this.setState({
        orderAmount: orderAmount.toFixed(2),
        selectedDeliveryOption: value,
      });
    });
  };

  getDeliveryOptions = () => {
    let selectDeliveryOptions = [];
    const { selectedCountryDeliveryAddress, deliveryOptions } = this.state;

    switch (selectedCountryDeliveryAddress) {
      case DEFAULT_COUNTRY_OPTION_VALUE:
        selectDeliveryOptions.push(
          <option value="" key="emptyOption">
            {EMPTY_DELIVERY_OPTION_TEXT}
          </option>
        );

        let alphabeticallyDeliveryOptions = {};

        if (typeof deliveryOptions !== 'undefined') {
          //   to sort keys alphabetically
          Object.keys(deliveryOptions)
            .sort(function(a, b) {
              return deliveryOptions[a] - deliveryOptions[b];
            })
            .forEach(function(v) {
              alphabeticallyDeliveryOptions[v] = deliveryOptions[v];
            });
        }

        for (let deliveryOption in alphabeticallyDeliveryOptions) {
          if (deliveryOption === DELIVERY_OPTION.COURIER || deliveryOption === DELIVERY_OPTION.STANDARD) {
            selectDeliveryOptions.push(
              <option value={deliveryOption.toLocaleUpperCase()} key={deliveryOption}>
                {`${deliveryOption} (£${deliveryOptions[deliveryOption].toFixed(2)}`})
              </option>
            );
          }
        }
        break;
      default:
        selectDeliveryOptions.push(
          <option value="" key="emptyOption">
            {EMPTY_DELIVERY_OPTION_TEXT}
          </option>
        );
        selectDeliveryOptions.push(
          <option value={DELIVERY_OPTION.INTERNATIONAL.toLocaleUpperCase()} key={DELIVERY_OPTION.INTERNATIONAL}>
            {`${DELIVERY_OPTION.INTERNATIONAL} (£${deliveryOptions[DELIVERY_OPTION.INTERNATIONAL].toFixed(2)}`})
          </option>
        );
        break;
    }

    return selectDeliveryOptions;
  };

  selectCountryOptions = () => {
    return countries.map(country => (
      <option value={country.code} key={country.code}>
        {country.code} - {country.name}
      </option>
    ));
  };

  onAddressBookClick = event => {
    event.preventDefault();
    const { isEditBillingAddressMode, isEditDeliveryAddressMode } = this.state;

    if (!isEditBillingAddressMode && !isEditDeliveryAddressMode) {
      this.setState({
        isAddressBookOpen: true,
      });
    }
  };

  renderHiddenIframe() {
    const { iFrameSrc } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <iframe src={iFrameSrc} width="100%" style={{ border: 'none' }} height="1300px" ref={this.iframeRef} />
          </div>
        </div>
      </div>
    );
  }

  renderHiddenForm() {
    const { order, config } = this.state;
    const { customer } = this.props;
    const { customerId, basketId } = customer;
    const { payments } = config;
    const { worldpay } = payments;
    const { formActionUrl, testModeValue, instId, lang, accId1, currency } = worldpay;

    return (
      <form action={formActionUrl} method="POST" ref={this.formRef}>
        <input type="hidden" name="testMode" value={testModeValue} />
        <input type="hidden" name="instId" value={instId} />
        <input type="hidden" name="cartId" value={order.orderNumber} />
        <input type="hidden" name="amount" value={order.orderAmount} />
        <input type="hidden" name="currency" value={currency} />

        <input type="hidden" name="address1" value={order.invoice.address1} />
        <input type="hidden" name="town" value={order.invoice.town} />
        <input type="hidden" name="region" value={order.invoice.region} />
        <input type="hidden" name="postcode" value={order.invoice.postcode} />
        <input type="hidden" name="country" value={order.invoice.country} />
        <input type="hidden" name="authMode" value="A" />
        <input type="hidden" name="authValidFrom" value="" />
        <input type="hidden" name="authValidTo" value="" />
        <input type="hidden" name="name" value={`${order.invoice.firstName} ${order.invoice.lastName}`} />
        <input type="hidden" name="email" value={order.invoice.email} />
        <input type="hidden" name="tel" value={order.invoice.phone} />

        <input type="hidden" name="lang" value={lang} />
        <input type="hidden" name="hideCurrency" value="" />
        <input type="hidden" name="noLanguageMenu" value="" />
        <input type="hidden" name="accId1" value={accId1} />

        {/*custom field*/}
        <input type="hidden" name="MC_orderId" value={order.id} />
        <input type="hidden" name="MC_basketId" value={basketId} />
        <input type="hidden" name="MC_customerId" value={customerId} />
        <input type="hidden" name="MC_callbackY" value={getCustomerCallbackY()} />
        <input type="hidden" name="MC_callbackC" value={getCustomerCallbackC()} />
        <input type="hidden" name="MC_callback" value={getCallback()} />
        <input type="hidden" name="MC_mobileApp" value={'no'} />
      </form>
    );
  }

  onSelectDeliveryAddress = (index: number) => {
    const { profile } = this.state;
    const { addressBook } = profile;

    this.setState({
      firstNameDeliveryAddress: addressBook[index].firstName,
      lastNameDeliveryAddress: addressBook[index].lastName,
      emailDeliveryAddress: addressBook[index].email,
      phoneDeliveryAddress: addressBook[index].phone,
      townDeliveryAddress: addressBook[index].town,
      regionDeliveryAddress: addressBook[index].region,
      streetAndNumberDeliveryAddress: addressBook[index].address1,
      postCodeDeliveryAddress: addressBook[index].postcode,
      selectedCountryDeliveryAddress: addressBook[index].country,
      isAddressBookOpen: false,
    });
  };

  onClickAddNewDeliveryAddress = () => {
    this.setState({
      isEditDeliveryAddressMode: true,
      firstNameDeliveryAddress: '',
      lastNameDeliveryAddress: '',
      streetAndNumberDeliveryAddress: '',
      townDeliveryAddress: '',
      regionDeliveryAddress: '',
      postCodeDeliveryAddress: '',
      selectedCountryDeliveryAddress: DEFAULT_COUNTRY_OPTION_VALUE,
      phoneDeliveryAddress: '',
      emailDeliveryAddress: '',
    });
  };

  onClickAddNewBillingAddress = () => {
    this.setState({
      isEditBillingAddressMode: true,
      firstNameBillingAddress: '',
      lastNameBillingAddress: '',
      streetAndNumberBillingAddress: '',
      townBillingAddress: '',
      regionBillingAddress: '',
      postCodeBillingAddress: '',
      selectedCountryBillingAddress: DEFAULT_COUNTRY_OPTION_VALUE,
      phoneBillingAddress: '',
      emailBillingAddress: '',
    });
  };

  onClickSaveDeliveryAddress = () => {
    const { customer } = this.props;
    const {
      streetAndNumberDeliveryAddress,
      townDeliveryAddress,
      regionDeliveryAddress,
      postCodeDeliveryAddress,
      selectedCountryDeliveryAddress,
      emailDeliveryAddress,
      phoneDeliveryAddress,
      firstNameDeliveryAddress,
      lastNameDeliveryAddress,
    } = this.state;

    const data = {
      address1: streetAndNumberDeliveryAddress,
      town: townDeliveryAddress,
      region: regionDeliveryAddress,
      postcode: postCodeDeliveryAddress,
      country: selectedCountryDeliveryAddress,
      isDeliveryPrimary: false,
      email: emailDeliveryAddress,
      phone: phoneDeliveryAddress,
      firstName: firstNameDeliveryAddress,
      lastName: lastNameDeliveryAddress,
    };

    const isFormFilled =
      streetAndNumberDeliveryAddress !== '' &&
      townDeliveryAddress !== '' &&
      regionDeliveryAddress !== '' &&
      postCodeDeliveryAddress !== '' &&
      emailDeliveryAddress !== '' &&
      phoneDeliveryAddress !== '' &&
      firstNameDeliveryAddress !== '' &&
      lastNameDeliveryAddress !== '';

    if (isFormFilled) {
      this.setState({
        isLoading: true,
      });

      updateCustomerAddressBook(customer, data).then(profile => {
        this.setState({
          isEditDeliveryAddressMode: false,
          isLoading: false,
          profile,
        });
      });
    }
  };

  onClickSaveBillingAddress = () => {
    const { customer } = this.props;
    const {
      streetAndNumberBillingAddress,
      townBillingAddress,
      regionBillingAddress,
      postCodeBillingAddress,
      selectedCountryBillingAddress,
      emailBillingAddress,
      phoneBillingAddress,
      firstNameBillingAddress,
      lastNameBillingAddress,
    } = this.state;

    const data = {
      address1: streetAndNumberBillingAddress,
      town: townBillingAddress,
      region: regionBillingAddress,
      postcode: postCodeBillingAddress,
      country: selectedCountryBillingAddress,
      isBillingPrimary: false,
      email: emailBillingAddress,
      phone: phoneBillingAddress,
      firstName: firstNameBillingAddress,
      lastName: lastNameBillingAddress,
    };

    const isFormFilled =
      streetAndNumberBillingAddress !== '' &&
      townBillingAddress !== '' &&
      regionBillingAddress !== '' &&
      postCodeBillingAddress !== '' &&
      emailBillingAddress !== '' &&
      phoneBillingAddress !== '' &&
      firstNameBillingAddress !== '' &&
      lastNameBillingAddress !== '';

    if (isFormFilled) {
      this.setState({
        isLoading: true,
      });

      updateCustomerAddressBook(customer, data).then(profile => {
        this.setState({
          isEditBillingAddressMode: false,
          isLoading: false,
          profile,
        });
      });
    }
  };

  setPrimaryDeliveryAddress = addressId => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    setPrimaryCustomerDeliveryAddress(customer, addressId).then(profile => {
      this.setState({
        isLoading: false,
        profile,
      });
    });
  };

  setPrimaryBillingAddress = addressId => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    setPrimaryCustomerBillingAddress(customer, addressId).then(profile => {
      this.setState({
        isLoading: false,
        profile,
      });
    });
  };

  onSelectBillingAddress = (index: number) => {
    const { profile } = this.state;
    const { addressBook } = profile;

    this.setState({
      isAddressBookOpen: false,
      firstNameBillingAddress: addressBook[index].firstName,
      lastNameBillingAddress: addressBook[index].lastName,
      emailBillingAddress: addressBook[index].email,
      phoneBillingAddress: addressBook[index].phone,
      townBillingAddress: addressBook[index].town,
      regionBillingAddress: addressBook[index].region,
      streetAndNumberBillingAddress: addressBook[index].address1,
      postCodeBillingAddress: addressBook[index].postcode,
      selectedCountryBillingAddress: addressBook[index].country,
    });
  };

  onBillingAddressDifferentDeliveryAddressClick = () => {
    const { isBillingAddressDifferentDeliveryAddress, profile } = this.state;
    const { addressBook } = profile;
    const primaryBillingAddress = addressBook.find(address => address.isBillingPrimary);
    const isPrimaryBillingAddressExist = typeof primaryBillingAddress !== 'undefined';

    if (isPrimaryBillingAddressExist) {
      this.setState({
        isBillingAddressDifferentDeliveryAddress: !isBillingAddressDifferentDeliveryAddress,
        firstNameBillingAddress: primaryBillingAddress.firstName,
        lastNameBillingAddress: primaryBillingAddress.lastName,
        streetAndNumberBillingAddress: primaryBillingAddress.address1,
        townBillingAddress: primaryBillingAddress.town,
        regionBillingAddress: primaryBillingAddress.region,
        postCodeBillingAddress: primaryBillingAddress.postcode,
        selectedCountryBillingAddress: primaryBillingAddress.country,
        phoneBillingAddress: primaryBillingAddress.phone,
        emailBillingAddress: primaryBillingAddress.email,
      });
    } else {
      this.setState({
        isBillingAddressDifferentDeliveryAddress: !isBillingAddressDifferentDeliveryAddress,
      });
    }
  };

  onCloseErrorClick = () => {
    this.setState({
      isPaymentUnavailable: false,
      isDisablePaymentButton: false,
    });
  };

  renderError(): React.ReactNode {
    const { isPaymentUnavailable } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentUnavailable}
        title={'Error'}
        body={'Unfortunately, the payment service is currently unavailable. Please try again later'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  render() {
    const {
      firstNameDeliveryAddress,
      lastNameDeliveryAddress,
      streetAndNumberDeliveryAddress,
      townDeliveryAddress,
      regionDeliveryAddress,
      postCodeDeliveryAddress,
      selectedCountryDeliveryAddress,
      phoneDeliveryAddress,
      emailDeliveryAddress,
      firstNameBillingAddress,
      lastNameBillingAddress,
      streetAndNumberBillingAddress,
      townBillingAddress,
      regionBillingAddress,
      postCodeBillingAddress,
      selectedCountryBillingAddress,
      phoneBillingAddress,
      emailBillingAddress,
      orderAmount,
      selectedDeliveryOption,
      order,
      isLoading,
      profile,
      isAddressBookOpen,
      isEditDeliveryAddressMode,
      isEditBillingAddressMode,
      isBillingAddressDifferentDeliveryAddress,
      deliveryOptions,
      items,
      isDisablePaymentButton,
      iFrameSrc,
      config,
      isPaymentUnavailable,
    } = this.state;

    const type = propz.get(config, ['payments', 'type'], 'worldPay');

    if (isLoading) {
      return <Loader />;
    }

    const { addressBook } = profile;
    const isAddressBookExist = Array.isArray(addressBook) && addressBook.length > 0;
    const isDeliveryAddressReadOnly = !isEditDeliveryAddressMode && isAddressBookExist;
    const isBillingAddressReadOnly = !isEditBillingAddressMode && isAddressBookExist;
    const isInternationalDelivery = selectedDeliveryOption === DELIVERY_OPTION.INTERNATIONAL.toUpperCase();

    if (isAddressBookOpen) {
      return (
        <CustomerAddressBook
          setPrimaryDeliveryAddress={this.setPrimaryDeliveryAddress}
          setPrimaryBillingAddress={this.setPrimaryBillingAddress}
          onSelectDeliveryAddress={this.onSelectDeliveryAddress}
          onSelectBillingAddress={this.onSelectBillingAddress}
          addressBook={addressBook}
        />
      );
    }

    const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

    const classes = isPaymentUnavailable ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderError()}
        {typeof order !== 'undefined' && type === 'worldPay' && this.renderHiddenForm()}
        {typeof iFrameSrc !== 'undefined' && this.renderHiddenIframe()}
        {typeof iFrameSrc === 'undefined' && (
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="bHomeDeliveryForm">
                  <div className={'eHomeDeliveryFormTitle'}>HOME DELIVERY</div>
                  <div className="row justify-content-center">
                    <form onSubmit={this.onFormSubmit} className="">
                      <div className="eHomeDeliveryFormInputWrapper">
                        <div className="eHomeDeliveryFormContainer mPaddingTop20">
                          <div className="eHomeDeliveryFormText">Delivery Address</div>
                          {isAddressBookExist && (
                            <a onClick={this.onAddressBookClick} className="eHomeDeliveryFormLink">
                              Address book
                            </a>
                          )}
                        </div>
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="First Name"
                          value={firstNameDeliveryAddress}
                          onChange={this.onFirstNameChange}
                          key="homeDeliveryForm_firstName"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Last Name"
                          value={lastNameDeliveryAddress}
                          onChange={this.onLastNameChange}
                          key="homeDeliveryForm_lastName"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Street and number"
                          value={streetAndNumberDeliveryAddress}
                          onChange={this.onStreetAndNumberChange}
                          key="homeDeliveryForm_streetAndNumber"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Town"
                          value={townDeliveryAddress}
                          onChange={this.onTownChange}
                          key="homeDeliveryForm_town"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput mWidth240"
                          placeholder="County"
                          value={regionDeliveryAddress}
                          onChange={this.onRegionChange}
                          key="homeDeliveryForm_region"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput mWidth240 mMarginleft10"
                          placeholder="Post code"
                          value={postCodeDeliveryAddress}
                          onChange={this.onPostCodeChange}
                          key="homeDeliveryForm_postCode"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <select
                          id="selectIdCountry"
                          key="homeDeliveryForm_country"
                          className="eHomeDeliveryFormSelect mMarginTop15"
                          onChange={this.onCountryOptionSelect}
                          value={selectedCountryDeliveryAddress}
                          disabled={isDeliveryAddressReadOnly}
                        >
                          {this.selectCountryOptions()}
                        </select>
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Contact number"
                          value={phoneDeliveryAddress}
                          onChange={this.onPhoneChange}
                          key="homeDeliveryForm_phone"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />
                        <input
                          type="email"
                          className="eHomeDeliveryFormInput"
                          placeholder="Email"
                          value={emailDeliveryAddress}
                          onChange={this.onEmailChange}
                          key="homeDeliveryForm_email"
                          pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          required
                          disabled={isDeliveryAddressReadOnly}
                        />

                        <div className="eHomeDeliveryFormButtonWrapper">
                          {isEditDeliveryAddressMode && (
                            <button
                              className="eHomeDeliveryFormButton mWidth235"
                              onClick={this.onClickSaveDeliveryAddress}
                            >
                              Save
                            </button>
                          )}
                          {!isEditDeliveryAddressMode && isAddressBookExist && (
                            <button
                              className="eHomeDeliveryFormButton mWidth235"
                              onClick={this.onClickAddNewDeliveryAddress}
                            >
                              Add delivery address
                            </button>
                          )}
                        </div>

                        <div className="eHomeDeliveryFormContainer mPaddingTop20">
                          <div className="eHomeDeliveryFormText">Billing Address</div>
                        </div>

                        <div className="checkbox mb-3 eHomeCheckbox">
                          <input
                            type="checkbox"
                            checked={isBillingAddressDifferentDeliveryAddress}
                            onChange={this.onBillingAddressDifferentDeliveryAddressClick}
                            disabled={isEditBillingAddressMode}
                          />
                          {' Billing address different from above'}
                        </div>

                        {!isBillingAddressDifferentDeliveryAddress && !isEditDeliveryAddressMode && (
                          <div className={'eHomeDeliveryBillingInfoBlock'}>
                            <div>{firstNameDeliveryAddress}</div>
                            <div>{lastNameDeliveryAddress}</div>
                            <div>{phoneDeliveryAddress}</div>
                            <div>{streetAndNumberDeliveryAddress}</div>
                            <div>{townDeliveryAddress}</div>
                            <div>{regionDeliveryAddress}</div>
                            <div>{selectedCountryDeliveryAddress}</div>
                            <div>{postCodeDeliveryAddress}</div>
                          </div>
                        )}

                        {isBillingAddressDifferentDeliveryAddress && (
                          <>
                            <input
                              type="text"
                              className="eHomeBillingFormInput"
                              placeholder="First Name"
                              value={firstNameBillingAddress}
                              onChange={this.onBillingAddressFirstNameChange}
                              key="homeBillingForm_firstName"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <input
                              type="text"
                              className="eHomeBillingFormInput"
                              placeholder="Last Name"
                              value={lastNameBillingAddress}
                              onChange={this.onBillingAddressLastNameChange}
                              key="homeBillingForm_lastName"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <input
                              type="text"
                              className="eHomeBillingFormInput"
                              placeholder="Street and number"
                              value={streetAndNumberBillingAddress}
                              onChange={this.onBillingAddressStreetAndNumberChange}
                              key="homeBillingForm_streetAndNumber"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <input
                              type="text"
                              className="eHomeBillingFormInput"
                              placeholder="Town"
                              value={townBillingAddress}
                              onChange={this.onBillingAddressTownChange}
                              key="homeBillingForm_town"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <input
                              type="text"
                              className="eHomeBillingFormInput mWidth240"
                              placeholder="County"
                              value={regionBillingAddress}
                              onChange={this.onBillingAddressRegionChange}
                              key="homeBillingForm_region"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <input
                              type="text"
                              className="eHomeBillingFormInput mWidth240 mMarginleft10"
                              placeholder="Post code"
                              value={postCodeBillingAddress}
                              onChange={this.onBillingAddressPostCodeChange}
                              key="homeBillingForm_postCode"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <select
                              id="selectIdCountry"
                              key="homeBillingForm_country"
                              className="eHomeBillingFormSelect mMarginTop15"
                              onChange={this.onBillingAddressCountryOptionSelect}
                              value={selectedCountryBillingAddress}
                              disabled={isBillingAddressReadOnly}
                            >
                              {this.selectCountryOptions()}
                            </select>
                            <input
                              type="text"
                              className="eHomeBillingFormInput"
                              placeholder="Contact number"
                              value={phoneBillingAddress}
                              onChange={this.onBillingAddressPhoneChange}
                              key="homeBillingForm_phone"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                            <input
                              type="email"
                              className="eHomeBillingFormInput"
                              placeholder="Email"
                              value={emailBillingAddress}
                              onChange={this.onBillingAddressEmailChange}
                              key="homeBillingForm_email"
                              pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              required
                              disabled={isBillingAddressReadOnly}
                            />
                          </>
                        )}

                        <div className="eHomeDeliveryFormButtonWrapper">
                          {isEditBillingAddressMode && (
                            <>
                              <button
                                className="eHomeDeliveryFormButton mWidth235"
                                onClick={this.onClickSaveBillingAddress}
                              >
                                Save
                              </button>
                            </>
                          )}
                          {!isEditBillingAddressMode && isAddressBookExist && isBillingAddressDifferentDeliveryAddress && (
                            <button
                              className="eHomeDeliveryFormButton mWidth235"
                              onClick={this.onClickAddNewBillingAddress}
                            >
                              Add billing address
                            </button>
                          )}
                        </div>
                        <div className="eHomeDeliveryFormContainer mMarginTop15">
                          {!isAllProductsDigital && (
                            <select
                              id="selectIdDeliveryOption"
                              className="eHomeDeliveryFormSelect mWidth240"
                              onChange={this.onDeliveryOptionSelect}
                              value={selectedDeliveryOption}
                              required
                              disabled={isEditDeliveryAddressMode}
                            >
                              {HomeDeliveryForm.selectDeliveryOptions(selectedCountryDeliveryAddress, deliveryOptions)}
                            </select>
                          )}
                          <div className="eHomeDeliveryFormText mFontSize18">Order total</div>
                          <div className="eHomeDeliveryFormText mFontSize18">{`£${orderAmount}`}</div>
                        </div>

                        {isInternationalDelivery && (
                          <div className="checkbox mb-3 mt-3 eHomeCheckbox">
                            <input type="checkbox" required />
                            <span className="eHomeDeliveryInfoText">
                              {' All shipments outside of UK since Brexit are now subject to VAT / Import Duty. ' +
                                'Our couriers have no control over these charges; therefore, the charges are not included when paying for international postage. ' +
                                'Please confirm you accept that you will pay these charges.'}
                            </span>
                          </div>
                        )}

                        <div className="eHomeDeliveryFormButtonWrapper">
                          <button
                            className="eHomeDeliveryFormButton"
                            type="submit"
                            disabled={isEditDeliveryAddressMode || isEditBillingAddressMode || isDisablePaymentButton}
                          >
                            Payment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
