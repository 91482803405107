import * as React from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useEffect } from 'react';
import { parse } from 'query-string';
import { getOrderBySessionId } from '../../../services/public/order';
import { getConfig } from '../../../services/public/config';
import { isDev, isLocal } from '../../../helpers/domain';
import { PAY360_RESULT } from '../../../consts/payment';
import { createBrokenOrder, deleteBrokenOrder, updateBrokenOrder } from '../../../services/public/brokenOrder';

const MobilePaymentSuccessHosted = props => {
  useEffect(() => {
    const { history } = props;
    const search = parse(history.location.search);
    const sessionId = search.sessionId as string;

    let initialTimer = null;
    let maximumTimer = null;
    let timer = null;

    const subdomain = document.location.hostname.replace('payment', 'orders');
    const protocol = document.location.protocol;
    const port = document.location.port;

    createBrokenOrder(sessionId)
      .then(() => getConfig())
      .then(res => {
        const { payments } = res;
        const { pay360 } = payments;
        const { pay360InitialWaitingTime, pay360MaximumWaitingTime, pay360AdditionalWaitingTime } = pay360;

        maximumTimer = setTimeout(() => {
          updateBrokenOrder(sessionId).then(() => {
            window.location.assign(
              isDev(document.location.hostname) || isLocal(document.location.hostname)
                ? `${protocol}//${subdomain}:${port}/mobile/result?result=${PAY360_RESULT.TIMEOUT}`
                : `${protocol}//${subdomain}/mobile/result?result=${PAY360_RESULT.TIMEOUT}`
            );
          });
        }, pay360MaximumWaitingTime);

        initialTimer = setTimeout(() => {
          getOrderBySessionId(sessionId).then(res => {
            const { orderStatus } = res;
            switch (orderStatus) {
              case PAY360_RESULT.PAID:
                deleteBrokenOrder(sessionId).then(() => {
                  window.location.assign(
                    isDev(document.location.hostname) || isLocal(document.location.hostname)
                      ? `${protocol}//${subdomain}:${port}/mobile?result?result=${PAY360_RESULT.PAID}`
                      : `${protocol}//${subdomain}/mobile/result?result=${PAY360_RESULT.PAID}`
                  );
                });
                break;
              case PAY360_RESULT.CANCELED:
                deleteBrokenOrder(sessionId).then(() => {
                  window.location.assign(
                    isDev(document.location.hostname) || isLocal(document.location.hostname)
                      ? `${protocol}//${subdomain}:${port}/mobile/result?result=${PAY360_RESULT.CANCELED}`
                      : `${protocol}//${subdomain}/mobile/result?result=${PAY360_RESULT.CANCELED}`
                  );
                });
                break;
              default: {
                timer = setInterval(() => {
                  getOrderBySessionId(sessionId).then(res => {
                    const { orderStatus } = res;
                    switch (orderStatus) {
                      case PAY360_RESULT.PAID:
                        deleteBrokenOrder(sessionId).then(() => {
                          window.location.assign(
                            isDev(document.location.hostname) || isLocal(document.location.hostname)
                              ? `${protocol}//${subdomain}:${port}/mobile/result?result=${PAY360_RESULT.PAID}`
                              : `${protocol}//${subdomain}/mobile/result?result=${PAY360_RESULT.PAID}`
                          );
                        });
                        break;
                      case PAY360_RESULT.CANCELED:
                        deleteBrokenOrder(sessionId).then(() => {
                          window.location.assign(
                            isDev(document.location.hostname) || isLocal(document.location.hostname)
                              ? `${protocol}//${subdomain}:${port}/mobile/result?result=${PAY360_RESULT.CANCELED}`
                              : `${protocol}//${subdomain}/mobile/result?result=${PAY360_RESULT.CANCELED}`
                          );
                        });
                    }
                  });
                }, pay360AdditionalWaitingTime);
              }
            }
          });
        }, pay360InitialWaitingTime);
      });
  }, []);

  return (
    <div className={'mt-3'}>
      <Loader />
      <div className={'text-center'}>Waiting for payment authorization...</div>
    </div>
  );
};

export default MobilePaymentSuccessHosted;
