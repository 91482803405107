import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import '../../../../../../../components/CustomNotificationPreview/CustomNotificationPreviewEmail.css';
import { Notification } from '../../../../../../../models/notifications';
import { ACTION_DESCRIPTOR_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/actionDescriptor';
import { NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/notificationChannels';
import { NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/notifications';
import * as Moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../../../../../consts/consts';

interface Props {
  notificationItem: Notification;
}

const ROWS = [
  { title: 'Action descriptor type', field: 'typeName' },
  { title: 'Channel type', field: 'channelType' },
  { title: 'Status', field: 'status' },
  { title: 'Create at', field: 'createdAt' },
  { title: 'Email', field: 'email' },
  { title: 'Delivery error', field: 'errorDetails' },
  { title: 'Title', field: 'title' },
];

export const CustomerNotificationSummary: FunctionComponent<Props> = props => {
  const { notificationItem } = props;
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'typeName':
        const { actionDescriptorType } = notificationItem;
        contentCell =
          typeof actionDescriptorType !== 'undefined'
            ? ACTION_DESCRIPTOR_TYPE_SERVER_TO_CLIENT_MAPPING[actionDescriptorType]
            : '';
        break;
      case 'channelType':
        const notificationChannelType = propz.get(notificationItem, ['channel', 'type'], undefined);
        contentCell =
          typeof notificationChannelType !== 'undefined'
            ? NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING[notificationChannelType]
            : '';
        break;
      case 'status':
        const notificationStatus = notificationItem.status;
        contentCell =
          typeof notificationStatus !== 'undefined'
            ? NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[notificationStatus]
            : '';
        break;
      case 'createdAt':
        contentCell = propz.get(notificationItem, ['name'], '');
        const createdAt = notificationItem.createdAt;
        contentCell = typeof createdAt !== 'undefined' ? Moment(new Date(createdAt)).format(DATE_TIME_FORMAT) : '';
        break;
      case 'email':
        contentCell = propz.get(notificationItem, ['channel', 'email'], '');
        break;
      case 'errorDetails':
        contentCell = propz.get(notificationItem, ['errorDetails'], 'no');
        break;
      case 'title':
        contentCell = propz.get(notificationItem, ['title'], '');
        break;
      default:
        contentCell = propz.get(notificationItem, [row.field], '');
    }

    return (
      <tr key={`notification_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });
  const body = propz.get(notificationItem, ['body'], '');
  const bodyHtml = <div dangerouslySetInnerHTML={{ __html: body }} />;
  return (
    <div>
      <table className={'table table-sm mPre mt-3'}>
        <tbody>
          {rows}
          <td>Message</td>
          <td>
            <div className="card border-success h-100">
              <div className="card-body">{bodyHtml}</div>
            </div>
          </td>
        </tbody>
      </table>
    </div>
  );
};

CustomerNotificationSummary.displayName = 'CustomerNotificationSummary';
