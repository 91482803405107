import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../../../../../consts/consts';

interface Props {
  notes: any[];
}

export function OrderNotesSummary(props: Props) {
  const { notes } = props;

  const rows = notes.map((note, index) => {
    const createdAt = propz.get(note, ['createdAt'], '');
    const createdDate = typeof createdAt !== 'undefined' ? Moment(new Date(createdAt)).format(DATE_TIME_FORMAT) : '';
    const createdBy = propz.get(note, ['createdBy', 'email'], '');
    const text = propz.get(note, ['text'], '');

    return (
      <tr key={`order_note_summary_${index}`}>
        <td>{createdDate}</td>
        <td>{createdBy}</td>
        <td title={text}>{text}</td>
        <td />
      </tr>
    );
  });

  return (
    <table className={'table table-sm mt-3 mPre'}>
      <thead>
        <tr>
          <th>Added</th>
          <th>User</th>
          <th>Text</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
