import * as React from 'react';
import { Offer } from 'Src/models/offers';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import {
  getFirstNameAndLastName,
  getJobReference,
  getName,
  searchFunctionCustomers,
  searchFunctionJobs,
  searchFunctionProductOffers,
  searchFunctionProducts,
  searchFunctionSchools,
} from '../../../../../../../helpers/autocomplete';
import { AppUser } from '../../../../../App';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import { Product } from '../../../../../../../models/products';
import { PRICE, PRICE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/offer';
import { Component, useState } from 'react';
import { getCurrencyMask } from '../../../../../../../helpers/currencyMask';
import MaskedInput from 'react-text-mask';
import { VOUCHER_SCOPE, VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/voucher';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  offer?: Offer;
  user: AppUser;
  products?: Product[];
}

interface State {
  productOffers: any[];
  scopeType: string;
  schools: any[];
  customers: any[];
  jobs: any[];
}

export class OfferForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { offer, products } = this.props;
    const isOfferExist = typeof offer !== 'undefined';

    this.state = {
      productOffers: isOfferExist
        ? offer.productOffers.map(productOffer => {
            const product = products.find(p => p.id === productOffer.productId);
            return {
              ...productOffer,
              listPrice: typeof product !== 'undefined' ? product.pricing.price : 0,
            };
          })
        : [],
      scopeType: isOfferExist ? offer.scope.scopeType : VOUCHER_SCOPE.WHOLE_PLATFORM,
      schools: isOfferExist ? offer.scope.schools : [],
      customers: isOfferExist ? offer.scope.customers : [],
      jobs: isOfferExist ? offer.scope.jobs : [],
    };
  }

  getProducts = (text: string) => {
    const { user } = this.props;
    return searchFunctionProducts(user, text);
  };

  getProductOffers = (text: string) => {
    const { user } = this.props;
    const { productOffers } = this.state;
    return searchFunctionProductOffers(user, text, productOffers);
  };

  renderProductOffers(productOffers, setFieldValue) {
    const { productOffers: productOffersState } = this.state;
    return productOffers.map((productOffer, index) => {
      return (
        <div key={productOffer.productId}>
          <div className={'mb-2'}>Product offer</div>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={productOffer}
            disabled
            customClass={'mb-3'}
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const nextProductOffers = productOffers.filter(p => p.productId !== productOffer.productId);
                setFieldValue('productOffers', nextProductOffers);
                this.setState({
                  productOffers: nextProductOffers,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>

          <div className="form-group">
            <label>Discount</label>
            <Field
              name={`productOffers.${index}.priceType`}
              component="select"
              className="form-control mb-3"
              onChange={event => {
                const priceType = event.target.value;
                setFieldValue(`productOffers.${index}.priceType`, priceType);
                setFieldValue(`productOffers.${index}.price`, 0);
              }}
            >
              <option value={PRICE.AMOUNT}>{PRICE_SERVER_TO_CLIENT_MAPPING.AMOUNT}</option>
              <option value={PRICE.PERCENT}>{PRICE_SERVER_TO_CLIENT_MAPPING.PERCENT}</option>
            </Field>
          </div>

          {productOffer.priceType === PRICE.AMOUNT && (
            <div className="form-group">
              <div className="row m-0">
                <div className="d-inline-block mt-2">
                  List price: {CURRENCY_SYMBOL.POUND}
                  {productOffersState[index].listPrice}, offered price:
                </div>

                <div className="input-group w-25 ml-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                  </div>

                  <Field name="">
                    {({ field }) => (
                      <MaskedInput
                        type="text"
                        className="form-control"
                        mask={getCurrencyMask}
                        onChange={event => {
                          setFieldValue(`productOffers.${index}.price`, event.target.value);
                        }}
                        value={productOffer.price}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage component="div" className="alert alert-danger mt-3" name={`productOffers.${index}.price`} />
            </div>
          )}

          {productOffer.priceType === PRICE.PERCENT && (
            <div className="form-group">
              <div className="row m-0">
                <div className="d-inline-block mt-2">
                  List price: {CURRENCY_SYMBOL.POUND}
                  {productOffersState[index].listPrice}, offered percent:
                </div>

                <div className="input-group w-25 ml-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">%</div>
                  </div>

                  <Field name="">
                    {({ field }) => (
                      <input
                        type="number"
                        className="form-control"
                        max="100"
                        min="0"
                        onChange={event => {
                          setFieldValue(`productOffers.${index}.price`, event.target.value);
                        }}
                        value={productOffer.price}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage component="div" className="alert alert-danger mt-3" name={`productOffers.${index}.price`} />
            </div>
          )}
        </div>
      );
    });
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    const { schools } = this.state;
    return searchFunctionSchools(user, text, schools);
  };

  getCustomers = (text: string) => {
    const { user } = this.props;
    const { customers } = this.state;
    return searchFunctionCustomers(user, text, customers);
  };

  getJobs = (text: string) => {
    const { user } = this.props;
    const { jobs } = this.state;
    return searchFunctionJobs(user, text, jobs);
  };

  renderSchools(schools, setFieldValue) {
    return schools.map(school => {
      return (
        <div key={school.schoolId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={school}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const schoolsFiltered = schools.filter(s => s.schoolId !== school.schoolId);
                setFieldValue('scope.schools', schoolsFiltered);
                this.setState({
                  schools: schoolsFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  renderCustomers(customers, setFieldValue) {
    return customers.map(customer => {
      return (
        <div key={customer.customerId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getFirstNameAndLastName}
            defaultItem={customer}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const customerFiltered = customers.filter(c => c.customerId !== customer.customerId);
                setFieldValue('scope.customers', customerFiltered);
                this.setState({
                  customers: customerFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  renderJobs(jobs, setFieldValue) {
    return jobs.map(job => {
      return (
        <div key={job.jobReferenceId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={job}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const josFiltered = jobs.filter(j => j.jobReferenceId !== job.jobReferenceId);
                setFieldValue('scope.jobs', josFiltered);
                this.setState({
                  jobs: josFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  render() {
    const { offer, onSubmit, onCancel } = this.props;
    const { scopeType } = this.state;

    const isOfferExist = typeof offer !== 'undefined';
    const offerForm = {
      product: {
        productId: isOfferExist ? offer.product.productId : '',
        name: isOfferExist ? offer.product.name : '',
      },
      productOffers: isOfferExist ? offer.productOffers : [],
      scope: {
        scopeType: isOfferExist ? offer.scope.scopeType : VOUCHER_SCOPE.WHOLE_PLATFORM,
        jobs: isOfferExist ? offer.scope.jobs : [],
        schools: isOfferExist ? offer.scope.schools : [],
        customers: isOfferExist ? offer.scope.customers : [],
      },
    };

    const isScopeWholePlatform = scopeType === VOUCHER_SCOPE.WHOLE_PLATFORM;
    const isScopeSchool = scopeType === VOUCHER_SCOPE.SCHOOL;
    const isScopeListOfCustomers = scopeType === VOUCHER_SCOPE.LIST_OF_CUSTOMERS;
    const isScopeJob = scopeType === VOUCHER_SCOPE.JOB;

    const ProductSchema = Yup.object().shape({
      productId: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
    });

    const ProductOffersSchema = Yup.array()
      .min(1, 'Should contain minimum 1 product')
      .of(
        Yup.object().shape({
          productId: Yup.string().required('Required'),
          name: Yup.string().required('Required'),
          priceType: Yup.string().required('Required'),
          price: Yup.string().when('priceType', {
            is: PRICE.AMOUNT,
            then: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            otherwise: Yup.string()
              .required('Required')
              .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
        })
      );

    let OfferSchema;

    switch (scopeType) {
      case VOUCHER_SCOPE.WHOLE_PLATFORM:
        {
          OfferSchema = Yup.object().shape({
            product: ProductSchema,
            productOffers: ProductOffersSchema,
          });
        }
        break;
      case VOUCHER_SCOPE.SCHOOL:
        {
          OfferSchema = Yup.object().shape({
            product: ProductSchema,
            productOffers: ProductOffersSchema,
            scope: Yup.object().shape({
              schools: Yup.array().min(1, 'Should contain minimum 1 school'),
            }),
          });
        }
        break;
      case VOUCHER_SCOPE.LIST_OF_CUSTOMERS:
        {
          OfferSchema = Yup.object().shape({
            product: ProductSchema,
            productOffers: ProductOffersSchema,
            scope: Yup.object().shape({
              customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
            }),
          });
        }
        break;
      case VOUCHER_SCOPE.JOB:
        {
          OfferSchema = Yup.object().shape({
            product: ProductSchema,
            productOffers: ProductOffersSchema,
            scope: Yup.object().shape({
              jobs: Yup.array().min(1, 'Should contain minimum 1 job'),
            }),
          });
        }
        break;
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik initialValues={offerForm} validationSchema={OfferSchema} onSubmit={onSubmit}>
              {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Product</label>
                      <Field name="product">
                        {() => (
                          <Autocomplete
                            searchFunction={this.getProducts}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.product}
                            onSelect={product => {
                              setFieldValue('product.productId', product.id);
                              setFieldValue('product.name', product.name);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="product.productId" />
                    </div>

                    <div>
                      {this.renderProductOffers(values.productOffers, setFieldValue)}
                      <div className="form-group">
                        <label>Add product:</label>
                        <Field name="">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getProductOffers}
                              getElementTitle={getName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={productOffer => {
                                const nextProductOffers = [
                                  ...values.productOffers,
                                  {
                                    productId: productOffer.id,
                                    name: productOffer.name,
                                    price: 0,
                                    priceType: PRICE.AMOUNT,
                                    listPrice: productOffer.pricing.price,
                                  },
                                ];

                                setFieldValue('productOffers', nextProductOffers);
                                this.setState({
                                  productOffers: nextProductOffers,
                                });
                              }}
                            />
                          )}
                        </Field>
                        {this.state.productOffers.length === 0 && (
                          <ErrorMessage component="div" className="alert alert-danger" name={`productOffers`} />
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Offer scope</label>
                      <Field
                        name="scope.scopeType"
                        component="select"
                        className="form-control mb-3"
                        onChange={event => {
                          const scopeType = event.target.value;

                          if (scopeType !== VOUCHER_SCOPE.SCHOOL) {
                            setFieldValue('scope.schools', []);
                          }

                          if (scopeType !== VOUCHER_SCOPE.JOB) {
                            setFieldValue('scope.jobs', []);
                          }

                          if (scopeType !== VOUCHER_SCOPE.LIST_OF_CUSTOMERS) {
                            setFieldValue('scope.customers', []);
                          }

                          setFieldValue('scope.scopeType', scopeType);
                          this.setState({ scopeType });
                        }}
                      >
                        <option value={VOUCHER_SCOPE.WHOLE_PLATFORM}>
                          {VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.WHOLE_PLATFORM}
                        </option>
                        <option value={VOUCHER_SCOPE.SCHOOL}>{VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.SCHOOL}</option>
                        <option value={VOUCHER_SCOPE.LIST_OF_CUSTOMERS}>
                          {VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.LIST_OF_CUSTOMERS}
                        </option>
                        <option value={VOUCHER_SCOPE.JOB}>{VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.JOB}</option>
                      </Field>
                    </div>

                    {isScopeListOfCustomers && (
                      <>
                        <div>Customers</div>
                        {this.renderCustomers(values.scope.customers, setFieldValue)}
                        <Field name="scope.customers">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getCustomers}
                              getElementTitle={getFirstNameAndLastName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={customer => {
                                const nextCustomers = [].concat(values.scope.customers, {
                                  customerId: customer.id,
                                  firstName: customer.firstName,
                                  lastName: customer.lastName,
                                });

                                setFieldValue('scope.customers', nextCustomers);
                                this.setState({
                                  customers: nextCustomers,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name={`scope.customers`} />
                      </>
                    )}

                    {isScopeSchool && (
                      <>
                        <div>Schools</div>
                        {this.renderSchools(values.scope.schools, setFieldValue)}
                        <Field name="scope.schools">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getSchools}
                              getElementTitle={getName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={school => {
                                const nextSchools = [].concat(values.scope.schools, {
                                  schoolId: school.id,
                                  name: school.name,
                                });

                                setFieldValue('scope.schools', nextSchools);
                                this.setState({
                                  schools: nextSchools,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name={`scope.schools`} />
                      </>
                    )}

                    {isScopeJob && (
                      <>
                        <div>Jobs</div>
                        {this.renderJobs(values.scope.jobs, setFieldValue)}
                        <Field name="scope.jobs">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getJobs}
                              getElementTitle={getJobReference}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={job => {
                                const nextJobs = [].concat(values.scope.jobs, {
                                  jobReferenceId: job.id,
                                  name: job.jobReference,
                                });

                                setFieldValue('scope.jobs', nextJobs);
                                this.setState({
                                  jobs: nextJobs,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name={`scope.jobs`} />
                      </>
                    )}

                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
