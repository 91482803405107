export const JOB_TABS = ['Summary'];

export const JOB_IMAGES_TABS = ['Summary'];

export const JOB_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export const JOB_STATUS_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'New',
  IN_PROGRESS: 'In progress',
  DONE: 'Ready for sale',
  ERROR: 'Error',
};
