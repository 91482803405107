import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';

export function verifyPhone(customer: AppCustomer, token: string) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/confirm/phone`, { token: token }, config).then(response => {
    return response.data;
  });
}

export function verifyEmail(customer: AppCustomer, token: string) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/confirm/email`, { token: token }, config).then(response => {
    return response.data;
  });
}
