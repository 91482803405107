import { AppUser } from '../../views/App/App';
import axios from 'axios';

export function sendFreeNotification(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/notifications/free`, data, config).then(response => {
    return response.data;
  });
}
