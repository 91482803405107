import axios from 'axios';

export function checkAvailability(data) {
  return axios.post(`${window.apiBase}/public/register/check`, data).then(response => {
    return response.data;
  });
}

export function register(data) {
  return axios.post(`${window.apiBase}/public/register`, data).then(response => {
    return response.data;
  });
}

export function checkValidParentCode(data) {
  return axios.post(`${window.apiBase}/public/schools/parentCode/check`, data).then(response => {
    return response.data;
  });
}
