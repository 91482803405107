import * as React from 'react';
import { History, Location } from 'history';

interface Props {
  history: History;
  location: Location;
  setAuthQuestionAsked: () => void;
}

export function OneTimeCodeReady(props: Props) {
  const onGuestClick = () => {
    const { setAuthQuestionAsked, history } = props;
    setAuthQuestionAsked();
    history.push('/basket');
  };

  const onLoginClick = () => {
    const { setAuthQuestionAsked, history } = props;
    setAuthQuestionAsked();
    history.push('/login');
  };

  const onCreateAccountClick = () => {
    const { setAuthQuestionAsked, history } = props;
    setAuthQuestionAsked();
    history.push('/registration');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className={'bOneTimeCodeReady'}>
            <div className={'eOneTimeCodeReadyTitle'}>NEXT STEP</div>
            <div className={'eOneTimeCodeReadyText'}>How would you like to proceed with your order?</div>
            <div className={'eOneTimeCodeReadyButtonWrapper'}>
              <div className="row">
                <div className="col-xl-4">
                  <div className="eOneTimeCodeReadyButton mColorBlue" onClick={onGuestClick}>
                    GUEST
                  </div>
                </div>
                <div className="col-xl-4">
                  <div
                    className="eOneTimeCodeReadyButton mColorRed d-flex flex-column align-items-center"
                    onClick={onLoginClick}
                  >
                    LOG
                    <div>IN</div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div
                    className="eOneTimeCodeReadyButton mColorPurple d-flex flex-column align-items-center"
                    onClick={onCreateAccountClick}
                  >
                    CREATE
                    <div className="h2">ACCOUNT</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
