import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';
import { AppUser } from '../../views/App/App';

export function getSchoolJobs(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/schoolJobs`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolJobs(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/schoolJobs`, config).then(response => {
    return response.data;
  });
}

export function getSchoolJobsCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/schoolJobs/count`, config).then(response => {
    return response.data;
  });
}

export function createSchoolJob(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schoolJobs`, data, config).then(response => {
    return response.data;
  });
}

export function getSchoolJob(user: AppUser, schoolJobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/schoolJobs/${schoolJobId}`, config).then(response => {
    return response.data;
  });
}

export function updateSchoolJob(user: AppUser, schoolJobId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/schoolJobs/${schoolJobId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteSchoolJobs(user: AppUser, schoolJobIds) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schoolJobs/remove`, schoolJobIds, config).then(response => {
    return response.data;
  });
}

export function updateSchoolJobStatus(user: AppUser, schoolJobId: string, status) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schoolJobs/${schoolJobId}/status`, { status }, config).then(response => {
    return response.data;
  });
}
