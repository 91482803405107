import axios from 'axios';
import { AppCustomer } from '../../views/AppOrders/AppOrders';
import {ALL_LIMIT} from "../../consts/table";

export function updateCustomerOrderStatusToPaid(customer: AppCustomer, basketId, orderId, data) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/orders/${orderId}/paid`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateCustomerOrderStatusToCanceled(customer: AppCustomer, basketId, orderId) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/orders/${orderId}/cancel`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function getAllCustomerOrders(customer: AppCustomer) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: ALL_LIMIT,
        skip: 0,
      }
    }
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/orders`, config).then(response => {
    return response.data;
  });
}

export function getCustomerOrder(customer: AppCustomer, orderId: string) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/orders/${orderId}`, config).then(response => {
    return response.data;
  });
}
