import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import * as moment from 'moment';
import { Component } from 'react';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  getPagesCount,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import {
  createJob,
  deleteJobs,
  generateJobPrivateLink,
  getAllJobs,
  getJobs,
  getJobsCount,
  sendNotifications,
  updateJob,
} from '../../../../../../services/superadmin/jobs';
import { JobForm } from './JobForm/JobForm';
import { getAllPhotoTypes } from '../../../../../../services/superadmin/photoTypes';
import { Job } from '../../../../../../models/jobs';
import { PhotoType } from '../../../../../../models/photoTypes';
import { School } from '../../../../../../models/schools';
import {
  getIsSchoolDeliveryAvailable,
  getIsSendNotificationOnImagesUpload,
  getJobDate,
  getJobStatus,
  getNotificationsSentAt,
  getPhotoTypeName,
  getUploadDate,
  getUploadUser,
} from '../../../../../../helpers/accessor';
import {
  getBooleanForYesOrNo,
  getSelectOptionForPhotoTypes,
  getSelectOptionForYesOrNo,
} from '../../../../../../helpers/select';
import { GenericSummary } from '../../../../../../components/GenericSummary/GenericSummary';
import { ResizablePanel } from '../../../../../../components/ResizablePanel/ResizablePanel';
import { JOB_STATUS, JOB_TABS } from '../../../../../../consts/job';
import { JobUploadImagesForm } from './JobUploadImagesForm/JobUploadImagesForm';
import { TABS } from '../../../../../../consts/common';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { Button } from '../../../../../../components/Button/Button';
import * as propz from 'propz';
import { VOUCHER_DISCOUNT_TYPE } from '../../../../../../consts/voucher';
import { addVoucher } from '../../../../../../services/superadmin/vouchers';
import { JobVoucherForm } from './JobVoucherForm/JobVoucherForm';
import { addOffer } from '../../../../../../services/superadmin/offers';
import { JobOfferForm } from './JobOfferForm/JobOfferForm';
import { jobPrivateLinkCell } from '../../../../../../helpers/cell';
import { LIMIT } from '../../../../../../consts/table';
import { JobWizard } from './JobWizard/JobWizard';
import { EMPTY_STRINGS_IN_EDITOR } from '../../../../../../consts/htmlEditor';
import { sendFreeNotification } from '../../../../../../services/superadmin/notifications';
import { uploadFile } from '../../../../../../services/file';
import { getCustomerEmails, getEmails } from '../../../../../../services/superadmin/emails';

export type jobsModeType = 'CUSTOMERS' | 'EMAILS';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'School',
    field: 'nameOfSchool',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    isShort: true,
    accessor: ['school', 'name'],
  },
  {
    text: 'School Reference',
    field: 'schoolCode',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['school', 'code'],
  },
  {
    text: 'Job Reference',
    field: 'jobReference',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['jobReference'],
  },
  {
    text: 'Photo type',
    field: 'photoType',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getPhotoTypeName,
  },
  {
    text: 'School delivery available',
    field: 'isSchoolDeliveryAvailable',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getIsSchoolDeliveryAvailable,
  },
  {
    text: 'Job date',
    field: 'jobDate',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getJobDate,
  },
  {
    text: 'Upload date',
    field: 'uploadDate',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getUploadDate,
  },
  {
    text: 'Upload user',
    field: 'uploadUser',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getUploadUser,
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getJobStatus,
  },
  {
    text: 'Public link',
    field: 'publicCode',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: jobPrivateLinkCell,
  },
  {
    text: 'Notification',
    field: 'isSendNotificationOnImagesUpload',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getIsSendNotificationOnImagesUpload,
  },
  {
    text: 'Notifications sent',
    field: 'notificationsSentAt',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getNotificationsSentAt,
  },
];

interface State {
  items: Job[];
  currentPage: number;
  selectedItems: Job[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isCreateJobFormModalOpen: boolean;
  photoTypes: PhotoType[];
  currentTabIndex: number;
  isDeleteModalOpen: boolean;
  isUploadImagesFormModalOpen: boolean;
  images: any[];
  isErrorModalOpen: boolean;
  errorMessage: string;
  isGoodSendingModalOpen: boolean;
  isVoucherFormModalOpen: boolean;
  isOfferFormModalOpen: boolean;
  resultUploadText: string;

  messageEmailSubject: string;
  messageEmailBody: string;
  isWizardModalOpen: boolean;
  wizardMode: jobsModeType;
  messageAttachments: any[];
  isLoadingFile: boolean;
  jobEmails: { customerId?: string; email: string }[];
  isRawEditor: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Jobs extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isCreateJobFormModalOpen: false,
      photoTypes: [],
      currentTabIndex: 0,
      isDeleteModalOpen: false,
      isUploadImagesFormModalOpen: false,
      images: [],
      isErrorModalOpen: false,
      errorMessage: '',
      isGoodSendingModalOpen: false,
      isVoucherFormModalOpen: false,
      isOfferFormModalOpen: false,
      resultUploadText: '',

      messageEmailSubject: '',
      messageEmailBody: '',
      isWizardModalOpen: false,
      wizardMode: 'CUSTOMERS',
      messageAttachments: [],
      isLoadingFile: false,
      jobEmails: [],
      isRawEditor: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;

    const promises = [getAllPhotoTypes(user)];

    return Promise.all(promises).then(([photoTypes]) => {
      this.setState({
        photoTypes: photoTypes,
      });

      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user, location } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getJobs(user, serverQueryFilter);
    const getItemsCountPromise = getJobsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      const jobId = propz.get(location, ['state', 'jobId']);
      if (typeof jobId !== 'undefined') {
        const job = items.find(item => item.id === jobId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof job !== 'undefined' ? [job] : [],
        });

        const state = { ...(history.location.state as any) };
        delete state.jobId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
        });
      }

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedUser = items[index];

    const selectedUserIndex = selectedItems.findIndex(user => selectedUser.id === user.id);
    let selectedUsersUpdated = [...selectedItems];

    if (selectedUserIndex !== -1) {
      selectedUsersUpdated.splice(selectedUserIndex, 1);
    } else {
      selectedUsersUpdated.push(selectedUser);
    }

    this.setState({
      selectedItems: selectedUsersUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllJobs(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
      isLoading: true,
    });
    this.setItems();
  };

  onShowStatusUploadImages = (isErrorStatus, data) => {
    this.setState({
      isLoading: true,
      isUploadImagesFormModalOpen: false,
    });
    if (isErrorStatus) {
      const errorText = JSON.stringify(propz.get(data, ['response'], ''));
      console.error(errorText);
      this.setState({
        isLoading: false,
        isErrorModalOpen: true,
        errorMessage: errorText,
      });
    } else {
      const isErrorResultUpload = propz.get(data, ['isError'], '');
      console.log('isError');
      console.log(isErrorResultUpload);
      switch (true) {
        case typeof data !== 'undefined' && data === '':
          const textAsyncResponse =
            'Images are being uploaded on the server. ' +
            'Refresh the page in the browser to see uploading status updates.';
          this.setState({
            isLoading: false,
            resultUploadText: textAsyncResponse,
            isGoodSendingModalOpen: true,
          });
          break;
        case typeof data.result !== 'undefined':
          const resultUploadText = data.result;
          this.setState({
            isLoading: false,
            resultUploadText: resultUploadText,
            isGoodSendingModalOpen: true,
          });
          break;
        default:
          this.setState({
            isLoading: false,
          });
      }
    }
  };

  onCloseGoodSendingModalClick = () => {
    this.setState({
      isGoodSendingModalOpen: false,
      isLoading: true,
    });
    this.setItems();
  };

  onSubmitForm = data => {
    const {
      isSchoolDeliveryAvailable,
      isVisibleToAllParents,
      typeId,
      school,
      schoolDeliveryDeadline,
      isSendNotificationOnImagesUpload,
      ...rest
    } = data;

    const { selectedItems, total } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isCreateJobFormModalOpen: false,
    });

    if (selectedItems.length === 1) {
      const jobUpdated = selectedItems[0];
      const { id } = jobUpdated;

      const schoolDeliveryDeadlineDate =
        typeof schoolDeliveryDeadline !== 'undefined' && schoolDeliveryDeadline !== null
          ? moment(new Date(new Date(schoolDeliveryDeadline).setHours(23, 59, 59, 0)))
          : undefined;

      const dataToTransfer = {
        ...rest,
        properties: {
          isSchoolDeliveryAvailable: getBooleanForYesOrNo(isSchoolDeliveryAvailable),
          isVisibleToAllParents: getBooleanForYesOrNo(isVisibleToAllParents),
          isSendNotificationOnImagesUpload: getBooleanForYesOrNo(isSendNotificationOnImagesUpload),
          schoolDeliveryDeadline: schoolDeliveryDeadlineDate,
        },
        photoType: {
          typeId: typeId,
        },
      };

      updateJob(user, id, dataToTransfer)
        .then(res => {
          this.setState({
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setItems();
        })
        .catch(error => {
          const errorTextInObject = propz.get(error, ['response', 'data', 'details', 'text', 'message'], '');
          const errorText =
            typeof error.response.data.details.text === 'string' ? error.response.data.details.text : errorTextInObject;
          console.error(errorText);
          this.setState({
            isLoading: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    } else {
      const dataToTransfer = {
        ...rest,
        properties: {
          isSchoolDeliveryAvailable: getBooleanForYesOrNo(isSchoolDeliveryAvailable),
          isVisibleToAllParents: getBooleanForYesOrNo(isVisibleToAllParents),
          isSendNotificationOnImagesUpload: getBooleanForYesOrNo(isSendNotificationOnImagesUpload),
          schoolDeliveryDeadline: schoolDeliveryDeadline,
        },
        photoType: {
          typeId: typeId,
        },
        school: {
          schoolId: school.id,
        },
      };

      createJob(user, dataToTransfer)
        .then(res => {
          const lastPage = getPagesCount(total + 1, LIMIT);
          this.setState({
            isCreateJobFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setCurrentPageParams(lastPage);
          this.setItems();
        })
        .catch(error => {
          const errorTextInObject = propz.get(error, ['response', 'data', 'details', 'text', 'message'], '');
          const errorText =
            typeof error.response.data.details.text === 'string' ? error.response.data.details.text : errorTextInObject;
          console.error(errorText);
          this.setState({
            isLoading: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    }
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderGoodSendingModal(): React.ReactNode {
    const { isGoodSendingModalOpen, resultUploadText } = this.state;
    // const text = 'Images have been queued for download. Refresh web page on your browser to see downloading status';
    return (
      <SimpleModal
        isOpen={isGoodSendingModalOpen}
        title={''}
        body={resultUploadText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseGoodSendingModalClick}
      />
    );
  }

  renderFormModal(): React.ReactNode {
    const { isCreateJobFormModalOpen, selectedItems, photoTypes } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 1) {
      const job = selectedItems[0];
      return (
        <SimpleModal isOpen={isCreateJobFormModalOpen}>
          <JobForm
            job={job}
            user={user}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSubmitForm}
            photoTypes={photoTypes}
          />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isCreateJobFormModalOpen}>
          <JobForm
            user={user}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSubmitForm}
            photoTypes={photoTypes}
          />
        </SimpleModal>
      );
    }
  }

  renderUploadImagesFormModal(): React.ReactNode {
    const { isUploadImagesFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isUploadImagesFormModalOpen}>
        <JobUploadImagesForm
          user={user}
          job={selectedItems[0]}
          onCancel={this.onCancelUploadImagesButtonClick}
          onShowStatusUploadImages={this.onShowStatusUploadImages}
        />
      </SimpleModal>
    );
  }

  renderDeleteModal() {
    const { selectedItems, isDeleteModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteModalOpen}
        title={'Remove job'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `job ${selectedItems[0].jobReference}` : `${selectedItems.length} jobs`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteJobsClick}
        onCloseClick={this.removeFormModalClose}
      />
    );
  }

  removeFormModalOpen = (): void => {
    this.setState({
      isDeleteModalOpen: true,
    });
  };

  removeFormModalClose = (): void => {
    this.setState({
      isDeleteModalOpen: false,
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isCreateJobFormModalOpen: false,
    });
  };

  onCancelUploadImagesButtonClick = () => {
    this.setState({
      isUploadImagesFormModalOpen: false,
    });
  };

  onCreateJobClick = () => {
    this.setState({
      isCreateJobFormModalOpen: true,
    });
  };

  onUploadImagesClick = () => {
    this.setState({
      isUploadImagesFormModalOpen: true,
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index,
    });
  };

  onEditJobClick = () => {
    this.setState({
      isCreateJobFormModalOpen: true,
    });
  };

  onDeleteJobsClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });
    const selectedItemIds = selectedItems.map(selectedItem => selectedItem.id);

    deleteJobs(user, selectedItemIds).then(res => {
      this.setState({
        selectedItems: [],
        isDeleteModalOpen: false,
        isSelectAllChecked: false,
      });

      this.setItems();
    });
  };

  isUploadImagesActive(): boolean {
    const { selectedItems } = this.state;

    if (selectedItems.length !== 1) {
      return false;
    }

    const [job] = selectedItems;
    const { status } = job;
    const isStatusNew = status === JOB_STATUS.NEW;
    const isStatusDone = status === JOB_STATUS.DONE;

    return isStatusNew || isStatusDone;
  }

  onCreateOfferClick = () => {
    this.setState({
      isOfferFormModalOpen: true,
    });
  };

  onCancelCreateOfferButtonClick = () => {
    this.setState({
      isOfferFormModalOpen: false,
    });
  };

  onSubmitCreateOfferForm = data => {
    const { user, history } = this.props;
    const { productListPrice, ...rest } = data;

    const dataToTransfer = {
      ...rest,
      productOffers: data.productOffers.map(productOffer => ({
        ...productOffer,
        price: Number(productOffer.price),
      })),
    };

    this.setState({
      isLoading: true,
    });

    addOffer(user, dataToTransfer).then(offer => {
      this.setState({
        isOfferFormModalOpen: false,
      });

      history.push({
        pathname: '/offers',
      });
    });
  };

  renderCreateOfferFormModal(): React.ReactNode {
    const { isOfferFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isOfferFormModalOpen}>
        <JobOfferForm
          jobs={selectedItems}
          user={user}
          onCancel={this.onCancelCreateOfferButtonClick}
          onSubmit={this.onSubmitCreateOfferForm}
        />
      </SimpleModal>
    );
  }

  isGeneratePublicLinkActive() {
    const { selectedItems } = this.state;

    if (selectedItems.length !== 1) {
      return false;
    }

    const [job] = selectedItems;
    const { photoType } = job;
    const { name } = photoType;
    const isPhotoTypeNameGroup = name === 'Group';
    const isPhotoTypeNamePanoramic = name === 'Panoramic';

    return isPhotoTypeNameGroup || isPhotoTypeNamePanoramic;
  }

  onGeneratePublicLinkClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const [job] = selectedItems;
    const { id } = job;

    this.setState({
      isLoading: true,
    });

    generateJobPrivateLink(user, id).then(jobUpdated => {
      this.setState({
        isLoading: true,
        selectedItems: [jobUpdated],
      });
      this.setItems();
    });
  };

  onSendNotification = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const promises = selectedItems.map(job => sendNotifications(user, job.id));

    BPromise.all(promises).then(res => {
      this.setState({
        resultUploadText: 'Success',
        isGoodSendingModalOpen: true,
      });
      this.setItems();
    });
  };

  onSendFreeNotification = (mode: jobsModeType) => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    const [job] = selectedItems;

    this.setState({
      isLoading: true,
    });

    const promise = mode === 'CUSTOMERS' ? getCustomerEmails(user, job.id) : getEmails(user, job.id);

    promise.then(emails => {
      if (emails.length === 0) {
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorMessage: 'Not emails',
        });
      } else {
        this.setState({
          wizardMode: mode,
          isWizardModalOpen: true,
          isLoading: false,
          jobEmails: emails,
        });
      }
    });
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      photoTypes,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
    } = this.state;

    const actionItems = [
      {
        itemText: 'Create Job',
        onItemClick: this.onCreateJobClick,
        isActive: selectedItems.length === 0,
      },
      {
        itemText: 'Edit Job',
        onItemClick: this.onEditJobClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete Job' : 'Delete Jobs',
        onItemClick: this.removeFormModalOpen,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Upload images',
        onItemClick: this.onUploadImagesClick,
        isActive: this.isUploadImagesActive(),
      },
      {
        itemText: 'Create voucher',
        onItemClick: this.onCreateVoucherClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Create offer',
        onItemClick: this.onCreateOfferClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Generate public link',
        onItemClick: this.onGeneratePublicLinkClick,
        isActive: this.isGeneratePublicLinkActive(),
      },
      {
        itemText: 'Send Notification Images Ready',
        onItemClick: this.onSendNotification,
        isActive:
          selectedItems.length > 0 && selectedItems.every(job => job.properties.isSendNotificationOnImagesUpload), // this.isGeneratePublicLinkActive(),
      },
      {
        itemText: 'Send an email to customers',
        onItemClick: () => this.onSendFreeNotification('CUSTOMERS'),
        isActive: selectedItems.length === 1,
      },
      {
        itemText: 'Send an email to all buyers',
        onItemClick: () => this.onSendFreeNotification('EMAILS'),
        isActive: selectedItems.length === 1,
      },
    ];

    const filterOptions = {
      photoType: getSelectOptionForPhotoTypes(photoTypes),
      isSchoolDeliveryAvailable: getSelectOptionForYesOrNo(),
      isSendNotificationOnImagesUpload: getSelectOptionForYesOrNo(),
    };

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  getTabs(): React.ReactNode {
    const { currentTabIndex } = this.state;

    const tabs = JOB_TABS;
    const currentTab = tabs[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <div />;
      default:
        return <div />;
    }
  }

  onCreateVoucherClick = () => {
    this.setState({
      isVoucherFormModalOpen: true,
    });
  };

  onCancelCreateVoucherButtonClick = () => {
    this.setState({
      isVoucherFormModalOpen: false,
    });
  };

  onSubmitCreateVoucherForm = data => {
    const { user, history } = this.props;
    const { discount, scope, code, description, startDate, endDate, isOneOffVoucher } = data;
    const { discountType, minOrderAmount, discountAmount, product, priceType } = discount;
    const { scopeType, jobs, purchaseSource } = scope;

    const dataToTransfer = {
      code: code,
      description: description,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      isOneOffVoucher: isOneOffVoucher,
      discount: {
        minOrderAmount: Number(minOrderAmount),
        discountType: discountType,
      },
      scope: {
        scopeType: scopeType,
        purchaseSource: purchaseSource,
        jobs: jobs,
      },
    };

    const isDiscountTypeFreePostage = discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE;
    const isDiscountTypeProductDiscount = discountType === VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT;

    if (!isDiscountTypeFreePostage) {
      propz.set(dataToTransfer, ['discount', 'discountAmount'], Number(discountAmount));
      propz.set(dataToTransfer, ['discount', 'priceType'], priceType);
    }

    if (isDiscountTypeProductDiscount) {
      propz.set(dataToTransfer, ['discount', 'product'], product);
    }

    this.setState({
      isLoading: true,
    });

    addVoucher(user, dataToTransfer).then(voucher => {
      this.setState({
        isVoucherFormModalOpen: false,
      });

      history.push({
        pathname: '/vouchers',
      });
    });
  };

  renderCreateVoucherFormModal(): React.ReactNode {
    const { isVoucherFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isVoucherFormModalOpen}>
        <JobVoucherForm
          jobs={selectedItems}
          user={user}
          onCancel={this.onCancelCreateVoucherButtonClick}
          onSubmit={this.onSubmitCreateVoucherForm}
        />
      </SimpleModal>
    );
  }

  onShowImagesButtonClick = () => {
    const { selectedItems } = this.state;
    const job = selectedItems[0];
    const { id } = job;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/images`,
      search: `job=${id}`,
      state: { search: search },
    });
  };

  renderSummary(): React.ReactNode {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a job above</div>;
    }

    const job = selectedItems[0];

    const tabs = this.getTabs();
    const currentTab = JOB_TABS[currentTabIndex];
    const isShowImages = currentTab === TABS.SUMMARY && job.status === JOB_STATUS.DONE;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={JOB_TABS} currentTabIndex={currentTabIndex}>
        {isShowImages && (
          <Button onClick={this.onShowImagesButtonClick} text={'Show images'} customClass={'mt-3 mr-3 mb-3'} />
        )}
        {tabs}
      </GenericSummary>
    );
  }

  onCancelSendNotificationsButtonClick = () => {
    this.setState({
      isLoadingFile: false,
      isWizardModalOpen: false,
      messageEmailSubject: '',
      messageEmailBody: '',
      messageAttachments: [],
      jobEmails: [],
    });
  };

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.url} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  onSendNotificationModalClick = () => {
    const { messageEmailSubject, messageEmailBody, messageAttachments, jobEmails } = this.state;

    let newMessageEmailBody;
    const emptyStrings = [...EMPTY_STRINGS_IN_EDITOR];
    const emptyEmailBody = emptyStrings.find(string => messageEmailBody === string);

    if (typeof emptyEmailBody !== 'undefined') {
      //  because   "<p><br></p>" === ''  in editor
      newMessageEmailBody = messageAttachments.length > 0 ? this.getAttachmentAsLinksString() : '';
    } else {
      newMessageEmailBody = messageEmailBody + this.getAttachmentAsLinksString();
    }

    const messageHTMLEmailBody = newMessageEmailBody !== '' ? newMessageEmailBody : '';

    const data = {
      content: {
        email: {
          subject: messageEmailSubject,
          body: messageHTMLEmailBody,
        },
      },
      affectedEmails: jobEmails.filter(email => email.customerId === undefined).map(email => email.email),
      affectedCustomerIds: jobEmails.filter(email => email.customerId !== undefined).map(email => email.customerId),
    };

    this.onSubmitNotificationClick(data);
  };

  onSubmitNotificationClick = data => {
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    sendFreeNotification(user, data)
      .then(empty => {
        this.setState({
          isLoading: false,
          isWizardModalOpen: false,
          messageEmailSubject: '',
          messageEmailBody: '',
          messageAttachments: [],
          jobEmails: [],
          selectedItems: [],
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
        console.error('error');
        console.error(errorText);
      });
  };

  onEmailSubjectChange = event => {
    this.setState({
      messageEmailSubject: event.target.value,
    });
  };

  onEmailBodyChange = (body: any) => {
    this.setState({
      messageEmailBody: body,
    });
  };

  onRemoveAttachment = (url: string) => {
    const attachments = [...this.state.messageAttachments];
    const attachmentIndex = attachments.findIndex(attachment => attachment.url === url);

    attachments.splice(attachmentIndex, 1);
    this.setState({
      messageAttachments: attachments,
    });
  };

  onEmailAttachmentChange = (event): void => {
    const { user } = this.props;
    const attachments = [...this.state.messageAttachments];
    this.setState({
      isLoadingFile: true,
    });
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const fileName = event.target.files[0];

    uploadFile(user, formData)
      .then(data => {
        const url = data.url;
        const urlFormatted = url.replace(/ /g, '%20');
        attachments.push({
          fileName: fileName.name,
          url: urlFormatted,
        });
        this.setState({
          messageAttachments: attachments,
          isLoadingFile: false,
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isErrorModalOpen: true,
          errorMessage: errorText,
          isLoadingFile: false,
        });
        console.error('error');
        console.error(error);
      });
  };

  onRawChange = event => {
    const { value } = event.target;
    if (value === 'richText') {
      this.setState({
        isRawEditor: false,
        messageEmailBody: '',
      });
    } else {
      this.setState({
        isRawEditor: true,
        messageEmailBody: '',
      });
    }
  };

  renderWizard(): React.ReactNode {
    const { isWizardModalOpen } = this.state;
    if (!isWizardModalOpen) {
      return null;
    }

    const {
      messageEmailSubject,
      messageEmailBody,
      messageAttachments,
      isRawEditor,
      isErrorModalOpen,
      isLoadingFile,
      jobEmails,
      wizardMode,
    } = this.state;

    return (
      <JobWizard
        onCancelClick={this.onCancelSendNotificationsButtonClick}
        isOpen={isWizardModalOpen}
        onSendNotificationModalClick={this.onSendNotificationModalClick}
        onEmailSubjectChange={this.onEmailSubjectChange}
        onEmailBodyChange={this.onEmailBodyChange}
        onRemoveAttachment={this.onRemoveAttachment}
        onEmailAttachmentChange={this.onEmailAttachmentChange}
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messageAttachments={messageAttachments}
        isError={isErrorModalOpen}
        isLoadingFile={isLoadingFile}
        isRawEditor={isRawEditor}
        onRawEditorChange={this.onRawChange}
        jobEmails={jobEmails}
        wizardMode={wizardMode}
      />
    );
  }

  render() {
    const {
      isLoading,
      isCreateJobFormModalOpen,
      isUploadImagesFormModalOpen,
      isErrorModalOpen,
      isVoucherFormModalOpen,
      isWizardModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isCreateJobFormModalOpen ||
      isUploadImagesFormModalOpen ||
      isErrorModalOpen ||
      isVoucherFormModalOpen ||
      isWizardModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderFormModal()}
        {this.renderCreateVoucherFormModal()}
        {this.renderUploadImagesFormModal()}
        {this.renderSendingError()}
        {this.renderGoodSendingModal()}
        {this.renderWizard()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
