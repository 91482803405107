import * as React from 'react';
import { Component } from 'react';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import * as Moment from 'moment';
import { History, Location } from 'history';
import { AppOneTimeCode } from '../AppOrders';
import { ImageTicketsOneTimeCodeView } from '../../../components/ImageTicketsOneTimeCodeView/ImageTicketsOneTimeCodeView';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { WORLD_PAY_RESULT, PAY360_RESULT } from '../../../consts/payment';
import { CountDownClock } from '../../../components/CountDownClock/CountDownClock';
import { getJobs, getTicketsCount } from '../../../services/public/images';
import { getClassFilter } from '../../../helpers/filter';
import { getFromHistory } from '../../../helpers/history';
import { sortImagesByPhotoType } from '../../../helpers/images';
import { loginOneTimeCode as getTickets } from '../../../helpers/auth';
import { LIMIT_DOWNLOAD_IMAGES } from '../../../consts/images';
import { Loader } from 'Src/components/Loader/Loader';
import { createBasket, addGalleryToBasket } from '../../../services/public/basket';
import { ConfirmationModal } from '../CommonComponents/PhotoSlider/ConfirmationModal/ConfirmationModal';
import { getOneTimeCodeOrder, getOneTimeCodeOrderImages } from '../../../services/public/order';
import { Order } from '../../../models/orders';
import { OneTimeCodeOrderInformation } from './OneTimeCodeOrderInformation';
import { OrderImage } from '../../../models/orderImages';
import './OneTimeCode.scss';

interface Props {
  oneTimeCode: AppOneTimeCode;
  tickets: any[];
  selectedTicketIds: any[];
  onGalleryImageClick: (ticket: any) => void;
  history: History;
  location: Location;
  setBasketId: (basketId: string) => void;
}

interface State {
  isWorldPayPaymentSuccess: boolean;
  isPay360PaymentSuccess: boolean;
  isPaymentCanceled: boolean;
  isPaymentTimeout: boolean;
  isShowCountDownClock: boolean;
  isLoading: boolean;

  dates: string[];
  dateTicketsCount: any;
  dateContent: any;
  dateClassContent: any;
  isDateContentShow: any;
  isDateClassContentShow: any;
  dateClassParams: any;
  isNoSelectedImagesInfo: boolean;
  searchQuery: string;

  orderDetails: Order | null;
  orderImages: OrderImage[];
}

export class OneTimeCodeView extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isPaymentCanceled: false,
      isWorldPayPaymentSuccess: false,
      isPay360PaymentSuccess: false,
      isPaymentTimeout: false,
      isShowCountDownClock: true,

      isLoading: false,
      dates: [],
      dateTicketsCount: {},
      dateContent: {},
      dateClassContent: {},
      isDateContentShow: {},
      isDateClassContentShow: {},
      dateClassParams: {},
      isNoSelectedImagesInfo: false,
      searchQuery: '',

      orderDetails: null,
      orderImages: [],
    };
  }

  componentDidMount() {
    const { history, location, setBasketId } = this.props;
    const storedOrderId = sessionStorage.getItem('orderId') || localStorage.getItem('orderId');

    this.setState({
      isLoading: true,
    });

    // pay360 iframe result is contained in history.search
    const status = getFromHistory(history, 'status');
    const isStatusExist = typeof status !== 'undefined';
    this.filterDatesBasedOnSearch();

    // worldpay and pay360 hosted result is contained in history.state after redirect from /accessCode
    let result = isStatusExist ? status : propz.get(location, ['state', 'result']);

    switch (result) {
      case WORLD_PAY_RESULT.PAID:
        this.setState({ isWorldPayPaymentSuccess: true });
        break;
      case PAY360_RESULT.PAID:
        setBasketId(undefined); //for pay360 iframe only

        this.setState({ isPay360PaymentSuccess: true });
        if (storedOrderId) {
          this.fetchOneTimeCodeOrderDetails(storedOrderId);
        }
        break;

      case WORLD_PAY_RESULT.CANCELED:
      case PAY360_RESULT.CANCELED:
        this.setState({
          isPaymentCanceled: true,
        });
        break;

      case PAY360_RESULT.TIMEOUT:
        this.setState({
          isPaymentTimeout: true,
        });
        break;
    }

    //clear history
    const state = { ...(history.location.state as any) };
    delete state.result;
    history.replace({ ...history.location, state });

    this.setInitialDataStructure();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.filterDatesBasedOnSearch();
    }
  }

  fetchOneTimeCodeOrderDetails(orderId: string) {
    const { oneTimeCode } = this.props;
    const { username, password } = oneTimeCode;

    this.setState({ isLoading: true });

    let fetchedOrder = null;

    getOneTimeCodeOrder(username, password, orderId)
      .then(order => {
        if (!order || !order.id) {
          throw new Error('Order details not found or invalid.');
        }
        fetchedOrder = order;
        return getOneTimeCodeOrderImages(username, password, orderId, 50);
      })
      .then(images => {
        this.setState({
          orderDetails: fetchedOrder,
          orderImages: images,
          isLoading: false,
        });
      })
      .catch(error => {
        console.error('Error fetching order details or images:', error);
        this.setState({ isLoading: false, orderDetails: null, orderImages: [] });
      });
  }

  getJobsFromAdditionalCodes = additionalCodes => {
    let jobs = [];
    let jobPromises = [];

    additionalCodes.forEach(code => {
      const getJobsPromise = getJobs(code);
      jobPromises.push(getJobsPromise);
    });

    return BPromise.all(jobPromises).then(res => {
      res.forEach((jobArray, index) => {
        jobArray.forEach(job => {
          job.username = additionalCodes[index].username;
          job.password = additionalCodes[index].password;
          jobs.push(job);
        });
      });

      return jobs;
    });
  };

  setInitialDataStructure = () => {
    const { oneTimeCode } = this.props;
    const { additionalCodes } = oneTimeCode;
    let jobs;
    let counts = [];
    let promises = [];

    const isAdditionalCodesExist = typeof additionalCodes !== 'undefined';

    const getJobsPromise = getJobs(oneTimeCode);

    if (isAdditionalCodesExist) {
      const getJobsFromAdditionalCodesPromise = this.getJobsFromAdditionalCodes(additionalCodes);
      promises = [getJobsPromise, getJobsFromAdditionalCodesPromise];
    } else {
      promises = [getJobsPromise];
    }

    BPromise.all(promises)
      .then(([jobArray, jobFromAdditionalCodes]) => {
        jobArray.forEach(job => {
          job.username = oneTimeCode.username;
          job.password = oneTimeCode.password;
        });

        if (isAdditionalCodesExist) {
          jobs = [...jobArray, ...jobFromAdditionalCodes];
        } else {
          jobs = [...jobArray];
        }

        let countPromises = [];
        jobs.forEach(job => {
          const { classname, username, password } = job;
          const filter = getClassFilter(classname);
          const getTicketsCountPromise = getTicketsCount({ username, password }, filter);
          countPromises.push(getTicketsCountPromise);
        });

        return BPromise.all(countPromises);
      })
      .then(res => {
        res.forEach(countObj => {
          counts.push(countObj.count);
        });

        return true;
      })
      .then(() => {
        const dates = Lazy(jobs)
          .map(job => Moment(job.jobDate).format('DD-MM-YYYY'))
          .uniq()
          .toArray();

        const dateContent = {};
        const dateClassContent = {};
        const isDateContentShow = {};
        const isDateClassContentShow = {};
        const dateClassParams = {};

        const jobsWithReducedDate = jobs.map(job => {
          return { ...job, jobDate: Moment(job.jobDate).format('DD-MM-YYYY') };
        });

        jobsWithReducedDate.forEach(job => {
          propz.set(isDateClassContentShow, [job.jobDate, job.classname], false);
          propz.set(dateClassContent, [job.jobDate, job.classname], []);
          propz.set(dateClassParams, [job.jobDate, job.classname], []);
        });

        jobsWithReducedDate.forEach((job, index) => {
          const paramsArray = propz.get(dateClassParams, [job.jobDate, job.classname]);
          propz.set(
            dateClassParams,
            [job.jobDate, job.classname],
            [...paramsArray, { skip: 0, username: job.username, password: job.password, ticketsCount: counts[index] }]
          );
        });

        dates.forEach(date => {
          propz.set(isDateContentShow, [date], true);

          const jobsFiltered = jobsWithReducedDate.filter(job => job.jobDate === date);
          dateContent[date] = Lazy(jobsFiltered)
            .map(job => job.classname)
            .uniq()
            .toArray();
        });

        this.setState({
          dates,
          dateContent,
          dateClassContent,
          isDateContentShow,
          isDateClassContentShow,
          dateClassParams,
          isLoading: false,
        });

        const firstDateClasses = dateContent[dates[0]];
        const isSingleClass = dates.length === 1 && firstDateClasses === 1;

        let totalCount = 0;
        for (let i in counts) {
          totalCount += counts[i];
        }

        if (totalCount <= 10 || isSingleClass) {
          this.downloadAllDateClasses();
        }
      });
  };

  handleSearchChange = event => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.filterDatesBasedOnSearch();
    });
  };

  filterDatesBasedOnSearch = () => {
    const { searchQuery, dateContent } = this.state;
    if (searchQuery === '') {
      this.setInitialDataStructure();
    } else {
      const filteredDates = [];
      for (const date in dateContent) {
        let hasMatchingClass = false;
        for (const classname of dateContent[date]) {
          if (classname.toLowerCase().includes(searchQuery.toLowerCase())) {
            hasMatchingClass = true;
            break;
          }
        }
        if (hasMatchingClass) {
          filteredDates.push(date);
        }
      }
      this.setState({ dates: filteredDates });
    }
  };

  downloadAllDateClasses = () => {
    const { isDateClassContentShow, isDateContentShow, dateClassContent, dateClassParams } = this.state;
    const isDateContentShowUpdated = { ...isDateContentShow };
    const isDateClassContentShowUpdated = { ...isDateClassContentShow };
    const dateClassContentUpdated = { ...dateClassContent };
    const dateClassParamsUpdated = { ...dateClassParams };

    for (let date in isDateContentShowUpdated) {
      isDateContentShowUpdated[date] = true;
    }

    for (let date in isDateClassContentShow) {
      for (let classname in isDateClassContentShow[date]) {
        propz.set(isDateClassContentShowUpdated, [date, classname], true);

        const accessCodeArray = propz.get(dateClassParams, [date, classname]);
        const accessCodeArrayUpdated = accessCodeArray.map(accessCode => (accessCode.skip = 10));

        const filterWithSkip = getClassFilter(classname, 0);
        const { username, password } = accessCodeArray[0];

        getTickets(username, password, filterWithSkip).then(({ data: tickets }) => {
          const ticketsSorted = sortImagesByPhotoType(tickets);
          propz.set(dateClassContentUpdated, [date, classname], ticketsSorted);
          propz.set(dateClassParamsUpdated, [date, classname], accessCodeArrayUpdated);

          this.setState({
            isDateContentShow: isDateContentShowUpdated,
            dateClassContent: dateClassContentUpdated,
            isDateClassContentShow: isDateClassContentShowUpdated,
            dateClassParams: dateClassParamsUpdated,
          });
        });
      }
    }
  };

  onClassClick = (date, classname) => {
    const { isDateClassContentShow, dateClassContent, dateClassParams } = this.state;

    const dateClassParamsUpdated = { ...dateClassParams };
    const isDateClassContentShowUpdated = { ...isDateClassContentShow };
    const dateClassContentUpdated = { ...dateClassContent };
    const isClassContentShow = isDateClassContentShow[date][classname];

    if (isClassContentShow) {
      propz.set(isDateClassContentShowUpdated, [date, classname], false);

      this.setState({
        isDateClassContentShow: isDateClassContentShowUpdated,
      });
    } else {
      propz.set(isDateClassContentShowUpdated, [date, classname], true);
      const isClassContentExist = dateClassContent[date][classname].length !== 0;

      if (isClassContentExist) {
        this.setState({
          isDateClassContentShow: isDateClassContentShowUpdated,
        });
      } else {
        const accessCodeArray = propz.get(dateClassParams, [date, classname]);
        const accessCodeArrayUpdated = [...accessCodeArray];

        const filterWithSkip = getClassFilter(classname, 0);
        const filter = getClassFilter(classname);
        const { username, password } = accessCodeArray[0];

        getTickets(username, password, filterWithSkip).then(({ data: tickets }) => {
          propz.set(accessCodeArrayUpdated[0], ['skip'], LIMIT_DOWNLOAD_IMAGES);

          const ticketsSorted = sortImagesByPhotoType(tickets);
          propz.set(dateClassParamsUpdated, [date, classname], accessCodeArrayUpdated);
          propz.set(dateClassContentUpdated, [date, classname], ticketsSorted);

          this.setState({
            isDateClassContentShow: isDateClassContentShowUpdated,
            dateClassContent: dateClassContentUpdated,
            dateClassParams: dateClassParamsUpdated,
          });
        });
      }
    }
  };

  isLoadMoreExist = (date, classname) => {
    const { dateClassParams } = this.state;
    const accessCodesArray = propz.get(dateClassParams, [date, classname]);
    return accessCodesArray.some(accessCode => accessCode.skip < accessCode.ticketsCount);
  };

  onLoadMoreClick = (date, classname) => {
    const { dateClassParams, dateClassContent } = this.state;

    const dateClassContentUpdated = { ...dateClassContent };
    const dateClassParamsUpdated = { ...dateClassParams };

    const accessCodesArray = propz.get(dateClassParams, [date, classname]);
    const accessCodesArrayUpdated = [...accessCodesArray];

    const accessCode = accessCodesArray.find(accessCode => accessCode.skip < accessCode.ticketsCount);
    const accessCodeIndex = accessCodesArray.findIndex(accessCode => accessCode.skip < accessCode.ticketsCount);

    const { username, password, skip } = accessCode;

    const filterWithSkip = getClassFilter(classname, skip);

    getTickets(username, password, filterWithSkip).then(({ data: tickets }) => {
      const ticketsSorted = sortImagesByPhotoType(tickets);

      const ticketsPrev = propz.get(dateClassContent, [date, classname]);
      propz.set(dateClassContentUpdated, [date, classname], [...ticketsPrev, ...ticketsSorted]);

      accessCodesArrayUpdated[accessCodeIndex].skip = skip + LIMIT_DOWNLOAD_IMAGES;
      propz.set(dateClassParamsUpdated, [date, classname], accessCodesArrayUpdated);

      this.setState({
        dateClassContent: dateClassContentUpdated,
        dateClassParams: dateClassParamsUpdated,
      });
    });
  };

  onNextButtonClick = () => {
    const { history, oneTimeCode, setBasketId, selectedTicketIds } = this.props;
    const { basketId } = oneTimeCode;
    const isSelectedImagesEmpty = selectedTicketIds.length === 0;

    if (!isSelectedImagesEmpty) {
      const galleryImages = selectedTicketIds.map(ticketId => ({
        imageId: ticketId,
      }));

      if (typeof basketId === 'undefined') {
        createBasket(oneTimeCode)
          .then(basket => {
            const updatedOneTimeCode = { ...oneTimeCode, basketId: basket.id };
            return addGalleryToBasket(updatedOneTimeCode, galleryImages);
          })
          .then(basket => {
            setBasketId(basket.id);
            history.push('/photoGallery');
          });
      } else {
        addGalleryToBasket(oneTimeCode, galleryImages).then(() => {
          history.push('/photoGallery');
        });
      }
    } else {
      this.setState({
        isNoSelectedImagesInfo: true,
      });
    }
  };

  onOkWorldPayPaymentSuccessModalClick = () => {
    this.setState({
      isWorldPayPaymentSuccess: false,
    });
  };

  onOkPay360PaymentSuccessModalClick = () => {
    this.setState({
      isPay360PaymentSuccess: false,
    });
  };

  renderWorldPayPaymentSuccessModal() {
    const { isWorldPayPaymentSuccess } = this.state;

    return (
      <SimpleModal
        isOpen={isWorldPayPaymentSuccess}
        title={'Info'}
        body={'Payment success'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkWorldPayPaymentSuccessModalClick}
        customClass={`bOneTimeCodeModal`}
      />
    );
  }

  renderPay360PaymentSuccessModal() {
    const { isPay360PaymentSuccess, orderDetails, orderImages } = this.state;

    return (
      <SimpleModal
        isOpen={isPay360PaymentSuccess}
        title="Payment Successful"
        buttonCancelText="Close"
        onCloseClick={this.onOkPay360PaymentSuccessModalClick}
        customClass="bOneTimeCodeModal"
      >
        <div>
          {orderDetails && orderImages.length ? (
            <>
              <p>
                Your order{' '}
                <span style={{ fontWeight: 'bold', color: '#7d0140' }}>
                  {orderDetails ? orderDetails.orderNumber : ''}
                </span>{' '}
                has been confirmed and payment was successful. The order details are below:
              </p>
              <OneTimeCodeOrderInformation order={orderDetails} orderImages={orderImages} />
            </>
          ) : (
            <p>Your payment has been successful. We will let you know once your order has been dispatched.</p>
          )}
        </div>
      </SimpleModal>
    );
  }

  onOkPaymentCanceledModalClick = () => {
    this.setState({
      isPaymentCanceled: false,
    });
    this.props.history.push('/basket');
  };

  noShowCountDownClock = () => {
    this.setState({
      isShowCountDownClock: false,
    });
  };

  renderPaymentCanceledModal() {
    const { isPaymentCanceled } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentCanceled}
        title={'Info'}
        body={'Payment failed, try again'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkPaymentCanceledModalClick}
        customClass={`bOneTimeCodeModal`}
      />
    );
  }

  noSelectedImagesInfoModalClose = () => {
    this.setState({
      isNoSelectedImagesInfo: false,
    });
  };

  renderNoSelectedImagesInfoModal(): React.ReactNode {
    const { isNoSelectedImagesInfo } = this.state;

    return (
      <SimpleModal isOpen={isNoSelectedImagesInfo}>
        <ConfirmationModal
          text={'Please select the image(s) you wish to add before pressing next.'}
          onOk={this.noSelectedImagesInfoModalClose}
        />
      </SimpleModal>
    );
  }

  onOkPaymentTimeoutModalClick = () => {
    this.setState({
      isPaymentTimeout: false,
    });
    this.props.history.push('/basket');
  };

  renderPaymentTimeoutModal() {
    const { isPaymentTimeout } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentTimeout}
        title={'Info'}
        body={'We were unable to process the payment. Please contact our support team to complete your purchase.'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkPaymentTimeoutModalClick}
        customClass={`bCustomerModal`}
      />
    );
  }

  render() {
    const { onGalleryImageClick, selectedTicketIds } = this.props;

    const {
      isLoading,
      dates,
      dateContent,
      isDateClassContentShow,
      dateClassContent,
      isShowCountDownClock,
      isDateContentShow,
      searchQuery,
    } = this.state;

    const jobSchoolDeliveryDeadlines = [];
    for (let valueClassContent in dateClassContent) {
      const className = dateClassContent[valueClassContent];
      for (let valueClassName in className) {
        className[valueClassName].forEach(ticket => {
          const jobSchoolDeliveryDeadline = propz.get(ticket, ['jobSchoolDeliveryDeadline']);
          const isExistJobSchoolDeliveryDeadline =
            typeof jobSchoolDeliveryDeadline !== 'undefined' && jobSchoolDeliveryDeadline !== '';
          if (isExistJobSchoolDeliveryDeadline) {
            jobSchoolDeliveryDeadlines.push(jobSchoolDeliveryDeadline);
          }
        });
      }
    }

    const isExistJobSchoolDeliveryDeadlines = jobSchoolDeliveryDeadlines.length > 0;
    const minJobSchoolDeliveryDeadlineDate = isExistJobSchoolDeliveryDeadlines
      ? Lazy(jobSchoolDeliveryDeadlines).min()
      : new Date();

    if (isLoading) {
      return <Loader />;
    }

    const filteredDates =
      searchQuery === ''
        ? dates
        : dates.filter(date =>
            dateContent[date].some(classname => classname.toLowerCase().includes(searchQuery.toLowerCase()))
          );

    const hasFilteredPhotos = filteredDates.length > 0 && filteredDates.some(date => dateContent[date].length > 0);

    return (
      <>
        {this.renderPaymentCanceledModal()}
        {this.renderWorldPayPaymentSuccessModal()}
        {this.renderPay360PaymentSuccessModal()}
        {this.renderNoSelectedImagesInfoModal()}
        {this.renderPaymentTimeoutModal()}
        <div className="bOneTimeCodeViewWrapper">
          <div className="container">
            <div className="row">
              <div className={'col-xl-12'}>
                <div className={'bOneTimeCodeView'}>
                  {isShowCountDownClock && isExistJobSchoolDeliveryDeadlines && (
                    <div>
                      <div className={'eCustomerFreeDeliveryTitle'}>Your Free School Delivery Offer expires in:</div>
                      <div className="">
                        <CountDownClock
                          schoolDeliveryDeadlineDate={new Date(minJobSchoolDeliveryDeadlineDate)}
                          noShowCountDownClock={this.noShowCountDownClock}
                        />
                      </div>
                    </div>
                  )}
                  <div className={'eOneTimeCodeViewTitle'}>HOORAY!</div>
                  <div className={'eOneTimeCodeViewText'}>Your photographs are ready</div>
                  <div className={'eOneTimeCodeViewText'}>
                    Please select the images you would like to view / order and press next
                  </div>
                  <div className={'eOneTimeImageCopyrightText'}>
                    We've enhanced our watermarking to prevent unauthorised image use. We apologise for any
                    inconvenience during online viewing; rest assured, your final prints remain pristine and untouched.
                    Thank you for your understanding and support.
                  </div>

                  <div className="search-field">
                    <input
                      type="text"
                      placeholder="Search by Key Words..."
                      onChange={this.handleSearchChange}
                      value={this.state.searchQuery}
                    />
                  </div>

                  <div className="row">
                    <div className={'col-xl-12'}>
                      <div className="eOneTimeCodeViewCardWrapper">
                        {hasFilteredPhotos ? (
                          dates.map((date, dateIndex) => (
                            <div className="card" key={`one_time_code_view_date_${date}_${dateIndex}`}>
                              <div className={isDateContentShow[date] ? 'collapse show' : 'collapse'}>
                                <div className="card-body">
                                  {dateContent[date].map((classname, index) => {
                                    if (classname.toLowerCase().includes(searchQuery.toLowerCase())) {
                                      return (
                                        <div
                                          className="card"
                                          key={`one_time_code_view_classname_${classname}_${index}`}
                                        >
                                          <div
                                            className="card-header"
                                            onClick={() => this.onClassClick(date, classname)}
                                          >
                                            <h5 className="mb-0">
                                              <button className="btn mNoOutline">{classname}</button>
                                            </h5>
                                          </div>

                                          <div
                                            className={
                                              isDateClassContentShow[date][classname] ? 'collapse show' : 'collapse'
                                            }
                                          >
                                            <div className="card-body">
                                              <div className="row">
                                                {dateClassContent[date][classname].map(ticket => {
                                                  const isSelected = selectedTicketIds.indexOf(ticket.id) !== -1;
                                                  return (
                                                    <ImageTicketsOneTimeCodeView
                                                      key={ticket.id}
                                                      ticket={ticket}
                                                      onImageClick={onGalleryImageClick}
                                                      isSelected={isSelected}
                                                    />
                                                  );
                                                })}
                                              </div>

                                              {this.isLoadMoreExist(date, classname) && (
                                                <div
                                                  className={'bLoadMoreButton'}
                                                  onClick={() => this.onLoadMoreClick(date, classname)}
                                                >
                                                  LOAD MORE
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className={'eOneTimeCodeViewText'}>
                            There are no photos matching your search criteria.
                          </div>
                        )}

                        {hasFilteredPhotos && (
                          <button onClick={this.onNextButtonClick} type={'button'} className={'eOneTimeCodeViewButton'}>
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
