import { AppUser } from '../../views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from '../../consts/table';

export function getSchoolUsers(user: AppUser, schoolId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/schoolUsers`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolUsers(user: AppUser, schoolId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/schoolUsers`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUsersCount(user: AppUser, schoolId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/schoolUsers/count`, config).then(response => {
    return response.data;
  });
}

export function addSchoolUsers(user: AppUser, schoolId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schools/${schoolId}/schoolUsers`, data, config).then(response => {
    return response.data;
  });
}

export function updateSchoolUser(user: AppUser, schoolId: string, schoolUserId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/admin/schools/${schoolId}/schoolUsers/${schoolUserId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolUser(user: AppUser, schoolId: string, schoolUserId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/schools/${schoolId}/schoolUsers/${schoolUserId}`, config)
    .then(response => {
      return response.data;
    });
}
