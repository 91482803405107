import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from '../../../../../App';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import { getEmail, searchFunctionCustomerEmails } from '../../../../../../../helpers/autocomplete';
import { useState } from 'react';

interface Props {
  onCancel: () => void;
  onSubmit: (data: { customerId: string; email: string }) => void;
  user: AppUser;
}

const validationSchema = Yup.object().shape({
  customerId: Yup.string().required('Must be valid customer email'),
  email: Yup.string()
    .required('Required')
    .email('Must be valid email'),
});

export function LinkImageForm(props: Props) {
  const { onSubmit, onCancel, user } = props;

  const initialValues = {
    customerId: '',
    email: '',
  };

  const getCustomers = (text: string) => {
    return searchFunctionCustomerEmails(user, text);
  };

  const [item, setItem] = useState(undefined);

  const onSelect = (item, setFieldValue) => {
    setItem(item);
    setFieldValue('customerId', item.id);
    setFieldValue('email', item.email);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Email</label>
                    <Field name="product">
                      {() => (
                        <Autocomplete
                          searchFunction={getCustomers}
                          getElementTitle={getEmail}
                          customClass="mFullWidth mb-3"
                          defaultItem={item}
                          onSelect={customer => {
                            onSelect(customer, setFieldValue);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                    <ErrorMessage component="div" className="alert alert-danger" name="customerId" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Add access code'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
