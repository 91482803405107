import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Profile } from '../../../../../models/profile';
import * as Yup from 'yup';
import { useState } from 'react';
import { loginCustomer } from '../../../../../helpers/auth';
import { EyeImage } from '../../../../../components/EyeImage/EyeImage';

interface Props {
  profile: Profile;
  onSubmit: (data) => void;
  onCancel: () => void;
}

export function CustomerChangePasswordForm(props: Props) {
  const { onSubmit, profile, onCancel } = props;

  const [inputOldPasswordType, changeInputOldPasswordType] = useState('password');
  const [inputPasswordType, changeInputPasswordType] = useState('password');
  const [inputConfirmPasswordType, changeInputConfirmPasswordType] = useState('password');

  const changePasswordInitialValue = {
    password: '',
    confirmPassword: '',
    oldPassword: '',
  };

  const ChangePasswordSchema = Yup.object({
    oldPassword: Yup.string().test('oldPassword', 'Passwords wrong', function(value) {
      if (!Boolean(this.parent.password) && !Boolean(this.parent.confirmPassword)) {
        return true;
      }

      const { email } = profile;
      return loginCustomer(email, value)
        .then(res => true)
        .catch(res => false);
    }),
    password: Yup.string(),
    confirmPassword: Yup.string().test('confirmPassword', 'Passwords must match', function(value) {
      return value === this.parent.password;
    }),
  });

  const onOldPasswordClick = (): void => {
    changeInputOldPasswordType(inputOldPasswordType === 'password' ? 'text' : 'password');
  };

  const onPasswordClick = (): void => {
    changeInputPasswordType(inputPasswordType === 'password' ? 'text' : 'password');
  };

  const onConfirmPasswordClick = (): void => {
    changeInputConfirmPasswordType(inputConfirmPasswordType === 'password' ? 'text' : 'password');
  };

  return (
    <div className="bCustomerMyDetailsForm">
      <Formik
        initialValues={changePasswordInitialValue}
        validationSchema={ChangePasswordSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({}) => (
          <Form>
            <div className="eCustomerMyDetailsFormInputWrapper form-check">
              <div className="eCustomerMyDetailsFormInputPasswordWrapper">
                <label className="mPrimaryText eCustomerMyDetailsFormLabel">Change Password:</label>
                <div className="d-flex justify-content-center w-100">
                  <Field
                    name="oldPassword"
                    type={inputOldPasswordType}
                    className="eCustomerMyDetailsFormInputPassword"
                    placeholder={'Old password'}
                  />
                  <div
                    className="eCustomerMyDetailsFormInputEyePassword"
                    onMouseDown={onOldPasswordClick}
                    onMouseUp={onOldPasswordClick}
                  >
                    {inputOldPasswordType === 'text' ? (
                      <EyeImage eyeWithSlash={false} />
                    ) : (
                      <EyeImage eyeWithSlash={true} />
                    )}
                  </div>
                </div>
                <ErrorMessage component="div" className="alert alert-danger" name="oldPassword" />

                <div className="d-flex justify-content-center w-100">
                  <Field
                    name="password"
                    type={inputPasswordType}
                    className="eCustomerMyDetailsFormInputPassword"
                    placeholder={'New password'}
                  />
                  <div
                    className="eCustomerMyDetailsFormInputEyePassword"
                    onMouseDown={onPasswordClick}
                    onMouseUp={onPasswordClick}
                  >
                    {inputPasswordType === 'text' ? (
                      <EyeImage eyeWithSlash={false} />
                    ) : (
                      <EyeImage eyeWithSlash={true} />
                    )}
                  </div>
                </div>
                <ErrorMessage component="div" className="alert alert-danger" name="password" />

                <div className="d-flex justify-content-center w-100">
                  <Field
                    name="confirmPassword"
                    type={inputConfirmPasswordType}
                    className="eCustomerMyDetailsFormInputPassword"
                    placeholder={'Re-type new password'}
                  />
                  <div
                    className="eCustomerMyDetailsFormInputEyePassword"
                    onMouseDown={onConfirmPasswordClick}
                    onMouseUp={onConfirmPasswordClick}
                  >
                    {inputConfirmPasswordType === 'text' ? (
                      <EyeImage eyeWithSlash={false} />
                    ) : (
                      <EyeImage eyeWithSlash={true} />
                    )}
                  </div>
                </div>
                <ErrorMessage component="div" className="alert alert-danger" name="confirmPassword" />
              </div>

              <button onClick={onCancel} className={'eCustomerMyDetailsFormButtonCancel'}>
                Cancel
              </button>

              <button type={'submit'} className={'eCustomerMyDetailsFormButton'}>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
