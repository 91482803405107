import * as React from 'react';
import { Link } from 'react-router-dom';
import { CartIcon } from './CartIcon/CartIcon';
import { useEffect, useState } from 'react';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { InfoBlockContactUs } from '../Footer/InfoBlock/InfoBlockContactUs';
import { AppOneTimeCode } from '../../AppOrders';
import { History } from 'history';

interface Props {
  basketCount: number;
  isAuthorizedCustomer: boolean;
  isAuthorizedOneTimeCode: boolean;
  logout: () => void;
  oneTimeCode: AppOneTimeCode;
  onProfileClick: (history: History) => void;
  onHomeClick: (history: History) => void;
  history: History;
}

export function Header(props: Props) {
  const {
    basketCount,
    isAuthorizedCustomer,
    isAuthorizedOneTimeCode,
    logout,
    oneTimeCode,
    onProfileClick,
    history,
    onHomeClick,
  } = props;
  const { basketId } = oneTimeCode;

  const [isActiveAccountDropdownMenu, setIsActiveAccountDropdownMenu] = useState(false);

  const closeAccountMenu = () => {
    setIsActiveAccountDropdownMenu(false);
    document.removeEventListener('click', closeAccountMenu);
  };

  const onAccountIconClick = (): void => {
    if (!isActiveAccountDropdownMenu) {
      document.addEventListener('click', closeAccountMenu);
    }
    setIsActiveAccountDropdownMenu(!isActiveAccountDropdownMenu);
  };

  const onLogoutClick = (): void => {
    logout();
  };

  const [isActiveBurgerMenu, setIsActiveBurgerMenu] = useState(false);

  const closeBurgerMenu = (): void => {
    setIsActiveBurgerMenu(false);
    document.removeEventListener('click', closeBurgerMenu);
  };

  const onBurgerIconClick = (): void => {
    if (!isActiveBurgerMenu) {
      document.addEventListener('click', closeBurgerMenu);
    }
    setIsActiveBurgerMenu(!isActiveBurgerMenu);
  };

  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);

  const onContactUsClick = (): void => {
    setIsContactsModalOpen(!isContactsModalOpen);
  };

  const onOkClick = (): void => {
    setIsContactsModalOpen(false);
  };

  const renderContactsModal = () => {
    return (
      <SimpleModal isOpen={isContactsModalOpen} customClass="bContactUsModal">
        <InfoBlockContactUs />
        <div className="eContactUsModalButtonWrapper">
          <button className="eContactUsModalButton" onClick={onOkClick}>
            Ok
          </button>
        </div>
      </SimpleModal>
    );
  };

  const onHomeItemClick = event => {
    onHomeClick(history);
  };

  const onProfileItemClick = event => {
    onProfileClick(history);
  };

  return (
    <div className="bHeaderWrapper">
      {renderContactsModal()}
      <div className="container">
        <div className="row">
          <div className="col-xs-3 mAlignItemCenter">
            <div className="mCursorPointer dropdown">
              <div
                className="bBurgerMenu"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                onClick={onBurgerIconClick}
              >
                {isActiveBurgerMenu ? (
                  <img className="eBurgerMenuBurgerIcon" src="/dist/images/icon/burgerActive.png" />
                ) : (
                  <img className="eBurgerMenuBurgerIcon" src="/dist/images/icon/burger.png" />
                )}
              </div>
              <div className={`${isActiveBurgerMenu ? 'dropdown-menu show' : 'dropdown-menu'}`}>
                <Link className={'dropdown-item'} to={{ pathname: '/' }}>
                  Home
                  <img className="eburgerMenuIcon mPadding7" src="/dist/images/icon/home.png" />
                </Link>

                <Link className={'dropdown-item'} to={{ pathname: '/login' }}>
                  Account
                  <img className="eburgerMenuIcon" src="/dist/images/icon/icon2.png" />
                </Link>

                <div className={'dropdown-item'} onClick={onContactUsClick}>
                  Contact us
                  <img className="eburgerMenuIcon" src="/dist/images/icon/icon1.png" />
                </div>

                <div className="dropdown-divider" />

                <Link className={'dropdown-item mBold'} to={{ pathname: '/products' }}>
                  Products
                </Link>

                <div className="dropdown-divider" />

                <Link className={'dropdown-item mBold'} to={{ pathname: '/accessCode' }}>
                  New Access Code
                </Link>

                <div className="dropdown-divider" />

                <div className={'eburgerMenuTitle mBold'}>My Bentley</div>

                <Link
                  className={
                    isAuthorizedCustomer || isAuthorizedOneTimeCode ? 'dropdown-item disabled' : 'dropdown-item'
                  }
                  to={{ pathname: '/login' }}
                >
                  Log in
                </Link>

                {isAuthorizedCustomer && (
                  <Link className="dropdown-item" to={{ pathname: '/customer' }}>
                    Preview
                  </Link>
                )}

                {isAuthorizedOneTimeCode && (
                  <Link className="dropdown-item" to={{ pathname: '/oneTimeCode' }}>
                    Preview
                  </Link>
                )}

                {!isAuthorizedCustomer && !isAuthorizedOneTimeCode && (
                  <Link className="dropdown-item disabled" to={{ pathname: '/oneTimeCode' }}>
                    Preview
                  </Link>
                )}

                {isAuthorizedCustomer && (
                  <Link className="dropdown-item" to={{ pathname: '/customer/photoGallery' }}>
                    View Gallery
                  </Link>
                )}

                {isAuthorizedOneTimeCode && (
                  <Link
                    className={typeof basketId !== 'undefined' ? 'dropdown-item' : 'dropdown-item disabled'}
                    to={{ pathname: '/photoGallery' }}
                  >
                    View Gallery
                  </Link>
                )}

                {!isAuthorizedOneTimeCode && !isAuthorizedCustomer && (
                  <Link className="dropdown-item disabled" to={{ pathname: '/photoGallery' }}>
                    View Gallery
                  </Link>
                )}
                <div className="dropdown-divider" />
              </div>
            </div>
          </div>
          <div className="col-xs-3 offset-xl-2">
            <Link to={{ pathname: '/' }}>
              <img className="eLogoWrapper" src="/dist/images/icon/bentleyLogo.png" />
            </Link>
          </div>
          <div className="col-xs-3 offset-xl-2 eInputSearchWrapper">
            <input type="text" placeholder="Search" className="eInputSearch" />
          </div>
          <div className="col-xs-3 offset-xl-2 mAlignItemCenterAndRight">
            <div className="">
              <div className="eIcons">
                <CartIcon
                  basketCount={basketCount}
                  isAuthorizedCustomer={isAuthorizedCustomer}
                  isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                />
                <div className="dropdown">
                  <div role="button" id="dropdownMenuLink" data-toggle="dropdown">
                    <img className="" src="/dist/images/icon/icon2.png" onClick={onAccountIconClick} />
                  </div>
                  <div className={isActiveAccountDropdownMenu ? 'dropdown-menu show' : 'dropdown-menu'}>
                    {isAuthorizedCustomer && (
                      <>
                        <div className="dropdown-item" onClick={onProfileItemClick}>
                          Profile
                        </div>
                      </>
                    )}
                    <div className="dropdown-item" onClick={onLogoutClick}>
                      Sign out
                    </div>
                  </div>
                </div>
                <Link to={{ pathname: '/' }}>
                  <img className="" src="/dist/images/icon/icon1.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
