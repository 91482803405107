import * as React from 'react';
import * as Yup from 'yup';
import * as BPromise from 'bluebird';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Component } from 'react';
import { School } from 'Src/models/schools';
import { AppCustomer } from '../../../AppOrders';
import { getSchoolByParentCode } from '../../../../../services/customer/schools';
import { checkValidParentCode } from '../../../../../services/public/register';
import { getFromHistory } from '../../../../../helpers/history';
import { History } from 'history';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  customer: AppCustomer;
  history: History;
}

interface State {
  school: School;
  parentCode: string;
  isError: boolean;
}

export class CustomerAddStudentForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    const { history } = props;
    const schoolCode = getFromHistory(history, 'schoolCode');
    const isSchoolCodeExist = typeof schoolCode !== 'undefined';

    this.state = {
      school: undefined,
      parentCode: isSchoolCodeExist ? schoolCode : '',
      isError: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const schoolCode = getFromHistory(history, 'schoolCode');
    const isSchoolCodeExist = typeof schoolCode !== 'undefined';
    if (isSchoolCodeExist) {
      this.onNextClick();
    }
  }

  onSchoolCodeChange = event => {
    const value = event.target.value;
    this.setState({
      parentCode: value,
      isError: false,
    });
  };

  onNextClick = () => {
    const { parentCode } = this.state;
    const { customer } = this.props;

    checkValidParentCode({ parentCode })
      .then(res => {
        const { isValid } = res;

        return isValid ? getSchoolByParentCode(customer, parentCode) : BPromise.resolve(undefined);
      })
      .then(school => {
        if (typeof school !== 'undefined') {
          this.setState({
            school: school,
          });
        } else {
          this.setState({
            isError: true,
          });
        }
      });
  };

  render() {
    const { onSubmit, onCancel, history } = this.props;
    const { school, parentCode, isError } = this.state;

    const CustomerStudentSchema = Yup.object().shape({
      formId: Yup.string().required('Required'),
      forename: Yup.string().required('Required'),
      surname: Yup.string().required('Required'),
    });

    const customerStudentForm = {
      formId: '',
      forename: '',
      surname: '',
    };

    const isSchoolExist = typeof school !== 'undefined';

    return (
      <div className={'mt-3'}>
        <div className="form-group form-check">
          <div className="form-group">
            <label>Enter Unique School Code</label>
            <input
              className="form-control"
              placeholder="Unique School Code"
              value={parentCode}
              onChange={this.onSchoolCodeChange}
              disabled={isSchoolExist}
            />
          </div>
          {isError && <div className="alert alert-danger">Not found</div>}
          <Button
            onClick={onCancel}
            text={'Cancel'}
            customClass={'mt-3 mb-3 mr-3 btn-secondary'}
            disabled={isSchoolExist}
          />
          <Button
            onClick={this.onNextClick}
            text={'Next'}
            customClass={'mt-3 mb-3 mr-3 btn-primary'}
            disabled={isSchoolExist}
          />
        </div>

        {isSchoolExist && (
          <Formik
            initialValues={customerStudentForm}
            validationSchema={CustomerStudentSchema}
            onSubmit={values => {
              onSubmit({ ...values, schoolId: school.id });
              history.push({
                search: `schoolCode=${parentCode}`,
              });
            }}
          >
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <div className="form-group">
                      <label>Form</label>
                      <Field name={`formId`} component="select" className="form-control mb-3">
                        <option value={''} />
                        {school.forms.map(form => (
                          <option key={form.id} value={form.id}>
                            {form.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="formId" />
                    </div>

                    <label>Forename</label>
                    <Field name="forename" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="forename" />
                  </div>

                  <div className="form-group">
                    <label>Surname</label>
                    <Field name="surname" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="surname" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  }
}
