import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';

export function getUsers(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/users`, config).then(response => {
    return response.data;
  });
}

export function getAllUsers(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/users`, config).then(response => {
    return response.data;
  });
}

export function getUsersCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/users/count`, config).then(response => {
    return response.data;
  });
}

export function addUser(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/users`, data, config).then(response => {
    return response.data;
  });
}

export function updateUser(user: AppUser, userId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/users/${userId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteUser(user: AppUser, userId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/users/${userId}`, config).then(response => {
    return response.data;
  });
}

export function getUser(key: string, userId: string) {
  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/users/${userId}`, config).then(response => {
    return response.data;
  });
}

export function checkAdminSession(key: string) {
  const config = {
    headers: { usid: key },
  };

  return axios
    .get(`${window.apiBase}/admin/check`, config)
    .then(response => {
      return response.status;
    })
    .catch(error => {
      return error.response.status;
    });
}
