import * as React from 'react';

export function InfoBlockTerms() {
  return (
    <div className="pt-5">
      <div className="bFAQsSubTitle">terms & conditions</div>
      <div className="bInfoBlockText">
        <strong>Payment</strong>
        <br />
        All orders must be paid for in advance. Payments can only be made using most debit cards, Visa or Mastercard.
        All prices are in pounds sterling and VAT is included at the standard rate, where applicable.
      </div>
      <div className="bInfoBlockText">
        <strong>Delivery</strong>
        <br />
        When your order is received, we will aim dispatch it within 7-10 days. Please allow 28 days at busy periods.
      </div>
      <div className="bInfoBlockText">
        <strong>Handling Charge</strong>
        <br />
        All orders will incur a small charge towards the cost of postage and packing at the checkout. Unless paperwork
        with your proof card states otherwise.
      </div>
      <div className="bInfoBlockText">
        <strong>Returning products</strong>
        <br />
        It's not possible to return any kind of images and claim a refund, since all products are individually
        manufactured products. On all other products, the customer has 14 days with full right to return the goods.
        Please return the item to the address below via recorded delivery including the invoice and a small note
        explaining the reason of return. FAULTY GOODS HAVE TO BE RETURNED WITHIN 14 DAYS. As soon as we receive the item
        and it has been checked we will either refund your card or send a replacement. We apologise for the
        inconvenience, if you have any problems please do not hesitate to contact us.
      </div>
      <div className="bInfoBlockText">
        <strong>Complaints</strong>
        <br />
        Complaints must be declared directly to Bentley Photographic within 3 working days after receipt. For your order
        to be rectified the complete order MUST be returned.
      </div>
      <div className="bInfoBlockText">
        <strong>Credit Card information</strong>
        <br />
        Bentley Photographic does not hold any information regarding card number, expiry date or card security code.
      </div>
      <div className="bInfoBlockText">
        <strong>Personal information</strong>
        <br />
        Bentley Photographic will not sell, distribute or disclose information about you as an individual or your
        personal usage of the site without your consent or unless required to do so by law. The Information we gather
        may include name, delivery address, email address, telephone number, and any personal preferences.
      </div>
      <div className="bInfoBlockText">
        <strong>Promotions</strong>
        <br />
        Periodically during the year we may email you specific offers that we think that may be of interest to you and
        that relate to images that you hold in your account. These offers will be only from Bentley Photographic and you
        can opt out of receiving these at any time.
      </div>
      <div className="bInfoBlockText">
        <strong>Accepting terms & conditions</strong>
        <br />
        By using our site, it is deemed that you have read and accepted our terms and conditions.
      </div>
    </div>
  );
}
