import { useEffect } from 'react';
import * as React from 'react';
import { PAY360_RESULT } from '../../../consts/payment';
import { parse } from 'query-string';
import { getConfig } from '../../../services/public/config';
import { getOrderBySessionId } from '../../../services/public/order';
import { Loader } from '../../../components/Loader/Loader';
import { createBrokenOrder, deleteBrokenOrder, updateBrokenOrder } from '../../../services/public/brokenOrder';

function AllPaymentsSuccessIframe(props) {
  useEffect(() => {
    const { history } = props;
    const search = parse(history.location.search);
    const sessionId = search.sessionId as string;

    let initialTimer = null;
    let maximumTimer = null;
    let timer = null;

    createBrokenOrder(sessionId)
      .then(() => getConfig())
      .then(res => {
        const { payments } = res;
        const { pay360 } = payments;
        const { pay360InitialWaitingTime, pay360MaximumWaitingTime, pay360AdditionalWaitingTime } = pay360;

        maximumTimer = setTimeout(() => {
          updateBrokenOrder(sessionId).then(() => {
            window.top.postMessage(PAY360_RESULT.TIMEOUT, '*');
          });
        }, pay360MaximumWaitingTime);

        initialTimer = setTimeout(() => {
          getOrderBySessionId(sessionId).then(res => {
            const { orderStatus } = res;
            switch (orderStatus) {
              case PAY360_RESULT.PAID:
                deleteBrokenOrder(sessionId).then(() => {
                  window.top.postMessage(PAY360_RESULT.PAID, '*');
                });
                break;
              case PAY360_RESULT.CANCELED:
                deleteBrokenOrder(sessionId).then(() => {
                  window.top.postMessage(PAY360_RESULT.CANCELED, '*');
                });
                break;
              default: {
                timer = setInterval(() => {
                  getOrderBySessionId(sessionId).then(res => {
                    const { orderStatus } = res;
                    switch (orderStatus) {
                      case PAY360_RESULT.PAID:
                        deleteBrokenOrder(sessionId).then(() => {
                          window.top.postMessage(PAY360_RESULT.PAID, '*');
                        });
                        break;
                      case PAY360_RESULT.CANCELED:
                        deleteBrokenOrder(sessionId).then(() => {
                          window.top.postMessage(PAY360_RESULT.CANCELED, '*');
                        });
                    }
                  });
                }, pay360AdditionalWaitingTime);
              }
            }
          });
        }, pay360InitialWaitingTime);
      });
  }, []);

  return (
    <div className={'mt-3'}>
      <Loader />
      <div className={'text-center'}>Waiting for payment authorization...</div>
    </div>
  );
}

export default AllPaymentsSuccessIframe;
