import * as React from 'react';
import { Component } from 'react';
import * as moment from 'moment';
import './CountDownClock.scss';

interface Props {
  schoolDeliveryDeadlineDate: any;
  noShowCountDownClock?: () => void;
}

interface State {
  schoolDeliveryDeadlineDate: any;
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
}

export class CountDownClock extends Component<Props, State> {
  timerId: any;

  constructor(props) {
    super(props);
    const schoolDeliveryDeadlineDate =
      typeof props.schoolDeliveryDeadlineDate !== 'undefined' ? props.schoolDeliveryDeadlineDate : moment();

    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      schoolDeliveryDeadlineDate: schoolDeliveryDeadlineDate,
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  tick() {
    const { schoolDeliveryDeadlineDate } = this.state;
    const now = new Date();
    const schoolDeliveryDeadline = new Date(schoolDeliveryDeadlineDate);

    //ms
    const diff = Number(schoolDeliveryDeadline) - Number(now);

    const MS_IN_DAY = 1000 * 60 * 60 * 24;
    const MS_IN_HOUR = 1000 * 60 * 60;
    const MS_IN_MINUTE = 1000 * 60;
    const MS_IN_SECOND = 1000;

    const days = Math.floor(diff / MS_IN_DAY);
    const hours = Math.floor((diff - days * MS_IN_DAY) / MS_IN_HOUR);
    const minutes = Math.floor((diff - days * MS_IN_DAY - hours * MS_IN_HOUR) / MS_IN_MINUTE);
    const seconds = Math.floor((diff - days * MS_IN_DAY - hours * MS_IN_HOUR - minutes * MS_IN_MINUTE) / MS_IN_SECOND);

    this.setState({
      days,
      hours,
      minutes,
      seconds,
    });
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;

    const secondSignSecond = seconds >= 10 ? seconds % 10 : seconds;
    const firstSignSecond = seconds >= 10 ? Math.floor(seconds / 10) : 0;
    const firstSignMinutes = minutes >= 10 ? Math.floor(minutes / 10) : 0;
    const secondSignMinutes = minutes >= 10 ? minutes % 10 : minutes;
    const firstSignHour = hours >= 10 ? Math.floor(hours / 10) : 0;
    const secondSignHour = hours >= 10 ? hours % 10 : hours;

    const isExistThirdSignDay = days >= 100;
    const isExistSecondSignDay = days >= 10;
    const thirdSignDay = isExistThirdSignDay ? Math.floor(days / 100) : 0;
    const firstAndSecondSignDay = isExistThirdSignDay ? days - thirdSignDay * 100 : 0;

    let secondSignDay;
    switch (true) {
      case isExistThirdSignDay:
        secondSignDay = Math.floor(firstAndSecondSignDay / 10);
        break;
      case isExistSecondSignDay:
        secondSignDay = Math.floor(days / 10);
        break;
      default:
        secondSignDay = 0;
    }

    let firstSignDay;
    switch (true) {
      case isExistThirdSignDay:
        firstSignDay = firstAndSecondSignDay - secondSignDay * 10;
        break;
      case isExistSecondSignDay:
        firstSignDay = days % 10;
        break;
      default:
        firstSignDay = days;
    }

    return (
      <div className="d-flex justify-content-center">
        <div className="">
          <div className="d-flex ml-2 mr-2">
            {!isExistSecondSignDay && (
              <>
                <div className="eCountDownClockNumberBlock">{secondSignDay}</div>
                <div className="eCountDownClockNumberBlock">{firstSignDay}</div>
              </>
            )}
            {!isExistThirdSignDay && isExistSecondSignDay && (
              <>
                <div className="eCountDownClockNumberBlock">{secondSignDay}</div>
                <div className="eCountDownClockNumberBlock">{firstSignDay}</div>
              </>
            )}
            {isExistThirdSignDay && (
              <>
                <div className="eCountDownClockNumberBlock">{thirdSignDay}</div>
                <div className="eCountDownClockNumberBlock">{secondSignDay}</div>
                <div className="eCountDownClockNumberBlock">{firstSignDay}</div>
              </>
            )}
          </div>
          <div className="eCountDownClockPeriodTitle">Days</div>
        </div>

        <div className="">
          <div className="d-flex ml-2 mr-2">
            <div className="eCountDownClockNumberBlock">{firstSignHour}</div>
            <div className="eCountDownClockNumberBlock">{secondSignHour}</div>
          </div>
          <div className="eCountDownClockPeriodTitle">Hours</div>
        </div>

        <div className="">
          <div className="d-flex ml-2 mr-2">
            <div className="eCountDownClockNumberBlock">{firstSignMinutes}</div>
            <div className="eCountDownClockNumberBlock">{secondSignMinutes}</div>
          </div>
          <div className="eCountDownClockPeriodTitle">Minutes</div>
        </div>

        <div className="">
          <div className="d-flex ml-2 mr-2">
            <div className="eCountDownClockNumberBlock">{firstSignSecond}</div>
            <div className="eCountDownClockNumberBlock">{secondSignSecond}</div>
          </div>
          <div className="eCountDownClockPeriodTitle">Seconds</div>
        </div>
      </div>
    );
  }
}
