import * as React from 'react';

interface Props {
  onUsernameChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onFormSubmit: (event: React.SyntheticEvent) => void;
  username: string;
  password: string;
}

export function CustomerAccessCodeForm(props: Props) {
  const { onUsernameChange, onPasswordChange, onFormSubmit, username, password } = props;
  return (
    <div className={'bCustomerAccessCode'}>
      <form onSubmit={onFormSubmit} className="">
        <div className={'eCustomerAccessCodeTitle'}>New Access Code</div>
        <div className={'eCustomerAccessCodeInputWrapper'}>
          <input
            type="text"
            className={'eCustomerAccessCodeInput'}
            placeholder={'Access code 1'}
            value={username}
            onChange={onUsernameChange}
          />
        </div>
        <div className={'eCustomerAccessCodeInputWrapper'}>
          <input
            type="password"
            className={'eCustomerAccessCodeInput'}
            placeholder={'Access code 2'}
            value={password}
            onChange={onPasswordChange}
          />
        </div>
        <button className="eCustomerAccessCodeButton" type="submit">
          Add
        </button>
      </form>
    </div>
  );
}
