import * as React from 'react';
import { Autocomplete } from '../../../../../../../../components/Autocomplete/Autocomplete';
import { getEmail, searchFunctionCustomerEmails } from '../../../../../../../../helpers/autocomplete';
import { AppUser } from '../../../../../../App';
import { useState } from 'react';

interface Props {
  onEmailChange: (email: string) => void;
  wizardEmail: string;
  user: AppUser;
  isEmailError: boolean;
}

function ImageWizardEmail({ onEmailChange, wizardEmail, user, isEmailError }: Props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control mb-3"
                onChange={event => onEmailChange(event.target.value)}
                value={wizardEmail}
              />
              {isEmailError && <div className={'alert alert-danger'}>Must be valid email</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageWizardEmail;
