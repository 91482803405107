import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT } from '../../consts/table';

export function getOrderNotes(user: AppUser, orderId: string, filter?) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/notes`, config).then(response => {
    return response.data;
  });
}

export function getAllOrderNotes(user: AppUser, orderId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/notes`, config).then(response => {
    return response.data;
  });
}

export function getOrderNotesCount(user: AppUser, orderId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/notes/count`, config).then(response => {
    return response.data;
  });
}

export function addOrderNote(user: AppUser, orderId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/orders/${orderId}/notes`, data, config).then(response => {
    return response.data;
  });
}

export function updateOrderNote(user: AppUser, orderId: string, noteId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/orders/${orderId}/notes/${noteId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteOrderNote(user: AppUser, orderId: string, noteId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/orders/${orderId}/notes/${noteId}`, config).then(response => {
    return response.data;
  });
}
