import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../components/Button/Button';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .test(
      'password',
      'Password must contain at least one capital, lower case character, one number and must be 8 digits long',
      value => {
        const passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/);

        return value && passwordRegExp.test(value);
      }
    ),
  confirmPassword: Yup.string().test('confirmPassword', 'Passwords must match', function(value) {
    return value === this.parent.password;
  }),
  termsAndConditionsAgreement: Yup.boolean().test(
    'termsAndConditionsAgreement',
    'Select the tick box to continue',
    value => {
      return value;
    }
  ),
});

interface Props {
  onSubmit: (data) => void;
  redirectToLoginPage: () => void;
}

export function ChangePasswordForm(props: Props) {
  const { onSubmit } = props;

  const changePasswordForm = {
    password: '',
    confirmPassword: '',
    termsAndConditionsAgreement: false,
    promoEmailAgreement: false,
  };

  return (
    <Formik initialValues={changePasswordForm} validationSchema={ChangePasswordSchema} onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue }) => (
        <Form>
          <div className={'bResetPasswordFormTitle'}>Please enter new password</div>
          <div className="form-group form-check">
            <div className="form-group">
              <Field type={'password'} name="password" className="eResetPasswordFormInput" />
              <ErrorMessage component="div" className="alert alert-danger eResetPasswordError" name="password" />
            </div>
          </div>
          <div className={'bResetPasswordFormTitle'}>Confirm password</div>
          <div className="form-group form-check">
            <div className="form-group">
              <Field type={'password'} name="confirmPassword" className="eResetPasswordFormInput" />
              <ErrorMessage component="div" className="alert alert-danger eResetPasswordError" name="confirmPassword" />
            </div>
          </div>

          <div className="form-group form-check">
            <div className="form-group">
              <Checkbox
                customClass="eResetPasswordCheckbox"
                name="termsAndConditionsAgreement"
                onChange={event => {
                  setFieldValue('termsAndConditionsAgreement', event.target.checked);
                }}
              >
                {'I have read and agree to the '}
                <a className="bLinkText" href="/termsAndConditions" target="_blank">
                  Terms and Conditions
                </a>
              </Checkbox>
              <ErrorMessage
                component="div"
                className="alert alert-danger eResetPasswordError"
                name="termsAndConditionsAgreement"
              />
            </div>
          </div>

          <div className="form-group form-check">
            <div className="form-group">
              <Checkbox
                customClass="eResetPasswordCheckbox"
                name="promoEmailAgreement"
                onChange={event => {
                  setFieldValue('promoEmailAgreement', event.target.checked);
                }}
              >
                I agree to receive exciting personalised offers
              </Checkbox>
            </div>
          </div>

          <Button
            onClick={handleSubmit}
            text={'Save'}
            customClass={'mt-3 mb-3 eResetPasswordFormButton'}
            type={'submit'}
          />
        </Form>
      )}
    </Formik>
  );
}
