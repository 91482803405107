import * as React from 'react';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from '../../../../../App';
import { Component } from 'react';
import { Loader } from '../../../../../../../components/Loader/Loader';
import { syncUploadCsvStudents } from '../../../../../../../services/superadmin/csv';
import { CSV_TYPE } from '../../../../../../../consts/csv';
import { School } from '../../../../../../../models/schools';

interface Props {
  onCancel: () => void;
  onShowStatusUploadImages: (isErrorStatus, data) => void;
  user: AppUser;
  school: School;
}

interface State {
  file: any;
  isDisabledUploadButton: boolean;
  isLoading: boolean;
}

export class SchoolUploadStudentsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: undefined,
      isDisabledUploadButton: true,
      isLoading: false,
    };
  }

  onChooseCSVFile = event => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    this.setState({
      file: formData,
      isDisabledUploadButton: false,
    });
  };

  onSyncUploadCSVChange = (): void => {
    const { user, school } = this.props;
    const { file } = this.state;
    this.setState({
      isDisabledUploadButton: true,
      isLoading: true,
    });
    let isErrorStatus;
    const uploadType = CSV_TYPE.STUDENTS_IMPORT;

    syncUploadCsvStudents(user, school.id, file, uploadType)
      .then(data => {
        this.setState({
          isLoading: false,
        });
        isErrorStatus = false;
        this.props.onShowStatusUploadImages(isErrorStatus, data);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
        isErrorStatus = true;
        this.props.onShowStatusUploadImages(isErrorStatus, error);
        console.error('error');
        console.error(error);
      });
  };

  render() {
    const { isDisabledUploadButton, isLoading } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="attachment">Selected CSV file</label>
              <input
                type="file"
                className="form-control-file"
                id="csv"
                disabled={isLoading}
                onChange={event => {
                  this.onChooseCSVFile(event);
                }}
              />
            </div>
            <div className="form-group">{isLoading && <Loader />}</div>
            <Button onClick={this.props.onCancel} text={'Close'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button
              onClick={() => {
                this.onSyncUploadCSVChange();
              }}
              text={'Upload'}
              customClass={'mt-3 mb-3 mr-3 btn btn-primary'}
              disabled={isDisabledUploadButton}
            />
          </div>
        </div>
      </div>
    );
  }
}
