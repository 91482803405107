import * as React from 'react';
import { FunctionComponent } from 'react';
import './GenericSummary.css';
import { Tabs } from './Tabs/Tabs';

interface Props {
  currentTabIndex: number;
  onTabClick: (event, index: number) => void;
  tabs: string[];
  customClass?: string;
}

export const GenericSummary: FunctionComponent<Props> = props => {
  const { currentTabIndex, onTabClick, tabs, customClass } = props;

  return (
    <div>
      <Tabs currentTabIndex={currentTabIndex} onTabClick={onTabClick} tabs={tabs} customClass={customClass} />
      <div>{props.children}</div>
    </div>
  );
};

GenericSummary.displayName = 'GenericSummary';
