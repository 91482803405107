import * as React from 'react';
import * as Yup from 'yup';
import { Customer, CustomerStudent } from 'Src/models/customers';
import { AppUser } from 'Src/views/App/App';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import {
  getForenameAndSurname,
  getName,
  getSchoolNameAndCode,
  searchFunctionForms,
  searchFunctionSchoolsByCode,
  searchFunctionStudentsBySchoolAndForm,
} from 'Src/helpers/autocomplete';
import { Component } from 'react';
import { School, SchoolForm } from 'Src/models/schools';
import { Student } from 'Src/models/students';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  user: AppUser;
  customer: Customer;
}

interface State {
  school: School;
  form: SchoolForm;
  student: Student;
}

export class CustomerStudentForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      school: undefined,
      form: undefined,
      student: undefined,
    };
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    return searchFunctionSchoolsByCode(user, text);
  };

  getForms = (text: string) => {
    const { user } = this.props;
    const { school } = this.state;
    return searchFunctionForms(user, school.id, text);
  };

  getStudents = (text: string) => {
    const { user, customer } = this.props;
    const { school, form } = this.state;
    return searchFunctionStudentsBySchoolAndForm(user, school.id, form.id, text, customer);
  };

  render() {
    const { onSubmit, onCancel } = this.props;

    const CustomerStudentSchema = Yup.object().shape({
      schoolId: Yup.string().required('Required'),
      formId: Yup.string().required('Required'),
      studentId: Yup.string().required('Required'),
    });

    const customerStudentForm = {
      schoolId: '',
      formId: '',
      studentId: '',
    };

    const { school, form, student } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik initialValues={customerStudentForm} validationSchema={CustomerStudentSchema} onSubmit={onSubmit}>
              {({ handleSubmit, setFieldValue }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>School</label>
                      <Field name="schoolId">
                        {() => (
                          <Autocomplete
                            searchFunction={this.getSchools}
                            getElementTitle={getSchoolNameAndCode}
                            customClass="mFullWidth mb-3"
                            defaultItem={school}
                            onSelect={school => {
                              setFieldValue('schoolId', school.id);
                              setFieldValue('formId', '');
                              setFieldValue('studentId', '');
                              this.setState({
                                school: school,
                                form: undefined,
                                student: undefined,
                              });
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="schoolId" />
                    </div>

                    <div className="form-group">
                      <label>Form</label>
                      <Field name="formId">
                        {() => (
                          <Autocomplete
                            searchFunction={this.getForms}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={form}
                            onSelect={form => {
                              setFieldValue('formId', form.id);
                              setFieldValue('studentId', '');
                              this.setState({
                                form: form,
                                student: undefined,
                              });
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="formId" />
                    </div>

                    <div className="form-group">
                      <label>Student</label>
                      <Field name="studentId">
                        {() => (
                          <Autocomplete
                            searchFunction={this.getStudents}
                            getElementTitle={getForenameAndSurname}
                            customClass="mFullWidth mb-3"
                            defaultItem={student}
                            onSelect={student => {
                              setFieldValue('studentId', student.id);
                              this.setState({
                                student: student,
                              });
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="studentId" />
                    </div>

                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
