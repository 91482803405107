export const PROFILE_TABS = [
  'My details',
  'Address book',
  'Order history',
  'Subscriptions',
  'Students',
  'Verification',
];

export const PROFILE_CUSTOMER_TABS = {
  ADDRESS_BOOK: 'Address book',
  MY_DETAILS: 'My details',
  ORDER_HISTORY: 'Order history',
  SUBSCRIPTIONS: 'Subscriptions',
  STUDENTS: 'Students',
  VERIFICATION: 'Verification',
};
