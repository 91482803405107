import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import {
  getCreatedAtWithTime,
  getIntegrationLogStatus,
  getUpdatedAtWithTime,
} from '../../../../../../helpers/accessor';

import { IntegrationLog } from '../../../../../../models/integrationLogs';
import {
  getIntegrationLogs,
  getIntegrationLogsCount,
  getAllIntegrationLogs,
} from '../../../../../../services/superadmin/integrationLog';
import { getSelectOptionForStatusIntegrationLog } from '../../../../../../helpers/select';
import { CSV_SUBTYPE, CSV_TYPE } from '../../../../../../consts/csv';
import { asyncUploadCsv } from '../../../../../../services/superadmin/csv';
import * as propz from 'propz';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Job type',
    field: 'jobType',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['jobType'],
  },
  {
    text: 'Job Reference',
    field: 'targetReference',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['targetReference'],
  },
  {
    text: 'Status',
    field: 'integrationLogStatus',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getIntegrationLogStatus,
  },
  {
    text: 'Result',
    field: 'result',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    isShort: true,
    accessor: ['result'],
  },
  {
    text: 'User',
    field: 'user',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    isShort: true,
    accessor: ['user'],
  },
  {
    text: 'Create at',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAtWithTime,
  },
  {
    text: 'Update at',
    field: 'updatedAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getUpdatedAtWithTime,
  },
  {
    text: 'Data file',
    field: 'dataFile',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['dataFile'],
  },
  {
    text: 'Log file',
    field: 'logFile',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['logFile'],
  },
];

interface State {
  items: IntegrationLog[];
  currentPage: number;
  selectedItems: IntegrationLog[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isInfoModalOpen: boolean;
  infoMessage: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class IntegrationLogs extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isInfoModalOpen: false,
      infoMessage: '',
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getIntegrationLogs(user, serverQueryFilter);
    const getItemsCountPromise = getIntegrationLogsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllIntegrationLogs(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  onOkInfoModalClick = () => {
    this.setState({
      isInfoModalOpen: false,
      infoMessage: '',
      isLoading: true,
    });
    this.setItems();
  };

  onAsyncUploadChange = (uploadType, uploadSubType, actionText: string): void => {
    const { user } = this.props;
    this.setState({
      isLoading: true,
    });
    const fullActionText = `${actionText} is queued. Refresh web page to see updates`;

    asyncUploadCsv(user, uploadType, uploadSubType)
      .then(data => {
        this.setState({
          isLoading: false,
          infoMessage: fullActionText,
          isInfoModalOpen: true,
        });
      })
      .catch(error => {
        const errorText = JSON.stringify(propz.get(error, ['response'], ''));

        this.setState({
          isLoading: false,
        });
        console.error('errorText');
        console.error(errorText);
      });
  };

  renderInfoModal() {
    const { isInfoModalOpen, infoMessage } = this.state;

    return (
      <SimpleModal
        isOpen={isInfoModalOpen}
        title={'Info'}
        body={infoMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkInfoModalClick}
      />
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isInfoModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const actionItems = [
      {
        itemText: 'Upload the last Pre Registrations',
        onItemClick: () => {
          this.onAsyncUploadChange(
            CSV_TYPE.S3_PRE_REGISTRATIONS,
            CSV_SUBTYPE.LAST,
            'Upload the last Pre Registrations'
          );
        },
        isActive: true,
      },
      {
        itemText: 'Upload all Pre Registrations',
        onItemClick: () => {
          this.onAsyncUploadChange(CSV_TYPE.S3_PRE_REGISTRATIONS, CSV_SUBTYPE.ALL, 'Upload all Pre Registrations');
        },
        isActive: true,
      },
      {
        itemText: 'Upload the last Images',
        onItemClick: () => {
          this.onAsyncUploadChange(CSV_TYPE.S3_JOBS, CSV_SUBTYPE.LAST, 'Upload the last Images');
        },
        isActive: true,
      },
      {
        itemText: 'Upload all Images',
        onItemClick: () => {
          this.onAsyncUploadChange(CSV_TYPE.S3_JOBS, CSV_SUBTYPE.ALL, 'Upload all Images');
        },
        isActive: true,
      },
      {
        itemText: 'Download all orders',
        onItemClick: () => {
          this.onAsyncUploadChange(CSV_TYPE.S3_ORDERS, CSV_SUBTYPE.ALL, 'Download all orders');
        },
        isActive: true,
      },
      {
        itemText: 'Upload last order status changes',
        onItemClick: () => {
          this.onAsyncUploadChange(
            CSV_TYPE.S3_ORDERS_IMPORT_STATUS,
            CSV_SUBTYPE.LAST,
            'Upload last order status changes'
          );
        },
        isActive: true,
      },
      {
        itemText: 'Upload all order status changes',
        onItemClick: () => {
          this.onAsyncUploadChange(
            CSV_TYPE.S3_ORDERS_IMPORT_STATUS,
            CSV_SUBTYPE.ALL,
            'Upload all order status changes'
          );
        },
        isActive: true,
      },
      {
        itemText: 'Upload last downloadable images',
        onItemClick: () => {
          this.onAsyncUploadChange(
            CSV_TYPE.S3_ORDERS_IMPORT_IMAGES,
            CSV_SUBTYPE.LAST,
            'Upload last order status changes'
          );
        },
        isActive: true,
      },
      {
        itemText: 'Upload all downloadable images',
        onItemClick: () => {
          this.onAsyncUploadChange(
            CSV_TYPE.S3_ORDERS_IMPORT_IMAGES,
            CSV_SUBTYPE.ALL,
            'Upload all order status changes'
          );
        },
        isActive: true,
      },
      {
        itemText: 'Upload last school job',
        onItemClick: () => {
          this.onAsyncUploadChange(CSV_TYPE.S3_SCHOOL_JOBS, CSV_SUBTYPE.LAST, 'Upload all order status changes');
        },
        isActive: true,
      },
      {
        itemText: 'Upload all school jobs',
        onItemClick: () => {
          this.onAsyncUploadChange(CSV_TYPE.S3_SCHOOL_JOBS, CSV_SUBTYPE.ALL, 'Upload all order status changes');
        },
        isActive: true,
      },
    ];
    const filterOptions = {
      integrationLogStatus: getSelectOptionForStatusIntegrationLog(),
    };

    const classes = isInfoModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderInfoModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              isSelectAllDisabled={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
