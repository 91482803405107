export const SIZE_IMAGE_FOR_VIEWING = {
  HEIGHT: '160px',
};

export const IMAGE_TYPE = {
  PANORAMIC: 'Panoramic',
  INDIVIDUAL: 'Individual',
  GROUP: 'Group',
};

export const PHOTO_TYPES_FILTER = {
  ALL: 'All',
  PANORAMICS: 'Panoramics',
  INDIVIDUALS: 'Individuals',
  GROUPS: 'Groups',
};

export const YEAR_FILTER_WHERE_PARAM = {
  All: {
    START: '2009-12-31T18:00:00.000Z',
    END: '2051-12-31T18:00:00.000Z',
  },
  '2018': {
    START: '2017-12-31T18:00:00.000Z',
    END: '2018-12-31T18:00:00.000Z',
  },
  '2019': {
    START: '2018-12-31T18:00:00.000Z',
    END: '2019-12-31T18:00:00.000Z',
  },
  '2020': {
    START: '2019-12-31T18:00:00.000Z',
    END: '2020-12-31T18:00:00.000Z',
  },
  '2021': {
    START: '2020-12-31T18:00:00.000Z',
    END: '2021-12-31T18:00:00.000Z',
  },
  '2022': {
    START: '2021-12-31T18:00:00.000Z',
    END: '2022-12-31T18:00:00.000Z',
  },
  '2023': {
    START: '2022-12-31T18:00:00.000Z',
    END: '2023-12-31T18:00:00.000Z',
  },
  '2024': {
    START: '2023-12-31T18:00:00.000Z',
    END: '2024-12-31T18:00:00.000Z',
  },
};

export const YEAR_FILTER = ['All', '2018', '2019', '2020', '2021', '2022', '2023', '2024'];

export const SIZE_UPLOAD_SLIDER_IMAGES = {
  MAIN_SLIDER: '1110 x 440',
  OFFER_OF_MONTH: '1110 x 130',
  PROMOTED_PRODUCTS: '340 x 340',
  PRODUCTS: '0 x 0',
};

export const LIMIT_DOWNLOAD_IMAGES = 50;

export const IMAGE_TABS = ['Summary'];
