import * as React from 'react';
import * as propz from 'propz';
import { CURRENCY_SYMBOL } from '../../../../../consts/common';
import { Order } from '../../../../../models/orders';
import { OrderImage } from '../../../../../models/orderImages';

interface Props {
  order: Order;
  orderImages: OrderImage[];
}

export function CustomerProfileOrderInformation(props: Props) {
  const { orderImages, order } = props;

  const rows = orderImages.map(image => {
    const productName = propz.get(image, ['product', 'name'], '');
    const productQuantity = propz.get(image, ['quantity'], '');
    const productPrice = propz.get(image, ['product', 'priceSetPrice'], '');

    return (
      <tr key={`customer_profile_order_information_product_${image.id}`}>
        <td>{productName}</td>
        <td>{productQuantity}</td>
        <td>
          {CURRENCY_SYMBOL.POUND} {productPrice}
        </td>
      </tr>
    );
  });

  const { totalWithoutSpecialOffers, deliveryAmount, specialOffersSaving, voucherSaving, orderAmount } = order;

  return (
    <table className="table table-bordered">
      <thead>
        <tr key="customer_profile_order_information_head">
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>

      <tbody>{rows}</tbody>

      <tfoot className="font-weight-bold">
        <tr key="customer_profile_order_information_subtotal">
          <td colSpan={2}>Subtotal:</td>
          <td>
            {CURRENCY_SYMBOL.POUND} {totalWithoutSpecialOffers}
          </td>
        </tr>
        <tr key="customer_profile_order_information_shipping">
          <td colSpan={2}>Shipping:</td>
          <td>
            {CURRENCY_SYMBOL.POUND} {deliveryAmount}
          </td>
        </tr>
        {specialOffersSaving !== 0 && (
          <tr key="customer_profile_order_information_offer_saving">
            <td colSpan={2}>Special offer saving:</td>
            <td>
              {CURRENCY_SYMBOL.POUND} {specialOffersSaving}
            </td>
          </tr>
        )}
        {voucherSaving !== 0 && (
          <tr key="customer_profile_order_information_voucher_saving">
            <td colSpan={2}>Voucher saving:</td>
            <td>
              {CURRENCY_SYMBOL.POUND} {voucherSaving}
            </td>
          </tr>
        )}
        <tr key="customer_profile_order_information_total">
          <td colSpan={2}>Total:</td>
          <td>
            {CURRENCY_SYMBOL.POUND} {orderAmount}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
