import * as React from 'react';
import { Component } from 'react';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { CustomNotificationModal } from '../CustomNotificationModal/CustomNotificationModal';
import { CustomNotificationTextForm } from '../CustomNotificationTextForm/CustomNotificationTextForm';
import { GeneralMessagePreviewWithTabs } from '../CustomNotificationPreview/CustomNotificationPreviewWithTabs';

interface Props {
  onCancelClick: () => void;
  onSendNotificationModalClick: () => void;
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (body: any) => void;
  onSmsTextChange: (event) => void;
  onPushTitleChange: (event) => void;
  onPushBodyChange: (event) => void;
  onIsEditorChange: (type: string, value: boolean) => void;
  onRemoveAttachment: (event) => void;
  onEmailAttachmentChange: (event) => void;
  isOpen: boolean;
  isError: boolean;
  isLoadingFile: boolean;

  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
  messagePushTitle: string;
  messagePushBody: string;

  isEmailEditorEnabled: boolean;
  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;

  isRawEditor: boolean;
  onRawEditorChange: (event) => void;
}

interface State {
  currentStep: number;
}

const CUSTOM_NOTIFICATION_MODAL_TOTAL_STEP: number = 2;

export class CustomNotificationWizzard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentStep: 1,
    };
  }

  // step callbacks
  onNextClick = (): void => {
    this.setState(prevState => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  onPreviousClick = (): void => {
    const { currentStep } = this.state;

    if (currentStep === 1) {
      return;
    }

    this.setState({
      currentStep: currentStep - 1,
    });
  };

  getProgress(current: number, total: number): number {
    return (current / total) * 100;
  }

  renderViewByStep(): React.ReactNode {
    const { currentStep } = this.state;
    const {
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      isPushEditorEnabled,
      isSmsEditorEnabled,
      isEmailEditorEnabled,
      messageAttachments,
      isError,
      isLoadingFile,
      onEmailSubjectChange,
      onEmailBodyChange,
      onSmsTextChange,
      onPushTitleChange,
      onPushBodyChange,
      onIsEditorChange,
      onRemoveAttachment,
      onEmailAttachmentChange,
      isRawEditor,
      onRawEditorChange,
    } = this.props;
    switch (currentStep) {
      case 1:
        return (
          <CustomNotificationTextForm
            onEmailSubjectChange={onEmailSubjectChange}
            onEmailBodyChange={onEmailBodyChange}
            onSmsTextChange={onSmsTextChange}
            onPushTitleChange={onPushTitleChange}
            onPushBodyChange={onPushBodyChange}
            onIsEditorChange={onIsEditorChange}
            onRemoveAttachment={onRemoveAttachment}
            onEmailAttachmentChange={onEmailAttachmentChange}
            messageSmsText={messageSmsText}
            messageEmailSubject={messageEmailSubject}
            messageEmailBody={messageEmailBody}
            messageAttachments={messageAttachments}
            messagePushTitle={messagePushTitle}
            messagePushBody={messagePushBody}
            isEmailEditorEnabled={isEmailEditorEnabled}
            isSmsEditorEnabled={isSmsEditorEnabled}
            isPushEditorEnabled={isPushEditorEnabled}
            isError={isError}
            isLoadingFile={isLoadingFile}
            isRawEditor={isRawEditor}
            onRawEditorChange={onRawEditorChange}
          />
        );
      case 2:
        return (
          <GeneralMessagePreviewWithTabs
            messageSmsText={messageSmsText}
            messageEmailSubject={messageEmailSubject}
            messageEmailBody={messageEmailBody}
            messagePushTitle={messagePushTitle}
            messagePushBody={messagePushBody}
            messageAttachments={messageAttachments}
          />
        );
    }
  }

  render() {
    const {
      isOpen,
      isEmailEditorEnabled,
      isPushEditorEnabled,
      isSmsEditorEnabled,
      isLoadingFile,
      onCancelClick,
      onSendNotificationModalClick,
    } = this.props;
    const { currentStep } = this.state;
    const isActiveSendButton = isEmailEditorEnabled || isPushEditorEnabled || isSmsEditorEnabled;
    return (
      <CustomNotificationModal
        isOpen={isOpen}
        title={'New message'}
        closeButtonText={'Close'}
        submitButtonText={'Next step'}
        onCloseClick={onCancelClick}
        onPreviousClick={this.onPreviousClick}
        onNextClick={this.onNextClick}
        onSendClick={onSendNotificationModalClick}
        hasNext={currentStep !== CUSTOM_NOTIFICATION_MODAL_TOTAL_STEP}
        hasPrevious={currentStep > 1}
        isActiveSendButton={isActiveSendButton && !isLoadingFile}
      >
        <ProgressBar
          progress={this.getProgress(currentStep, CUSTOM_NOTIFICATION_MODAL_TOTAL_STEP)}
          step={currentStep}
          stepTotal={CUSTOM_NOTIFICATION_MODAL_TOTAL_STEP}
        />
        {this.renderViewByStep()}
      </CustomNotificationModal>
    );
  }
}
