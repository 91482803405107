import axios from 'axios';

export function createBrokenOrder(sessionId: string) {
  return axios.post(`${window.apiBase}/public/brokenOrders`, { payment360SessionId: sessionId }).then(response => {
    return response.data;
  });
}

export function updateBrokenOrder(sessionId: string) {
  return axios.put(`${window.apiBase}/public/brokenOrders/${sessionId}`, {}).then(response => {
    return response.data;
  });
}

export function deleteBrokenOrder(sessionId: string) {
  return axios.delete(`${window.apiBase}/public/brokenOrders/${sessionId}`).then(response => {
    return response.data;
  });
}
