import * as React from 'react';
import './CookiesAgreementModal.scss';

interface Props {
  onOk: () => void;
}

export function CookiesAgreementModal(props: Props) {
  const { onOk } = props;
  return (
    <div className="bCookiesModal">
      <div className="eCookiesModalText">
        We use cookies to provide you the best experience on our Website. By continuing to browse our Website, you are
        agreeing to our use of cookies.
        <br />
        <br />
        Please read our{' '}
        <a href="/dist/images/BentleyPhotographic_CookiePolicy.pdf" download>
          cookie policy
        </a>{' '}
        to find out more.
      </div>
      <div className="eVoucherFormButtonWrapper">
        <button className="eCookiesModalButton" onClick={onOk}>
          Ok
        </button>
      </div>
    </div>
  );
}
