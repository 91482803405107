import * as React from 'react';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrdersSiteLink } from '../../../helpers/link';
import './PromotedProductsSlider.css';
import { IMAGES_COUNT_IN_SLIDER_FRAME } from '../../../consts/slider';
import { FONT_COLOR } from '../../../consts/fontColor';

interface Props {
  images: any[];
  delay: number;
}

interface State {
  timerId: any;
  currentFrameIndex: number;
}

export class PromotedProductsSlider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      timerId: undefined,
      currentFrameIndex: 0,
    };
  }

  componentDidMount() {
    const timerId = setInterval(() => {
      this.toggleNextFrame();
    }, this.props.delay);

    this.setState({ timerId });
  }

  componentWillUnmount() {
    if (this.state.timerId) {
      clearInterval(this.state.timerId);
    }
  }

  getFrames() {
    const { images } = this.props;
    const imagesCount = images.length;

    return images.map((image, index) => {
      if (index === imagesCount - 2) {
        return [image, images[index + 1], images[0]];
      }
      if (index === imagesCount - 1) {
        return [image, images[0], images[1]];
      }

      return [image, images[index + 1], images[index + 2]];
    });
  }

  getImages() {
    const { images } = this.props;

    if (images.length <= IMAGES_COUNT_IN_SLIDER_FRAME.PROMOTED_PRODUCTS) {
      const singleFrame = images.map((image, index) => {
        const isLinkExist = image.link !== '' && typeof image.link !== 'undefined';
        return isLinkExist ? (
          <a href={`${getOrdersSiteLink()}${image.link}`} key={`image_${index}`} target="_blank">
            <img src={image.pic} alt="slide" />
          </a>
        ) : (
          <img src={image.pic} alt="slide" />
        );
      });

      return <div className="row justify-content-center">{singleFrame}</div>;
    }

    const frames = this.getFrames();

    return frames.map((frame, index) => {
      const isShowing = this.state.currentFrameIndex === index ? ' mShowing' : '';
      const isFirstImageLinkExist = frame[0].link !== '' && typeof frame[0].link !== 'undefined';
      const isSecondImageLinkExist = frame[1].link !== '' && typeof frame[1].link !== 'undefined';
      const isThirdImageLinkExist = frame[2].link !== '' && typeof frame[2].link !== 'undefined';

      return (
        <div key={`promoted_products_slider_${index}`} style={{ position: 'relative' }}>
          {isFirstImageLinkExist ? (
            <a href={`${getOrdersSiteLink()}${frame[0].link}`} target="_blank">
              <img
                className={'ePromotedProductsSliderFrameItem mTransitionImage mFirst' + isShowing}
                src={frame[0].pic}
                alt="slide"
              />
            </a>
          ) : (
            <img
              className={'ePromotedProductsSliderFrameItem mTransitionImage mFirst' + isShowing}
              src={frame[0].pic}
              alt="slide"
            />
          )}
          {isSecondImageLinkExist ? (
            <a href={`${getOrdersSiteLink()}${frame[1].link}`} target="_blank">
              <img
                className={'ePromotedProductsSliderFrameItem mTransitionImage mSecond' + isShowing}
                src={frame[1].pic}
                alt="slide"
              />
            </a>
          ) : (
            <img
              className={'ePromotedProductsSliderFrameItem mTransitionImage mSecond' + isShowing}
              src={frame[1].pic}
              alt="slide"
            />
          )}
          {isThirdImageLinkExist ? (
            <a href={`${getOrdersSiteLink()}${frame[2].link}`} target="_blank">
              <img
                className={'ePromotedProductsSliderFrameItem mTransitionImage mThird' + isShowing}
                src={frame[2].pic}
                alt="slide"
              />
            </a>
          ) : (
            <img
              className={'ePromotedProductsSliderFrameItem mTransitionImage mThird' + isShowing}
              src={frame[2].pic}
              alt="slide"
            />
          )}
        </div>
      );
    });
  }

  toggleNextFrame = () => {
    const previousFrameIndex = this.state.currentFrameIndex;
    const totalFramesCount = this.props.images.length;
    const nextFrameIndex = (previousFrameIndex + 1) % totalFramesCount;
    this.setState({ currentFrameIndex: nextFrameIndex });
  };

  togglePreviousFrame = () => {
    let currentFrameIndex = this.state.currentFrameIndex;
    if (currentFrameIndex === 0) {
      currentFrameIndex = this.props.images.length;
    }
    const totalFramesCount = this.props.images.length;
    const previousFrameIndex = (currentFrameIndex - 1) % totalFramesCount;

    this.setState({ currentFrameIndex: previousFrameIndex });
  };

  render() {
    const { images } = this.props;
    const isSingleFrameSlider = images.length <= IMAGES_COUNT_IN_SLIDER_FRAME.PROMOTED_PRODUCTS;
    return (
      <div className="bPromotedProductsSliderWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              {images.length === 0 ? null : (
                <div className="bPromotedProductsSlider">
                  <div className="ePromotedProductsSliderContainer">
                    {this.getImages()}
                    {!isSingleFrameSlider && (
                      <>
                        <button className="ePromotedProductsSliderArrowPrevious" onClick={this.togglePreviousFrame}>
                          <FontAwesomeIcon size={'5x'} icon="chevron-left" color={FONT_COLOR.MAIN_COLOR} />
                        </button>
                        <button className="ePromotedProductsSliderArrowNext" onClick={this.toggleNextFrame}>
                          <FontAwesomeIcon size={'5x'} icon="chevron-right" color={FONT_COLOR.MAIN_COLOR} />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
