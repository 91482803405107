import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function getCurrencyMask(rawValue) {
  let numDecimals = 2;
  let numberMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: true,
    integerLimit: 3, // limit length of integer numbers
    allowDecimal: true,
    requireDecimal: true,
    allowLeadingZeroes: false,
  });
  let mask = numberMask(rawValue);

  let decimalsRegex = /\.([0-9]{1,2})/;
  let result = decimalsRegex.exec(rawValue);
  // In case there is only one decimal
  let numZerosToAdd = 0;

  switch (true) {
    case result && result[1].length < numDecimals:
      numZerosToAdd = numDecimals - result[1].length;
      break;
    case !result:
      numZerosToAdd = numDecimals;
      break;
  }

  for (let i = 0; i < numZerosToAdd; i++) {
    mask.push('0');
  }

  return mask;
}
