export const SLIDER_TABS = ['Summary'];

export const SLIDER_TYPES = {
  MAIN_SLIDER: 'MAIN_SLIDER',
  OFFER_OF_MONTH: 'OFFER_OF_MONTH',
  PROMOTED_PRODUCTS: 'PROMOTED_PRODUCTS',
  PRODUCTS: 'PRODUCTS',
};

export const SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING = {
  MAIN_SLIDER: 'Main slider',
  OFFER_OF_MONTH: 'Offer of month',
  PROMOTED_PRODUCTS: 'Promoted products',
  PRODUCTS: 'Products',
};

export const IMAGES_COUNT_IN_SLIDER_FRAME = {
  MAIN_SLIDER: 1,
  OFFER_OF_MONTH: 1,
  PROMOTED_PRODUCTS: 3,
};
