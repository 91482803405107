import * as React from 'react';
import * as Yup from 'yup';
import * as Promise from 'bluebird';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { OrderImage } from '../../../../../../../../models/orderImages';
import { ImageWithTicket, JobImage } from '../../../../../../../../models/images';
import { AppUser } from '../../../../../../App';
import { getOrderImagesByUsernameAndPassword } from '../../../../../../../../services/superadmin/orderImages';
import { getPackage } from '../../../../../../../../services/superadmin/packages';
import { Package } from '../../../../../../../../models/packages';
import { createTicket } from '../../../../../../../../services/superadmin/image';
import { Switch } from '../../../../../../../../components/Switch/Switch';
import { Autocomplete } from '../../../../../../../../components/Autocomplete/Autocomplete';
import { getBulkImageId } from '../../../../../../../../helpers/autocomplete';
import { LIMIT } from 'Src/consts/table';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  orderImage?: OrderImage;
  user: AppUser;
}

interface State {
  username: string;
  password: string;
  allImages: JobImage[];
  imagesToDisplay: ImageWithTicket[];
  packageItem: Package;
}

const OrderImageSchema = Yup.object().shape({
  image: Yup.object().shape({
    imageId: Yup.string().required('Required'),
  }),
  package: Yup.object().shape({
    packageId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
  priceSet: Yup.object().shape({
    priceSetId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
  product: Yup.object().shape({
    productId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
  quantity: Yup.number()
    .min(1)
    .required('Required'),
});

export class OrderImageForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      allImages: [],
      imagesToDisplay: [],
      packageItem: undefined,
    };
  }

  componentDidMount() {
    const { orderImage, user } = this.props;
    const isOrderImageExist = typeof orderImage !== 'undefined';

    if (isOrderImageExist) {
      const packageId = orderImage.package.packageId;
      getPackage(user, packageId).then(packageItem => {
        this.setState({
          packageItem,
        });
      });
    }
  }

  onUsernameChange = event => {
    const value = event.target.value;
    this.setState({
      username: value,
      allImages: [],
    });
  };

  onPasswordChange = event => {
    const value = event.target.value;
    this.setState({
      password: value,
      allImages: [],
    });
  };

  loadImages = () => {
    const { username, password } = this.state;
    const { user } = this.props;

    let allImages;
    let imagesToDisplay;

    getOrderImagesByUsernameAndPassword(user, username, password)
      .then(_images => {
        allImages = _images;
        imagesToDisplay = allImages.slice(0, LIMIT);

        return Promise.all(
          imagesToDisplay.map(image => {
            const data = {
              key: image.key,
              mimeType: image.mimeType,
            };

            return createTicket(user, data);
          })
        );
      })
      .then(tickets => {
        const imagesExtended = imagesToDisplay.map((image, index) => ({
          ...image,
          ticket: tickets[index],
        }));
        this.setState({
          imagesToDisplay: imagesExtended,
          allImages,
        });
      });
  };

  getImages = (text: string) => {
    const { allImages } = this.state;
    const isTextExist = text !== '' && typeof text !== 'undefined';

    if (isTextExist) {
      const result = allImages.filter(image => image.bulkImageId.includes(text));
      return result.slice(0, 10);
    } else {
      return allImages.slice(0, 10);
    }
  };

  render() {
    const { onSubmit, onCancel, orderImage, user } = this.props;
    const { username, password, imagesToDisplay, packageItem } = this.state;
    const isOrderImageExist = typeof orderImage !== 'undefined';

    const orderImageForm = {
      image: {
        imageId: isOrderImageExist ? orderImage.image.imageId : '',
        fileName: isOrderImageExist ? orderImage.image.fileName : '',
        bulkImageId: '',
      },
      package: {
        packageId: isOrderImageExist ? orderImage.package.packageId : '',
        name: isOrderImageExist ? orderImage.package.name : '',
      },
      priceSet: {
        priceSetId: isOrderImageExist ? orderImage.priceSet.priceSetId : '',
        name: isOrderImageExist ? orderImage.priceSet.name : '',
      },
      product: {
        productId: isOrderImageExist ? orderImage.product.productId : '',
        name: isOrderImageExist ? orderImage.product.name : '',
      },
      quantity: isOrderImageExist ? orderImage.quantity : 0,
      isIncludedInReprint: isOrderImageExist ? orderImage.isIncludedInReprint : false,
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            {!isOrderImageExist && (
              <div className="form-group form-check">
                <div className="form-group">
                  <label>Username</label>
                  <input
                    className="form-control"
                    placeholder="User name"
                    value={username}
                    onChange={this.onUsernameChange}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={this.onPasswordChange}
                  />
                </div>
                <Button onClick={this.loadImages} text={'Load images'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
              </div>
            )}

            {imagesToDisplay.length > 0 && (
              <div className={'row'}>
                {imagesToDisplay.map(image => (
                  <div className={'col-md-3'}>
                    <img
                      className="img-fluid img-thumbnail"
                      src={`${window.apiFile}/storage/images/${image.ticket.ticket}`}
                    />
                    <div>{image.fileName}</div>
                  </div>
                ))}
              </div>
            )}

            <Formik initialValues={orderImageForm} validationSchema={OrderImageSchema} onSubmit={onSubmit}>
              {({ handleSubmit, setFieldValue, values }) => (
                <Form>
                  <div className="form-group form-check">
                    {!isOrderImageExist && (
                      <div className="form-group">
                        <label>Image</label>
                        <Field name="image.imageId">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getImages}
                              getElementTitle={getBulkImageId}
                              customClass="mFullWidth mb-3"
                              defaultItem={values.image.imageId}
                              disabled={imagesToDisplay.length === 0}
                              onSelect={image => {
                                const { packageId } = image.package;
                                getPackage(user, packageId).then(packageItem => {
                                  this.setState({
                                    packageItem: packageItem,
                                  });
                                  setFieldValue('image.imageId', image.id);
                                  setFieldValue('image.bulkImageId', image.bulkImageId);
                                  setFieldValue('image.fileName', image.fileName);
                                  setFieldValue('package.packageId', packageItem.id);
                                  setFieldValue('package.name', packageItem.name);
                                  setFieldValue('priceSet.priceSetId', image.priceSet.priceSetId);
                                  setFieldValue('priceSet.name', image.priceSet.name);
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name="image.imageId" />
                      </div>
                    )}

                    <div className="form-group">
                      <label>Product</label>
                      <Field
                        name="product.productId"
                        component="select"
                        disabled={typeof packageItem === 'undefined'}
                        className="form-control mb-3"
                        onChange={event => {
                          const productId = event.target.value;
                          const product = packageItem.products.find(p => p.productId === productId);
                          setFieldValue('product.productId', product.productId);
                          setFieldValue('product.name', product.name);
                        }}
                      >
                        <option value={''} />
                        {typeof packageItem !== 'undefined' &&
                          packageItem.products.map(product => (
                            <option key={product.productId} value={product.productId}>
                              {product.name}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="product.productId" />
                    </div>

                    <div className="form-group">
                      <label>Price set</label>
                      <Field
                        name="priceSet.priceSetId"
                        component="select"
                        disabled={true}
                        className="form-control mb-3"
                      >
                        <option value={values.priceSet.priceSetId}>{values.priceSet.name}</option>
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="priceSet.priceSetId" />
                    </div>

                    <div className="form-group">
                      <label>Quantity</label>
                      <Field name="quantity" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="quantity" />
                    </div>
                    <div className="form-group">
                      <Field name="isIncludedInReprint">
                        {({ field }) => (
                          <Switch
                            {...field}
                            onChange={event => {
                              setFieldValue('isIncludedInReprint', event.target.checked);
                            }}
                            text={'Included in reprint'}
                            customClass="mb-3"
                          />
                        )}
                      </Field>
                    </div>
                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
