import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT, LIMIT } from '../../consts/table';

export function getOrderImages(user: AppUser, orderId: string, filter?) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/images`, config).then(response => {
    return response.data;
  });
}

export function getAllOrderImages(user: AppUser, orderId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/images`, config).then(response => {
    return response.data;
  });
}

export function getOrderImagesCount(user: AppUser, orderId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/images/count`, config).then(response => {
    return response.data;
  });
}

export function addOrderImage(user: AppUser, orderId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/orders/${orderId}/images`, data, config).then(response => {
    return response.data;
  });
}

export function updateOrderImage(user: AppUser, orderId: string, imageId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/orders/${orderId}/images/${imageId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteOrderImage(user: AppUser, orderId: string, imageId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/orders/${orderId}/images/${imageId}`, config).then(response => {
    return response.data;
  });
}

export function getOrderImagesByUsernameAndPassword(user: AppUser, username: string, password: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          username: username,
          password: password,
        },
        limit: ALL_LIMIT,
      },
    },
  };

  return axios.get(`${window.apiBase}/admin/images`, config).then(response => {
    return response.data;
  });
}

export function getImageDetailsById(user: AppUser, imageId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/images/${imageId}`, config).then(response => response.data);
}
