import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';
import { ALL_LIMIT } from '../../consts/table';

export function getCustomerTickets(customer: AppCustomer, filter) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/newTickets`, config).then(response => {
    return response.data;
  });
}

export function getTicketsCount(customer: AppCustomer, filter?) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/newTickets/count`, config).then(response => {
    return response.data;
  });
}

export function getStudentTickets(customer: AppCustomer, studentId) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
    params: {
      limit: ALL_LIMIT,
      skip: 0,
    },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/students/${studentId}/images`, config).then(response => {
    return response.data;
  });
}

export function getStudentTicketsCount(customer: AppCustomer, studentId) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
    params: {
      limit: ALL_LIMIT,
      skip: 0,
    },
  };

  return axios
    .get(`${window.apiBase}/customer/${customerId}/students/${studentId}/images/count`, config)
    .then(response => {
      return response.data;
    });
}
