import * as React from 'react';
import { Component } from 'react';
import * as Promise from 'bluebird';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { AppUser } from 'Src/views/App/App';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { OrderImage } from '../../../../../../models/orderImages';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { searchFunctionSchools, getName, searchFunctionProducts } from '../../../../../../helpers/autocomplete';
import {
  getOrderImages,
  getOrderImagesCount,
  getAllOrderImages,
} from '../../../../../../services/superadmin/allOrderImages';
import { CURRENCY_SYMBOL } from '../../../../../../consts/common';
import { getCreatedAt } from '../../../../../../helpers/accessor';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Order date',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAt,
  },
  {
    text: 'Order number',
    field: 'orderImagesOrderNumber',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['order', 'orderNumber'],
  },
  {
    text: 'School',
    field: 'orderImagesSchoolName',
    isSort: true,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['school', 'name'],
  },
  {
    text: 'Job',
    field: 'jobReferenceImages',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['job', 'jobReference'],
  },
  {
    text: 'Product name',
    field: 'productName',
    isSort: true,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['product', 'name'],
  },
  {
    text: 'Price',
    field: 'productPrice',
    isSort: true,
    type: FILTER_TYPE.NUMBER_INTERVAL,
    accessor: ['product', 'price'],
  },
  {
    text: 'Quantity',
    field: 'quantity',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: ['quantity'],
  },
  {
    text: 'File name',
    field: 'image.fileName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['image', 'fileName'],
  },
];

interface State {
  items: OrderImage[];
  currentPage: number;
  selectedItems: OrderImage[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  currentTabIndex: number;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isResultDownloadModalOpen: boolean;
  isUploadCSVFormModalOpen: boolean;
  resultDownloadText: string;
  autocompleteDefaultTexts: any;
  autocompleteSearchFunctions: any;
  autocompleteGetElementsTitles: any;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class AllOrderImages extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isUploadCSVFormModalOpen: false,
      currentTabIndex: 0,
      isErrorModalOpen: false,
      errorMessage: '',
      isResultDownloadModalOpen: false,
      resultDownloadText: '',
      autocompleteDefaultTexts: {
        orderImagesSchoolName: '',
        productName: '',
      },
      autocompleteSearchFunctions: {
        orderImagesSchoolName: this.getSchools,
        productName: this.getProducts,
      },
      autocompleteGetElementsTitles: {
        orderImagesSchoolName: getName,
        productName: getName,
      },
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    return searchFunctionSchools(user, text);
  };

  getProducts = (text: string) => {
    const { user } = this.props;
    return searchFunctionProducts(user, text);
  };

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getOrderImages(user, serverQueryFilter);
    const getItemsCountPromise = getOrderImagesCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
        autocompleteDefaultTexts: {
          orderImagesSchoolName: filters.orderImagesSchoolName,
          productName: filters.productName,
        },
      });
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllOrderImages(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onTableAutocompleteFilterChange = (item, filterField: string): void => {
    let filterFieldUpdated;

    switch (filterField) {
      case 'orderImagesSchoolName':
        filterFieldUpdated = 'name';
        break;
      case 'productName':
        filterFieldUpdated = 'name';
        break;
      default:
        filterFieldUpdated = filterField;
    }

    const filterValue = item[filterFieldUpdated];
    const filters = this.state.filters;

    let nextFilters = { ...filters };

    nextFilters = {
      ...nextFilters,
      [filterField]: filterValue,
    };

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
      autocompleteDefaultTexts: {
        name: '',
      },
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      isLoading,
      autocompleteSearchFunctions,
      autocompleteGetElementsTitles,
      autocompleteDefaultTexts,
    } = this.state;

    const { user } = this.props;

    const actionItems = [];

    const classes = 'mt-3';

    const sumFields = [
      {
        field: 'quantity',
        text: `Total images:`,
      },
      {
        field: 'amount',
        text: `Total amount: ${CURRENCY_SYMBOL.POUND}`,
      },
    ];

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className={classes}>
        <div className="row">
          <div className="col-md-12">
            <Grid
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              autocompleteSearchFunctions={autocompleteSearchFunctions}
              autocompleteGetElementsTitles={autocompleteGetElementsTitles}
              onTableAutocompleteFilterChange={this.onTableAutocompleteFilterChange}
              autocompleteDefaultTexts={autocompleteDefaultTexts}
              user={user}
              sumFields={sumFields}
            />
          </div>
        </div>
      </div>
    );
  }
}
