import * as React from 'react';
import { FunctionComponent } from 'react';
import { GRID_MAX_TEXT_LENGTH } from '../../../consts/grid';

interface Props {
  text: string;
  isShort?: boolean;
}

export const TextCell: FunctionComponent<Props> = props => {
  const { text, isShort } = props;

  if (!isShort) {
    return <td>{text}</td>;
  }

  const textLength = text.length;
  return textLength > GRID_MAX_TEXT_LENGTH ? (
    <td>
      <span className={'bTooltip'} data-description={text}>{`${text.slice(0, 27)}...`}</span>
    </td>
  ) : (
    <td>{text}</td>
  );
};

TextCell.displayName = 'TextCell';
