import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './views/App/App';
import { BrowserRouter } from 'react-router-dom';
import { apiSelector, parseDomainName } from './helpers/api';
import { AppOrders } from './views/AppOrders/AppOrders';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { AppSchools2 } from './views/AppSchools2/AppSchools2';
import Payment from './views/Payment/Payment';

const myDomain = document.location.hostname;
const api = apiSelector(myDomain);
const { model } = parseDomainName(myDomain);

declare global {
  interface Window {
    apiBase: string;
    apiImg: string;
    apiFile: string;
    apiSquad: string;
  }
}

window.apiBase = api.main;
window.apiImg = api.img;
window.apiFile = api.file;
window.apiSquad = api.squad;

switch (model) {
  case 'orders':
    ReactDOM.render(
      <BrowserRouter>
        <ScrollToTop />
        <AppOrders />
      </BrowserRouter>,
      document.getElementById('app')
    );
    break;
  case 'schools':
    ReactDOM.render(
      <BrowserRouter>
        <AppSchools2 />
      </BrowserRouter>,
      document.getElementById('app')
    );
    break;
  case 'payment':
    ReactDOM.render(
      <BrowserRouter>
        <Payment />
      </BrowserRouter>,
      document.getElementById('app')
    );

    break;
  default:
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById('app')
    );
}
