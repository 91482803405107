export function isStage(domainName: string): boolean {
  return domainName.includes('stage');
}
export function isLocal(domainName: string): boolean {
  return domainName.includes('local');
}
export function isDev(domainName: string): boolean {
  return domainName.includes('dev');
}
export function isPreprod(domainName: string): boolean {
  return domainName.includes('preprod');
}

export function isPreprod2(domainName: string): boolean {
  return domainName.includes('preprod2');
}
