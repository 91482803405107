import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT } from '../../consts/table';

export function getImages(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/images`, config).then(response => {
    return response.data;
  });
}

export function getAllImages(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/images`, config).then(response => {
    return response.data;
  });
}

export function getImagesCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/images/count`, config).then(response => {
    return response.data;
  });
}

export function deleteImages(user: AppUser, imageIds) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    data: { imageIds },
  };

  return axios.delete(`${window.apiBase}/admin/images`, config).then(response => {
    return response.data;
  });
}
