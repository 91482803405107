import * as React from 'react';
import { FunctionComponent } from 'react';
import './DateIntervalField.css';
import { DATE_INTERVAL } from 'Src/consts/table';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import * as Moment from 'moment';

interface Props {
  onTableFilterChange: (event, value: string, options?) => void;
  field: string;
  from: string;
  to: string;
  onEnterPress?: (event) => void;
}

export const DateIntervalField: FunctionComponent<Props> = props => {
  const { onEnterPress } = props;

  return (
    <div className={'mMinWidth170 mMaxWidth250'}>
      <div className="input-group mMarginBottom4">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth60">From:</span>
        </div>
        <DatePicker
          selected={Moment(props.from, 'DD-MM-YYYY').isValid() ? Moment(props.from, 'DD-MM-YYYY').toDate() : null}
          onChange={date => {
            props.onTableFilterChange(
              { target: { value: Moment(date).isValid() ? Moment(date).format('DD-MM-YYYY') : '' } },
              props.field,
              DATE_INTERVAL.FROM
            );
          }}
          onKeyDown={onEnterPress}
          className="form-control mMaxWidth110"
          dateFormat={'dd-MM-yyyy'}
          customInput={
            <MaskedInput mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} placeholder="DD-MM-YYYY" />
          }
        />
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth60">To:</span>
        </div>
        <DatePicker
          selected={Moment(props.to, 'DD-MM-YYYY').isValid() ? Moment(props.to, 'DD-MM-YYYY').toDate() : null}
          onChange={date => {
            props.onTableFilterChange(
              { target: { value: Moment(date).isValid() ? Moment(date).format('DD-MM-YYYY') : '' } },
              props.field,
              DATE_INTERVAL.TO
            );
          }}
          onKeyDown={onEnterPress}
          className="form-control mMaxWidth110"
          dateFormat={'dd-MM-yyyy'}
          customInput={
            <MaskedInput mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} placeholder="DD-MM-YYYY" />
          }
        />
      </div>
    </div>
  );
};

DateIntervalField.displayName = 'DateIntervalField';
