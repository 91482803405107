import * as React from 'react';
import { OrderImage } from '../../../../../../../../models/orderImages';
import { AppUser } from '../../../../../../App';
import { getImageDetailsById } from '../../../../../../../../services/superadmin/orderImages';
import { createTicket } from '../../../../../../../../services/superadmin/image';

const { useState, useEffect } = React;

interface OrderImageSummaryProps {
  imageItem: OrderImage;
  user: AppUser;
}

export const OrderImageSummary: React.FC<OrderImageSummaryProps> = ({ imageItem, user }) => {
  const [detailedImageData, setDetailedImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!imageItem || !imageItem.image || !imageItem.image.imageId) return;

    const fetchDetailsAndTicket = async () => {
      setIsLoading(true);
      try {
        const details = await getImageDetailsById(user, imageItem.image.imageId);
        if (details && details.ticket) {
          setDetailedImageData(details);
        } else if (details && details.key) {
          const ticketResponse = await createTicket(user, { key: details.key, mimeType: details.mimeType });
          if (ticketResponse && ticketResponse.ticket) {
            const updatedDetails = { ...details, ticket: ticketResponse.ticket };
            setDetailedImageData(updatedDetails);
          }
        }
      } catch (error) {
        console.error('Error fetching image details and ticket:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetailsAndTicket();
  }, [imageItem, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const picUrl = detailedImageData?.ticket ? `${window.apiFile}/storage/images/${detailedImageData.ticket}` : '';
  console.log('picUrl:', picUrl);

  const rows = [
    {
      title: 'School code',
      content: detailedImageData?.school?.code ?? 'N/A',
    },
    {
      title: 'School name',
      content: detailedImageData?.school?.name ?? 'N/A',
    },
    {
      title: 'Class',
      content: detailedImageData?.student?.class ?? 'N/A',
    },
    {
      title: 'Student first name',
      content: detailedImageData?.student?.firstName ?? 'N/A',
    },
    {
      title: 'Student last name',
      content: detailedImageData?.student?.lastName ?? 'N/A',
    },
    {
      title: 'Job reference',
      content: detailedImageData?.job?.jobReference ?? 'N/A',
    },
    {
      title: 'Username',
      content: detailedImageData?.username ?? 'N/A',
    },
    {
      title: 'Password',
      content: detailedImageData?.password ?? 'N/A',
    },
  ].map((row, index) => (
    <tr key={index}>
      <td>{row.title}</td>
      <td>{row.content}</td>
    </tr>
  ));
  console.log('Rows:', rows);

  return (
    <div className="container-fluid mt-3">
      {picUrl && (
        <div className="row">
          <div className="col-md-4">
            <img className="img-fluid img-thumbnail" src={picUrl} alt={detailedImageData?.fileName} />
          </div>
          <div className="col-md-8">
            <table className="table table-sm mPre">
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

OrderImageSummary.displayName = 'OrderImageSummary';
