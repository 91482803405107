import * as React from 'react';
import { Address } from '../../../../../models/customers';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../../../components/Button/Button';
import { Country } from '../../CustomerBasket/CustomerDeliveryOptions/CustomerHomeDeliveryForm';

interface Props {
  address: Address;
  onSubmit: (data) => void;
  onCancel: () => void;
}

const countries: Country[] = require('Src/services/countries.json');

const CustomerAddressSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  town: Yup.string().required('Required'),
  region: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
});

export function CustomerAddressForm(props: Props) {
  const { address, onSubmit, onCancel } = props;

  const customerAddressForm = {
    firstName: address.firstName,
    lastName: address.lastName,
    email: address.email,
    phone: address.phone,
    address1: address.address1,
    town: address.town,
    region: address.region,
    postcode: address.postcode,
    country: address.country,
  };

  return (
    <div className={'mt-3'}>
      <Formik initialValues={customerAddressForm} validationSchema={CustomerAddressSchema} onSubmit={onSubmit}>
        {({ setFieldValue, values, handleSubmit }) => (
          <Form>
            <div className="form-group form-check">
              <div className="form-group">
                <label>Name</label>
                <Field name="firstName" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
              </div>

              <div className="form-group">
                <label>Surname</label>
                <Field name="lastName" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
              </div>

              <div className="form-group">
                <label>Email</label>
                <Field name="email" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="email" />
              </div>

              <div className="form-group">
                <label>Phone</label>
                <Field name="phone" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="phone" />
              </div>

              <div className="form-group">
                <label>Address</label>
                <Field name="address1" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="address1" />
              </div>

              <div className="form-group">
                <label>Town</label>
                <Field name="town" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="town" />
              </div>

              <div className="form-group">
                <label>Region</label>
                <Field name="region" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="region" />
              </div>

              <div className="form-group">
                <label>Postcode</label>
                <Field name="postcode" className="form-control mb-3" />
                <ErrorMessage component="div" className="alert alert-danger" name="postcode" />
              </div>

              <div className="form-group">
                <label>Country</label>
                <Field name="country" component="select" placeholder="Select country" className="form-control mb-3">
                  {countries.map(country => (
                    <option value={country.code} key={country.code}>
                      {country.code} - {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component="div" className="alert alert-danger" name="country" />
              </div>

              <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
              <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
