import * as React from 'react';
import { Component } from 'react';
import './CustomNotificationTextForm.css';
import { HTMLEditorQuill } from '../HTMLEditorQuill/HTMLEditorQuill';
import { CUSTOM_NOTIFICATION_TYPE } from '../../consts/customNotification';
import { Loader } from '../Loader/Loader';
import { isFilterExist } from '../../helpers/table';

interface Props {
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (body: any) => void;
  onSmsTextChange: (event) => void;
  onPushTitleChange: (event) => void;
  onPushBodyChange: (event) => void;
  onIsEditorChange: (type: string, value: boolean) => void;
  onEmailAttachmentChange: (event) => void;
  onRemoveAttachment: (event) => void;
  isError: boolean;
  isLoadingFile: boolean;

  isEmailEditorEnabled: boolean;
  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;

  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
  messagePushTitle: string;
  messagePushBody: string;
  isRawEditor: boolean;
  onRawEditorChange: (event) => void;
}

const EMPTY_EVENT_TARGET_VALUE = { target: { value: '' } };
const SMS_CHARACTERS_LIMIT = 160;
const FILE_NAME_MAX_LENGTH = 15;

export class CustomNotificationTextForm extends Component<Props> {
  onIsEmailEditorChange = event => {
    const isEmailEditorEnabled = event.target.checked;

    const { onEmailSubjectChange, onEmailBodyChange, onIsEditorChange } = this.props;

    onEmailSubjectChange(EMPTY_EVENT_TARGET_VALUE);
    onEmailBodyChange('');
    onIsEditorChange(CUSTOM_NOTIFICATION_TYPE.EMAIL, isEmailEditorEnabled);
  };

  onIsSmsEditorChange = event => {
    const isSmsEditorEnabled = event.target.checked;

    const { onSmsTextChange, onIsEditorChange } = this.props;

    onSmsTextChange(EMPTY_EVENT_TARGET_VALUE);
    onIsEditorChange(CUSTOM_NOTIFICATION_TYPE.SMS, isSmsEditorEnabled);
  };

  onIsPushEditorChange = event => {
    const isPushEditorEnabled = event.target.checked;

    const { onPushTitleChange, onPushBodyChange, onIsEditorChange } = this.props;

    onPushTitleChange(EMPTY_EVENT_TARGET_VALUE);
    onPushBodyChange(EMPTY_EVENT_TARGET_VALUE);
    onIsEditorChange(CUSTOM_NOTIFICATION_TYPE.PUSH, isPushEditorEnabled);
  };

  getFileName(fileName: string): string {
    if (fileName.length <= FILE_NAME_MAX_LENGTH) {
      return fileName;
    }
    const fileNameExtension = fileName.substr(fileName.length - 5, 5);
    const fileNameCropped = fileName.substr(0, FILE_NAME_MAX_LENGTH);

    return `${fileNameCropped}...${fileNameExtension}`;
  }

  renderSmsCharCounters() {
    const { messageSmsText } = this.props;
    const smsCharactersCount = messageSmsText.length;
    const smsMessagesCount = Math.ceil(smsCharactersCount / SMS_CHARACTERS_LIMIT);

    return (
      <div className="eMessageTextFormSmsEditorCounters text-secondary">
        <i>{`${smsCharactersCount} ${smsCharactersCount === 1 ? 'character' : 'characters'} have been entered.`}</i>
        <br />
        <i>{`${smsMessagesCount} ${smsMessagesCount === 1 ? 'message' : 'messages'} to be sent.`}</i>
      </div>
    );
  }

  renderAttachments() {
    const { isError, messageAttachments } = this.props;
    const onRemoveAttachment = this.props.onRemoveAttachment;

    return messageAttachments.map((attachment, index) => {
      const fileName = attachment.fileName;
      const attachmentFileName = this.getFileName(fileName);

      return (
        <div key={`attachment_${index}`}>
          {attachmentFileName}
          <button
            type="button"
            className="close"
            disabled={isError}
            onClick={() => onRemoveAttachment(attachment.externalFileId)}
          >
            <span>&times;</span>
          </button>
        </div>
      );
    });
  }

  render() {
    const {
      onEmailSubjectChange,
      onEmailBodyChange,
      onEmailAttachmentChange,
      onSmsTextChange,
      onPushTitleChange,
      onPushBodyChange,
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      isEmailEditorEnabled,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isError,
      isLoadingFile,
      onRawEditorChange,
      isRawEditor,
    } = this.props;

    return (
      <div className="bMessageTextForm">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="bMessageTextFormEmailEditor border border-primary rounded">
                <div className="form-group form-check">
                  <input
                    id="messageTextFormIsEmailEditorEnabled"
                    type="checkbox"
                    className="form-check-input"
                    checked={isEmailEditorEnabled}
                    onChange={this.onIsEmailEditorChange}
                  />
                  <label htmlFor="messageTextFormIsEmailEditorEnabled" className="form-check-label">
                    Send email
                  </label>
                </div>
                <div className="form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={onEmailSubjectChange}
                    value={messageEmailSubject}
                    disabled={!isEmailEditorEnabled}
                  />
                </div>

                <div className="form-group">
                  <label>Editor</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isRawEditor"
                      id="richText"
                      value="richText"
                      checked={!isRawEditor}
                      onChange={onRawEditorChange}
                    />
                    <label className="form-check-label" htmlFor="richText">
                      Rich text
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isRawEditor"
                      id="htmlCode"
                      value="htmlCode"
                      checked={isRawEditor}
                      onChange={onRawEditorChange}
                    />
                    <label className="form-check-label" htmlFor="htmlCode">
                      HTML code
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label>Body</label>
                  {isRawEditor ? (
                    <div>
                      <textarea
                        onChange={event => onEmailBodyChange(event.target.value)}
                        value={messageEmailBody}
                        disabled={!isEmailEditorEnabled}
                        className="form-control"
                        rows={8}
                      />
                    </div>
                  ) : (
                    <HTMLEditorQuill
                      onEmailBodyChange={onEmailBodyChange}
                      value={messageEmailBody}
                      isEnabled={isEmailEditorEnabled}
                    />
                  )}
                </div>
                {isEmailEditorEnabled && (
                  <div className="form-group">
                    <label htmlFor="attachment">Attachments</label>
                    <input
                      type="file"
                      className="form-control-file"
                      id="attachment"
                      onChange={onEmailAttachmentChange}
                      disabled={isError}
                    />
                    {this.renderAttachments()}
                    {isLoadingFile && <Loader />}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="bMessageTextFormPushEditor border border-primary rounded">
                <div className="form-group form-check">
                  <input
                    id="messageTextFormIsPushEditorEnabled"
                    type="checkbox"
                    className="form-check-input"
                    checked={isPushEditorEnabled}
                    onChange={this.onIsPushEditorChange}
                  />
                  <label htmlFor="messageTextFormIsPushEditorEnabled" className="form-check-label">
                    Send push
                  </label>
                </div>
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={onPushTitleChange}
                    value={messagePushTitle}
                    disabled={!isPushEditorEnabled}
                  />
                </div>
                <div className="form-group">
                  <label>Body</label>
                  <textarea
                    rows={8}
                    onChange={onPushBodyChange}
                    value={messagePushBody}
                    disabled={!isPushEditorEnabled}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bMessageTextFormSmsEditor border border-primary rounded">
                <div className="form-group form-check">
                  <input
                    id="messageTextFormIsSmsEditorEnabled"
                    type="checkbox"
                    className="form-check-input"
                    checked={isSmsEditorEnabled}
                    onChange={this.onIsSmsEditorChange}
                  />
                  <label htmlFor="messageTextFormIsSmsEditorEnabled" className="form-check-label">
                    Send sms
                  </label>
                </div>
                <div className="form-group">
                  <label>Text</label>
                  <textarea
                    rows={8}
                    onChange={onSmsTextChange}
                    value={messageSmsText}
                    disabled={!isSmsEditorEnabled}
                    className="form-control"
                  />
                  {this.renderSmsCharCounters()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
