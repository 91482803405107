import * as React from 'react';

interface Props {
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
}

function ImageWizardPreview({ messageEmailSubject, messageAttachments, messageEmailBody }: Props) {
  const isAttachmentExist = messageAttachments.length > 0;
  const renderAttachments = (): React.ReactNode => {
    return messageAttachments.map((attachment, index) => {
      return (
        <div key={`attachment_${index}`}>
          <a href={attachment.url} target="_blank">
            {attachment.fileName}
          </a>
        </div>
      );
    });
  };

  const renderBody = (): React.ReactNode => {
    if (
      typeof messageEmailBody === 'undefined' ||
      typeof messageEmailBody === 'object' ||
      messageEmailBody.length === 0
    ) {
      return null;
    }
    return <div className={'bEmailPreview'} dangerouslySetInnerHTML={{ __html: messageEmailBody }} />;
  };

  return (
    <div className="container-fluid">
      <div className="bGeneralMessagePreview row">
        <div className="col-md-12">
          <div className="card border-success h-100">
            <h5 className="card-header eGeneralMessagePreviewEmailHeader">Email preview</h5>
            <div className="card-body">
              <h5 className="card-title">{messageEmailSubject}</h5>
              {renderBody()}
              {isAttachmentExist && <div>Attachments: {renderAttachments()}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageWizardPreview;
