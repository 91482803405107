import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';

export function getImagePriceSetProducts(customer: AppCustomer, imageId: string) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/images/${imageId}`, config).then(response => {
    return response.data;
  });
}

export function getCustomerJobs(customer: AppCustomer, filter?) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/images/jobs`, config).then(response => {
    return response.data;
  });
}

export function downloadImage(customer: AppCustomer, ticketId: string) {
  const { sessionKey: key } = customer;
  const config = {
    headers: { usid: key },
    responseType: 'blob' as any,
  };

  return axios.get(`${window.apiFile}/storage/images/${ticketId}`, config).then(response => {
    return response.data;
  });
}
