import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';

export function addCustomerOfferProduct(customer: AppCustomer, data: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };
  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/addOfferProduct`, data, config)
    .then(response => {
      return response.data;
    });
}
