import * as React from 'react';
import * as propz from 'propz';
import { Customer } from '../../../../../../../models/customers';

interface Props {
  customer: Customer;
}

export function CustomerStudentsSummary(props: Props) {
  const { customer } = props;
  const { students } = customer;

  const rows = students
    .filter(student => {
      const studentId = propz.get(student, ['student', 'studentId']);
      return typeof studentId !== 'undefined';
    })
    .map((student, index) => {
      const studentId = propz.get(student, ['student', 'studentId']);
      const isConfirmed = typeof studentId !== 'undefined';
      const forename = isConfirmed
        ? propz.get(student, ['student', 'forename'], '')
        : propz.get(student, ['forename'], '');
      const surname = isConfirmed
        ? propz.get(student, ['student', 'surname'], '')
        : propz.get(student, ['surname'], '');
      const schoolName = propz.get(student, ['school', 'schoolName'], '');
      const formName = propz.get(student, ['form', 'formName'], '');

      return (
        <tr key={`customer_students_summary_${index}`}>
          <td>{forename}</td>
          <td>{surname}</td>
          <td>{schoolName}</td>
          <td>{formName}</td>
        </tr>
      );
    });

  return (
    <table className={'table table-sm mt-3'}>
      <thead>
        <tr>
          <th>Forename</th>
          <th>Surname</th>
          <th>School name</th>
          <th>Form name</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
