import * as React from 'react';
import { FunctionComponent } from 'react';
import { Package } from 'Src/models/packages';

interface Props {
  packageItem: Package;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Description', field: 'description' },
];

export const PackageSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    const field = row.field;
    const { packageItem } = props;
    const contentCell = packageItem[field];

    return (
      <tr key={`student_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mt-3'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

PackageSummary.displayName = 'PackageSummary';
