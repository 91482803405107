import * as React from 'react';
import { Profile } from '../../../../../models/profile';
import { Button } from '../../../../../components/Button/Button';

interface Props {
  profile: Profile;
  setPrimaryDeliveryAddress: (addressId: string) => void;
  setPrimaryBillingAddress: (addressId: string) => void;
  onEditAddress: (addressId: string) => void;
}

export function CustomerAddressBookTab(props: Props) {
  const { profile } = props;
  const { addressBook } = profile;
  return (
    <div className={'mt-3 container'}>
      <div className="row">
        {addressBook.map((address, index) => (
          <div className="col-xl-4" key={address.id}>
            <div className={'eCustomerAddressBookTabTitle'}>Address #{index + 1}</div>
            <div>
              {address.firstName} {address.lastName}
            </div>
            <div>{address.email}</div>
            <div>{address.phone}</div>
            <div>{address.address1}</div>
            <div>{address.town}</div>
            <div>{address.region}</div>
            <div>{address.postcode}</div>
            <div>{address.country}</div>
            <div className={'eCustomerAddressBookTabCheckboxWrapper'}>
              <div className="checkbox ">
                <input
                  type="checkbox"
                  checked={address.isDeliveryPrimary}
                  onChange={() => props.setPrimaryDeliveryAddress(address.id)}
                />
                {' Default Delivery'}
              </div>
              <div className="checkbox eCustomerAddressBookTabCheckbox">
                <input
                  type="checkbox"
                  checked={address.isBillingPrimary}
                  onChange={() => props.setPrimaryBillingAddress(address.id)}
                />
                {' Default Billing'}
              </div>
            </div>

            <button className={'eCustomerAddressBookTabButton'} onClick={() => props.onEditAddress(address.id)}>
              Edit
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
