import axios from 'axios';
import { AppUser } from '../../views/App/App';

export function uploadCsv(user: AppUser, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`${window.apiBase}/admin/users/preRegistered/upload`, formData, config).then(response => {
    return response.data;
  });
}

export function syncUploadCsvInPreReg(user: AppUser, formData, uploadType: string) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`${window.apiBase}/admin/system/import/${uploadType}/sync`, formData, config).then(response => {
    return response.data;
  });
}

export function syncUploadCsvStudents(user: AppUser, schoolId: string, formData, uploadType: string) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios
    .post(`${window.apiBase}/admin/system/import/${uploadType}/sync/${schoolId}`, formData, config)
    .then(response => {
      return response.data;
    });
}
export function asyncUploadCsv(user: AppUser, uploadType: string, uploadSubType: string) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios
    .post(`${window.apiBase}/admin/system/import/${uploadType}/${uploadSubType}/async`, {}, config)
    .then(response => {
      return response.data;
    });
}
