export const ORDER_TABS = ['Summary', 'Notes'];

export const ORDER_FORM_TABS = {
  GENERAL: 'General',
  DELIVERY: 'Delivery details',
  BILLING: 'Billing details',
  SCHOOL_DELIVERY: 'School delivery',
};

export const ORDER_STATUS = {
  NEW: 'NEW',
  PAID: 'PAID',
  DOWNLOADED: 'DOWNLOADED',
  DISPATCHED: 'DISPATCHED',
  REPRINT: 'REPRINT',
  CANCELED: 'CANCELED',
};

export const ORDER_STATUS_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'New',
  PAID: 'Paid',
  DOWNLOADED: 'Downloaded',
  DISPATCHED: 'Dispatched',
  REPRINT: 'Reprint',
  CANCELED: 'Canceled',
};

export const ORDER_STATUS_CUSTOMER_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'Not finished',
  PAID: 'Placed',
  DOWNLOADED: 'Processing',
  DISPATCHED: 'Dispatched',
  REPRINT: 'Reprint',
  CANCELED: 'Canceled',
};

export const ORDER_DELIVERY_TYPE = {
  HOME: 'HOME',
  SCHOOL: 'SCHOOL',
};

export const ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING = {
  HOME: 'Home',
  SCHOOL: 'School',
};

export const EMPTY_DELIVERY_OPTION_TEXT = 'Delivery Option';
export const DEFAULT_COUNTRY_OPTION_VALUE = 'GB';

export const COUNTRIES_WITH_STANDARD_AND_COURIER_DELIVERY_TYPE = ['GB', 'IM', 'JE', 'GG'];

export const DELIVERY_OPTION = {
  STANDARD: 'standard',
  COURIER: 'courier',
  INTERNATIONAL: 'international',
};

export const BASKET_DELIVERY_CHARGE_TYPE = {
  STANDARD: 'STANDARD',
  COURIER: 'COURIER',
  INTERNATIONAL: 'INTERNATIONAL',
  FREE_SCHOOL: 'FREE_SCHOOL',
  DIGITAL_PRODUCT: 'DIGITAL_PRODUCT',
};

export const ORDER_SOURCE = {
  STAFF: 'STAFF',
  WEB_PURCHASE: 'WEB_PURCHASE',
  MOBILE_PURCHASE: 'MOBILE_PURCHASE',
  NOT_DEFINED: 'NOT_DEFINED',
};

export const ORDER_SOURCE_SERVER_TO_CLIENT_MAPPING = {
  STAFF: 'Staff',
  WEB_PURCHASE: 'Web purchase',
  MOBILE_PURCHASE: 'Mobile purchase',
  NOT_DEFINED: 'Not defined',
};
