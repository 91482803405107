import * as React from 'react';
import { Component } from 'react';
import axios from 'axios';
import { Loader } from 'Src/components/Loader/Loader';
import { AppSchoolsUser2 } from '../AppSchools2';

interface Props {
  user: AppSchoolsUser2;
}

interface State {
  content: string;
  isLoading: boolean;
  isError: boolean;
}

export class SchoolAppContactDetails2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      content: '',
      isLoading: true,
      isError: false,
    };
  }

  componentDidMount() {
    this.fetchContactDetails();
  }

  fetchContactDetails = () => {
    axios
      .get(`${window.apiBase}/schoolUser/contactDetails`, {
        headers: { usid: this.props.user.sessionKey },
      })
      .then(response => {
        this.setState({
          content: response.data.content,
          isLoading: false,
        });
      })
      .catch(error => {
        console.error('Error fetching contact details:', error);
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  render() {
    const { content, isLoading, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <div className="alert alert-danger">Error fetching contact details.</div>;
    }

    return (
      <div className="dashboard-container">
        <div className="dashboard-card">
          <div className="dashboard-card-content">
            <h2 className="dashboard-card-header">
              <div className="dashboard-card-icon">
                <img src="/dist/images/icon/contact.png" alt="Contact Details" />
              </div>
              <span>Contact Details</span>
            </h2>
            <div className="contact-details-content" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    );
  }
}
