import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { HTMLEditorQuill } from 'Src/components/HTMLEditorQuill/HTMLEditorQuill';
import { Loader } from 'Src/components/Loader/Loader';

interface Props {
  user: AppUser;
}

interface State {
  content: string;
  isLoading: boolean;
  isSaving: boolean;
  isError: boolean;
  isEditing: boolean;
  errorMessage: string;
  originalContent: string;
}

export class ContactDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: '',
      isLoading: true,
      isSaving: false,
      isError: false,
      isEditing: false,
      errorMessage: '',
      originalContent: '',
    };
  }

  componentDidMount() {
    this.fetchContactDetails();
  }

  fetchContactDetails = () => {
    const { user } = this.props;

    axios
      .get(`${window.apiBase}/admin/contactDetails`, {
        headers: { usid: user.sessionKey },
      })
      .then(response => {
        this.setState({
          content: response.data.content,
          originalContent: response.data.content,
          isLoading: false,
        });
      })
      .catch(error => {
        console.error('Error fetching contact details:', error);
        this.setState({
          isError: true,
          errorMessage: 'Failed to fetch contact details.',
          isLoading: false,
        });
      });
  };

  handleSave = () => {
    const { user } = this.props;
    const { content } = this.state;

    this.setState({ isSaving: true });

    axios
      .post(
        `${window.apiBase}/admin/contactDetails`,
        { content },
        {
          headers: { usid: user.sessionKey },
        }
      )
      .then(() => {
        this.setState({
          isSaving: false,
          isEditing: false,
          originalContent: content,
        });
        alert('Contact details updated successfully!');
      })
      .catch(error => {
        console.error('Error saving contact details:', error);
        this.setState({
          isSaving: false,
          isError: true,
          errorMessage: 'Failed to save contact details.',
        });
      });
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
      content: this.state.originalContent,
    });
  };

  handleContentChange = (newContent: string) => {
    this.setState({ content: newContent });
  };

  render() {
    const { content, isLoading, isSaving, isError, errorMessage, isEditing } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <div className="alert alert-danger">{errorMessage}</div>;
    }

    return (
      <div>
        <h2>Contact Details</h2>

        {!isEditing ? (
          <div>
            <div className="view-content" dangerouslySetInnerHTML={{ __html: content }} />
            <button onClick={this.handleEdit} className="btn btn-primary mt-3">
              Edit
            </button>
          </div>
        ) : (
          <div>
            <HTMLEditorQuill value={content} onEmailBodyChange={this.handleContentChange} isEnabled={!isSaving} />
            <button onClick={this.handleSave} className="btn btn-primary mt-3" disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button onClick={this.handleCancel} className="btn btn-secondary mt-3 ml-2" disabled={isSaving}>
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  }
}
