import * as React from 'react';
import { login } from '../../../helpers/auth';
import StorageHelper from '../../../helpers/storage';
import { LoginForm } from './LoginForm';
import { USER_TYPE } from '../../../consts/user';

interface Props {
  history: any;
  onFormSubmit: (key: string, type: string, userId: string) => void;
}

interface State {
  email: string;
  password: string;
  isRememberMe: boolean;
  isError: boolean;
}

export class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isRememberMe: false,
      isError: false,
    };
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ email: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { email, password, isRememberMe } = this.state;

    login(email, password)
      .then(session => {
        const { key, type, userId } = session;

        if (isRememberMe) {
          // one year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('session', session, { expires: 365 });
        } else {
          StorageHelper.Cookie.set('session', session);
        }

        this.props.onFormSubmit(key, type, userId);
        switch (true) {
          case type === USER_TYPE.SUPERADMIN:
            this.props.history.push('/');
            break;
          case type === USER_TYPE.ADMIN:
            this.props.history.push('/');
            break;
          default:
            this.props.history.push('/');
        }
      })
      .catch(error => {
        this.setState({ isError: true });
      });
  };

  onRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked;
    this.setState({ isRememberMe: value });
  };

  render() {
    return (
      <LoginForm
        email={this.state.email}
        password={this.state.password}
        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onFormSubmit={this.onFormSubmit}
        isRememberMe={this.state.isRememberMe}
        onRememberMeChange={this.onRememberMeChange}
        isError={this.state.isError}
        caption={'Administrating terminal'}
      />
    );
  }
}
