import StorageHelper from './storage';

export function setCustomerBasketIdToStorage(customerBasketId): void {
  StorageHelper.SessionStorage.set('customerBasketId', customerBasketId);
}

export function getCustomerBasketIdToStorage() {
  return StorageHelper.SessionStorage.get('customerBasketId');
}

export function clearCustomerBasketIdToStorage(): void {
  StorageHelper.SessionStorage.remove('customerBasketId');
}

export function setOneTimeCodeToStorage(oneTimeCode): void {
  StorageHelper.SessionStorage.set('oneTimeCode', oneTimeCode);
}

export function getOneTimeCodeToStorage() {
  return StorageHelper.SessionStorage.get('oneTimeCode');
}

export function clearOneTimeCodeInStorage(): void {
  StorageHelper.SessionStorage.remove('oneTimeCode');
}

export function setMobilePlatformToStorage(mobilePlatform): void {
  StorageHelper.SessionStorage.set('mobilePlatform', mobilePlatform);
}

export function getMobilePlatformFromStorage() {
  return StorageHelper.SessionStorage.get('mobilePlatform');
}

export function clearMobilePlatformInStorage(): void {
  StorageHelper.SessionStorage.remove('mobilePlatform');
}

export function setIsSignFromOneTimeCodeWaitingToStorage(value: boolean): void {
  StorageHelper.SessionStorage.set('isSignFromOneTimeCodeWaiting', value);
}

export function getIsSignFromOneTimeCodeWaitingFromStorage(): boolean {
  return Boolean(StorageHelper.SessionStorage.get('isSignFromOneTimeCodeWaiting'));
}

export function clearIsSignFromOneTimeCodeWaitingInStorage(): void {
  StorageHelper.SessionStorage.remove('isSignFromOneTimeCodeWaiting');
}
