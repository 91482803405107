import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Switch } from 'Src/components/Switch/Switch';
import { MENU_ITEMS, MENU_ITEMS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/sidebar';
import { User } from 'Src/models/users';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  users: User[];
}

export function MenuItemsForm(props: Props) {
  const { onSubmit, onCancel, users } = props;

  function isProductsAndPackagesActive(menuItems) {
    return (
      menuItems[MENU_ITEMS.PHOTO_TYPES] ||
      menuItems[MENU_ITEMS.PRODUCT_TYPES] ||
      menuItems[MENU_ITEMS.PRODUCTS] ||
      menuItems[MENU_ITEMS.PACKAGES] ||
      menuItems[MENU_ITEMS.OFFERS] ||
      menuItems[MENU_ITEMS.VOUCHERS] ||
      menuItems[MENU_ITEMS.DELIVERY_CHARGES]
    );
  }

  function setProductsAndPackagesItemsActive(setFieldValue, isActive) {
    setFieldValue(MENU_ITEMS.PHOTO_TYPES, isActive);
    setFieldValue(MENU_ITEMS.PRODUCT_TYPES, isActive);
    setFieldValue(MENU_ITEMS.PRODUCTS, isActive);
    setFieldValue(MENU_ITEMS.PACKAGES, isActive);
    setFieldValue(MENU_ITEMS.OFFERS, isActive);
    setFieldValue(MENU_ITEMS.VOUCHERS, isActive);
    setFieldValue(MENU_ITEMS.DELIVERY_CHARGES, isActive);
  }

  function isJobsAndPreRegistrationsActive(menuItems) {
    return menuItems[MENU_ITEMS.PRE_REGISTRATIONS] || menuItems[MENU_ITEMS.JOBS] || menuItems[MENU_ITEMS.IMAGES];
  }

  function setJobsAndPreRegistrationsItemsActive(setFieldValue, isActive) {
    setFieldValue(MENU_ITEMS.PRE_REGISTRATIONS, isActive);
    setFieldValue(MENU_ITEMS.JOBS, isActive);
    setFieldValue(MENU_ITEMS.IMAGES, isActive);
  }

  function isOrdersActive(menuItems) {
    return menuItems[MENU_ITEMS.ORDERS] || menuItems[MENU_ITEMS.ALL_ORDER_IMAGES];
  }

  function setOrdersItemsActive(setFieldValue, isActive) {
    setFieldValue(MENU_ITEMS.ORDERS, isActive);
    setFieldValue(MENU_ITEMS.ALL_ORDER_IMAGES, isActive);
  }

  function isSchoolsActive(menuItems) {
    return menuItems[MENU_ITEMS.SCHOOL_TYPES] || menuItems[MENU_ITEMS.SCHOOLS];
  }

  function setSchoolsItemsActive(setFieldValue, isActive) {
    setFieldValue(MENU_ITEMS.SCHOOL_TYPES, isActive);
    setFieldValue(MENU_ITEMS.SCHOOLS, isActive);
  }

  function isCustomersActive(menuItems) {
    return menuItems[MENU_ITEMS.CUSTOMERS] || menuItems[MENU_ITEMS.CUSTOMER_WITH_STUDENTS];
  }

  function setCustomersItemsActive(setFieldValue, isActive) {
    setFieldValue(MENU_ITEMS.CUSTOMERS, isActive);
    setFieldValue(MENU_ITEMS.CUSTOMER_WITH_STUDENTS, isActive);
  }

  function isDataExchangeActive(menuItems) {
    return menuItems[MENU_ITEMS.DATA_EXCHANGE] || menuItems[MENU_ITEMS.CONTACT_DETAILS];
  }

  function setDataExchangeItemsActive(setFieldValue, isActive) {
    setFieldValue(MENU_ITEMS.DATA_EXCHANGE, isActive);
    setFieldValue(MENU_ITEMS.CONTACT_DETAILS, isActive);
  }

  function getMenuItems() {
    const menuItems = {};
    const usersMenuItemsEnabled = users.map(user => user.menuItemsEnabled);

    for (let menuItem in MENU_ITEMS) {
      const userMenuItem = usersMenuItemsEnabled
        .map(userMenuItemsEnabled => userMenuItemsEnabled[menuItem])
        .some(userMenuItemsEnabled => userMenuItemsEnabled === true);

      menuItems[menuItem] = userMenuItem;
    }

    return menuItems;
  }

  const menuItemsForm = getMenuItems();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={menuItemsForm} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <Switch
                      text={'Users'}
                      customClass=""
                      name={'users'}
                      value={values[MENU_ITEMS.USERS]}
                      onChange={() => {
                        setFieldValue(MENU_ITEMS.USERS, !values[MENU_ITEMS.USERS]);
                      }}
                    />
                    <Field name={MENU_ITEMS.USERS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.USERS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Schools'}
                      customClass=""
                      name={'schools'}
                      value={isSchoolsActive(values)}
                      onChange={() => {
                        if (isSchoolsActive(values)) {
                          setSchoolsItemsActive(setFieldValue, false);
                        } else {
                          setSchoolsItemsActive(setFieldValue, true);
                        }
                      }}
                    />
                    <Field name={MENU_ITEMS.SCHOOL_TYPES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.SCHOOL_TYPES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.SCHOOLS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.SCHOOLS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Products and Packages'}
                      customClass=""
                      name={'productsAndPackages'}
                      value={isProductsAndPackagesActive(values)}
                      onChange={() => {
                        if (isProductsAndPackagesActive(values)) {
                          setProductsAndPackagesItemsActive(setFieldValue, false);
                        } else {
                          setProductsAndPackagesItemsActive(setFieldValue, true);
                        }
                      }}
                    />
                    <Field name={MENU_ITEMS.PHOTO_TYPES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.PHOTO_TYPES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.PRODUCT_TYPES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.PRODUCT_TYPES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.PRODUCTS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.PRODUCTS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.PACKAGES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.PACKAGES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.OFFERS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.OFFERS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.VOUCHERS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.VOUCHERS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.DELIVERY_CHARGES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.DELIVERY_CHARGES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Jobs and Preregistrations'}
                      customClass=""
                      name={'jobsAndPreRegistrations'}
                      value={isJobsAndPreRegistrationsActive(values)}
                      onChange={() => {
                        if (isJobsAndPreRegistrationsActive(values)) {
                          setJobsAndPreRegistrationsItemsActive(setFieldValue, false);
                        } else {
                          setJobsAndPreRegistrationsItemsActive(setFieldValue, true);
                        }
                      }}
                    />
                    <Field name={MENU_ITEMS.PRE_REGISTRATIONS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.PRE_REGISTRATIONS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.JOBS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.JOBS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.IMAGES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.IMAGES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Orders'}
                      customClass=""
                      name={'orders'}
                      value={isOrdersActive(values)}
                      onChange={() => {
                        if (isOrdersActive(values)) {
                          setOrdersItemsActive(setFieldValue, false);
                        } else {
                          setOrdersItemsActive(setFieldValue, true);
                        }
                      }}
                    />
                    <Field name={MENU_ITEMS.ORDERS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.ORDERS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.ALL_ORDER_IMAGES}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.ALL_ORDER_IMAGES]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Customers'}
                      customClass=""
                      name={'customers'}
                      value={isCustomersActive(values)}
                      onChange={() => {
                        if (isCustomersActive(values)) {
                          setCustomersItemsActive(setFieldValue, false);
                        } else {
                          setCustomersItemsActive(setFieldValue, true);
                        }
                      }}
                    />
                    <Field name={MENU_ITEMS.CUSTOMERS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.CUSTOMERS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.CUSTOMER_WITH_STUDENTS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.CUSTOMER_WITH_STUDENTS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <Switch
                      text={'Website setting'}
                      customClass=""
                      name={'Sliders'}
                      value={values[MENU_ITEMS.SLIDERS]}
                      onChange={() => {
                        setFieldValue(MENU_ITEMS.SLIDERS, !values[MENU_ITEMS.SLIDERS]);
                      }}
                    />
                    <Field name={MENU_ITEMS.SLIDERS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.SLIDERS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Integration'}
                      customClass=""
                      name={'Integration'}
                      value={values[MENU_ITEMS.INTEGRATION_LOGS]}
                      onChange={() => {
                        setFieldValue(MENU_ITEMS.INTEGRATION_LOGS, !values[MENU_ITEMS.INTEGRATION_LOGS]);
                      }}
                    />
                    <Field name={MENU_ITEMS.INTEGRATION_LOGS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.INTEGRATION_LOGS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Unsubscribed emails'}
                      customClass=""
                      name={'Unsubscribed emails'}
                      value={values[MENU_ITEMS.UNSUBSCRIBED_EMAILS]}
                      onChange={() => {
                        setFieldValue(MENU_ITEMS.UNSUBSCRIBED_EMAILS, !values[MENU_ITEMS.UNSUBSCRIBED_EMAILS]);
                      }}
                    />
                    <Field name={MENU_ITEMS.UNSUBSCRIBED_EMAILS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.UNSUBSCRIBED_EMAILS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'Data exchange'}
                      customClass=""
                      name={'Data exchange'}
                      value={values[MENU_ITEMS.DATA_EXCHANGE]}
                      onChange={() => {
                        setFieldValue(MENU_ITEMS.DATA_EXCHANGE, !values[MENU_ITEMS.DATA_EXCHANGE]);
                      }}
                    />
                    <Field name={MENU_ITEMS.DATA_EXCHANGE}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.DATA_EXCHANGE]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                    <Field name={MENU_ITEMS.CONTACT_DETAILS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.CONTACT_DETAILS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Switch
                      text={'School jobs'}
                      customClass=""
                      name={'School jobs'}
                      value={values[MENU_ITEMS.SCHOOL_JOBS]}
                      onChange={() => {
                        setFieldValue(MENU_ITEMS.SCHOOL_JOBS, !values[MENU_ITEMS.SCHOOL_JOBS]);
                      }}
                    />
                    <Field name={MENU_ITEMS.SCHOOL_JOBS}>
                      {({ field }) => (
                        <Switch
                          {...field}
                          text={MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[MENU_ITEMS.SCHOOL_JOBS]}
                          customClass="ml-3"
                        />
                      )}
                    </Field>
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
