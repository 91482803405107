import * as React from 'react';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onTableFilterClick: (event) => void;
  isFilterShown: boolean;
  isDataFiltered?: boolean;
}

export const FilterIcon: FunctionComponent<Props> = props => {
  const className = props.isFilterShown ? 'btn-group dropup' : 'btn-group';
  const filteredClassName = props.isDataFiltered
    ? 'btn-outline-primary'
    : 'btn-outline-secondary';

  return (
    <div className={className}>
      <button
        className={`btn ${filteredClassName} dropdown-toggle btn-sm`}
        onClick={event => props.onTableFilterClick(event)}
      >
        <FontAwesomeIcon icon="filter" className="mr-2" />
        <span className="mr-2">Filter</span>
      </button>
    </div>
  );
};

FilterIcon.displayName = 'FilterIcon';
