import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { Product } from '../../../../../../../models/products';
import { AppUser } from '../../../../../App';

interface Props {
  productItem: Product;
  user: AppUser;
}

const ROWS = [
  { title: 'Product type', field: 'typeName' },
  { title: 'Halse product reference Id', field: 'referenceId' },
  { title: 'Product name (for Bentley)', field: 'description' },
  { title: 'Product description (for parents)', field: 'name' },
  { title: 'Base price', field: 'price' },
  { title: 'Delivery charge', field: 'deliveryCharge' },
];

export const ProductSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;
    const { productItem } = props;

    switch (field) {
      case 'typeName':
        contentCell = propz.get(productItem, ['productType', 'name'], '');
        break;
      case 'referenceId':
        contentCell = propz.get(productItem, ['referenceId'], '');
        break;
      case 'description':
        contentCell = propz.get(productItem, ['description'], '');
        break;
      case 'name':
        contentCell = propz.get(productItem, ['name'], '');
        break;
      case 'price':
        contentCell = propz.get(productItem, ['pricing', 'price'], '');
        break;
      case 'deliveryCharge':
        contentCell = propz.get(productItem, ['pricing', 'deliveryCharge'], '');
        break;
      default:
        contentCell = propz.get(productItem, [row.field], '');
    }

    return (
      <tr key={`product_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });
  const { productItem } = props;

  const pic = propz.get(productItem, ['pic', 'ticketId'], '');
  const picUrl = `${window.apiFile}/storage/images/${pic}`;
  if (pic !== '') {
    return (
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-4">
            <img className="img-fluid" src={picUrl} />
          </div>
          <div className="col-md-8">
            <table className={'table table-sm mPre '}>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <table className={'table table-sm mPre mt-3'}>
        <tbody>{rows}</tbody>
      </table>
    );
  }
};

ProductSummary.displayName = 'ProductSummary';
