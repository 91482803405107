import * as React from 'react';

interface Props {
  jobEmails: { customerId?: string; email: string }[];
}

function JobWizardEmails({ jobEmails }: Props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <h3>Sending list</h3>
            {jobEmails.map(email => (
              <div key={email.email}>{email.email}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobWizardEmails;
