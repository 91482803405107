import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolJobImages } from './SchoolJobImages/SchoolJobImages';
import { SchoolJobs } from './SchoolJobs';

export const SchoolJobsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/schoolJobs'} render={props => <SchoolJobs {...props} user={allProps.user} />} />

      <Route exact path={'/schoolJobs/images'} render={props => <SchoolJobImages {...props} user={allProps.user} />} />
    </Switch>
  );
};

SchoolJobsRouting.displayName = 'SchoolJobsRouting';
