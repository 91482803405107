import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';

export function updateCustomerAddressBook(customer: AppCustomer, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/addressBook/address`, data, config).then(response => {
    return response.data;
  });
}

export function setPrimaryCustomerDeliveryAddress(customer: AppCustomer, addressId: string) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/addressBook/${addressId}/setDeliveryPrimary`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function setPrimaryCustomerBillingAddress(customer: AppCustomer, addressId: string) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/addressBook/${addressId}/setBillingPrimary`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function updateCustomerAddress(customer: AppCustomer, addressId: string, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/customer/addressBook/${addressId}`, data, config).then(response => {
    return response.data;
  });
}
