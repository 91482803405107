import * as React from 'react';
import * as classNames from 'classnames';
import { Component } from 'react';
import * as debounce from 'debounce-promise';
import './Autocomplete.css';

interface Props {
  onSelect: (item: any) => void;
  searchFunction: (value) => any;
  getElementTitle: (item: any) => string;
  defaultItem?: any;
  customClass?: string;
  disabled?: boolean;
  customInputClass?: string;
  placeholder?: string;
}

interface State {
  isLoading: boolean;
  itemList: any[];
  isOpen: boolean;
  currentText: string;
}

export class Autocomplete extends Component<Props, State> {
  searchFunctionDebounced: any;

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itemList: [],
      isOpen: false,
      currentText: undefined,
    };
  }

  componentDidMount() {
    const { searchFunction, defaultItem, getElementTitle } = this.props;
    this.searchFunctionDebounced = debounce(searchFunction, 500);
    if (typeof defaultItem !== 'undefined') {
      this.setState({
        currentText: getElementTitle(defaultItem),
      });
    }
  }

  /** Checks on new props if we need to set default item */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultItem, getElementTitle } = this.props;
    const { defaultItem: nextDefaultItem } = nextProps;
    // Eliminating infinite looping by checking if really props update take place
    if (nextDefaultItem && JSON.stringify(nextDefaultItem) !== JSON.stringify(defaultItem)) {
      // Change current text (value in input field) on props.defaultItem, if received new props.defaultItem
      this.setState({
        currentText: getElementTitle(nextDefaultItem),
      });
    }
    if (typeof nextDefaultItem === 'undefined') {
      this.setState({
        currentText: undefined,
      });
    }
  }

  onChange = event => {
    event.preventDefault();
    const value = event.target.value;
    this.search(value);
  };

  onFocus = () => {
    const { currentText } = this.state;
    this.search(currentText);
  };

  search(value: string) {
    this.setState({
      isLoading: true,
      currentText: value,
    });

    this.searchFunctionDebounced(value).then(data => {
      this.setState(
        {
          isLoading: false,
          itemList: data,
          isOpen: true,
        },
        () => {
          document.addEventListener('click', this.closeMenu);
        }
      );
    });
  }

  closeMenu = () => {
    this.setState({ isOpen: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  onItemClick = (event, item) => {
    event.preventDefault();
    const currentText = this.props.getElementTitle(item);

    this.setState({
      currentText: currentText,
      isOpen: false,
    });

    this.props.onSelect(item);
  };

  renderItems() {
    const { itemList } = this.state;

    if (itemList.length == 0) {
      return (
        <a href="" className="dropdown-item disabled">
          No results
        </a>
      );
    }

    return itemList.map((item, index) => {
      return (
        <a
          href=""
          className="dropdown-item"
          key={`autocomplete_${index}`}
          onClick={event => this.onItemClick(event, item)}
        >
          {this.props.getElementTitle(item)}
        </a>
      );
    });
  }

  getCurrentText(): string {
    const { currentText } = this.state;
    const { defaultItem, getElementTitle } = this.props;

    switch (true) {
      case typeof currentText !== 'undefined':
        return currentText;
      case typeof defaultItem !== 'undefined':
        return getElementTitle(defaultItem);
      default:
        return '';
    }
  }

  render() {
    const { isLoading, isOpen } = this.state;

    const { customClass, disabled, customInputClass, placeholder } = this.props;
    const autocompleteClass = classNames('bAutocomplete', customClass);
    const autocompleteInputClass = classNames('form-control eAutocompleteInput', customInputClass);

    return (
      <div className={autocompleteClass}>
        <input
          className={autocompleteInputClass}
          value={this.getCurrentText()}
          onChange={this.onChange}
          onFocus={this.onFocus}
          disabled={disabled}
          placeholder={placeholder}
        />
        {isLoading && <div className={'spinner-border spinner-border-sm eAutocompleteSpinner'} />}

        {isOpen && <div className="dropdown-menu show">{this.renderItems()}</div>}
      </div>
    );
  }
}
