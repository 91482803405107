import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import './VoucherForm.scss';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  onOk?: () => void;
  text?: string;
  isEnterButtonClicked?: boolean;
}

export function VoucherForm(props: Props) {
  const voucherCodeSchema = Yup.object().shape({
    code: Yup.string().required('Voucher code required'),
  });

  const voucherForm = {
    code: '',
  };

  const { text, onCancel, onSubmit, onOk, isEnterButtonClicked } = props;
  const isTextExist = typeof text !== 'undefined';
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={voucherForm} validationSchema={voucherCodeSchema} onSubmit={onSubmit}>
            {({ values, handleSubmit, setFieldValue }) => (
              <Form>
                <div className="bVoucherForm">
                  <div className="eVoucherFormTitle">VOUCHER CODE</div>
                  <Field
                    name="code"
                    className="eVoucherFormInput"
                    type="text"
                    value={values.code}
                    placeholder="Code"
                    onChange={event => {
                      setFieldValue('code', event.target.value);
                    }}
                  />
                  <ErrorMessage component="div" className="alert alert-danger mVoucherFormAlert" name="code" />
                  {isTextExist && <div className="eVoucherFormText">{text}</div>}
                  <div className="eVoucherFormButtonWrapper">
                    {isEnterButtonClicked ? (
                      <Button onClick={onOk} text={'Ok'} customClass={'eVoucherFormButton'} />
                    ) : (
                      <>
                        <Button
                          onClick={handleSubmit}
                          text={'Enter'}
                          customClass={'eVoucherFormButton'}
                          type={'submit'}
                        />
                        <Button onClick={onCancel} text={'Cancel'} customClass={'eVoucherFormButton'} />
                      </>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
