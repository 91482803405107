import * as React from 'react';
import * as Yup from 'yup';
import { Profile } from '../../../../models/profile';
import { checkCustomerSession, getCustomerProfile } from '../../../../services/customer/customer';
import { AppCustomer } from '../../AppOrders';
import { History, Location } from 'history';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../consts/common';
import { getName, searchFunctionCustomerSchoolForms } from '../../../../helpers/autocomplete';
import { School } from '../../../../models/schools';
import { getSchoolByParentCode } from '../../../../services/customer/schools';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Autocomplete } from '../../../../components/Autocomplete/Autocomplete';

import './CustomerStudent.scss';
import { addCustomerStudents } from '../../../../services/customer/students';

interface Props {
  customer: AppCustomer;
  history: History;
  location: Location;
  onLogoutClick: () => void;
}

interface State {
  profile: Profile;
  isLoading: boolean;
  school: School;
}

const SchoolFormSchema = Yup.object().shape({
  forename: Yup.string().required('Required'),
  surname: Yup.string().required('Required'),
  formId: Yup.string().required('Required'),
});

export class CustomerStudent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      profile: undefined,
      school: undefined,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { customer, onLogoutClick } = this.props;
    const { sessionKey } = customer;

    this.setState({
      isLoading: true,
    });

    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    let parentCode;
    let profile;

    getCustomerProfile(customer)
      .then(_profile => {
        profile = _profile;
        parentCode = _profile.parentCode;
        return getSchoolByParentCode(customer, parentCode);
      })
      .then(school => {
        this.setState({
          isLoading: false,
          profile: profile,
          school: school,
        });
      });
  }

  getForms = text => {
    const { customer } = this.props;
    const { school } = this.state;
    return searchFunctionCustomerSchoolForms(customer, school.id, text);
  };

  onSubmit = data => {
    const { customer, history } = this.props;
    const { school } = this.state;
    const schoolId = school.id;
    const formId = data.formId;

    const dataToTransfer = {
      forename: data.forename,
      surname: data.surname,
    };

    this.setState({
      isLoading: true,
    });

    addCustomerStudents(customer, schoolId, formId, dataToTransfer).then(profile => {
      history.push('/customer');
    });
  };

  render() {
    const formSchoolStudents = {
      forename: '',
      surname: '',
      formId: undefined,
      form: {
        id: '',
        name: '',
      },
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className={'bCustomerStudent'}>
              <div className="eCustomerStudentFormTitle">ADD STUDENT</div>
              <div className="eCustomerStudentFormText">
                Please input your child's details below to enable us to link you to the correct student
              </div>
              <Formik initialValues={formSchoolStudents} validationSchema={SchoolFormSchema} onSubmit={this.onSubmit}>
                {({ setFieldValue, values, handleSubmit }) => (
                  <Form>
                    <div>
                      <div>
                        <Field name="forename" className="eCustomerStudentFormInput" placeholder={'Name'} />
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger mCustomerStudentError"
                          name="forename"
                        />
                      </div>
                      <div>
                        <Field name="surname" className="eCustomerStudentFormInput" placeholder={'Surname'} />
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger mCustomerStudentError"
                          name="surname"
                        />
                      </div>
                      <div>
                        <Field
                          name="form"
                          render={({ field }) => {
                            return (
                              <>
                                <Autocomplete
                                  searchFunction={this.getForms}
                                  getElementTitle={getName}
                                  customClass="eCustomerStudentFormInput"
                                  placeholder={'Form'}
                                  defaultItem={values.form}
                                  onSelect={form => {
                                    setFieldValue('formId', form.id);
                                    setFieldValue('form', form);
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <button className="eCustomerStudentFormButton" type="submit">
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
