import { SchoolType } from '../models/schoolTypes';
import { SelectOption } from './table';
import { PhotoType } from '../models/photoTypes';
import { USER_TYPE, USER_TYPE_SERVER_TO_CLIENT_MAPPING } from '../consts/user';
import { MENU_ITEMS, MENU_ITEMS_SERVER_TO_CLIENT_MAPPING } from '../consts/sidebar';
import {
  ORDER_STATUS,
  ORDER_STATUS_SERVER_TO_CLIENT_MAPPING,
  ORDER_DELIVERY_TYPE,
  ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING,
  ORDER_SOURCE,
  ORDER_SOURCE_SERVER_TO_CLIENT_MAPPING,
} from '../consts/order';
import {
  NOTIFICATION_DELIVERY_STATUS_FOR_SELECT,
  NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING_FOR_SELECT,
} from '../consts/notifications';
import {
  NOTIFICATION_CHANNELS_TYPE,
  NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING,
} from '../consts/notificationChannels';
import {
  INTEGRATION_LOG_STATUS_TYPES,
  INTEGRATION_LOG_STATUS_TYPES_SERVER_TO_CLIENT_MAPPING,
} from '../consts/integrationLog';
import { ProductType } from '../models/productTypes';
import {
  REASON_FOR_CANCELLATION, REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING,
  STUDENT_REQUEST_STATUS,
  STUDENT_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../consts/customer';
import { CountryWithCode } from '../models/countryWithCode';
import * as React from 'react';
const countriesWithCode: CountryWithCode[] = require('Src/services/countriesWithCode.json');

export function getSelectOptionForSchoolTypes(schoolTypes: SchoolType[]): SelectOption[] {
  return schoolTypes.map(schoolType => {
    return {
      text: schoolType.name,
      value: schoolType.id,
    };
  });
}

export function getSelectOptionForPhotoTypes(photoTypes: PhotoType[]): SelectOption[] {
  return photoTypes.map(photoType => {
    return {
      text: photoType.name,
      value: photoType.id,
    };
  });
}

export function getSelectOptionForProductTypes(productTypes: ProductType[]): SelectOption[] {
  return productTypes.map(productType => {
    return {
      text: productType.name,
      value: productType.id,
    };
  });
}

export function getSelectOptionForYesOrNo(): SelectOption[] {
  return [
    { text: 'Yes', value: 'true' },
    { text: 'No', value: 'false' },
  ];
}

export function getSelectOptionForYesOrNoInvers(): SelectOption[] {
  return [
    { text: 'Yes', value: 'false' },
    { text: 'No', value: 'true' },
  ];
}

export function getYesOrNoForBoolean(isBoolean): string {
  return isBoolean ? 'Yes' : 'No';
}

export function getBooleanForYesOrNo(selection): boolean {
  return selection === 'Yes';
}

export function getSelectOptionForUserTypes(): SelectOption[] {
  const selectOptions = [
    {
      text: USER_TYPE_SERVER_TO_CLIENT_MAPPING.ADMIN,
      value: USER_TYPE.ADMIN,
    },
    {
      text: USER_TYPE_SERVER_TO_CLIENT_MAPPING.ACCOUNT_MANAGER,
      value: USER_TYPE.ACCOUNT_MANAGER,
    },
    {
      text: USER_TYPE_SERVER_TO_CLIENT_MAPPING.USER,
      value: USER_TYPE.USER,
    },
  ];
  return selectOptions;
}

export function getSelectOptionForUserMenuItems(): SelectOption[] {
  const selectOptions = [];

  for (let menuItem in MENU_ITEMS) {
    selectOptions.push({
      text: MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[menuItem],
      value: MENU_ITEMS[menuItem],
    });
  }

  return selectOptions;
}

export function getSelectOptionForOrderStatus(): SelectOption[] {
  const selectOptions = [];

  for (let key in ORDER_STATUS) {
    selectOptions.push({
      text: ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: ORDER_STATUS[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForDeliveryType(): SelectOption[] {
  const selectOptions = [];

  for (let key in ORDER_DELIVERY_TYPE) {
    selectOptions.push({
      text: ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING[key],
      value: ORDER_DELIVERY_TYPE[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForOrderSource(): SelectOption[] {
  const selectOptions = [];

  for (let key in ORDER_SOURCE) {
    selectOptions.push({
      text: ORDER_SOURCE_SERVER_TO_CLIENT_MAPPING[key],
      value: ORDER_SOURCE[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForStatusNotification(): SelectOption[] {
  const selectOptions = [];

  for (let key in NOTIFICATION_DELIVERY_STATUS_FOR_SELECT) {
    selectOptions.push({
      text: NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING_FOR_SELECT[key],
      value: NOTIFICATION_DELIVERY_STATUS_FOR_SELECT[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForChannelTypeNotification(): SelectOption[] {
  const selectOptions = [];

  for (let key in NOTIFICATION_CHANNELS_TYPE) {
    selectOptions.push({
      text: NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING[key],
      value: NOTIFICATION_CHANNELS_TYPE[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForStatusIntegrationLog(): SelectOption[] {
  const selectOptions = [];

  for (let key in INTEGRATION_LOG_STATUS_TYPES) {
    selectOptions.push({
      text: INTEGRATION_LOG_STATUS_TYPES_SERVER_TO_CLIENT_MAPPING[key],
      value: INTEGRATION_LOG_STATUS_TYPES[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForRequestStatus(): SelectOption[] {
  const selectOptions = [];

  for (let key in STUDENT_REQUEST_STATUS) {
    selectOptions.push({
      text: STUDENT_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: STUDENT_REQUEST_STATUS[key],
    });
  }

  return selectOptions;
}

export function getSelectOptionForNoAutoAcceptReason(): SelectOption[] {
  const selectOptions = [];

  for (let key in REASON_FOR_CANCELLATION) {
    selectOptions.push({
      text: REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING[key],
      value: REASON_FOR_CANCELLATION[key],
    });
  }

  return selectOptions;
}

export function getSelectCountryOptions() {
  return countriesWithCode.map((country, index) => (
    <option value={country.ISO} key={`code_${index}`}>
      {country.ISO} - {country.name}
    </option>
  ));
}
