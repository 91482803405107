import axios from 'axios';

export function login(email: string, password: string) {
  return axios.post(`${window.apiBase}/i/login`, { email, password }, {}).then(response => {
    return response.data;
  });
}

export function logout(sessionKey) {
  const config = {
    headers: { usid: sessionKey },
  };

  return axios.delete(`${window.apiBase}/i/sessions/${sessionKey}`, config);
}

export function loginOneTimeCode(username: string, password: string, filter?) {
  const config = {
    params: {
      filter,
    },
  };

  return axios.post(`${window.apiBase}/public/images/newAvailable`, { username, password }, config).then(response => {
    return {
      status: response.status,
      data: response.data,
    };
  });
}

export function loginCustomer(email: string, password: string) {
  return axios.post(`${window.apiBase}/customer/login`, { email, password }, {}).then(response => {
    return response.data;
  });
}

export function logoutCustomer(sessionKey) {
  const config = {
    headers: { usid: sessionKey },
  };

  return axios.delete(`${window.apiBase}/customer/sessions/${sessionKey}`, config);
}
