import * as React from 'react';
import { Loader } from '../../../../../../../components/Loader/Loader';
import { FunctionComponent } from 'react';

interface Props {
  onButtonClick: () => void;
  resultDownloadText: string;
  isLoadingResult: boolean;
}

export const OrderResultDownloadForm: FunctionComponent<Props> = props => {
  const { onButtonClick, resultDownloadText, isLoadingResult } = props;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="modal-header">
            <h5 className="modal-title">Result</h5>
            <button type="button" className="close" onClick={onButtonClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body mPreWrap">{resultDownloadText}</div>
          <div className="form-group">{isLoadingResult && <Loader />}</div>
          <button type="button" className="btn btn-primary" onClick={onButtonClick}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
OrderResultDownloadForm.displayName = 'OrderResultDownloadForm';
