import { useEffect } from 'react';
import * as React from 'react';
import { PAY360_RESULT } from '../../../consts/payment';
import { Loader } from '../../../components/Loader/Loader';

function AllPaymentsCancelIframe() {
  useEffect(() => {
    window.top.postMessage(PAY360_RESULT.CANCELED, '*');
  }, []);
  return <Loader />;
}

export default AllPaymentsCancelIframe;
