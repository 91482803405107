import * as React from 'react';
import { getPrivateLinkImages, getPrivateLinkTicketsCount } from '../../../services/public/privateLink';
import { Loader } from '../../../components/Loader/Loader';
import { sortImagesByPhotoType } from '../../../helpers/images';
import { ImageTicketsView } from '../../../components/ImageTicketsView/ImageTicketsView';
import { LIMIT_DOWNLOAD_IMAGES } from '../../../consts/images';

interface Props {
  match: any;
}

interface State {
  tickets: any[];
  ticketsCount: number;
  isLoading: boolean;
  skip: number;
  selectedTicketIds: any[];
}

export class PrivateLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tickets: [],
      ticketsCount: 0,
      selectedTicketIds: [],
      isLoading: false,
      skip: 0,
    };
  }

  componentDidMount(): void {
    const { match } = this.props;
    const link = match.params.link;

    this.setState({
      isLoading: true,
    });

    const { skip } = this.state;

    const filter = {
      skip: skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
      order: 'job.jobDate ASC',
    };

    getPrivateLinkImages(link, filter).then(tickets => {
      const sortedImages = sortImagesByPhotoType(tickets);
      getPrivateLinkTicketsCount(link).then(({ count }) => {
        this.setState({
          tickets: sortedImages,
          ticketsCount: count,
          isLoading: false,
          skip: LIMIT_DOWNLOAD_IMAGES,
        });
      });
    });
  }

  onLoadMoreClick = () => {
    const { skip, tickets } = this.state;
    const filter = {
      skip: skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
      order: 'job.jobDate ASC',
    };

    const { match } = this.props;
    const link = match.params.link;

    getPrivateLinkImages(link, filter).then(nextTickets => {
      if (Object.keys(nextTickets).length) {
        const nextSkip = skip + LIMIT_DOWNLOAD_IMAGES;
        const sortedImages = sortImagesByPhotoType(nextTickets);

        this.setState({
          tickets: [...tickets, ...sortedImages],
          skip: nextSkip,
        });
      }
    });
  };

  onGalleryImageClick = (ticket: any) => {
    const { selectedTicketIds } = this.state;

    if (selectedTicketIds.includes(ticket.id)) {
      const nextSelectedTicketIds = selectedTicketIds.filter(selectedTicketId => selectedTicketId !== ticket.id);
      this.setState({
        selectedTicketIds: nextSelectedTicketIds,
      });
    } else {
      this.setState({
        selectedTicketIds: [...selectedTicketIds, ticket.id],
      });
    }
  };

  render() {
    const { tickets, isLoading, selectedTicketIds, skip, ticketsCount } = this.state;
    const isSelectedTicketsExist = typeof selectedTicketIds !== 'undefined';

    const images = tickets.map(ticket => {
      if (isSelectedTicketsExist && selectedTicketIds.indexOf(ticket.id) !== -1) {
        return (
          <ImageTicketsView key={ticket.id} ticket={ticket} onImageClick={this.onGalleryImageClick} isSelected={true} />
        );
      } else {
        return (
          <ImageTicketsView
            key={ticket.id}
            ticket={ticket}
            onImageClick={this.onGalleryImageClick}
            isSelected={false}
          />
        );
      }
    });

    if (isLoading) {
      return <Loader />;
    }

    const isLoadMoreExist = skip < ticketsCount;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className={'text-center col-xl-12'}>
            <h1>Welcome!</h1>
          </div>
          {images}
          {isLoadMoreExist && (
            <div className={'text-center col-xl-12 bLoadMoreButton'} onClick={this.onLoadMoreClick}>
              LOAD MORE
            </div>
          )}
        </div>
      </div>
    );
  }
}
