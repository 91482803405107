import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Customers } from './Customers';
import { CustomerPermissions } from './CustomerPermissions/CustomerPermissions';
import { CustomerNotifications } from './CustomerNotifications/CustomerNotifications';
import { CustomerStudents } from './CustomerStudents/CustomerStudents';

export const CustomersRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/customers'} render={props => <Customers {...props} user={allProps.user} />} />

      <Route
        exact
        path={'/customers/permissions'}
        render={props => <CustomerPermissions {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/customers/notifications'}
        render={props => <CustomerNotifications {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/customers/students'}
        render={props => <CustomerStudents {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

CustomersRouting.displayName = 'CustomersRouting';
