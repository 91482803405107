import * as React from 'react';
import * as propz from 'propz';
import { Customer } from '../../../../../../../models/customers';
import { getIsBillingPrimary, getIsDeliveryPrimary } from '../../../../../../../helpers/accessor';

interface Props {
  customer: Customer;
}

export function CustomerAddressSummary(props: Props) {
  const { customer } = props;
  const { addressBook } = customer;

  const rows = addressBook.map((address, index) => {
    const firstName = propz.get(address, ['firstName'], '');
    const lastName = propz.get(address, ['lastName'], '');
    const email = propz.get(address, ['email'], '');
    const phone = propz.get(address, ['phone'], '');
    const country = propz.get(address, ['country'], '');
    const region = propz.get(address, ['region'], '');
    const town = propz.get(address, ['town'], '');
    const postcode = propz.get(address, ['postcode'], '');
    const address1 = propz.get(address, ['address1'], '');
    const isBillingPrimary = getIsBillingPrimary(address);
    const isDeliveryPrimary = getIsDeliveryPrimary(address);

    return (
      <tr key={`customer_address_book_summary_${index}`}>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td>{country}</td>
        <td>{region}</td>
        <td>{town}</td>
        <td>{postcode}</td>
        <td>{address1}</td>
        <td>{isDeliveryPrimary}</td>
        <td>{isBillingPrimary}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mt-3'}>
      <thead>
        <tr>
          <th>First name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Country</th>
          <th>Region</th>
          <th>Town</th>
          <th>Postcode</th>
          <th>Address1</th>
          <th>Delivery primary</th>
          <th>Billing primary</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
