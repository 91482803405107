import * as React from 'react';
import * as Yup from 'yup';
import {
  VOUCHER_DISCOUNT_TYPE,
  VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING,
  VOUCHER_PURCHASE_SOURCE,
  VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING,
  VOUCHER_SCOPE,
} from 'Src/consts/voucher';
import { Button } from 'Src/components/Button/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { getName, searchFunctionProducts } from '../../../../../../../helpers/autocomplete';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import { AppUser } from '../../../../../App';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import { PRICE, PRICE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/offer';
import { Customer } from '../../../../../../../models/customers';
import { getCurrencyMask } from '../../../../../../../helpers/currencyMask';
import { Switch } from '../../../../../../../components/Switch/Switch';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  customers: Customer[];
  user: AppUser;
}

interface State {
  discountType: string;
  priceType: string;
}

export class CustomerVoucherForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      discountType: VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT,
      priceType: PRICE.AMOUNT,
    };
  }

  getProducts = (text: string) => {
    const { user } = this.props;
    return searchFunctionProducts(user, text);
  };

  renderCustomers(customers) {
    return customers.map(customer => {
      return <div key={customer.customerId}>{`${customer.firstName} ${customer.lastName}`}</div>;
    });
  }

  render() {
    const { onCancel, onSubmit, customers } = this.props;
    const { discountType, priceType } = this.state;

    const now = new Date();
    const weekAhead = new Date();
    weekAhead.setDate(weekAhead.getDate() + 7);

    const voucherForm = {
      code: '',
      description: '',
      startDate: now,
      endDate: weekAhead,
      isOneOffVoucher: false,
      discount: {
        discountType: VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT,
        discountAmount: '',
        minOrderAmount: '',
        priceType: PRICE.AMOUNT,
        product: {
          productId: '',
          name: '',
        },
      },
      scope: {
        scopeType: VOUCHER_SCOPE.LIST_OF_CUSTOMERS,
        purchaseSource: VOUCHER_PURCHASE_SOURCE.ALL_SOURCES,
        customers: customers.map(customer => ({
          customerId: customer.id,
          firstName: customer.firstName,
          lastName: customer.lastName,
        })),
        schools: [],
        jobs: [],
      },
    };

    const isDiscountTypeProductDiscount = discountType === VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT;
    const isDiscountTypeOrderDiscount = discountType === VOUCHER_DISCOUNT_TYPE.ORDER_DISCOUNT;
    const isDiscountTypeReducedPostage = discountType === VOUCHER_DISCOUNT_TYPE.REDUCED_POSTAGE;
    const isDiscountTypeFreePostage = discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE;

    const isPriceTypeAmount = priceType === PRICE.AMOUNT;
    const isPriceTypePercent = priceType === PRICE.PERCENT;

    let VoucherSchema;

    switch (true) {
      case isDiscountTypeProductDiscount:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            product: Yup.object().shape({
              productId: Yup.string().required('Required'),
              name: Yup.string().required('Required'),
            }),
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
      case isDiscountTypeOrderDiscount:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
      case isDiscountTypeReducedPostage:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
      case isDiscountTypeFreePostage:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik initialValues={voucherForm} validationSchema={VoucherSchema} onSubmit={onSubmit}>
              {({ handleSubmit, setFieldValue, values }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Code</label>
                      <Field name="code" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="code" />
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <Field name="description" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="description" />
                    </div>

                    <div className="form-group">
                      <label>Start date</label>
                      <Field name="startDate">
                        {({ field }) => (
                          <div className="mb-3">
                            <DatePicker
                              selected={field.value}
                              onChange={startDate => {
                                setFieldValue('startDate', startDate);
                              }}
                              className="form-control"
                              dateFormat={'dd-MM-yyyy'}
                              customInput={
                                <MaskedInput
                                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                  placeholder="DD-MM-YYYY"
                                />
                              }
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="startDate" />
                    </div>

                    <div className="form-group">
                      <label>End date</label>
                      <Field name="endDate">
                        {({ field }) => (
                          <div className="mb-3">
                            <DatePicker
                              selected={field.value}
                              onChange={endDate => {
                                setFieldValue('endDate', endDate);
                              }}
                              className="form-control"
                              dateFormat={'dd-MM-yyyy'}
                              customInput={
                                <MaskedInput
                                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                  placeholder="DD-MM-YYYY"
                                />
                              }
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="endDate" />
                    </div>

                    <div className="form-group">
                      <label>Discount type</label>
                      <Field
                        name="discount.discountType"
                        component="select"
                        className="form-control mb-3"
                        onChange={event => {
                          const discountType = event.target.value;

                          if (discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE) {
                            setFieldValue('discount.discountAmount', '');
                          }

                          setFieldValue('discount.discountType', discountType);
                          setFieldValue('discount.product.productId', '');
                          this.setState({ discountType });
                        }}
                      >
                        <option value={VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.PRODUCT_DISCOUNT}
                        </option>
                        <option value={VOUCHER_DISCOUNT_TYPE.ORDER_DISCOUNT}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.ORDER_DISCOUNT}
                        </option>
                        <option value={VOUCHER_DISCOUNT_TYPE.REDUCED_POSTAGE}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.REDUCED_POSTAGE}
                        </option>
                        <option value={VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.FREE_POSTAGE}
                        </option>
                      </Field>
                    </div>

                    {isDiscountTypeProductDiscount && (
                      <>
                        <div>Product</div>
                        <Field name="discount.product.productId">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getProducts}
                              getElementTitle={getName}
                              customClass="mFullWidth mb-3"
                              defaultItem={values.discount.product}
                              onSelect={product => {
                                setFieldValue('discount.product.productId', product.id);
                                setFieldValue('discount.product.name', product.name);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger"
                          name={`discount.product.productId`}
                        />
                      </>
                    )}

                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount || isDiscountTypeReducedPostage) && (
                      <div className="form-group">
                        <label>Discount</label>
                        <Field
                          name="discount.priceType"
                          component="select"
                          className="form-control mb-3"
                          onChange={event => {
                            const priceType = event.target.value;
                            setFieldValue('discount.priceType', priceType);
                            setFieldValue('discount.discountAmount', 0);
                            this.setState({ priceType });
                          }}
                        >
                          <option value={PRICE.AMOUNT}>{PRICE_SERVER_TO_CLIENT_MAPPING.AMOUNT}</option>
                          <option value={PRICE.PERCENT}>{PRICE_SERVER_TO_CLIENT_MAPPING.PERCENT}</option>
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name="discount.priceType" />
                      </div>
                    )}

                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount || isDiscountTypeReducedPostage) &&
                      isPriceTypeAmount && (
                        <div className="form-group">
                          <label>Discount amount</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                            </div>
                            <Field name="discount.discountAmount">
                              {({ field }) => (
                                <MaskedInput
                                  type="text"
                                  className="form-control"
                                  mask={getCurrencyMask}
                                  onChange={event => {
                                    setFieldValue(`discount.discountAmount`, event.target.value);
                                  }}
                                  value={values.discount.discountAmount}
                                />
                              )}
                            </Field>
                          </div>
                          <ErrorMessage
                            component="div"
                            className="alert alert-danger mt-3"
                            name="discount.discountAmount"
                          />
                        </div>
                      )}

                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount || isDiscountTypeReducedPostage) &&
                      isPriceTypePercent && (
                        <div className="form-group">
                          <label>Discount percent</label>
                          <div className="input-group">
                            <Field name="discount.discountAmount" className="form-control" />
                            <div className="input-group-append">
                              <div className="input-group-text">%</div>
                            </div>
                          </div>
                          <ErrorMessage
                            component="div"
                            className="alert alert-danger mt-3"
                            name="discount.discountAmount"
                          />
                        </div>
                      )}

                    <div className="form-group">
                      <label>Min order amount</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                        </div>
                        <Field name="discount.minOrderAmount">
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              className="form-control"
                              mask={getCurrencyMask}
                              onChange={event => {
                                setFieldValue(`discount.minOrderAmount`, event.target.value);
                              }}
                              value={values.discount.minOrderAmount}
                            />
                          )}
                        </Field>
                      </div>
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger mt-3"
                        name="discount.minOrderAmount"
                      />
                    </div>

                    <div className="form-group">
                      <label>Purchase source</label>
                      <Field
                        name="scope.purchaseSource"
                        component="select"
                        className="form-control mb-3"
                        onChange={event => {
                          const purchaseSource = event.target.value;
                          setFieldValue('scope.purchaseSource', purchaseSource);
                        }}
                      >
                        <option value={VOUCHER_PURCHASE_SOURCE.ALL_SOURCES}>
                          {VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING.ALL_SOURCES}
                        </option>
                        <option value={VOUCHER_PURCHASE_SOURCE.WEB_PURCHASE}>
                          {VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING.WEB_PURCHASE}
                        </option>
                        <option value={VOUCHER_PURCHASE_SOURCE.MOBILE_PURCHASE}>
                          {VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING.MOBILE_PURCHASE}
                        </option>
                      </Field>
                    </div>

                    <Field name="isOneOffVoucher">
                      {({ field }) => <Switch {...field} text={'One off voucher'} customClass="mb-3" />}
                    </Field>

                    <div className={'mb-2'}>Customers</div>
                    {this.renderCustomers(values.scope.customers)}

                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
