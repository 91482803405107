import { AppUser } from '../../views/App/App';
import axios from 'axios';

export function getCustomerEmails(user: AppUser, jobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/jobs/${jobId}/customerEmails`, config).then(response => {
    return response.data;
  });
}

export function getEmails(user: AppUser, jobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/jobs/${jobId}/emails`, config).then(response => {
    return response.data;
  });
}
