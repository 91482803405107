import * as React from 'react';
import { Slider } from '../../../../../../../models/slider';
import * as propz from 'propz';
import { SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/slider';
import { FunctionComponent } from 'react';

interface Props {
  sliderItem: Slider;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Delay (sec)', field: 'delay' },
  { title: 'Type', field: 'type' },
];

export const SliderSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;
    const { sliderItem } = props;

    switch (field) {
      case 'name':
        contentCell = propz.get(sliderItem, ['name'], '');
        break;
      case 'type':
        const { type } = sliderItem;
        contentCell = typeof type !== 'undefined' ? SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING[type] : '';
        break;
      case 'delay':
        contentCell = propz.get(sliderItem, ['delay'], '');
        break;
      default:
        contentCell = propz.get(sliderItem, [row.field], '');
    }

    return (
      <tr key={`slider_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mt-3'}>
      <tbody>{rows}</tbody>
    </table>
  );
};
SliderSummary.displayName = 'SliderSummary';
