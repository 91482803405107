import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  getPagesCount,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { DeliveryCharge } from 'Src/models/deliveryCharges';
import {
  addDeliveryCharge,
  getAllDeliveryCharges,
  getDeliveryCharges,
  getDeliveryChargesCount,
  updateDeliveryCharge,
} from '../../../../../../services/superadmin/deliveryCharges';
import { DeliveryChargeForm } from './DeliveryChargeForm/DeliveryChargeForm';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { LIMIT } from '../../../../../../consts/table';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Minimum delivery charge',
    field: 'minimumDeliveryCharge',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['minimumDeliveryCharge'],
  },
  {
    text: 'Maximum delivery charge',
    field: 'maximumDeliveryCharge',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['maximumDeliveryCharge'],
  },
  {
    text: 'International delivery charge',
    field: 'internationalDeliveryCharge',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['internationalDeliveryCharge'],
  },
  {
    text: 'Courier delivery charge',
    field: 'courierDeliveryCharge',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['courierDeliveryCharge'],
  },
];

interface State {
  items: DeliveryCharge[];
  currentPage: number;
  selectedItems: DeliveryCharge[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isDeliveryChargeFormModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class DeliveryCharges extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isDeliveryChargeFormModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getDeliveryCharges(user, serverQueryFilter);
    const getItemsCountPromise = getDeliveryChargesCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllDeliveryCharges(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  renderFormModal(): React.ReactNode {
    const { isDeliveryChargeFormModalOpen, selectedItems } = this.state;

    if (selectedItems.length === 1) {
      const deliveryCharge = selectedItems[0];
      return (
        <SimpleModal isOpen={isDeliveryChargeFormModalOpen}>
          <DeliveryChargeForm
            deliveryCharge={deliveryCharge}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSubmitForm}
          />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isDeliveryChargeFormModalOpen}>
          <DeliveryChargeForm onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
        </SimpleModal>
      );
    }
  }

  onSubmitForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;

    const dataToTransfer = {
      minimumDeliveryCharge: Number(data.minimumDeliveryCharge),
      maximumDeliveryCharge: Number(data.maximumDeliveryCharge),
      internationalDeliveryCharge: Number(data.internationalDeliveryCharge),
      courierDeliveryCharge: Number(data.courierDeliveryCharge),
    };

    this.setState({
      isLoading: true,
    });

    if (selectedItems.length === 1) {
      const deliveryCharge = selectedItems[0];
      const { id } = deliveryCharge;

      updateDeliveryCharge(user, id, dataToTransfer).then(deliveryCharge => {
        this.setState({
          isDeliveryChargeFormModalOpen: false,
          selectedItems: [deliveryCharge],
          isSelectAllChecked: false,
        });
        this.setItems();
      });
    } else {
      addDeliveryCharge(user, dataToTransfer).then(deliveryCharge => {
        const lastPage = getPagesCount(total + 1, LIMIT);
        this.setState({
          isDeliveryChargeFormModalOpen: false,
          selectedItems: [deliveryCharge],
          isSelectAllChecked: false,
        });
        this.setCurrentPageParams(lastPage);
        this.setItems();
      });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      isDeliveryChargeFormModalOpen: false,
    });
  };

  onCreateDeliveryChargeClick = () => {
    this.setState({
      isDeliveryChargeFormModalOpen: true,
    });
  };

  onEditDeliveryChargeClick = () => {
    this.setState({
      isDeliveryChargeFormModalOpen: true,
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isDeliveryChargeFormModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isDeliveryChargeFormModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create delivery charge',
        onItemClick: this.onCreateDeliveryChargeClick,
        isActive: total === 0,
      },
      {
        itemText: 'Edit delivery charge',
        onItemClick: this.onEditDeliveryChargeClick,
        isActive: selectedItems.length === 1,
      },
    ];

    const filterOptions = {};

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderFormModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
