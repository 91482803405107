import * as React from 'react';
import { PhotoType } from 'Src/models/photoTypes';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  photoType?: PhotoType;
}

const PhotoTypeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export function PhotoTypeForm(props: Props) {
  const { photoType, onSubmit, onCancel } = props;
  const isPhotoTypeExist = typeof photoType !== 'undefined';
  const photoTypeForm = {
    name: isPhotoTypeExist ? photoType.name : '',
    mainType: isPhotoTypeExist ? photoType.mainType : false,
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={photoTypeForm}
            validationSchema={PhotoTypeSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Photo Type Name</label>
                    <Field name="name" className="form-control mb-3" />
                    <ErrorMessage
                      component="div"
                      className="alert alert-danger"
                      name="name"
                    />
                  </div>

                  <Field name="mainType">
                    {({ field }) => (
                      <Switch
                        {...field}
                        text={'Main type'}
                        customClass="mb-3"
                      />
                    )}
                  </Field>

                  <Button
                    onClick={onCancel}
                    text={'Cancel'}
                    customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                  />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
