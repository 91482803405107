import * as React from 'react';
import { SimpleSidebarItem } from './Sidebar';
import { NavLink } from 'react-router-dom';

interface Props {
  item: SimpleSidebarItem;
}

export function SidebarItem(props: Props) {
  const { itemTitle, to, isVisible } = props.item;

  if (!isVisible) {
    return null;
  }

  return (
    <NavLink
      activeStyle={{ fontWeight: 'bold', color: '#7D003F' }}
      className={'nav-link eSidebarItem'}
      to={to}
    >
      {itemTitle}
    </NavLink>
  );
}
