import * as React from 'react';
import { History, Location } from 'history';
import { AppCustomer } from '../../AppOrders';
import { getCustomerOrderImageTickets } from '../../../../services/customer/orderImages';
import { Loader } from '../../../../components/Loader/Loader';
import './CustomerOrderImages.scss';
import { downloadImage } from '../../../../services/customer/images';

interface Props {
  match: any;
  history: History;
  location: Location;
  customer: AppCustomer;
}
interface State {
  isLoading: boolean;
  orderImages: OrderImage[];
}

interface OrderImage {
  fileName: string;
  ticketId: string;
}

export class CustomerOrderImages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      orderImages: [],
    };
  }

  componentDidMount() {
    const { match, customer } = this.props;
    const orderId = match.params.orderId;

    this.setState({
      isLoading: true,
    });

    getCustomerOrderImageTickets(customer, orderId).then(tickets => {
      this.setState({
        isLoading: false,
        orderImages: tickets,
      });
    });
  }

  onDownloadImageClick = (orderImage: OrderImage) => {
    const { customer } = this.props;
    this.setState({
      isLoading: true,
    });

    downloadImage(customer, orderImage.ticketId).then(image => {
      const blob = new Blob([image], { type: 'image/*' });
      if (window.navigator.msSaveBlob) {
        // IE 10+
        window.navigator.msSaveBlob(blob, orderImage.fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          /*global URL:true*/
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', orderImage.fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      this.setState({
        isLoading: false,
      });
    });
  };

  render() {
    const { orderImages, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="bCustomerOrderImagesWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h3 className={'text-center mt-3 mb-3'}>Download images</h3>
            </div>
            {orderImages.length === 0 ? (
              <div className={'col-xl-12'}>No downloadable images</div>
            ) : (
              orderImages.map(orderImage => {
                return (
                  <div key={orderImage.ticketId} className={'col-xl-4 eCustomerOrderImageWrapper'}>
                    <img
                      className={'eCustomerOrderImage'}
                      src={`${window.apiFile}/storage/images/${orderImage.ticketId}`}
                      alt="image"
                    />
                    <div className={'eCustomerOrderImageFileName'}>{orderImage.fileName}</div>
                    <button
                      className={'eCustomerOrderImageButton'}
                      onClick={() => this.onDownloadImageClick(orderImage)}
                    >
                      Download
                    </button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}
