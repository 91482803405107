import * as React from 'react';
import { Route, Switch } from 'react-router';
import { JobImages } from './JobImages/JobImages';
import { Jobs } from './Jobs';

export const JobsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/jobs'}
        render={props => <Jobs {...props} user={allProps.user} />}
      />

      <Route
        exact
        path={'/jobs/images'}
        render={props => <JobImages {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

JobsRouting.displayName = 'JobsRouting';
