import * as React from 'react';
import { Component } from 'react';
import { School } from 'Src/models/schools';
import { getSchoolUserSchool, getBucketConfigBP } from '../../../services/schoolUser/schools';
import { getSchoolUserSchoolUsers } from '../../../services/schoolUser/schools';
import { getSchoolUserSchoolJobs } from '../../../services/schoolUser/schoolJobs';
import { AppSchoolsUser2 } from '../AppSchools2';
import { Loader } from '../../../components/Loader/Loader';
import * as AWS from 'aws-sdk';

interface FolderCount {
  folders: number;
  files: number;
}

interface FolderCounts {
  bookings: FolderCount;
  links: FolderCount;
  pdf: FolderCount;
  downloads: FolderCount;
}

interface Props {
  user: AppSchoolsUser2;
}

interface State {
  school: School | null;
  loading: boolean;
  error: string | null;
  totalUsers: number;
  adminUsers: number;
  notificationEnabledUsers: number;
  totalJobs: number;
  approvedJobs: number;
  jobsNeedChecking: number;
  folderCounts: FolderCounts;
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  bucketName: string;
  bucketRegion: string;
}

export class SchoolAppDashboard2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      school: null,
      loading: true,
      error: null,
      totalUsers: 0,
      adminUsers: 0,
      notificationEnabledUsers: 0,
      totalJobs: 0,
      approvedJobs: 0,
      jobsNeedChecking: 0,
      folderCounts: {
        bookings: { folders: 0, files: 0 },
        links: { folders: 0, files: 0 },
        pdf: { folders: 0, files: 0 },
        downloads: { folders: 0, files: 0 },
      },
      accessKeyId: '',
      secretAccessKey: '',
      sessionToken: '',
      bucketName: '',
      bucketRegion: '',
    };
  }

  componentDidMount() {
    const { user } = this.props;

    getBucketConfigBP(user)
      .then(config => {
        const { bucketName, bucketRegion, AccessKeyId, SecretKey, SessionToken } = config;
        this.setState(
          {
            accessKeyId: AccessKeyId,
            secretAccessKey: SecretKey,
            sessionToken: SessionToken,
            bucketName,
            bucketRegion,
          },
          () => {
            const folderAndFileCountPromise = this.fetchFolderAndFileCounts();

            if (user.isSchoolAdmin) {
              Promise.all([
                getSchoolUserSchool(user),
                getSchoolUserSchoolUsers(user, {}),
                getSchoolUserSchoolJobs(user, {}),
                folderAndFileCountPromise,
              ])
                .then(([school, users, jobs, folderCounts]) => {
                  const adminUsers = users.filter(user => user.isSchoolAdmin).length;
                  const notificationEnabledUsers = users.filter(user => user.isDataNotificationEnabled).length;
                  const approvedJobs = jobs.filter(job => job.status === 'APPROVED_BY_SCHOOL').length;
                  const jobsNeedChecking = jobs.filter(job => job.status === 'NEW').length;

                  this.setState({
                    school,
                    totalUsers: users.length,
                    adminUsers,
                    notificationEnabledUsers,
                    totalJobs: jobs.length,
                    approvedJobs,
                    jobsNeedChecking,
                    folderCounts,
                    loading: false,
                  });
                })
                .catch(error => this.setState({ error: error.message, loading: false }));
            } else {
              folderAndFileCountPromise
                .then(folderCounts => this.setState({ folderCounts, loading: false }))
                .catch(error => this.setState({ error: error.message, loading: false }));
            }
          }
        );
      })
      .catch(error => this.setState({ error: error.message, loading: false }));
  }

  getBucketCredentials = () => {
    const { bucketName, bucketRegion, accessKeyId, secretAccessKey, sessionToken } = this.state;

    AWS.config.update({
      region: bucketRegion,
    });

    const credentials = new AWS.Credentials({
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });

    AWS.config.credentials = credentials;

    return new AWS.S3({
      params: { Bucket: bucketName },
    });
  };

  fetchFolderAndFileCounts = async (): Promise<FolderCounts> => {
    const { user } = this.props;
    const s3 = this.getBucketCredentials();
    const paths = ['bookings', 'links', 'pdf', 'downloads'];

    const folderCounts: FolderCounts = {
      bookings: { folders: 0, files: 0 },
      links: { folders: 0, files: 0 },
      pdf: { folders: 0, files: 0 },
      downloads: { folders: 0, files: 0 },
    };

    const logAndCount = async (prefix: string): Promise<FolderCount> => {
      let totalFolders = 0;
      let totalFiles = 0;
      let nextContinuationToken: string | undefined = undefined;

      do {
        const response = await s3
          .listObjectsV2({
            Bucket: this.state.bucketName,
            Prefix: prefix,
            Delimiter: '/',
            ContinuationToken: nextContinuationToken,
          })
          .promise();

        if (response.Contents) {
          const fileCount = response.Contents.filter(file => !file.Key.endsWith('/')).length;
          totalFiles += fileCount;
        }

        if (response.CommonPrefixes) {
          const folderCount = response.CommonPrefixes.length;
          totalFolders += folderCount;

          await Promise.all(
            response.CommonPrefixes.map(async prefixObj => {
              if (prefixObj.Prefix) {
                const subResult = await logAndCount(prefixObj.Prefix!);
                totalFolders += subResult.folders;
                totalFiles += subResult.files;
              }
            })
          );
        }

        nextContinuationToken = response.NextContinuationToken;
      } while (nextContinuationToken);

      return { folders: totalFolders, files: totalFiles };
    };

    await Promise.all(
      paths.map(async path => {
        try {
          const prefix = `${user.activeSchool.code}/${path}/`;
          folderCounts[path as keyof FolderCounts] = await logAndCount(prefix);
        } catch (error) {
          console.error(`Error fetching counts for ${path}:`, error);
          folderCounts[path as keyof FolderCounts] = { folders: 0, files: 0 };
        }
      })
    );

    return folderCounts;
  };

  render() {
    const {
      school,
      loading,
      error,
      totalUsers,
      adminUsers,
      notificationEnabledUsers,
      totalJobs,
      approvedJobs,
      jobsNeedChecking,
      folderCounts,
    } = this.state;

    const { user } = this.props;

    if (loading) return <Loader />;

    if (error) return <div>Error: {error}</div>;

    const formatCountText = (count: number, singular: string, plural: string) =>
      `${count} ${count === 1 ? singular : plural}`;

    return (
      <div className="dashboard-container">
        {user.isSchoolAdmin && (
          <>
            <div className="dashboard-card school-details-card">
              <div className="dashboard-card-content">
                <h2 className="dashboard-card-header">
                  <div className="dashboard-card-icon">
                    <img src="/dist/images/icon/establishmentInformation.png" alt="School Icon" />
                  </div>
                  <span>School Details</span>
                </h2>
                {school && (
                  <div>
                    <p>
                      <strong>Name:</strong> {school.name}
                    </p>
                    <p>
                      <strong>Address:</strong> {school.schoolAddress}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="dashboard-card users-details-card">
              <div className="dashboard-card-content">
                <h2 className="dashboard-card-header">
                  <div className="dashboard-card-icon">
                    <img src="/dist/images/icon/users.png" alt="Users Icon" />
                  </div>
                  <span>School Users</span>
                </h2>
                <p>
                  <strong>Total Users:</strong> {totalUsers}
                </p>
                <p>
                  <strong>Admin Users:</strong> {adminUsers}
                </p>
                <p>
                  <strong>Users with Notifications Enabled:</strong> {notificationEnabledUsers}
                </p>
              </div>
            </div>

            <div className="dashboard-card jobs-details-card">
              <div className="dashboard-card-content">
                <h2 className="dashboard-card-header">
                  <div className="dashboard-card-icon">
                    <img src="/dist/images/icon/photo.png" alt="Jobs Icon" />
                  </div>
                  <span>Jobs</span>
                </h2>
                <p>
                  <strong>Total Jobs:</strong> {totalJobs}
                </p>
                <p>
                  <strong>Approved Jobs: </strong>
                  <span className="circle green">{approvedJobs}</span>
                </p>
                <p>
                  <strong>Jobs Needing Checking: </strong>
                  <span className="circle red">{jobsNeedChecking}</span>
                </p>
              </div>
            </div>
          </>
        )}
        <div className="dashboard-card folder-counts-card">
          <div className="dashboard-card-content">
            <h2 className="dashboard-card-header">
              <div className="dashboard-card-icon">
                <img src="/dist/images/icon/folders.png" alt="Folders Icon" />
              </div>
              <span>Folders and Files Summary</span>
            </h2>
            <p>
              <strong>Bookings:</strong> {formatCountText(folderCounts.bookings.folders, 'folder', 'folders')},{' '}
              {formatCountText(folderCounts.bookings.files, 'file', 'files')}
            </p>
            <p>
              <strong>Links & Logins for Parents:</strong>{' '}
              {formatCountText(folderCounts.links.folders, 'folder', 'folders')},{' '}
              {formatCountText(folderCounts.links.files, 'file', 'files')}
            </p>
            <p>
              <strong>PDF Documents:</strong> {formatCountText(folderCounts.pdf.folders, 'folder', 'folders')},{' '}
              {formatCountText(folderCounts.pdf.files, 'file', 'files')}
            </p>
            <p>
              <strong>Images Available to Download:</strong>{' '}
              {formatCountText(folderCounts.downloads.folders, 'folder', 'folders')},{' '}
              {formatCountText(folderCounts.downloads.files, 'file', 'files')}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
