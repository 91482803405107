import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { checkFormName } from '../../../../../../../../services/superadmin/schoolForms';
import { AppUser } from '../../../../../../App';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  form?: any;
  user: AppUser;
  schoolId: string;
}

export function SchoolFormForm(props: Props) {
  const { form, onSubmit, onCancel, user, schoolId } = props;

  const SchoolFormSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .test('name', 'Duplicate error', value => {
        if (!value) {
          return true;
        }

        return checkFormName(user, schoolId, value).then(res => {
          return res.isAvailable;
        });
      }),
  });

  const formSchoolForm = {
    name: propz.get(form, ['name'], ''),
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={formSchoolForm}
            validationSchema={SchoolFormSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Name</label>
                    <Field name="name" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="name" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
