import * as Lazy from 'lazy.js';

export function getSchoolsFromBasketImagesUniq(images): { schoolId: string; schoolName: string; schoolCode: string }[] {
  const uniqSchools = Lazy(images)
    .filter(image => image.isSchoolDeliveryAvailable === true)
    .map(image => ({
      schoolId: image.schoolId,
      schoolName: image.schoolName,
      schoolCode: image.schoolCode,
    }))
    .uniq('schoolId')
    .toArray();

  return uniqSchools;
}
