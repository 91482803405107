import * as React from 'react';
import * as propz from 'propz';
import {Order} from "../../../../../../../models/orders";
import * as Moment from "moment";
import {DATE_TIME_FORMAT} from "../../../../../../../consts/consts";

interface Props {
    orders: Order[];
}

export function CustomerOrdersSummary(props: Props) {
  const { orders } = props;


    const rows = orders.map((orders, index) => {
        const createdAt = propz.get(orders, ['createdAt'], '');
        const createdDate = typeof createdAt !== 'undefined' ? Moment(new Date(createdAt)).format(DATE_TIME_FORMAT) : '';
        const orderNumber = propz.get(orders, ['orderNumber'], '');
        const orderAmount = propz.get(orders, ['orderAmount'], '');
        const orderStatus = propz.get(orders, ['orderStatus'], '');

      return (
        <tr key={`customer_orders_summary_${index}`}>
          <td>{createdDate}</td>
          <td>{orderNumber}</td>
          <td>{orderAmount}</td>
          <td>{orderStatus}</td>
        </tr>
      );
    });

  return (
    <table className={'table table-sm mt-3'}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Order number</th>
          <th>Order amount</th>
          <th>Order status</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
