import * as React from 'react';
import './LoginForm.css';

interface Props {
  onEmailChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onRememberMeChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onFormSubmit: (event: React.SyntheticEvent) => void;
  email: string;
  password: string;
  isRememberMe: boolean;
  isError: boolean;
  caption: string;
}

export function LoginForm(props: Props) {
  const {
    onEmailChange,
    onPasswordChange,
    onRememberMeChange,
    onFormSubmit,
    email,
    password,
    isRememberMe,
    isError,
    caption,
  } = props;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="bFormSignIn">
            <form onSubmit={onFormSubmit} className="eFormSignIn">
              <div className="eFormSignInImg">
                <img src="/dist/images/logo.png" />
              </div>
              <h1 className="h3 mb-3 mt-3 font-weight-normal">{caption}</h1>
              {isError && (
                <div className="alert alert-danger">
                  Incorrect email or password.
                </div>
              )}

              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                value={email}
                onChange={onEmailChange}
                id="login_email"
              />
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                id="login_password"
                value={password}
                onChange={onPasswordChange}
              />
              <div className="checkbox mb-3">
                <input
                  type="checkbox"
                  checked={isRememberMe}
                  onChange={onRememberMeChange}
                />{' '}
                Remember me
              </div>
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                id="login_submit"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
