import * as React from 'react';
import './../LandingPage.scss';

export function GDPRInformation() {
  return (
    <div className="bFAQsWrapper mb-5 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="bGDPRTitle">Data Protection Policy</div>
            <div className="bGDPRSubTitle mGDPRTextUnderline mGDPRTextItalic">Context and overview</div>
            <div className="bGDPRSubTitle">Key Details</div>
            <div className="bGDPRInfoBlockText">
              <ul>
                <li className="bGDPRListCircle">Policy prepared by: Andrew Ralph</li>
                <li className="bGDPRListCircle">Next review date: 01/04/25</li>
              </ul>
            </div>
            <div className="bGDPRSubTitle">Introduction</div>
            <div className="bGDPRInfoBlockText">
              Bentley Photographic needs to gather and use certain information about individuals.
            </div>
            <div className="bGDPRInfoBlockText">
              These can include customers, suppliers, business contacts, employees and other people the organisation has
              a relationship with or may need to contact. These also include names for students to allow us to create
              named group photographs and student ID cards.
            </div>
            <div className="bGDPRInfoBlockText">
              This policy describes how this personal data must be collected, handled and stored to meet our data
              protection standards – and to comply with the law.
            </div>
            <div className="bGDPRSubTitle">Why this policy exists</div>
            <div className="bGDPRInfoBlockText">
              This data protection policy ensures Bentley Photographic:
              <ul>
                <li className="mGDPRListDisc">Complies with data protection law and follows good practice</li>
                <li className="mGDPRListDisc">Protects the rights of staff, customers and partners</li>
                <li className="mGDPRListDisc">Is open to how it stores and processes individual's data</li>
                <li className="mGDPRListDisc">Protects itself from the risks of a data breach</li>
              </ul>
            </div>
            <div className="bGDPRSubTitle">Data protection law</div>
            <div className="bGDPRInfoBlockText">
              The General Data Protection Regulation 2018 describes how organisations – including Bentley Photographic –
              must collect, handle and store personal information.
            </div>
            <div className="bGDPRInfoBlockText">
              These rules apply regardless of wether data is stored electronically, on paper or on other materials.
            </div>
            <div className="bGDPRInfoBlockText">
              To comply with the law, personal information must be collected and used fairly, stored safely and not
              disclosed unlawfully.
            </div>
            <div className="bGDPRInfoBlockText">
              The General Data Protection Regulation 2018 is underpinned by six important principles. These say that
              data must be:
            </div>
            <div className="bGDPRInfoBlockText">
              <ul>
                <li className="mGDPRListDecimal">
                  Processed lawfully, fairly and in a transparent manner in relation to individuals
                </li>
                <li className="mGDPRListDecimal">
                  Collected for specified, explicit and legitimate purposes and not further processed in a manner that
                  in incompatible with those purposes. Further processing for archiving purposes in the public interest,
                  scientific or historical research purposes or statistical purposes shall not be considered to be
                  incompatible with the initial purposes
                </li>
                <li className="mGDPRListDecimal">
                  Adequate, relevant and limited to what is necessary in relation to the purposes for which they are
                  processed
                </li>
                <li className="mGDPRListDecimal">
                  Accurate and, where necessary, kept up to date. Every reasonable step must be taken to ensure that
                  personal data that are inaccurate, having regard to the purposes for which they are processed, are
                  erased or rectified without delay
                </li>
                <li className="mGDPRListDecimal">
                  kept in a form which permits identification of data subjects for no longer than is necessary for the
                  purposes for which the personal data are processed; personal data may be stored for longer periods
                  insofar as the personal data will be processed solely for archiving purposes in the public interest,
                  scientific or historical research purposes or statistical purposes subject to implementation of the
                  appropriate technical and organisational measures required by the GDPR in order to safeguard the
                  rights and freedoms of individuals
                </li>
                <li className="mGDPRListDecimal">
                  processed in a manner that ensures appropriate security of the personal data, including protection
                  against unauthorised or unlawful processing and against accidental loss, destruction or damage, using
                  appropriate technical or organisational measures
                </li>
              </ul>
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">People, risks and responsibilities</div>
            <div className="bGDPRSubTitle">Policy scope</div>
            <div className="bGDPRInfoBlockText">
              This policy applies to
              <ul>
                <li className="mGDPRListDisc">The head office of Bentley Photographic</li>
                <li className="mGDPRListDisc">All branches of Bentley Photographic</li>
                <li className="mGDPRListDisc">All staff and volunteers of Bentley Photographic</li>
                <li className="mGDPRListDisc">
                  All contractors, suppliers and other people working on behalf of Bentley Photographic
                </li>
              </ul>
              It applies to all data that the company holds relating to identifiable individuals, even if that
              information technically falls outside of the General Data Protection Regulation 2018. This can include:
              <ul>
                <li className="mGDPRListDisc">Names of individuals</li>
                <li className="mGDPRListDisc">Postal addresses</li>
                <li className="mGDPRListDisc">Email addresses</li>
                <li className="mGDPRListDisc">Telephone numbers</li>
                <li className="mGDPRListDisc">any other information relating to individuals</li>
              </ul>
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic">Data protection risks</div>
            <div className="bGDPRInfoBlockText">
              This policy helps to protect Bentley Photographic from some very real data security risks, including:
              <ul>
                <li className="mGDPRListDisc">
                  <b>Breaches of confidentiality.</b> For instance, information being given out inappropriately
                </li>
                <li className="mGDPRListDisc">
                  <b>Failing to offer choice.</b> For instance, all individuals should be free to choose how the company
                  uses data relating to them.
                </li>
                <li className="mGDPRListDisc">
                  <b>Reputational damage.</b> For instance, the company could suffer if hackers successfully gained
                  access to sensitive data
                </li>
              </ul>
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic">Responsibilities</div>
            <div className="bGDPRInfoBlockText">
              Everyone who works for or with Bentley Photographic has some responsibility for ensuring data is
              collected, stored and handled appropriately.
            </div>
            <div className="bGDPRInfoBlockText">
              Each team that handles personal data must ensure that it is handled and processed in line with this policy
              and data protection principles. However, these people have key areas of responsibility:
              <ul>
                <li className="mGDPRListDisc">
                  The <b>board of directors</b> is ultimately responsible for ensuring that Bentley Photographic meets
                  its legal obligations.
                </li>
                <li className="mGDPRListDisc mb-5">
                  The <b>data protection officer,</b> is responsible for:
                  <ul>
                    <li className="mGDPRListCircle">
                      Keeping the board updated about data protection responsibilities, risks and issues.
                    </li>
                    <li className="mGDPRListCircle">
                      Reviewing all data protection procedures and related policies, in line with an agreed schedule.
                    </li>
                    <li className="mGDPRListCircle">
                      Arranging data protection training and advice for the people covered by this policy.
                    </li>
                    <li className="mGDPRListCircle">
                      Handling data protection questions from staff and anyone else covered by this policy.
                    </li>
                    <li className="mGDPRListCircle">
                      Dealing with requests from individuals to see the data Bentley Photographic holds about them (also
                      called ‘subject access requests’).
                    </li>
                    <li className="mGDPRListCircle">
                      Checking and approving any contracts or agreements with third parties that may handle the
                      company’s sensitive data.
                    </li>
                    <li className="mGDPRListCircle">
                      Addressing any data protection queries from journalists or media outlets like newspapers.
                    </li>
                    <li className="mGDPRListCircle">
                      Where necessary, working with other staff to ensure marketing initiatives abide by data protection
                      principles.
                    </li>
                  </ul>
                </li>
                <li className="mGDPRListDisc">
                  The <b>IT manager,</b> is responsible for:
                  <ul>
                    <li className="mGDPRListCircle">
                      Ensuring all systems, services and equipment used for storing data meet acceptable security
                      standards.
                    </li>
                    <li className="mGDPRListCircle">
                      Performing regular checks and scans to ensure security hardware and software is functioning
                      properly.
                    </li>
                    <li className="mGDPRListCircle">
                      Evaluating any third-party services the company is considering using to store or process data. For
                      instance, cloud computing services.
                    </li>
                  </ul>
                </li>
                <li className="mGDPRListDisc">
                  The <b>office manager,</b> is responsible for:
                  <ul>
                    <li className="mGDPRListCircle">
                      Approving any data protection statements attached to communications such as emails and letters.
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">General staff guidelines</div>
              <div className="bGDPRInfoBlockText">
                <ul>
                  <li className="mGDPRListDisc">
                    The only people able to access data covered by this policy should be those who{' '}
                    <b>need it for their work</b>
                  </li>
                  <li className="mGDPRListDisc">
                    Data <b>should not be shared informally.</b> When access to confidential information is required,
                    employees can request it from their line managers
                  </li>
                  <li className="mGDPRListDisc">
                    <b>Bentley Photographic will provide training</b> to all employees to help them understand their
                    responsibilities when handling data
                  </li>
                  <li className="mGDPRListDisc">
                    Employees should keep all data secure, by taking sensible precautions and following the guidelines
                    below
                  </li>
                  <li className="mGDPRListDisc">
                    In particular, <b>strong passwords must be used</b> and they should never be shared
                  </li>
                  <li className="mGDPRListDisc">
                    Personal data <b>should not be disclosed</b> to unauthorised people, either within the company or
                    externally
                  </li>
                  <li className="mGDPRListDisc">
                    Data should be <b>regularly reviewed and updated</b> if it is found to be out of date. If no longer
                    required, it should be deleted and disposed of
                  </li>
                  <li className="mGDPRListDisc">
                    Employees <b>should request help</b> from their line manager or the data protection officer if they
                    are unsure about any aspect of data protection
                  </li>
                </ul>
              </div>
              <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Data storage</div>
              <div className="bGDPRInfoBlockText">
                These rules describe how and where data should be safely stored. Questions about storing data safely can
                be directed to the IT manager or data controller.
              </div>
              <div className="bGDPRInfoBlockText">
                When data is stored on paper, it should be kept in a secure place where unauthorised people cannot see
                it.
              </div>
              <div className="bGDPRInfoBlockText">
                These guidelines also apply to data that is usually stored electronically but has been printed out for
                some reason:
                <ul>
                  <li className="mGDPRListDisc">
                    When not required, the paper or files should be kept in a locked drawer or filing cabinet
                  </li>
                  <li className="mGDPRListDisc">
                    Employees should make sure paper and printouts are not left where unauthorised people could see
                    them, like on a printer
                  </li>
                  <li className="mGDPRListDisc">
                    Data printouts should be shredded and disposed of securely when no longer required
                  </li>
                </ul>
              </div>
              <div className="bGDPRInfoBlockText">
                When data is stored electronically, it must be protected from unauthorised access, accidental deletion
                and malicious hacking attempts:
                <ul>
                  <li className="mGDPRListDisc">
                    Data should be protected by strong passwords that are changed regularly
                  </li>
                  <li className="mGDPRListDisc">
                    If data is stored on removable media (like a CD or DVD), these should be kept locked away securely
                    when not being used
                  </li>
                  <li className="mGDPRListDisc">
                    Data should only be stored on designated drives and servers, and should only be uploaded to an
                    approved cloud computing service
                  </li>
                  <li className="mGDPRListDisc">
                    Servers containing personal data should be sited in a secure location, away from general office
                    space
                  </li>
                  <li className="mGDPRListDisc">
                    Data should be backed up frequently. Those backups should be tested regularly, in line with the
                    company’s standard backup procedures
                  </li>
                  <li className="mGDPRListDisc">
                    Data should never be saved directly to laptops or other mobile devices like tablets or smart phones
                  </li>
                  <li className="mGDPRListDisc">
                    All servers and computers containing data should be protected by approved security software and a
                    firewall
                  </li>
                </ul>
              </div>
              <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Data use</div>
              <div className="bGDPRInfoBlockText">
                Personal data is of no value to Bentley Photographic unless the business can make use of it. However, it
                is when personal data is accessed and used that it can be at the greatest risk of loss, corruption or
                theft:
                <ul>
                  <li className="mGDPRListDisc">
                    When working with personal data, employees should ensure the screens of their computers are always
                    locked when left unattended
                  </li>
                  <li className="mGDPRListDisc">
                    Personal data should not be shared informally. In particular, it should never be sent by email, as
                    this form of communication is not secure
                  </li>
                  <li className="mGDPRListDisc">
                    Data must be encrypted before being transferred electronically. The IT manager can explain how to
                    send data to authorised external contacts
                  </li>
                  <li className="mGDPRListDisc">
                    Personal data should never be transferred outside of the European Economic Area
                  </li>
                  <li className="mGDPRListDisc">
                    Employees should not save copies of personal data to their own computers. Always access and update
                    the central copy of any data
                  </li>
                </ul>
              </div>
              <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Data accuracy</div>
              <div className="bGDPRInfoBlockText">
                The law requires Bentley Photographic to take reasonable steps to ensure data is kept accurate and up to
                date.
              </div>
              <div className="bGDPRInfoBlockText">
                The more important it is that the personal data is accurate, the greater the effort Bentley Photographic
                should put into ensuring its accuracy.
              </div>
              <div className="bGDPRInfoBlockText">
                It is the responsibility of all employees who work with data to take reasonable steps to ensure it is
                kept as accurate and up to date as possible.
                <ul>
                  <li className="mGDPRListDisc">
                    Data will be held in as few places as necessary. Staff should not create any unnecessary additional
                    data sets.
                  </li>
                  <li className="mGDPRListDisc">
                    Staff should take every opportunity to ensure data is updated. For instance, by confirming a
                    customer’s details when they call.
                  </li>
                  <li className="mGDPRListDisc">
                    Bentley Photographic will make it easy for data subjects to update the information Bentley
                    Photographic holds about them. For instance, via the company website.
                  </li>
                  <li className="mGDPRListDisc">
                    Data should be updated as inaccuracies are discovered. For instance, if a customer can no longer be
                    reached on their stored telephone number, it should be removed from the database.
                  </li>
                  <li className="mGDPRListDisc">
                    Marketing databases should be checked against industry suppression files every six months.
                  </li>
                </ul>
              </div>
              <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Online Orders</div>
              <div className="bGDPRInfoBlockText">
                Bentley Photographic uses on online ordering system provided by a third party. The website has a SHA-256
                SSL certificate. All payments are processed remotely. No credit card information information is
                processed or stored by Bentley Photographic.{' '}
              </div>
            </div>
            <div className="bGDPRInfoBlockText">
              Orders that are to be posted to home addresses are not sent with any personal data other than the name and
              address to which the order is to be delivered.
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Subject access requests</div>
            <div className="bGDPRInfoBlockText">
              All individuals who are the subject of personal data held by Bentley Photographic are entitled to:
              <ul>
                <li className="mGDPRListDisc">Ask what information the company holds about them and why.</li>
                <li className="mGDPRListDisc">Ask how to gain access to it.</li>
                <li className="mGDPRListDisc">Be informed how to keep it up to date.</li>
                <li className="mGDPRListDisc">
                  Be informed how the company is meeting its data protection obligations.
                </li>
              </ul>
            </div>
            <div className="bGDPRInfoBlockText">
              If an individual contacts the company requesting this information, this is called a subject access
              request.
            </div>
            <div className="bGDPRInfoBlockText">
              Subject access requests from individuals should be made by email, addressed to the data controller at
              info@bentleyphoto.com. The data controller can supply a standard request form, although individuals do not
              have to use this.
            </div>
            <div className="bGDPRInfoBlockText">
              The data controller will aim to provide the relevant data within 14 days.
            </div>
            <div className="bGDPRInfoBlockText">
              The data controller will always verify the identity of anyone making a subject access request before
              handing over any information.
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Disclosing data for other reasons</div>
            <div className="bGDPRInfoBlockText">
              In certain circumstances, the General Data Protection Regulations allow personal data to be disclosed to
              law enforcement agencies without the consent of the data subject.
            </div>
            <div className="bGDPRInfoBlockText">
              Under these circumstances, Bentley Photographic will disclose requested data. However, the data controller
              will ensure the request is legitimate, seeking assistance from the board and from the company’s legal
              advisers where necessary.
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Providing information</div>
            <div className="bGDPRInfoBlockText">
              Bentley Photographic aims to ensure that individuals are aware that their data is being processed, and
              that they understand:
              <ul>
                <li className="mGDPRListDisc">How the data is being used</li>
                <li className="mGDPRListDisc">How to exercise their rights</li>
              </ul>
            </div>
            <div className="bGDPRSubTitle mGDPRTextItalic mGDPRTextUnderline">Deleting Your Account</div>
            <div className="bGDPRInfoBlockText">
              To permanently delete your account and remove all associated data, you can:
              <li className="mGDPRListDecimal">
                <b>Use the Web Application:</b>
              </li>
              <ul>
                <li className="mGDPRListDisc">Log in to your account on our website.</li>
                <li className="mGDPRListDisc">
                  Navigate to your profile and select the <b>My Details</b> tab.
                </li>
                <li className="mGDPRListDisc">
                  Click on the <b>Delete Account</b> button.
                </li>
              </ul>
              <li className="mGDPRListDecimal">
                <b>Use the Mobile App (Android or iOS):</b>
              </li>
              <ul>
                <li className="mGDPRListDisc">Open the Bentley Photo app on your mobile device.</li>
                <li className="mGDPRListDisc">
                  Go to your profile and select the <b>My Details</b> tab.
                </li>
                <li className="mGDPRListDisc">
                  Tap on the <b>Delete Account</b> option.
                </li>
              </ul>
              <li className="mGDPRListDecimal">
                <b>Email Us:</b>
              </li>
              <ul>
                <li className="mGDPRListDisc">
                  Send an email to <a href="mailto:support@bentleyphoto.com">support@bentleyphoto.com</a> with a request
                  to delete your account.
                </li>
                <li className="mGDPRListDisc">
                  We will ensure that your account is deleted within three working days and send you an email
                  confirmation once the process is complete.
                </li>
              </ul>
              <div className="bGDPRInfoBlockText">
                When your account is deleted, all your data, including your email address, phone number, and access
                credentials, will be permanently removed from our platform. Please note that you will no longer be able
                to access any orders you have placed. If you decide to use our services again in the future, you will
                need to sign up for a new account.
              </div>
              <div className="bGDPRInfoBlockText">
                <b>Account Reinstatement:</b> Once your account is deleted, it cannot be restored. You will need to
                create a new account if you wish to return to Bentley Photo.
              </div>
              <div className="bGDPRInfoBlockText">
                <b>Order Access:</b> Deleting your account will also remove access to any orders you have placed. Ensure
                you have saved any necessary information before proceeding with account deletion.
              </div>
              <div className="bGDPRInfoBlockText">
                For any further assistance or inquiries, please contact our support team at{' '}
                <a href="mailto:support@bentleyphoto.com">support@bentleyphoto.com</a>. We are here to help you manage
                your data and ensure your experience with Bentley Photo is secure and satisfactory.
              </div>
            </div>
            <div className="">
              <img className="w-100" src="/dist/images/ICO_certificate.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
