import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { History, Location } from 'history';
import {
  getBasket,
  setDeliveryOption,
  createOrder,
  setDeliveryCountryToBasket,
} from '../../../../services/public/basket';
import { AppOneTimeCode } from '../../../AppOrders/AppOrders';
import {
  ORDER_DELIVERY_TYPE,
  EMPTY_DELIVERY_OPTION_TEXT,
  COUNTRIES_WITH_STANDARD_AND_COURIER_DELIVERY_TYPE,
  BASKET_DELIVERY_CHARGE_TYPE,
} from '../../../../consts/order';
import { PAY360_RESULT } from '../../../../consts/payment';
import { PAYMENT_CHANNEL, BUYER_TYPE } from '../../../../consts/payment';
import { getConfig } from '../../../../services/public/config';
import { DEFAULT_COUNTRY_OPTION_VALUE } from '../../../../consts/order';
import { DELIVERY_OPTION } from '../../../../consts/order';
import { getCallback, getOneTimeCodeCallbackC, getOneTimeCodeCallbackY } from '../../../../helpers/worldpay';
import { Loader } from '../../../../components/Loader/Loader';
import { PublicConfig } from '../../../../models/config';
import { payment360 } from '../../../../services/public/payment360';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import './DeliveryOptions.scss';

let countries = require('../../../../services/countries.json');

interface Props {
  history: History;
  location: Location;
  oneTimeCode: AppOneTimeCode;
  deliveryFromPrevSubmit: any;
}

interface State {
  firstName: string;
  lastName: string;
  streetAndNumber: string;
  town: string;
  region: string;
  postCode: string;
  countries: any[];
  selectedCountry: string;
  phone: string;
  email: string;
  orderAmount: number;
  selectedDeliveryOption: string;
  deliveryOptions: any;
  order: any;
  config: PublicConfig;
  isLoading: boolean;
  isDisablePaymentButton: boolean;
  items: any[];

  iFrameSrc: string | undefined;
  sessionId: string | undefined;

  isPaymentUnavailable: boolean;
}

export class HomeDeliveryForm extends Component<Props, State> {
  formRef: any;
  iframeRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      streetAndNumber: '',
      town: '',
      region: '',
      postCode: '',
      countries: [],
      selectedCountry: DEFAULT_COUNTRY_OPTION_VALUE,
      phone: '',
      email: '',
      orderAmount: 0.0,
      selectedDeliveryOption: '',
      deliveryOptions: undefined,
      order: undefined,
      config: undefined,
      isLoading: true,
      isDisablePaymentButton: false,
      items: [],
      iFrameSrc: undefined,
      sessionId: undefined,
      isPaymentUnavailable: false,
    };

    this.formRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    const { oneTimeCode, deliveryFromPrevSubmit } = this.props;

    window.addEventListener('message', this.payment);

    this.setState({
      isLoading: true,
    });

    let config: PublicConfig;

    if (typeof deliveryFromPrevSubmit !== 'undefined') {
      const { school, home } = deliveryFromPrevSubmit;

      const isSchool = typeof school !== 'undefined';
      const isHome = typeof home !== 'undefined';

      getConfig()
        .then(_config => {
          config = _config;
          return getBasket(oneTimeCode);
        })
        .then(basket => {
          const { items } = basket;
          const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

          return isAllProductsDigital
            ? setDeliveryOption(oneTimeCode, { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT })
            : BPromise.resolve(basket);
        })
        .then(basket => {
          const { orderAmount, deliveryOptions, deliveryOption, items } = basket;

          switch (true) {
            case isHome:
              const { firstName, lastName, address1, town, region, postcode, country, phone, email } = home;
              this.setState({
                config,
                firstName,
                lastName,
                streetAndNumber: address1,
                town,
                region,
                postCode: postcode,
                selectedCountry: country,
                phone,
                email,
                selectedDeliveryOption: deliveryOption,
                orderAmount: orderAmount.toFixed(2),
                deliveryOptions,
                countries,
                items,
                isLoading: false,
              });
              break;
            case isSchool:
              const { phone: phoneFromSchool, email: emailFromSchool } = school;
              this.setState({
                config,
                orderAmount: orderAmount.toFixed(2),
                deliveryOptions,
                selectedDeliveryOption: '',
                countries,
                phone: phoneFromSchool,
                email: emailFromSchool,
                items,
                isLoading: false,
              });
              break;
          }
        });
    } else {
      getConfig()
        .then(_config => {
          config = _config;
          return getBasket(oneTimeCode);
        })
        .then(basket => {
          const { items } = basket;
          const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

          return isAllProductsDigital
            ? setDeliveryOption(oneTimeCode, { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT })
            : BPromise.resolve(basket);
        })
        .then(basket => {
          const { orderAmount, deliveryOptions, deliveryOption, deliveryCountry, items } = basket;
          const selectedCountry =
            typeof deliveryCountry !== 'undefined' && deliveryCountry !== ''
              ? deliveryCountry
              : DEFAULT_COUNTRY_OPTION_VALUE;

          this.setState({
            config,
            orderAmount: orderAmount.toFixed(2),
            deliveryOptions,
            selectedDeliveryOption: deliveryOption,
            selectedCountry: selectedCountry,
            countries,
            items,
            isLoading: false,
          });
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.payment);
  }

  payment = event => {
    const { data } = event;

    const isDataPaid = data === PAY360_RESULT.PAID;
    const isDataCanceled = data === PAY360_RESULT.CANCELED;
    const isDataTimeout = data === PAY360_RESULT.TIMEOUT;
    if (isDataPaid || isDataCanceled || isDataTimeout) {
      this.props.history.push(`/oneTimeCode?status=${data}`);
    }
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { selectedDeliveryOption, items } = this.state;

    this.setState({
      isLoading: true,
      isDisablePaymentButton: true,
    });

    let order;

    const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

    if (selectedDeliveryOption !== '' || isAllProductsDigital) {
      const { oneTimeCode } = this.props;
      const {
        firstName,
        lastName,
        streetAndNumber,
        town,
        region,
        postCode,
        selectedCountry,
        phone,
        email,
      } = this.state;
      const data = {
        deliveryType: ORDER_DELIVERY_TYPE.HOME,
        delivery: {
          firstName: firstName,
          lastName: lastName,
          address1: streetAndNumber,
          town: town,
          region: region,
          postcode: postCode,
          country: selectedCountry,
          phone: phone,
          email: email,
        },
      };

      const setDeliveryOptionPromise = isAllProductsDigital
        ? setDeliveryOption(oneTimeCode, { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT })
        : BPromise.resolve();

      setDeliveryOptionPromise
        .then(res => {
          return createOrder(oneTimeCode, data);
        })
        .then(_order => {
          order = _order;
          const { config } = this.state;
          const { payments } = config;
          const { type } = payments;

          const channel = PAYMENT_CHANNEL.WEB;
          const buyerType = BUYER_TYPE.ONE_TIME_CODE;

          switch (type) {
            case 'worldPay': {
              this.setState(
                {
                  order,
                  isLoading: false,
                },
                () => {
                  this.formRef.current.submit();
                }
              );
              break;
            }

            case 'pay360Hosted': {
              const { id } = order;
              payment360(id, channel, buyerType).then(res => {
                const { redirectUrl } = res;

                if (redirectUrl) {
                  window.open(redirectUrl, '_self');
                } else {
                  this.setState({
                    isLoading: false,
                    isPaymentUnavailable: true,
                    order,
                  });
                }
              });
              break;
            }

            case 'pay360Iframe': {
              const { id } = order;

              payment360(id, channel, buyerType).then(res => {
                const { redirectUrl, sessionId } = res;

                if (redirectUrl && sessionId) {
                  this.setState({ isLoading: false, order, iFrameSrc: redirectUrl, sessionId });
                } else {
                  this.setState({
                    isLoading: false,
                    isPaymentUnavailable: true,
                    order,
                  });
                }
              });
              break;
            }
          }
        });
    }
  };

  onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ firstName: value });
  };

  onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ lastName: value });
  };

  onStreetAndNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ streetAndNumber: value });
  };

  onTownChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ town: value });
  };

  onRegionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ region: value });
  };

  onPostCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ postCode: value });
  };

  onCountryOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const country = event.target.value;
    const { oneTimeCode } = this.props;
    const { selectedDeliveryOption } = this.state;
    const isDigitalProduct = selectedDeliveryOption === BASKET_DELIVERY_CHARGE_TYPE.DIGITAL_PRODUCT;

    if (country === DEFAULT_COUNTRY_OPTION_VALUE && !isDigitalProduct) {
      setDeliveryOption(oneTimeCode, { deliveryOption: '' }).then(basket => {
        const { orderAmount } = basket;
        this.setState({
          selectedCountry: country,
          selectedDeliveryOption: '',
          orderAmount: orderAmount.toFixed(2),
        });
      });
    }

    if (country !== DEFAULT_COUNTRY_OPTION_VALUE && !isDigitalProduct) {
      setDeliveryOption(oneTimeCode, { deliveryOption: DELIVERY_OPTION.INTERNATIONAL.toUpperCase() }).then(basket => {
        const { orderAmount } = basket;
        this.setState({
          selectedCountry: country,
          selectedDeliveryOption: DELIVERY_OPTION.INTERNATIONAL,
          orderAmount: orderAmount.toFixed(2),
        });
      });
    }

    if (isDigitalProduct) {
      this.setState({
        selectedCountry: country,
      });
    }

    setDeliveryCountryToBasket(oneTimeCode, { deliveryCountry: country });
  };

  onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phone: value });
  };

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ email: value });
  };

  onDeliveryOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { oneTimeCode } = this.props;
    const value = event.target.value;
    setDeliveryOption(oneTimeCode, { deliveryOption: value }).then(basket => {
      const { orderAmount } = basket;
      this.setState({
        orderAmount: orderAmount.toFixed(2),
        selectedDeliveryOption: value,
      });
    });
  };

  static selectDeliveryOptions(selectedCountry, deliveryOptions) {
    const isSomeCountriesEqualSelectedCountry = COUNTRIES_WITH_STANDARD_AND_COURIER_DELIVERY_TYPE.some(
      country => country === selectedCountry
    );

    return isSomeCountriesEqualSelectedCountry ? (
      <>
        <option value="" key="emptyOption">
          {EMPTY_DELIVERY_OPTION_TEXT}
        </option>
        <option value={BASKET_DELIVERY_CHARGE_TYPE.STANDARD} key={DELIVERY_OPTION.STANDARD}>
          {`${DELIVERY_OPTION.STANDARD} (£${deliveryOptions[DELIVERY_OPTION.STANDARD]})`}
        </option>
        <option value={BASKET_DELIVERY_CHARGE_TYPE.COURIER} key={DELIVERY_OPTION.COURIER}>
          {`${DELIVERY_OPTION.COURIER} (£${deliveryOptions[DELIVERY_OPTION.COURIER]})`}
        </option>
      </>
    ) : (
      <>
        <option value="" key="emptyOption">
          {EMPTY_DELIVERY_OPTION_TEXT}
        </option>
        <option value={BASKET_DELIVERY_CHARGE_TYPE.INTERNATIONAL} key={DELIVERY_OPTION.INTERNATIONAL}>
          {`${DELIVERY_OPTION.INTERNATIONAL} (£${deliveryOptions[DELIVERY_OPTION.INTERNATIONAL]})`}
        </option>
      </>
    );
  }

  selectCountryOptions = () => {
    let selectCountryOptions = [];
    const { countries } = this.state;
    for (let i = 0; i < countries.length; i++) {
      selectCountryOptions.push(
        <option value={countries[i].code} key={countries[i].code}>
          {`${countries[i].code} - ${countries[i].name}`}
        </option>
      );
    }
    return selectCountryOptions;
  };

  renderHiddenForm() {
    const { order, config } = this.state;
    const { oneTimeCode } = this.props;
    const { username, password, basketId } = oneTimeCode;
    const { payments } = config;
    const { worldpay } = payments;
    const { formActionUrl, testModeValue, instId, lang, accId1, currency } = worldpay;

    return (
      <form action={formActionUrl} method="POST" ref={this.formRef}>
        <input type="hidden" name="testMode" value={testModeValue} />
        <input type="hidden" name="instId" value={instId} />
        <input type="hidden" name="cartId" value={order.orderNumber} />
        <input type="hidden" name="amount" value={order.orderAmount} />
        <input type="hidden" name="currency" value={currency} />

        <input type="hidden" name="address1" value={order.delivery.address1} />
        <input type="hidden" name="town" value={order.delivery.town} />
        <input type="hidden" name="region" value={order.delivery.region} />
        <input type="hidden" name="postcode" value={order.delivery.postcode} />
        <input type="hidden" name="country" value={order.delivery.country} />
        <input type="hidden" name="authMode" value="A" />
        <input type="hidden" name="authValidFrom" value="" />
        <input type="hidden" name="authValidTo" value="" />
        <input type="hidden" name="name" value={`${order.delivery.firstName} ${order.delivery.lastName}`} />
        <input type="hidden" name="email" value={order.delivery.email} />

        <input type="hidden" name="lang" value={lang} />
        <input type="hidden" name="hideCurrency" value="" />
        <input type="hidden" name="noLanguageMenu" value="" />
        <input type="hidden" name="accId1" value={accId1} />

        {/*custom field*/}
        <input type="hidden" name="MC_orderId" value={order.id} />
        <input type="hidden" name="MC_basketId" value={basketId} />
        <input type="hidden" name="MC_username" value={username} />
        <input type="hidden" name="MC_password" value={password} />
        <input type="hidden" name="MC_callbackY" value={getOneTimeCodeCallbackY()} />
        <input type="hidden" name="MC_callbackC" value={getOneTimeCodeCallbackC()} />
        <input type="hidden" name="MC_callback" value={getCallback()} />
        <input type="hidden" name="MC_mobileApp" value={'no'} />
      </form>
    );
  }

  renderHiddenIframe() {
    const { iFrameSrc } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <iframe src={iFrameSrc} width="100%" style={{ border: 'none' }} height="1300px" ref={this.iframeRef} />
          </div>
        </div>
      </div>
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isPaymentUnavailable: false,
      isDisablePaymentButton: false,
    });
  };

  renderError(): React.ReactNode {
    const { isPaymentUnavailable } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentUnavailable}
        title={'Error'}
        body={'Unfortunately, the payment service is currently unavailable. Please try again later'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  render() {
    const {
      firstName,
      lastName,
      streetAndNumber,
      town,
      region,
      postCode,
      selectedCountry,
      phone,
      email,
      orderAmount,
      selectedDeliveryOption,
      order,
      deliveryOptions,
      isLoading,
      items,
      isDisablePaymentButton,
      iFrameSrc,
      config,
      isPaymentUnavailable,
    } = this.state;

    const type = propz.get(config, ['payments', 'type'], 'worldPay');

    if (isLoading) {
      return <Loader />;
    }

    const isInternationalDelivery = selectedDeliveryOption === DELIVERY_OPTION.INTERNATIONAL.toUpperCase();
    const isAllProductsDigital = items.every(item => item.productIsDigitalProduct === true);

    const classes = isPaymentUnavailable ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderError()}
        {typeof order !== 'undefined' && type === 'worldPay' && this.renderHiddenForm()}
        {typeof iFrameSrc !== 'undefined' && this.renderHiddenIframe()}
        {typeof iFrameSrc === 'undefined' && (
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="bHomeDeliveryForm">
                  <div className={'eHomeDeliveryFormTitle'}>HOME DELIVERY</div>
                  <div className="row justify-content-center">
                    <form onSubmit={this.onFormSubmit} className="">
                      <div className="eHomeDeliveryFormInputWrapper">
                        <div className="eHomeDeliveryFormContainer mPaddingTop20">
                          <div className="eHomeDeliveryFormText">Delivery Address</div>
                          <div className="eHomeDeliveryFormText">Edit</div>
                        </div>
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="First Name"
                          value={firstName}
                          onChange={this.onFirstNameChange}
                          key="homeDeliveryForm_firstName"
                          required
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={this.onLastNameChange}
                          key="homeDeliveryForm_lastName"
                          required
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Street and number"
                          value={streetAndNumber}
                          onChange={this.onStreetAndNumberChange}
                          key="homeDeliveryForm_streetAndNumber"
                          required
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Town"
                          value={town}
                          onChange={this.onTownChange}
                          key="homeDeliveryForm_town"
                          required
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput mWidth240"
                          placeholder="County"
                          value={region}
                          onChange={this.onRegionChange}
                          key="homeDeliveryForm_region"
                          required
                        />
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput mWidth240 mMarginleft10"
                          placeholder="Post code"
                          value={postCode}
                          onChange={this.onPostCodeChange}
                          key="homeDeliveryForm_postCode"
                          required
                        />
                        <select
                          id="selectIdCountry"
                          key="homeDeliveryForm_country"
                          className="eHomeDeliveryFormSelect mMarginTop15"
                          onChange={this.onCountryOptionSelect}
                          value={selectedCountry}
                        >
                          {this.selectCountryOptions()}
                        </select>
                        <input
                          type="text"
                          className="eHomeDeliveryFormInput"
                          placeholder="Contact number"
                          value={phone}
                          onChange={this.onPhoneChange}
                          key="homeDeliveryForm_phone"
                          required
                        />
                        <input
                          type="email"
                          className="eHomeDeliveryFormInput"
                          placeholder="Email"
                          value={email}
                          onChange={this.onEmailChange}
                          key="homeDeliveryForm_email"
                          pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          required
                        />
                        <div className="eHomeDeliveryFormContainer mMarginTop15">
                          {!isAllProductsDigital && (
                            <select
                              id="selectIdDeliveryOption"
                              className="eHomeDeliveryFormSelect mWidth240"
                              onChange={this.onDeliveryOptionSelect}
                              value={selectedDeliveryOption}
                              required
                            >
                              {HomeDeliveryForm.selectDeliveryOptions(selectedCountry, deliveryOptions)}
                            </select>
                          )}
                          <div className="eHomeDeliveryFormText mFontSize18">Order total</div>
                          <div className="eHomeDeliveryFormText mFontSize18">{`£${orderAmount}`}</div>
                        </div>

                        {isInternationalDelivery && (
                          <div className="checkbox mb-3 mt-3 eHomeCheckbox">
                            <input type="checkbox" required />
                            <span className="eHomeDeliveryInfoText">
                              {' All shipments outside of UK since Brexit are now subject to VAT / Import Duty. ' +
                                'Our couriers have no control over these charges; therefore, the charges are not included when paying for international postage. ' +
                                'Please confirm you accept that you will pay these charges.'}
                            </span>
                          </div>
                        )}

                        <div className="eHomeDeliveryFormButtonWrapper">
                          <button className="eHomeDeliveryFormButton" type="submit" disabled={isDisablePaymentButton}>
                            Payment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
