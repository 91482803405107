import * as React from 'react';
import { Route, Switch } from 'react-router';
import AllPaymentsSuccessIframe from './PaymentSuccess/AllPaymentsSuccessIframe';
import AllPaymentsCancelIframe from './PaymentCancel/AllPaymentsCancelIframe';

import OneTimeCodePaymentCancelHosted from './PaymentCancel/OneTimeCodePaymentCancelHosted';
import OneTimeCodePaymentSuccessHosted from './PaymentSuccess/OneTimeCodePaymentSuccessHosted';

import CustomerPaymentSuccessHosted from './PaymentSuccess/CustomerPaymentSuccessHosted';
import CustomerPaymentCancelHosted from './PaymentCancel/CustomerPaymentCancelHosted';

import MobilePaymentSuccessHosted from './PaymentSuccess/MobilePaymentSuccessHosted';
import MobilePaymentCancelHosted from './PaymentCancel/MobilePaymentCancelHosted';


function Payment() {
  const isFrame = window.self !== window.top;
  return (
    <Switch>
      <Route
        exact
        path="/customer/success"
        component={props =>
          isFrame ? <AllPaymentsSuccessIframe {...props} /> : <CustomerPaymentSuccessHosted {...props} />
        }
      />
      <Route
        exact
        path="/customer/cancel"
        component={props =>
          isFrame ? <AllPaymentsCancelIframe {...props} /> : <CustomerPaymentCancelHosted {...props} />
        }
      />
      <Route
        exact
        path="/oneTimeCode/success"
        component={props =>
          isFrame ? <AllPaymentsSuccessIframe {...props} /> : <OneTimeCodePaymentSuccessHosted {...props} />
        }
      />
      <Route
        exact
        path="/oneTimeCode/cancel"
        component={props =>
          isFrame ? <AllPaymentsCancelIframe {...props} /> : <OneTimeCodePaymentCancelHosted {...props} />
        }
      />
      <Route
        exact
        path="/mobile/success"
        component={props => (isFrame ? <AllPaymentsSuccessIframe {...props} /> : <MobilePaymentSuccessHosted {...props} />)}
      />
      <Route
        exact
        path="/mobile/cancel"
        component={props => (isFrame ? <AllPaymentsCancelIframe {...props} /> : <MobilePaymentCancelHosted {...props} />)}
      />
      <Route path="/" component={props => <div>Page not found</div>} />
    </Switch>
  );
}

export default Payment;
