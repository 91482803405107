import { Slider, SliderImage } from '../models/slider';
import { DEFAULT_SLIDER_DELAY_IN_MILLISECONDS, DEFAULT_SLIDER_DELAY_IN_SECONDS } from '../consts/common';
import { convertSecondsInMilliseconds } from './converting';

export function getSliderObject(sliders: Slider[], sliderType: string) {
  const slider = sliders.find(slider => slider.type === sliderType);
  //short way
  if (typeof slider === 'undefined') {
    return {
      images: [],
      delay: DEFAULT_SLIDER_DELAY_IN_MILLISECONDS,
    };
  }

  const sliderActiveImages = filterActiveImages(slider.images || []);
  const sliderDelayInMilliseconds = convertSecondsInMilliseconds(slider.delay || DEFAULT_SLIDER_DELAY_IN_SECONDS);

  return {
    images: sliderActiveImages,
    delay: sliderDelayInMilliseconds,
  };
}

function filterActiveImages(images: SliderImage[]): SliderImage[] {
  return images.filter(image => image.isActive);
}
