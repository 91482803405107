import * as React from 'react';

export function InfoBlockContactUs() {
  return (
    <div className="pt-5">
      <div className="bFAQsSubTitle">contact us</div>
      <div className="bInfoBlockText">
        <strong>bentley photographic</strong>
        <br />
        New Hall Barn
        <br />
        Horsley Cross
        <br />
        Manningtree
        <br />
        Essex
        <br />
        CO11 2NU
        <br />
      </div>
      <div className="bInfoBlockText pb-2">
        E: <a href="mailto:support@bentleyphoto.com">support@bentleyphoto.com</a>
      </div>
      <div className="bInfoBlockText pb-2">T: 01206 395888 (Outside UK 0044 1206 395888)</div>
      <div className="bInfoBlockText pb-2">F: 01206 395544</div>
      <div className="bInfoBlockLittleText">
        Bentley Photographic Ltd, trading as Bentley Photographic, Reg No 3800667, Reg Office, Izod Bassett, 105 High
        Street, Needham Market, IP6 8DQ VAT No 368 5035 36
      </div>
    </div>
  );
}
