import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { History, Location } from 'history';
import { checkAvailability } from '../../../../services/public/register';
import { Button } from '../../../../components/Button/Button';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  history: History;
  location: Location;
}

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Must be valid email')
    .test('email', 'Duplicate email', value =>
      checkAvailability({ email: value }).then(res => {
        return res.isAvailable;
      })
    ),
});

export function EmailChangeForm(props: Props) {
  const { onSubmit, onCancel, history } = props;
  const { email } = history.location.state as any;
  return (
    <Formik
      initialValues={{ email }}
      validationSchema={EmailSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form>
          <div className="form-group form-check">
            <div className="form-group">
              <label>Email</label>
              <Field name="email" className="form-control mb-3" />
              <ErrorMessage component="div" className="alert alert-danger" name="email" />
            </div>

            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
