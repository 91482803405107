import * as React from 'react';
import * as propz from 'propz';
import { CURRENCY_SYMBOL } from '../../../consts/common';
import { Order } from '../../../models/orders';
import { OrderImage } from '../../../models/orderImages';

interface Props {
  order: Order;
  orderImages: OrderImage[];
}

export function OneTimeCodeOrderInformation(props: Props) {
  const { orderImages, order } = props;

  const rows = orderImages.map(image => {
    const productName = propz.get(image, ['product', 'name'], '');
    const productQuantity = propz.get(image, ['quantity'], '');
    const productPrice = propz.get(image, ['product', 'priceSetPrice'], '');

    return (
      <tr key={`one_time_code_order_information_product_${image.id}`}>
        <td>{productName}</td>
        <td>{productQuantity}</td>
        <td>
          {CURRENCY_SYMBOL.POUND} {productPrice}
        </td>
      </tr>
    );
  });

  const { totalWithoutSpecialOffers, deliveryAmount, specialOffersSaving, voucherSaving, orderAmount } = order;

  return (
    <table className="table table-bordered">
      <thead>
        <tr key="one_time_code_order_information_head">
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {rows}
        <tr key="one_time_code_order_information_total_without_offers">
          <td colSpan={2} style={{ fontWeight: 'bold' }}>
            Subtotal
          </td>
          <td>
            {CURRENCY_SYMBOL.POUND} {totalWithoutSpecialOffers}
          </td>
        </tr>
        <tr key="one_time_code_order_information_delivery_amount">
          <td colSpan={2} style={{ fontWeight: 'bold' }}>
            Delivery
          </td>
          <td>
            {CURRENCY_SYMBOL.POUND} {deliveryAmount}
          </td>
        </tr>
        {specialOffersSaving !== 0 && (
          <tr key="one_time_code_order_information_special_offers">
            <td colSpan={2} style={{ fontWeight: 'bold' }}>
              Special Offer Savings
            </td>
            <td>
              -{CURRENCY_SYMBOL.POUND} {specialOffersSaving}
            </td>
          </tr>
        )}
        {voucherSaving !== 0 && (
          <tr key="one_time_code_order_information_voucher_saving">
            <td colSpan={2} style={{ fontWeight: 'bold' }}>
              Voucher Savings
            </td>
            <td>
              -{CURRENCY_SYMBOL.POUND} {voucherSaving}
            </td>
          </tr>
        )}
        <tr key="one_time_code_order_information_order_amount">
          <td colSpan={2} style={{ fontWeight: 'bold' }}>
            Total
          </td>
          <td>
            {CURRENCY_SYMBOL.POUND} {orderAmount}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
