import * as React from 'react';
import './../LandingPage.scss';

export function FAQs() {
  return (
    <div className="bFAQsWrapper mb-5 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="bFAQsTitle">Frequently Asked Questions - Parents</div>
            <div className="bFAQsSubTitle">1) Username & Password does not work.</div>
            <div className="bInfoBlockText">
              a) Your email address is being recognised. You have already created an account with us so you need to log
              into our website with your email address and personal password then click in the box which states ‘add
              another proof card/pre-registration’ and add the username & password (this will be on your proof card or
              supplied by your school).
              <br />
              <br />
              b) Please check the letters of the codes - Upper case I (for India) can be mistaken for lower case l (for
              lima). 0 (zero) can be mistaken for Upper Case O (for Oscar).
            </div>
            <div className="bFAQsSubTitle">
              2) Can’t see any photographs when inserting Pre-Registration Code (from the white ticket).
            </div>
            <div className="bInfoBlockText">
              The card you have received is a pre-registration card - you will not see any photographs until you receive
              a 2nd e-mail from us advising you that they are available to view and order.
            </div>
            <div className="bFAQsSubTitle">3) Why can I only see old photographs on my account.</div>
            <div className="bInfoBlockText">
              You need to add the current username & password from the proof card or the code the school have given you
              in the field which states ‘add another proof card/pre-registration’ and this will load the current image.
            </div>
            <div className="bFAQsSubTitle">
              4) Logging in as a returning customer and being advised this is incorrect.
            </div>
            <div className="bInfoBlockText">
              Please check email address and personal password carefully to make sure all is correct. If still showing
              as incorrect please click on ‘forgotten password’ and a new one will be sent. Once you have gained access
              to your account we would advise you to go into your profiles and change this back to something more
              memorable.
            </div>
            <div className="bFAQsSubTitle">5) Cannot get delivery to school free of charge.</div>
            <div className="bInfoBlockText">
              This could be that the school are not accepting deliveries to the school or the ‘order by date’ on your
              proof card has expired and you will have to pay a nominal postage to your home address.
            </div>
            <div className="bFAQsSubTitle">6) Cannot get delivery or payment options to come up.</div>
            <div className="bInfoBlockText">
              This usually means that you have not added the items ordered to your basket. Please make sure once you
              have selected the preferred item, scroll to the next screen and enter the amount you wish to order and
              click on the field ‘add to basket’. This will then load the delivery and payment options.
            </div>
            <div className="bFAQsSubTitle">
              7) My children are at two different schools and cannot get free delivery to school.
            </div>
            <div className="bInfoBlockText">
              The free delivery to school option is not available when two different schools are selected. You have to
              place the orders separately to take advantage of the free delivery to school or place the order as one and
              pay for delivery to your home address.
            </div>
            <div className="bFAQsSubTitle">8) Why is delivery taking so long.</div>
            <div className="bInfoBlockText">
              During our busy period delivery can take up to 28 days. 
              If you have opted for free delivery to school then your order will not be processed until after the ‘order by
              date’ on your proof card. Once this has expired all orders will be collected and collated and one bulk
              delivery will then be returned to the school.
            </div>
            <div className="bFAQsSubTitle">9) How do I edit my details.</div>
            <div className="bInfoBlockText">
              Go into ‘my profile’ and edit accordingly. If you wish to change your email address or phone umber please
              contact our office.
            </div>
            <div className="bFAQsSubTitle">10) Why am I being charged postage.</div>
            <div className="bInfoBlockText">
              The school are not accepting delivery of orders to the school or the ‘order by date’ has expired and a
              nominal postal charge will apply.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
