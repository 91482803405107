import * as React from 'react';

interface Props {
  onUsernameChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onFormSubmit: (event: React.SyntheticEvent) => void;
  username: string;
  password: string;
  textError: string;
}

export function LoginOneTimeCodeForm(props: Props) {
  const { onUsernameChange, onPasswordChange, onFormSubmit, username, password, textError } = props;

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="bLoginOneTimeCodeForm">
            <div className={'eLoginOneTimeCodeFormTitle'}>ARE YOU LOOKING FOR ACCESS TO YOUR SCHOOL PHOTOGRAPHS?</div>
            <div className={'eLoginOneTimeCodeFormText'}>Let's get started, please enter your login details</div>
            <form onSubmit={onFormSubmit} className="">
              {textError && <div className="alert alert-danger mLoginOrdersError">{textError}</div>}
              <div>
                <input
                  type="text"
                  className="eLoginOneTimeCodeInput"
                  placeholder="Access code 1"
                  value={username}
                  onChange={onUsernameChange}
                />
              </div>
              <div>
                <input
                  type="password"
                  className="eLoginOneTimeCodeInput"
                  placeholder="Access code 2"
                  value={password}
                  onChange={onPasswordChange}
                />
              </div>
              <button className="eLoginOneTimeCodeButton" type="submit">
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
