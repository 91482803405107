import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { SchoolJobImage } from '../../../../../../../../models/schoolImages';
import * as propz from 'propz';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  image: SchoolJobImage;
}

const JobImageSchema = Yup.object().shape({
  // student: Yup.object().shape({
  //   class: Yup.string().required('Required'),
  //   firstName: Yup.string().required('Required'),
  //   lastName: Yup.string().required('Required'),
  // }),
  staffData: Yup.object().shape({
    title: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    surname: Yup.string().required('Required'),
    jobTitle: Yup.string().required('Required'),
  }),
});

export function SchoolJobImageForm(props: Props) {
  const { image, onSubmit, onCancel } = props;

  const jobImageForm = {
    // student: {
    //   class: propz.get(image, ['student', 'class'], ''),
    //   firstName: propz.get(image, ['student', 'firstName'], ''),
    //   lastName: propz.get(image, ['student', 'lastName'], ''),
    // },
    staffData: {
      title: propz.get(image, ['staffData', 'title'], ''),
      name: propz.get(image, ['staffData', 'name'], ''),
      surname: propz.get(image, ['staffData', 'surname'], ''),
      jobTitle: propz.get(image, ['staffData', 'jobTitle'], ''),
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={jobImageForm} validationSchema={JobImageSchema} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  {/*<div className="form-group">*/}
                  {/*  <label>Student class</label>*/}
                  {/*  <Field name="student.class" className="form-control mb-3" />*/}
                  {/*  <ErrorMessage component="div" className="alert alert-danger" name="student.class" />*/}
                  {/*</div>*/}

                  {/*<div className="form-group">*/}
                  {/*  <label>Student first name</label>*/}
                  {/*  <Field name="student.firstName" className="form-control mb-3" />*/}
                  {/*  <ErrorMessage component="div" className="alert alert-danger" name="student.firstName" />*/}
                  {/*</div>*/}

                  {/*<div className="form-group">*/}
                  {/*  <label>Student last name</label>*/}
                  {/*  <Field name="student.lastName" className="form-control mb-3" />*/}
                  {/*  <ErrorMessage component="div" className="alert alert-danger" name="student.lastName" />*/}
                  {/*</div>*/}

                  <div className="form-group">
                    <label>Title</label>
                    <Field name="staffData.title" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="staffData.title" />
                  </div>

                  <div className="form-group">
                    <label>Name</label>
                    <Field name="staffData.name" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="staffData.name" />
                  </div>

                  <div className="form-group">
                    <label>Surname</label>
                    <Field name="staffData.surname" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="staffData.surname" />
                  </div>

                  <div className="form-group">
                    <label>Job title</label>
                    <Field name="staffData.jobTitle" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="staffData.jobTitle" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
