import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as Moment from 'moment';
import * as propz from 'propz';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './ImageTicketsCustomerView.css';
import { SIZE_IMAGE_FOR_VIEWING } from '../../consts/images';
import { Component } from 'react';
import { isImageGroup, isImageIndividual, isImagePanoramic } from '../../helpers/images';
import * as classNames from 'classnames';

interface Props {
  ticket: any;
  onImageClick?: (ticket: any) => void;
  isSelected?: boolean;
}

interface State {
  cssClass: string;
  cssClassText: string;
  cssClassSizeModal: string;
  isFullSizeModalOpen: boolean;
  isBigSizeModalOpen: boolean;
}

export class ImageTicketsCustomerView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cssClass: '.d-none',
      cssClassText: '.d-none',
      cssClassSizeModal: '',
      isFullSizeModalOpen: false,
      isBigSizeModalOpen: false,
    };
  }

  componentDidMount() {
    const { ticket } = this.props;
    switch (true) {
      case isImageIndividual(ticket.photoTypeName):
      case isImageGroup(ticket.photoTypeName):
      default:
        this.setState({
          cssClass: 'col-xl-4',
          cssClassText: 'h6',
          cssClassSizeModal: 'modal-xs',
        });
        break;
      case isImagePanoramic(ticket.photoTypeName):
        this.setState({
          cssClass: 'col-xl-12',
          cssClassText: 'h4',
          cssClassSizeModal: 'modal-xl',
        });
        break;
    }
  }

  onImageClick = () => {
    const { ticket, onImageClick } = this.props;
    onImageClick(ticket);
  };

  onFullSizeImageClose = event => {
    event.preventDefault();
    this.setState({
      isFullSizeModalOpen: false,
    });
  };

  onFullSizeImageOpen = event => {
    event.preventDefault();

    this.setState({
      isFullSizeModalOpen: true,
    });
  };

  onBigSizeImageClose = event => {
    event.preventDefault();
    this.setState({
      isBigSizeModalOpen: false,
    });
  };

  onBigSizeImageOpen = event => {
    event.preventDefault();

    this.setState({
      isBigSizeModalOpen: true,
    });
  };

  renderBigSizeModal = () => {
    const { isBigSizeModalOpen, cssClassSizeModal } = this.state;
    const { ticket } = this.props;

    const modalClasses = classNames({
      mDisplayBlock: isBigSizeModalOpen,
      show: isBigSizeModalOpen,
      modal: true,
      fade: true,
    });

    return (
      <div className={modalClasses}>
        <div className={`modal-dialog modal-dialog-centered ${cssClassSizeModal}`}>
          <div className="modal-content">
            <div className="modal-header p-0">
              <div className="modal-body p-0">
                <img
                  src={`${window.apiFile}/storage/images/${ticket.ticket}`}
                  alt="fullImages"
                  className="w-100"
                  onClick={this.onBigSizeImageClose}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderFullSizeModal = () => {
    const { isFullSizeModalOpen } = this.state;
    const { ticket } = this.props;

    const modalClasses = classNames({
      mDisplayBlock: isFullSizeModalOpen,
      show: isFullSizeModalOpen,
      mOverflowAuto: true,
      modal: true,
      fade: true,
    });

    return (
      <div className={modalClasses}>
        <img
          src={`${window.apiFile}/storage/images/${ticket.ticket}`}
          alt="fullImages"
          className="eFullSizeImage"
          onClick={this.onFullSizeImageClose}
        />
      </div>
    );
  };

  render() {
    const { ticket, isSelected } = this.props;
    const { cssClass, cssClassText, isFullSizeModalOpen, isBigSizeModalOpen } = this.state;
    const classname = propz.get(ticket, ['classname'], '');
    const imageRef = propz.get(ticket, ['bulkImageId'], '');
    const classes = isFullSizeModalOpen || isBigSizeModalOpen ? 'modal-open' : '';
    return (
      <div key={ticket.id} className={`${cssClass} ${classes} eImageTicket`}>
        <div className="d-flex flex-column align-items-center">
          <LazyLoadImage
            alt="image"
            height={SIZE_IMAGE_FOR_VIEWING.HEIGHT}
            src={`${window.apiFile}/storage/images/${ticket.ticket}`}
            onClick={this.onImageClick}
            className={isSelected ? 'mSelected' : 'eImageFrame'}
            effect="opacity"
          />
          <div className="">
            <img
              className="eFullImageIcon"
              width="20px"
              src="/dist/images/icon/zoomIn.png"
              onClick={this.onFullSizeImageOpen}
            />
          </div>
          <div className={`${cssClassText} text-center`}>{classname}</div>
          <div className={`${cssClassText} text-center`}>Image ref: {imageRef}</div>
          {this.renderFullSizeModal()}
        </div>
      </div>
    );
  }
}
