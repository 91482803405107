import * as React from 'react';
import { Component } from 'react';
import { ProgressBar } from '../../../../../../../components/ProgressBar/ProgressBar';
import { JobWizardModal } from './JobWizardModal/JobWizardModal';
import JobWizardEmails from './JobWizardEmails/JobWizardEmails';
import JobWizardEditor from './JobWizardEditor/JobWizardEditor';
import JobWizardPreview from './JobWizardPreview/JobWizardPreview';
import { jobsModeType } from '../Jobs';

interface Props {
  onCancelClick: () => void;
  isOpen: boolean;
  onSendNotificationModalClick: () => void;
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (body: any) => void;
  onRemoveAttachment: (event) => void;
  onEmailAttachmentChange: (event) => void;
  isError: boolean;
  isLoadingFile: boolean;
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
  isRawEditor: boolean;
  onRawEditorChange: (event) => void;

  jobEmails: { customerId?: string; email: string }[];
  wizardMode: jobsModeType;
}

interface State {
  currentStep: number;
}

const JOB_MODAL_TOTAL_STEP: number = 3;

export class JobWizard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentStep: 1,
    };
  }

  // step callbacks
  onNextClick = (): void => {
    this.setState(prevState => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  onPreviousClick = (): void => {
    const { currentStep } = this.state;

    if (currentStep === 1) {
      return;
    }

    this.setState({
      currentStep: currentStep - 1,
    });
  };

  getProgress(current: number, total: number): number {
    return (current / total) * 100;
  }

  renderViewByStep(): React.ReactNode {
    const { currentStep } = this.state;
    const {
      messageEmailSubject,
      messageEmailBody,
      messageAttachments,
      isError,
      isLoadingFile,
      onEmailSubjectChange,
      onEmailBodyChange,
      onRemoveAttachment,
      onEmailAttachmentChange,
      isRawEditor,
      onRawEditorChange,
      jobEmails,
    } = this.props;
    switch (currentStep) {
      case 1:
        return <JobWizardEmails jobEmails={jobEmails} />;
      case 2:
        return (
          <JobWizardEditor
            onEmailSubjectChange={onEmailSubjectChange}
            onEmailBodyChange={onEmailBodyChange}
            onEmailAttachmentChange={onEmailAttachmentChange}
            onRemoveAttachment={onRemoveAttachment}
            isError={isError}
            isLoadingFile={isLoadingFile}
            messageEmailSubject={messageEmailSubject}
            messageEmailBody={messageEmailBody}
            messageAttachments={messageAttachments}
            isRawEditor={isRawEditor}
            onRawEditorChange={onRawEditorChange}
          />
        );
      case 3:
        return (
          <JobWizardPreview
            messageAttachments={messageAttachments}
            messageEmailBody={messageEmailBody}
            messageEmailSubject={messageEmailSubject}
          />
        );
    }
  }

  render() {
    const { isOpen, onCancelClick, onSendNotificationModalClick } = this.props;
    const { currentStep } = this.state;

    return (
      <JobWizardModal
        isOpen={isOpen}
        title={'New message'}
        closeButtonText={'Close'}
        submitButtonText={'Next step'}
        onCloseClick={onCancelClick}
        onPreviousClick={this.onPreviousClick}
        onNextClick={this.onNextClick}
        onSendClick={onSendNotificationModalClick}
        hasNext={currentStep !== JOB_MODAL_TOTAL_STEP}
        hasPrevious={currentStep > 1}
      >
        <ProgressBar
          progress={this.getProgress(currentStep, JOB_MODAL_TOTAL_STEP)}
          step={currentStep}
          stepTotal={JOB_MODAL_TOTAL_STEP}
        />
        {this.renderViewByStep()}
      </JobWizardModal>
    );
  }
}
