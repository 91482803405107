import * as React from 'react';
import { FunctionComponent } from 'react';
import { NUMBER_INTERVAL } from 'Src/consts/table';

interface Props {
  onTableFilterChange: (event, value: string, options?) => void;
  field: string;
  from: string;
  to: string;
  onEnterPress?: (event) => void;
}

export const NumberIntervalField: FunctionComponent<Props> = props => {
  const { onTableFilterChange, field, from, to, onEnterPress } = props;

  return (
    <div className={'mMinWidth170 mMaxWidth250'}>
      <div className="input-group mMarginBottom4">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth60">From:</span>
        </div>
        <input
          onChange={event => {
            onTableFilterChange(event, field, NUMBER_INTERVAL.FROM);
          }}
          onKeyDown={onEnterPress}
          value={from}
          className="form-control mMaxWidth110"
        />
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth60">To:</span>
        </div>
        <input
          onChange={event => {
            onTableFilterChange(event, field, NUMBER_INTERVAL.TO);
          }}
          onKeyDown={onEnterPress}
          value={to}
          className="form-control mMaxWidth110"
        />
      </div>
    </div>
  );
};

NumberIntervalField.displayName = 'NumberIntervalField';
