export const VOUCHER_DISCOUNT_TYPE = {
  FREE_POSTAGE: 'FREE_POSTAGE',
  ORDER_DISCOUNT: 'ORDER_DISCOUNT',
  PRODUCT_DISCOUNT: 'PRODUCT_DISCOUNT',
  REDUCED_POSTAGE: 'REDUCED_POSTAGE',
};

export const VOUCHER_SCOPE = {
  WHOLE_PLATFORM: 'WHOLE_PLATFORM',
  SCHOOL: 'SCHOOL',
  LIST_OF_CUSTOMERS: 'LIST_OF_CUSTOMERS',
  JOB: 'JOB',
};

export const VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING = {
  FREE_POSTAGE: 'Free postage',
  ORDER_DISCOUNT: 'Order discount',
  PRODUCT_DISCOUNT: 'Product discount',
  REDUCED_POSTAGE: 'Reduced postage',
};

export const VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING = {
  WHOLE_PLATFORM: 'Whole platform',
  SCHOOL: 'School',
  LIST_OF_CUSTOMERS: 'List of customers',
  JOB: 'Job',
};

export const VOUCHER_STATUS = {
  NOT_READY: 'NOT_READY',
  OUTDATED: 'OUTDATED',
  VALID: 'VALID',
};

export const VOUCHER_PURCHASE_SOURCE = {
  ALL_SOURCES: 'ALL_SOURCES',
  WEB_PURCHASE: 'WEB_PURCHASE',
  MOBILE_PURCHASE: 'MOBILE_PURCHASE',
};

export const VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING = {
  ALL_SOURCES: 'All sources',
  WEB_PURCHASE: 'Web purchase',
  MOBILE_PURCHASE: 'Mobile purchase',
};
