/** Returns api endpoint based on given domain name */
export function apiSelector(domainName: string): { main: string; img: string; file: string; squad: string } {
  const { env } = parseDomainName(domainName);
  switch (env) {
    case 'local':
      return {
        main: `http://localhost:3000`,
        img: '',
        file: '//localhost:3003',
        squad: `http://localhost:3000`,
      };
    case 'dev':
      return {
        main: `https://api.stage.bp.squadintouch.com`,
        img: '',
        file: 'https://files.stage.bp.squadintouch.com',
        squad: `https://api.stage1.squadintouch.com`,
      };
    case 'stage':
      return {
        main: `https://api.stage.bp.squadintouch.com`,
        img: '',
        file: '//files.stage.bp.squadintouch.com',
        squad: `https://api.stage1.squadintouch.com`,
      };
    case 'preprod2':
      return {
        main: `https://api.preprod2.bp.squadintouch.com`,
        img: '',
        file: '//files.preprod2.bp.squadintouch.com',
        squad: `https://api2.preprod2.squadintouch.com`,
      };
    case 'preprod':
      return {
        main: `https://api.preprod.bp.squadintouch.com`,
        img: '',
        file: '//files.preprod.bp.squadintouch.com',
        squad: `https://api2.preprod.squadintouch.com`,
      };
    case 'production':
      return {
        main: `https://api.online.bentleypics.com`,
        img: '',
        file: '//files.online.bentleypics.com',
        squad: `https://api2.squadintouch.com`,
      };
    default:
      return {
        main: '',
        img: '',
        file: '',
        squad: '',
      };
  }
}

function getEnv(domainName: string) {
  const isLocal = domainName.includes('local');
  const isStage = domainName.includes('stage');
  const isDev = domainName.includes('dev');
  const isPreprod2 = domainName.includes('preprod2');
  const isPreprod = domainName.includes('preprod');

  let env;

  switch (true) {
    case isLocal:
      env = 'local';
      break;
    case isStage:
      env = 'stage';
      break;
    case isDev:
      env = 'dev';
      break;
    case isPreprod2:
      env = 'preprod2';
      break;
    case isPreprod:
      env = 'preprod';
      break;
    default:
      env = 'production';
      break;
  }

  return env;
}

function getModel(domainName: string) {
  const isOrders = domainName.includes('orders');
  const isApp = domainName.includes('app');
  const isPayment = domainName.includes('payment');
  const isSchools = domainName.includes('schools.');

  let model;

  switch (true) {
    case isOrders:
      model = 'orders';
      break;
    case isApp:
      model = 'app';
      break;
    case isSchools:
      model = 'schools';
      break;
    case isPayment:
      model = 'payment';
      break;
    default:
      console.error('Fallback to default model');
      model = 'app';
  }

  return model;
}

/** Parses domain name to structure */
export function parseDomainName(domainName: string) {
  return {
    env: getEnv(domainName),
    model: getModel(domainName),
  };
}
