import * as React from 'react';
import './Products.scss';

interface Props {
  images: any[];
}

export function Products(props: Props) {
  const { images } = props;

  return (
    <div className="bProductsWrapper">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="eProductsTitle pt-5">BROWSE PRODUCTS</div>
          </div>
        </div>
        <div className="eProductsImages">
          <div className="row justify-content-center">
            {images.map((image, index) => {
              return (
                <div className="col-xl-3" key={`product_image_${index}`}>
                  <div className="eProductsImageWrapper">
                    <img className="eProductsImage" src={image.pic} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
