import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { AppUser } from 'Src/views/App/App';
import { Button } from 'Src/components/Button/Button';
import { Order } from '../../../../../../../models/orders';
import { useState } from 'react';
import { Switch } from '../../../../../../../components/Switch/Switch';
import {
  ORDER_STATUS,
  ORDER_STATUS_SERVER_TO_CLIENT_MAPPING,
  ORDER_FORM_TABS,
  ORDER_DELIVERY_TYPE,
  ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING,
} from '../../../../../../../consts/order';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import {
  getSchoolNameAndCode,
  searchFunctionOrderSchoolsByCode,
  searchFunctionSchoolsByCode,
} from '../../../../../../../helpers/autocomplete';

interface Props {
  user: AppUser;
  onCancel: () => void;
  onSubmit: (data) => void;
  order?: Order;
}

export function OrderForm(props: Props) {
  const { onSubmit, onCancel, order } = props;

  const isOrderExist = typeof order !== 'undefined';

  const orderForm = {
    orderNumber: isOrderExist ? order.orderNumber : '',
    orderStatus: isOrderExist ? order.orderStatus : ORDER_STATUS.NEW,
    worldpayTransId: isOrderExist ? order.worldpayTransId : '',
    customerEmail: isOrderExist ? order.customerEmail : '',
    customerPhone: isOrderExist ? order.customerPhone : '',
    orderAmount: isOrderExist ? order.orderAmount : '',
    deliveryAmount: isOrderExist ? order.deliveryAmount : '',
    deliveryType: isOrderExist ? order.deliveryType : '',
    comment: isOrderExist ? order.comment : '',
    invoice: {
      firstName: isOrderExist ? propz.get(order, ['invoice', 'firstName'], '') : '',
      lastName: isOrderExist ? propz.get(order, ['invoice', 'lastName'], '') : '',
      postcode: isOrderExist ? propz.get(order, ['invoice', 'postcode'], '') : '',
      address1: isOrderExist ? propz.get(order, ['invoice', 'address1'], '') : '',
      address2: isOrderExist ? propz.get(order, ['invoice', 'address2'], '') : '',
      town: isOrderExist ? propz.get(order, ['invoice', 'town'], '') : '',
      region: isOrderExist ? propz.get(order, ['invoice', 'region'], '') : '',
      country: isOrderExist ? propz.get(order, ['invoice', 'country'], '') : '',
      email: isOrderExist ? propz.get(order, ['invoice', 'email'], '') : '',
      phone: isOrderExist ? propz.get(order, ['invoice', 'phone'], '') : '',
    },
    delivery: {
      firstName: isOrderExist ? propz.get(order, ['delivery', 'firstName']) : '',
      lastName: isOrderExist ? propz.get(order, ['delivery', 'lastName']) : '',
      postcode: isOrderExist ? propz.get(order, ['delivery', 'postcode']) : '',
      address1: isOrderExist ? propz.get(order, ['delivery', 'address1']) : '',

      address2: isOrderExist ? propz.get(order, ['delivery', 'address2']) : '',
      town: isOrderExist ? propz.get(order, ['delivery', 'town']) : '',
      region: isOrderExist ? propz.get(order, ['delivery', 'region']) : '',
      country: isOrderExist ? propz.get(order, ['delivery', 'country']) : '',
      email: isOrderExist ? propz.get(order, ['delivery', 'email'], '') : '',
      phone: isOrderExist ? propz.get(order, ['delivery', 'phone'], '') : '',
    },
    schoolDelivery: {
      schoolId: isOrderExist ? propz.get(order, ['schoolDelivery', 'schoolId'], '') : '',
      schoolName: isOrderExist ? propz.get(order, ['schoolDelivery', 'schoolName'], '') : '',
      schoolCode: isOrderExist ? propz.get(order, ['schoolDelivery', 'schoolCode'], '') : '',
      studentName: isOrderExist ? propz.get(order, ['schoolDelivery', 'studentName'], '') : '',
      studentForm: isOrderExist ? propz.get(order, ['schoolDelivery', 'studentForm'], '') : '',
      phone: isOrderExist ? propz.get(order, ['delivery', 'phone'], '') : '',
    },
  };

  const isInvoiceEqualDelivery = (): boolean => {
    const { invoice, delivery } = orderForm;
    return JSON.stringify(invoice) === JSON.stringify(delivery);
  };

  const [isSame, setIsSame] = useState(!isOrderExist ? true : isInvoiceEqualDelivery());

  const OrderSchema = Yup.object().shape({
    orderStatus: Yup.string().required('Required'),
    customerEmail: Yup.string().email('Must be valid email'),
    deliveryType: Yup.string().required('Required'),
    schoolDelivery: Yup.object().shape({
      schoolId: Yup.string().test('schoolId', 'Should be defined', function(value) {
        const deliveryType = this.from[1].value.deliveryType;
        const isDeliveryTypeSchool = deliveryType === ORDER_DELIVERY_TYPE.SCHOOL;
        if (isDeliveryTypeSchool) {
          const isSchoolIdExist = typeof value !== 'undefined' && value !== '';
          if (!isSchoolIdExist) {
            setActiveIndex(3);
          }
          return isSchoolIdExist;
        } else {
          return true;
        }
      }),
    }),
  });

  const getSchools = (text: string) => {
    const { user } = props;
    return searchFunctionSchoolsByCode(user, text);
  };

  const getOrderSchools = (text: string) => {
    const { user, order } = props;
    const orderId = propz.get(order, ['id']);
    return searchFunctionOrderSchoolsByCode(user, text, orderId);
  };

  const generalTabContent = () => (
    <div className="form-group form-check">
      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Order status</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field component={'select'} name="orderStatus" className="form-control mb-3">
              <option key={ORDER_STATUS.NEW} value={ORDER_STATUS.NEW}>
                {ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[ORDER_STATUS.NEW]}
              </option>
              <option key={ORDER_STATUS.PAID} value={ORDER_STATUS.PAID}>
                {ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[ORDER_STATUS.PAID]}
              </option>
              <option key={ORDER_STATUS.DOWNLOADED} value={ORDER_STATUS.DOWNLOADED}>
                {ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[ORDER_STATUS.DOWNLOADED]}
              </option>
              <option key={ORDER_STATUS.DISPATCHED} value={ORDER_STATUS.DISPATCHED}>
                {ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[ORDER_STATUS.DISPATCHED]}
              </option>
              <option key={ORDER_STATUS.REPRINT} value={ORDER_STATUS.REPRINT}>
                {ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[ORDER_STATUS.REPRINT]}
              </option>
            </Field>
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="orderStatus" />
      </div>

      {isOrderExist && (
        <div className="form-group">
          <div className="row">
            <div className="col-auto">
              <label className="mt-2">Order number</label>
            </div>
            <div className="col pr-3 pl-0">
              <Field name="orderNumber" className="form-control mb-3" disabled />
            </div>
          </div>
        </div>
      )}

      {isOrderExist && (
        <div className="form-group">
          <div className="row">
            <div className="col-auto">
              <label className="mt-2">WP transaction ID</label>
            </div>
            <div className="col pr-3 pl-0">
              <Field name="worldpayTransId" className="form-control mb-3" disabled />
            </div>
          </div>
        </div>
      )}

      <div className="form-group">
        <label>Customer email</label>
        <Field name="customerEmail" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="customerEmail" />
      </div>

      <div className="form-group">
        <label>Customer phone</label>
        <Field name="customerPhone" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="customerPhone" />
      </div>

      <div className="form-group">
        <label>Order amount</label>
        <Field name="orderAmount" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="orderAmount" />
      </div>

      <div className="form-group">
        <label>Delivery amount</label>
        <Field name="deliveryAmount" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="deliveryAmount" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Delivery type</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="deliveryType" component={'select'} className="form-control mb-3">
              <option key="Empty_delivery_type" value=""></option>
              <option key={ORDER_DELIVERY_TYPE.HOME} value={ORDER_DELIVERY_TYPE.HOME}>
                {ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING[ORDER_DELIVERY_TYPE.HOME]}
              </option>
              <option key={ORDER_DELIVERY_TYPE.SCHOOL} value={ORDER_DELIVERY_TYPE.SCHOOL}>
                {ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING[ORDER_DELIVERY_TYPE.SCHOOL]}
              </option>
            </Field>
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="deliveryType" />
      </div>

      <div className="form-group">
        <label>Comment</label>
        <Field component={'textarea'} name="comment" className="form-control mb-3" />
      </div>
    </div>
  );

  const billingTabContent = () => (
    <>
      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Billing first name</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="invoice.firstName" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.firstName" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Billing last name</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="invoice.lastName" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.lastName" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Billing postcode</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="invoice.postcode" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.postcode" />
      </div>

      <div className="form-group">
        <label>Billing address 1</label>
        <Field name="invoice.address1" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.address1" />
      </div>

      <div className="form-group">
        <label>Billing address 2</label>
        <Field name="invoice.address2" className="form-control mb-3" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Billing town</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="invoice.town" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.town" />
      </div>

      <div className="form-group">
        <label>Billing region</label>
        <Field name="invoice.region" className="form-control mb-3" />
      </div>

      <div className="form-group">
        <label>Billing country</label>
        <Field name="invoice.country" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.country" />
      </div>

      <div className="form-group">
        <label>Billing email</label>
        <Field name="invoice.email" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.email" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Billing phone</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="invoice.phone" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="invoice.phone" />
      </div>
    </>
  );

  const deliveryTabContent = () => (
    <>
      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Delivery first name</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="delivery.firstName" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.firstName" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Delivery last name</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="delivery.lastName" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.lastName" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Delivery postcode</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="delivery.postcode" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.postcode" />
      </div>

      <div className="form-group">
        <label>Delivery address 1</label>
        <Field name="delivery.address1" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.address1" />
      </div>

      <div className="form-group">
        <label>Delivery address 2</label>
        <Field name="delivery.address2" className="form-control mb-3" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Delivery town</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="delivery.town" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.town" />
      </div>

      <div className="form-group">
        <label>Delivery region</label>
        <Field name="delivery.region" className="form-control mb-3" />
      </div>

      <div className="form-group">
        <label>Delivery country</label>
        <Field name="delivery.country" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.country" />
      </div>

      <div className="form-group">
        <label>Delivery email</label>
        <Field name="delivery.email" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.email" />
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-auto">
            <label className="mt-2">Delivery phone</label>
          </div>
          <div className="col pr-3 pl-0">
            <Field name="delivery.phone" className="form-control mb-3" />
          </div>
        </div>
        <ErrorMessage component="div" className="alert alert-danger" name="delivery.phone" />
      </div>

      <Switch
        onChange={(): void => {
          if (isSame) {
            const { delivery } = orderForm;
            for (let key in delivery) {
              delivery[key] = '';
            }
          }
          setIsSame(!isSame);
        }}
        value={isSame}
        text={'Billing address is the same'}
        name={'isSame'}
        customClass={'mb-3'}
      />
    </>
  );

  const schoolTabContent = (values, setFieldValue) => (
    <>
      <div className="form-group">
        <label>School</label>
        <Field name="schoolDelivery.schoolName">
          {() => (
            <Autocomplete
              searchFunction={isOrderExist ? getOrderSchools : getSchools}
              getElementTitle={getSchoolNameAndCode}
              customClass="mFullWidth mb-3"
              defaultItem={{ name: values.schoolDelivery.schoolName, code: values.schoolDelivery.schoolCode }}
              onSelect={school => {
                setFieldValue('schoolDelivery.schoolName', school.name);
                setFieldValue('schoolDelivery.schoolId', school.id);
                setFieldValue('schoolDelivery.schoolCode', school.code);
              }}
            />
          )}
        </Field>
        <ErrorMessage component="div" className="alert alert-danger" name="schoolDelivery.schoolId" />
      </div>

      <div className="form-group">
        <label>Student's name</label>
        <Field name="schoolDelivery.studentName" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="schoolDelivery.studentName" />
      </div>

      <div className="form-group">
        <label>Class/Form</label>
        <Field name="schoolDelivery.studentForm" className="form-control mb-3" />
        <ErrorMessage component="div" className="alert alert-danger" name="schoolDelivery.studentForm" />
      </div>
    </>
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const onTabClick = index => {
    setActiveIndex(index);
  };
  const tabs = [
    ORDER_FORM_TABS.GENERAL,
    ORDER_FORM_TABS.DELIVERY,
    ORDER_FORM_TABS.BILLING,
    ORDER_FORM_TABS.SCHOOL_DELIVERY,
  ];
  const tabsContent = [generalTabContent, deliveryTabContent, billingTabContent, schoolTabContent];

  const setTabs = (values, setFieldValue) => {
    return (
      <>
        <ul className="nav nav-tabs mb-3">
          {tabs.map((item, index) =>
            item === ORDER_FORM_TABS.BILLING && isSame ? null : (
              <li className="nav-item" key={`order_form_tab_${item}`}>
                <div className={`nav-link ${index === activeIndex ? 'active' : ''}`} onClick={() => onTabClick(index)}>
                  {item}
                </div>
              </li>
            )
          )}
        </ul>

        <div className="tab-content">
          {tabsContent.map((item, index) => (
            <div
              className={`tab-pane ${index === activeIndex ? 'show active' : ''}`}
              key={`order_form_tab_content_${index}`}
              onClick={() => onTabClick(index)}
            >
              {item(values, setFieldValue)}
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={orderForm}
            validationSchema={OrderSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={values => {
              if (isSame) {
                const { invoice, ...rest } = values;
                const data = {
                  ...rest,
                  invoice: values.delivery,
                };
                onSubmit(data);
              } else {
                onSubmit(values);
              }
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form>
                {setTabs(values, setFieldValue)}
                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <Button
                  onClick={handleSubmit}
                  text={'Save'}
                  customClass={'mt-3 mb-3 btn btn-primary'}
                  type={'submit'}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
