import * as React from 'react';
import { History, Location } from 'history';
import * as Hls from 'hls.js';
import * as propz from 'propz';
import './OneTimeCode.scss';
import { setIsSignFromOneTimeCodeWaitingToStorage } from 'Src/helpers/sessionStorage';

interface Props {
  history: History;
  location: Location;
}

interface State {}

export class OneTimeCodeViewWaiting extends React.Component<Props, State> {
  videoFileRef: any;

  constructor(props) {
    super(props);

    this.videoFileRef = React.createRef();
  }

  componentDidMount() {
    const { state } = this.props.history.location as any;
    const promoteMovie = propz.get(state, ['promoteMovie']);
    const isPromoteMovieExist = typeof promoteMovie !== 'undefined' && promoteMovie !== '';

    if (isPromoteMovieExist) {
      const hls = new Hls();
      hls.loadSource(promoteMovie);
      hls.attachMedia(this.videoFileRef);
    }
  }

  onCreateAccountClick = () => {
    this.props.history.push('/registration');
  };

  onLoginClick = () => {
    this.props.history.push('/login');
    setIsSignFromOneTimeCodeWaitingToStorage(true);
  };

  render() {
    const { state } = this.props.history.location as any;
    const promoteMovie = propz.get(state, ['promoteMovie']);

    const isPromoteMovieExist = typeof promoteMovie !== 'undefined' && promoteMovie !== '';

    return (
      <div className="container">
        <div className="row">
          <div className={'col-xl-12'}>
            <div className={'bOneTimeCodeViewWaiting'}>
              <div className={'eOneTimeCodeViewWaitingTitle'}>THANK YOU!</div>
              <div className={'eOneTimeCodeViewWaitingText'}>
                We are currently busy uploading your school photographs to our website however create a customer account
                and we will let you know as soon as they are ready to view and order!
              </div>

              {isPromoteMovieExist && (
                <video
                  controls={true}
                  style={{ width: '100%', height: '400px', backgroundColor: '#000000' }}
                  ref={input => (this.videoFileRef = input)}
                />
              )}

              <button
                className={
                  isPromoteMovieExist ? 'eOneTimeCodeViewWaitingButton marginTop40' : 'eOneTimeCodeViewWaitingButton'
                }
                onClick={this.onLoginClick}
              >
                Sign in
              </button>

              <button
                className={
                  isPromoteMovieExist
                    ? 'eOneTimeCodeViewWaitingButton marginTop40 ml-4'
                    : 'eOneTimeCodeViewWaitingButton ml-4'
                }
                onClick={this.onCreateAccountClick}
              >
                Create account
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
