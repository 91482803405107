import * as React from 'react';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Parser } from 'json2csv';
import {
  ColumnDefinition,
  FunctionAccessorColumnDefinition,
  isFunctionAccessorBasedColumnDefinition,
  isPathAccessorBasedColumnDefinition,
  PathAccessorColumnDefinition,
} from 'Src/helpers/table';
import * as propz from 'propz';

interface Props {
  selectedItems: any[];
  columns: ColumnDefinition[];
}

export const CsvIcon: FunctionComponent<Props> = props => {
  const { selectedItems, columns } = props;

  const onClick = (): void => {
    const selectedItemsConverted = selectedItems.map(item => {
      let itemObj = {};
      columns.forEach(column => {
        let value;

        switch (true) {
          case isPathAccessorBasedColumnDefinition(column): {
            const pathAccessorBasedColumn = column as PathAccessorColumnDefinition;
            value = propz.get<any>(item, pathAccessorBasedColumn.accessor, '');
            break;
          }
          case isFunctionAccessorBasedColumnDefinition(column): {
            const funcAccessorColumn = column as FunctionAccessorColumnDefinition;
            value = funcAccessorColumn.accessor(item);
            break;
          }
        }
        const regExp = /\n/gi;
        const strValue = String(value)
          .trim()
          .replace(regExp, ' ');
        itemObj = { ...itemObj, ...{ [column.text]: strValue } };
      });

      return itemObj;
    });

    const fields = columns.map(column => column.text);
    const opts = { fields };

    try {
      const parser = new (Parser as any)(opts);
      const csv = parser.parse(selectedItemsConverted);

      // copy-paste from old frontend
      /*global Blob:true*/
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (window.navigator.msSaveBlob) {
        // IE 10+
        window.navigator.msSaveBlob(blob, 'file.csv');
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          /*global URL:true*/
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', 'file.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isSelectedItemsExist = selectedItems.length > 0;
  const buttonClassName = isSelectedItemsExist
    ? 'btn-outline-primary'
    : 'btn-outline-secondary';

  return (
    <div className={'d-inline-block ml-2'}>
      <button
        className={`btn btn-sm ${buttonClassName}`}
        onClick={onClick}
        disabled={!isSelectedItemsExist}
      >
        <FontAwesomeIcon icon={'file-csv'} className="mr-2" />
        <span className="mr-2">Download as csv</span>
      </button>
    </div>
  );
};

CsvIcon.displayName = 'CsvIcon';
