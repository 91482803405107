import * as React from 'react';
import * as propz from 'propz';
import { Product } from 'Src/models/products';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { AppUser } from '../../../../../App';
import { createTicket, uploadImage } from '../../../../../../../services/superadmin/image';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import MaskedInput from 'react-text-mask';
import { getCurrencyMask } from '../../../../../../../helpers/currencyMask';
import { NUMBER_MASK, NUMBER_MASK_TO_WEIGHT } from '../../../../../../../consts/mask';
import { ProductType } from '../../../../../../../models/productTypes';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  product?: Product;
  productTypes: ProductType[];
  user: AppUser;
}

const ProductSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  productTypeId: Yup.string().required('Required'),
  pricing: Yup.object().shape({
    price: Yup.string()
      .required('Required')
      .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
    deliveryCharge: Yup.string()
      .required('Required')
      .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
  }),
  dimensions: Yup.object().shape({
    weight: Yup.string().matches(/^\d{0,100}(.)?\d{1,3}$/, {
      excludeEmptyString: true,
      message: 'Should contain only number',
    }),
    length: Yup.string().matches(/^\d{0,100}(.)?\d{1,2}$/, {
      excludeEmptyString: true,
      message: 'Should contain only number',
    }),
    width: Yup.string().matches(/^\d{0,100}(.)?\d{1,2}$/, {
      excludeEmptyString: true,
      message: 'Should contain only number',
    }),
    height: Yup.string().matches(/^\d{0,100}(.)?\d{1,2}$/, {
      excludeEmptyString: true,
      message: 'Should contain only number',
    }),
  }),
});

export function ProductForm(props: Props) {
  const { product, onSubmit, onCancel, productTypes } = props;
  const isProductExist = typeof product !== 'undefined';
  const isPicUrlExists = isProductExist && typeof product.pic !== 'undefined' && product.pic !== '';
  const productForm = {
    name: isProductExist ? propz.get(product, ['name'], '') : '',
    productTypeId: isProductExist ? propz.get(product, ['productType', 'typeId'], '') : '',
    referenceId: isProductExist ? propz.get(product, ['referenceId'], '') : '',
    description: isProductExist ? propz.get(product, ['description'], '') : '',
    settings: {
      isPreviewAvailable: isProductExist ? propz.get(product, ['settings', 'isPreviewAvailable'], false) : false,
      isOutsidePackageAvailable: isProductExist
        ? propz.get(product, ['settings', 'isOutsidePackageAvailable'], false)
        : false,
      isDigitalProduct: isProductExist ? propz.get(product, ['settings', 'isDigitalProduct'], false) : false,
    },
    pricing: {
      price: isProductExist ? propz.get(product, ['pricing', 'price'], 0) : 0,
      deliveryCharge: isProductExist ? propz.get(product, ['pricing', 'deliveryCharge'], 0) : 0,
    },
    pic: {
      ticketId: isPicUrlExists ? product.pic.ticketId || '' : '',
      key: isPicUrlExists ? product.pic.key || '' : '',
      mimeType: isPicUrlExists ? product.pic.mimeType || '' : '',
    },
    dimensions: {
      weight: isProductExist ? propz.get(product, ['dimensions', 'weight'], '') : '',
      length: isProductExist ? propz.get(product, ['dimensions', 'length'], '') : '',
      width: isProductExist ? propz.get(product, ['dimensions', 'width'], '') : '',
      height: isProductExist ? propz.get(product, ['dimensions', 'height'], '') : '',
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={productForm} validationSchema={ProductSchema} onSubmit={onSubmit}>
            {({ setFieldValue, values, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <div className="row m-0">
                      <label className="mt-2">Product type</label>
                      <Field
                        name="productTypeId"
                        component="select"
                        placeholder="Select product type"
                        className="form-control mb-3 w-50 ml-3"
                      >
                        <option value={''} />
                        {productTypes.map(productType => (
                          <option key={productType.id} value={productType.id}>
                            {productType.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="productTypeId" />
                  </div>

                  <div className="form-group">
                    <div className="row m-0">
                      <label className="mt-2">Halse product reference Id</label>
                      <Field name="referenceId" className="form-control mb-3 w-25 ml-3" />
                    </div>
                  </div>
                  {/*Please, close your eyes and work*/}
                  <div className="form-group">
                    <div className="row m-0">
                      <label className="mt-2 p-0 col-4">Product description (for parents)</label>
                      <Field name="name" className="form-control mb-3 col" />
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="name" />
                  </div>
                  {/*Please, close your eyes and work*/}
                  <div className="form-group">
                    <label>Product name (for Bentley)</label>
                    <Field component={'textarea'} name="description" className="form-control mb-3" />
                  </div>

                  <Field name="settings.isPreviewAvailable">
                    {({ field }) => <Switch {...field} text={'Preview available?'} customClass="mb-3" />}
                  </Field>

                  <Field name="settings.isOutsidePackageAvailable">
                    {({ field }) => <Switch {...field} text={'Available outside package?'} customClass="mb-3" />}
                  </Field>

                  <Field name="settings.isDigitalProduct">
                    {({ field }) => (
                      <Switch
                        {...field}
                        text={'Digital product? (Product will be delivered via email to customer)'}
                        customClass="mb-3"
                      />
                    )}
                  </Field>

                  <div className="form-group">
                    <div className="row mb-2">
                      <div className="row col-5">
                        <label className="col-5 col-form-label">Base price</label>
                        <div className="input-group col-6">
                          <div className="input-group-prepend">
                            <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                          </div>
                          <Field name="pricing.price">
                            {({ field }) => (
                              <MaskedInput
                                type="text"
                                className="form-control"
                                mask={getCurrencyMask}
                                onChange={event => {
                                  setFieldValue('pricing.price', event.target.value);
                                }}
                                value={values.pricing.price}
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="row col-5">
                        <label className="col-6 col-form-label">Delivery charge</label>
                        <div className="input-group col-6">
                          <div className="input-group-prepend">
                            <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                          </div>
                          <Field name="pricing.deliveryCharge">
                            {({ field }) => (
                              <MaskedInput
                                type="text"
                                className="form-control"
                                mask={getCurrencyMask}
                                onChange={event => {
                                  setFieldValue('pricing.deliveryCharge', event.target.value);
                                }}
                                value={values.pricing.deliveryCharge}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="pricing.deliveryCharge" />
                    <ErrorMessage component="div" className="alert alert-danger" name="pricing.price" />
                  </div>

                  <div className="mb-2">Dimensions</div>

                  <div className="form-group row mGutters">
                    <div className="col-md-3 input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Weight</div>
                      </div>
                      <Field name="dimensions.weight">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            className="form-control"
                            mask={NUMBER_MASK_TO_WEIGHT}
                            onChange={event => {
                              setFieldValue(`dimensions.weight`, event.target.value);
                            }}
                            value={values.dimensions.weight}
                            placeholder={'0.000'}
                          />
                        )}
                      </Field>
                      <div className="input-group-append">
                        <span className="input-group-text">kg</span>
                      </div>
                    </div>

                    <div className="col-md-3 input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Length</div>
                      </div>
                      <Field name="dimensions.length">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            className="form-control"
                            mask={NUMBER_MASK}
                            onChange={event => {
                              setFieldValue('dimensions.length', event.target.value);
                            }}
                            value={values.dimensions.length}
                            placeholder={'000.00'}
                          />
                        )}
                      </Field>
                      <div className="input-group-append">
                        <span className="input-group-text">cm</span>
                      </div>
                    </div>

                    <div className="col-md-3 input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Width</div>
                      </div>
                      <Field name="dimensions.width">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            className="form-control"
                            mask={NUMBER_MASK}
                            onChange={event => {
                              setFieldValue('dimensions.width', event.target.value);
                            }}
                            value={values.dimensions.width}
                            placeholder={'000.00'}
                          />
                        )}
                      </Field>
                      <div className="input-group-append">
                        <span className="input-group-text">cm</span>
                      </div>
                    </div>

                    <div className="col-md-3 input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Height</div>
                      </div>
                      <Field name="dimensions.height">
                        {({ field }) => (
                          <MaskedInput
                            type="text"
                            className="form-control"
                            mask={NUMBER_MASK}
                            onChange={event => {
                              setFieldValue('dimensions.height', event.target.value);
                            }}
                            value={values.dimensions.height}
                            placeholder={'000.00'}
                          />
                        )}
                      </Field>
                      <div className="input-group-append">
                        <span className="input-group-text">cm</span>
                      </div>
                    </div>
                  </div>

                  <ErrorMessage component="div" className="alert alert-danger" name="dimensions.weight" />

                  <ErrorMessage component="div" className="alert alert-danger" name="dimensions.length" />

                  <ErrorMessage component="div" className="alert alert-danger" name="dimensions.width" />

                  <ErrorMessage component="div" className="alert alert-danger" name="dimensions.height" />

                  <div className="form-group">
                    <label>Picture</label>
                    <Field name="pic">
                      {() => (
                        <input
                          type="file"
                          className="form-control-file"
                          onChange={event => {
                            const { user } = props;
                            const file = event.target.files[0];
                            const formData = new FormData();
                            formData.append('file', file);
                            uploadImage(user, formData)
                              .then(data => {
                                setFieldValue('pic.key', data.key);
                                setFieldValue('pic.mimeType', data.mimeType);
                                return data;
                              })
                              .then(data =>
                                createTicket(user, data).then(data => {
                                  const key = data.ticket;
                                  setFieldValue('pic.ticketId', key);
                                })
                              );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {values.pic.ticketId !== '' && (
                    <div className="w-25">
                      <button
                        type="button"
                        className="close"
                        onClick={() => {
                          setFieldValue('pic.ticketId', '');
                          setFieldValue('pic.key', '');
                          setFieldValue('pic.mimeType', '');
                        }}
                      >
                        <span>&times;</span>
                      </button>
                      <img
                        className="img-fluid img-thumbnail"
                        src={`${window.apiFile}/storage/images/${values.pic.ticketId}`}
                        alt="News picture"
                      />
                    </div>
                  )}
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
