import axios from 'axios';

export function resetRequestPassword(email: string) {
  const data = {
    email,
  };
  const config = {};

  return axios.post(`${window.apiBase}/public/passwords/resetRequest`, data, config).then(response => {
    return response.data;
  });
}

export function resetPassword(password: string, secretKey: string) {
  const data = {
    newPassword: password,
    secretKey: secretKey,
  };
  const config = {};

  return axios.post(`${window.apiBase}/public/passwords/reset`, data, config).then(response => {
    return response.data;
  });
}
