import * as React from 'react';
import { Button } from '../../../../components/Button/Button';

interface Props {
  onLogoutClick: () => void;
}

export function TopNavigation(props: Props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-end">
      <Button onClick={props.onLogoutClick} text={'Logout'} />
    </nav>
  );
}
