import * as React from 'react';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { rounding } from '../../../helpers/converting';

interface Props {
  selectedItems: any[];
  field: string;
  text: string;
}

export function SumField(props: Props) {
  const { selectedItems, field, text } = props;

  const calculateField =
    selectedItems.length === 0
      ? 0
      : Lazy(selectedItems)
          .map(item => {
            if (field === 'totalOfSales') {
              const orderAmount = propz.get(item, ['orderAmount'], 0);
              const deliveryAmount = propz.get(item, ['deliveryAmount'], 0);
              return orderAmount - deliveryAmount;
            } else {
              return propz.get(item, [field], 0);
            }
          })
          .sum();

  return (
    <div className={'mr-3 d-inline font-weight-bold'}>
      {text}
      {rounding(calculateField)}
    </div>
  );
}
