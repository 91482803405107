import axios from 'axios';
import { AppUser } from '../../views/App/App';

export function uploadZipFile(user: AppUser, jobId, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`${window.apiBase}/admin/jobs/${jobId}/import`, formData, config).then(response => {
    return response.data;
  });
}

export function asyncUploadZipFile(user: AppUser, jobId: string, uploadType: string, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios
    .post(`${window.apiBase}/admin/system/import/${uploadType}/async/${jobId}`, formData, config)
    .then(response => {
      return response.data;
    });
}

export function syncUploadZipFile(user: AppUser, jobId: string, uploadType: string, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios
    .post(`${window.apiBase}/admin/system/import/${uploadType}/sync/${jobId}`, formData, config)
    .then(response => {
      return response.data;
    });
}
