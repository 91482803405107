import axios from 'axios';
import * as propz from 'propz';
import { AppSchoolsUser } from '../views/AppSchools/AppSchools';
import { clearSessions } from './session';
import { ALL_LIMIT } from '../consts/table';
import { NOT_AUTHORIZED_STATUS_CODE } from '../consts/common';

export function loginSchoolApp(email: string, password: string) {
  return axios.post(`${window.apiSquad}/i/login`, { email, password }, {}).then(response => {
    return response.data;
  });
}

export function become(user: AppSchoolsUser, name: string) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiSquad}/i/roles/${name}/become`, {}, config).then(response => {
    return response.data;
  });
}

export function getRoles(user: AppSchoolsUser) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiSquad}/i/roles`, config).then(
    response => {
      return response.data;
    },
    err => {
      const status = propz.get(err, ['response', 'status']);
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        clearSessions();
        //fix it later
        document.location.reload();
      } else {
        throw err;
      }
    }
  );
}

export function logoutSchoolApp(loginSessionKey, roleSessionKey) {
  const loginConfig = {
    headers: { usid: loginSessionKey },
  };

  const roleConfig = {
    headers: { usid: roleSessionKey },
  };

  return axios.delete(`${window.apiSquad}/i/sessions/${loginSessionKey}`, loginConfig).then(
    () => {
      return axios.delete(`${window.apiSquad}/i/sessions/${roleSessionKey}`, roleConfig);
    },
    () => {
      return axios.delete(`${window.apiSquad}/i/sessions/${roleSessionKey}`, roleConfig);
    }
  );
}

export function getSchoolSchoolApp(user: AppSchoolsUser, schoolId: string, optKey?: string) {
  const key = user.roleSessionKey;

  let config;
  if (typeof optKey !== 'undefined') {
    config = {
      headers: { usid: optKey },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiSquad}/i/schools/${schoolId}`, config).then(
    response => {
      return response.data;
    },
    err => {
      const status = propz.get(err, ['response', 'status']);
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        clearSessions();
        //fix it later
        document.location.reload();
      } else {
        throw err;
      }
    }
  );
}

export function roleout(roleSessionKey) {
  const roleConfig = {
    headers: { usid: roleSessionKey },
  };

  return axios.delete(`${window.apiSquad}/i/sessions/${roleSessionKey}`, roleConfig);
}

export function resetRequestPasswordSchoolApp(email: string) {
  const data = {
    email,
  };
  const config = {};

  return axios.post(`${window.apiSquad}/public/passwords/resetRequest`, data, config).then(response => {
    return response.data;
  });
}

export function resetPasswordSchoolApp(password: string, secretKey: string) {
  const data = {
    newPassword: password,
    secretKey: secretKey,
  };
  const config = {};

  return axios.post(`${window.apiSquad}/public/passwords/reset`, data, config).then(response => {
    return response.data;
  });
}

export function checkAvailabilitySchoolApp(data) {
  return axios.post(`${window.apiSquad}/public/register/check`, data).then(response => {
    return response.data;
  });
}

export function getBucketConfig(user: AppSchoolsUser, schoolId: string) {
  const key = user.roleSessionKey;

  const config = {
    headers: {
      usid: key,
    },
  };

  return axios.get(`${window.apiSquad}/i/schools/${schoolId}/newConfig`, config).then(response => {
    return response.data;
  });
}

export function getSUSchools(user: AppSchoolsUser, schoolId: string) {
  const key = user.roleSessionKey;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: ALL_LIMIT,
        skip: 0,
      },
    },
  };

  return axios.get(`${window.apiSquad}/i/schools/${schoolId}/schools`, config).then(response => {
    return response.data;
  });
}
