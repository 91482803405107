import * as React from 'react';
import { Component } from 'react';
import * as Promise from 'bluebird';
import { History, Location } from 'history';
import { AppCustomer } from '../../../AppOrders';
import './CustomerDeliveryOptions.scss';
import { BASKET_DELIVERY_CHARGE_TYPE, DEFAULT_COUNTRY_OPTION_VALUE } from '../../../../../consts/order';
import {
  setDeliveryOptionToCustomerBasket,
  setDeliveryCountryToCustomerBasket,
  getCustomerBasket,
} from '../../../../../services/customer/basket';
import { checkCustomerSession } from '../../../../../services/customer/customer';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../../consts/common';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';
import * as Moment from 'moment';

interface Props {
  history: History;
  location: Location;
  customer: AppCustomer;
  onLogoutClick: () => void;
}

interface State {
  deadline: String;
  deliveryDate: String;
  isSchoolDeliveryInfoModalOpen: boolean;
  isHomeDeliveryInfoModalOpen: boolean;
}

export class CustomerDeliveryOptions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deadline: '',
      deliveryDate: '',
      isSchoolDeliveryInfoModalOpen: false,
      isHomeDeliveryInfoModalOpen: false,
    };
  }

  componentDidMount() {
    const { customer, onLogoutClick } = this.props;
    const { sessionKey } = customer;
    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    getCustomerBasket(customer).then(basket => {
      const { items } = basket;
      const deadline = this.getLatestDeadline(items);
      if (deadline !== '') {
        const deadlineFormatted = Moment(deadline).format('DD-MM-YYYY');

        const deliveryDate = Moment(new Date(deadline))
          .add(21, 'day')
          .format('DD-MM-YYYY');

        this.setState({
          deadline: deadlineFormatted,
          deliveryDate,
        });
      }
    });
  }

  getLatestDeadline = basketItems => {
    const deadlines = basketItems
      .map(item => item.schoolDeliveryDeadline)
      .filter(item => item !== '')
      .sort((date1, date2) => {
        return Date.parse(date1) - Date.parse(date2);
      });

    const isDeadlinesExist = deadlines.length > 0;
    const result = isDeadlinesExist ? deadlines[deadlines.length - 1] : '';
    return result;
  };

  setDeliveryOptionsToBasket = (deliveryCountry, deliveryOption) => {
    const { customer } = this.props;
    const addDeliveryCountryToBasketPromise = setDeliveryCountryToCustomerBasket(customer, deliveryCountry);
    const setDeliveryOptionPromise = setDeliveryOptionToCustomerBasket(customer, deliveryOption);
    const promises = [addDeliveryCountryToBasketPromise, setDeliveryOptionPromise];

    return Promise.all(promises).then(() => {
      return true;
    });
  };

  setShoolDelivery = () => {
    const { history } = this.props;
    this.setDeliveryOptionsToBasket(
      { deliveryCountry: '' },
      { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.FREE_SCHOOL }
    ).then(() => history.push('/customer/schoolDelivery'));
  };

  onHomeClick = () => {
    const { history } = this.props;
    this.setDeliveryOptionsToBasket(
      { deliveryCountry: DEFAULT_COUNTRY_OPTION_VALUE },
      { deliveryOption: '' }
    ).then(() => history.push('/customer/homeDelivery'));
  };

  openSchoolDeliveryInfoModal = () => {
    this.setState({
      isSchoolDeliveryInfoModalOpen: true,
    });
  };

  closeSchoolDeliveryInfoModal = () => {
    this.setState({
      isSchoolDeliveryInfoModalOpen: false,
    });
  };

  renderSchoolDeliveryInfoModal() {
    const { isSchoolDeliveryInfoModalOpen, deadline, deliveryDate } = this.state;
    return (
      <SimpleModal
        isOpen={isSchoolDeliveryInfoModalOpen}
        title={'For your information'}
        buttonCancelText={'cancel'}
        onCloseClick={this.closeSchoolDeliveryInfoModal}
        buttonText={'ok'}
        onButtonClick={this.setShoolDelivery}
        customClass={`bDeliveryModal`}
      >
        <p>
          {'You have selected delivery to school, which is free of charge. \n' +
            'Please be aware that all delivery to school orders are downloaded after'}{' '}
          <strong>{deadline}</strong>
          {'. And we expect to dispatch the whole order to your school by'} <strong>{deliveryDate}</strong>
          {'.\n'}
          {'If you require your order in advance please do not choose this option. ' +
            'Select home delivery instead. \n' +
            'Thank you.'}
        </p>
      </SimpleModal>
    );
  }

  openHomeDeliveryInfoModal = () => {
    this.setState({
      isHomeDeliveryInfoModalOpen: true,
    });
  };

  closeHomeDeliveryInfoModal = () => {
    this.setState({
      isHomeDeliveryInfoModalOpen: false,
    });
  };

  renderHomeDeliveryInfoModal() {
    const { isHomeDeliveryInfoModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isHomeDeliveryInfoModalOpen}
        title={'For your information'}
        buttonCancelText={'cancel'}
        onCloseClick={this.closeHomeDeliveryInfoModal}
        buttonText={'ok'}
        onButtonClick={this.onHomeClick}
        customClass={`bDeliveryModal`}
      >
        <p>As these are bespoke products delivery can take up to 28 days. Thank you.</p>
      </SimpleModal>
    );
  }

  render() {
    return (
      <>
        {this.renderSchoolDeliveryInfoModal()}
        {this.renderHomeDeliveryInfoModal()}
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={'bDeliveryOptions'}>
                <div className={'eDeliveryOptionsTitle'}>DELIVERY OPTIONS</div>
                <div className={'eDeliveryOptionsButtonWrapper'}>
                  <div className="row justify-content-center">
                    <div className="col-xl-4">
                      <div className="eDeliveryOptionsButton mColorBlue" onClick={this.openSchoolDeliveryInfoModal}>
                        FREE SCHOOL DELIVERY
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="eDeliveryOptionsButton mColorRed" onClick={this.openHomeDeliveryInfoModal}>
                        HOME DELIVERY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
