import axios from 'axios';
import { AppUser } from '../../views/App/App';

export function uploadImage(user: AppUser, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios
    .post(`${window.apiFile}/storage/images/upload`, formData, config)
    .then(response => {
      return response.data;
    });
}

export function createTicket(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/admin/tickets/create`, data, config)
    .then(response => {
      return response.data;
    });
}
