import * as React from 'react';
import { InfoBlockTerms } from '../LandingPage/Footer/InfoBlock/InfoBlockTerms';

export function TermsAndConditions() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <InfoBlockTerms />
        </div>
      </div>
    </div>
  );
}
