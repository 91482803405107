import * as React from 'react';
import StorageHelper from '../../../helpers/storage';
import { SchoolAppLoginForm2 } from './SchoolAppLoginForm2';
import { loginSchoolUser } from '../../../services/schoolUser/schools';

interface Props {
  history: any;
  onFormSubmit: (userId: string, key: string) => void;
}

interface State {
  email: string;
  password: string;
  isRememberMe: boolean;
  isError: boolean;
}

export class SchoolAppLogin2 extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isRememberMe: false,
      isError: false,
    };
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ email: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { email, password, isRememberMe } = this.state;
    const { onFormSubmit, history } = this.props;

    loginSchoolUser(email, password)
      .then(session => {
        const { userId, key } = session;

        if (isRememberMe) {
          // one year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('session', session, { expires: 365 });
        } else {
          StorageHelper.Cookie.set('session', session);
        }

        onFormSubmit(userId, key);
        history.push('/dashboard');
      })
      .catch(error => {
        this.setState({ isError: true });
      });
  };

  onRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked;
    this.setState({ isRememberMe: value });
  };

  render() {
    return (
      <SchoolAppLoginForm2
        email={this.state.email}
        password={this.state.password}
        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onFormSubmit={this.onFormSubmit}
        isRememberMe={this.state.isRememberMe}
        onRememberMeChange={this.onRememberMeChange}
        isError={this.state.isError}
        caption={'Schools'}
      />
    );
  }
}
