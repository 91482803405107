import axios from 'axios';

export function getPrivateLinkImages(link: string, filter?) {
  const config = {
    params: {
      filter,
    },
  };
  return axios.get(`${window.apiBase}/public/privateLink/${link}`, config).then(response => {
    return response.data;
  });
}

export function getPrivateLinkTicketsCount(link: string, filter?) {
  const config = {};

  return axios.get(`${window.apiBase}/public/privateLink/${link}/count`, config).then(response => {
    return response.data;
  });
}
