import {
  ColumnDefinition,
  convertToFilterWithPlus,
  getFilters,
  getOrder,
  getPagesCount,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from '../../../../../../helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from '../../../../../../consts/table';
import { AppUser } from '../../../../App';
import { History, Location } from 'history';
import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import * as Promise from 'bluebird';
import { Grid } from '../../../../../../components/Grid/Grid';
import { Loader } from '../../../../../../components/Loader/Loader';
import { UnsubscribedEmail } from '../../../../../../models/unsubscribedEmails';
import {
  addUnsubscribedEmail,
  deleteUnsubscribedEmail,
  getAllUnsubscribedEmails,
  getUnsubscribedEmails,
  getUnsubscribedEmailsCount,
  updateUnsubscribedEmail,
} from '../../../../../../services/superadmin/unsubscribedEmails';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { UnsubscribedEmailForm } from './UnsubscribedEmailForm/UnsubscribedEmailForm';
import * as propz from 'propz';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Email',
    field: 'email',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['email'],
  },
  {
    text: 'Created by',
    field: 'createdBy',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['createdBy'],
  },
  {
    text: 'Notes',
    field: 'notes',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['notes'],
  },
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: UnsubscribedEmail[];
  currentPage: number;
  selectedItems: UnsubscribedEmail[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isUnsubscribedEmailFormModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
}

export class UnsubscribedEmails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isUnsubscribedEmailFormModalOpen: false,
      isConfirmationModalOpen: false,
      isErrorModalOpen: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getUnsubscribedEmails(user, serverQueryFilter);
    const getItemsCountPromise = getUnsubscribedEmailsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllUnsubscribedEmails(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  renderGrid(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
    } = this.state;

    const actionItems = [
      {
        itemText: 'Create unsubscribed email',
        onItemClick: this.onCreateUnsubscribedEmailClick,
        isActive: selectedItems.length === 0,
      },
      {
        itemText: 'Edit unsubscribed email',
        onItemClick: this.onEditUnsubscribedEmailClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete unsubscribed email' : 'Delete unsubscribed emails',
        onItemClick: this.showConfirmationModal,
        isActive: selectedItems.length > 0,
      },
    ];

    const filterOptions = {};

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  renderFormModal(): React.ReactNode {
    const { isUnsubscribedEmailFormModalOpen, selectedItems } = this.state;

    if (selectedItems.length === 1) {
      const unsubscribedEmail = selectedItems[0];
      return (
        <SimpleModal isOpen={isUnsubscribedEmailFormModalOpen}>
          <UnsubscribedEmailForm
            unsubscribedEmail={unsubscribedEmail}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSubmitForm}
          />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isUnsubscribedEmailFormModalOpen}>
          <UnsubscribedEmailForm onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
        </SimpleModal>
      );
    }
  }

  onSubmitForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    if (selectedItems.length === 1) {
      const unsubscribedEmail = selectedItems[0];
      const { id } = unsubscribedEmail;

      updateUnsubscribedEmail(user, id, data).then(res => {
        this.setState({
          isUnsubscribedEmailFormModalOpen: false,
          selectedItems: [res],
          isSelectAllChecked: false,
        });
        this.setItems();
      });
    } else {
      addUnsubscribedEmail(user, data)
        .then(res => {
          const lastPage = getPagesCount(total + 1, LIMIT);
          this.setState({
            isUnsubscribedEmailFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setCurrentPageParams(lastPage);
          this.setItems();
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(errorText);
          this.setState({
            isLoading: false,
            isUnsubscribedEmailFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      isUnsubscribedEmailFormModalOpen: false,
    });
  };

  onCreateUnsubscribedEmailClick = () => {
    this.setState({
      isUnsubscribedEmailFormModalOpen: true,
    });
  };

  onEditUnsubscribedEmailClick = () => {
    this.setState({
      isUnsubscribedEmailFormModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? 'unsubscribed email' : 'unsubscribed emails'
        }?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteUnsubscribedEmailClick}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  onDeleteUnsubscribedEmailClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    Promise.all(selectedItems.map(selectedItem => deleteUnsubscribedEmail(user, selectedItem.id)))
      .then(res => {
        this.setState({
          selectedItems: [],
          isSelectAllChecked: false,
        });

        this.setItems();
      })
      .catch(error => {
        const errorTextInObject = propz.get(error, ['response', 'data', 'details', 'text', 'errmsg'], '');
        const errorText =
          typeof error.response.data.details.text === 'string' ? error.response.data.details.text : errorTextInObject;
        console.error(errorText);
        this.setState({
          isLoading: false,
          isConfirmationModalOpen: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
      });
  };

  render() {
    const { isLoading, isUnsubscribedEmailFormModalOpen, isConfirmationModalOpen, isErrorModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isUnsubscribedEmailFormModalOpen || isConfirmationModalOpen || isErrorModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderFormModal()}
        {this.renderConfirmationModal()}
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
