import * as Moment from 'moment';
import { YEAR_FILTER_WHERE_PARAM, LIMIT_DOWNLOAD_IMAGES } from '../consts/images';

export function getPhotoTypeYearFilter(selectedPhotoType: string, selectedPhotoYear: string) {
  let filter;

  const isSelectedPhotoType = typeof selectedPhotoType !== 'undefined';
  const isSelectedPhotoYear = typeof selectedPhotoYear !== 'undefined';

  switch (true) {
    case isSelectedPhotoType && isSelectedPhotoYear:
      filter = {
        order: 'job.jobDate DESC',
        where: {
          'photoType.name': selectedPhotoType,
          'job.jobDate': {
            $lte: YEAR_FILTER_WHERE_PARAM[selectedPhotoYear].END,
            $gte: YEAR_FILTER_WHERE_PARAM[selectedPhotoYear].START,
          },
        },
      };
      break;
    case isSelectedPhotoType && !isSelectedPhotoYear:
      filter = {
        order: 'job.jobDate DESC',
        where: {
          'photoType.name': selectedPhotoType,
        },
      };
      break;
    case !isSelectedPhotoType && isSelectedPhotoYear:
      filter = {
        order: 'job.jobDate DESC',
        where: {
          'job.jobDate': {
            $lte: YEAR_FILTER_WHERE_PARAM[selectedPhotoYear].END,
            $gte: YEAR_FILTER_WHERE_PARAM[selectedPhotoYear].START,
          },
        },
      };
      break;
    default:
      filter = {
        order: 'job.jobDate DESC',
      };
  }
  return filter;
}

export function getPhotoTypeDateFilter(date: string, selectedPhotoType: string) {
  const isSelectedPhotoType = typeof selectedPhotoType !== 'undefined';

  const dateformatted = Moment(date, 'DD-MM-YYYY', true)
    .add(1, 'day')
    .format();
  const dateformattedPrev = Moment(date, 'DD-MM-YYYY', true).format();

  let filter;

  if (isSelectedPhotoType) {
    filter = {
      where: {
        'photoType.name': selectedPhotoType,
        'job.jobDate': {
          $lte: new Date(dateformatted).toISOString(),
          $gte: new Date(dateformattedPrev).toISOString(),
        },
      },
    };
  } else {
    filter = {
      where: {
        'job.jobDate': {
          $lte: new Date(dateformatted).toISOString(),
          $gte: new Date(dateformattedPrev).toISOString(),
        },
      },
    };
  }
  return filter;
}

export function getPhotoTypeDateClassFilter(date: string, classname: string, selectedPhotoType: string, skip: number) {
  const isSelectedPhotoType = typeof selectedPhotoType !== 'undefined';

  const dateformatted = Moment(date, 'DD-MM-YYYY', true)
    .add(1, 'day')
    .format();
  const dateformattedPrev = Moment(date, 'DD-MM-YYYY', true).format();

  let filter;

  if (isSelectedPhotoType) {
    filter = {
      skip: skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
      where: {
        'job.jobDate': {
          $lte: new Date(dateformatted).toISOString(),
          $gte: new Date(dateformattedPrev).toISOString(),
        },
        'photoType.name': selectedPhotoType,
        'student.class': `${classname}`,
      },
      order: 'job.jobDate DESC',
    };
  } else {
    filter = {
      skip: skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
      where: {
        'job.jobDate': {
          $lte: new Date(dateformatted).toISOString(),
          $gte: new Date(dateformattedPrev).toISOString(),
        },
        'student.class': `${classname}`,
      },
      order: 'job.jobDate DESC',
    };
  }
  return filter;
}

export function getClassFilter(classname: string, skip?: number) {
  let filter;
  if (typeof skip === undefined) {
    filter = {
      where: {
        'student.class': `${classname}`,
      },
    };
  } else {
    filter = {
      skip: skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
      where: {
        'student.class': `${classname}`,
      },
    };
  }
  return filter;
}
