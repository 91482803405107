import axios from 'axios';
import { AppCustomer } from '../../views/AppOrders/AppOrders';

export function getCustomerSchoolForms(customer: AppCustomer, schoolId: string, filter) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/customer/schools/${schoolId}/forms`, config).then(response => {
    return response.data;
  });
}
