import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { History } from 'history';
import { loginOneTimeCode } from '../../../helpers/auth';
import { LoginOneTimeCodeForm } from './LoginOneTimeCodeForm';
import {
  NO_CONTENT_STATUS_CODE,
  SUCCESS_STATUS_CODE,
  GONE_STATUS_CODE,
  CREATED_STATUS_CODE,
  NOT_AUTHORIZED_STATUS_CODE,
} from '../../../consts/common';
import { WORLD_PAY_RESULT, PAY360_RESULT } from '../../../consts/payment';

import { ORDER_DELIVERY_TYPE, ORDER_STATUS } from '../../../consts/order';
import { LIMIT_DOWNLOAD_IMAGES } from '../../../consts/images';
import {
  getOneTimeCodeOrder,
  updateOneTimeCodeOrderStatusToCanceled,
  updateOneTimeCodeOrderStatusToPaid,
} from '../../../services/public/order';
import { getTicketsCount } from '../../../services/public/images';
import { getFromHistory } from '../../../helpers/history';
import { getOneTimeCodeToStorage } from '../../../helpers/sessionStorage';
import { addAccessCode } from '../../../services/customer/customer';
import { AppCustomer } from '../AppOrders';
import { Loader } from '../../../components/Loader/Loader';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { AdditionalCode } from '../../../models/additionalCode';
import { getPublicPrereg } from 'Src/services/public/prereg';
import { ConfirmationModal } from 'Src/views/AppOrders/CommonComponents/PhotoSlider/ConfirmationModal/ConfirmationModal';
import './LoginOneTimeCode.scss';

interface Props {
  history: History;
  onFormSubmit: (
    tickets,
    username: string,
    password: string,
    ticketsCount: number,
    basketId?: string,
    isAuthQuestionAsked?: boolean,
    additionalCodes?: AdditionalCode[]
  ) => void;
  setDelivery: (delivery: any) => void;
  isAuthorizedCustomer: boolean;
  isAuthorizedOneTimeCode: boolean;
  customer: AppCustomer;
  onLogoutClick: () => void;
  setAdditionalCodesToOneTimeCode: (userName, password) => void;
  oneTimeCode;
}

interface State {
  username: string;
  password: string;
  textError: string;
  isLoading: boolean;
  isSuccessModalOpen: boolean;
  isFallModalOpen: boolean;
  isWaitingModalOpen: boolean;
  infoMessage: string;
}

const initialFilter = {
  skip: 0,
  limit: LIMIT_DOWNLOAD_IMAGES,
};

export class LoginOneTimeCode extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      textError: '',
      isLoading: false,
      isSuccessModalOpen: false,
      isFallModalOpen: false,
      isWaitingModalOpen: false,
      infoMessage: '',
    };
  }

  getOrderPromise() {
    const { history } = this.props;

    const orderId = getFromHistory(history, 'orderId');
    const username = getFromHistory(history, 'username');
    const password = getFromHistory(history, 'password');
    const basketId = getFromHistory(history, 'basketId');
    const result = getFromHistory(history, 'result');

    switch (result) {
      case WORLD_PAY_RESULT.PAID:
        const data = {
          address1: getFromHistory(history, 'address1'),
          address2: getFromHistory(history, 'address2'),
          town: getFromHistory(history, 'town'),
          region: getFromHistory(history, 'region'),
          postcode: getFromHistory(history, 'postcode'),
          country: getFromHistory(history, 'country'),
          firstName: getFromHistory(history, 'firstName'),
          phone: getFromHistory(history, 'tel'),
          email: getFromHistory(history, 'email'),
        };
        return getOneTimeCodeOrder(username, password, orderId).then(order => {
          return order.orderStatus === ORDER_STATUS.PAID
            ? BPromise.resolve({})
            : updateOneTimeCodeOrderStatusToPaid(username, password, basketId, orderId, data);
        });
      case WORLD_PAY_RESULT.CANCELED:
        return getOneTimeCodeOrder(username, password, orderId).then(order => {
          return order.orderStatus === ORDER_STATUS.CANCELED
            ? BPromise.resolve({})
            : updateOneTimeCodeOrderStatusToCanceled(username, password, basketId, orderId);
        });
      default:
        console.error('Can not find result');
        return BPromise.resolve({});
    }
  }

  componentDidMount() {
    const { history, onFormSubmit, setDelivery } = this.props;
    const orderId = getFromHistory(history, 'orderId');
    const username = getFromHistory(history, 'username');
    const password = getFromHistory(history, 'password');
    const result = getFromHistory(history, 'result');
    const basketId = getFromHistory(history, 'basketId');

    const isOrderIdExist = typeof orderId !== 'undefined';
    const isUsernameExist = typeof username !== 'undefined';
    const isPasswordExist = typeof password !== 'undefined';
    const isResultExist = typeof result !== 'undefined';
    const isResultCanceled =
      result === WORLD_PAY_RESULT.CANCELED || result === PAY360_RESULT.CANCELED || result === PAY360_RESULT.TIMEOUT;

    //auto-login
    //used after pay360 hosted
    if (isResultExist && !isUsernameExist && !isPasswordExist) {
      const oneTimeCode = getOneTimeCodeToStorage();
      const usernameFromStorage = propz.get(oneTimeCode, ['username']);
      const passwordFromStorage = propz.get(oneTimeCode, ['password']);
      const basketIdFromStorage = propz.get(oneTimeCode, ['basketId']);
      const isAuthQuestionAsked = propz.get(oneTimeCode, ['isAuthQuestionAsked']);
      const additionalCodes = propz.get(oneTimeCode, ['additionalCodes']);

      const isUsernameFromStorageExist = typeof usernameFromStorage !== 'undefined';
      const isPasswordFromStorageExist = typeof passwordFromStorage !== 'undefined';

      if (isUsernameFromStorageExist && isPasswordFromStorageExist) {
        let data;

        loginOneTimeCode(usernameFromStorage, passwordFromStorage, initialFilter)
          .then(res => {
            data = res.data;
            return getTicketsCount({
              username: usernameFromStorage,
              password: passwordFromStorage,
            });
          })
          .then(({ count }) => {
            onFormSubmit(
              data,
              usernameFromStorage,
              passwordFromStorage,
              count,
              isResultCanceled ? basketIdFromStorage : undefined,
              isAuthQuestionAsked,
              additionalCodes
            );

            history.push({
              pathname: '/oneTimeCode',
              state: { result },
            });
          });
      } else {
        history.push({
          pathname: '/accessCode',
        });
      }
    }

    //auto-login with username and password, example: /accessCode?username=<...>&password=<...>
    //used after worldpay
    if (isUsernameExist && isPasswordExist && isOrderIdExist) {
      let data, status;
      this.getOrderPromise()
        .then(res => {
          if ((res as any).deliveryType === ORDER_DELIVERY_TYPE.HOME) {
            setDelivery({ home: (res as any).delivery });
          }

          if ((res as any).deliveryType === ORDER_DELIVERY_TYPE.SCHOOL) {
            const { delivery, schoolDelivery } = res as any;
            const { studentForm, studentName } = schoolDelivery;
            const { phone, email } = delivery;
            setDelivery({
              school: {
                phone,
                email,
                studentName,
                studentClassName: studentForm,
              },
            });
          }

          return loginOneTimeCode(username, password, initialFilter);
        })
        .then(res => {
          data = res.data;
          status = res.status;
          return getTicketsCount({
            username,
            password,
            skip: LIMIT_DOWNLOAD_IMAGES,
            basketId: undefined,
            ticketsCount: 0,
          });
        })
        .then(({ count }) => {
          switch (true) {
            case status === NO_CONTENT_STATUS_CODE:
              onFormSubmit([], username, password, count);
              history.push({
                pathname: '/oneTimeCode',
              });
              break;
            case status === SUCCESS_STATUS_CODE:
              const isAuthQuestionAsked = isOrderIdExist ? true : false;
              const { oneTimeCode } = this.props;
              const additionalCodes = isOrderIdExist ? oneTimeCode.additionalCodes : undefined;

              onFormSubmit(
                data,
                username,
                password,
                count,
                isResultCanceled ? basketId : undefined,
                isAuthQuestionAsked,
                additionalCodes
              );
              history.push({
                pathname: '/oneTimeCode',
                state: { result },
              });
              break;
          }
          return true;
        })
        .catch(error => {
          switch (true) {
            case error.response.status === GONE_STATUS_CODE:
              this.setState({ textError: 'Your credentials are not valid because you have used them' });
              break;
            default:
              this.setState({ textError: 'Access code cannot be found' });
          }
        });
    }

    //auto-login with username and password, example: /accessCode?username=<...>&password=<...>
    if (isUsernameExist && isPasswordExist && !isOrderIdExist) {
      this.setState(
        {
          username,
          password,
        },
        () => this.login()
      );
    }
  }

  onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ username: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.login();
  };

  login = (): void => {
    const { username, password } = this.state;
    const {
      history,
      isAuthorizedCustomer,
      onLogoutClick,
      isAuthorizedOneTimeCode,
      setAdditionalCodesToOneTimeCode,
    } = this.props;

    if (isAuthorizedCustomer) {
      const { customer } = this.props;

      addAccessCode(customer, username, password)
        .then(({ status }) => {
          if (status === CREATED_STATUS_CODE) {
            return getTicketsCount({ username, password });
          }
        })
        .then(countObj => {
          const { count } = countObj;

          if (count > 0) {
            this.setState({
              isLoading: false,
              isSuccessModalOpen: true,
            });
          } else {
            this.setState({
              isLoading: false,
              isWaitingModalOpen: true,
            });
          }
        })
        .catch(error => {
          const status = propz.get(error, ['response', 'status']);

          if (status === NOT_AUTHORIZED_STATUS_CODE) {
            onLogoutClick();
          } else {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            this.setState({
              isLoading: false,
              isFallModalOpen: true,
              infoMessage: errorText,
            });
          }
        });
    }

    if (isAuthorizedOneTimeCode) {
      loginOneTimeCode(username, password, initialFilter)
        .then(res => {
          const { oneTimeCode } = this.props;

          const isEqualWithOneTimeCode = username === oneTimeCode.username && password === oneTimeCode.password;
          if (isEqualWithOneTimeCode) {
            this.setState({ textError: 'This code has already been added to your account' });
          }

          const { additionalCodes } = oneTimeCode;
          const isAdditionalCodesExist = typeof additionalCodes !== 'undefined';

          if (!isEqualWithOneTimeCode && isAdditionalCodesExist) {
            const isEqualWithAdditionalCode = additionalCodes.some(
              code => code.username === username && code.password === password
            );

            if (isEqualWithAdditionalCode) {
              this.setState({ textError: 'This code has already been added to your account' });
            } else {
              setAdditionalCodesToOneTimeCode(username, password);
              history.push('/oneTimeCode');
            }
          }

          if (!isEqualWithOneTimeCode && !isAdditionalCodesExist) {
            setAdditionalCodesToOneTimeCode(username, password);
            history.push('/oneTimeCode');
          }
        })
        .catch(error => {
          switch (true) {
            case error.response.status === GONE_STATUS_CODE:
              this.setState({ textError: 'Your credentials are not valid because you have used them' });
              break;
            default:
              this.setState({ textError: 'Access code cannot be found' });
          }
        });
    }

    if (!isAuthorizedCustomer && !isAuthorizedOneTimeCode) {
      let data, status, promoteMovie;

      loginOneTimeCode(username, password, initialFilter)
        .then(res => {
          data = res.data;
          status = res.status;

          return status === NO_CONTENT_STATUS_CODE
            ? getPublicPrereg({
                username,
                password,
                skip: LIMIT_DOWNLOAD_IMAGES,
                basketId: undefined,
                ticketsCount: 0,
              })
            : BPromise.resolve(true);
        })
        .then(prereg => {
          if (status === NO_CONTENT_STATUS_CODE) {
            const { promoteMovie: _promoteMovie } = prereg;
            promoteMovie = _promoteMovie;
          }

          return getTicketsCount({
            username,
            password,
            skip: LIMIT_DOWNLOAD_IMAGES,
            basketId: undefined,
            ticketsCount: 0,
          });
        })
        .then(({ count }) => {
          switch (true) {
            case status === NO_CONTENT_STATUS_CODE:
              this.props.onFormSubmit([], username, password, count);
              history.push({
                pathname: '/oneTimeCode',
                state: { promoteMovie },
              });
              break;
            case status === SUCCESS_STATUS_CODE:
              this.props.onFormSubmit(data, username, password, count);
              history.push('/oneTimeCode');
              break;
          }
        })
        .catch(error => {
          switch (true) {
            case error.response.status === GONE_STATUS_CODE:
              this.setState({ textError: 'Your credentials are not valid because you have used them' });
              break;
            default:
              this.setState({ textError: 'Access code cannot be found' });
          }
        });
    }
  };

  onOkSuccessModalClick = () => {
    const { history, isAuthorizedOneTimeCode, isAuthorizedCustomer } = this.props;
    this.setState({
      isSuccessModalOpen: false,
    });
    if (isAuthorizedCustomer) {
      history.push('customer');
    } else {
      history.push('/');
    }
  };

  onOkFallModalClick = () => {
    this.setState({
      isFallModalOpen: false,
    });
  };

  onOkWaitingModalClick = () => {
    const { history } = this.props;

    this.setState({
      isWaitingModalOpen: false,
    });

    history.push('customer');
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isSuccessModalOpen}>
        <ConfirmationModal
          text={'The code has been added to your account. Your images are ready to view and order.'}
          onOk={this.onOkSuccessModalClick}
        />
      </SimpleModal>
    );
  }

  renderFallModal() {
    const { isFallModalOpen, infoMessage } = this.state;

    return (
      <SimpleModal isOpen={isFallModalOpen}>
        <ConfirmationModal text={infoMessage} onOk={this.onOkFallModalClick} />
      </SimpleModal>
    );
  }

  renderWaitingModal() {
    const { isWaitingModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isWaitingModalOpen}>
        <ConfirmationModal
          text={`The code has been added to your account, but the images haven't been uploaded yet. You will receive a notification as soon as  your photos are ready to view and order!`}
          onOk={this.onOkWaitingModalClick}
        />
      </SimpleModal>
    );
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {this.renderSuccessModal()}
        {this.renderFallModal()}
        {this.renderWaitingModal()}
        <LoginOneTimeCodeForm
          username={this.state.username}
          password={this.state.password}
          onUsernameChange={this.onUsernameChange}
          onPasswordChange={this.onPasswordChange}
          onFormSubmit={this.onFormSubmit}
          textError={this.state.textError}
        />
      </>
    );
  }
}
