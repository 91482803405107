import * as React from 'react';
import { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as propz from 'propz';
import { Profile } from '../../../../../../../models/profile';
import {getPhoneAndCode, getPhoneWithoutFirstZero} from '../../../../../../../helpers/phone';
import { checkAvailability } from '../../../../../../../services/public/register';
import { Button } from '../../../../../../../components/Button/Button';
import { getSelectCountryOptions } from '../../../../../../../helpers/select';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  profile: Profile;
}

export function ProfilePhoneChangeForm(props: Props) {
  const { onSubmit, onCancel, profile } = props;

  const phoneObj = getPhoneAndCode(profile);
  const isPhoneCodeExist =
    typeof phoneObj !== 'undefined' && typeof phoneObj.code !== 'undefined' && phoneObj.code !== '';

  const [phoneCode, setPhoneCode] = useState(isPhoneCodeExist ? phoneObj.code : '+44');

  const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Required')
      .test('phone', 'Duplicate phone or incorrect symbol', function(value) {
        const phoneRegExp = /^\d+$|^\d+#\d+$/;
        const phone = getPhoneWithoutFirstZero(value);
        const result = phoneRegExp.test(phone);
        const phoneForCheck = `${phoneCode}${phone}`;

        return result
          ? checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            })
          : false;
      }),
  });

  const phone = propz.get(phoneObj, ['phone'], '');

  return (
    <Formik
      initialValues={{
        phone: phone,
        phoneCode: phoneCode,
      }}
      validationSchema={PhoneSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form>
          <div className="form-group form-check">
            <div className="form-group">
              <label>Phone code</label>
              <Field
                name="phoneCode"
                component="select"
                className=""
                onChange={event => {
                  setFieldValue('phoneCode', event.target.value);
                  setPhoneCode(event.target.value);
                }}
              >
                {getSelectCountryOptions()}
              </Field>
              <div className="form-group">
                <label>Phone</label>
                <Field name="phone" placeholder="Phone" className={'form-control mb-3'} />
                <ErrorMessage component="div" className="alert alert-danger" name="phone" />
              </div>
            </div>

            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
