import * as React from 'react';
import { Link } from 'react-router-dom';
import './CartIcon.scss';

interface Props {
  basketCount: number;
  isAuthorizedCustomer: boolean;
  isAuthorizedOneTimeCode: boolean;
}

export function CartIcon(props: Props) {
  const { basketCount, isAuthorizedCustomer, isAuthorizedOneTimeCode } = props;
  let pathname;

  if (isAuthorizedOneTimeCode) {
    pathname = '/basket';
  }

  if (isAuthorizedCustomer) {
    pathname = '/customer/basket';
  }

  const isBasketEmpty = basketCount === 0;

  return !isBasketEmpty ? (
    <Link to={{ pathname: pathname }}>
      <div className={'eCartWrapper'}>
        <div className={'eCartCount'}>{basketCount}</div>
        <img className="" src="/dist/images/icon/icon3.png" alt={'cart_icon'} />
      </div>
    </Link>
  ) : (
    <div className={'eCartWrapper'}>
      <div className={'eCartCount'}>{basketCount}</div>
      <img className="" src="/dist/images/icon/icon3.png" alt={'cart_icon'} />
    </div>
  );
}
