export const BROWSER_HEIGHT = Math.max(
  document.body.scrollHeight,
  document.documentElement.scrollHeight,
  document.body.offsetHeight,
  document.documentElement.offsetHeight,
  document.body.clientHeight,
  document.documentElement.clientHeight
);

const HEADER_HEIGHT = 52.75;
const DEFAULT_MARGIN_TOP = 10;
const RESIZE_PANEL_HEIGHT = 40;

export const CONTENT_HEIGHT =
  BROWSER_HEIGHT - HEADER_HEIGHT - DEFAULT_MARGIN_TOP - RESIZE_PANEL_HEIGHT;
