import * as React from 'react';
import './App.scss';
import { Component } from 'react';
import * as Promise from 'bluebird';
import { clearSession, getSession } from '../../helpers/cookies';
import { Loader } from '../../components/Loader/Loader';
import { Redirect, Route, Switch } from 'react-router';
import { Login } from './Login/Login';
import { GenericView } from './GenericView/GenericView';
import { logout } from '../../helpers/auth';

import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faQuoteRight,
  faLink,
  faListUl,
  faListOl,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faCode,
  faUnderline,
  faItalic,
  faBold,
  faSyncAlt,
  faEllipsisH,
  faCaretDown,
  faCaretUp,
  faPlusCircle,
  faMinusCircle,
  faClock,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf as farFilePdf } from '@fortawesome/free-regular-svg-icons';
import { checkAdminSession, getUser } from '../../services/superadmin/users';
import { isUserTypeAdmin } from '../../helpers/user';
import { USER_TYPE } from '../../consts/user';
import { getConfig } from '../../services/superadmin/config';
import { AdminConfig } from '../../models/config';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../consts/common';
import UnsubscribedForm from './UnsubscribedForm/UnsubscribedForm';

library.add(
  faAngleDoubleLeft as IconDefinition,
  faAngleDoubleRight as IconDefinition,
  faAngleLeft as IconDefinition,
  faAngleRight as IconDefinition,
  faSort as IconDefinition,
  faSortUp as IconDefinition,
  faSortDown as IconDefinition,
  faFilter as IconDefinition,
  faQuoteRight as IconDefinition,
  faCode as IconDefinition,
  faBold as IconDefinition,
  faItalic as IconDefinition,
  faUnderline as IconDefinition,
  faLink as IconDefinition,
  faListOl as IconDefinition,
  faListUl as IconDefinition,
  faAlignLeft as IconDefinition,
  faAlignRight as IconDefinition,
  faAlignCenter as IconDefinition,
  faSyncAlt as IconDefinition,
  faEllipsisH as IconDefinition,
  faCaretDown as IconDefinition,
  faCaretUp as IconDefinition,
  faPlusCircle as IconDefinition,
  faMinusCircle as IconDefinition,
  faClock as IconDefinition,
  faPlus as IconDefinition,
  faMinus as IconDefinition,
  faChevronDown as IconDefinition,
  faChevronUp as IconDefinition,
  faChevronRight as IconDefinition,
  faChevronLeft as IconDefinition,
  faFileCsv as IconDefinition,
  farFilePdf as IconDefinition
);

interface State {
  user: AppUser;
  menuItemsEnabled: MenuItemsEnabled;
  isLoading: boolean;
}

export interface MenuItemsEnabled {
  [prop: string]: boolean;
}

export interface AppUser {
  authorized: boolean;
  sessionKey: string;
  type: string;
  userId: string;
  config?: AdminConfig;
}

export class App extends Component<{}, State> {
  constructor(props) {
    super(props);

    const session = getSession();
    const isSession = typeof session !== 'undefined';

    this.state = {
      user: {
        sessionKey: isSession ? session.id || session.key : undefined,
        authorized: isSession,
        type: isSession ? session.type : undefined,
        userId: isSession ? session.userId : undefined,
        config: undefined,
      },
      menuItemsEnabled: undefined,
      isLoading: true,
    };
  }

  componentDidMount() {
    const user = this.state.user;
    const { sessionKey, userId } = user;

    this.setState({
      isLoading: true,
    });

    checkAdminSession(sessionKey)
      .then(status => {
        if (status === NOT_AUTHORIZED_STATUS_CODE) {
          this.onLogoutClick();
        }
        return true;
      })
      .then(() => {
        switch (true) {
          case typeof sessionKey !== 'undefined' && isUserTypeAdmin(user):
            const getConfigPromise = getConfig(user);
            const getUserPromise = getUser(sessionKey, userId);
            const promises = [getConfigPromise, getUserPromise];

            Promise.all(promises)
              .then(([config, userData]) => {
                this.setState({
                  isLoading: false,
                  menuItemsEnabled: userData.menuItemsEnabled,
                  user: { ...user, config },
                });
              })
              .catch(err => {
                this.setState({
                  isLoading: false,
                });
              });
            break;
          case typeof sessionKey !== 'undefined' && !isUserTypeAdmin(user):
            getConfig(user)
              .then(config => {
                this.setState({
                  isLoading: false,
                  user: { ...user, config },
                });
              })
              .catch(err => {
                this.setState({
                  isLoading: false,
                });
              });
            break;
          default:
            this.setState({
              isLoading: false,
            });
            break;
        }
      });
  }

  onLoginClick = (key: string, type: string, userId: string): void => {
    const user = {
      authorized: true,
      sessionKey: key,
      type: type,
      userId: userId,
    };

    switch (type) {
      case USER_TYPE.ADMIN:
        this.setState({
          isLoading: true,
        });

        const getConfigPromise = getConfig(user);
        const getUserPromise = getUser(key, userId);
        const promises = [getConfigPromise, getUserPromise];

        Promise.all(promises).then(([config, userData]) => {
          this.setState({
            isLoading: false,
            user: { ...user, config },
            menuItemsEnabled: userData.menuItemsEnabled,
          });
        });
        break;
      default:
        this.setState({
          isLoading: true,
        });

        getConfig(user).then(config => {
          this.setState({
            isLoading: false,
            user: { ...user, config },
          });
        });
        break;
    }
  };

  setDefaultState = (): void => {
    clearSession();

    this.setState({
      user: {
        sessionKey: undefined,
        authorized: false,
        type: undefined,
        userId: undefined,
      },
      menuItemsEnabled: undefined,
    });
  };

  onLogoutClick = (): void => {
    const { user } = this.state;
    const { sessionKey } = user;
    logout(sessionKey).then(
      () => {
        this.setDefaultState();
      },
      () => {
        this.setDefaultState();
      }
    );
  };

  render() {
    const { isLoading, user, menuItemsEnabled } = this.state;
    const { authorized } = user;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <Switch>
        <Route exact path="/unsubscribe" component={props => <UnsubscribedForm {...props} />} />
        <Route exact path="/login" component={props => <Login onFormSubmit={this.onLoginClick} {...props} />} />
        <Route
          path="/"
          render={props =>
            authorized ? (
              <GenericView
                user={user}
                menuItemsEnabled={menuItemsEnabled}
                onLogoutClick={this.onLogoutClick}
                {...props}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      </Switch>
    );
  }
}
