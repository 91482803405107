import * as React from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { isDev, isLocal } from '../../../helpers/domain';
import { PAY360_RESULT } from '../../../consts/payment';
import { useEffect } from 'react';

const MobilePaymentCancelHosted = () => {
  const subdomain = document.location.hostname.replace('payment', 'orders');
  const protocol = document.location.protocol;
  const port = document.location.port;

  useEffect(() => {
    window.location.assign(
      isDev(document.location.hostname) || isLocal(document.location.hostname)
        ? `${protocol}//${subdomain}:${port}/mobile/result?result=${PAY360_RESULT.CANCELED}`
        : `${protocol}//${subdomain}/mobile/result?result=${PAY360_RESULT.CANCELED}`
    );
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default MobilePaymentCancelHosted;
