import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT, SORT } from '../../consts/table';
import { AppCustomer } from '../../views/AppOrders/AppOrders';

export function getCustomers(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/customers`, config).then(response => {
    return response.data;
  });
}

export function getAllCustomers(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers`, config).then(response => {
    return response.data;
  });
}

export function getCustomersCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/count`, config).then(response => {
    return response.data;
  });
}

export function deleteCustomer(user: AppUser, customerId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/customers/${customerId}`, config).then(response => {
    return response.data;
  });
}

export function addCustomer(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/customers`, data, config).then(response => {
    return response.data;
  });
}

export function updateCustomer(user: AppUser, customerId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/customers/${customerId}`, data, config).then(response => {
    return response.data;
  });
}

export function getCustomer(user: AppUser, customerId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/customers/${customerId}`, config).then(response => {
    return response.data;
  });
}

export function createCustomerNotificationChannel(user: AppUser, customerId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/admin/customers/${customerId}/notifications/channels`, data, config)
    .then(response => {
      return response.data;
    });
}

export function sendCustomerNotification(user: AppUser, customerId: string, channelId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(
      `${window.apiBase}/admin/customers/${customerId}/notifications/channels/${channelId}/sendNotification`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function deleteNotificationChannel(user: AppUser, channelId: string, customerId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/customers/${customerId}/notifications/channels/${channelId}`, config)
    .then(response => {
      return response.data;
    });
}

export function deleteCustomerProfileNotificationChannel(customer: AppCustomer, channelId: string) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/customer/${customerId}/notifications/channels/${channelId}`, config)
    .then(response => {
      return response.data;
    });
}

export function createCustomerProfileNotificationChannel(customer: AppCustomer, data) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/${customerId}/notifications/channels`, data, config).then(response => {
    return response.data;
  });
}

export function getCustomerWithStudents(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where,
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || `updatedAt ${SORT.DESC}`,
      },
    },
  };

  return axios.get(`${window.apiBase}/admin/customers/students`, config).then(response => {
    return response.data;
  });
}

export function getAllCustomerWithStudents(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
          order: `updatedAt ${SORT.DESC}`,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
          order: `updatedAt ${SORT.DESC}`,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/students`, config).then(response => {
    return response.data;
  });
}

export function getCustomerWithStudentsCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
          order: `updatedAt ${SORT.DESC}`,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/students/count`, config).then(response => {
    return response.data;
  });
}
