import * as React from 'react';
import { PHOTO_TYPES_FILTER, IMAGE_TYPE, YEAR_FILTER } from '../../../../../consts/images';
import './FilterIcons.scss';

interface Props {
  onPhotoTypeClick: (photoType: string) => void;
  onYearClick: (year: string) => void;
}

export function FilterIcons(props: Props) {
  const { onPhotoTypeClick, onYearClick } = props;

  const getYearLabel = year => {
    if (year === 'All') {
      return 'All';
    }
    return year.substring(2, 4);
  };

  const onYearFilterClick = year => {
    console.log('year', year);

    if (year === 'All') {
      console.log('+++++++++++++++++');
      onYearClick(undefined);
    } else {
      onYearClick(year);
    }
  };

  return (
    <div className={'eCustomerFilterIcons'}>
      <div
        className="eCustomerFilterIconWrapper"
        key={'eCustomerFilterIcons_productTypes_all'}
        onClick={() => onPhotoTypeClick(undefined)}
      >
        <div className="eCustomerFilterIcon mAllIcon">All</div>
        <div className={'eCustomerFilterText'}>{PHOTO_TYPES_FILTER.ALL}</div>
      </div>

      <div
        className="eCustomerFilterIconWrapper"
        key={'eCustomerFilterIcons_productTypes_individual'}
        onClick={() => onPhotoTypeClick(IMAGE_TYPE.INDIVIDUAL)}
      >
        <div className="eCustomerFilterIcon mIndividualsIcon">I</div>
        <div className={'eCustomerFilterText'}>{PHOTO_TYPES_FILTER.INDIVIDUALS}</div>
      </div>

      <div
        className="eCustomerFilterIconWrapper"
        key={'eCustomerFilterIcons_productTypes_group'}
        onClick={() => onPhotoTypeClick(IMAGE_TYPE.GROUP)}
      >
        <div className="eCustomerFilterIcon mGroupsIcon">G</div>
        <div className={'eCustomerFilterText'}>{PHOTO_TYPES_FILTER.GROUPS}</div>
      </div>

      <div
        className="eCustomerFilterIconWrapper"
        key={'eCustomerFilterIcons_productTypes_panoramic'}
        onClick={() => onPhotoTypeClick(IMAGE_TYPE.PANORAMIC)}
      >
        <div className="eCustomerFilterIcon mPanoramicIcon">P</div>
        <div className={'eCustomerFilterText'}>{PHOTO_TYPES_FILTER.PANORAMICS}</div>
      </div>

      <div className="eCustomerYearFilterIcons">
        {YEAR_FILTER.map(year => {
          return (
            <div
              className="eCustomerFilterIconWrapper"
              key={`customerYearsFilterText_${year}`}
              onClick={() => onYearFilterClick(year)}
            >
              <div className={`eCustomerFilterIcon m${year}Icon`}>{getYearLabel(year)}</div>
              <div className={'eCustomerFilterText'}>{year}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
