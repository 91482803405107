import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../../../components/Button/Button';

interface Props {
  onSubmitConfirmStudentBirthdayFormClick: (data) => void;
  onCancel: () => void;
}

export function CustomerConfirmStudentBirthdayForm(props: Props) {
  const { onSubmitConfirmStudentBirthdayFormClick, onCancel } = props;

  const selectDayOptions = () => {
    let selectOptions = [];
    for (let i = 1; i <= 31; i++) {
      selectOptions.push(
        <option value={i} key={i}>
          {`${i}`}
        </option>
      );
    }
    return selectOptions;
  };

  const ConfirmStudentBirthdaySchema = Yup.object().shape({
    dayOfBorn: Yup.number().required('Required'),
  });

  const studentBirthdayForm = {
    dayOfBorn: '',
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={studentBirthdayForm}
            validationSchema={ConfirmStudentBirthdaySchema}
            onSubmit={onSubmitConfirmStudentBirthdayFormClick}
          >
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group">
                  <div className="form-group row">
                    <div className="col-auto mt-2">
                      <label className="">Please confirm the day the student was born on</label>
                    </div>
                    <Field name="dayOfBorn" component={'select'} className="form-control mb-3 col-2">
                      <option key="Empty_dayOfBorn" value=""></option>
                      {selectDayOptions()}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger text-center w-100" name="dayOfBorn" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Ок'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
