import * as React from 'react';
import * as propz from 'propz';
import { Profile } from 'Src/models/profile';

interface Props {
  profile: Profile;
  onDeleteStudentClick: (studentId: string) => void;
  onAddStudentClick: () => void;
  onShowImagesClick: () => void;
  onOpenExplanationModalClick: (isConfirmed) => void;
}

export function CustomerStudentsTab(props: Props) {
  const { profile, onDeleteStudentClick, onAddStudentClick, onShowImagesClick, onOpenExplanationModalClick } = props;
  const { students } = profile;

  const rows = students.map((student, index) => {
    const studentId = propz.get(student, ['student', 'studentId']);
    const parentCode = propz.get(student, ['school', 'parentCode'], '');
    const isConfirmed = typeof studentId !== 'undefined';
    const forename = isConfirmed
      ? propz.get(student, ['student', 'forename'], '')
      : propz.get(student, ['forename'], '');
    const surname = isConfirmed ? propz.get(student, ['student', 'surname'], '') : propz.get(student, ['surname'], '');
    const schoolName = propz.get(student, ['school', 'schoolName'], '');
    const schoolData = parentCode !== '' ? `${schoolName} (${parentCode})` : schoolName;
    const formName = propz.get(student, ['form', 'formName'], '');

    return (
      <tr key={`students_summary_${index}`}>
        <td>{forename}</td>
        <td>{surname}</td>
        <td>{schoolData}</td>
        <td>{formName}</td>
        <td>{isConfirmed ? 'Verified' : 'Pending'}</td>
        <td>
          <div className="" onClick={() => onOpenExplanationModalClick(isConfirmed)}>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-question-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              />
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
          </div>
        </td>
        <td>
          <button
            onClick={() => {
              onDeleteStudentClick(student.id);
            }}
            className={'eCustomerStudentsTabTableButton'}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'students_empty_row'}>
      <td colSpan={5}>No students</td>
    </tr>
  );

  return (
    <div className={'bCustomerStudentsTab'}>
      <table className={'eCustomerStudentsTabTable table table-sm'}>
        <thead>
          <tr>
            <th>Forename</th>
            <th>Surname</th>
            <th>School (Unique Code)</th>
            <th>Form</th>
            <th>Confirmation</th>
            <td />
            <th />
          </tr>
        </thead>
        <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
      </table>
      <button onClick={onAddStudentClick} className={'eCustomerAddStudentButton'}>
        Add
      </button>
      <button onClick={onShowImagesClick} className={'eCustomerShowImagesButton'}>
        Show images
      </button>
    </div>
  );
}
