import * as React from 'react';
import { FunctionComponent } from 'react';

import './CustomNotificationPreview.css';
import { CustomNotificationPreviewEmail } from './CustomNotificationPreviewEmail';
import { CustomNotificationPreviewPush } from './CustomNotificationPreviewPush';
import { CustomNotificationPreviewSms } from './CustomNotificationPreviewSms';
import { CustomNotificationPreviewTabs } from './CustomNotificationPreviewTabs';

interface Props {
  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;
  messageAttachments: any[];
}

export const GeneralMessagePreviewWithTabs: FunctionComponent<Props> = props => {
  return (
    <div className="container-fluid">
      <div className="bGeneralMessagePreview row">
        <div className="col-md-6">
          <CustomNotificationPreviewTabs tabs={['Email preview', 'Push preview', 'Sms preview']}>
            {/*email preview*/}
            <CustomNotificationPreviewEmail
              messageEmailSubject={props.messageEmailSubject}
              messageEmailBody={props.messageEmailBody}
              messageAttachments={props.messageAttachments}
            />

            {/*push preview*/}
            <CustomNotificationPreviewPush
              messagePushTitle={props.messagePushTitle}
              messagePushBody={props.messagePushBody}
            />

            {/*text preview*/}
            <CustomNotificationPreviewSms messageSmsText={props.messageSmsText} />
          </CustomNotificationPreviewTabs>
        </div>
        <div className="col-md-6 overflow-auto" />
      </div>
    </div>
  );
};

GeneralMessagePreviewWithTabs.displayName = 'GeneralMessagePreviewWithTabs';
