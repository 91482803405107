import axios from 'axios';
import { AppOneTimeCode } from '../../views/AppOrders/AppOrders';

export function getPublicVoucher(oneTimeCode: AppOneTimeCode, voucherCode: string) {
  const { username, password, basketId } = oneTimeCode;

  const config = {};

  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/vouchers`, { voucherCode }, config)
    .then(response => {
      return response.data;
    });
}
