import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as propz from 'propz';
import { Button } from '../../../../../../../../components/Button/Button';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  orderNote?: any;
}

const OrderNoteSchema = Yup.object().shape({
  text: Yup.string().required('Required'),
});

export function OrderNoteForm(props: Props) {
  const { onSubmit, onCancel, orderNote } = props;
  const isOrderNoteExist = typeof orderNote !== 'undefined';

  const orderNoteForm = {
    text: isOrderNoteExist ? propz.get(orderNote, ['text'], '') : '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={orderNoteForm} validationSchema={OrderNoteSchema} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Text</label>
                    <Field component={'textarea'} rows="4" name="text" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="text" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
