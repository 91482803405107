export const SCHOOL_JOB_TABS = ['Summary'];

export const SCHOOL_JOB_IMAGES_TABS = ['Summary'];

export const SCHOOL_JOB_STATUS = {
  NEW: 'NEW',
  APPROVED_BY_SCHOOL: 'APPROVED_BY_SCHOOL',
};

export const SCHOOL_JOB_STATUS_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'New',
  APPROVED_BY_SCHOOL: 'Approved by school',
};
