import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import MaskedInput from 'react-text-mask';
import { Autocomplete } from '../../../../../../../../components/Autocomplete/Autocomplete';
import { getName, searchFunctionForms } from '../../../../../../../../helpers/autocomplete';
import { School } from '../../../../../../../../models/schools';
import { AppUser } from '../../../../../../App';
import DatePicker from 'react-datepicker';
import { Switch } from '../../../../../../../../components/Switch/Switch';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  user: AppUser;
  student?: any;
  schoolItem: School;
}

const SchoolFormSchema = Yup.object().shape({
  forename: Yup.string().required('Required'),
  surname: Yup.string().required('Required'),
  formId: Yup.string().required('Required'),
});

export function SchoolStudentsForm(props: Props) {
  const { student, onSubmit, onCancel } = props;

  const getForms = text => {
    const { user, schoolItem } = props;
    return searchFunctionForms(user, schoolItem.id, text);
  };

  const getBirthdayDate = () => {
    const birthdayDate = propz.get(student, ['birthday'], undefined);
    const isBirthdayDateExist = typeof birthdayDate !== 'undefined';
    return isBirthdayDateExist ? new Date(birthdayDate) : undefined;
  };

  const formSchoolStudents = {
    forename: propz.get(student, ['forename'], ''),
    surname: propz.get(student, ['surname'], ''),
    birthday: getBirthdayDate(),
    formId: propz.get(student, ['form', 'formId'], undefined),
    form: {
      id: propz.get(student, ['form', 'formId'], ''),
      name: propz.get(student, ['form', 'formName'], ''),
    },
    adminNo: propz.get(student, ['adminNo'], ''),
    isAutoAcceptAvailable: propz.get(student, ['isAutoAcceptAvailable'], true),
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={formSchoolStudents} validationSchema={SchoolFormSchema} onSubmit={onSubmit}>
            {({ setFieldValue, values, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Name</label>
                    <Field name="forename" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="forename" />
                  </div>
                  <div className="form-group">
                    <label>Surname</label>
                    <Field name="surname" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="surname" />
                  </div>

                  <div className="form-group">
                    <label>Date of birth</label>
                    <Field name="birthday">
                      {({ field }) => (
                        <div className="mb-3">
                          <DatePicker
                            selected={field.value}
                            onChange={birthday => {
                              setFieldValue('birthday', birthday);
                            }}
                            className="form-control"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat={'dd-MM-yyyy'}
                            customInput={
                              <MaskedInput
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="DD-MM-YYYY"
                              />
                            }
                          />
                        </div>
                      )}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="birthday" />
                  </div>

                  <div className="form-group">
                    <label>Form</label>
                    <Field
                      name="form"
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={getForms}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.form}
                            onSelect={form => {
                              setFieldValue('formId', form.id);
                              setFieldValue('form', form);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <Field name="isAutoAcceptAvailable">
                      {({ field }) => (
                        <Switch
                          {...field}
                          value={!values.isAutoAcceptAvailable}
                          text="No auto accept"
                          customClass="ml-3"
                          onChange={() => {
                            setFieldValue('isAutoAcceptAvailable', !values.isAutoAcceptAvailable);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label>AdminNo</label>
                    <Field name="adminNo" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="adminNo" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
