import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT } from '../../consts/table';

export function getSchoolJobImages(user: AppUser, schoolJobId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/schoolJobs/${schoolJobId}/schoolImages`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolJobImages(user: AppUser, schoolJobId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/schoolJobs/${schoolJobId}/schoolImages`, config).then(response => {
    return response.data;
  });
}

export function getSchoolJobImagesCount(user: AppUser, schoolJobId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/schoolJobs/${schoolJobId}/schoolImages/count`, config).then(response => {
    return response.data;
  });
}

export function updateSchoolJobImage(user: AppUser, schoolJobId: string, schoolImageId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/admin/schoolJobs/${schoolJobId}/schoolImages/${schoolImageId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolJobImage(user: AppUser, schoolJobId: string, schoolImageId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/schoolJobs/${schoolJobId}/schoolImages/${schoolImageId}`, config)
    .then(response => {
      return response.data;
    });
}
