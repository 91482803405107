import * as React from 'react';

export function InfoBlockAboutUs() {
  return (
    <div className="pt-5">
      <div className="bFAQsSubTitle">about bentley photographic</div>
      <div className="bInfoBlockText">
        We are Bentley Photographic, a small family run company based near Colchester in Essex... but please don't hold
        that against us! Our Photographers travel far and wide taking all types of school photographs - portraits,
        groups, whole school panoramics and more. Retouching, printing, mounting and framing are all done in-house,
        keeping costs and quality under control.
      </div>
    </div>
  );
}
