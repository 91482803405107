import * as React from 'react';
import { Component } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { getImageProductTypes } from '../../../../helpers/images';
import { ProductType } from '../../../../models/productTypes';
import './ProductTypeFilter.scss';

interface Props {
  selectedImageId: string;
  selectedProductTypeId: string;
  onClick: (productTypeId: string) => void;
}

interface State {
  productTypes: ProductType[];
  isLoading: boolean;
}

export class ProductTypeFilter extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      productTypes: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { selectedImageId } = this.props;

    if (selectedImageId) {
      getImageProductTypes(selectedImageId).then(res => {
        this.setState({
          productTypes: res,
          isLoading: false,
        });
      });
    }
  }

  render() {
    const { productTypes, isLoading } = this.state;
    const { onClick, selectedProductTypeId } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className={'bCustomerProductTypeFilter'}>
        <div className={'eCustomerProductTypeFilterTitle'}>Products</div>
        <div className="eCustomerProductTypeFilterTextWrapper">
          <div
            className={
              typeof selectedProductTypeId === 'undefined'
                ? 'eCustomerProductTypeFilterText eTextSelected'
                : 'eCustomerProductTypeFilterText'
            }
            key={'productTypes_all'}
            onClick={() => onClick(undefined)}
          >
            All
          </div>
          {productTypes.map(productType => (
            <div
              className={
                selectedProductTypeId === productType.id
                  ? 'eCustomerProductTypeFilterText eTextSelected'
                  : 'eCustomerProductTypeFilterText'
              }
              key={`productTypes_${productType.id}`}
              onClick={() => onClick(productType.id)}
            >
              {productType.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
