import { ALL_LIMIT } from '../../consts/table';
import axios from 'axios';
import { AppSchoolsUser2 } from '../../views/AppSchools2/AppSchools2';

export function getSchoolUserAllPhotoTypes(user: AppSchoolsUser2, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/schoolUser/photoTypes`, config).then(response => {
    return response.data;
  });
}
