import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { parse } from 'query-string';
import { History, Location } from 'history';
import { PAYMENT_CHANNEL, PAY360_RESULT } from 'Src/consts/payment';
import { getCallback, getMobileCallbackY, getMobileCallbackC } from '../../../../helpers/worldpay';
import { getConfig } from '../../../../services/public/config';
import { payment360 } from '../../../../services/public/payment360';
import { PublicConfig } from '../../../../models/config';
import { CURRENCY_SYMBOL } from '../../../../consts/common';
import { setMobilePlatformToStorage } from '../../../../helpers/sessionStorage';
import StorageHelper from '../../../../helpers/storage';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { Loader } from 'Src/components/Loader/Loader';
import './MobileCheckout.scss';

interface Props {
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  orderNumber: string;
  orderAmount: string;
  address1: string;
  town: string;
  region: string;
  postcode: string;
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  orderId: string;
  basketId: string;
  customerId: string;

  config: PublicConfig;
  iFrameSrc: string | undefined;
  sessionId: string | undefined;
  isPaymentUnavailable: boolean;
}

export class MobileCheckout extends Component<Props, State> {
  formRef: any;
  iframeRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      orderNumber: '',
      orderAmount: '',
      address1: '',
      town: '',
      region: '',
      postcode: '',
      country: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      orderId: '',
      basketId: '',
      customerId: '',

      config: undefined,
      iFrameSrc: undefined,
      sessionId: undefined,
      isPaymentUnavailable: false,
    };

    this.formRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    const search = parse(history.location.search) as any;
    const {
      platform,
      orderNumber,
      orderAmount,
      address1,
      town,
      region,
      postcode,
      country,
      firstName,
      lastName,
      email,
      phone,
      orderId,
      basketId,
      customerId,
      sessionKey,
    } = search;

    setMobilePlatformToStorage(platform);

    const session = { key: sessionKey, userId: customerId };
    StorageHelper.Cookie.set('session', session, { expires: 365 });

    window.addEventListener('message', this.payment);

    getConfig().then(config => {
      this.setState({
        orderNumber,
        orderAmount,
        address1,
        town,
        region,
        postcode,
        country,
        firstName,
        lastName,
        email,
        phone,
        orderId,
        basketId,
        customerId,
        config,
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.payment);
  }

  payment = event => {
    const { data } = event;

    const isDataPaid = data === PAY360_RESULT.PAID;
    const isDataCanceled = data === PAY360_RESULT.CANCELED;
    const isDataTimeout = data === PAY360_RESULT.TIMEOUT;

    if (isDataPaid || isDataCanceled || isDataTimeout) {
      this.props.history.push(`/mobile/result?result=${data}`);
    }
  };

  renderHiddenForm() {
    const {
      orderNumber,
      orderAmount,
      address1,
      town,
      region,
      postcode,
      country,
      firstName,
      lastName,
      email,
      phone,
      orderId,
      basketId,
      customerId,
      config,
    } = this.state;

    const { payments } = config;
    const { worldpay } = payments;
    const { formActionUrl, testModeValue, instId, lang, accId1, currency } = worldpay;

    return (
      <form action={formActionUrl} method="POST" ref={this.formRef}>
        <input type="hidden" name="testMode" value={testModeValue} />
        <input type="hidden" name="instId" value={instId} />
        <input type="hidden" name="cartId" value={orderNumber} />
        <input type="hidden" name="amount" value={orderAmount} />
        <input type="hidden" name="currency" value={currency} />

        <input type="hidden" name="address1" value={address1} />
        <input type="hidden" name="town" value={town} />
        <input type="hidden" name="region" value={region} />
        <input type="hidden" name="postcode" value={postcode} />
        <input type="hidden" name="country" value={country} />
        <input type="hidden" name="authMode" value="A" />
        <input type="hidden" name="authValidFrom" value="" />
        <input type="hidden" name="authValidTo" value="" />
        <input type="hidden" name="name" value={`${firstName} ${lastName}`} />
        <input type="hidden" name="email" value={email} />
        <input type="hidden" name="tel" value={phone} />

        <input type="hidden" name="lang" value={lang} />
        <input type="hidden" name="hideCurrency" value="" />
        <input type="hidden" name="noLanguageMenu" value="" />
        <input type="hidden" name="accId1" value={accId1} />

        {/*custom field*/}
        <input type="hidden" name="MC_orderId" value={orderId} />
        <input type="hidden" name="MC_basketId" value={basketId} />
        <input type="hidden" name="MC_customerId" value={customerId} />
        <input type="hidden" name="MC_callbackY" value={getMobileCallbackY()} />
        <input type="hidden" name="MC_callbackC" value={getMobileCallbackC()} />
        <input type="hidden" name="MC_callback" value={getCallback()} />
        <input type="hidden" name="MC_mobileApp" value={'yes'} />
      </form>
    );
  }

  renderHiddenIframe() {
    const { iFrameSrc } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <iframe src={iFrameSrc} width="100%" style={{ border: 'none' }} height="1300px" ref={this.iframeRef} />
          </div>
        </div>
      </div>
    );
  }

  onPaymentClick = () => {
    const { config } = this.state;
    const { payments } = config;
    const { type } = payments;
    const { orderId } = this.state;

    switch (type) {
      case 'worldPay': {
        this.formRef.current.submit();
        break;
      }

      case 'pay360Hosted': {
        payment360(orderId, PAYMENT_CHANNEL.MOBILE).then(res => {
          const { redirectUrl } = res;

          if (redirectUrl) {
            window.open(redirectUrl, '_self');
          } else {
            this.setState({
              isLoading: false,
              isPaymentUnavailable: true,
            });
          }
        });
        break;
      }

      case 'pay360Iframe': {
        payment360(orderId, PAYMENT_CHANNEL.MOBILE).then(res => {
          const { redirectUrl, sessionId } = res;

          if (redirectUrl && sessionId) {
            this.setState({ isLoading: false, iFrameSrc: redirectUrl, sessionId });
          } else {
            this.setState({
              isLoading: false,
              isPaymentUnavailable: true,
            });
          }
        });
        break;
      }
    }
  };

  onCloseErrorClick = () => {
    this.setState({
      isPaymentUnavailable: false,
    });
  };

  renderError(): React.ReactNode {
    const { isPaymentUnavailable } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentUnavailable}
        title={'Error'}
        body={'Unfortunately, the payment service is currently unavailable. Please try again later'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  render() {
    const { orderAmount, iFrameSrc, config, isPaymentUnavailable, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const isConfigExist = typeof config !== 'undefined';

    const type = propz.get(config, ['payments', 'type'], 'worldPay');
    const classes = isPaymentUnavailable ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderError()}
        {typeof isConfigExist && type === 'worldPay' && this.renderHiddenForm()}
        {typeof iFrameSrc !== 'undefined' && this.renderHiddenIframe()}
        {typeof iFrameSrc === 'undefined' && (
          <div className="bMobileCheckoutWrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="bMobileCheckout">
                    <div className="bHeader">
                      <div className="bHeaderText">bentley</div>
                      <div className="bHeaderText mfontsize10">photographic</div>
                    </div>
                    <div className="eMobileCheckoutTitle">CHECKOUT</div>
                    <div className="eMobileCheckoutText">
                      Please click Payment
                      <br />
                      to proceed to the payment.
                    </div>
                    <div className="eMobileCheckoutOrderInfo">
                      <div className="eMobileCheckoutOrderText">Order amount:</div>
                      <div className="eMobileCheckoutOrderText">{`${CURRENCY_SYMBOL.POUND}${orderAmount}`}</div>
                    </div>
                    <div className="eMobileCheckoutButtonWrapper">
                      <button className="eMobileCheckoutButton" onClick={this.onPaymentClick}>
                        Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
