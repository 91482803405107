import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { User } from 'Src/models/users';
import { USER_TYPE, USER_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/user';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  user?: User;
}

export function UserForm(props: Props) {
  const { user, onSubmit, onCancel } = props;
  const isUserExist = typeof user !== 'undefined';

  const UserSchema = isUserExist
    ? Yup.object().shape({
        email: Yup.string()
          .email('Must be valid email')
          .required('Required'),
        type: Yup.string().required('Required'),
      })
    : Yup.object().shape({
        email: Yup.string()
          .email('Must be valid email')
          .required('Required'),
        type: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
      });

  const userForm = {
    email: isUserExist ? user.email : '',
    type: isUserExist ? user.type : '',
    isActive: isUserExist ? user.isActive : false,
    isSchoolNotificationsEnabled: isUserExist ? user.isSchoolNotificationsEnabled : false,
    password: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={userForm}
            validationSchema={UserSchema}
            onSubmit={values => {
              const { password, ...rest } = values;
              if (password === '') {
                onSubmit(rest);
              } else {
                onSubmit(values);
              }
            }}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                  </div>

                  <div className="form-group">
                    <label>Role</label>
                    <Field name="type" component="select" placeholder="Select type" className="form-control mb-3">
                      <option value={''} />
                      <option value={USER_TYPE.ADMIN}>{USER_TYPE_SERVER_TO_CLIENT_MAPPING.ADMIN}</option>
                      <option value={USER_TYPE.ACCOUNT_MANAGER}>
                        {USER_TYPE_SERVER_TO_CLIENT_MAPPING.ACCOUNT_MANAGER}
                      </option>
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="type" />
                  </div>

                  <Field name="isActive">
                    {({ field }) => <Switch {...field} text={'Active'} customClass="mb-3" />}
                  </Field>

                  <Field name="isSchoolNotificationsEnabled">
                    {({ field }) => <Switch {...field} text={'School notifications'} customClass="mb-3" />}
                  </Field>

                  <div className="form-group">
                    <label>Password</label>
                    <Field name="password" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="password" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
