export function getDomainParts() {
  return document.location.host.split('.').slice();
}

export function getOrdersSiteLink(): string {
  const domain = 'orders';
  const domainParts = getDomainParts();
  domainParts.splice(0, 1, domain);
  return document.location.protocol + '//' + domainParts.join('.') + '/';
}
