import axios from 'axios';
import { AppCustomer } from '../../views/AppOrders/AppOrders';

export function createCustomerBasket(customer: AppCustomer) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };
  return axios.post(`${window.apiBase}/customer/${customerId}/basket`, { customerId }, config).then(response => {
    return response.data;
  });
}

export function getCustomerBasket(customer: AppCustomer) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/basket/${basketId}`, config).then(response => {
    return response.data;
  });
}

export function deleteImageFromGalleryInCustomerBasket(customer: AppCustomer, imageIds: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
    data: { imageIds },
  };
  return axios
    .delete(`${window.apiBase}/customer/${customerId}/basket/${basketId}/galleryImages`, config)
    .then(response => {
      return response.data;
    });
}

export function getOrCreateCustomerBasket(customer: AppCustomer) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/basket`, config).then(response => {
    return response.data;
  });
}

export function addProductToCustomerBasket(customer: AppCustomer, data: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/addProduct`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateCustomerBasketItemQuantity(customer: AppCustomer, data: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/quantity`, data, config)
    .then(response => {
      return response.data;
    });
}

export function addGalleryToCustomerBasket(customer: AppCustomer, galleryImages: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/galleryImages`, { galleryImages }, config)
    .then(response => {
      return response.data;
    });
}

export function addSelectedProductTypeToCustomerBasket(customer: AppCustomer, productTypeId: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/productType`, { productTypeId }, config)
    .then(response => {
      return response.data;
    });
}

export function addSelectedProductToCustomerBasket(customer: AppCustomer, productId: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/product`, { productId }, config)
    .then(response => {
      return response.data;
    });
}

export function addSelectedImageToCustomerBasket(customer: AppCustomer, imageId: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/image`, { imageId }, config)
    .then(response => {
      return response.data;
    });
}

export function createCustomerOrder(customer: AppCustomer, data: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/orders`, data, config)
    .then(response => {
      return response.data;
    });
}

export function setDeliveryCountryToCustomerBasket(customer: AppCustomer, data: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/deliveryCountry`, data, config)
    .then(response => {
      return response.data;
    });
}

export function setDeliveryOptionToCustomerBasket(customer: AppCustomer, data: any) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/deliveryOption`, data, config)
    .then(response => {
      return response.data;
    });
}
