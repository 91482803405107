import * as React from 'react';
import { Loader } from '../../components/Loader/Loader';
import { Redirect, Route, Switch } from 'react-router';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { LoginOrders } from './Login/Login';
import { clearSession, getSession } from '../../helpers/cookies';
import {
  clearCustomerBasketIdToStorage,
  clearOneTimeCodeInStorage,
  setOneTimeCodeToStorage,
  getOneTimeCodeToStorage,
  setCustomerBasketIdToStorage,
  getCustomerBasketIdToStorage,
  getIsSignFromOneTimeCodeWaitingFromStorage,
} from '../../helpers/sessionStorage';
import { loginCustomer, logoutCustomer } from '../../helpers/auth';
import { register } from '../../services/public/register';
import { Registration } from './Registration/Registration';
import { Verification } from './Verification/Verification';
import StorageHelper from '../../helpers/storage';
import { LandingPage } from './LandingPage/LandingPage';
import { EmptyComponent } from '../../components/EmptyComponent/EmptyComponent';
import { Header } from './LandingPage/Header/Header';
import { TopMenu } from './LandingPage/TopMenu/TopMenu';
import { OfferOfMonthSlider } from '../../components/Slider/OfferOfMonthSlider/OfferOfMonthSlider';
import { Footer } from './LandingPage/Footer/Footer';
import { PrivateLink } from './PrivateLink/PrivateLink';
import { LoginOneTimeCode } from './LoginOneTimeCode/LoginOneTimeCode';
import { OneTimeCode } from './OneTimeCode/OneTimeCode';
import { PhotoGallery } from './PhotoGallery/PhotoGallery';
import { PhotoProductMatch } from './PhotoProductMatch/PhotoProductMatch';
import { PublicOffers } from './PublicOffers/PublicOffers';
import { OneTimeCodeReady } from './OneTimeCode/OneTimeCodeReady';
import { OneTimeCodeWaitingReady } from './OneTimeCode/OneTimeCodeWaitingReady';
import { Basket } from './Basket/Basket';
import { DeliveryOptions } from './Basket/DeliveryOptions/DeliveryOptions';
import { SchoolDeliveryForm } from './Basket/DeliveryOptions/SchoolDeliveryForm';
import { HomeDeliveryForm } from './Basket/DeliveryOptions/HomeDeliveryForm';
import { CustomerPhotoGallery } from './Customer/CustomerPhotoGallery/CustomerPhotoGallery';
import { CustomerPhotoProductMatch } from './Customer/CustomerPhotoProductMatch/CustomerPhotoProductMatch';
import { CustomerOffers } from './Customer/CustomerOffers/CustomerOffers';
import { CustomerBasket } from './Customer/CustomerBasket/CustomerBasket';
import { CustomerDeliveryOptions } from './Customer/CustomerBasket/CustomerDeliveryOptions/CustomerDeliveryOptions';
import { CustomerSchoolDeliveryForm } from './Customer/CustomerBasket/CustomerDeliveryOptions/CustomerSchoolDeliveryForm';
import { CustomerHomeDeliveryForm } from './Customer/CustomerBasket/CustomerDeliveryOptions/CustomerHomeDeliveryForm';
import { getSliders } from '../../services/public/sliders';
import { SLIDER_TYPES } from '../../consts/slider';
import { CustomerView } from './Customer/CustomerView/CustomerView';
import { isDev, isLocal, isStage } from '../../helpers/domain';
import { getSliderObject } from '../../helpers/slider';
import { SliderImage } from '../../models/slider';
import { AdditionalCode } from '../../models/additionalCode';
import { LIMIT_DOWNLOAD_IMAGES } from '../../consts/images';
import { addGalleryToBasket, addOneTimeCodeBasketToCustomer, createBasket } from '../../services/public/basket';
import { addAccessCode } from '../../services/customer/customer';
import { FAQs } from './LandingPage/FAQs/FAQs';
import { getPhoneWithCode, getPhoneWithoutFirstZero } from '../../helpers/phone';
import { INFO_BLOCK_TYPE } from '../../consts/infoblock';
import { ResetPassword } from './ResetPassword/ResetPasword';
import { clearTickets, clearGalleryTicketIds } from '../../helpers/gallery';
import { getConfig } from '../../services/public/config';
import { getTicketsCount } from '../../services/public/images';
import { getBasket } from '../../services/public/basket';
import { getCustomerBasket, addGalleryToCustomerBasket } from '../../services/customer/basket';
import { SimpleModal } from '../../components/SimpleModal/SimpleModal';
import { CookiesAgreementModal } from './CommonComponents/CookiesAgreementModal/CookiesAgreementModal';
import { setCookieAgreement, getCookieAgreement } from '../../helpers/cookies';
import { History } from 'history';
import { CustomerProfile } from './Customer/CustomerProfile/CustomerProfile';
import { setTermsAndConditionsAgreement, setPromoEmailAgreement } from '../../services/customer/customer';
import { TermsAndConditions } from './Registration/TermsAndConditions';
import { Products } from './Products/Products';
import { ProductType } from '../../models/productTypes';
import { getAllProductTypes } from '../../services/public/images';
import { CustomerStudent } from './Customer/CustomerStudent/CustomerStudent';
import { MobileCheckout } from './Mobile/MobileCheckout/MobileCheckout';
import { MobileResult } from './Mobile/MobileResult/MobileResult';
import { ParentInvite } from './ParentInvite/ParentInvite';
import { Order } from './Order/Order';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../consts/common';
import { GDPRInformation } from './LandingPage/GDPRInformation/GDPRInformation';
import { getCustomerProfile } from '../../services/customer/customer';
import { PROFILE_CUSTOMER_TABS } from '../../consts/profile';
import { loginOneTimeCode } from '../../helpers/auth';
import { isOneTimeCodeContainsUsernameAndPassword } from '../../helpers/oneTimeCode';
import { getOrCreateCustomerBasket } from '../../services/customer/basket';
import { checkCustomerSession } from '../../services/customer/customer';
import './AppOrders.scss';
import { CustomerOrderImages } from './Customer/CustomerOrderImages/CustomerOrderImages';
import { PublicConfig } from '../../models/config';

export interface AppCustomer {
  sessionKey: string;
  customerId: string;
  basketId?: string;
}

export interface AppOneTimeCode {
  username: string;
  password: string;
  skip?: number;
  ticketsCount?: number;
  basketId?: string;
  isAuthQuestionAsked?: boolean;
  additionalCodes?: AdditionalCode[];
}

interface State {
  isLoading: boolean;
  isAuthorizedCustomer: boolean;
  isAuthorizedOneTimeCode: boolean;
  allowCustomerAccess: boolean;
  isCustomerVerified: boolean;
  isRegistrationFromOneTimeCode: boolean;
  tickets: any[];
  selectedTicketIds: any[];
  customer?: AppCustomer;
  oneTimeCode?: AppOneTimeCode;
  offersOfMonthSlider: SliderObject;
  mainSlider: SliderObject;
  promotedProductsSlider: SliderObject;
  products: any[];
  basketCount: number;
  isOpenInfoBlock: boolean;
  infoBlockType: string;
  schools: any[];
  delivery: any;
  isCookiesModalOpen: boolean;
  productTypes: ProductType[];
  isRedirectModalOpen: boolean;
}

export interface SliderObject {
  images: SliderImage[];
  delay: number;
}

export class AppOrders extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    const session = getSession();
    const isSession = typeof session !== 'undefined';

    const customerBasketId = getCustomerBasketIdToStorage();

    const oneTimeCodeSession = getOneTimeCodeToStorage();
    const isOneTimeCodeSession = typeof oneTimeCodeSession !== 'undefined';

    const cookiesAgreement = getCookieAgreement();
    const isCookiesAgreement = typeof cookiesAgreement !== 'undefined';

    this.state = {
      isAuthorizedCustomer: isSession,
      isAuthorizedOneTimeCode: isOneTimeCodeSession,
      isRegistrationFromOneTimeCode: false,
      isCustomerVerified: undefined,
      allowCustomerAccess: false,
      isLoading: true,
      tickets: [],
      selectedTicketIds: [],
      customer: {
        sessionKey: isSession ? session.id || session.key : undefined,
        customerId: isSession ? session.userId : undefined,
        basketId: customerBasketId,
      },
      oneTimeCode: {
        username: isOneTimeCodeSession ? oneTimeCodeSession.username : undefined,
        password: isOneTimeCodeSession ? oneTimeCodeSession.password : undefined,
        skip: 0,
        basketId: isOneTimeCodeSession ? oneTimeCodeSession.basketId : undefined,
        ticketsCount: 0,
        isAuthQuestionAsked: isOneTimeCodeSession ? oneTimeCodeSession.isAuthQuestionAsked : false,
        additionalCodes: isOneTimeCodeSession ? oneTimeCodeSession.additionalCodes : undefined,
      },
      offersOfMonthSlider: {
        images: [],
        delay: undefined,
      },
      mainSlider: {
        images: [],
        delay: undefined,
      },
      promotedProductsSlider: {
        images: [],
        delay: undefined,
      },
      products: [],
      basketCount: 0,
      isOpenInfoBlock: false,
      infoBlockType: '',
      schools: [],
      delivery: undefined,
      isCookiesModalOpen: !isCookiesAgreement,
      productTypes: [],
      isRedirectModalOpen: true,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    const { isAuthorizedOneTimeCode, oneTimeCode, isAuthorizedCustomer, customer } = this.state;

    let basketPromise;
    let profilePromise;

    let checkCustomerSessionPromise;

    BPromise.resolve(true)
      .then(() => {
        if (isAuthorizedOneTimeCode) {
          const { basketId } = oneTimeCode;
          const isBasketExist = typeof basketId !== 'undefined';

          if (isBasketExist) {
            basketPromise = getBasket(oneTimeCode).catch(err => {
              if (err.response.status === NOT_AUTHORIZED_STATUS_CODE) {
                this.setDefaultState();
                clearOneTimeCodeInStorage();
              }
            });
          } else {
            basketPromise = BPromise.resolve(undefined);
          }

          profilePromise = BPromise.resolve(undefined);
          checkCustomerSessionPromise = BPromise.resolve(undefined);
        }

        if (isAuthorizedCustomer) {
          const { sessionKey } = customer;
          checkCustomerSessionPromise = checkCustomerSession(sessionKey);
        }
        return checkCustomerSessionPromise;
      })
      .then(status => {
        if (isAuthorizedCustomer) {
          if (status === NOT_AUTHORIZED_STATUS_CODE) {
            basketPromise = BPromise.resolve(undefined);
            profilePromise = BPromise.resolve(undefined);
            this.onLogoutClick();
          } else {
            const { basketId } = customer;
            const isBasketExist = typeof basketId !== 'undefined';

            if (isBasketExist) {
              basketPromise = getCustomerBasket(customer).catch(err => {});
            } else {
              basketPromise = BPromise.resolve(undefined);
            }

            profilePromise = getCustomerProfile(customer);
          }
        }

        const promises: [Promise<PublicConfig>, Promise<any>, Promise<any>, Promise<any>, Promise<any>] = [
          getConfig(),
          getSliders(),
          basketPromise,
          getAllProductTypes(),
          profilePromise,
        ];
        return BPromise.all(promises);
      })
      .then(([config, sliders, basket, productTypes, profile]) => {
        const { app } = config;
        const { allowCustomerAccess } = app;

        const offersOfMonthSlider = getSliderObject(sliders, SLIDER_TYPES.OFFER_OF_MONTH);
        const mainSlider = getSliderObject(sliders, SLIDER_TYPES.MAIN_SLIDER);
        const promotedProductsSlider = getSliderObject(sliders, SLIDER_TYPES.PROMOTED_PRODUCTS);
        const productsSlider = getSliderObject(sliders, SLIDER_TYPES.PRODUCTS);
        const { images } = productsSlider;

        let isCustomerVerified;

        if (typeof profile !== 'undefined') {
          const email = propz.get(profile, ['verification', 'status', 'email']);
          const sms = propz.get(profile, ['verification', 'status', 'sms']);

          isCustomerVerified = email && sms;
        }

        if (typeof basket !== 'undefined') {
          this.setState({
            offersOfMonthSlider: offersOfMonthSlider,
            mainSlider: mainSlider,
            promotedProductsSlider: promotedProductsSlider,
            products: images,
            isLoading: false,
            allowCustomerAccess,
            basketCount: basket.items.length,
            productTypes: productTypes,
            isCustomerVerified,
          });
        } else {
          this.setState({
            offersOfMonthSlider: offersOfMonthSlider,
            mainSlider: mainSlider,
            promotedProductsSlider: promotedProductsSlider,
            products: images,
            isLoading: false,
            allowCustomerAccess,
            productTypes: productTypes,
            isCustomerVerified,
          });
        }
      });
  }

  onLoginCustomerClick = (tickets, session, redirectCallback?, basketId?) => {
    const { key, userId } = session;
    const { oneTimeCode } = this.state;
    const { username, password } = oneTimeCode;

    let isCustomerVerified;
    const customer = {
      customerId: userId,
      sessionKey: key,
      basketId: basketId,
    };

    const isLoginFromOneTimeCodeWaiting = getIsSignFromOneTimeCodeWaitingFromStorage();

    getCustomerProfile(customer)
      .then(profile => {
        const email = propz.get(profile, ['verification', 'status', 'email'], '');
        const sms = propz.get(profile, ['verification', 'status', 'email'], '');
        isCustomerVerified = email && sms;

        return true;
      })
      .then(() => {
        switch (true) {
          case isLoginFromOneTimeCodeWaiting:
            clearOneTimeCodeInStorage();
            this.setState({
              isAuthorizedCustomer: true,
              customer,
              isAuthorizedOneTimeCode: false,
              oneTimeCode: {
                username: undefined,
                password: undefined,
              },
              isCustomerVerified,
            });
            break;

          case typeof basketId !== 'undefined':
            setCustomerBasketIdToStorage(basketId);
            clearOneTimeCodeInStorage();

            this.setState(
              {
                isAuthorizedCustomer: true,
                customer,
                isAuthorizedOneTimeCode: false,
                oneTimeCode: {
                  username: undefined,
                  password: undefined,
                  skip: 0,
                  ticketsCount: 0,
                  basketId: undefined,
                  isAuthQuestionAsked: false,
                },
                isCustomerVerified,
              },
              () => {
                redirectCallback();
              }
            );
            break;

          default:
            this.setState({
              isAuthorizedCustomer: true,
              tickets: tickets,
              customer: {
                customerId: userId,
                sessionKey: key,
                basketId: undefined,
              },
              isCustomerVerified,
            });
        }
      });
  };

  onLoginOneTimeCodeClick = (
    tickets,
    username: string,
    password: string,
    ticketsCount: number,
    basketId?: string,
    isAuthQuestionAsked?: boolean,
    additionalCodes?: AdditionalCode[]
  ) => {
    let oneTimeCode;

    if (typeof basketId !== 'undefined') {
      oneTimeCode = {
        username,
        password,
        skip: LIMIT_DOWNLOAD_IMAGES,
        basketId: basketId,
        ticketsCount: ticketsCount,
        isAuthQuestionAsked,
        additionalCodes,
      };
      setOneTimeCodeToStorage({ username, password, basketId, isAuthQuestionAsked, additionalCodes });
    } else {
      clearGalleryTicketIds();
      clearTickets();
      oneTimeCode = {
        username,
        password,
        skip: LIMIT_DOWNLOAD_IMAGES,
        basketId: undefined,
        ticketsCount: ticketsCount,
        isAuthQuestionAsked,
        additionalCodes,
      };
      setOneTimeCodeToStorage({ username, password, isAuthQuestionAsked, additionalCodes });
    }

    this.setState({
      oneTimeCode,
      isAuthorizedOneTimeCode: true,
      tickets: tickets,
      selectedTicketIds: [],
      basketCount: 0,
      schools: [],
    });
  };

  onRegisterClick = data => {
    const { phone, phoneCode, promoEmailAgreement, email, ...rest } = data;
    const phoneWithoutFirstZero = getPhoneWithoutFirstZero(phone.trim());
    const phoneToPost = `${phoneCode}${phoneWithoutFirstZero}`;

    const dataToTransfer = {
      ...rest,
      phone: phoneToPost,
      email: email.trim(),
    };

    const customerPassword = data.password;
    const customerEmail = data.email.trim();

    let session;
    const { oneTimeCode, isAuthorizedOneTimeCode } = this.state;
    let isBasketExist;

    return register(dataToTransfer)
      .then(() => {
        return loginCustomer(customerEmail, customerPassword);
      })
      .then(_session => {
        session = _session;
        const { key, userId } = session;
        const { username, password, basketId } = oneTimeCode;

        if (!isAuthorizedOneTimeCode) {
          // One year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('session', session, { expires: 365 });

          this.setState({
            customer: {
              customerId: userId,
              sessionKey: key,
              basketId: undefined,
            },
            oneTimeCode: {
              username: undefined,
              password: undefined,
              skip: 0,
              ticketsCount: 0,
              basketId: undefined,
              isAuthQuestionAsked: false,
            },
            isAuthorizedCustomer: true,
          });
        }

        const setTermsAndConditionsAgreementPromise = setTermsAndConditionsAgreement({
          sessionKey: key,
          customerId: userId,
        });

        const setPromoEmailAgreementPromise = promoEmailAgreement
          ? setPromoEmailAgreement({ sessionKey: key, customerId: userId }, promoEmailAgreement)
          : BPromise.resolve(true);

        isBasketExist = typeof basketId !== 'undefined' && basketId !== '';

        const addOneTimeCodeBasketToCustomerPromise =
          isAuthorizedOneTimeCode && isBasketExist
            ? addOneTimeCodeBasketToCustomer(oneTimeCode, userId)
            : BPromise.resolve(true);

        const addAccessCodePromise = isAuthorizedOneTimeCode
          ? addAccessCode({ customerId: userId, sessionKey: key }, username, password)
          : BPromise.resolve(true);

        const promises = [
          setTermsAndConditionsAgreementPromise,
          setPromoEmailAgreementPromise,
          addOneTimeCodeBasketToCustomerPromise,
          addAccessCodePromise,
        ];

        return Promise.all(promises);
      })
      .then(([res1, res2, basket, res3]) => {
        if (isAuthorizedOneTimeCode) {
          // One year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('session', session, { expires: 365 });
          const { key, userId } = session;

          const customer = isBasketExist
            ? {
                customerId: userId,
                sessionKey: key,
                basketId: basket.id,
              }
            : {
                customerId: userId,
                sessionKey: key,
              };

          this.setState({
            customer,
            oneTimeCode: {
              username: undefined,
              password: undefined,
              skip: 0,
              ticketsCount: 0,
              basketId: undefined,
              isAuthQuestionAsked: false,
            },
            isAuthorizedCustomer: true,
            isAuthorizedOneTimeCode: false,
            isRegistrationFromOneTimeCode: true,
          });
          setCustomerBasketIdToStorage(basket.id);
          clearOneTimeCodeInStorage();
        }
      });
  };

  onLogoutClick = (): void => {
    const { isAuthorizedCustomer, isAuthorizedOneTimeCode, customer } = this.state;
    const { sessionKey } = customer;

    switch (true) {
      case isAuthorizedCustomer:
        logoutCustomer(sessionKey).finally(() => {
          this.setDefaultState();
          clearCustomerBasketIdToStorage();
        });
        break;
      case isAuthorizedOneTimeCode:
        this.setDefaultState();
        clearOneTimeCodeInStorage();
        break;
      default:
        this.setDefaultState();
        break;
    }
  };

  setDefaultState = (): void => {
    clearSession();

    this.setState({
      customer: {
        sessionKey: undefined,
        customerId: undefined,
        basketId: undefined,
      },
      oneTimeCode: {
        username: undefined,
        password: undefined,
        skip: 0,
        ticketsCount: 0,
        basketId: undefined,
        isAuthQuestionAsked: false,
      },
      isAuthorizedCustomer: false,
      isAuthorizedOneTimeCode: false,
      isCustomerVerified: undefined,
      tickets: [],
      schools: [],
      basketCount: 0,
    });
  };

  setBasketCount = (count: number) => {
    this.setState({
      basketCount: count,
    });
  };

  setAuthQuestionAsked = () => {
    const { oneTimeCode } = this.state;
    const updatedOneTimeCode = { ...oneTimeCode, isAuthQuestionAsked: true };

    this.setState({
      oneTimeCode: updatedOneTimeCode,
    });

    const { username, password, basketId, isAuthQuestionAsked, additionalCodes } = updatedOneTimeCode;
    setOneTimeCodeToStorage({ username, password, basketId, isAuthQuestionAsked, additionalCodes });
  };

  onGalleryImageClick = (ticket: any) => {
    const { selectedTicketIds } = this.state;

    if (selectedTicketIds.includes(ticket.id)) {
      const nextSelectedTicketIds = selectedTicketIds.filter(selectedTicketId => selectedTicketId !== ticket.id);
      this.setState({
        selectedTicketIds: nextSelectedTicketIds,
      });
    } else {
      this.setState({
        selectedTicketIds: [...selectedTicketIds, ticket.id],
      });
    }
  };

  clearSelectedTicketIds = () => {
    this.setState({
      selectedTicketIds: [],
    });
  };

  setOneTimeCodeBasketId = (basketId: string) => {
    const { oneTimeCode } = this.state;
    this.setState({
      oneTimeCode: { ...oneTimeCode, basketId },
    });

    const { username, password, isAuthQuestionAsked, additionalCodes } = oneTimeCode;
    setOneTimeCodeToStorage({ username, password, basketId, isAuthQuestionAsked, additionalCodes });
  };

  setCustomerBasketId = (basketId: string) => {
    const { customer } = this.state;
    this.setState({
      customer: { ...customer, basketId },
    });
    setCustomerBasketIdToStorage(basketId);
  };

  setAdditionalCodesToOneTimeCode = (username, password) => {
    const { oneTimeCode } = this.state;
    const { additionalCodes } = oneTimeCode;
    const isAdditionalCodesExist = typeof additionalCodes !== 'undefined';

    if (isAdditionalCodesExist) {
      const additionalCodesUpdated = [...additionalCodes, { username, password }];
      const oneTimeCodeUpdated = { ...oneTimeCode, additionalCodes: additionalCodesUpdated };

      this.setState({
        oneTimeCode: oneTimeCodeUpdated,
      });
      setOneTimeCodeToStorage(oneTimeCodeUpdated);
    } else {
      const additionalCodesUpdated = [{ username, password }];
      const oneTimeCodeUpdated = { ...oneTimeCode, additionalCodes: additionalCodesUpdated };

      this.setState({
        oneTimeCode: oneTimeCodeUpdated,
      });
      setOneTimeCodeToStorage(oneTimeCodeUpdated);
    }
  };

  updateTickets = (tickets: any[]) => {
    const { tickets: prevTickets } = this.state;
    this.setState({
      tickets: [...prevTickets, ...tickets],
    });
  };

  onShowInfoBlock = type => {
    const { isOpenInfoBlock, infoBlockType } = this.state;

    switch (true) {
      case type === INFO_BLOCK_TYPE.LOGIN:
        this.setState({
          isOpenInfoBlock: false,
          infoBlockType: type,
        });
        break;
      case type !== infoBlockType && infoBlockType !== '' && infoBlockType !== INFO_BLOCK_TYPE.LOGIN:
        this.setState({
          infoBlockType: type,
          isOpenInfoBlock: true,
        });
        break;
      default:
        this.setState({
          infoBlockType: type,
          isOpenInfoBlock: !isOpenInfoBlock,
        });
        break;
    }
  };

  clearCustomerBasketId = () => {
    const { customer } = this.state;
    this.setState({
      customer: {
        ...customer,
        basketId: undefined,
      },
    });
  };

  setDelivery = delivery => {
    this.setState({
      delivery,
    });
  };

  setOneTimeCodeTicketsCount = () => {
    const { oneTimeCode } = this.state;
    const additionalCodes = propz.get(oneTimeCode, ['additionalCodes'], []);
    const promises = additionalCodes.map(code => getTicketsCount(code));
    promises.push(getTicketsCount(oneTimeCode));

    let totalTicketsCount = 0;

    BPromise.all(promises).then(countArray => {
      countArray.map(({ count }) => (totalTicketsCount += count));

      this.setState({
        oneTimeCode: { ...oneTimeCode, ticketsCount: totalTicketsCount },
      });
    });
  };

  onCookiesModalOkClick = () => {
    setCookieAgreement();
    this.setState({
      isCookiesModalOpen: false,
    });
  };

  renderCookiesModal = () => {
    const { isCookiesModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isCookiesModalOpen}>
        <CookiesAgreementModal onOk={this.onCookiesModalOkClick} {...this.props} />
      </SimpleModal>
    );
  };

  onProfileClick = (history: History) => {
    history.push('/customer/profile');
  };

  onHomeClick = (history: History) => {
    history.push('/customer');
  };

  setDataFromOrder = (order: { username: string; password: string; imageId: string }, history: History) => {
    this.setState({
      isLoading: true,
    });

    const { username, password, imageId } = order;
    const { isAuthorizedCustomer, isAuthorizedOneTimeCode } = this.state;

    switch (true) {
      case isAuthorizedCustomer:
        const { customer } = this.state;
        let customerUpdated = { ...customer };
        let basketCount;

        addAccessCode(customer, username, password)
          .then(({ status }) => {
            return getOrCreateCustomerBasket(customer);
          })
          .then(basket => {
            const { galleryImages, id: basketId, items } = basket;
            setCustomerBasketIdToStorage(basketId);
            customerUpdated = { ...customerUpdated, basketId: basket.id };
            basketCount = items.length;

            const isGalleryImagesExist = Array.isArray(galleryImages);

            if (isGalleryImagesExist) {
              const galleryImagesToPost = galleryImages.map(image => {
                return { imageId: image.imageId };
              });
              const galleryImagesUpdated = [...galleryImagesToPost, { imageId: imageId }];

              return addGalleryToCustomerBasket(customerUpdated, galleryImagesUpdated);
            } else {
              return addGalleryToCustomerBasket(customerUpdated, [{ imageId: imageId }]);
            }
          })
          .then(() => {
            history.push('/customer/photoGallery');
            this.setState({
              isLoading: false,
              customer: customerUpdated,
              basketCount: basketCount,
            });
          })
          .catch(error => {
            history.push('/customer');
            this.setState({
              isLoading: false,
            });
          });
        break;
      case isAuthorizedOneTimeCode:
        const { oneTimeCode } = this.state;
        const { basketId: oneTimeCodeBasketId } = oneTimeCode;
        const isOneTimeCodeBasketExist = typeof oneTimeCodeBasketId !== 'undefined';
        let oneTimeCodeUpdated = { ...oneTimeCode };

        if (!isOneTimeCodeContainsUsernameAndPassword(oneTimeCode, username, password)) {
          loginOneTimeCode(username, password)
            .then(res => {
              const { additionalCodes } = oneTimeCode;
              const isAdditionalCodesExist = typeof additionalCodes !== 'undefined';

              if (isAdditionalCodesExist) {
                const additionalCodesUpdated = [...additionalCodes, { username, password }];
                oneTimeCodeUpdated = { ...oneTimeCodeUpdated, additionalCodes: additionalCodesUpdated };
                setOneTimeCodeToStorage(oneTimeCodeUpdated);
              } else {
                const additionalCodesUpdated = [{ username, password }];
                oneTimeCodeUpdated = { ...oneTimeCodeUpdated, additionalCodes: additionalCodesUpdated };
                setOneTimeCodeToStorage(oneTimeCodeUpdated);
              }

              if (!isOneTimeCodeBasketExist) {
                return createBasket(oneTimeCode);
              } else {
                return getBasket(oneTimeCode);
              }
            })
            .then(basket => {
              if (!isOneTimeCodeBasketExist) {
                oneTimeCodeUpdated = { ...oneTimeCodeUpdated, basketId: basket.id };

                return addGalleryToBasket(oneTimeCodeUpdated, [{ imageId: imageId }]);
              } else {
                const { galleryImages } = basket;

                const galleryImagesToPost = galleryImages.map(image => {
                  return { imageId: image.imageId };
                });

                const galleryImagesUpdated = [...galleryImagesToPost, { imageId: imageId }];

                return addGalleryToBasket(oneTimeCode, galleryImagesUpdated);
              }
            })
            .then(() => {
              history.push('/photoGallery');
              this.setState({
                oneTimeCode: oneTimeCodeUpdated,
                isLoading: false,
              });
            })
            .catch(error => {
              history.push('/oneTimeCode');

              this.setState({
                isLoading: false,
              });
            });
        } else {
          history.push('/oneTimeCode');
          this.setState({
            isLoading: false,
          });
        }
        break;
      default:
        let basketId;

        loginOneTimeCode(username, password)
          .then(() => {
            return createBasket({ username, password });
          })
          .then(basket => {
            basketId = basket.id;
            return addGalleryToBasket({ username, password, basketId }, [{ imageId: imageId }]);
          })
          .then(basket => {
            setOneTimeCodeToStorage({
              username,
              password,
              basketId: basketId,
              isAuthQuestionAsked: true,
              additionalCodes: undefined,
            });

            history.push('/photoGallery');

            this.setState({
              oneTimeCode: {
                username: username,
                password: password,
                basketId: basketId,
              },
              isAuthorizedOneTimeCode: true,
              isLoading: false,
            });
          })
          .catch(error => {
            history.push('/accessCode');

            this.setState({
              isLoading: false,
            });
          });
    }
  };

  onClickLink = event => {
    event.preventDefault();

    this.setState({
      isRedirectModalOpen: false,
    });
  };

  renderRedirectModal = () => {
    const { isRedirectModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isRedirectModalOpen} customClass={'modal-lg'}>
        <div className={'eRedirectModalText mb-3 ml-3 mr-3'}>
          <strong>Welcome to the New Bentley Photographic Online Shop</strong>
        </div>
        <div className={'eRedirectModalText mb-3 ml-3 mr-3'}>
          This website only has images from June 2020 onwards and does not hold account details from our old ordering
          website.
        </div>
        <div className={'eRedirectModalText mb-3 ml-3 mr-3'}>
          We will be moving all the old accounts and images to this site and this will be completed by the end of
          January 2021
        </div>
        <div className={'eRedirectModalText mb-3 ml-3 mr-3'}>
          If you wish to have access to this in future, please create an account on this website using the same email
          address and you will receive an invite to add the images when this process is complete.
        </div>
        <div className={'eRedirectLinkWrapper'}>
          <button className={'eRedirectLink'} onClick={this.onClickLink}>
            OK
          </button>
        </div>
      </SimpleModal>
    );
  };

  updateIsCustomerVerified = () => {
    this.setState({
      isCustomerVerified: true,
    });
  };

  render() {
    const {
      isLoading,
      isAuthorizedCustomer,
      isAuthorizedOneTimeCode,
      allowCustomerAccess,
      isCustomerVerified,
      tickets,
      customer,
      oneTimeCode,
      offersOfMonthSlider,
      mainSlider,
      promotedProductsSlider,
      products,
      basketCount,
      selectedTicketIds,
      isRegistrationFromOneTimeCode,
      isOpenInfoBlock,
      infoBlockType,
      schools,
      delivery,
      productTypes,
      isCookiesModalOpen,
      isRedirectModalOpen,
    } = this.state;

    const { images, delay } = offersOfMonthSlider;

    const url = document.location;
    const isMobile = url.pathname.indexOf('/mobile') !== -1;

    if (isLoading) {
      return <Loader />;
    }

    if (isMobile) {
      return (
        <Switch>
          <Route path="/mobile/checkout" component={props => <MobileCheckout {...props} />} />
          <Route
            path="/mobile/result"
            component={props => <MobileResult {...props} onLogoutClick={this.onLogoutClick} />}
          />
        </Switch>
      );
    }

    const classes = isRedirectModalOpen || isCookiesModalOpen ? 'mt-3 modal-open' : 'mt-3';

    if (!isMobile && isAuthorizedCustomer && !isCustomerVerified) {
      return (
        //        <div className={classes}>
        //          <Switch>
        //            <Route path="/" exact component={() => this.renderRedirectModal()} />
        //          </Switch>
        <div className={classes}>
          <Switch>
            <Route
              path="/"
              component={props => (
                <Header
                  {...props}
                  basketCount={basketCount}
                  isAuthorizedCustomer={isAuthorizedCustomer}
                  isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                  logout={this.onLogoutClick}
                  oneTimeCode={oneTimeCode}
                  onProfileClick={this.onProfileClick}
                  onHomeClick={this.onHomeClick}
                />
              )}
            />
          </Switch>
          <Switch>
            <Route
              path="/"
              component={props => (
                <TopMenu
                  isAuthorizedCustomer={isAuthorizedCustomer}
                  isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                  oneTimeCode={oneTimeCode}
                  productTypes={productTypes}
                  {...props}
                />
              )}
            />
          </Switch>
          {images.length > 0 && <OfferOfMonthSlider images={images} delay={delay} />}
          <Switch>
            <Route exact path="/uploadImages" component={props => <EmptyComponent />} />
            <Route exact path="/cartSuccess" component={props => <EmptyComponent />} />
            <Route exact path="/products" component={props => <Products {...props} productTypes={productTypes} />} />
            <Route
              path="/parentInvite"
              component={props => <ParentInvite {...props} isAuthorizedCustomer={isAuthorizedCustomer} />}
            />
            <Route
              path="/verification"
              exact
              render={props => (
                <Verification
                  customer={customer}
                  {...props}
                  isRegistrationFromOneTimeCode={isRegistrationFromOneTimeCode}
                  updateIsCustomerVerified={this.updateIsCustomerVerified}
                  onLogoutClick={this.onLogoutClick}
                />
              )}
            />
            <Route
              path="/customer/profile"
              exact
              render={props => (
                <CustomerProfile
                  {...props}
                  customer={customer}
                  onLogoutClick={this.onLogoutClick}
                  currentTab={PROFILE_CUSTOMER_TABS.VERIFICATION}
                  isCustomerVerified={isCustomerVerified}
                  updateIsCustomerVerified={this.updateIsCustomerVerified}
                />
              )}
            />

            <Route
              exact
              path="/privateLink/:link"
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              exact
              path="/order"
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              exact
              path="/login"
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              exact
              path="/accessCode"
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/photoGallery"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/photoProductMatch"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/offers"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/basket"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/deliveryOptions"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/schoolDelivery"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />
            <Route
              path="/customer/homeDelivery"
              exact
              render={props => <Redirect to={{ pathname: '/customer/profile', state: { from: props.location } }} />}
            />

            <Route path="/parentFAQs" exact render={props => <FAQs />} />
            <Route path="/GDPRInformation" exact render={props => <GDPRInformation />} />
            <Route
              path="/"
              exact
              render={props => (
                <LandingPage
                  offersOfMonthSlider={offersOfMonthSlider}
                  mainSlider={mainSlider}
                  promotedProductsSlider={promotedProductsSlider}
                  products={products}
                />
              )}
            />
          </Switch>
          <Footer
            isOpenInfoBlock={isOpenInfoBlock}
            infoBlockType={infoBlockType}
            onShowInfoBlock={this.onShowInfoBlock}
          />
        </div>
      );
    }

    if (!isMobile) {
      return (
        //        <div className={classes}>
        //          {this.renderCookiesModal()}
        //          <Switch>
        //            <Route path="/" exact component={() => this.renderRedirectModal()} />
        //          </Switch>
        <div className={classes}>
          {/* {this.renderCookiesModal()} */}
          <Switch>
            <Route
              path="/"
              component={props => (
                <Header
                  {...props}
                  basketCount={basketCount}
                  isAuthorizedCustomer={isAuthorizedCustomer}
                  isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                  logout={this.onLogoutClick}
                  oneTimeCode={oneTimeCode}
                  onProfileClick={this.onProfileClick}
                  onHomeClick={this.onHomeClick}
                />
              )}
            />
          </Switch>
          <Switch>
            <Route
              path="/"
              component={props => (
                <TopMenu
                  isAuthorizedCustomer={isAuthorizedCustomer}
                  isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                  oneTimeCode={oneTimeCode}
                  productTypes={productTypes}
                  {...props}
                />
              )}
            />
          </Switch>
          {images.length > 0 && <OfferOfMonthSlider images={images} delay={delay} />}
          <Switch>
            <Route exact path="/uploadImages" component={props => <EmptyComponent />} />
            <Route exact path="/cartSuccess" component={props => <EmptyComponent />} />
            <Route
              exact
              path="/order"
              component={props => <Order setDataFromOrder={this.setDataFromOrder} {...props} />}
            />
            <Route exact path="/privateLink/:link" component={props => <PrivateLink {...props} />} />
            <Route exact path="/products" component={props => <Products {...props} productTypes={productTypes} />} />
            <Route path="/resetPassword" component={props => <ResetPassword {...props} />} />
            <Route
              path="/parentInvite"
              component={props => <ParentInvite {...props} isAuthorizedCustomer={isAuthorizedCustomer} />}
            />
            <Route path="/termsAndConditions" component={props => <TermsAndConditions />} />
            <Route
              exact
              path="/accessCode"
              component={props => (
                <LoginOneTimeCode
                  onFormSubmit={this.onLoginOneTimeCodeClick}
                  {...props}
                  setDelivery={this.setDelivery}
                  isAuthorizedCustomer={isAuthorizedCustomer}
                  isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                  customer={customer}
                  onLogoutClick={this.onLogoutClick}
                  setAdditionalCodesToOneTimeCode={this.setAdditionalCodesToOneTimeCode}
                  oneTimeCode={oneTimeCode}
                />
              )}
            />
            <Route
              path="/oneTimeCode"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <OneTimeCode
                    {...props}
                    oneTimeCode={oneTimeCode}
                    isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                    tickets={tickets}
                    onGalleryImageClick={this.onGalleryImageClick}
                    selectedTicketIds={selectedTicketIds}
                    setBasketId={this.setOneTimeCodeBasketId}
                    updateTickets={this.updateTickets}
                    setTicketsCount={this.setOneTimeCodeTicketsCount}
                  />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/photoGallery"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <PhotoGallery
                    {...props}
                    clearSelectedTicketIds={this.clearSelectedTicketIds}
                    oneTimeCode={oneTimeCode}
                  />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/photoProductMatch"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <PhotoProductMatch {...props} oneTimeCode={oneTimeCode} setBasketCount={this.setBasketCount} />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/offers"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <PublicOffers {...props} oneTimeCode={oneTimeCode} setBasketCount={this.setBasketCount} />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/oneTimeCodeReady"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <OneTimeCodeReady {...props} setAuthQuestionAsked={this.setAuthQuestionAsked} />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/basket"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <Basket
                    {...props}
                    oneTimeCode={oneTimeCode}
                    setBasketCount={this.setBasketCount}
                    allowCustomerAccess={allowCustomerAccess}
                  />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/deliveryOptions"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <DeliveryOptions {...props} oneTimeCode={oneTimeCode} />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/schoolDelivery"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <SchoolDeliveryForm
                    {...props}
                    oneTimeCode={oneTimeCode}
                    schools={schools}
                    deliveryFromPrevSubmit={delivery}
                  />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/homeDelivery"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <HomeDeliveryForm {...props} oneTimeCode={oneTimeCode} deliveryFromPrevSubmit={delivery} />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/oneTimeCodeWaitingReady"
              exact
              render={props =>
                isAuthorizedOneTimeCode ? (
                  <OneTimeCodeWaitingReady promotedProductsSlider={promotedProductsSlider} {...props} />
                ) : (
                  <Redirect to={{ pathname: '/accessCode', state: { from: props.location } }} />
                )
              }
            />
            <Route
              exact
              path="/login"
              render={props =>
                allowCustomerAccess ? (
                  isAuthorizedCustomer ? (
                    <Redirect to={{ pathname: '/customer', state: { from: props.location } }} />
                  ) : (
                    <LoginOrders
                      onFormSubmit={this.onLoginCustomerClick}
                      {...props}
                      oneTimeCode={oneTimeCode}
                      isAuthorizedOneTimeCode={isAuthorizedOneTimeCode}
                    />
                  )
                ) : (
                  <EmptyComponent />
                )
              }
            />
            <Route
              path="/registration"
              exact
              render={props =>
                allowCustomerAccess ? <Registration onSubmit={this.onRegisterClick} {...props} /> : null
              }
            />
            <Route
              path="/verification"
              exact
              render={props =>
                isAuthorizedOneTimeCode || isAuthorizedCustomer ? (
                  <Verification
                    customer={customer}
                    {...props}
                    isRegistrationFromOneTimeCode={isRegistrationFromOneTimeCode}
                    updateIsCustomerVerified={this.updateIsCustomerVerified}
                    onLogoutClick={this.onLogoutClick}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerView
                    setBasketCount={this.setBasketCount}
                    customer={customer}
                    onLogoutClick={this.onLogoutClick}
                    onGalleryImageClick={this.onGalleryImageClick}
                    selectedTicketIds={selectedTicketIds}
                    setCustomerBasketId={this.setCustomerBasketId}
                    clearBasketId={this.clearCustomerBasketId}
                    clearSelectedTicketIds={this.clearSelectedTicketIds}
                    setDelivery={this.setDelivery}
                    {...props}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/photoGallery"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerPhotoGallery
                    {...props}
                    selectedTicketIds={selectedTicketIds}
                    customer={customer}
                    onLogoutClick={this.onLogoutClick}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/students"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerStudent {...props} customer={customer} onLogoutClick={this.onLogoutClick} />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/photoProductMatch"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerPhotoProductMatch
                    {...props}
                    customer={customer}
                    setBasketCount={this.setBasketCount}
                    setCustomerBasketId={this.setCustomerBasketId}
                    onLogoutClick={this.onLogoutClick}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/offers"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerOffers
                    {...props}
                    customer={customer}
                    setBasketCount={this.setBasketCount}
                    onLogoutClick={this.onLogoutClick}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/basket"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerBasket
                    {...props}
                    customer={customer}
                    setBasketCount={this.setBasketCount}
                    onLogoutClick={this.onLogoutClick}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/deliveryOptions"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerDeliveryOptions {...props} customer={customer} onLogoutClick={this.onLogoutClick} />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/schoolDelivery"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerSchoolDeliveryForm
                    {...props}
                    customer={customer}
                    schools={schools}
                    onLogoutClick={this.onLogoutClick}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/homeDelivery"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerHomeDeliveryForm {...props} customer={customer} onLogoutClick={this.onLogoutClick} />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />
            <Route
              path="/customer/profile"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerProfile
                    {...props}
                    customer={customer}
                    onLogoutClick={this.onLogoutClick}
                    isCustomerVerified={isCustomerVerified}
                    updateIsCustomerVerified={this.updateIsCustomerVerified}
                  />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />

            <Route
              path="/customer/order/:orderId"
              exact
              render={props =>
                isAuthorizedCustomer ? (
                  <CustomerOrderImages {...props} customer={customer} />
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />

            <Route path="/parentFAQs" exact render={props => <FAQs />} />
            <Route path="/GDPRInformation" exact render={props => <GDPRInformation />} />
            <Route
              path="/"
              exact
              render={props => (
                <LandingPage
                  offersOfMonthSlider={offersOfMonthSlider}
                  mainSlider={mainSlider}
                  promotedProductsSlider={promotedProductsSlider}
                  products={products}
                />
              )}
            />
          </Switch>
          <Footer
            isOpenInfoBlock={isOpenInfoBlock}
            infoBlockType={infoBlockType}
            onShowInfoBlock={this.onShowInfoBlock}
          />
        </div>
      );
    }
  }
}
