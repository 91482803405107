import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';

export function getJobs(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/jobs`, config).then(response => {
    return response.data;
  });
}

export function getAllJobs(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/jobs`, config).then(response => {
    return response.data;
  });
}

export function getJobsCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/jobs/count`, config).then(response => {
    return response.data;
  });
}

export function createJob(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/jobs`, data, config).then(response => {
    return response.data;
  });
}

export function getJob(user: AppUser, jobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/jobs/${jobId}`, config).then(response => {
    return response.data;
  });
}

export function updateJob(user: AppUser, jobId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/jobs/${jobId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteJobs(user: AppUser, preRegisteredUserIds) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/jobs/remove`, preRegisteredUserIds, config).then(response => {
    return response.data;
  });
}

export function generateJobPrivateLink(user: AppUser, jobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/jobs/${jobId}/generateLink`, {}, config).then(response => {
    return response.data;
  });
}

export function sendNotifications(user: AppUser, jobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/jobs/${jobId}/notifications`, {}, config).then(response => {
    return response.data;
  });
}
