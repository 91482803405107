import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function getConfig(user: AppUser) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/config`, config).then(response => {
    return response.data;
  });
}

export function getNewConfig(user: AppUser) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/newConfig`, config).then(response => {
    return response.data;
  });
}
