import * as React from 'react';
import { PHOTO_TYPES_FILTER, IMAGE_TYPE } from '../../../../../consts/images';
import './CustomerPhotoTypeFilter.scss';

interface Props {
  selectedPhotoType?: string;
  onClick: (photoType: string) => void;
}

export function CustomerPhotoTypeFilter(props: Props) {
  const { selectedPhotoType, onClick } = props;
  return (
    <div className={'bCustomerPhotoTypeFilter'}>
      <div className={'eCustomerPhotoTypeFilterTitle'}>Photo type</div>
      <div className="eCustomerPhotoTypeFilterTextWrapper">
        <div
          className={
            typeof selectedPhotoType === 'undefined'
              ? 'eCustomerPhotoTypeFilterText eTextSelected'
              : 'eCustomerPhotoTypeFilterText'
          }
          key={'productTypes_all'}
          onClick={() => onClick(undefined)}
        >
          {PHOTO_TYPES_FILTER.ALL}
        </div>

        <div
          className={
            selectedPhotoType === IMAGE_TYPE.INDIVIDUAL
              ? 'eCustomerPhotoTypeFilterText eTextSelected'
              : 'eCustomerPhotoTypeFilterText'
          }
          key={'productTypes_individual'}
          onClick={() => onClick(IMAGE_TYPE.INDIVIDUAL)}
        >
          {PHOTO_TYPES_FILTER.INDIVIDUALS}
        </div>

        <div
          className={
            selectedPhotoType === IMAGE_TYPE.GROUP
              ? 'eCustomerPhotoTypeFilterText eTextSelected'
              : 'eCustomerPhotoTypeFilterText'
          }
          key={'productTypes_group'}
          onClick={() => onClick(IMAGE_TYPE.GROUP)}
        >
          {PHOTO_TYPES_FILTER.GROUPS}
        </div>

        <div
          className={
            selectedPhotoType === IMAGE_TYPE.PANORAMIC
              ? 'eCustomerPhotoTypeFilterText eTextSelected'
              : 'eCustomerPhotoTypeFilterText'
          }
          key={'productTypes_panoranic'}
          onClick={() => onClick(IMAGE_TYPE.PANORAMIC)}
        >
          {PHOTO_TYPES_FILTER.PANORAMICS}
        </div>
      </div>
    </div>
  );
}
