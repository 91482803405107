import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from '../../../components/Button/Button';
import * as Yup from 'yup';
import { checkAvailability } from '../../../services/public/register';
import { checkAvailabilitySchoolApp } from '../../../helpers/squadApi';

interface Props {
  onSubmit: (data) => void;
  redirectToLoginPage: () => void;
  isSchoolApp?: boolean;
}

export function ResetPasswordForm(props: Props) {
  const { onSubmit, isSchoolApp } = props;

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .email('Must be valid email')
      .test('email', 'Email not found', value => {
        if (!value) {
          return false;
        } else {
          const checkPromise = isSchoolApp
            ? checkAvailabilitySchoolApp({ email: value })
            : checkAvailability({ email: value });
          return checkPromise.then(res => {
            return !res.isAvailable;
          });
        }
      }),
  });

  const resetPasswordForm = {
    email: '',
  };
  return (
    <Formik
      initialValues={resetPasswordForm}
      validationSchema={ResetPasswordSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ touched, errors, handleSubmit }) => (
        <Form>
          <div className={'bResetPasswordFormTitle'}>
            Please enter the email address you used to create the account.
          </div>
          <div className="form-group form-check">
            <div className="form-group">
              <Field placeholder={'Email'} name="email" className="eResetPasswordFormInput" />
              <ErrorMessage component="div" className="alert alert-danger eResetPasswordError" name="email" />
            </div>
            <Button
              onClick={handleSubmit}
              text={'Save'}
              customClass={'mt-3 mb-3 eResetPasswordFormButton'}
              type={'submit'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
