import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import * as Promise from 'bluebird';
import { AppCustomer } from '../../AppOrders';
import { PhotoSlider } from '../../CommonComponents/PhotoSlider/PhotoSlider';
import { ConfirmationModal } from '../../CommonComponents/PhotoSlider/ConfirmationModal/ConfirmationModal';
import { getImageProductTypes } from '../../../../helpers/images';
import {
  getCustomerBasket,
  addSelectedProductTypeToCustomerBasket,
  addSelectedImageToCustomerBasket,
  deleteImageFromGalleryInCustomerBasket,
} from '../../../../services/customer/basket';
import { checkCustomerSession } from '../../../../services/customer/customer';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../consts/common';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { ProductType } from '../../../../models/productTypes';
import './CustomerPhotoGallery.scss';

interface Props {
  history: History;
  location: Location;
  customer: AppCustomer;
  onLogoutClick: () => void;
}

interface State {
  //TODO: replace any type after making server workings
  sliderImages: any[];
  selectedImageId: string;
  productTypes: ProductType[];
  isConfirmationModalOpen: boolean;
  imageToDeleteIndex: number;
}

export class CustomerPhotoGallery extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sliderImages: [],
      selectedImageId: undefined,
      productTypes: [],
      isConfirmationModalOpen: false,
      imageToDeleteIndex: undefined,
    };
  }

  componentDidMount() {
    const { customer, onLogoutClick } = this.props;
    const { sessionKey } = customer;
    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    getCustomerBasket(customer).then(basket => {
      const { galleryImages } = basket;

      if (galleryImages.length === 0) {
        this.props.history.push('/customer');
      } else {
        getImageProductTypes(galleryImages[0].imageId).then(res => {
          this.setState({
            sliderImages: galleryImages,
            selectedImageId: galleryImages.length === 1 ? galleryImages[0].imageId : undefined,
            productTypes: res,
          });
        });
      }
    });
  }

  onSliderImageClick = (selectedImage: any) => {
    getImageProductTypes(selectedImage.imageId).then(productTypes => {
      this.setState({
        selectedImageId: selectedImage.imageId,
        productTypes: productTypes,
      });
    });
  };

  onProductTypeClick = (productTypeId: any) => {
    const { selectedImageId } = this.state;
    const { history, customer } = this.props;

    if (typeof productTypeId === 'undefined') {
      addSelectedImageToCustomerBasket(customer, selectedImageId).then(() =>
        history.push('/customer/photoProductMatch')
      );
    } else {
      const addSelectedImagePromise = addSelectedImageToCustomerBasket(customer, selectedImageId);
      const addSelectedProductTypePromise = addSelectedProductTypeToCustomerBasket(customer, productTypeId);
      const promises = [addSelectedImagePromise, addSelectedProductTypePromise];
      Promise.all(promises).then(res => {
        history.push('/customer/photoProductMatch');
      });
    }
  };

  showConfirmationModal = index => {
    this.setState({
      isConfirmationModalOpen: true,
      imageToDeleteIndex: index,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isConfirmationModalOpen}>
        <ConfirmationModal
          title={'The photo will be removed'}
          text={'Please confirm you would like to remove the photo from you gallery.'}
          onOk={this.onDeleteImageClick}
          onCancel={this.hideConfirmationModal}
        />
      </SimpleModal>
    );
  }

  setImageToDeleteIndex = index => {
    this.setState({
      imageToDeleteIndex: index,
    });
  };

  onDeleteImageClick = () => {
    const { customer } = this.props;
    const { sliderImages, imageToDeleteIndex } = this.state;

    const updatedSliderImages = [...sliderImages];
    updatedSliderImages.splice(imageToDeleteIndex, 1);

    const imageToDelete = sliderImages[imageToDeleteIndex];

    deleteImageFromGalleryInCustomerBasket(customer, [imageToDelete.imageId]).then(basket => {
      if (updatedSliderImages.length === 0) {
        this.props.history.push('/customer');
      } else {
        this.setState({
          sliderImages: updatedSliderImages,
          selectedImageId: updatedSliderImages[0].imageId,
          isConfirmationModalOpen: false,
        });
      }
    });
  };

  render() {
    const { selectedImageId, sliderImages, productTypes } = this.state;
    const isSelectedImageIdExist = typeof selectedImageId !== 'undefined';

    return (
      <div className="bPhotoGalleryWrapper">
        {/*Place for render modal windows*/}
        {this.renderConfirmationModal()}
        <div className="container">
          <div className="row">
            <div className={'col-xl-12'}>
              <div className="bPhotoGallery">
                <div className="row">
                  <div className={'col-lg-3 col-xl-2'}>
                    <PhotoSlider
                      images={sliderImages}
                      onImageClick={this.onSliderImageClick}
                      selectedImageId={selectedImageId}
                      showConfirmationModal={this.showConfirmationModal}
                      isDeleteIconVisible={true}
                    />
                  </div>

                  <div className={'col-lg-9 col-xl-10'}>
                    {!isSelectedImageIdExist ? (
                      <div className="ePhotoGalleryTitle">
                        <img
                          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmc
                        iIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOn
                        N2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iM
                        CIgdmlld0JveD0iMCAwIDQ4IDQ4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4
                        bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgtMSwwLDAsMSw0Ny45OTk
                        5OTE0MTY5MzExNSwwKSI+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBpZD0iWE1MSU
                        RfMTQ1Ml8iIGQ9Im0xLjc4MSAyNS45OSAzMi45ODUgMy42NjItMi44OCA0LjkyNWMtLjg3NCAxLjUgMS4wMTMgM
                        y4wNyAyLjMyOSAxLjk0M2wxMy4yNDMtMTEuMzVjLjcxNS0uNjE0LjcxNS0xLjcyMiAwLTIuMzM2bC0xMy4yNDMt
                        MTEuMzVjLTEuMzE3LTEuMTMzLTMuMjAzLjQ0My0yLjMyOSAxLjkzN2wyLjg3NCA0LjkzMS0zMi45NzkgMy42NTd
                        jLTIuMzY2LjI1Ni0yLjM2OCAzLjcyNCAwIDMuOTgxeiIgZmlsbD0iIzdjMDAzZSIgZGF0YS1vcmlnaW5hbD0iIz
                        g4YzA1NyIgY2xhc3M9IiI+PC9wYXRoPjwvZz48L3N2Zz4="
                          height="70"
                        />
                        <h2 className="ePhotoGalleryTitleText">
                          Please select a photo from the list to view available products.
                        </h2>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-lg-6 col-xl-6" key={'photoGallery_productTypes_all'}>
                          <div
                            className="ePhotoGalleryProductType"
                            onClick={() => {
                              this.onProductTypeClick(undefined);
                            }}
                          >
                            <div className="ePhotoGalleryProductTypeTitle text-center">ALL</div>
                          </div>
                        </div>

                        {productTypes.map(productType => (
                          <div className="col-lg-6 col-xl-6" key={productType.id}>
                            <div
                              className="ePhotoGalleryProductType"
                              onClick={() => {
                                this.onProductTypeClick(productType.id);
                              }}
                            >
                              <div className="ePhotoGalleryProductTypeTitle text-center">{productType.name}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
