import * as React from 'react';
import { Component } from 'react';
import { History } from 'history';
import { Profile } from '../../../../../../models/profile';
import * as propz from 'propz';
import {
  changeEmail,
  changePhone,
  resendEmailCode,
  resendPhoneCode,
  getCustomerProfile,
  getCustomerTokensExist,
} from '../../../../../../services/customer/customer';
import { verifyEmail, verifyPhone } from '../../../../../../services/customer/confirm';
import { Button } from '../../../../../../components/Button/Button';
import { AppCustomer } from '../../../../AppOrders';
import { Loader } from '../../../../../../components/Loader/Loader';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { ProfileEmailChangeForm } from './ProfileEmailChangeForm/ProfileEmailChangeForm';
import { ProfilePhoneChangeForm } from './ProfilePhoneChangeForm/ProfilePhoneChangeForm';
import { NOTIFICATION_CHANNELS_TYPE } from '../../../../../../consts/notificationChannels';
import { createCustomerProfileNotificationChannel } from '../../../../../../services/superadmin/customers';
import {getPhoneWithoutFirstZero} from "../../../../../../helpers/phone";

interface Props {
  customer: AppCustomer;
  profile: Profile;
  updatePhoneInProfile: (phone: string) => void;
  updateEmailInProfile: (email: string) => void;
  updateIsCustomerVerified: () => void;
  history: History;
}

interface State {
  customerProfile: any;
  emailToken: string;
  phoneToken: string;
  email: string;
  phone: string;
  tokensStatus: any;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  isEmailChangeModalOpen: boolean;
  isPhoneChangeModalOpen: boolean;
  isSuccessModalOpen: boolean;
  isLoading: boolean;
}

export class CustomerVerificationTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      customerProfile: undefined,
      emailToken: '',
      phoneToken: '',
      email: '',
      phone: '',
      tokensStatus: undefined,
      isPhoneVerified: undefined,
      isEmailVerified: undefined,
      isEmailChangeModalOpen: false,
      isPhoneChangeModalOpen: false,
      isSuccessModalOpen: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  setItems() {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    getCustomerTokensExist(customer)
      .then(tokensStatus => {
        this.setState({
          tokensStatus: tokensStatus,
        });
        return tokensStatus;
      })
      .then(tokensStatus => {
        getCustomerProfile(customer).then(profile => {
          const { verification, email, phone } = profile;
          const { status } = verification;
          const { sms: isPhoneVerified, email: isEmailVerified } = status;
          switch (true) {
            case isPhoneVerified && isEmailVerified:
              this.setState({
                customerProfile: profile,
                isPhoneVerified: isPhoneVerified,
                isEmailVerified: isEmailVerified,
                email,
                phone,
                isLoading: false,
              });
              break;
            case isPhoneVerified:
              this.setState({
                customerProfile: profile,
                isPhoneVerified: isPhoneVerified,
                email,
                phone,
                isLoading: false,
              });
              break;
            case isEmailVerified:
              this.setState({
                customerProfile: profile,
                isEmailVerified: isEmailVerified,
                email,
                phone,
                isLoading: false,
              });
              break;
            default:
              this.setState({
                customerProfile: profile,
                email,
                phone,
                isLoading: false,
              });
          }
        });
      });
  }

  onEmailTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ emailToken: value });
  };

  onPhoneTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phoneToken: value });
  };

  onVerifyEmailClick = () => {
    const { customer } = this.props;
    const { emailToken } = this.state;

    this.setState({
      isLoading: true,
    });

    verifyEmail(customer, emailToken).then(res => {
      const { confirmed } = res;
      this.setState({
        isEmailVerified: confirmed,
        isLoading: false,
      });
    });
  };

  onVerifyPhoneClick = () => {
    const { customer } = this.props;
    const { phoneToken } = this.state;

    this.setState({
      isLoading: true,
    });

    verifyPhone(customer, phoneToken).then(res => {
      const { confirmed } = res;
      this.setState({
        isPhoneVerified: confirmed,
        isLoading: false,
      });
    });
  };

  onChangeEmailClick = () => {
    this.setState({
      isEmailChangeModalOpen: true,
    });
  };

  onChangePhoneClick = () => {
    this.setState({
      isPhoneChangeModalOpen: true,
    });
  };

  onPhoneChangeFormCancelClick = () => {
    this.setState({
      isPhoneChangeModalOpen: false,
    });
  };

  onEmailChangeFormCancelClick = () => {
    this.setState({
      isEmailChangeModalOpen: false,
    });
  };

  onResendEmailCodeClick = () => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    resendEmailCode(customer).then(res => {
      this.setState({
        isLoading: false,
        isSuccessModalOpen: true,
      });
      this.setItems();
    });
  };

  onSendEmailCodeClick = () => {
    const { customer } = this.props;
    const { customerProfile } = this.state;

    this.setState({
      isLoading: true,
    });

    const data = {
      type: NOTIFICATION_CHANNELS_TYPE.EMAIL,
    };
    const emailChannel = customerProfile.notificationChannels.find(
      channel => channel.type === NOTIFICATION_CHANNELS_TYPE.EMAIL
    );

    if (typeof emailChannel === 'undefined') {
      createCustomerProfileNotificationChannel(customer, data).then(res => {
        return resendEmailCode(customer).then(res => {
          this.setState({
            isSuccessModalOpen: true,
          });
          this.setItems();
        });
      });
    } else {
      resendEmailCode(customer).then(res => {
        this.setState({
          isSuccessModalOpen: true,
        });
        this.setItems();
      });
    }
  };

  onResendPhoneCodeClick = () => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    resendPhoneCode(customer).then(res => {
      this.setState({
        isLoading: false,
        isSuccessModalOpen: true,
      });
      this.setItems();
    });
  };

  onSendPhoneCodeClick = () => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    resendPhoneCode(customer).then(res => {
      this.setState({
        isSuccessModalOpen: true,
      });
      this.setItems();
    });
  };

  onEmailChangeFormSubmit = data => {
    const { customer, updateEmailInProfile } = this.props;
    this.setState({
      isLoading: true,
    });

    changeEmail(customer, data.email).then(res => {
      this.setState({
        isLoading: false,
        isEmailChangeModalOpen: false,
        isSuccessModalOpen: true,
        email: data.email,
      });
      updateEmailInProfile(data.email);
      this.setItems();
    });
  };

  onPhoneChangeFormSubmit = data => {
    const { customer, updatePhoneInProfile } = this.props;
    this.setState({
      isLoading: true,
    });

    const { phone, phoneCode } = data;
    const phoneWithoutFirstZero = getPhoneWithoutFirstZero(phone);

    const phoneToPost = `${phoneCode}${phoneWithoutFirstZero}`;

    changePhone(customer, phoneToPost).then(res => {
      this.setState({
        isPhoneChangeModalOpen: false,
        isSuccessModalOpen: true,
      });
      updatePhoneInProfile(phoneToPost);
      this.setItems();
    });
  };

  renderEmailChangeModal() {
    const { isEmailChangeModalOpen, email } = this.state;
    return (
      <SimpleModal isOpen={isEmailChangeModalOpen}>
        <ProfileEmailChangeForm
          onCancel={this.onEmailChangeFormCancelClick}
          onSubmit={this.onEmailChangeFormSubmit}
          email={email}
        />
      </SimpleModal>
    );
  }

  renderPhoneChangeModal() {
    const { isPhoneChangeModalOpen, phone } = this.state;
    const { profile } = this.props;
    return (
      <SimpleModal isOpen={isPhoneChangeModalOpen}>
        <ProfilePhoneChangeForm
          onCancel={this.onPhoneChangeFormCancelClick}
          onSubmit={this.onPhoneChangeFormSubmit}
          profile={profile}
        />
      </SimpleModal>
    );
  }

  onOkSuccessModalClick = () => {
    this.setState({
      isSuccessModalOpen: false,
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Success'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkSuccessModalClick}
      />
    );
  }

  onStartClick = () => {
    const { updateIsCustomerVerified, history } = this.props;
    updateIsCustomerVerified();
    history.push('/customer');
  };

  render() {
    const {
      emailToken,
      phoneToken,
      isEmailVerified,
      isPhoneVerified,
      isLoading,
      isEmailChangeModalOpen,
      isPhoneChangeModalOpen,
      isSuccessModalOpen,
      email,
      phone,
      tokensStatus,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isEmailChangeModalOpen || isPhoneChangeModalOpen || isSuccessModalOpen ? 'mt-5 modal-open mb-5' : 'mt-5 mb-5';

    if (isLoading) {
      return <Loader />;
    }

    const isEmailTokenExist = propz.get(tokensStatus, ['isEmailTokenExist'], false);
    const isPhoneTokenExist = propz.get(tokensStatus, ['isPhoneTokenExist'], false);
    const isPhoneExist = typeof phone !== 'undefined' && phone !== '';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEmailChangeModal()}
        {this.renderPhoneChangeModal()}
        {this.renderSuccessModal()}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3">
              {(!isEmailTokenExist || !isPhoneTokenExist) && (
                <label>We need to verify your email and phone number before you start using our application.</label>
              )}
              <div className={'mt-3 mb-3'}>
                <label>
                  <strong>Email verification code</strong>
                </label>
                {isEmailTokenExist ? (
                  <>
                    <p>We will send your verification code to: {email}</p>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Enter code here"
                      value={emailToken}
                      onChange={this.onEmailTokenChange}
                      disabled={isEmailVerified}
                    />
                    {isEmailVerified === false && <div className="alert alert-danger">Bad code</div>}
                    {isEmailVerified && <div className="alert alert-success">Verified</div>}
                    <Button
                      disabled={isEmailVerified}
                      text={'Verify'}
                      onClick={this.onVerifyEmailClick}
                      customClass={'eCustomerVerificationTabButton'}
                    />
                    <Button
                      disabled={isEmailVerified}
                      text={'Change email'}
                      onClick={this.onChangeEmailClick}
                      customClass={'btn-light ml-3 eCustomerVerificationTabButton'}
                    />
                    <Button
                      disabled={isEmailVerified}
                      text={'Resend email code'}
                      onClick={this.onResendEmailCodeClick}
                      customClass={'btn-light ml-3 eCustomerVerificationTabButton'}
                    />
                  </>
                ) : (
                  <>
                    <label>We will send your verification code to: {email}</label>
                    <Button
                      text={'Send verification code'}
                      onClick={this.onSendEmailCodeClick}
                      customClass={'eCustomerVerificationTabButton'}
                    />
                    <Button
                      disabled={isEmailVerified}
                      text={'Change email'}
                      onClick={this.onChangeEmailClick}
                      customClass={'btn-light ml-3 eCustomerVerificationTabButton'}
                    />
                  </>
                )}
              </div>
              <div className={'mt-3 mb-3'}>
                <label>
                  <strong>Mobile phone verification code</strong>
                </label>
                {isPhoneExist ? (
                  <p>We will send your verification code to: {phone}</p>
                ) : (
                  <p>Please enter your mobile phone number and click "Send"</p>
                )}
                {isPhoneTokenExist ? (
                  <>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Enter code here"
                      value={phoneToken}
                      onChange={this.onPhoneTokenChange}
                      disabled={isPhoneVerified}
                    />
                    {isPhoneVerified === false && <div className="alert alert-danger">Bad code</div>}
                    {isPhoneVerified && <div className="alert alert-success">Verified</div>}
                    <Button
                      disabled={isPhoneVerified}
                      text={'Verify'}
                      onClick={this.onVerifyPhoneClick}
                      customClass={'eCustomerVerificationTabButton'}
                    />
                    <Button
                      disabled={isPhoneVerified}
                      text={isPhoneExist ? 'Change phone' : 'Add phone'}
                      onClick={this.onChangePhoneClick}
                      customClass={'btn-light ml-3 eCustomerVerificationTabButton'}
                    />
                    <Button
                      disabled={isPhoneVerified}
                      text={'Resend phone code'}
                      onClick={this.onResendPhoneCodeClick}
                      customClass={'btn-light ml-3 eCustomerVerificationTabButton'}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      disabled={!isPhoneExist}
                      text={'Send verification code'}
                      onClick={this.onSendPhoneCodeClick}
                      customClass={'eCustomerVerificationTabButton'}
                    />
                    <Button
                      text={isPhoneExist ? 'Change phone' : 'Add phone'}
                      onClick={this.onChangePhoneClick}
                      customClass={'btn-light ml-3 eCustomerVerificationTabButton'}
                    />
                  </>
                )}
              </div>
              {isEmailVerified && isPhoneVerified && (
                <Button text={'Start'} onClick={this.onStartClick} customClass={'eCustomerVerificationTabButton'} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
