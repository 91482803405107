export const ACTION_DESCRIPTOR_TYPE = {
  CUSTOM: 'CUSTOM',
  CHANNEL_TEST: 'CHANNEL_TEST',
  JOB_IS_DONE: 'JOB_IS_DONE',
  INTEGRATION_IS_DONE: 'INTEGRATION_IS_DONE',
  FREE: 'FREE',
};

export const ACTION_DESCRIPTOR_TYPE_SERVER_TO_CLIENT_MAPPING = {
  CUSTOM: 'Custom',
  CHANNEL_TEST: 'Channel test',
  JOB_IS_DONE: 'Job is done',
  INTEGRATION_IS_DONE: 'Images have been uploaded',
  FREE: 'Free',
};
