import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { SLIDER_TYPES, SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/slider';
import { Slider } from '../../../../../../../models/slider';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  sliders?: Slider;
}

export function SliderForm(props: Props) {
  const { onSubmit, onCancel, sliders } = props;

  const isSliderExist = typeof sliders !== 'undefined';

  const sliderForm = {
    name: isSliderExist ? sliders.name : '',
    delay: isSliderExist ? sliders.delay : 6,
    type: isSliderExist ? sliders.type : '',
  };

  const SliderSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    delay: Yup.number().required('Required'),
    type: Yup.string().required('Required'),
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={sliderForm}
            validationSchema={SliderSchema}
            onSubmit={values => {
              onSubmit(values);
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Slider name</label>
                    <Field name="name" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="name" />
                  </div>
                  <div className="form-group">
                    <label>Delay</label>
                    <Field name="delay">
                      {({ field }) => (
                        <input
                          type="number"
                          className="form-control"
                          max="20"
                          min="0"
                          onChange={event => {
                            setFieldValue(`delay`, event.target.value);
                          }}
                          value={values.delay}
                        />
                      )}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="delay" />
                  </div>

                  <div className="form-group">
                    <label>Type</label>
                    <Field
                      name={`type`}
                      component="select"
                      className="form-control mb-3"
                      onChange={event => {
                        const sliderType = event.target.value;
                        setFieldValue(`type`, sliderType);
                      }}
                    >
                      <option value={''} />
                      <option value={SLIDER_TYPES.MAIN_SLIDER}>
                        {SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING.MAIN_SLIDER}
                      </option>
                      <option value={SLIDER_TYPES.OFFER_OF_MONTH}>
                        {SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING.OFFER_OF_MONTH}
                      </option>
                      <option value={SLIDER_TYPES.PROMOTED_PRODUCTS}>
                        {SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING.PROMOTED_PRODUCTS}
                      </option>
                      <option value={SLIDER_TYPES.PRODUCTS}>{SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING.PRODUCTS}</option>
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="type" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
