import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import {
  getName,
  searchFunctionPackages,
  searchFunctionPriceSets,
} from 'Src/helpers/autocomplete';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  user: AppUser;
}

interface State {
  packageId: string;
}

const JobImageSchema = Yup.object().shape({
  packageItem: Yup.object().shape({
    id: Yup.string().required('Required'),
  }),
  priceSet: Yup.object().shape({
    id: Yup.string().required('Required'),
  }),
});

const jobImageForm = {
  packageItem: {
    id: '',
  },
  priceSet: {
    id: '',
  },
};

export class JobImagePriceSetForm extends React.Component<Props, State> {
  getPackages = (text: string) => {
    const { user } = this.props;
    return searchFunctionPackages(user, text);
  };

  getPriceSets = (values, text: string) => {
    const { user } = this.props;
    const { packageId } = this.state;
    return searchFunctionPriceSets(user, packageId, text);
  };

  render() {
    const { onSubmit, onCancel } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={jobImageForm}
              validationSchema={JobImageSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                <Form>
                  <div className="form-group form-check">
                    <div>Package</div>
                    <Field name="packageItem.id">
                      {() => (
                        <Autocomplete
                          searchFunction={this.getPackages}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.packageItem}
                          onSelect={packageItem => {
                            setFieldValue('packageItem', packageItem);
                            setFieldValue('priceSet', { id: '' });
                            //hack for rerender
                            this.setState({
                              packageId: packageItem.id,
                            });
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="alert alert-danger"
                      name="packageItem.id"
                    />

                    <div>Price set</div>
                    <Field name="priceSet.id">
                      {() => (
                        <Autocomplete
                          searchFunction={text =>
                            this.getPriceSets(values, text)
                          }
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.priceSet}
                          onSelect={priceSet => {
                            setFieldValue('priceSet', priceSet);
                          }}
                          disabled={!values.packageItem.id}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="alert alert-danger"
                      name="priceSet.id"
                    />

                    <Button
                      onClick={onCancel}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
