import { AppUser } from '../../views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from '../../consts/table';

export function getSchoolForms(user: AppUser, schoolId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/forms`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolForms(user: AppUser, schoolId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/forms`, config).then(response => {
    return response.data;
  });
}

export function getSchoolFormsCount(user: AppUser, schoolId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/forms/count`, config).then(response => {
    return response.data;
  });
}

export function addSchoolForm(user: AppUser, schoolId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schools/${schoolId}/forms`, data, config).then(response => {
    return response.data;
  });
}

export function updateSchoolForm(user: AppUser, schoolId: string, formId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/schools/${schoolId}/forms/${formId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteSchoolForm(user: AppUser, schoolId: string, formId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/schools/${schoolId}/forms/${formId}`, config).then(response => {
    return response.data;
  });
}

export function checkFormName(user: AppUser, schoolId: string, formName: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/admin/schools/${schoolId}/forms/check`, { formName: formName }, config)
    .then(response => {
      return response.data;
    });
}
