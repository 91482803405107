import * as React from 'react';
import { AppUser, MenuItemsEnabled } from '../App';
import { Switch, Route } from 'react-router';
import { getRoutes } from '../../../routing/routing';
import { TopNavigation } from './TopNavigation/TopNavigation';
import { Sidebar } from './Sidebar/Sidebar';
import { History } from 'history';

interface Props {
  user: AppUser;
  menuItemsEnabled: MenuItemsEnabled;
  onLogoutClick: () => void;
  history: History;
}

export class GenericView extends React.Component<Props> {
  render() {
    const { user, onLogoutClick } = this.props;
    const routes = getRoutes(user);

    return (
      <div>
        <TopNavigation onLogoutClick={onLogoutClick} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <Sidebar {...this.props} />
            </div>
            <div className="col-md-10">
              <Switch>
                {routes.map((route, i) => (
                  <Route
                    exact={route.exact}
                    key={`route_${i}`}
                    path={route.path}
                    render={routeProps => <route.component {...this.props} />}
                  />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
