import axios from 'axios';
import { AppCustomer } from '../../views/AppOrders/AppOrders';

export function addCustomerOrderImages(customer: AppCustomer, orderId: string, data) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/orders/${orderId}/images`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getCustomerOrderImages(customer: AppCustomer, orderId: string, limit: number = 50) {
  const { sessionKey: key, customerId } = customer;

  const filter = JSON.stringify({
    limit,
  });

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios
    .get(`${window.apiBase}/customer/${customerId}/orders/${orderId}/images`, config)
    .then(response => response.data);
}

export function getCustomerOrderImageTickets(customer: AppCustomer, orderId: string) {
  const { sessionKey: key, customerId } = customer;
  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/orders/${orderId}/tickets`, config).then(response => {
    return response.data;
  });
}
