export function isOneTimeCodeContainsUsernameAndPassword(oneTimeCode, username, password) {
  const isEqualWithOneTimeCode = username === oneTimeCode.username && password === oneTimeCode.password;

  if (isEqualWithOneTimeCode) {
    return true;
  }

  const { additionalCodes } = oneTimeCode;
  const isAdditionalCodesExist = Array.isArray(additionalCodes);

  if (isAdditionalCodesExist) {
    const isEqualWithAdditionalCode = additionalCodes.some(
      code => code.username === username && code.password === password
    );

    if (isEqualWithAdditionalCode) {
      return true;
    }
  }
  return false;
}
