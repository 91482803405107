import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import * as Promise from 'bluebird';
import { PhotoSlider } from '../CommonComponents/PhotoSlider/PhotoSlider';
import { ProductTypeFilter } from '../CommonComponents/ProductTypeFilter/ProductTypeFilter';
import { getImageProductsByProductType, getAllImageProducts } from '../../../services/public/images';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { AppOneTimeCode } from '../../AppOrders/AppOrders';
import {
  getBasket,
  addProductToBasket,
  addSelectedImageToBasket,
  addSelectedProductToBasket,
} from '../../../services/public/basket';
import { getPublicOffers } from '../../../services/public/offers';
import './PhotoProductMatch.scss';

interface Props {
  history: History;
  location: Location;
  oneTimeCode: AppOneTimeCode;
  setBasketCount: (count: number) => void;
}

interface State {
  //TODO: replace any type after making server workings
  sliderImages: any;
  selectedImageId: string;
  selectedProductTypeId: string;
  //TODO: replace any type after making server workings
  products: any[];
  isModalOpen: boolean;
}

export class PhotoProductMatch extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedImageId: undefined,
      selectedProductTypeId: undefined,
      products: [],
      sliderImages: [],
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { oneTimeCode, setBasketCount } = this.props;
    getBasket(oneTimeCode).then(basket => {
      setBasketCount(basket.items.length);

      const { selectedImageId, selectedProductTypeId, galleryImages } = basket;

      const getProductsPromise =
        typeof selectedProductTypeId === 'undefined'
          ? getAllImageProducts(selectedImageId)
          : getImageProductsByProductType(selectedImageId, selectedProductTypeId);
      getProductsPromise.then(products => {
        this.setState({
          sliderImages: galleryImages,
          selectedImageId: selectedImageId,
          selectedProductTypeId: selectedProductTypeId,
          products: products,
        });
      });
    });
  }

  onSliderImageClick = (image: any) => {
    const { imageId } = image;
    getAllImageProducts(imageId).then(products => {
      this.setState({
        selectedImageId: imageId,
        products: products,
        selectedProductTypeId: undefined,
      });
    });
  };

  onProductTypeFilterClick = (productTypeId: string) => {
    const { selectedImageId } = this.state;

    const getProductsPromise =
      typeof productTypeId === 'undefined'
        ? getAllImageProducts(selectedImageId)
        : getImageProductsByProductType(selectedImageId, productTypeId);
    getProductsPromise.then(products => {
      this.setState({
        products: products,
        selectedProductTypeId: productTypeId,
      });
    });
  };

  onAddButtonClick = (product: any) => {
    const { selectedImageId } = this.state;
    const { oneTimeCode, setBasketCount } = this.props;
    const { id: productId } = product;

    const addSelectedImagePromise = addSelectedImageToBasket(oneTimeCode, selectedImageId);
    const addSelectedProductPromise = addSelectedProductToBasket(oneTimeCode, productId);
    const addProductPromise = addProductToBasket(oneTimeCode, { imageId: selectedImageId, productId });
    const getPublicOffersPromise = getPublicOffers(selectedImageId, productId);

    const promises = [addSelectedImagePromise, addSelectedProductPromise, addProductPromise, getPublicOffersPromise];

    Promise.all(promises).then(([res1, res2, basket, specialOffers]) => {
      setBasketCount(basket.items.length);
      if (specialOffers.length !== 0) {
        this.props.history.push('/offers');
      } else {
        this.setState({
          isModalOpen: true,
        });
      }
    });
  };

  onCheckoutClick = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.history.push('/basket');
  };

  onContinueClick = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  renderModal() {
    const { isModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isModalOpen}
        body={`WOULD YOU LIKE TO PROCEED TO CHECKOUT OR CONTINUE SHOPPING?`}
        buttonText={'CHECKOUT'}
        buttonCancelText={'CONTINUE SHOPPING'}
        onButtonClick={this.onCheckoutClick}
        onCloseClick={this.onContinueClick}
        customClass={`bPhotoProductMatchModal`}
      />
    );
  }

  render() {
    const { selectedImageId, products, sliderImages, selectedProductTypeId } = this.state;
    const isSelectedImageExist = typeof selectedImageId !== 'undefined';

    return (
      <div className="bPhotoProductMatchWrapper">
        {this.renderModal()}
        <div className="container">
          <div className="row">
            <div className={'col-lg-3 col-xl-2'}>
              <PhotoSlider
                images={sliderImages}
                onImageClick={this.onSliderImageClick}
                selectedImageId={selectedImageId}
                isDeleteIconVisible={false}
              />
              {isSelectedImageExist && (
                <ProductTypeFilter
                  selectedImageId={selectedImageId}
                  onClick={this.onProductTypeFilterClick}
                  selectedProductTypeId={selectedProductTypeId}
                />
              )}
            </div>
            <div className={'col-lg-9 col-xl-10'}>
              <div className="row">
                {products.length === 0
                  ? null
                  : products.map(product => (
                      <div className={'col-lg-6 col-xl-6'} key={product.id}>
                        <div className="bProduct">
                          <div className="eProductImageWrapper">
                            <span className="eProductImageHelper" />
                            <img
                              className="eProductImage"
                              src={`${window.apiFile}/storage/images/${product.ticket}`}
                              alt="Image of product"
                            />
                          </div>
                          <div className="eProductFooter">
                            <div className="eProductFooterLeft">
                              <div className="eProductTitle">{product.name}</div>
                              <div className="eProductDescription">{product.description}</div>
                            </div>
                            <div className="eProductFooterRight">
                              <div className="eProductPrice">{`£${product.pricing.price.toFixed(2)}`}</div>
                              {/*<div className="eProductEdit">Edit Photo</div>*/}
                              <button className="eProductFooterButton" onClick={() => this.onAddButtonClick(product)}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
