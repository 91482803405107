import * as React from 'react';
import { Component } from 'react';
import * as Lazy from 'lazy.js';
import * as BPromise from 'bluebird';
import * as Moment from 'moment';
import * as propz from 'propz';
import { History, Location } from 'history';
import { Loader } from 'Src/components/Loader/Loader';
import { getCustomerTickets } from 'Src/services/customer/tickets';
import { AppCustomer } from '../../AppOrders';
import { FilterIcons } from './FilterIcons/FilterIcons';
import { CustomerAccessCode } from './CustomerAccessCode/CustomerAccessCode';
import { CustomerPhotoTypeFilter } from './CustomerPhotoTypeFilter/CustomerPhotoTypeFilter';
import { CustomerYearsFilter } from './CustomerYearsFilter/CustomerYearsFilter';
import { checkCustomerSession } from '../../../../services/customer/customer';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../consts/common';
import { PAY360_RESULT, WORLD_PAY_RESULT } from '../../../../consts/payment';

import { LIMIT_DOWNLOAD_IMAGES } from '../../../../consts/images';
import {
  getCustomerOrder,
  updateCustomerOrderStatusToCanceled,
  updateCustomerOrderStatusToPaid,
} from '../../../../services/customer/orders';
import { getTicketsCount } from '../../../../services/customer/tickets';
import { getFromHistory } from '../../../../helpers/history';
import { ImageTicketsCustomerView } from '../../../../components/ImageTicketsCustomerView/ImageTicketsCustomerView';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { getOrCreateCustomerBasket, addGalleryToCustomerBasket } from '../../../../services/customer/basket';
import {
  getPhotoTypeDateFilter,
  getPhotoTypeDateClassFilter,
  getPhotoTypeYearFilter,
} from '../../../../helpers/filter';
import { CountDownClock } from '../../../../components/CountDownClock/CountDownClock';
import { getCustomerJobs } from '../../../../services/customer/images';
import { sortImagesByPhotoType } from '../../../../helpers/images';
import './CustomerView.scss';
import { ORDER_STATUS } from '../../../../consts/order';
import { ConfirmationModal } from '../../CommonComponents/PhotoSlider/ConfirmationModal/ConfirmationModal';
import { getOrderBySessionId } from '../../../../services/public/order';
import {
  getIsSignFromOneTimeCodeWaitingFromStorage,
  clearIsSignFromOneTimeCodeWaitingInStorage,
} from 'Src/helpers/sessionStorage';
import { getCustomerOrderImages } from '../../../../services/customer/orderImages';
import { Order } from '../../../../models/orders';
import { CustomerProfileOrderInformation } from '../CustomerProfile/Modals/CustomerProfileOrderInformation';
import { OrderImage } from '../../../../models/orderImages';

interface Props {
  customer: AppCustomer;
  history: History;
  location: Location;
  selectedTicketIds: any[];
  setBasketCount: (count: number) => void;
  onLogoutClick: () => void;
  onGalleryImageClick: () => void;
  setCustomerBasketId: (basketId: string) => void;
  clearSelectedTicketIds: () => void;
  clearBasketId: () => void;
  setDelivery: (delivery: any) => void;
}

interface State {
  isLoading: boolean;
  tickets: any;
  isWorldPayPaymentSuccess: boolean;
  isPay360PaymentSuccess: boolean;
  isPaymentCanceled: boolean;
  isPaymentTimeout: boolean;
  isPaymentProcess: boolean;
  galleryImages: any[];
  selectedPhotoType: string;
  selectedPhotoYear: string;
  dates: string[];
  dateTicketsCount: any;
  dateContent: any;
  dateClassContent: any;
  isDateContentShow: any;
  isDateClassContentShow: any;
  dateClassParams: any;
  isShowCountDownClock: boolean;
  isNoSelectedImagesInfo: boolean;

  isAccessCodeAddedModalOpen: boolean;
  searchQuery: string;
  hasPhotographs: boolean;

  orderDetails: Order | null;
  orderImages: OrderImage[];
}

export class CustomerView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const isSignFromOneTimeCodeWaiting = getIsSignFromOneTimeCodeWaitingFromStorage();

    this.state = {
      isLoading: true,
      tickets: {},
      isPaymentCanceled: false,
      isPaymentTimeout: false,
      isWorldPayPaymentSuccess: false,
      isPay360PaymentSuccess: false,
      isPaymentProcess: false,
      galleryImages: [],
      selectedPhotoType: undefined,
      selectedPhotoYear: undefined,
      dates: [],
      dateTicketsCount: {},
      dateContent: {},
      dateClassContent: {},
      isDateContentShow: {},
      isDateClassContentShow: {},
      dateClassParams: {},
      isShowCountDownClock: true,
      isNoSelectedImagesInfo: false,

      isAccessCodeAddedModalOpen: isSignFromOneTimeCodeWaiting,
      searchQuery: '',
      hasPhotographs: false,

      orderDetails: null,
      orderImages: [],
    };
  }

  getOrderPromise() {
    const { customer, history } = this.props;

    const orderId = getFromHistory(history, 'orderId');
    const basketId = getFromHistory(history, 'basketId');
    const result = getFromHistory(history, 'result');

    switch (result) {
      case WORLD_PAY_RESULT.PAID:
        const data = {
          address1: getFromHistory(history, 'address1'),
          address2: getFromHistory(history, 'address2'),
          town: getFromHistory(history, 'town'),
          region: getFromHistory(history, 'region'),
          postcode: getFromHistory(history, 'postcode'),
          country: getFromHistory(history, 'country'),
          firstName: getFromHistory(history, 'firstName'),
          phone: getFromHistory(history, 'tel'),
          email: getFromHistory(history, 'email'),
        };
        return getCustomerOrder(customer, orderId).then(order => {
          return order.orderStatus === ORDER_STATUS.PAID
            ? BPromise.resolve({})
            : updateCustomerOrderStatusToPaid(customer, basketId, orderId, data);
        });
      case WORLD_PAY_RESULT.CANCELED:
        return getCustomerOrder(customer, orderId).then(order => {
          return order.orderStatus === ORDER_STATUS.CANCELED
            ? BPromise.resolve({})
            : updateCustomerOrderStatusToCanceled(customer, basketId, orderId);
        });
      default:
        console.error('Can not find result');
        return BPromise.resolve(false);
      //return BPromise.resolve({});
    }
  }

  componentDidMount() {
    const { customer, setBasketCount, onLogoutClick, setCustomerBasketId, clearSelectedTicketIds } = this.props;
    const { sessionKey } = customer;
    const { history } = this.props;
    const storedOrderId = sessionStorage.getItem('orderId') || localStorage.getItem('orderId');

    this.filterDatesBasedOnSearch();

    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    clearSelectedTicketIds();

    this.setState({
      isLoading: true,
    });

    const orderId = getFromHistory(history, 'orderId');
    const isOrderIdExist = typeof orderId !== 'undefined';
    const orderPromise = isOrderIdExist ? this.getOrderPromise() : BPromise.resolve(true);

    orderPromise
      .then(_order => {
        return getOrCreateCustomerBasket(customer);
      })
      .then(basket => {
        const { id, items, galleryImages } = basket;

        setBasketCount(items.length);
        const status = getFromHistory(history, 'status');
        const isStatusExist = typeof status !== 'undefined';

        const result = isStatusExist ? status : getFromHistory(history, 'result');

        switch (result) {
          case WORLD_PAY_RESULT.PAID:
            setCustomerBasketId(id);
            this.setState({
              isWorldPayPaymentSuccess: true,
            });
            history.push('/customer');
            break;
          case PAY360_RESULT.PAID:
            setCustomerBasketId(id);
            this.setState({
              isPay360PaymentSuccess: true,
            });
            if (storedOrderId) {
              this.fetchOrderDetails(storedOrderId);
            }
            history.push('/customer');
            break;

          case WORLD_PAY_RESULT.CANCELED:
          case PAY360_RESULT.CANCELED:
            this.setState({
              isPaymentCanceled: true,
              galleryImages,
            });
            break;
          case PAY360_RESULT.TIMEOUT:
            this.setState({
              isPaymentTimeout: true,
              galleryImages,
            });
            break;
          default:
            this.setState({
              galleryImages,
            });
        }

        if (typeof customer.basketId === 'undefined') {
          setCustomerBasketId(id);
        }
      });
    this.setInitialDataStructure();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedPhotoType !== this.state.selectedPhotoType ||
      prevState.selectedPhotoYear !== this.state.selectedPhotoYear
    ) {
      this.setInitialDataStructure();
    }
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.filterDatesBasedOnSearch();
    }
  }

  setInitialDataStructure = () => {
    const { customer } = this.props;
    const { selectedPhotoType, selectedPhotoYear } = this.state;

    getCustomerJobs(customer, {}).then(allJobs => {
      const hasPhotographs = allJobs.length > 0;

      const filter = getPhotoTypeYearFilter(selectedPhotoType, selectedPhotoYear);
      getCustomerJobs(customer, filter).then(jobs => {
        getTicketsCount(customer, filter).then(countObj => {
          const { count } = countObj;

          const dates = Lazy(jobs)
            .map(job => Moment(job.jobDate).format('DD-MM-YYYY'))
            .uniq()
            .toArray();

          const dateContent = {};
          const dateClassContent = {};
          const isDateContentShow = {};
          const isDateClassContentShow = {};
          const dateClassParams = {};

          const jobsWithReducedDate = jobs.map(job => ({
            ...job,
            jobDate: Moment(job.jobDate).format('DD-MM-YYYY'),
          }));

          jobsWithReducedDate.forEach(job => {
            propz.set(isDateClassContentShow, [job.jobDate, job.classname], false);
            propz.set(dateClassContent, [job.jobDate, job.classname], []);
            propz.set(dateClassParams, [job.jobDate, job.classname, 'skip'], 0);
          });

          dates.forEach(date => {
            propz.set(isDateContentShow, [date], false);
            const jobsFiltered = jobsWithReducedDate.filter(job => job.jobDate === date);
            dateContent[date] = Lazy(jobsFiltered)
              .map(job => job.classname)
              .uniq()
              .toArray();
          });

          this.setState({
            dates,
            dateContent,
            dateClassContent,
            isDateContentShow,
            isDateClassContentShow,
            dateClassParams,
            isLoading: false,
            hasPhotographs,
          });

          if (count <= 10) {
            this.downloadAllDateClasses();
          }
        });
      });
    });
  };

  handleSearchChange = event => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.filterDatesBasedOnSearch();
    });
  };

  filterDatesBasedOnSearch = () => {
    const { searchQuery, dateClassContent, dateContent } = this.state;
    if (searchQuery === '') {
      this.setInitialDataStructure();
    } else {
      const filteredDates = [];
      for (const date in dateContent) {
        let hasMatchingClass = false;
        for (const classname of dateContent[date]) {
          if (classname.toLowerCase().includes(searchQuery.toLowerCase())) {
            hasMatchingClass = true;
            break;
          }
        }
        if (hasMatchingClass) {
          filteredDates.push(date);
        }
      }
      this.setState({ dates: filteredDates });
    }
  };

  downloadAllDateClasses = () => {
    const { customer } = this.props;
    const { isDateClassContentShow, isDateContentShow, dateClassContent, selectedPhotoType } = this.state;
    const isDateContentShowUpdated = { ...isDateContentShow };
    const isDateClassContentShowUpdated = { ...isDateClassContentShow };
    const dateClassContentUpdated = { ...dateClassContent };

    for (let date in isDateContentShowUpdated) {
      isDateContentShowUpdated[date] = true;
    }

    for (let date in isDateClassContentShow) {
      for (let classname in isDateClassContentShow[date]) {
        propz.set(isDateClassContentShowUpdated, [date, classname], true);

        const filter = getPhotoTypeDateClassFilter(date, classname, selectedPhotoType, 0);

        getCustomerTickets(customer, filter).then(tickets => {
          const ticketsSorted = sortImagesByPhotoType(tickets);
          propz.set(dateClassContentUpdated, [date, classname], ticketsSorted);

          this.setState({
            isDateContentShow: isDateContentShowUpdated,
            dateClassContent: dateClassContentUpdated,
            isDateClassContentShow: isDateClassContentShowUpdated,
          });
        });
      }
    }
  };

  onDateClick = date => {
    const { customer } = this.props;
    const { isDateContentShow, dateTicketsCount, isDateClassContentShow, selectedPhotoType } = this.state;
    const isDateContentShowUpdated = { ...isDateContentShow };
    const dateTicketsCountUpdated = { ...dateTicketsCount };

    if (isDateContentShow[date]) {
      isDateContentShowUpdated[date] = false;
      this.setState({
        isDateContentShow: isDateContentShowUpdated,
      });
    } else {
      isDateContentShowUpdated[date] = true;

      const filter = getPhotoTypeDateFilter(date, selectedPhotoType);

      if (typeof dateTicketsCount[date] === 'undefined') {
        getTicketsCount(customer, filter).then(({ count }) => {
          dateTicketsCountUpdated[date] = count;
          if (count <= 10) {
            for (let classname in isDateClassContentShow[date]) {
              this.onClassClick(date, classname);
            }
          }
        });
      } else {
        if (dateTicketsCount[date] <= 10) {
          for (let classname in isDateClassContentShow[date]) {
            this.onClassClick(date, classname);
          }
        }
      }
      this.setState({
        isDateContentShow: isDateContentShowUpdated,
        dateTicketsCount: dateTicketsCountUpdated,
      });
    }
  };

  onClassClick = (date, classname) => {
    const { customer } = this.props;
    const { isDateClassContentShow, dateClassContent, dateClassParams, selectedPhotoType } = this.state;

    const isDateClassContentShowUpdated = { ...isDateClassContentShow };
    const dateClassContentUpdated = { ...dateClassContent };
    const dateClassParamsUpdated = { ...dateClassParams };
    const isClassContentShow = isDateClassContentShow[date][classname];

    if (isClassContentShow) {
      propz.set(isDateClassContentShowUpdated, [date, classname], false);

      this.setState({
        isDateClassContentShow: isDateClassContentShowUpdated,
      });
    } else {
      propz.set(isDateClassContentShowUpdated, [date, classname], true);
      const isClassContentExist = dateClassContent[date][classname].length !== 0;

      if (isClassContentExist) {
        this.setState({
          isDateClassContentShow: isDateClassContentShowUpdated,
        });
      } else {
        const filter = getPhotoTypeDateClassFilter(date, classname, selectedPhotoType, 0);

        const getCustomerTicketsPromise = getCustomerTickets(customer, filter);
        const getTicketsCountPromise = getTicketsCount(customer, filter);
        const promises = [getCustomerTicketsPromise, getTicketsCountPromise];

        BPromise.all(promises).then(([tickets, countObj]) => {
          const ticketsSorted = sortImagesByPhotoType(tickets);
          const { count } = countObj;
          propz.set(dateClassParamsUpdated, [date, classname, 'ticketsCount'], count);
          propz.set(dateClassParamsUpdated, [date, classname, 'skip'], LIMIT_DOWNLOAD_IMAGES);
          propz.set(dateClassContentUpdated, [date, classname], ticketsSorted);

          this.setState({
            isDateClassContentShow: isDateClassContentShowUpdated,
            dateClassContent: dateClassContentUpdated,
            dateClassParams: dateClassParamsUpdated,
          });
        });
      }
    }
  };

  onLoadMoreClick = (date, classname) => {
    const { customer } = this.props;
    const { dateClassParams, dateClassContent, selectedPhotoType } = this.state;
    const dateClassContentUpdated = { ...dateClassContent };
    const dateClassParamsUpdated = { ...dateClassParams };

    const skip = propz.get(dateClassParams, [date, classname, 'skip']);
    const filter = getPhotoTypeDateClassFilter(date, classname, selectedPhotoType, skip);

    getCustomerTickets(customer, filter).then(tickets => {
      const ticketsSorted = sortImagesByPhotoType(tickets);

      const ticketsPrev = propz.get(dateClassContent, [date, classname]);
      propz.set(dateClassContentUpdated, [date, classname], [...ticketsPrev, ...ticketsSorted]);
      propz.set(dateClassParamsUpdated, [date, classname, 'skip'], skip + LIMIT_DOWNLOAD_IMAGES);

      this.setState({
        dateClassContent: dateClassContentUpdated,
        dateClassParams: dateClassParamsUpdated,
      });
    });
  };

  onNextButtonClick = () => {
    const { history, customer, selectedTicketIds } = this.props;
    const { galleryImages } = this.state;
    const isSelectedImagesEmpty = selectedTicketIds.length === 0;

    if (!isSelectedImagesEmpty) {
      const selectedTicketIdFiltered = selectedTicketIds.filter(id => {
        return galleryImages.every(image => image.imageId !== id);
      });

      const galleryImagesForUploadOld = galleryImages.map(image => ({
        imageId: image.imageId,
      }));

      const galleryImagesForUploadNew = selectedTicketIdFiltered.map(id => ({
        imageId: id,
      }));

      const data = [...galleryImagesForUploadOld, ...galleryImagesForUploadNew];

      addGalleryToCustomerBasket(customer, data).then(() => {
        history.push('/customer/photoGallery');
      });
    } else {
      this.setState({
        isNoSelectedImagesInfo: true,
      });
    }
  };

  onOkPaymentProcessModalClick = () => {
    this.setState({
      isPaymentProcess: false,
    });
  };

  renderPaymentProcessModal() {
    const { isPaymentProcess } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentProcess}
        title={'Info'}
        body={'Payment in process. Please check order status later.'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkPaymentProcessModalClick}
        customClass={`bCustomerModal`}
      />
    );
  }

  onOkWorldPayPaymentSuccessModalClick = () => {
    this.setState({
      isWorldPayPaymentSuccess: false,
    });
  };

  onOkPay360PaymentSuccessModalClick = () => {
    this.setState({
      isPay360PaymentSuccess: false,
    });
  };

  renderWorldPayPaymentSuccessModal() {
    const { isWorldPayPaymentSuccess } = this.state;

    return (
      <SimpleModal
        isOpen={isWorldPayPaymentSuccess}
        title={'Info'}
        body={'Payment success'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkWorldPayPaymentSuccessModalClick}
        customClass={`bCustomerModal`}
      />
    );
  }

  fetchOrderDetails(storedOrderId) {
    const { customer } = this.props;
    this.setState({ isLoading: true });

    let fetchedOrder = null;

    getCustomerOrder(customer, storedOrderId)
      .then(order => {
        if (!order || !order.id) {
          throw new Error('Order details not found or invalid.');
        }
        fetchedOrder = order;
        return getCustomerOrderImages(customer, storedOrderId, 50);
      })
      .then(images => {
        this.setState({
          orderDetails: fetchedOrder,
          orderImages: images,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false, orderDetails: null, orderImages: [] });
      });
  }

  renderPay360PaymentSuccessModal() {
    const { isPay360PaymentSuccess, orderDetails, orderImages } = this.state;

    return (
      <SimpleModal
        isOpen={isPay360PaymentSuccess}
        title="Payment Successful"
        buttonCancelText="Close"
        onCloseClick={this.onOkPay360PaymentSuccessModalClick}
        customClass="bCustomerModal"
      >
        <div>
          {orderDetails && orderImages.length ? (
            <>
              <p>
                Your order{' '}
                <span style={{ fontWeight: 'bold', color: '#7d0140' }}>
                  {orderDetails ? orderDetails.orderNumber : ''}
                </span>{' '}
                has been confirmed and payment was successful. The order details are below:
              </p>
              <CustomerProfileOrderInformation order={orderDetails} orderImages={orderImages} />
            </>
          ) : (
            <p>Your payment has been successful. We will let you know once your order has been dispatched.</p>
          )}
        </div>
      </SimpleModal>
    );
  }

  onOkPaymentTimeoutModalClick = () => {
    this.setState({
      isPaymentTimeout: false,
    });
    this.props.history.push('/customer/basket');
  };

  renderPaymentTimeoutModal() {
    const { isPaymentTimeout } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentTimeout}
        title={'Info'}
        body={'We were unable to process the payment. Please contact our support team to complete your purchase.'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkPaymentTimeoutModalClick}
        customClass={`bCustomerModal`}
      />
    );
  }

  onOkPaymentCanceledModalClick = () => {
    this.setState({
      isPaymentCanceled: false,
    });
    this.props.history.push('/customer/basket');
  };

  renderPaymentCanceledModal() {
    const { isPaymentCanceled } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentCanceled}
        title={'Info'}
        body={'Payment failed, try again'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkPaymentCanceledModalClick}
        customClass={`bCustomerModal`}
      />
    );
  }

  onPhotoTypeFilterClick = (photoType: string) => {
    this.setState({
      selectedPhotoType: photoType,
    });
  };

  onYearFilterClick = (year: string) => {
    this.setState({
      selectedPhotoYear: year,
    });
  };

  noShowCountDownClock = () => {
    this.setState({
      isShowCountDownClock: false,
    });
  };

  noSelectedImagesInfoModalClose = () => {
    this.setState({
      isNoSelectedImagesInfo: false,
    });
  };

  renderNoSelectedImagesInfoModal(): React.ReactNode {
    const { isNoSelectedImagesInfo } = this.state;

    return (
      <SimpleModal isOpen={isNoSelectedImagesInfo}>
        <ConfirmationModal
          text={'Please select the image(s) you wish to add before pressing next.'}
          onOk={this.noSelectedImagesInfoModalClose}
        />
      </SimpleModal>
    );
  }

  renderAccessCodeAddedModal(): React.ReactNode {
    const { isAccessCodeAddedModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isAccessCodeAddedModalOpen}>
        <ConfirmationModal
          text={
            'Access code has been added to your account. We will let you know as soon as your photos are ready to view and order!'
          }
          onOk={this.closeAccessCodeModal}
        />
      </SimpleModal>
    );
  }

  closeAccessCodeModal = () => {
    clearIsSignFromOneTimeCodeWaitingInStorage();

    this.setState({
      isAccessCodeAddedModalOpen: false,
    });
  };

  render() {
    const {
      isLoading,
      selectedPhotoType,
      selectedPhotoYear,
      dates,
      isDateContentShow,
      dateContent,
      isDateClassContentShow,
      dateClassContent,
      dateClassParams,
      isShowCountDownClock,
      hasPhotographs,
    } = this.state;
    const { customer, selectedTicketIds, onGalleryImageClick } = this.props;

    let message;
    if (dates.length === 0) {
      if (hasPhotographs) {
        message = 'There are no photos matching your search or filtering criteria.';
      } else {
        message =
          'No photographs have been added to your account yet. You will get a notification once your photographs are ready.';
      }
    }

    if (isLoading) {
      return <Loader />;
    }

    const jobSchoolDeliveryDeadlines = [];
    for (let valueClassContent in dateClassContent) {
      const className = dateClassContent[valueClassContent];
      for (let valueClassName in className) {
        className[valueClassName].forEach(ticket => {
          const jobSchoolDeliveryDeadline = propz.get(ticket, ['jobSchoolDeliveryDeadline']);
          const isExistJobSchoolDeliveryDeadline =
            typeof jobSchoolDeliveryDeadline !== 'undefined' && jobSchoolDeliveryDeadline !== '';
          if (isExistJobSchoolDeliveryDeadline) {
            jobSchoolDeliveryDeadlines.push(jobSchoolDeliveryDeadline);
          }
        });
      }
    }
    const isExistJobSchoolDeliveryDeadlines = jobSchoolDeliveryDeadlines.length > 0;
    const minJobSchoolDeliveryDeadlineDate = isExistJobSchoolDeliveryDeadlines
      ? Lazy(jobSchoolDeliveryDeadlines).min()
      : new Date();

    return (
      <>
        {this.renderPaymentCanceledModal()}
        {this.renderPaymentTimeoutModal()}
        {this.renderWorldPayPaymentSuccessModal()}
        {this.renderPay360PaymentSuccessModal()}
        {this.renderPaymentProcessModal()}
        {this.renderNoSelectedImagesInfoModal()}
        {this.renderAccessCodeAddedModal()}
        <div className="bCustomerViewWrapper">
          <div className="container">
            <div className="row">
              <div className={'col-xl-12'}>
                {isShowCountDownClock && isExistJobSchoolDeliveryDeadlines && (
                  <div>
                    <div className={'eCustomerFreeDeliveryTitle'}>Your Free School Delivery Offer expires in:</div>
                    <div className="">
                      <CountDownClock
                        schoolDeliveryDeadlineDate={new Date(minJobSchoolDeliveryDeadlineDate)}
                        noShowCountDownClock={this.noShowCountDownClock}
                      />
                    </div>
                  </div>
                )}
                <div className={'eCustomerViewTitle'}>
                  Please select the images you would like to view / order and press next
                </div>
                <div className={'eCustomerImageCopyrightText'}>
                  We've enhanced our watermarking to prevent unauthorised image use. We apologise for any inconvenience
                  during online viewing; rest assured, your final prints remain pristine and untouched. Thank you for
                  your understanding and support.
                </div>
                <FilterIcons onPhotoTypeClick={this.onPhotoTypeFilterClick} onYearClick={this.onYearFilterClick} />
              </div>
            </div>

            <div className="search-field">
              <input
                type="text"
                placeholder="Search by Key Words..."
                onChange={this.handleSearchChange}
                value={this.state.searchQuery}
              />
            </div>

            <div className="row">
              <div className={'col-xl-2'}>
                <CustomerAccessCode customer={customer} setInitialDataStructure={this.setInitialDataStructure} />
                <CustomerPhotoTypeFilter selectedPhotoType={selectedPhotoType} onClick={this.onPhotoTypeFilterClick} />
                <CustomerYearsFilter selectedPhotoYear={selectedPhotoYear} onClick={this.onYearFilterClick} />
              </div>

              <div className={'col-xl-10 mt-3 mb-3 bCustomerImageBlock'}>
                {dates.length === 0 ? (
                  <div className={'eCustomerViewTitle'}>{message}</div>
                ) : (
                  dates.map(date => {
                    return (
                      <div className="card" key={`customer_view_date_${date}`}>
                        <div className="card-header" onClick={() => this.onDateClick(date)}>
                          <h5 className="mb-0">
                            <button className="btn mNoOutline">{date}</button>
                          </h5>
                        </div>

                        <div className={isDateContentShow[date] ? 'collapse show' : 'collapse'}>
                          <div className="card-body">
                            {dateContent[date].map(classname => {
                              if (classname.toLowerCase().includes(this.state.searchQuery.toLowerCase())) {
                                return (
                                  <div className="card" key={`customer_view_classname_${classname}`}>
                                    <div className="card-header" onClick={() => this.onClassClick(date, classname)}>
                                      <h5 className="mb-0">
                                        <button className="btn mNoOutline">{classname}</button>
                                      </h5>
                                    </div>

                                    <div
                                      className={isDateClassContentShow[date][classname] ? 'collapse show' : 'collapse'}
                                    >
                                      <div className="card-body">
                                        <div className="row">
                                          {dateClassContent[date][classname].map(ticket => {
                                            const isSelected = selectedTicketIds.indexOf(ticket.id) !== -1;
                                            return (
                                              <ImageTicketsCustomerView
                                                key={ticket.id}
                                                ticket={ticket}
                                                onImageClick={onGalleryImageClick}
                                                isSelected={isSelected}
                                              />
                                            );
                                          })}
                                        </div>

                                        {dateClassParams[date][classname]['skip'] <
                                          dateClassParams[date][classname]['ticketsCount'] && (
                                          <div
                                            className={'bLoadMoreButton'}
                                            onClick={() => this.onLoadMoreClick(date, classname)}
                                          >
                                            LOAD MORE
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}

                {dates.length > 0 && (
                  <div className={'eCustomerNextButtonWrapper'}>
                    <button onClick={this.onNextButtonClick} type={'button'} className={'eCustomerNextButton'}>
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
