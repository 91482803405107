import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import {
  getName,
  searchFunctionProductOffers,
  searchFunctionProducts,
} from '../../../../../../../helpers/autocomplete';
import { AppUser } from '../../../../../App';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import { PRICE, PRICE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/offer';
import { Component, useState } from 'react';
import { getCurrencyMask } from '../../../../../../../helpers/currencyMask';
import MaskedInput from 'react-text-mask';
import { VOUCHER_SCOPE } from '../../../../../../../consts/voucher';
import { School } from '../../../../../../../models/schools';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  user: AppUser;
  schools?: School[];
}

interface State {
  productOffers: any[];
}

export class SchoolOfferForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      productOffers: [],
    };
  }

  getProducts = (text: string) => {
    const { user } = this.props;
    return searchFunctionProducts(user, text);
  };

  getProductOffers = (text: string) => {
    const { user } = this.props;
    const { productOffers } = this.state;
    return searchFunctionProductOffers(user, text, productOffers);
  };

  renderProductOffers(productOffers, setFieldValue) {
    const { productOffers: productOffersState } = this.state;
    return productOffers.map((productOffer, index) => {
      return (
        <div key={productOffer.productId}>
          <div className={'mb-2'}>Product offer</div>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={productOffer}
            disabled
            customClass={'mb-3'}
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const nextProductOffers = productOffers.filter(p => p.productId !== productOffer.productId);
                setFieldValue('productOffers', nextProductOffers);
                this.setState({
                  productOffers: nextProductOffers,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>

          <div className="form-group">
            <label>Discount</label>
            <Field
              name={`productOffers.${index}.priceType`}
              component="select"
              className="form-control mb-3"
              onChange={event => {
                const priceType = event.target.value;
                setFieldValue(`productOffers.${index}.priceType`, priceType);
                setFieldValue(`productOffers.${index}.price`, 0);
              }}
            >
              <option value={PRICE.AMOUNT}>{PRICE_SERVER_TO_CLIENT_MAPPING.AMOUNT}</option>
              <option value={PRICE.PERCENT}>{PRICE_SERVER_TO_CLIENT_MAPPING.PERCENT}</option>
            </Field>
          </div>

          {productOffer.priceType === PRICE.AMOUNT && (
            <div className="form-group">
              <div className="row m-0">
                <div className="d-inline-block mt-2">
                  List price: {CURRENCY_SYMBOL.POUND}
                  {productOffersState[index].listPrice}, offered price:
                </div>

                <div className="input-group w-25 ml-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                  </div>

                  <Field name="">
                    {({ field }) => (
                      <MaskedInput
                        type="text"
                        className="form-control"
                        mask={getCurrencyMask}
                        onChange={event => {
                          setFieldValue(`productOffers.${index}.price`, event.target.value);
                        }}
                        value={productOffer.price}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage component="div" className="alert alert-danger mt-3" name={`productOffers.${index}.price`} />
            </div>
          )}

          {productOffer.priceType === PRICE.PERCENT && (
            <div className="form-group">
              <div className="row m-0">
                <div className="d-inline-block mt-2">
                  List price: {CURRENCY_SYMBOL.POUND}
                  {productOffersState[index].listPrice}, offered percent:
                </div>

                <div className="input-group w-25 ml-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">%</div>
                  </div>

                  <Field name="">
                    {({ field }) => (
                      <input
                        type="number"
                        className="form-control"
                        max="100"
                        min="0"
                        onChange={event => {
                          setFieldValue(`productOffers.${index}.price`, event.target.value);
                        }}
                        value={productOffer.price}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage component="div" className="alert alert-danger mt-3" name={`productOffers.${index}.price`} />
            </div>
          )}
        </div>
      );
    });
  }

  renderSchools(schools) {
    return schools.map(school => {
      return <div key={school.schoolId}>{school.name}</div>;
    });
  }

  render() {
    const { onSubmit, onCancel, schools } = this.props;
    const { productOffers } = this.state;
    const offerForm = {
      product: {
        productId: '',
        name: '',
      },
      productOffers: [],
      scope: {
        scopeType: VOUCHER_SCOPE.SCHOOL,
        schools: schools.map(school => ({ schoolId: school.id, name: school.name })),
      },
    };

    const OfferSchema = Yup.object().shape({
      product: Yup.object().shape({
        productId: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
      }),
      productOffers: Yup.array()
        .min(1, 'Should contain minimum 1 product')
        .of(
          Yup.object().shape({
            productId: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            priceType: Yup.string().required('Required'),
            price: Yup.string().when('priceType', {
              is: PRICE.AMOUNT,
              then: Yup.string()
                .required('Required')
                .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
              otherwise: Yup.string()
                .required('Required')
                .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
            }),
          })
        ),
    });

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik initialValues={offerForm} validationSchema={OfferSchema} onSubmit={onSubmit}>
              {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Product</label>
                      <Field name="product">
                        {() => (
                          <Autocomplete
                            searchFunction={this.getProducts}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.product}
                            onSelect={product => {
                              setFieldValue('product.productId', product.id);
                              setFieldValue('product.name', product.name);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="product.productId" />
                    </div>

                    <div>
                      {this.renderProductOffers(values.productOffers, setFieldValue)}
                      <div className="form-group">
                        <label>Add product:</label>
                        <Field name="">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getProductOffers}
                              getElementTitle={getName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={productOffer => {
                                const nextProductOffers = [
                                  ...values.productOffers,
                                  {
                                    productId: productOffer.id,
                                    name: productOffer.name,
                                    price: 0,
                                    priceType: PRICE.AMOUNT,
                                    listPrice: productOffer.pricing.price,
                                  },
                                ];

                                setFieldValue('productOffers', nextProductOffers);
                                this.setState({
                                  productOffers: nextProductOffers,
                                });
                              }}
                            />
                          )}
                        </Field>
                        {productOffers.length === 0 && (
                          <ErrorMessage component="div" className="alert alert-danger" name={`productOffers`} />
                        )}
                      </div>
                    </div>

                    <div className={'mb-2'}>Schools</div>
                    {this.renderSchools(values.scope.schools)}

                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
