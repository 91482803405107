export const INTEGRATION_LOG_STATUS_TYPES = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export const INTEGRATION_LOG_STATUS_TYPES_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'New',
  IN_PROGRESS: 'In progress',
  DONE: 'Done',
  ERROR: 'Error',
};
