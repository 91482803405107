import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as copy from 'copy-to-clipboard';
import { Component } from 'react';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getPagesCount,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { CHECKBOX_FIELDS, DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { imageCell, imagePublicLinkCell } from '../../../../../../helpers/cell';
import { ImageWithTicket } from '../../../../../../models/images';
import { getAllImages, getImages, getImagesCount } from '../../../../../../services/superadmin/images';
import { createTicket } from '../../../../../../services/superadmin/image';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { addOrder, deleteOrder, getOrdersCount } from '../../../../../../services/superadmin/orders';
import { ImageOrderForm } from './ImageOrderForm/ImageOrderForm';
import { Order } from '../../../../../../models/orders';
import { Package } from '../../../../../../models/packages';
import { getPackage } from '../../../../../../services/superadmin/packages';
import { addOrderImage } from '../../../../../../services/superadmin/orderImages';
import { Button } from '../../../../../../components/Button/Button';
import { getBpImageIds, getImageJobDate } from '../../../../../../helpers/accessor';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { parseDomainName } from '../../../../../../helpers/api';
import { ImageWizard } from './ImageWizard/ImageWizard';
import { EMPTY_STRINGS_IN_EDITOR } from '../../../../../../consts/htmlEditor';
import * as propz from 'propz';
import { sendFreeNotification } from '../../../../../../services/superadmin/notifications';
import { uploadFile } from '../../../../../../services/file';
import { LinkImageForm } from './LinkImageForm/LinkImageForm';
import * as BPromise from 'bluebird';
import { sendNotifications } from '../../../../../../services/superadmin/jobs';
import { addCustomerPermission } from '../../../../../../services/superadmin/customerPermissions';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Image',
    field: 'image',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: imageCell,
  },
  {
    text: 'School name',
    field: 'nameOfSchool',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['school', 'name'],
  },
  {
    text: 'School Reference',
    field: 'schoolCode',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['school', 'code'],
  },
  {
    text: 'Job Reference',
    field: 'jobReferenceImages',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['job', 'jobReference'],
  },
  {
    text: 'Student class',
    field: 'studentClass',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['student', 'class'],
  },
  {
    text: 'Student first name',
    field: 'studentFirstNameWithCheckbox',
    isSort: false,
    type: FILTER_TYPE.TEXT_WITH_CHECKBOX,
    accessor: ['student', 'firstName'],
  },
  {
    text: 'Student last name',
    field: 'studentLastNameWithCheckbox',
    isSort: false,
    type: FILTER_TYPE.TEXT_WITH_CHECKBOX,
    accessor: ['student', 'lastName'],
  },
  {
    text: 'Username',
    field: 'username',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['username'],
  },
  {
    text: 'Password',
    field: 'password',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['password'],
  },
  {
    text: 'Date',
    field: 'imageJobDate',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getImageJobDate,
  },
  {
    text: 'Barcode number',
    field: 'bulkImageId',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['bulkImageId'],
  },
  {
    text: 'BP image ID',
    field: 'bpImageId',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getBpImageIds,
  },
  {
    text: 'Public link',
    field: 'publicLink',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: imagePublicLinkCell,
  },
];

interface State {
  items: ImageWithTicket[];
  currentPage: number;
  selectedItems: ImageWithTicket[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isConfirmationModalOpen: boolean;
  isOrderFormModalOpen: boolean;
  order: Order;
  packageItem: Package;

  messageEmailSubject: string;
  messageEmailBody: string;
  isWizardModalOpen: boolean;
  messageAttachments: any[];
  isLoadingFile: boolean;
  wizardEmail: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isRawEditor: boolean;

  isLinkImageFormModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Images extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isConfirmationModalOpen: false,
      isOrderFormModalOpen: false,
      order: undefined,
      packageItem: undefined,
      messageEmailSubject: '',
      messageEmailBody: '',
      isWizardModalOpen: false,
      messageAttachments: [],
      isLoadingFile: false,
      wizardEmail: '',
      isErrorModalOpen: false,
      errorMessage: '',
      isRawEditor: false,
      isLinkImageFormModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getImages(user, serverQueryFilter);
    const getItemsCountPromise = getImagesCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    let countObj, items;
    return Promise.all(promises)
      .then(([_countObj, _items]) => {
        countObj = _countObj;
        items = _items;

        return Promise.all(
          items.map(item => {
            const data = {
              key: item.key,
              mimeType: item.mimeType,
            };

            return createTicket(user, data);
          })
        );
      })
      .then(tickets => {
        const imagesExtended = items.map((item, index) => ({
          ...item,
          ticket: tickets[index],
        }));

        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: imagesExtended,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
        });

        return true;
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllImages(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
        case CHECKBOX_FIELDS.CHECKBOX: {
          const checked = event.target.checked;
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              value: '',
              checked: checked,
            },
          };
          break;
        }
        case CHECKBOX_FIELDS.TEXT:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              value: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
      order: undefined,
    });
  };

  onFinishButtonClick = () => {
    const { order } = this.state;
    const { user, history } = this.props;
    const { id } = order;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    getOrdersCount(user).then(countObj => {
      const { count } = countObj;

      this.setState({
        isLoading: false,
      });

      const pages = getPagesCount(count, LIMIT);

      history.push({
        pathname: '/orders',
        search: `page=${pages}`,
        state: { orderId: id },
      });
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isOrderFormModalOpen: false,
    });
  };

  onCreateOrderClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const [image] = selectedItems;
    this.setState({
      isLoading: true,
    });

    const packageId = image.package.packageId;
    getPackage(user, packageId).then(packageItem => {
      this.setState({
        packageItem,
        isOrderFormModalOpen: true,
        isLoading: false,
      });
    });
  };

  onSubmitForm = data => {
    const { user } = this.props;
    const { order } = this.state;

    const isOrderExist = typeof order !== 'undefined';

    const { orderStatus, orderComment, ...rest } = data;

    const dataOrder = {
      orderStatus: orderStatus,
      orderComment: orderComment,
    };

    const dataImage = {
      ...rest,
      quantity: Number(data.quantity),
    };

    this.setState({
      isLoading: true,
    });

    if (!isOrderExist) {
      addOrder(user, dataOrder)
        .then(order => {
          this.setState({
            order: order,
          });
          return addOrderImage(user, order.id, dataImage);
        })
        .then(image => {
          this.setState({
            isOrderFormModalOpen: false,
            selectedItems: [],
            isSelectAllChecked: false,
            isLoading: false,
            isConfirmationModalOpen: true,
          });
        });
    } else {
      addOrderImage(user, order.id, dataImage).then(image => {
        this.setState({
          isOrderFormModalOpen: false,
          selectedItems: [],
          isSelectAllChecked: false,
          isLoading: false,
          isConfirmationModalOpen: true,
        });
      });
    }
  };

  renderFormModal(): React.ReactNode {
    const { isOrderFormModalOpen, selectedItems, packageItem, order } = this.state;
    const { user } = this.props;
    const [image] = selectedItems;

    return (
      <SimpleModal isOpen={isOrderFormModalOpen}>
        <ImageOrderForm
          image={image}
          user={user}
          packageItem={packageItem}
          order={order}
          onSubmit={this.onSubmitForm}
          onCancel={this.onCancelButtonClick}
        />
      </SimpleModal>
    );
  }

  onAddNewImageClick = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isConfirmationModalOpen} title={'Confirmation'} body={`Add new image in current order?`}>
        <div className={'mt-3 mb-3'}>
          <Button customClass={'btn-secondary mr-3'} onClick={this.onCancelOrderClick} text={'Cancel order'} />
          <Button customClass={'mr-3'} onClick={this.onFinishButtonClick} text={'Finish order'} />
          <Button customClass={''} onClick={this.onAddNewImageClick} text={'Add new image'} />
        </div>
      </SimpleModal>
    );
  }

  onCancelOrderClick = () => {
    const { order } = this.state;
    const { user } = this.props;
    const { id } = order;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    deleteOrder(user, id).then(res => {
      this.setState({
        order: undefined,
        isLoading: false,
      });
    });
  };

  getLink(image): string {
    const { username, password, id } = image;

    const hostname = document.location.hostname;
    const protocol = document.location.protocol;

    const hostnameParsed = parseDomainName(hostname);
    const { env } = hostnameParsed;

    const port = document.location.port;
    const isDev = env === 'dev';
    const isLocal = env === 'local';

    const hostnameParts = hostname.split('.');

    const [model, ...rest] = hostnameParts;

    const restJoined = rest.join('.');

    return isDev || isLocal
      ? `${protocol}//orders.${restJoined}:${port}/order?username=${username}&password=${password}&imageId=${id}`
      : `${protocol}//orders.${restJoined}/order?username=${username}&password=${password}&imageId=${id}`;
  }

  getLink2(image): string {
    const { username, password, id } = image;

    const hostname = document.location.hostname;
    const protocol = document.location.protocol;

    const hostnameParsed = parseDomainName(hostname);
    const { env } = hostnameParsed;

    const port = document.location.port;
    const isDev = env === 'dev';
    const isLocal = env === 'local';

    const hostnameParts = hostname.split('.');

    const [model, ...rest] = hostnameParts;

    const restJoined = rest.join('.');

    return isDev || isLocal
      ? `${protocol}//orders.${restJoined}:${port}/accessCode?username=${username}&password=${password}`
      : `${protocol}//orders.${restJoined}/accessCode?username=${username}&password=${password}`;
  }

  onCreateOrderInAppClick = () => {
    const { selectedItems } = this.state;
    const [image] = selectedItems;

    const link = this.getLink(image);

    window.open(link, '_blank');
  };

  onCopyToClipboardClick = () => {
    const { selectedItems } = this.state;
    const [image] = selectedItems;

    const link = this.getLink(image);

    copy(link);
  };

  onCancelSendNotificationsButtonClick = () => {
    this.setState({
      isLoadingFile: false,
      isWizardModalOpen: false,
      messageEmailSubject: '',
      messageEmailBody: '',
      messageAttachments: [],
      wizardEmail: '',
    });
  };

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.url} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  onSendNotificationModalClick = () => {
    const { messageEmailSubject, messageEmailBody, messageAttachments, wizardEmail } = this.state;

    let newMessageEmailBody;
    const emptyStrings = [...EMPTY_STRINGS_IN_EDITOR];
    const emptyEmailBody = emptyStrings.find(string => messageEmailBody === string);

    if (typeof emptyEmailBody !== 'undefined') {
      //  because   "<p><br></p>" === ''  in editor
      newMessageEmailBody = messageAttachments.length > 0 ? this.getAttachmentAsLinksString() : '';
    } else {
      newMessageEmailBody = messageEmailBody + this.getAttachmentAsLinksString();
    }

    const messageHTMLEmailBody = newMessageEmailBody !== '' ? newMessageEmailBody : '';

    const data = {
      content: {
        email: {
          subject: messageEmailSubject,
          body: messageHTMLEmailBody,
        },
      },
      affectedEmails: [wizardEmail],
      affectedCustomerIds: [],
    };

    this.onSubmitNotificationClick(data);
  };

  onSubmitNotificationClick = data => {
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    sendFreeNotification(user, data)
      .then(empty => {
        this.setState({
          isLoading: false,
          isWizardModalOpen: false,
          messageEmailSubject: '',
          messageEmailBody: '',
          messageAttachments: [],
          wizardEmail: '',
          selectedItems: [],
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
        console.error('error');
        console.error(errorText);
      });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  // message content callbacks
  onEmailChange = email => {
    this.setState({
      wizardEmail: email,
    });
  };

  onEmailSubjectChange = event => {
    this.setState({
      messageEmailSubject: event.target.value,
    });
  };

  onEmailBodyChange = (body: any) => {
    this.setState({
      messageEmailBody: body,
    });
  };

  onRemoveAttachment = (url: string) => {
    const attachments = [...this.state.messageAttachments];
    const attachmentIndex = attachments.findIndex(attachment => attachment.url === url);

    attachments.splice(attachmentIndex, 1);
    this.setState({
      messageAttachments: attachments,
    });
  };

  onEmailAttachmentChange = (event): void => {
    const { user } = this.props;
    const attachments = [...this.state.messageAttachments];
    this.setState({
      isLoadingFile: true,
    });
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const fileName = event.target.files[0];

    uploadFile(user, formData)
      .then(data => {
        const url = data.url;
        const urlFormatted = url.replace(/ /g, '%20');
        attachments.push({
          fileName: fileName.name,
          url: urlFormatted,
        });
        this.setState({
          messageAttachments: attachments,
          isLoadingFile: false,
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isErrorModalOpen: true,
          errorMessage: errorText,
          isLoadingFile: false,
        });
        console.error('error');
        console.error(error);
      });
  };

  renderWizard(): React.ReactNode {
    const { isWizardModalOpen } = this.state;
    if (!isWizardModalOpen) {
      return null;
    }

    const {
      messageEmailSubject,
      messageEmailBody,
      messageAttachments,
      isRawEditor,
      isErrorModalOpen,
      isLoadingFile,
      wizardEmail,
    } = this.state;

    const { user } = this.props;

    return (
      <ImageWizard
        onCancelClick={this.onCancelSendNotificationsButtonClick}
        isOpen={isWizardModalOpen}
        onSendNotificationModalClick={this.onSendNotificationModalClick}
        onEmailSubjectChange={this.onEmailSubjectChange}
        onEmailBodyChange={this.onEmailBodyChange}
        onRemoveAttachment={this.onRemoveAttachment}
        onEmailAttachmentChange={this.onEmailAttachmentChange}
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messageAttachments={messageAttachments}
        isError={isErrorModalOpen}
        isLoadingFile={isLoadingFile}
        isRawEditor={isRawEditor}
        onRawEditorChange={this.onRawChange}
        onEmailChange={this.onEmailChange}
        wizardEmail={wizardEmail}
        user={user}
      />
    );
  }

  onRawChange = event => {
    const { value } = event.target;
    const { selectedItems } = this.state;
    if (value === 'richText') {
      this.setState({
        isRawEditor: false,
        messageEmailBody: selectedItems
          .map(image => {
            const link = this.getLink2(image);
            return `<p><a href="${link}">Link</a></p>`;
          })
          .join(''),
        messageEmailSubject: 'New message',
      });
    } else {
      this.setState({
        isRawEditor: true,
        messageEmailBody: selectedItems
          .map(image => {
            const link = this.getLink2(image);
            return `<p><a href="${link}">Link</a></p>`;
          })
          .join(''),
        messageEmailSubject: 'New message',
      });
    }
  };

  onWizardClick = () => {
    const { selectedItems } = this.state;

    this.setState({
      isWizardModalOpen: true,
      messageEmailBody: selectedItems
        .map(image => {
          const link = this.getLink2(image);
          return `<p><a href="${link}">Link</a></p>`;
        })
        .join(''),
      messageEmailSubject: 'New message',
    });
  };

  onLinkImageClick = () => {
    this.setState({
      isLinkImageFormModalOpen: true,
    });
  };

  onCancelLinkImageClick = () => {
    this.setState({
      isLinkImageFormModalOpen: false,
    });
  };

  onSubmitLinkImageForm = ({ email, customerId }: { email: string; customerId: string }) => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true,
    });

    const imagesUniq = Lazy(selectedItems)
      .uniq(({ username, password }) => `${username}|${password}`)
      .toArray();

    const promises = imagesUniq.map(image =>
      addCustomerPermission(user, customerId, { username: image.username, password: image.password })
    );

    BPromise.all(promises).finally(() => {
      this.setState({
        isLoading: false,
        isLinkImageFormModalOpen: false,
        selectedItems: [],
      });
    });
  };

  renderLinkImageFormModal(): React.ReactNode {
    const { isLinkImageFormModalOpen } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isLinkImageFormModalOpen}>
        <LinkImageForm user={user} onSubmit={this.onSubmitLinkImageForm} onCancel={this.onCancelLinkImageClick} />
      </SimpleModal>
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isConfirmationModalOpen,
      order,
      isWizardModalOpen,
      isErrorModalOpen,
      isLinkImageFormModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isConfirmationModalOpen || isWizardModalOpen || isErrorModalOpen || isLinkImageFormModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    const isOrderExist = typeof order !== 'undefined';

    const actionItems = [
      {
        itemText: 'Create order',
        onItemClick: this.onCreateOrderClick,
        isActive: selectedItems.length === 1 && !isOrderExist,
      },
      {
        itemText: 'Create order in app',
        onItemClick: this.onCreateOrderInAppClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: 'Copy link to clipboard',
        onItemClick: this.onCopyToClipboardClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: 'Add image to order',
        onItemClick: this.onCreateOrderClick,
        isActive: selectedItems.length === 1 && isOrderExist,
      },
      {
        itemText: 'Link images to a customer',
        onItemClick: this.onLinkImageClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Send usernames/passwords via email',
        onItemClick: this.onWizardClick,
        isActive: selectedItems.length > 0,
      },
    ];

    const filterOptions = {};

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderFormModal()}
        {this.renderConfirmationModal()}
        {this.renderWizard()}
        {this.renderSendingError()}
        {this.renderLinkImageFormModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              isSelectAllDisabled={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
