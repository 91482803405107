import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { History, Location } from 'history';
import { getFromHistory } from '../../../helpers/history';
import { useState } from 'react';
import { unsubscribe } from '../../../services/public/unsubscribe';

const UnsubscribedSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Must be valid email'),
});

interface Props {
  history: History;
  location: Location;
}

function UnsubscribedForm(props: Props) {
  const { history } = props;

  const emailFromHistory = getFromHistory(history, 'email');

  const unsubscribedForm = {
    email: emailFromHistory || '',
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAlreadyUnsubscribed, setIsAlreadyUnsubscribed] = useState(false);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="h3 text-center mt-3">Are you really want to unsubscribe from Bentley emails?</div>
          <div className="mt-3">
            <Formik
              initialValues={unsubscribedForm}
              validationSchema={UnsubscribedSchema}
              onSubmit={({ email }) => {
                unsubscribe({ email })
                  .then(res => {
                    setIsSubmitted(true);
                  })
                  .catch(err => {
                    setIsAlreadyUnsubscribed(true);
                  });
              }}
            >
              {({ handleSubmit }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Email</label>
                      <Field
                        disabled={isSubmitted || emailFromHistory || isAlreadyUnsubscribed}
                        name="email"
                        className="form-control mb-3"
                      />
                      <ErrorMessage component="div" className="alert alert-danger" name="email" />
                    </div>

                    <Button
                      onClick={handleSubmit}
                      text={'Unsubscribe'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                      disabled={isSubmitted || isAlreadyUnsubscribed}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {isSubmitted && <div className="h3 text-center">Success!</div>}
          {isAlreadyUnsubscribed && <div className="h3 text-center">You already unsubscribed!</div>}
        </div>
      </div>
    </div>
  );
}

export default UnsubscribedForm;
