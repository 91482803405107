import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolAppSchoolJobs2 } from './SchoolAppSchoolJobs2';
import { SchoolAppSchoolJobImages2 } from './SchoolAppSchoolJobImages2/SchoolAppSchoolJobImages2';

export const SchoolAppSchoolJobsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/schoolJobs'} render={props => <SchoolAppSchoolJobs2 {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/schoolJobs/images'}
        render={props => <SchoolAppSchoolJobImages2 {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

SchoolAppSchoolJobsRouting.displayName = 'SchoolAppSchoolJobsRouting';
