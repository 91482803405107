import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { SchoolSchoolType } from '../../../../../../../models/schools';

interface Props {
  onCancel: () => void;
  onSubmit: (data: { id: string }) => void;
  schoolTypes: SchoolSchoolType[];
}

const SchoolTypeSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
});

export function DeleteSchoolTypeForm(props: Props) {
  const { onSubmit, onCancel, schoolTypes } = props;
  const schoolTypeForm = {
    id: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={schoolTypeForm}
            validationSchema={SchoolTypeSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>School type</label>
                    <Field
                      name="id"
                      component="select"
                      placeholder="Select type"
                      className="form-control mb-3"
                    >
                      <option value={''} />
                      {schoolTypes.map(schoolType => (
                        <option
                          key={schoolType.typeId}
                          value={schoolType.typeId}
                        >
                          {schoolType.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="alert alert-danger"
                      name="id"
                    />
                  </div>

                  <Button
                    onClick={onCancel}
                    text={'Cancel'}
                    customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                  />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
