import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';

export function getVouchers(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/vouchers`, config).then(response => {
    return response.data;
  });
}

export function getAllVouchers(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/vouchers`, config).then(response => {
    return response.data;
  });
}

export function getVouchersCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/vouchers/count`, config).then(response => {
    return response.data;
  });
}

export function addVoucher(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/vouchers`, data, config).then(response => {
    return response.data;
  });
}

export function updateVoucher(user: AppUser, voucherId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/vouchers/${voucherId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteVoucher(user: AppUser, voucherId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/vouchers/${voucherId}`, config).then(response => {
    return response.data;
  });
}

export function getVoucher(user: AppUser, voucherId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/vouchers/${voucherId}`, config).then(response => {
    return response.data;
  });
}
