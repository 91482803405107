import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';

export function getSliderImage(user: AppUser, sliderId: string, imageId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/sliders/${sliderId}/images/${imageId}`, config).then(response => {
    return response.data;
  });
}

export function getSliderImages(AppUser, sliderId: string, filter) {
  const { sessionKey: key } = AppUser;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/sliders/${sliderId}/images`, config).then(response => {
    return response.data;
  });
}

export function getAllSliderImages(user: AppUser, sliderId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/sliders/${sliderId}/images`, config).then(response => {
    return response.data;
  });
}

export function getSliderImagesCount(user: AppUser, sliderId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/sliders/${sliderId}/images/count`, config).then(response => {
    return response.data;
  });
}

export function addSliderImages(user: AppUser, sliderId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/sliders/${sliderId}/images`, data, config).then(response => {
    return response.data;
  });
}

export function updateSliderImages(user: AppUser, sliderId: string, imageId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/sliders/${sliderId}/images/${imageId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteSliderImage(user: AppUser, sliderId: string, imageId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/sliders/${sliderId}/images/${imageId}`, config).then(response => {
    return response.data;
  });
}
