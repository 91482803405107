import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';

export function getCustomerVoucher(customer: AppCustomer, voucherCode: string) {
  const { sessionKey: key, customerId, basketId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/${customerId}/basket/${basketId}/vouchers`, { voucherCode }, config)
    .then(response => {
      return response.data;
    });
}
