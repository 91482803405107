import * as React from 'react';
import './ConfirmationModal.scss';

interface Props {
  onCancel?: () => void;
  onOk?: () => void;
  title?: string;
  text?: string;
}

export function ConfirmationModal(props: Props) {
  const { title, text, onCancel, onOk } = props;
  const isCancelExist = typeof onCancel !== 'undefined';

  return (
    <div className="bConfirmationModal">
      <div className="eConfirmationModalTitle">{title}</div>
      <div className="eConfirmationModalText">{text}</div>
      <div className="eConfirmationModalButtonWrapper">
        <button className="eConfirmationModalButton" onClick={onOk}>
          Ok
        </button>
          {isCancelExist &&
          <button className="eConfirmationModalButton" onClick={onCancel}>
              Cancel
          </button>
      }
      </div>
    </div>
  );
}
