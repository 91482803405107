import { MAX_COUNTRY_PHONE_CODE_LENGTH, PHONE_CODE } from '../consts/common';
import { CountryWithCode } from '../models/countryWithCode';

const countriesWithCode: CountryWithCode[] = require('Src/services/countriesWithCode.json');

export function getPhoneAndCode(item) {
  const isExistPhone = typeof item !== 'undefined' && typeof item.phone !== 'undefined';
  if (isExistPhone) {
    const phone = item.phone;
    let currentCode = '';
    let currentPhone = '';
    for (let i = 0; i < MAX_COUNTRY_PHONE_CODE_LENGTH; i++) {
      const possibleCode = phone.substr(0, MAX_COUNTRY_PHONE_CODE_LENGTH - i);
      countriesWithCode.forEach(countryWithCode => {
        if (countryWithCode.ISO === possibleCode) {
          currentCode = countryWithCode.ISO;
          currentPhone = phone.substr(currentCode.length);
          i = MAX_COUNTRY_PHONE_CODE_LENGTH;
        }
      });
    }
    return { code: currentCode, phone: currentPhone };
  }
}

export function getPhoneWithCode(phone: string, phoneCode?: string): string {
  let result;

  if (!phone) {
    return '';
  }

  switch (true) {
    case phone[0] === '0':
      result = phone.slice(1);
      break;
    case phone[0] === '4' && phone[1] === '4':
      result = phone.slice(2);
      break;
    case phone[0] === '+' && phone[1] === '4' && phone[2] === '4':
      result = phone.slice(3);
      break;
    default:
      result = phone;
  }

  return typeof phoneCode !== 'undefined' ? `${phoneCode}${phone}` : `${PHONE_CODE.UK}${result}`;
}

export function getPhoneWithoutFirstZero(phone) {
  const isExistPhone = typeof phone !== 'undefined' && phone !== '';

  if (isExistPhone) {
    const regExp = /^0*/;
    return phone.replace(regExp, '');
  } else {
    return phone;
  }
}