import axios from 'axios';
import { AppOneTimeCode } from '../../views/AppOrders/AppOrders';

export function getPublicOffers(imageId: string, productId: string) {
  return axios.get(`${window.apiBase}/public/images/${imageId}/products/${productId}/offers`).then(response => {
    return response.data;
  });
}

export function addPublicOfferProduct(oneTimeCode: AppOneTimeCode, data: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/addOfferProduct`, data)
    .then(response => {
      return response.data;
    });
}
