import * as React from 'react';
import { Profile } from '../../../../../models/profile';
import { Field } from 'formik';
import { Switch } from '../../../../../components/Switch/Switch';
import { NOTIFICATION_CHANNELS_TYPE } from '../../../../../consts/notificationChannels';

interface Props {
  profile: Profile;
  onEditMyDetails: () => void;
  onEditChangePassword: () => void;
  onDeleteProfileClick: () => void;
}

export function CustomerMyDetailsTab(props: Props) {
  const { profile, onEditMyDetails, onEditChangePassword, onDeleteProfileClick } = props;
  const emailChannels = profile.notificationChannels.filter(
    channel => channel.type === NOTIFICATION_CHANNELS_TYPE.EMAIL
  );
  const isExistEmailChannels = emailChannels.length > 0;
  const smsChannels = profile.notificationChannels.filter(channel => channel.type === NOTIFICATION_CHANNELS_TYPE.SMS);
  const isExistSmsChannels = smsChannels.length > 0;

  return (
    <div className={'bCustomerMyDetailsTab'}>
      <div className={'mPrimaryText eCustomerMyDetailsTabTitle'}>Name</div>
      <div className={'mSecondaryText eCustomerMyDetailsTabText'}>
        {profile.firstName || ''} {profile.lastName || ''}
      </div>
      <div className={'mPrimaryText eCustomerMyDetailsTabTitle'}>Email address:</div>
      <div className={'mSecondaryText eCustomerMyDetailsTabText'}>{profile.email || ''}</div>
      <div className={'mPrimaryText eCustomerMyDetailsTabTitle'}>Contact number:</div>
      <div className={'mSecondaryText eCustomerMyDetailsTabText'}>{profile.phone || ''}</div>
      <div className="eCustomerMyDetailsTabText">
        <Switch
          value={isExistEmailChannels}
          name={'email_notifications'}
          onChange={() => {}}
          disabled
          text={'Email notifications'}
          customClass="mb-3"
        />
      </div>
      <div className="eCustomerMyDetailsTabText">
        <Switch
          value={isExistSmsChannels}
          name={'sms_notifications'}
          onChange={() => {}}
          disabled
          text={'SMS notifications'}
          customClass="mb-3"
        />
      </div>
      <div className={'mPrimaryText eCustomerMyDetailsTabTitle'}>Marketing emails:</div>
      <div className={'mSecondaryText eCustomerMyDetailsTabText'}>
        {profile.agreement.promoEmail ? 'I agree to receive' : 'I disagree to receive'}
      </div>
      <div>
        <button className="eCustomerMyDetailsTabButton" onClick={onEditMyDetails}>
          Edit
        </button>
        <button className="eCustomerChangePasswordFormButton" onClick={onEditChangePassword}>
          Change password
        </button>
        <button className="eCustomerDeleteProfileButton" onClick={onDeleteProfileClick}>
          Delete Profile
        </button>
      </div>
    </div>
  );
}
