import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import * as propz from 'propz';

export function getOrders(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/orders`, config).then(response => {
    return response.data;
  });
}

export function getAllOrders(user: AppUser, where?) {
  const { sessionKey: key } = user;
  const hard = propz.get(user, ['config', 'gridLimits', 'hard']);

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: hard,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: hard,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders`, config).then(response => {
    return response.data;
  });
}

export function getOrdersCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/count`, config).then(response => {
    return response.data;
  });
}

export function addOrder(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/orders`, data, config).then(response => {
    return response.data;
  });
}

export function updateOrder(user: AppUser, orderId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/orders/${orderId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteOrder(user: AppUser, orderId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/orders/${orderId}`, config).then(response => {
    return response.data;
  });
}

export function getOrderById(user: AppUser, orderId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/orders/${orderId}`, config).then(response => {
    return response.data;
  });
}

export function downloadCSVOrdersToServer(user: AppUser, formData, uploadType: string) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`${window.apiBase}/admin/system/${uploadType}/export`, formData, config).then(response => {
    return response.data;
  });
}

export function getPDFOrdersToServer(user: AppUser, taskId: string) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
    },
  };

  return axios.get(`${window.apiBase}/admin/orderPdf/${taskId}`, config).then(response => {
    return response.data;
  });
}

export function getOrderSchools(user: AppUser, orderId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/orders/${orderId}/schools`, config).then(response => {
    return response.data;
  });
}
