import * as React from 'react';
import { PromotedProductsSlider } from '../../../components/Slider/PromotedProductsSlider/PromotedProductsSlider';

interface Props {
  promotedProductsSlider: any;
}

export function OneTimeCodeWaitingReady(props: Props) {
  const { promotedProductsSlider } = props;
  const { images, delay } = promotedProductsSlider;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="bOneTimeCodeWaitingReady">
              <div className={'eOneTimeCodeWaitingReadyTitle'}>SEE YOU SOON</div>
              <div className={'eOneTimeCodeWaitingReadyText'}>
                Whilst you wait why not check out our most popular products.
              </div>
            </div>
          </div>
        </div>
      </div>
      {images.length > 0 && <PromotedProductsSlider images={images} delay={delay} />}
    </>
  );
}
