import * as React from 'react';
import { SchoolAppTopNavigation2 } from '../SchoolAppTopNavigation2/SchoolAppTopNavigation2';
import { AppSchoolsUser2 } from '../AppSchools2';
import { SchoolAppSidebar2 } from '../SchoolAppSidebar2/SchoolAppSidebar2';
import { Route, Switch } from 'react-router';
import { schoolUserAdminRoutes, schoolUserNotAdminRoutes } from '../../../routing/routing';
import '../appSchools2.css';

interface Props {
  user: AppSchoolsUser2;
  onLogoutClick: (event) => void;
  history: any;
  location: any;
}

export class SchoolAppGenericView2 extends React.Component<Props> {
  render() {
    const { user, history, onLogoutClick, location } = this.props;
    const { isSchoolAdmin } = user;

    const routes = isSchoolAdmin ? schoolUserAdminRoutes : schoolUserNotAdminRoutes;

    return (
      <div className="schoolAppContainer2">
        <SchoolAppTopNavigation2 user={user} history={history} onLogoutClick={onLogoutClick} />
        <div className="sidebar-and-content-wrapper">
          <div className="bSchoolAppSidebar2">
            <SchoolAppSidebar2 user={user} />
          </div>
          <div className="schoolAppContent2">
            <Switch>
              {routes.map((route, i) => (
                <Route
                  exact={route.exact}
                  key={`route_${i}`}
                  path={route.path}
                  render={routeProps => <route.component user={user} history={history} location={location} />}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
