import StorageHelper from './storage';

export function setGalleryTicketIds(gallery: any[]): void {
  StorageHelper.LocalStorage.set('gallery', gallery);
}

export function getGalleryTicketIds() {
  return StorageHelper.LocalStorage.get('gallery');
}

export function clearGalleryTicketIds(): void {
  StorageHelper.LocalStorage.remove('gallery');
}

export function setTickets(tickets: any[]): void {
  StorageHelper.LocalStorage.set('tickets', tickets);
}

export function getTickets() {
  return StorageHelper.LocalStorage.get('tickets');
}

export function clearTickets(): void {
  StorageHelper.LocalStorage.remove('tickets');
}
