import axios from 'axios';
import { AppCustomer } from '../../views/AppOrders/AppOrders';

export function addCustomerStudents(customer: AppCustomer, schoolId: string, formId: string, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/schools/${schoolId}/forms/${formId}/students`, data, config)
    .then(response => {
      return response.data;
    });
}

export function checkedCustomerStudent(customer: AppCustomer, schoolId: string, formId: string, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/schools/${schoolId}/forms/${formId}/studentCheck`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeCustomerStudents(customer: AppCustomer, studentId: string) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/customer/students/${studentId}`, config).then(response => {
    return response.data;
  });
}
