export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const FILTER_TYPE = {
  NONE: 'NONE',
  TEXT: 'TEXT',
  NUMBER_INTERVAL: 'NUMBER_INTERVAL',
  SELECT: 'SELECT',
  MULTISELECT: 'MULTISELECT',
  DATE_INTERVAL: 'DATE_INTERVAL',
  TIME_INTERVAL: 'TIME_INTERVAL',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  TEXT_WITH_CHECKBOX: 'TEXT_WITH_CHECKBOX',
};

export const DATE_INTERVAL = {
  TO: 'TO',
  FROM: 'FROM',
};

export const NUMBER_INTERVAL = {
  TO: 'TO',
  FROM: 'FROM',
};

export const CHECKBOX_FIELDS = {
  TEXT: 'TEXT',
  CHECKBOX: 'CHECKBOX',
};

export const FIRST_PAGE = 1;
export const LIMIT = 20;
export const ALL_LIMIT = 100000;
