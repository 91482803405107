import * as React from 'react';
import { FunctionComponent } from 'react';
import { ColumnDefinition, SelectOption } from '../../helpers/table';
import { CHECKBOX_FIELDS, DATE_INTERVAL, FILTER_TYPE } from '../../consts/table';
import { MultiSelectField } from './MultiSelectField/MultiselectField';
import { SelectField } from './SelectField/SelectField';

import { TimeIntervalField } from './TimeIntervalField/TimeIntervalField';
import { DateIntervalField } from './DateIntervalField/DateIntervalField';
import { NumberIntervalField } from './NumberIntervalField/NumberIntervalField';
import { SelectOptions } from './Grid';
import { AutocompleteFilter } from './AutocompleteFilter/AutocompleteFilter';

interface Props {
  columns: ColumnDefinition[];
  filters: any;
  onTableFilterChange: (event, value: string, options?) => void;
  options?: SelectOptions;
  autocompleteSearchFunctions?: any;
  autocompleteGetElementsTitles?: any;
  onTableAutocompleteFilterChange?: (item: any, field: string) => any;
  autocompleteDefaultTexts?: any;
  onApplyFilterClick?: () => void;
}

// 13 it's enter button key code in Event object.
const ENTER_BUTTON_KEY = 13;

export const FilterRow: FunctionComponent<Props> = props => {
  const {
    filters,
    columns,
    options,
    onTableAutocompleteFilterChange,
    onTableFilterChange,
    autocompleteSearchFunctions,
    autocompleteGetElementsTitles,
    autocompleteDefaultTexts,
    onApplyFilterClick,
  } = props;
  const row = columns.map((column, index) => {
    const { type, field, text } = column;

    const onEnterPress = event => {
      event.keyCode === ENTER_BUTTON_KEY && onApplyFilterClick();
    };

    switch (type) {
      case FILTER_TYPE.NONE: {
        return <th key={`${text}_filter_${index}`} />;
      }
      case FILTER_TYPE.AUTOCOMPLETE: {
        return (
          <th key={`${text}_filter_${index}`}>
            <AutocompleteFilter
              searchFunction={autocompleteSearchFunctions[field]}
              getElementTitle={autocompleteGetElementsTitles[field]}
              customClass="mMaxWidth250"
              defaultText={autocompleteDefaultTexts[field]}
              onEnterPress={onEnterPress}
              onSelect={item => {
                onTableAutocompleteFilterChange(item, field);
              }}
            />
          </th>
        );
      }
      case FILTER_TYPE.TEXT: {
        return (
          <th key={`${text}_filter_${index}`}>
            <input
              onChange={event => {
                onTableFilterChange(event, field);
              }}
              onKeyDown={onEnterPress}
              value={filters[field]}
              className="form-control mMaxWidth250 mWidthAuto"
            />
          </th>
        );
      }
      case FILTER_TYPE.TEXT_WITH_CHECKBOX: {
        return (
          <th key={`${text}_filter_${index}`}>
            <input
              onChange={event => {
                onTableFilterChange(event, field, CHECKBOX_FIELDS.TEXT);
              }}
              value={filters[field].value}
              onKeyDown={onEnterPress}
              className="form-control mMaxWidth250"
              disabled={filters[field].checked}
            />
            <>
              <input
                id={field}
                className={'mr-2 mt-2'}
                type="checkbox"
                checked={filters[field].checked}
                onKeyDown={onEnterPress}
                onChange={event => {
                  onTableFilterChange(event, field, CHECKBOX_FIELDS.CHECKBOX);
                }}
              />
              <label style={{ userSelect: 'none', fontWeight: 'normal' }} htmlFor={field}>
                Show only empty entries
              </label>
            </>
          </th>
        );
      }
      case FILTER_TYPE.SELECT: {
        return (
          <th key={`${text}_filter_${index}`}>
            <SelectField
              items={options[field]}
              field={field}
              onChange={onTableFilterChange}
              onEnterPress={onEnterPress}
              value={filters[field]}
              isEmptyOptionRequired
            />
          </th>
        );
      }
      case FILTER_TYPE.MULTISELECT: {
        return (
          <th key={`${text}_filter_${index}`}>
            <MultiSelectField
              items={options[field]}
              field={field}
              onChange={onTableFilterChange}
              onEnterPress={onEnterPress}
              value={filters[field]}
            />
          </th>
        );
      }
      case FILTER_TYPE.DATE_INTERVAL: {
        return (
          <th key={`${text}_filter_${index}`}>
            <DateIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.NUMBER_INTERVAL: {
        return (
          <th key={`${text}_filter_${index}`}>
            <NumberIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.TIME_INTERVAL: {
        return (
          <th key={`${text}_filter_${index}`}>
            <TimeIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
    }
  });

  return (
    <>
      <th />
      {row}
    </>
  );
};

FilterRow.displayName = 'FilterRow';
