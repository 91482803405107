import * as React from 'react';
import { YEAR_FILTER } from '../../../../../consts/images';
import './CustomerYearsFilter.scss';

interface Props {
  selectedPhotoYear: string;
  onClick: (string) => void;
}

export function CustomerYearsFilter(props: Props) {
  const { selectedPhotoYear, onClick } = props;

  const onYearClick = year => {
    if (year === 'All') {
      onClick(undefined);
    } else {
      onClick(year);
    }
  };

  return (
    <div className={'bCustomerYearsFilter'}>
      <div className={'eCustomerYearsFilterTitle'}>Year Taken</div>
      <div className="eCustomerYearsFilterTextWrapper">
        {YEAR_FILTER.map(year => {
          return (
            <div
              className={
                selectedPhotoYear === year || (year === 'All' && typeof selectedPhotoYear === 'undefined')
                  ? 'eCustomerYearsFilterText eTextSelected'
                  : 'eCustomerYearsFilterText'
              }
              key={`customerYearsFilterText_${year}`}
              onClick={() => onYearClick(year)}
            >
              {year}
            </div>
          );
        })}
      </div>
    </div>
  );
}
