import * as React from 'react';
import { FunctionComponent } from 'react';
import {
  getPackagePriceSetProducts,
  getPackagePriceSetProductsPrice,
} from '../../../../../../../helpers/accessor';

interface Props {
  priceSets: any[];
}

export const PackagePriceSetsSummary: FunctionComponent<Props> = props => {
  const { priceSets } = props;

  const rows = priceSets.map((priceSet, index) => {
    return (
      <tr key={`package_price_sets_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{priceSet.name}</td>
        <td>{priceSet.description}</td>
        <td>{getPackagePriceSetProducts(priceSet)}</td>
        <td>{getPackagePriceSetProductsPrice(priceSet)}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No price sets</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th />
          <th>Name</th>
          <th>Description</th>
          <th>Products</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

PackagePriceSetsSummary.displayName = 'PackagePriceSetsSummary';
