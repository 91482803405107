import * as React from 'react';
import * as propz from 'propz';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { PhotoType } from 'Src/models/photoTypes';
import { AppUser } from '../../../../../App';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { getSchoolNameAndCode, searchFunctionSchoolsByCode } from '../../../../../../../helpers/autocomplete';
import { SchoolJob } from '../../../../../../../models/schoolJob';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  job?: SchoolJob;
  photoTypes: PhotoType[];
  user: AppUser;
}

const SchoolJobSchema = Yup.object().shape({
  jobReference: Yup.string().required('Required'),
  typeId: Yup.string().required('Required'),
  school: Yup.object().shape({
    id: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
});

export function SchoolJobForm(props: Props) {
  const { job, onSubmit, onCancel, photoTypes, user } = props;
  const isJobExist = typeof job !== 'undefined';

  const getSchools = (text: string) => {
    return searchFunctionSchoolsByCode(user, text);
  };

  const getJobDate = () => {
    const jobDate = propz.get(job, ['jobDate']);
    if (typeof jobDate === 'undefined' || jobDate === null) {
      return new Date();
    } else {
      return new Date(jobDate);
    }
  };

  const jobForm = {
    jobReference: isJobExist ? propz.get(job, ['jobReference'], '') : '',
    jobDate: isJobExist ? getJobDate() : new Date(),
    typeId: isJobExist ? propz.get(job, ['photoType', 'typeId'], '') : '',
    school: {
      id: isJobExist ? propz.get(job, ['school', 'schoolId'], '') : '',
      name: isJobExist ? propz.get(job, ['school', 'name'], '') : '',
      code: isJobExist ? propz.get(job, ['school', 'code'], '') : '',
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={jobForm} validationSchema={SchoolJobSchema} onSubmit={onSubmit}>
            {({ setFieldValue, values, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Photographer Job Reference</label>
                    <Field name="jobReference" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="jobReference" />
                  </div>

                  <div className="form-group">
                    <label>Job Date</label>
                    <Field name="jobDate">
                      {({ field }) => (
                        <div className="mb-3">
                          <DatePicker
                            selected={field.value}
                            onChange={jobDate => {
                              setFieldValue('jobDate', jobDate);
                            }}
                            className="form-control"
                            dateFormat={'dd-MM-yyyy'}
                            customInput={
                              <MaskedInput
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="DD-MM-YYYY"
                              />
                            }
                          />
                        </div>
                      )}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="jobDate" />
                  </div>

                  <div className="form-group">
                    <label>Photo type</label>
                    <Field
                      name="typeId"
                      component="select"
                      placeholder="Select photo type"
                      className="form-control mb-3"
                      disabled={isJobExist}
                    >
                      <option value={''} />
                      {photoTypes.map(photoType => (
                        <option key={photoType.id} value={photoType.id}>
                          {photoType.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="typeId" />
                  </div>
                  <div className="form-group">
                    <label>School</label>
                    <Field name="school">
                      {() => (
                        <Autocomplete
                          searchFunction={getSchools}
                          getElementTitle={getSchoolNameAndCode}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.school}
                          disabled={isJobExist}
                          onSelect={school => {
                            setFieldValue('school', school);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="school.id" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
