import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const NUMBER_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  requireDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 3,
});

export const NUMBER_MASK_TO_WEIGHT = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 3,
  requireDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 1,
});
