import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { NotificationChannel } from '../../../../../../../../models/customers';
import {
  NOTIFICATION_CHANNELS_TYPE,
  NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING,
} from '../../../../../../../../consts/notificationChannels';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  notificationChannels: NotificationChannel[];
}

const notificationChannelsSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
});

export function CustomerNotificationChannelsForm(props: Props) {
  const { notificationChannels, onSubmit, onCancel } = props;
  const emailNotificationChannels = notificationChannels.find(channel => channel.type === 'EMAIL');
  const isEmailNotificationChannelsFormExist = typeof emailNotificationChannels !== 'undefined';
  const smsNotificationChannels = notificationChannels.find(channel => channel.type === 'SMS');
  const isSmsNotificationChannelsFormExist = typeof smsNotificationChannels !== 'undefined';

  const notificationChannelsForm = {
    type: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={notificationChannelsForm}
            validationSchema={notificationChannelsSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <div className="row m-0">
                      <label className="mt-2">Channel type</label>
                      <Field name="type" component="select" placeholder="Select type" className="form-control mb-3">
                        <option value={''} />
                        <option
                          value={NOTIFICATION_CHANNELS_TYPE.EMAIL}
                          disabled={isEmailNotificationChannelsFormExist}
                        >
                          {NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING.EMAIL}
                        </option>
                        <option value={NOTIFICATION_CHANNELS_TYPE.SMS} disabled={isSmsNotificationChannelsFormExist}>
                          {NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING.SMS}
                        </option>
                      </Field>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="type" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Create'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
