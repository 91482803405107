import * as React from 'react';
import { Order } from '../../../../../models/orders';
import { CURRENCY_SYMBOL } from '../../../../../consts/common';
import * as Moment from 'moment';
import { getOrderStatusCustomer } from '../../../../../helpers/accessor';
import { ORDER_STATUS } from '../../../../../consts/order';

interface Props {
  orders: Order[];
  onViewOrderClick: (orderId: string) => void;
  onPayOrderClick: (orderId: string) => void;
  onDownloadImagesClick: (orderId: string) => void;
}

export function CustomerOrderHistoryTab(props: Props) {
  const { orders, onViewOrderClick, onPayOrderClick, onDownloadImagesClick } = props;

  const rows = orders.map((order, index) => {
    const { orderStatus, isDownloadableImages } = order;
    const isStatusCanceled = orderStatus === ORDER_STATUS.CANCELED;
    const isStatusNew = orderStatus === ORDER_STATUS.NEW;
    const isShowPayButton = isStatusCanceled || isStatusNew;

    return (
      <tr key={`orders_summary_${index}`}>
        <td>{order.orderNumber}</td>
        <td>{Moment(order.createdAt).format('DD/MM/YY')}</td>
        <td>
          {CURRENCY_SYMBOL.POUND}
          {order.orderAmount}
        </td>
        <td>
          {getOrderStatusCustomer(order)} {Moment(order.statusUpdatedAt).format('DD/MM/YY')}
        </td>
        <td>
          <button
            onClick={() => {
              onViewOrderClick(order.id);
            }}
            className={'eCustomerOrderHistoryTabTableButton'}
          >
            View
          </button>
        </td>
        <td>
          {isShowPayButton && (
            <button
              onClick={() => {
                onPayOrderClick(order.id);
              }}
              className={'eCustomerOrderHistoryTabTableButton'}
            >
              Pay
            </button>
          )}
        </td>
        <td>
          {isDownloadableImages && (
            <button
              onClick={() => {
                onDownloadImagesClick(order.id);
              }}
              className={'eCustomerOrderHistoryTabTableDownloadButton'}
            >
              Download images
            </button>
          )}
        </td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'orders_empty_row'}>
      <td colSpan={6}>No orders</td>
    </tr>
  );

  return (
    <div className={'bCustomerOrderHistoryTab'}>
      <table className={'eCustomerOrderHistoryTabTable'}>
        <thead>
          <tr>
            <th>Order #</th>
            <th>Date</th>
            <th>Order Total</th>
            <th>Order Status</th>
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
      </table>
    </div>
  );
}
