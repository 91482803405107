import * as React from 'react';
import { AppUser } from '../../../../../../App';
import { Customer } from '../../../../../../../../models/customers';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from '../../../../../../../../components/Button/Button';
import * as Yup from 'yup';
import './CustomerStudentsForm.scss';
import { Autocomplete } from '../../../../../../../../components/Autocomplete/Autocomplete';
import {
  getForenameAndSurname,
  getFormName,
  searchFunctionForms,
  searchFunctionSchoolStudent,
  searchFunctionStudents,
} from '../../../../../../../../helpers/autocomplete';
import { Student } from '../../../../../../../../models/students';
import * as propz from 'propz';
import {
  REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING,
  REASON_FOR_CANCELLATION,
  STUDENT_REQUEST_STATUS,
} from '../../../../../../../../consts/customer';
import { getYesOrNoForBoolean } from '../../../../../../../../helpers/select';
import { getCustomerName } from '../../../../../../../../helpers/accessor';
import * as Moment from 'moment';
import { BIRTH_DATE_FORMAT } from '../../../../../../../../consts/consts';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  user: AppUser;
  customer: Customer;
  allCustomerStudents: Student[];
  onDeleteStudentClick: (id: string) => void;
  updateRequestStatusToNewCustomerStudentClick: (id: string) => void;
  updateRequestStatusNoMatchCustomerStudentClick: (id: string) => void;
}

export function CustomerStudentsForm(props: Props) {
  const {
    customer,
    onSubmit,
    onCancel,
    allCustomerStudents,
    onDeleteStudentClick,
    updateRequestStatusToNewCustomerStudentClick,
    updateRequestStatusNoMatchCustomerStudentClick,
  } = props;

  const studentsForm = {
    students: customer.students.map(student => ({
      studentId: '',
      forename: '',
      surname: '',
      formDisabled: true,
      commentEnabled: student.requestStatus === STUDENT_REQUEST_STATUS.POSTPONED,
      comment: propz.get(student, ['comment'], ''),
    })),
    forms: customer.students.map(student => ({
      formId: undefined,
      form: {
        id: '',
        name: '',
      },
    })),
  };

  const StudentsSchema = Yup.object().shape({
    students: Yup.array().of(
      Yup.object().shape({
        studentId: Yup.string().test('studentId', 'Required', function(value) {
          const index = this.options.index;
          const formId = this.from[1].value.forms[index].formId;
          const formDisabled = this.from[1].value.students[index].formDisabled;
          const isFormIdExist = typeof formId !== 'undefined' && formId !== '';
          const isValueExist = typeof value !== 'undefined' && value !== '';

          return isValueExist || formDisabled;
        }),
      })
    ),
    forms: Yup.array().of(
      Yup.object().shape({
        formId: Yup.string().test('formId', 'Required', function(value) {
          const index = this.options.index;
          const studentId = this.from[1].value.students[index].studentId;
          const formDisabled = this.from[1].value.students[index].formDisabled;
          const isStudentIdExist = typeof studentId !== 'undefined' && studentId !== '';
          const isValueExist = typeof value !== 'undefined' && value !== '';

          return !isStudentIdExist || isValueExist || (isValueExist && !formDisabled);
        }),
      })
    ),
  });

  const getStudents = (text: string, schoolId: string, setFieldValue, values, index) => {
    const { user } = props;
    const currentStudentsFromForm = values.students;
    const isNoTextEmpty = typeof text !== 'undefined' && text !== '';
    if (!isNoTextEmpty) {
      const students = [...values.students];
      students[index].studentId = '';
      students[index].forename = '';
      students[index].surname = '';
      setFieldValue('students', students);
    }
    const isFormIdExist = typeof values.forms[index].formId !== 'undefined' && values.forms[index].formId !== '';

    if (isFormIdExist) {
      return searchFunctionStudents(
        user,
        text,
        allCustomerStudents,
        schoolId,
        values.forms[index].form.id,
        currentStudentsFromForm
      );
    } else {
      return searchFunctionSchoolStudent(user, schoolId, allCustomerStudents, currentStudentsFromForm, text);
    }
  };

  const getForms = (text, schoolId, setFieldValue, values, index) => {
    const { user } = props;
    const forms = [...values.forms];
    forms[index].formId = '';
    setFieldValue('forms', forms);
    const studentId = values.students[index].studentId;
    const isStudentIdExist = typeof studentId !== 'undefined' && studentId !== '';
    if (!isStudentIdExist) {
      return searchFunctionForms(user, schoolId, text);
    } else {
      return [
        {
          name: values.forms[index].form.name,
          id: values.forms[index].form.id,
        },
      ];
    }
  };

  const renderAlreadyLinkedStudents = () => {
    return allCustomerStudents
      .filter(student => {
        const studentId = propz.get(student, ['student', 'studentId']);
        return typeof studentId !== 'undefined';
      })
      .map((student, index) => {
        const forename = propz.get(student, ['student', 'forename'], '');
        const surname = propz.get(student, ['student', 'surname'], '');
        const schoolName = propz.get(student, ['school', 'schoolName'], '');
        const parentCode = propz.get(student, ['school', 'parentCode'], '');
        const schoolData = parentCode !== '' ? `${parentCode} ${schoolName}` : schoolName;

        return (
          <div key={`${student.id}`}>
            <div>
              {index + 1}. {forename} {surname}
            </div>
            <div className="ml-3">School: {schoolData}</div>
          </div>
        );
      });
  };

  const customerName = getCustomerName(customer);

  return (
    <div>
      <div className="mb-4">
        <strong>Customer: </strong>
        {customerName}
      </div>
      <div className="mb-2">
        <strong>The students this customer is already linked to:</strong>
        {renderAlreadyLinkedStudents()}
      </div>
      <div>
        <strong>The students this customer has requested to be linked to:</strong>
      </div>
      <Formik initialValues={studentsForm} validationSchema={StudentsSchema} onSubmit={onSubmit}>
        {({ touched, errors, handleSubmit, values, setFieldValue }) => (
          <Form>
            <div className="form-group form-check">
              {customer.students.map((student, index) => {
                const {
                  forename,
                  surname,
                  school,
                  requestStatus,
                  form,
                  id,
                  isConfirmLegallyEntitled,
                  isLegalCarer,
                  isParentOrGuardian,
                  relation,
                  requestComment,
                  dayOfBorn,
                  birthday,
                  reasonForCancellation,
                } = student;
                const { schoolCode, schoolName, schoolId } = school;
                const isNewRequestStatus = requestStatus === STUDENT_REQUEST_STATUS.NEW;
                const isNoMatchRequestStatus = requestStatus === STUDENT_REQUEST_STATUS.NO_MATCH;
                const isConfirmLegallyEntitledExist = typeof isConfirmLegallyEntitled !== 'undefined';
                const isDayOfBornExist = typeof dayOfBorn !== 'undefined';
                const isBirthdayExist = typeof birthday !== 'undefined';
                const studentBirthday = isBirthdayExist ? Moment(new Date(birthday)).format(BIRTH_DATE_FORMAT) : '';
                const isReasonForCancellationExist = typeof reasonForCancellation !== 'undefined';
                const isAutoAcceptUnavailable =
                  reasonForCancellation === REASON_FOR_CANCELLATION.AUTO_ACCEPT_UNAVAILABLE;
                const customerReasonForCancellation =
                  REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING[reasonForCancellation];

                return (
                  <div key={id}>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <strong>Forename:</strong> {forename}
                        </div>
                        <div>
                          <strong>Surname:</strong> {surname}
                        </div>
                        {isReasonForCancellationExist && (
                          <div>
                            <strong>No auto-accept reason:</strong>{' '}
                            <span className={isAutoAcceptUnavailable ? 'mReasonAutoAcceptUnavailable' : ''}>
                              {customerReasonForCancellation}
                            </span>
                          </div>
                        )}
                        {isDayOfBornExist && isBirthdayExist && (
                          <div>
                            <strong>Student's DOB:</strong> {studentBirthday} ({dayOfBorn})
                          </div>
                        )}
                        {isConfirmLegallyEntitledExist && (
                          <>
                            <div>
                              <strong>Surname match:</strong> No
                            </div>
                            <div>
                              <strong>Parent / Guardian:</strong> {getYesOrNoForBoolean(isLegalCarer)}
                            </div>
                            <div>
                              <strong>Relation - Please state:</strong> {getYesOrNoForBoolean(isParentOrGuardian)}
                            </div>
                            <div>
                              <strong>Legal carer:</strong> {relation}
                            </div>
                            <div>
                              <strong>Other - Please state:</strong> {requestComment}
                            </div>
                          </>
                        )}
                        <div>
                          <strong>School:</strong> {schoolCode} {schoolName}
                        </div>
                        <div>
                          <strong>Status:</strong>{' '}
                          <span className={isNewRequestStatus ? 'mRequestStatusNew' : 'mRequestStatusPostponed'}>
                            {requestStatus}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end flex-column">
                        <div className="bStudentLinkedIconBlock text-right">
                          <img
                            className="mr-2 mCursorPointer"
                            width="30px"
                            src="/dist/images/icon/mapsAndFlags.png"
                            title="Approve"
                            onClick={() => {
                              const students = [...values.students];
                              const forms = [...values.forms];
                              const currentStudent = values.students[index];
                              const formDisabled = values.students[index].formDisabled;
                              if (!formDisabled) {
                                const emptyStudent = {
                                  studentId: '',
                                  forename: '',
                                  surname: '',
                                  formDisabled: true,
                                };
                                students.splice(index, 1, emptyStudent);
                                setFieldValue('students', students);

                                  const emptyForm = {
                                    formId: undefined,
                                  form: {
                                    id: '',
                                    name: '',
                                  },
                                };
                               forms.splice(index, 1, emptyForm);
                                setFieldValue('forms', forms);
                              } else {
                                currentStudent.formDisabled = !formDisabled;
                                currentStudent.commentEnabled = !formDisabled;
                                students.splice(index, 1, currentStudent);
                                setFieldValue('students', students);
                              }
                            }}
                          />
                          {isNewRequestStatus ? (
                            <img
                              className="mr-2 mCursorPointer"
                              width="30px"
                              src="/dist/images/icon/clock.png"
                              title="Postpone"
                              onClick={() => {
                                const students = [...values.students];
                                const forms = [...values.forms];
                                const commentEnabled = values.students[index].commentEnabled;

                                const emptyStudent = {
                                  studentId: '',
                                  forename: '',
                                  surname: '',
                                  formDisabled: true,
                                  commentEnabled: !commentEnabled,
                                };
                                students.splice(index, 1, emptyStudent);
                                setFieldValue('students', students);

                                  const emptyForm = {
                                    formId: undefined,
                                  form: {
                                    id: '',
                                    name: '',
                                  },
                                };
                                forms.splice(index, 1, emptyForm);
                                setFieldValue('forms', forms);
                              }}
                            />
                          ) : (
                            <img
                              className="mr-2 mCursorPointer"
                              width="30px"
                              src="/dist/images/icon/reverseArrow.png"
                              title="Back to New"
                              onClick={() => updateRequestStatusToNewCustomerStudentClick(id)}
                            />
                          )}
                          {!isNoMatchRequestStatus && (
                            <img
                              className="mr-2 mCursorPointer"
                              width="30px"
                              src="/dist/images/icon/information.png"
                              title="No match"
                              onClick={() => updateRequestStatusNoMatchCustomerStudentClick(id)}
                            />
                          )}
                          <img
                            className="mr-2 mCursorPointer"
                            width="30px"
                            src="/dist/images/icon/multiply.png"
                            title="Decline"
                            onClick={() => onDeleteStudentClick(id)}
                          />
                        </div>
                      </div>
                    </div>
                    {values.students[index].commentEnabled && (
                      <div className="form-group">
                        <label>Reason for postponing:</label>
                        <textarea
                          rows={4}
                          onChange={event => {
                            const commentText = event.target.value;
                            const students = [...values.students];
                            const currentStudent = values.students[index];
                            currentStudent.comment = commentText;
                            students.splice(index, 1, currentStudent);
                            setFieldValue('students', students);
                          }}
                          value={values.students[index].comment}
                          className="form-control mb-3"
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label>
                        <strong>Form: </strong>
                      </label>
                      <Field name="form">
                        {() => (
                          <Autocomplete
                            searchFunction={text => getForms(text, schoolId, setFieldValue, values, index)}
                            getElementTitle={getFormName}
                            disabled={values.students[index].formDisabled}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.forms[index]}
                            onSelect={form => {
                              const forms = [...values.forms].slice();
                              forms[index].formId = form.id;
                              forms[index].form.name = form.name;
                              forms[index].form.id = form.id;
                              setFieldValue('forms', forms);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name={`forms.${index}.formId`} />
                    </div>

                    <div className="form-group">
                      <label>
                        <strong>Student: </strong>
                      </label>
                      <Field name="student">
                        {() => (
                          <Autocomplete
                            searchFunction={text => getStudents(text, schoolId, setFieldValue, values, index)}
                            getElementTitle={getForenameAndSurname}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.students[index]}
                            disabled={values.students[index].formDisabled}
                            onSelect={student => {
                              const students = [...values.students];
                              students[index].studentId = student.id;
                              students[index].forename = student.forename;
                              students[index].surname = student.surname;
                              setFieldValue('students', students);
                              const isFormIdExist =
                                typeof values.forms[index].formId !== 'undefined' && values.forms[index].formId !== '';
                              if (!isFormIdExist) {
                                const forms = [...values.forms];
                                forms[index].formId = student.form.formId;
                                forms[index].form.name = student.form.formName;
                                forms[index].form.id = student.form.formId;
                                setFieldValue('forms', forms);
                              }
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger"
                        name={`students.${index}.studentId`}
                      />
                    </div>
                  </div>
                );
              })}

              <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
              <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
