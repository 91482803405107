import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ORDER_STATUS } from '../../consts/order';
import * as propz from 'propz';

const DEFAULT_ALL_IMAGES_FILTER: any = {
  'order.orderStatus': {
    $in: [ORDER_STATUS.PAID, ORDER_STATUS.DISPATCHED, ORDER_STATUS.DOWNLOADED],
  },
};

export function getOrderImages(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where,
          ...DEFAULT_ALL_IMAGES_FILTER,
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order,
      },
    },
  };

  return axios.get(`${window.apiBase}/admin/orders/images`, config).then(response => {
    return response.data;
  });
}

export function getAllOrderImages(user: AppUser, where?) {
  const { sessionKey: key } = user;
  const hard = propz.get(user, ['config', 'gridLimits', 'hard']);

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
            ...DEFAULT_ALL_IMAGES_FILTER,
          },
          limit: hard,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_ALL_IMAGES_FILTER,
          },
          limit: hard,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/images`, config).then(response => {
    return response.data;
  });
}

export function getOrderImagesCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
            ...DEFAULT_ALL_IMAGES_FILTER,
          },
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_ALL_IMAGES_FILTER,
          },
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/orders/images/count`, config).then(response => {
    return response.data;
  });
}
