import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';
import { AppSchoolsUser2 } from '../../views/AppSchools2/AppSchools2';

export function getSchoolUserSchoolJobs(user: AppSchoolsUser2, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/schoolUser/schoolJobs`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserAllSchoolJobs(user: AppSchoolsUser2, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/schoolUser/schoolJobs`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserSchoolJobsCount(user: AppSchoolsUser2, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/schoolUser/schoolJobs/count`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserSchoolJob(user: AppSchoolsUser2, schoolJobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}`, config).then(response => {
    return response.data;
  });
}

export function approveSchoolJob(user: AppSchoolsUser2, schoolJobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/approve`, {}, config).then(response => {
    return response.data;
  });
}

export function unApproveSchoolJob(user: AppSchoolsUser2, schoolJobId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/unApprove`, {}, config).then(response => {
    return response.data;
  });
}
