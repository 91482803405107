import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { History, Location } from 'history';
import { checkAvailability } from '../../../../services/public/register';
import { Button } from '../../../../components/Button/Button';
import { useState } from 'react';
import { getSelectCountryOptions } from '../../../../helpers/select';
import {getPhoneAndCode, getPhoneWithoutFirstZero} from "../../../../helpers/phone";

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  history: History;
  location: Location;
}

export function PhoneChangeForm(props: Props) {
  const { onSubmit, onCancel, history } = props;
  const { phone, code } = history.location.state as any;
  const isExistCode = typeof code !== 'undefined';

  const [phoneCode, setPhoneCode] = useState(isExistCode ? code : '+44');
  const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Required')
      .test('phone', 'Duplicate phone or incorrect symbol', function(value) {
        const phoneRegExp = /^\d+$|^\d+#\d+$/;
        const phone = getPhoneWithoutFirstZero(value);
        const result = phoneRegExp.test(phone);
        const phoneForCheck = `${phoneCode}${phone}`;

        return result
          ? checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            })
          : false;
      }),
  });

  return (
    <Formik
      initialValues={{ phone, phoneCode }}
      validationSchema={PhoneSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form>
          <div className="form-group form-check">
            <div className="form-group">
              <label>Phone code</label>
              <Field
                name="phoneCode"
                component="select"
                className="form-control"
                onChange={event => {
                  setPhoneCode(event.target.value);
                  setFieldValue('phoneCode', event.target.value);
                }}
              >
                {getSelectCountryOptions()}
              </Field>
            </div>

            <div className="form-group">
              <label>Phone</label>
              <Field name="phone" placeholder="Phone" className={'form-control mb-3'} />
              <ErrorMessage component="div" className="alert alert-danger" name="phone" />
            </div>
            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
