import axios from 'axios';
import { AppOneTimeCode } from '../../views/AppOrders/AppOrders';

export function getPublicPrereg(oneTimeCode: AppOneTimeCode) {
  const { username, password } = oneTimeCode;

  const config = {};

  return axios.get(`${window.apiBase}/public/${username}/${password}/prereg`, config).then(response => {
    return response.data;
  });
}
