import * as React from 'react';
import { FunctionComponent } from 'react';
import { ColumnDefinition, getColSpanNumber, TABLE_SORT_DIRECTION } from 'Src/helpers/table';
import { GridSortIcon } from './GridSortIcon';
import { GridFilterButtons } from './GridFilterButtons';
import { FilterRow } from './FilterRow';
import { SelectOptions } from './Grid';

interface Props {
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  columns: ColumnDefinition[];
  onSortClick: (sortField: string) => void;
  isShowFilter: boolean;
  onApplyFilterClick: () => void;
  onClearFilterClick: () => void;
  onTableFilterChange?: (event, value: string, options?) => void;
  filters?: any;
  options?: SelectOptions;
  autocompleteSearchFunctions?: any;
  autocompleteGetElementsTitles?: any;
  onTableAutocompleteFilterChange?: any;
  autocompleteDefaultTexts?: any;
}

export const GridTitle: FunctionComponent<Props> = props => {
  const {
    sortDirection,
    sortColumnsName,
    columns,
    onSortClick,
    isShowFilter,
    onApplyFilterClick,
    onClearFilterClick,
    filters,
    options,
    onTableFilterChange,
    autocompleteSearchFunctions,
    autocompleteGetElementsTitles,
    onTableAutocompleteFilterChange,
    autocompleteDefaultTexts,
  } = props;

  const sortClick = (event, field) => {
    event.preventDefault();
    onSortClick(field);
  };

  const colSpanNumber = getColSpanNumber(columns);

  const titles = columns.map((column, index) => {
    const { isSort, text, field } = column;

    if (isSort) {
      return (
        <th key={`${text}_sort_${index}`}>
          <a className="eHeadSort" href="" onClick={event => sortClick(event, field)}>
            {`${text} `}
            <GridSortIcon
              tableSortDirection={sortDirection}
              tableSortColumnsName={sortColumnsName}
              tableColumnName={field}
            />
          </a>
        </th>
      );
    } else {
      return <th key={`${text}_sort_${index}`}>{`${text}`}</th>;
    }
  });

  return (
    <thead>
      <tr>
        <th />
        {titles}
      </tr>
      {isShowFilter && (
        <>
          <tr>
            <FilterRow
              columns={columns}
              filters={filters}
              onTableFilterChange={onTableFilterChange}
              onApplyFilterClick={onApplyFilterClick}
              options={options}
              autocompleteSearchFunctions={autocompleteSearchFunctions}
              autocompleteGetElementsTitles={autocompleteGetElementsTitles}
              onTableAutocompleteFilterChange={onTableAutocompleteFilterChange}
              autocompleteDefaultTexts={autocompleteDefaultTexts}
            />
          </tr>
          <tr>
            <GridFilterButtons
              onApplyFilterClick={onApplyFilterClick}
              onClearFilterClick={onClearFilterClick}
              colSpanNumber={colSpanNumber}
            />
          </tr>
        </>
      )}
    </thead>
  );
};

GridTitle.displayName = 'GridTitle';
