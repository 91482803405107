import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';

export function getSchools(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/schools`, config).then(response => {
    return response.data;
  });
}

export function getAllSchools(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools`, config).then(response => {
    return response.data;
  });
}

export function getSchoolsCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/count`, config).then(response => {
    return response.data;
  });
}

export function getSchool(user: AppUser, schoolId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}`, config).then(response => {
    return response.data;
  });
}

export function addSchool(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schools`, data, config).then(response => {
    return response.data;
  });
}

export function updateSchool(user: AppUser, schoolId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/admin/schools/${schoolId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteSchool(user: AppUser, schoolId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/admin/schools/${schoolId}`, config).then(response => {
    return response.data;
  });
}

export function assignSchoolType(user: AppUser, schoolId: string, data: string[]) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schools/${schoolId}/addTypes`, data, config).then(response => {
    return response.data;
  });
}

export function unassignSchoolType(user: AppUser, schoolId: string, data: string[]) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schools/${schoolId}/removeTypes`, data, config).then(response => {
    return response.data;
  });
}

export function checkParentCode(user: AppUser, parentCode: string, schoolId?: string) {
  const { sessionKey: key } = user;
  const config = {
    headers: { usid: key },
  };
  const body = schoolId ? { parentCode, schoolId } : { parentCode };
  return axios.post(`${window.apiBase}/admin/schools/parentCode/check`, body, config).then(response => {
    return response.data;
  });
}
