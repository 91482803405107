import * as React from 'react';
import { PromotedProductsSlider } from '../../../../components/Slider/PromotedProductsSlider/PromotedProductsSlider';
import './PopularProducts.scss';

interface Props {
  promotedProductsSlider: any;
}

export function PopularProducts(props: Props) {
  const { promotedProductsSlider } = props;
  const { images, delay } = promotedProductsSlider;
  return (
    <div className="bPopularProductsWrapper mb-5">
      {images.length > 0 && (
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bPopularProductsTitle">Our Most Popular Products</div>
              <PromotedProductsSlider images={images} delay={delay} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
