/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
export const HTML_EDITOR_QUILL_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const HTML_EDITOR_QUILL_FORMATS = [
  'header',
  'font',
  'size',
  'background',
  'color',
  'code',
  'script',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'header',
  'align',
  'direction',
  'list',
  'bullet',
  'indent',
  'code-block',
  'formula',
  'link',
  'image',
  'video',
];

export const HTML_FIRST_PART_PAGE_CODE =
  '<!DOCTYPE html>\n' +
  '<html>\n' +
  '<head>\n' +
  '    <style>\n' +
  '        .ql-align-center {text-align: center;}\n' +
  '        .ql-align-right   {text-align: right;}\n' +
  '        .ql-align-left    {text-align: left;}\n' +
  '        .ql-align-justify {text-align: justify;}\n' +
  '        .ql-size-huge {font-size: 2.5em;}\n' +
  '        .ql-size-small{font-size: 0.75em;}\n' +
  '        .ql-size-large {font-size: 1.5em;}\n' +
  '        .ql-font-serif    {font-family: Georgia, Times New Roman, serif;}\n' +
  '        .ql-font-monospace    {font-family: Monaco, Courier New, monospace;}\n' +
  '    </style>\n' +
  '</head>\n' +
  '<body>';

export const HTML_LAST_PART_PAGE_CODE = '</body>\n' + '</html>';

export const EMPTY_STRINGS_IN_EDITOR = [
  '<p><br></p>',
  '<blockquote><br></blockquote>',
  '<pre class="ql-syntax" spellcheck="false"> </pre>',
  '<ol><li><br></li></ol>',
  '<ul><li><br></li></ul>',
  '<p class="ql-align-center"><br></p>',
  '<h1><br></h1>',
  '<h2><br></h2>',
  '<h3><br></h3>',
  '<h4><br></h4>',
  '<h5><br></h5>',
];
