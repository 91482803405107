import * as React from 'react';
import * as propz from 'propz';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { SliderImage } from '../../../../../../../../models/slider';
import { AppUser } from '../../../../../../App';
import { Switch } from '../../../../../../../../components/Switch/Switch';
import { uploadFile } from '../../../../../../../../services/file';
import { getOrdersSiteLink } from '../../../../../../../../helpers/link';
import { SIZE_UPLOAD_SLIDER_IMAGES } from '../../../../../../../../consts/images';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  sliderImage?: SliderImage;
  sliderType: string;
  user: AppUser;
}

const SliderSchema = Yup.object().shape({
  pic: Yup.string().required('Required'),
});

export function SliderImageForm(props: Props) {
  const { sliderImage, sliderType, onSubmit, onCancel } = props;
  const isSliderExist = typeof sliderImage !== 'undefined';
  const isPicUrlExists = isSliderExist && typeof sliderImage.pic !== 'undefined' && sliderImage.pic !== '';
  const sliderForm = {
    isActive: isSliderExist ? propz.get(sliderImage, ['isActive'], false) : false,
    pic: isSliderExist ? propz.get(sliderImage, ['pic'], '') : '',
    link: isSliderExist ? propz.get(sliderImage, ['link'], '') : '',
  };

  const link = getOrdersSiteLink();
  const sizeUploadImages = SIZE_UPLOAD_SLIDER_IMAGES[sliderType];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={sliderForm} validationSchema={SliderSchema} onSubmit={onSubmit}>
            {({ setFieldValue, values, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Link</label>
                    <div className="form-group row mGutters">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{link}</div>
                        </div>
                        <Field name="link" className="form-control" />
                      </div>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="link" />
                  </div>
                  <div className="form-group">
                    <Field name="isActive">
                      {({ field }) => <Switch {...field} text="is Active" customClass="ml-3" />}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label>Picture</label>
                    <div className="mb-3">
                      <i>For best results, upload an image that is {sizeUploadImages}</i>
                    </div>
                    <Field name="pic">
                      {() => (
                        <input
                          type="file"
                          className="form-control-file mMarginBottom28"
                          onChange={event => {
                            const { user } = props;
                            const file = event.target.files[0];
                            const formData = new FormData();
                            formData.append('file', file);
                            uploadFile(user, formData).then(data => {
                              setFieldValue('pic', data.url);
                            });
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="pic" />
                  </div>
                  {typeof values.pic !== 'undefined' && values.pic !== '' && (
                    <div className="w-25">
                      <button
                        type="button"
                        className="close"
                        onClick={() => {
                          setFieldValue('pic', '');
                        }}
                      >
                        <span>&times;</span>
                      </button>
                      <img className="img-fluid img-thumbnail" src={values.pic} alt="Slider image" />
                    </div>
                  )}
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
