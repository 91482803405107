import { AppUser } from '../views/App/App';
import { USER_TYPE } from '../consts/user';

export function isUserTypeSuperAdmin(user: AppUser): boolean {
  const { type } = user;
  return type === USER_TYPE.SUPERADMIN;
}

export function isUserTypeAdmin(user: AppUser): boolean {
  const { type } = user;
  return type === USER_TYPE.ADMIN;
}

export function isUserTypeAccountManager(user: AppUser): boolean {
  const { type } = user;
  return type === USER_TYPE.ACCOUNT_MANAGER;
}

export function isUserTypeUser(user: AppUser): boolean {
  const { type } = user;
  return type === USER_TYPE.USER;
}
