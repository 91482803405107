import * as React from 'react';
import { Component } from 'react';
import { ProgressBar } from '../../../../../../../components/ProgressBar/ProgressBar';
import { ImageWizardModal } from './ImageWizardModal/ImageWizardModal';
import ImageWizardEmail from './ImageWizardEmail/ImageWizardEmail';
import ImageWizardEditor from './ImageWizardEditor/ImageWizardEditor';
import ImageWizardPreview from './ImageWizardPreview/ImageWizardPreview';
import { AppUser } from '../../../../../App';

interface Props {
  onCancelClick: () => void;
  isOpen: boolean;
  onSendNotificationModalClick: () => void;
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (body: any) => void;
  onRemoveAttachment: (event) => void;
  onEmailAttachmentChange: (event) => void;
  isError: boolean;
  isLoadingFile: boolean;
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
  isRawEditor: boolean;
  onRawEditorChange: (event) => void;

  onEmailChange: (email: string) => void;
  wizardEmail: string;

  user: AppUser;
}

interface State {
  currentStep: number;
  isEmailError: boolean;
}

const IMAGE_MODAL_TOTAL_STEP: number = 3;

export class ImageWizard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentStep: 1,
      isEmailError: false,
    };
  }

  // step callbacks
  onNextClick = (): void => {
    const validateEmail = email => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (validateEmail(this.props.wizardEmail)) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep + 1,
        isEmailError: false,
      }));
    } else {
      this.setState({
        isEmailError: true,
      });
    }
  };

  onPreviousClick = (): void => {
    const { currentStep } = this.state;

    if (currentStep === 1) {
      return;
    }

    this.setState({
      currentStep: currentStep - 1,
    });
  };

  getProgress(current: number, total: number): number {
    return (current / total) * 100;
  }

  renderViewByStep(): React.ReactNode {
    const { currentStep, isEmailError } = this.state;
    const {
      messageEmailSubject,
      messageEmailBody,
      messageAttachments,
      isError,
      isLoadingFile,
      onEmailSubjectChange,
      onEmailBodyChange,
      onRemoveAttachment,
      onEmailAttachmentChange,
      isRawEditor,
      onRawEditorChange,
      wizardEmail,
      onEmailChange,
      user,
    } = this.props;
    switch (currentStep) {
      case 1:
        return (
          <ImageWizardEmail
            user={user}
            wizardEmail={wizardEmail}
            onEmailChange={onEmailChange}
            isEmailError={isEmailError}
          />
        );
      case 2:
        return (
          <ImageWizardEditor
            onEmailSubjectChange={onEmailSubjectChange}
            onEmailBodyChange={onEmailBodyChange}
            onEmailAttachmentChange={onEmailAttachmentChange}
            onRemoveAttachment={onRemoveAttachment}
            isError={isError}
            isLoadingFile={isLoadingFile}
            messageEmailSubject={messageEmailSubject}
            messageEmailBody={messageEmailBody}
            messageAttachments={messageAttachments}
            isRawEditor={isRawEditor}
            onRawEditorChange={onRawEditorChange}
          />
        );
      case 3:
        return (
          <ImageWizardPreview
            messageAttachments={messageAttachments}
            messageEmailBody={messageEmailBody}
            messageEmailSubject={messageEmailSubject}
          />
        );
    }
  }

  render() {
    const { isOpen, onCancelClick, onSendNotificationModalClick } = this.props;
    const { currentStep } = this.state;

    return (
      <ImageWizardModal
        isOpen={isOpen}
        title={'New message'}
        closeButtonText={'Close'}
        submitButtonText={'Next step'}
        onCloseClick={onCancelClick}
        onPreviousClick={this.onPreviousClick}
        onNextClick={this.onNextClick}
        onSendClick={onSendNotificationModalClick}
        hasNext={currentStep !== IMAGE_MODAL_TOTAL_STEP}
        hasPrevious={currentStep > 1}
      >
        <ProgressBar
          progress={this.getProgress(currentStep, IMAGE_MODAL_TOTAL_STEP)}
          step={currentStep}
          stepTotal={IMAGE_MODAL_TOTAL_STEP}
        />
        {this.renderViewByStep()}
      </ImageWizardModal>
    );
  }
}
