import { AppCustomer } from '../../views/AppOrders/AppOrders';
import axios from 'axios';

export function changePhone(customer: AppCustomer, phone: string) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/customer/${customerId}/phone`, { phone: phone }, config).then(response => {
    return response.data;
  });
}

export function resendPhoneCode(customer: AppCustomer) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/${customerId}/phone/resend`, {}, config).then(response => {
    return response.data;
  });
}

export function changeEmail(customer: AppCustomer, email: string) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/customer/${customerId}/email`, { email: email }, config).then(response => {
    return response.data;
  });
}

export function resendEmailCode(customer: AppCustomer) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/${customerId}/email/resend`, {}, config).then(response => {
    return response.data;
  });
}

export function addAccessCode(customer: AppCustomer, username: string, password: string) {
  const { sessionKey: key, customerId } = customer;
  const config = {
    headers: { usid: key },
  };
  return axios
    .post(`${window.apiBase}/customer/${customerId}/permissions`, { username: username, password: password }, config)
    .then(response => {
      return {
        status: response.status,
      };
    });
}

export function getCustomerProfile(customer: AppCustomer) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/profile`, config).then(response => {
    return response.data;
  });
}

export function getCustomerTokensExist(customer: AppCustomer) {
  const { sessionKey: key, customerId } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.get(`${window.apiBase}/customer/${customerId}/getTokens`, config).then(response => {
    return response.data;
  });
}

export function checkCustomerSession(key: string) {
  const config = {
    headers: { usid: key },
  };

  return axios
    .get(`${window.apiBase}/customer/check`, config)
    .then(response => {
      return response.status;
    })
    .catch(error => {
      return error.response.status;
    });
}

export function updateCustomerProfile(customer: AppCustomer, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/customer/profile`, data, config).then(response => {
    return response.data;
  });
}

export function setTermsAndConditionsAgreement(customer: AppCustomer) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/agreement/termsAndConditions`, {}, config).then(response => {
    return response.data;
  });
}

export function setPromoEmailAgreement(customer: AppCustomer, promoEmail) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/agreement/promoEmail`, { promoEmail: promoEmail }, config)
    .then(response => {
      return response.data;
    });
}

export function deleteCustomerProfile(customer: AppCustomer) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/customer/profile`, config).then(response => {
    return response.data;
  });
}