import * as React from 'react';
import { Component } from 'react';
import { History } from 'history';
import { getFromHistory } from '../../../helpers/history';

interface Props {
  history: History;
  setDataFromOrder: (order: { username: string; password: string; imageId: string }, history: History) => void;
}

interface State {}

export class Order extends Component<Props, State> {
  componentDidMount() {
    const { history, setDataFromOrder } = this.props;
    const username = getFromHistory(history, 'username');
    const password = getFromHistory(history, 'password');
    const imageId = getFromHistory(history, 'imageId');

    const order = {
      username,
      password,
      imageId,
    };

    setDataFromOrder(order, history);
  }

  render() {
    return null;
  }
}
