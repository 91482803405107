import * as React from 'react';
import { DeliveryCharge } from 'Src/models/deliveryCharges';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import { getCurrencyMask } from '../../../../../../../helpers/currencyMask';
import MaskedInput from 'react-text-mask';
interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  deliveryCharge?: DeliveryCharge;
}

const DeliveryChargeSchema = Yup.object().shape({
  minimumDeliveryCharge: Yup.string()
    .required('Required')
    .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
  maximumDeliveryCharge: Yup.string()
    .required('Required')
    .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
  internationalDeliveryCharge: Yup.string()
    .required('Required')
    .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
  courierDeliveryCharge: Yup.string()
    .required('Required')
    .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
});

export function DeliveryChargeForm(props: Props) {
  const { deliveryCharge, onSubmit, onCancel } = props;
  const isDeliveryChargeExist = typeof deliveryCharge !== 'undefined';
  const deliveryChargeForm = {
    minimumDeliveryCharge: isDeliveryChargeExist ? deliveryCharge.minimumDeliveryCharge : '',
    maximumDeliveryCharge: isDeliveryChargeExist ? deliveryCharge.maximumDeliveryCharge : '',
    internationalDeliveryCharge: isDeliveryChargeExist ? deliveryCharge.internationalDeliveryCharge : '',
    courierDeliveryCharge: isDeliveryChargeExist ? deliveryCharge.courierDeliveryCharge : '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={deliveryChargeForm} validationSchema={DeliveryChargeSchema} onSubmit={onSubmit}>
            {({ touched, errors, setFieldValue, values, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <div className="row mb-2">
                      <label className="col-6 col-form-label">Minimum delivery charge</label>
                      <div className="input-group col-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                        </div>
                        <Field name="minimumDeliveryCharge">
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              className="form-control"
                              mask={getCurrencyMask}
                              onChange={event => {
                                setFieldValue(`minimumDeliveryCharge`, event.target.value);
                              }}
                              value={values.minimumDeliveryCharge}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="minimumDeliveryCharge" />
                  </div>
                  <div className="form-group">
                    <div className="row mb-2">
                      <label className="col-6 col-form-label">Maximum delivery charge</label>
                      <div className="input-group col-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                        </div>
                        <Field name="maximumDeliveryCharge">
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              className="form-control"
                              mask={getCurrencyMask}
                              onChange={event => {
                                setFieldValue(`maximumDeliveryCharge`, event.target.value);
                              }}
                              value={values.maximumDeliveryCharge}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="maximumDeliveryCharge" />
                  </div>
                  <div className="form-group">
                    <div className="row mb-2">
                      <label className="col-6 col-form-label">International delivery charge</label>
                      <div className="input-group col-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                        </div>
                        <Field name="internationalDeliveryCharge">
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              className="form-control"
                              mask={getCurrencyMask}
                              onChange={event => {
                                setFieldValue(`internationalDeliveryCharge`, event.target.value);
                              }}
                              value={values.internationalDeliveryCharge}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="internationalDeliveryCharge" />
                  </div>

                  <div className="form-group">
                    <div className="row mb-2">
                      <label className="col-6 col-form-label">Courier delivery charge</label>
                      <div className="input-group col-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                        </div>
                        <Field name="courierDeliveryCharge">
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              className="form-control"
                              mask={getCurrencyMask}
                              onChange={event => {
                                setFieldValue(`courierDeliveryCharge`, event.target.value);
                              }}
                              value={values.courierDeliveryCharge}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage component="div" className="alert alert-danger" name="courierDeliveryCharge" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
