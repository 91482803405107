export const CUSTOM_NOTIFICATION_CREATING_STEPS = {
  CONTENT: 'CONTENT',
  PREVIEW: 'PREVIEW',
};

export const CUSTOM_NOTIFICATION_TYPE_ARRAY = ['EMAIL', 'ANDROID', 'IOS', 'SMS'];

export const CUSTOM_NOTIFICATION_TYPE = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  SMS: 'SMS',
};
