import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';
import '../Registration.scss';

interface Props {
  isOpen: boolean;
  onButtonClick: () => void;
}

export const RegistrationInfoModal: FunctionComponent<Props> = props => {
  const { isOpen, onButtonClick } = props;

  if (!isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: isOpen,
    show: isOpen,
    modal: true,
    fade: true,
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-xl-12">
          <div className={modalClasses}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={onButtonClick}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body mRegistrationInfoModalPreWrap">
                  We are delighted to bring you our new Quick Pics service, but we do require your email address and
                  contact number to ensure the highest level of security.
                  <br />
                  <br />
                  Quick Pics will allow us to supply you with a bespoke service ensuring that you never miss your
                  child’s school photographs, we are able to link any new images to your account within the schools
                  academic year. Please be aware that you will need to renew your ‘schools code’ annually.
                  <br />
                  <br />
                  We promise we will never pass your information to any third party and it is purely for the use of{' '}
                  <a href="https://www.bentleypics.com/" target="_blank">
                    www.bentleypics.com
                  </a>{' '}
                  website.
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={props.onButtonClick}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RegistrationInfoModal.displayName = 'RegistrationInfoModal';
