import * as React from 'react';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from '../../../../../App';
import { Component } from 'react';
import { asyncUploadZipFile, syncUploadZipFile, uploadZipFile } from '../../../../../../../services/superadmin/zip';
import { Job } from '../../../../../../../models/jobs';
import { Loader } from '../../../../../../../components/Loader/Loader';
import { syncUploadCsvInPreReg } from '../../../../../../../services/superadmin/csv';
import { CSV_TYPE } from '../../../../../../../consts/csv';

interface Props {
  onCancel: () => void;
  onShowStatusUploadImages: (isErrorStatus, data) => void;
  user: AppUser;
  job: Job;
}

interface State {
  file: any;
  isDisabledUploadButton: boolean;
  isLoading: boolean;
}

export class JobUploadImagesForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: undefined,
      isDisabledUploadButton: true,
      isLoading: false,
    };
  }

  onChooseZIPFile = event => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    this.setState({
      file: formData,
      isDisabledUploadButton: false,
    });
  };

  onAsyncUploadZIPChange = (): void => {
    const { user, job } = this.props;
    const { file } = this.state;
    this.setState({
      isDisabledUploadButton: true,
      isLoading: true,
    });
    let isErrorStatus;
    const uploadType = CSV_TYPE.JOBS;

    asyncUploadZipFile(user, job.id, uploadType, file)
      .then(data => {
        this.setState({
          isLoading: false,
        });
        isErrorStatus = false;
        this.props.onShowStatusUploadImages(isErrorStatus, data);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
        isErrorStatus = true;
        this.props.onShowStatusUploadImages(isErrorStatus, error);
        console.error('error');
        console.error(error);
      });
  };

  onSyncUploadZIPChange = (): void => {
    const { user, job } = this.props;
    const { file } = this.state;
    this.setState({
      isDisabledUploadButton: true,
      isLoading: true,
    });
    let isErrorStatus;
    const uploadType = CSV_TYPE.JOBS;

    syncUploadZipFile(user, job.id, uploadType, file)
      .then(data => {
        this.setState({
          isLoading: false,
        });
        isErrorStatus = false;
        this.props.onShowStatusUploadImages(isErrorStatus, data);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
        isErrorStatus = true;
        this.props.onShowStatusUploadImages(isErrorStatus, error);
        console.error('error');
        console.error(error);
      });
  };

  render() {
    const { isDisabledUploadButton, isLoading } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="attachment">Selected ZIP file</label>
              <input
                type="file"
                className="form-control-file"
                id="zip"
                disabled={isLoading}
                onChange={event => {
                  this.onChooseZIPFile(event);
                }}
              />
            </div>
            <div className="form-group">{isLoading && <Loader />}</div>
            <Button onClick={this.props.onCancel} text={'Close'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button
              onClick={() => {
                this.onSyncUploadZIPChange();
              }}
              text={'Upload'}
              customClass={'mt-3 mb-3 mr-3 btn btn-primary'}
              disabled={isDisabledUploadButton}
            />
            <Button
              onClick={() => {
                this.onAsyncUploadZIPChange();
              }}
              text={'Async Upload'}
              customClass={'mt-3 mb-3 btn btn-primary'}
              disabled={isDisabledUploadButton}
            />
          </div>
        </div>
      </div>
    );
  }
}
