import * as React from 'react';
import * as BPromise from 'bluebird';
import './CustomerAccessCode.scss';
import { AppCustomer } from '../../../AppOrders';
import { CustomerAccessCodeForm } from './CustomerAccessCodeForm';
import { addAccessCode } from '../../../../../services/customer/customer';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';
import { loginOneTimeCode } from '../../../../../helpers/auth';
import { CREATED_STATUS_CODE } from '../../../../../consts/common';
import * as propz from 'propz';
import { getTicketsCount } from 'Src/services/public/images';

interface Props {
  customer: AppCustomer;
  setInitialDataStructure: any;
}

interface State {
  username: string;
  password: string;
  isSuccessModalOpen: boolean;
  isWaitingModalOpen: boolean;
  isFallModalOpen: boolean;
  infoMessage: string;
  isLoading: boolean;
}

export class CustomerAccessCode extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      isSuccessModalOpen: false,
      isWaitingModalOpen: false,
      isFallModalOpen: false,
      infoMessage: '',
      isLoading: false,
    };
  }

  onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ username: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { username, password } = this.state;
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    addAccessCode(customer, username, password)
      .then(({ status }) => {
        if (status === CREATED_STATUS_CODE) {
          return getTicketsCount({ username, password });
        }
      })
      .then(countObj => {
        const { count } = countObj;

        if (count > 0) {
          this.reloadPhotos();
        } else {
          this.setState({
            isLoading: false,
            isWaitingModalOpen: true,
            username: '',
            password: '',
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isFallModalOpen: true,
          infoMessage: errorText,
        });
      });
  };

  reloadPhotos = () => {
    const { setInitialDataStructure } = this.props;
    setInitialDataStructure();

    this.setState({
      isLoading: false,
      isSuccessModalOpen: true,
      username: '',
      password: '',
    });
  };

  onOkSuccessModalClick = () => {
    this.setState({
      isSuccessModalOpen: false,
    });
  };

  onOkWaitingModalClick = () => {
    this.setState({
      isWaitingModalOpen: false,
    });
  };

  onOkFallModalClick = () => {
    this.setState({
      isFallModalOpen: false,
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Your photographs are in your account and ready to view.'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkSuccessModalClick}
      />
    );
  }

  renderWaitingModal() {
    const { isWaitingModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isWaitingModalOpen}
        title={'Info'}
        body={
          'We are currently busy uploading your school photographs to our website, we will let you know as soon as they are ready to view and order!'
        }
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkWaitingModalClick}
      />
    );
  }

  renderFallModal() {
    const { isFallModalOpen, infoMessage } = this.state;

    return (
      <SimpleModal
        isOpen={isFallModalOpen}
        title={'Info'}
        body={infoMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkFallModalClick}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderSuccessModal()}
        {this.renderWaitingModal()}
        {this.renderFallModal()}
        <CustomerAccessCodeForm
          username={this.state.username}
          password={this.state.password}
          onUsernameChange={this.onUsernameChange}
          onPasswordChange={this.onPasswordChange}
          onFormSubmit={this.onFormSubmit}
        />
      </>
    );
  }
}
