import * as React from 'react';
import './CustomNotificationModal.css';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  isOpen: boolean;
  title: string;
  closeButtonText: string;
  submitButtonText: string;
  onCloseClick: () => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onSendClick: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  isActiveSendButton: boolean;
}

export const CustomNotificationModal: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true,
  });

  const { title, onCloseClick, onNextClick, closeButtonText, submitButtonText, hasNext, isActiveSendButton } = props;

  const renderButtons = () => {
    if (hasNext) {
      return (
        <button type="button" className="btn btn-primary" onClick={onNextClick} disabled={!isActiveSendButton}>
          {submitButtonText}
        </button>
      );
    } else {
      return null;
    }
  };

  const renderPreviousStepButton = () => {
    if (props.hasPrevious) {
      return (
        <button type="button" className="btn btn-primary" onClick={props.onPreviousClick}>
          Previous step
        </button>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={modalClasses}>
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header d-flex">
            <h5 className="modal-title">{title}</h5>
            {!hasNext ? (
              <div className="ml-auto">
                <button className="btn btn-primary" type="button" onClick={props.onSendClick}>
                  Send
                </button>
              </div>
            ) : (
              <button type="button" className="close" onClick={onCloseClick}>
                <span>&times;</span>
              </button>
            )}
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>
              {closeButtonText}
            </button>
            {renderPreviousStepButton()}
            {renderButtons()}
          </div>
        </div>
      </div>
    </div>
  );
};
