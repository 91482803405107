export const NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING = {
  EMAIL: 'Email',
  SMS: 'Sms',
  IOS: 'Ios',
  ANDROID: 'Android',
};

export const NOTIFICATION_CHANNELS_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  IOS: 'IOS',
  ANDROID: 'ANDROID',
};
