import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT } from '../../consts/table';

export function getCustomerNotifications(user: AppUser, customerId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/customers/${customerId}/notifications`, config).then(response => {
    return response.data;
  });
}

export function getAllCustomerNotifications(user: AppUser, customerId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/${customerId}/notifications`, config).then(response => {
    return response.data;
  });
}

export function getCustomerNotificationsCount(user: AppUser, customerId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/${customerId}/notifications/count`, config).then(response => {
    return response.data;
  });
}

export function sendCustomNotification(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/customers/notifications/custom`, data, config).then(response => {
    return response.data;
  });
}
