import * as React from 'react';
import { Packages } from './Packages';
import { PackagePriceSets } from './PackagePriceSets/PackagePriceSets';
import { Route, Switch } from 'react-router';

export const PackagesRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/packages'}
        render={props => <Packages {...props} user={allProps.user} />}
      />

      <Route
        exact
        path={'/packages/priceSets'}
        render={props => <PackagePriceSets {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

PackagesRouting.displayName = 'PackagesRouting';
