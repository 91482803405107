import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
}

const SchoolTypeSchema = Yup.object().shape({
  dirName: Yup.string().required('Required'),
});

export function DataExchangeUploadFilesForm(props: Props) {
  const { onSubmit, onCancel } = props;
  const schoolTypeForm = {
    dirName: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={schoolTypeForm} validationSchema={SchoolTypeSchema} onSubmit={onSubmit}>
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Folder Name</label>
                    <Field name="dirName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="dirName" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
