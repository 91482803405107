import * as React from 'react';
import { Component } from 'react';
import { ProductType } from '../../../../models/productTypes';

interface Props {
  productTypes: ProductType[];
  selectedProductTypeId: string;
  onClick: (productTypeId: string) => void;
}

export class ProductTypeFilter extends Component<Props> {
  render() {
    const { onClick, selectedProductTypeId, productTypes } = this.props;

    return (
      <div className={'bCustomerProductTypeFilter'}>
        <div className={'eCustomerProductTypeFilterTitle'}>Products</div>
        <div className="eCustomerProductTypeFilterTextWrapper">
          <div
            className={
              typeof selectedProductTypeId === 'undefined'
                ? 'eCustomerProductTypeFilterText eTextSelected'
                : 'eCustomerProductTypeFilterText'
            }
            key={'productTypes_all'}
            onClick={() => onClick(undefined)}
          >
            All
          </div>
          {productTypes.map(productType => (
            <div
              className={
                selectedProductTypeId === productType.id
                  ? 'eCustomerProductTypeFilterText eTextSelected'
                  : 'eCustomerProductTypeFilterText'
              }
              key={`productTypes_${productType.id}`}
              onClick={() => onClick(productType.id)}
            >
              {productType.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
