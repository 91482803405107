import * as React from 'react';
import { Component } from 'react';
import * as Promise from 'bluebird';
import * as Moment from 'moment';
import { History, Location } from 'history';
import { AppOneTimeCode } from '../../AppOrders';
import { BASKET_DELIVERY_CHARGE_TYPE, DEFAULT_COUNTRY_OPTION_VALUE } from '../../../../consts/order';
import { setDeliveryOption, setDeliveryCountryToBasket, getBasket } from '../../../../services/public/basket';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import './DeliveryOptions.scss';

interface Props {
  history: History;
  location: Location;
  oneTimeCode: AppOneTimeCode;
}

interface State {
  deadline: String;
  deliveryDate: String;
  isDeliveryInfoModalOpen: boolean;
}

export class DeliveryOptions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deadline: '',
      deliveryDate: '',
      isDeliveryInfoModalOpen: false,
    };
  }

  componentDidMount() {
    const { oneTimeCode } = this.props;

    getBasket(oneTimeCode).then(basket => {
      const { items } = basket;
      const deadline = this.getLatestDeadline(items);
      if (deadline !== '') {
        const deadlineFormatted = Moment(deadline).format('DD-MM-YYYY');

        const deliveryDate = Moment(new Date(deadline))
          .add(14, 'day')
          .format('DD-MM-YYYY');

        this.setState({
          deadline: deadlineFormatted,
          deliveryDate,
        });
      }
    });
  }

  getLatestDeadline = basketItems => {
    const deadlines = basketItems
      .map(item => item.schoolDeliveryDeadline)
      .filter(item => item !== '')
      .sort((date1, date2) => {
        return Date.parse(date1) - Date.parse(date2);
      });

    const isDeadlinesExist = deadlines.length > 0;
    const result = isDeadlinesExist ? deadlines[deadlines.length - 1] : '';
    return result;
  };

  setDeliveryOptionsToBasket = (deliveryCountry, deliveryOption) => {
    const { oneTimeCode } = this.props;
    const addDeliveryCountryToBasketPromise = setDeliveryCountryToBasket(oneTimeCode, deliveryCountry);
    const setDeliveryOptionPromise = setDeliveryOption(oneTimeCode, deliveryOption);
    const promises = [addDeliveryCountryToBasketPromise, setDeliveryOptionPromise];

    return Promise.all(promises).then(() => {
      return true;
    });
  };

  onSchoolClick = () => {
    this.setState({
      isDeliveryInfoModalOpen: true,
    });
  };

  setShoolDelivery = () => {
    const { history } = this.props;
    this.setDeliveryOptionsToBasket(
      { deliveryCountry: '' },
      { deliveryOption: BASKET_DELIVERY_CHARGE_TYPE.FREE_SCHOOL }
    ).then(() => history.push('/schoolDelivery'));
  };

  onHomeClick = () => {
    this.setDeliveryOptionsToBasket(
      { deliveryCountry: DEFAULT_COUNTRY_OPTION_VALUE },
      { deliveryOption: '' }
    ).then(() => this.props.history.push('/homeDelivery'));
  };

  closeDeliveryInfoModal = () => {
    this.setState({
      isDeliveryInfoModalOpen: false,
    });
  };

  renderDeliveryInfoModal() {
    const { isDeliveryInfoModalOpen, deadline, deliveryDate } = this.state;
    return (
      <SimpleModal
        isOpen={isDeliveryInfoModalOpen}
        title={'For your information'}
        buttonCancelText={'cancel'}
        onCloseClick={this.closeDeliveryInfoModal}
        buttonText={'ok'}
        onButtonClick={this.setShoolDelivery}
        customClass={`bDeliveryModal`}
      >
        <p>
          {'You have selected delivery to school, which is free of charge. \n' +
            'Please be aware that all delivery to school orders are downloaded after'}{' '}
          <strong>{deadline}</strong>
          {'. And we expect to dispatch the whole order to your school by'} <strong>{deliveryDate}</strong>
          {'.\n'}
          {'If you require your order in advance please do not choose this option. ' +
            'Select home delivery instead. \n' +
            'Thank you.'}
        </p>
      </SimpleModal>
    );
  }

  render() {
    return (
      <>
        {this.renderDeliveryInfoModal()}
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={'bDeliveryOptions'}>
                <div className={'eDeliveryOptionsTitle'}>DELIVERY OPTIONS</div>
                <div className={'eDeliveryOptionsButtonWrapper'}>
                  <div className="row justify-content-center">
                    <div className="col-xl-4">
                      <div className="eDeliveryOptionsButton mColorBlue" onClick={this.onSchoolClick}>
                        FREE SCHOOL DELIVERY
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="eDeliveryOptionsButton mColorRed" onClick={this.onHomeClick}>
                        HOME DELIVERY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
