import * as React from 'react';
import * as propz from 'propz';
import { Customer } from '../../../../../../../models/customers';

interface Props {
  customer: Customer;
  checkedChannelId: string;
  getCheckedChannelId: (channelId) => void;
}

export function CustomerNotificationChannelsSummary(props: Props) {
  const { customer, checkedChannelId } = props;
  const { notificationChannels } = customer;

  const rows = notificationChannels.map((channel, index) => {
    const type = propz.get(channel, ['type'], '');
    const channelId = channel.id;
    const isChecked = checkedChannelId === channelId;
    return (
      <tr key={`customer_notification_channels_summary_${index}`}>
        <td>
          <input
            onChange={() => {
              props.getCheckedChannelId(channelId);
            }}
            className=""
            type="checkbox"
            checked={isChecked}
          />
        </td>
        <td>{type}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mt-3'}>
      <thead>
        <tr>
          <th></th>
          <th>Channel type</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
