import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './ImageTicketsView.css';
import { SIZE_IMAGE_FOR_VIEWING } from '../../consts/images';
import { Component } from 'react';
import { isImageGroup, isImageIndividual, isImagePanoramic } from '../../helpers/images';

interface Props {
  ticket: any;
  onImageClick?: (ticket: any) => void;
  isSelected?: boolean;
}

interface State {
  cssClass: string;
}

export class ImageTicketsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cssClass: '.d-none',
    };
  }

  componentDidMount() {
    const { ticket } = this.props;
    switch (true) {
      case isImageIndividual(ticket.photoTypeName):
      case isImageGroup(ticket.photoTypeName):
      default:
        this.setState({
          cssClass: 'col-xl-3',
        });
        break;
      case isImagePanoramic(ticket.photoTypeName):
        this.setState({
          cssClass: 'col-xl-12',
        });
        break;
    }
  }

  onImageClick = () => {
    const { ticket, onImageClick } = this.props;
    onImageClick(ticket);
  };

  render() {
    const { ticket, isSelected } = this.props;
    const { cssClass } = this.state;

    return (
      <div key={ticket.id} className={`${cssClass} eImageTicket`}>
        <LazyLoadImage
          alt="image"
          height={SIZE_IMAGE_FOR_VIEWING.HEIGHT}
          src={`${window.apiFile}/storage/images/${ticket.ticket}`}
          onClick={this.onImageClick}
          className={isSelected ? 'mSelected' : 'eImageFrame'}
          effect="opacity"
        />
      </div>
    );
  }
}
