import * as React from 'react';
import './Sidebar.css';
import { AppUser, MenuItemsEnabled } from '../../App';
import {
  getSidebarItems,
  isSidebarItemExpandable,
} from '../../../../helpers/sidebar';
import { SidebarItems } from './SidebarItems';
import { SidebarItem } from './SidebarItem';

interface Props {
  user: AppUser;
  menuItemsEnabled: MenuItemsEnabled;
}

export interface SidebarItemExpandable {
  title: string;
  items: SimpleSidebarItem[];
  isVisible: boolean;
}

export interface SimpleSidebarItem {
  to: { pathname: string };
  itemTitle: string;
  isVisible: boolean;
}

export function Sidebar(props: Props) {
  const { user, menuItemsEnabled } = props;
  const sidebarItems: (
    | SidebarItemExpandable
    | SimpleSidebarItem
  )[] = getSidebarItems(user, menuItemsEnabled);

  return (
    <div className="bSidebar">
      {sidebarItems.map((sidebarItem, index) => {
        return isSidebarItemExpandable(sidebarItem) ? (
          <SidebarItems key={`sidebar_items_${index}`} item={sidebarItem} />
        ) : (
          <SidebarItem key={`sidebar_item_${index}`} item={sidebarItem} />
        );
      })}
    </div>
  );
}
