import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  value: boolean;
  onChange: () => void;
  text: string;
  customClass?: string;
  name: string;
  disabled?: boolean;
}

export const Switch: FunctionComponent<Props> = props => {
  const { customClass, onChange, text, value, name, disabled } = props;

  const switchClass = classNames('custom-control', 'custom-switch', customClass);

  return (
    <div className={switchClass}>
      <input
        name={name}
        onChange={onChange}
        checked={value}
        type="checkbox"
        className="custom-control-input"
        id={name}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={name}>
        {text}
      </label>
    </div>
  );
};

Switch.displayName = 'Switch';
