import * as React from 'react';
import { Component } from 'react';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from '../../../../../App';
import * as propz from 'propz';
import { syncUploadCsvInPreReg } from '../../../../../../../services/superadmin/csv';
import { CSV_TYPE } from '../../../../../../../consts/csv';
import { Loader } from '../../../../../../../components/Loader/Loader';

interface Props {
  onCancel: () => void;
  onShowStatusUploadOrders: (isErrorStatus, responseText) => void;
  user: AppUser;
}

interface State {
  file: any;
  isDisabledUploadButton: boolean;
  isLoading: boolean;
}

export class OrdersImportStatusForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: undefined,
      isDisabledUploadButton: true,
      isLoading: false,
    };
  }

  onChooseCSVFile = event => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    this.setState({
      file: formData,
      isDisabledUploadButton: false,
    });
  };

  onUploadSyncCsvInPreRegChange = (): void => {
    const { user } = this.props;
    const { file } = this.state;
    this.setState({
      isLoading: true,
    });
    const uploadType = CSV_TYPE.ORDERS_IMPORT_STATUS;
    let isErrorStatus;

    syncUploadCsvInPreReg(user, file, uploadType)
      .then(data => {
        const responseText = propz.get(data, ['result'], undefined);
        this.setState({
          isLoading: false,
        });
        isErrorStatus = false;
        const isErrorResultUpload = propz.get(data, ['isError'], '');
        console.log('isError');
        console.log(isErrorResultUpload);
        this.props.onShowStatusUploadOrders(isErrorStatus, responseText);
      })
      .catch(error => {
        const errorText = JSON.stringify(propz.get(error, ['response'], ''));
        console.error(errorText);
        this.setState({
          isLoading: false,
        });
        isErrorStatus = true;
        this.props.onShowStatusUploadOrders(isErrorStatus, errorText);
      });
  };

  render() {
    const { isLoading, isDisabledUploadButton } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="attachment">Selected CSV file</label>
              <input
                type="file"
                className="form-control-file"
                id="csv"
                disabled={isLoading}
                onChange={event => {
                  this.onChooseCSVFile(event);
                }}
              />
            </div>
            <div className="form-group">{isLoading && <Loader />}</div>
            <Button onClick={this.props.onCancel} text={'Close'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button
              onClick={() => {
                this.onUploadSyncCsvInPreRegChange();
              }}
              text={'Upload'}
              customClass={'mt-3 mb-3 mr-3 btn btn-primary'}
              disabled={isLoading || isDisabledUploadButton}
            />
          </div>
        </div>
      </div>
    );
  }
}
