import axios from 'axios';
import { AppOneTimeCode } from '../../views/AppOrders/AppOrders';

export function createBasket(oneTimeCode: AppOneTimeCode) {
  const { username, password } = oneTimeCode;
  return axios.post(`${window.apiBase}/public/${username}/${password}/basket`).then(response => {
    return response.data;
  });
}

export function getBasket(oneTimeCode: AppOneTimeCode) {
  const { username, password, basketId } = oneTimeCode;
  const config = {};

  return axios.get(`${window.apiBase}/public/${username}/${password}/basket/${basketId}`, config).then(response => {
    return response.data;
  });
}

export function addGalleryToBasket(oneTimeCode: AppOneTimeCode, galleryImages: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/galleryImages`, { galleryImages })
    .then(response => {
      return response.data;
    });
}

export function deleteImageFromGalleryInBasket(oneTimeCode: AppOneTimeCode, imageIds: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .delete(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/galleryImages`, { data: { imageIds } })
    .then(response => {
      return response.data;
    });
}

export function addSelectedProductTypeToBasket(oneTimeCode: AppOneTimeCode, productTypeId: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/productType`, { productTypeId })
    .then(response => {
      return response.data;
    });
}

export function addSelectedProductToBasket(oneTimeCode: AppOneTimeCode, productId: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/product`, { productId })
    .then(response => {
      return response.data;
    });
}

export function addSelectedImageToBasket(oneTimeCode: AppOneTimeCode, imageId: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/image`, { imageId })
    .then(response => {
      return response.data;
    });
}

export function addProductToBasket(oneTimeCode: AppOneTimeCode, data: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/addProduct`, data)
    .then(response => {
      return response.data;
    });
}

export function updateBasketItemQuantity(oneTimeCode: AppOneTimeCode, data: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/quantity`, data)
    .then(response => {
      return response.data;
    });
}

export function createOrder(oneTimeCode: AppOneTimeCode, data: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/orders`, data)
    .then(response => {
      return response.data;
    });
}

export function setDeliveryCountryToBasket(oneTimeCode: AppOneTimeCode, data: any) {
  const { username, password, basketId } = oneTimeCode;

  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/deliveryCountry`, data)
    .then(response => {
      return response.data;
    });
}

export function setDeliveryOption(oneTimeCode: AppOneTimeCode, data: any) {
  const { username, password, basketId } = oneTimeCode;
  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/deliveryOption`, data)
    .then(response => {
      return response.data;
    });
}

export function addOneTimeCodeBasketToCustomer(oneTimeCode: AppOneTimeCode, customerId) {
  const { username, password, basketId } = oneTimeCode;

  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/customer`, { customerId })
    .then(response => {
      return response.data;
    });
}
