import * as React from 'react';

interface Props {
  image: any;
}

export function SchoolAppSchoolJobImageSummary(props: Props) {
  const { image } = props;
  return (
    <div className={'mt-3'}>
      <img className="img-fluid img-thumbnail" src={`${window.apiFile}/storage/images/${image.ticket.ticket}`} />
    </div>
  );
}
