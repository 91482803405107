import { isDev, isLocal, isPreprod, isPreprod2, isStage } from './domain';

export function getCustomerCallbackC(): string {
  const myDomain = document.location.hostname;

  switch (true) {
    case isLocal(myDomain):
      return `http://orders.local.bentley.com:8080/customer`;
    case isDev(myDomain):
      return `http://orders.dev.bentley.com:8080/customer`;
    case isStage(myDomain):
      return `https://orders.stage.bp.squadintouch.com/customer`;
    case isPreprod2(myDomain):
      return `https://orders.preprod2.bp.squadintouch.com/customer`;
    case isPreprod(myDomain):
      return `https://orders.preprod.bp.squadintouch.com/customer`;
    default:
      //production mode
      return `https://orders.online.bentleypics.com/customer`;
  }
}

export function getCustomerCallbackY(): string {
  const myDomain = document.location.hostname;

  switch (true) {
    case isLocal(myDomain):
      return `http://orders.local.bentley.com:8080/customer`;
    case isDev(myDomain):
      return `http://orders.dev.bentley.com:8080/customer`;
    case isStage(myDomain):
      return `https://orders.stage.bp.squadintouch.com/customer`;
    case isPreprod2(myDomain):
      return `https://orders.preprod2.bp.squadintouch.com/customer`;
    case isPreprod(myDomain):
      return `https://orders.preprod.bp.squadintouch.com/customer`;
    default:
      //production mode
      return `https://orders.online.bentleypics.com/customer`;
  }
}

export function getOneTimeCodeCallbackC(): string {
  const myDomain = document.location.hostname;

  switch (true) {
    case isLocal(myDomain):
      return `http://orders.local.bentley.com:8080/accessCode`;
    case isDev(myDomain):
      return `http://orders.dev.bentley.com:8080/accessCode`;
    case isStage(myDomain):
      return `https://orders.stage.bp.squadintouch.com/accessCode`;
    case isPreprod2(myDomain):
      return `https://orders.preprod2.bp.squadintouch.com/accessCode`;
    case isPreprod(myDomain):
      return `https://orders.preprod.bp.squadintouch.com/accessCode`;
    default:
      //production mode
      return `https://orders.online.bentleypics.com/accessCode`;
  }
}

export function getOneTimeCodeCallbackY(): string {
  const myDomain = document.location.hostname;

  switch (true) {
    case isLocal(myDomain):
      return `http://orders.local.bentley.com:8080/accessCode`;
    case isDev(myDomain):
      return `http://orders.dev.bentley.com:8080/accessCode`;
    case isStage(myDomain):
      return `https://orders.stage.bp.squadintouch.com/accessCode`;
    case isPreprod2(myDomain):
      return `https://orders.preprod2.bp.squadintouch.com/accessCode`;
    case isPreprod(myDomain):
      return `https://orders.preprod.bp.squadintouch.com/accessCode`;
    default:
      //production mode
      return `https://orders.online.bentleypics.com/accessCode`;
  }
}

export function getMobileCallbackC(): string {
  const myDomain = document.location.hostname;

  switch (true) {
    case isLocal(myDomain):
      return `http://orders.local.bentley.com:8080/mobile/result`;
    case isDev(myDomain):
      return `http://orders.dev.bentley.com:8080/mobile/result`;
    case isStage(myDomain):
      return `https://orders.stage.bp.squadintouch.com/mobile/result`;
    case isPreprod2(myDomain):
      return `https://orders.preprod2.bp.squadintouch.com/mobile/result`;
    case isPreprod(myDomain):
      return `https://orders.preprod.bp.squadintouch.com/mobile/result`;
    default:
      //production mode
      return `https://orders.online.bentleypics.com/mobile/result`;
  }
}

export function getMobileCallbackY(): string {
  const myDomain = document.location.hostname;

  switch (true) {
    case isLocal(myDomain):
      return `http://orders.local.bentley.com:8080/mobile/result`;
    case isDev(myDomain):
      return `http://orders.dev.bentley.com:8080/mobile/result`;
    case isStage(myDomain):
      return `https://orders.stage.bp.squadintouch.com/mobile/result`;
    case isPreprod2(myDomain):
      return `https://orders.preprod2.bp.squadintouch.com/mobile/result`;
    case isPreprod(myDomain):
      return `https://orders.preprod.bp.squadintouch.com/mobile/result`;
    default:
      //production mode
      return `https://orders.online.bentleypics.com/mobile/result`;
  }
}

export function getCallback(): string {
  return `${window.apiBase}/public/webhook/worldpay/orders`;
}
