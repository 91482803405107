import * as React from 'react';
import { Component } from 'react';
import * as BPromise from 'bluebird';
import { History, Location } from 'history';
import { getFromHistory } from '../../../../helpers/history';
import { PAY360_RESULT, WORLD_PAY_RESULT } from '../../../../consts/payment';
import { ORDER_STATUS } from '../../../../consts/order';
import { getMobilePlatformFromStorage, clearMobilePlatformInStorage } from '../../../../helpers/sessionStorage';
import {
  getCustomerOrder,
  updateCustomerOrderStatusToCanceled,
  updateCustomerOrderStatusToPaid,
} from '../../../../services/customer/orders';
import { getSession, clearSession } from '../../../../helpers/cookies';
import { isPreprod, isPreprod2, isStage } from '../../../../helpers/domain';
import { Order } from '../../../../models/orders';
import { getCustomerOrderImages } from '../../../../services/customer/orderImages';
import { CustomerProfileOrderInformation } from '../../../AppOrders/Customer/CustomerProfile/Modals/CustomerProfileOrderInformation';
import { OrderImage } from '../../../../models/orderImages';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import './MobileResult.scss';

interface Props {
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  result: string;
  isButtonClicked: boolean;
  isPaymentSuccess: boolean;

  orderDetails: Order | null;
  orderImages: OrderImage[];
}

export class MobileResult extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      result: undefined,
      isButtonClicked: false,
      isPaymentSuccess: false,
      orderDetails: null,
      orderImages: [],
    };
  }

  componentDidMount() {
    const { history } = this.props;

    const orderId = getFromHistory(history, 'orderId');
    const basketId = getFromHistory(history, 'basketId');
    const result = getFromHistory(history, 'result');

    const session = getSession();
    const customer = { sessionKey: session.key, customerId: session.userId };
    const storedOrderId = sessionStorage.getItem('orderId') || localStorage.getItem('orderId') || orderId;

    if (storedOrderId) {
      this.fetchOrderDetails(storedOrderId, customer);
    } else {
      console.error('No orderId found in local storage');
    }

    this.setState({
      result,
      isPaymentSuccess: result === PAY360_RESULT.PAID,
    });

    switch (result) {
      case WORLD_PAY_RESULT.PAID:
        const data = {
          address1: getFromHistory(history, 'address1'),
          address2: getFromHistory(history, 'address2'),
          town: getFromHistory(history, 'town'),
          region: getFromHistory(history, 'region'),
          postcode: getFromHistory(history, 'postcode'),
          country: getFromHistory(history, 'country'),
          firstName: getFromHistory(history, 'firstName'),
          phone: getFromHistory(history, 'tel'),
          email: getFromHistory(history, 'email'),
        };
        getCustomerOrder(customer, orderId).then(order => {
          return order.orderStatus === ORDER_STATUS.PAID
            ? BPromise.resolve({})
            : updateCustomerOrderStatusToPaid(customer, basketId, orderId, data);
        });
        break;

      case WORLD_PAY_RESULT.CANCELED:
        getCustomerOrder(customer, orderId).then(order => {
          return order.orderStatus === ORDER_STATUS.CANCELED
            ? BPromise.resolve({})
            : updateCustomerOrderStatusToCanceled(customer, basketId, orderId);
        });
        break;

      default:
        console.error('Can not find result');
        break;
    }
  }

  componentWillUnmount() {
    clearMobilePlatformInStorage();
    clearSession();
  }

  fetchOrderDetails(storedOrderId: string, customer: { sessionKey: string; customerId: string }) {
    this.setState({ isLoading: true });

    let fetchedOrder = null;

    getCustomerOrder(customer, storedOrderId)
      .then(order => {
        if (!order || !order.id) {
          throw new Error('Order details not found or invalid.');
        }
        fetchedOrder = order;
        return getCustomerOrderImages(customer, storedOrderId, 50);
      })
      .then(images => {
        this.setState({
          orderDetails: fetchedOrder,
          orderImages: images,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false, orderDetails: null, orderImages: [] });
      });
  }

  onClosePay360SuccessModal = () => {
    window.location.href = this.url();
    window.close();
  };

  renderPay360SuccessModal() {
    const { orderDetails, orderImages, isPaymentSuccess } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentSuccess}
        title={'Payment Successful'}
        buttonCancelText="Close"
        onCloseClick={this.onClosePay360SuccessModal}
        customClass={`bMobileModal`}
      >
        <div>
          {orderDetails && orderImages.length ? (
            <>
              <p>
                Your order{' '}
                <span style={{ fontWeight: 'bold', color: '#7d0140' }}>
                  {orderDetails ? orderDetails.orderNumber : ''}
                </span>{' '}
                has been confirmed and payment was successful. The order details are below:
              </p>
              <CustomerProfileOrderInformation order={orderDetails} orderImages={orderImages} />
            </>
          ) : (
            <p>Your payment has been successful. We will let you know once your order has been dispatched.</p>
          )}
        </div>
      </SimpleModal>
    );
  }

  renderWorldPaySuccessModal() {
    return (
      <>
        <div className="eMobileResultTitle">THANK YOU!</div>
        <div className="eMobileResultText">Your payment was successful. For more details, see the modal window.</div>
      </>
    );
  }

  url = () => {
    const { result } = this.state;
    const mobilePlatform = getMobilePlatformFromStorage();
    const isMobilePlatformIos = mobilePlatform === 'ios';
    const isResultPaid = result === WORLD_PAY_RESULT.PAID || result === PAY360_RESULT.PAID;
    const myDomain = document.location.hostname;

    if (isMobilePlatformIos && isResultPaid) {
      return 'bentleymobile://Preview';
    }

    if (isMobilePlatformIos && !isResultPaid) {
      return 'bentleymobile://Basket';
    }

    if (!isMobilePlatformIos && isResultPaid) {
      switch (true) {
        case isStage(myDomain):
          return 'intent://orders.stage.bp.squadintouch.com/mobile/redirect/Preview#Intent;scheme=https;package=com.schoolphoto.stage;end';
        case isPreprod2(myDomain):
          return 'intent://orders.preprod2.bp.squadintouch.com/mobile/redirect/Preview#Intent;scheme=https;package=com.schoolphoto.preprod;end';
        case isPreprod(myDomain):
          return 'intent://orders.preprod.bp.squadintouch.com/mobile/redirect/Preview#Intent;scheme=https;package=com.schoolphoto.preprod;end';
        default:
          //production mode
          return 'intent://orders.online.bentleypics.com/mobile/redirect/Preview#Intent;scheme=https;package=com.schoolphoto;end';
      }
    }

    if (!isMobilePlatformIos && !isResultPaid) {
      switch (true) {
        case isStage(myDomain):
          return 'intent://orders.stage.bp.squadintouch.com/mobile/redirect/Basket#Intent;scheme=https;package=com.schoolphoto.stage;end';
        case isPreprod2(myDomain):
          return 'intent://orders.preprod2.bp.squadintouch.com/mobile/redirect/Basket#Intent;scheme=https;package=com.schoolphoto.preprod;end';
        case isPreprod(myDomain):
          return 'intent://orders.preprod.bp.squadintouch.com/mobile/redirect/Basket#Intent;scheme=https;package=com.schoolphoto.preprod;end';
        default:
          //production mode
          return 'intent://orders.online.bentleypics.com/mobile/redirect/Basket#Intent;scheme=https;package=com.schoolphoto;end';
      }
    }
  };

  onClick = () => {
    this.setState({
      isButtonClicked: true,
    });
  };

  render() {
    const { result, isButtonClicked } = this.state;

    const isResultPaid = result === WORLD_PAY_RESULT.PAID || result === PAY360_RESULT.PAID;
    const isResultCanceled = result === WORLD_PAY_RESULT.CANCELED || result === PAY360_RESULT.CANCELED;
    const isResultTimeout = result === PAY360_RESULT.TIMEOUT;

    return (
      <div className="bMobileResultWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bMobileResult">
                <div className="bHeader">
                  <div className="bHeaderText">bentley</div>
                  <div className="bHeaderText mfontsize10">photographic</div>
                </div>

                {result === WORLD_PAY_RESULT.PAID && (
                  <>
                    <div className="eMobileResultTitle">THANK YOU!</div>
                    <div className="eMobileResultText">
                      Your payment has been successful. We will let you know once your order has been dispatched.
                    </div>
                  </>
                )}

                {result === PAY360_RESULT.PAID && this.renderPay360SuccessModal()}

                {isResultCanceled && (
                  <>
                    <div className="eMobileResultTitle">VERY SORRY</div>
                    <div className="eMobileResultText">Payment failed, try again.</div>
                  </>
                )}

                {isResultTimeout && (
                  <>
                    <div className="eMobileResultTitle">VERY SORRY</div>
                    <div className="eMobileResultText">
                      We were unable to process the payment. Please contact our support team to complete your purchase.
                    </div>
                  </>
                )}

                {!isButtonClicked && (
                  <a href={this.url()} onClick={this.onClick} className="eMobileResultButton">
                    ok
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
