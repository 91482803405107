import * as React from 'react';
import { Component } from 'react';
import * as classNames from 'classnames';

interface Props {
  actionItems: ActionItem[];
}

export interface ActionItem {
  itemText: string;
  onItemClick: () => void;
  isActive: boolean;
}

interface State {
  isOpen: boolean;
}

export class ActionsButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  openMenu = () => {
    this.setState(
      {
        isOpen: true,
      },
      () => {
        document.addEventListener('click', this.closeMenu);
      }
    );
  };

  closeMenu = () => {
    this.setState(
      {
        isOpen: false,
      },
      () => {
        document.removeEventListener('click', this.closeMenu);
      }
    );
  };

  onItemClick = (event, index: number) => {
    event.preventDefault();

    const actionItems = this.props.actionItems;
    const actionItem = actionItems[index];
    const isActionItemActive = actionItem.isActive;

    if (isActionItemActive) {
      this.props.actionItems[index].onItemClick();
    }
  };

  renderItems() {
    const actionItems = this.props.actionItems;

    return actionItems.map((actionItem, index) => {
      return (
        <a
          className={actionItem.isActive ? 'dropdown-item' : 'dropdown-item disabled'}
          href=""
          key={`actionItem_${index}`}
          onClick={event => this.onItemClick(event, index)}
        >
          {actionItem.itemText}
        </a>
      );
    });
  }

  render() {
    const isOpen = this.state.isOpen;

    const dropdownClasses = classNames({
      'btn-group': true,
      bActionsButton: true,
      show: isOpen,
    });

    const dropdownMenuClasses = classNames({
      'dropdown-menu': true,
      show: isOpen,
    });

    const actionItems = this.props.actionItems;
    const isActionButtonDisabled = actionItems.length === 0;

    return (
      <div className={dropdownClasses}>
        <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          onClick={this.openMenu}
          disabled={isActionButtonDisabled}
        >
          Actions
        </button>
        <div className={dropdownMenuClasses}>{this.renderItems()}</div>
      </div>
    );
  }
}
