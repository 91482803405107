import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { ORDER_STATUS } from '../../../../../../../consts/order';
import { ImageWithTicket } from '../../../../../../../models/images';
import { AppUser } from '../../../../../App';
import { Package } from '../../../../../../../models/packages';
import { Order } from '../../../../../../../models/orders';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  image: ImageWithTicket;
  user: AppUser;
  packageItem: Package;
  order?: Order;
}

const OrderSchema = Yup.object().shape({
  image: Yup.object().shape({
    imageId: Yup.string().required('Required'),
    fileName: Yup.string().required('Required'),
  }),
  package: Yup.object().shape({
    packageId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
  priceSet: Yup.object().shape({
    priceSetId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
  product: Yup.object().shape({
    productId: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }),
  quantity: Yup.number()
    .min(1)
    .required('Required'),
});

export function ImageOrderForm(props: Props) {
  const { onSubmit, onCancel, image, packageItem, order } = props;

  const isOrderExist = typeof order !== 'undefined';

  const orderForm = {
    orderNumber: isOrderExist ? order.orderNumber : '',
    orderStatus: isOrderExist ? order.orderStatus : ORDER_STATUS.NEW,
    orderComment: isOrderExist ? order.comment : '',
    image: {
      imageId: image.id,
      fileName: image.fileName,
    },
    package: {
      packageId: packageItem.id,
      name: packageItem.name,
    },
    priceSet: {
      priceSetId: image.priceSet.priceSetId,
      name: image.priceSet.name,
    },
    product: {
      productId: '',
      name: '',
    },
    quantity: 0,
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={orderForm} validationSchema={OrderSchema} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form>
                <div className="form-group form-check">
                  {isOrderExist && (
                    <div className="form-group">
                      <label>Order number</label>
                      <Field name="orderNumber" className="form-control mb-3" disabled />
                    </div>
                  )}

                  <div className="form-group">
                    <label>Product</label>
                    <Field
                      name="product.productId"
                      component="select"
                      className="form-control mb-3"
                      onChange={event => {
                        const productId = event.target.value;
                        const product = packageItem.products.find(p => p.productId === productId);
                        setFieldValue('product.productId', product.productId);
                        setFieldValue('product.name', product.name);
                      }}
                    >
                      <option value={''} />
                      {packageItem.products.map(product => (
                        <option key={product.productId} value={product.productId}>
                          {product.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="product.productId" />
                  </div>

                  <div className="form-group">
                    <label>Price set</label>
                    <Field name="priceSet.priceSetId" component="select" className="form-control mb-3" disabled={true}>
                      <option value={values.priceSet.priceSetId}>{values.priceSet.name}</option>
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="priceSet.priceSetId" />
                  </div>

                  <div className="form-group">
                    <label>Quantity</label>
                    <Field name="quantity" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="quantity" />
                  </div>

                  <div className="form-group">
                    <label>Comment</label>
                    <Field component={'textarea'} name="comment" className="form-control mb-3" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />

                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <img
                        className="img-fluid img-thumbnail"
                        src={`${window.apiFile}/storage/images/${image.ticket.ticket}`}
                      />
                      <div>{image.fileName}</div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
