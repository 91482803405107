import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { Order } from '../../../../../../../models/orders';
import { OrderImage } from '../../../../../../../models/orderImages';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import './OrderSummary.scss';

interface Props {
  orderItem: Order;
  orderImages: OrderImage[];
}

export const OrderSummary: FunctionComponent<Props> = props => {
  const { orderImages, orderItem } = props;

  const rows = orderImages.map(image => {
    const productName = propz.get(image, ['product', 'name'], '');
    const productQuantity = propz.get(image, ['quantity'], '');
    const productPrice = propz.get(image, ['product', 'priceSetPrice'], '');

    return (
      <tr key={`order_summary_product_${image.id}`}>
        <td>{productName}</td>
        <td>{productQuantity}</td>
        <td>
          {CURRENCY_SYMBOL.POUND} {productPrice}
        </td>
      </tr>
    );
  });

  const { totalWithoutSpecialOffers, deliveryAmount, specialOffersSaving, voucherSaving, orderAmount } = orderItem;
  return (
    <table className="table table-bordered mMaxWidth850">
      <thead>
        <tr key="order_summary_head">
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>

      <tbody>{rows}</tbody>

      <tfoot className="mFontBold">
        <tr className="mBorderTopBold" key="order_summary_subtotal">
          <td colSpan={2}>Subtotal:</td>
          <td>
            {CURRENCY_SYMBOL.POUND} {totalWithoutSpecialOffers}
          </td>
        </tr>
        <tr key="order_summary_shipping">
          <td colSpan={2}>Shipping:</td>
          <td>
            {CURRENCY_SYMBOL.POUND} {deliveryAmount}
          </td>
        </tr>
        {specialOffersSaving > 0 && (
          <tr key="order_summary_offer_saving">
            <td colSpan={2}>Special offer saving:</td>
            <td>
              {CURRENCY_SYMBOL.POUND} {specialOffersSaving}
            </td>
          </tr>
        )}
        {voucherSaving > 0 && (
          <tr key="order_summary_voucher_saving">
            <td colSpan={2}>Voucher saving:</td>
            <td>
              {CURRENCY_SYMBOL.POUND} {voucherSaving}
            </td>
          </tr>
        )}
        <tr key="order_summary_total">
          <td colSpan={2}>Total:</td>
          <td>
            {CURRENCY_SYMBOL.POUND} {orderAmount}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
