import * as React from 'react';
import * as Promise from 'bluebird';
import { Component } from 'react';
import { History, Location } from 'history';
import { parse } from 'query-string';
import * as Moment from 'moment';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  getPagesCount,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { Order } from '../../../../../../models/orders';
import {
  addOrder,
  deleteOrder,
  downloadCSVOrdersToServer,
  getAllOrders,
  getOrders,
  getOrdersCount,
  updateOrder,
} from '../../../../../../services/superadmin/orders';
import { OrderForm } from './OrderForm/OrderForm';
import { ORDER_DELIVERY_TYPE, ORDER_STATUS, ORDER_TABS } from '../../../../../../consts/order';
import { CURRENCY_SYMBOL, TABS } from '../../../../../../consts/common';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { GenericSummary } from '../../../../../../components/GenericSummary/GenericSummary';
import { Button } from '../../../../../../components/Button/Button';
import { ResizablePanel } from '../../../../../../components/ResizablePanel/ResizablePanel';
import {
  getOrderStatus,
  getDeliveryType,
  getOrderSource,
  getCreatedAt,
  getUpdatedAt,
  getStatusUpdatedAt,
} from '../../../../../../helpers/accessor';
import {
  getSelectOptionForOrderStatus,
  getSelectOptionForDeliveryType,
  getSelectOptionForOrderSource,
} from '../../../../../../helpers/select';
import { CSV_TYPE } from '../../../../../../consts/csv';
import { OrderResultDownloadForm } from './OrderResultDownloadForm/OrderResultDownloadForm';
import { LIMIT } from '../../../../../../consts/table';
import { OrderSummary } from './OrderSummary/OrderSummary';
import { getAllOrderImages, getOrderImages } from '../../../../../../services/superadmin/orderImages';
import { OrderImage } from 'src/models/orderImages';
import { OrdersImportStatusForm } from './OrdersImportStatusForm/OrdersImportStatusForm';
import { searchFunctionSchools, getName } from '../../../../../../helpers/autocomplete';
import { OrderNotesSummary } from './OrderNotesSummary/OrderNotesSummary';
import { OrderNoteForm } from './OrderNotes/OrderNoteForm/OrderNoteForm';
import { addOrderNote, getAllOrderNotes } from '../../../../../../services/superadmin/orderNotes';
import { orderEmailsCell, orderPostcodesCell } from '../../../../../../helpers/cell';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Date',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAt,
  },
  {
    text: 'Order number',
    field: 'orderNumber',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['orderNumber'],
  },
  {
    text: 'Order amount',
    field: 'orderAmount',
    isSort: true,
    type: FILTER_TYPE.NUMBER_INTERVAL,
    accessor: ['orderAmount'],
  },
  {
    text: 'Delivery charge',
    field: 'deliveryAmount',
    isSort: true,
    type: FILTER_TYPE.NUMBER_INTERVAL,
    accessor: ['deliveryAmount'],
  },
  {
    text: 'Order status',
    field: 'orderStatus',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getOrderStatus,
  },
  {
    text: 'Status updated at',
    field: 'statusUpdatedAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getStatusUpdatedAt,
  },
  {
    text: 'Delivery type',
    field: 'deliveryType',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getDeliveryType,
  },
  {
    text: 'Delivery school',
    field: 'schoolName',
    isSort: true,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['schoolDelivery', 'schoolName'],
  },
  {
    text: 'Source',
    field: 'orderSource',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getOrderSource,
  },
  {
    text: 'Updated at',
    field: 'updatedAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getUpdatedAt,
  },
  {
    text: 'Created by',
    field: 'createdBy',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    cell: orderEmailsCell,
  },
  {
    text: 'Post code',
    field: 'postcode',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    cell: orderPostcodesCell,
  },
  {
    text: 'WP transaction ID',
    field: 'worldpayTransId',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['worldpayTransId'],
  },
];

interface State {
  items: Order[];
  currentPage: number;
  selectedItems: Order[];
  orderImages: OrderImage[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isOrderFormModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  currentTabIndex: number;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isResultDownloadModalOpen: boolean;
  isUploadCSVFormModalOpen: boolean;
  resultDownloadText: string;
  isLoadingResult: boolean;
  autocompleteDefaultTexts: any;
  autocompleteSearchFunctions: any;
  autocompleteGetElementsTitles: any;
  isOrderNoteFormModalOpen: boolean;
  notes: any[];
  isAlertModalOpen: boolean;
  orderImagesData: any;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Orders extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      orderImages: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isOrderFormModalOpen: false,
      isConfirmationModalOpen: false,
      isUploadCSVFormModalOpen: false,
      currentTabIndex: 0,
      isErrorModalOpen: false,
      errorMessage: '',
      isResultDownloadModalOpen: false,
      resultDownloadText: '',
      isLoadingResult: false,
      isOrderNoteFormModalOpen: false,
      autocompleteDefaultTexts: {
        schoolName: '',
      },
      autocompleteSearchFunctions: {
        schoolName: this.getSchools,
      },
      autocompleteGetElementsTitles: {
        schoolName: getName,
      },
      notes: [],
      isAlertModalOpen: false,
      orderImagesData: undefined
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    return searchFunctionSchools(user, text);
  };

  setItems() {
    const { history, user, location } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getOrders(user, serverQueryFilter);
    const getItemsCountPromise = getOrdersCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      const orderId = propz.get(location, ['state', 'orderId']);
      if (typeof orderId !== 'undefined') {
        const order = items.find(item => item.id === orderId);
        if (typeof order !== 'undefined') {
          getOrderImages(user, order.id).then(orderImages => {
            this.setState({
              currentPage: page,
              sortDirection: sortDirection as TABLE_SORT_DIRECTION,
              sortColumnsName: sortColumnsName,
              isShowFilter: isShowFilter,
              isDataFiltered: isDataFiltered,
              filters: filters,
              items: items,
              total: countObj.count,
              basePath: history.location.pathname,
              isLoading: false,
              selectedItems: [order],
              currentTabIndex: 0,
              orderImages: orderImages,
              autocompleteDefaultTexts: {
                schoolName: filters.schoolName,
              },
            });
          });
        } else {
          this.setState({
            currentPage: page,
            sortDirection: sortDirection as TABLE_SORT_DIRECTION,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: items,
            total: countObj.count,
            basePath: history.location.pathname,
            isLoading: false,
            selectedItems: [],
            orderImages: [],
            currentTabIndex: 0,
            autocompleteDefaultTexts: {
              schoolName: filters.schoolName,
            },
          });
        }

        const state = { ...(history.location.state as any) };
        delete state.orderId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          currentTabIndex: 0,
          basePath: history.location.pathname,
          isLoading: false,
          autocompleteDefaultTexts: {
            schoolName: filters.schoolName,
          },
        });
      }
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const { user } = this.props;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    if (selectedItemsUpdated.length === 1) {
      getAllOrderImages(user, selectedItemsUpdated[0].id).then(orderImages => {
        this.setState({
          selectedItems: selectedItemsUpdated,
          orderImages,
          currentTabIndex: 0,
        });
      });
    } else {
      this.setState({
        selectedItems: selectedItemsUpdated,
        currentTabIndex: 0,
      });
    }
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllOrders(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
      autocompleteDefaultTexts: {
        schoolName: '',
      },
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  onTableAutocompleteFilterChange = (item, filterField: string): void => {
    let filterFieldUpdated;

    switch (filterField) {
      case 'schoolName':
        filterFieldUpdated = 'name';
        break;
      default:
        filterFieldUpdated = filterField;
    }

    const filterValue = item[filterFieldUpdated];
    const filters = this.state.filters;

    let nextFilters = { ...filters };

    nextFilters = {
      ...nextFilters,
      [filterField]: filterValue,
    };

    this.setState({
      filters: nextFilters,
    });
  };

  renderNotesFormModal(): React.ReactNode {
    const { isOrderNoteFormModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isOrderNoteFormModalOpen} title={'Add note'}>
        <OrderNoteForm onSubmit={this.onSubmitOrderNoteForm} onCancel={this.onCancelOrderNoteFormButtonClick} />
      </SimpleModal>
    );
  }

  renderFormModal(): React.ReactNode {
    const { isOrderFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 1) {
      const order = selectedItems[0];
      return (
        <SimpleModal isOpen={isOrderFormModalOpen}>
          <OrderForm onSubmit={this.onCheckIncludedInReprint} onCancel={this.onCancelButtonClick} order={order} user={user} />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isOrderFormModalOpen}>
          <OrderForm onSubmit={this.onCheckIncludedInReprint} onCancel={this.onCancelButtonClick} user={user} />
        </SimpleModal>
      );
    }
  }

  onCheckIncludedInReprint = (data) => {
    const {orderImages} = this.state;
    const isStatusReprint = data.orderStatus === ORDER_STATUS.REPRINT;
    const isExistAllIsIncludedInReprint = orderImages.some(image => image.isIncludedInReprint);

    this.setState({
      orderImagesData: data,
      isOrderFormModalOpen: false
    });
    if (!isExistAllIsIncludedInReprint && isStatusReprint) {
      this.setState({
        isAlertModalOpen: true,
      });
    } else {
      this.onSubmitForm();
    }
  };

  onSubmitForm = () => {
    const { selectedItems, total, orderImagesData } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    const isSchoolDelivery = orderImagesData.deliveryType === ORDER_DELIVERY_TYPE.SCHOOL;

    if (!isSchoolDelivery) {
      delete orderImagesData.schoolDelivery;
    }

    if (selectedItems.length === 1) {
      const order = selectedItems[0];
      const { id } = order;

      updateOrder(user, id, orderImagesData).then(res => {
        this.setState({
          isOrderFormModalOpen: false,
          selectedItems: [res],
          isSelectAllChecked: false,
        });
        this.setItems();
      });
    } else {
      addOrder(user, orderImagesData).then(res => {
        const lastPage = getPagesCount(total + 1, LIMIT);
        this.setState({
          isOrderFormModalOpen: false,
          selectedItems: [res],
          isSelectAllChecked: false,
        });
        this.setCurrentPageParams(lastPage);
        this.setItems();
      });
    }
  };

  onSubmitOrderNoteForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;
    const order = selectedItems[0];
    const { id } = order;

    this.setState({
      isLoading: true,
    });

    addOrderNote(user, id, data)
      .then(res => {
        const lastPage = getPagesCount(total + 1, LIMIT);
        this.setState({
          isOrderNoteFormModalOpen: false,
          isLoading: false,
        });
        this.setItems();
      })
      .catch(error => {
        const errorText = JSON.stringify(propz.get(error, ['response'], ''));
        console.error(errorText);
        this.setState({
          isLoadingResult: false,
          isResultDownloadModalOpen: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
        console.error('error');
        console.error(errorText);
      });
  };

  onCancelButtonClick = () => {
    this.setState({
      isOrderFormModalOpen: false,
    });
  };

  onCancelOrderNoteFormButtonClick = () => {
    this.setState({
      isOrderNoteFormModalOpen: false,
    });
  };

  onCreateOrderClick = () => {
    this.setState({
      isOrderFormModalOpen: true,
    });
  };

  onEditOrderClick = () => {
    this.setState({
      isOrderFormModalOpen: true,
    });
  };

  onDeleteOrderClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    Promise.all(selectedItems.map(selectedItem => deleteOrder(user, selectedItem.id))).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
      });

      this.setItems();
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  onCloseResultDownloadModalClick = () => {
    this.setState({
      isResultDownloadModalOpen: false,
      isLoadingResult: false,
      isLoading: true,
      resultDownloadText: '',
    });
    this.setItems();
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove ${selectedItems.length === 1 ? 'order' : 'orders'}?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteOrderClick}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  renderResultDownloadModal(): React.ReactNode {
    const { isResultDownloadModalOpen, resultDownloadText, isLoadingResult } = this.state;

    return (
      <SimpleModal isOpen={isResultDownloadModalOpen}>
        <OrderResultDownloadForm
          resultDownloadText={resultDownloadText}
          onButtonClick={this.onCloseResultDownloadModalClick}
          isLoadingResult={isLoadingResult}
        />
      </SimpleModal>
    );
  }

  onCloseAlertClick = () => {
    this.setState({
      isAlertModalOpen: false,
    });
  };

  renderSendingAlertReprintError(): React.ReactNode {
    const {  isAlertModalOpen } = this.state;
    return (
        <SimpleModal
            isOpen={isAlertModalOpen}
            body={'There are no images included for reprinting. Please select the Show images button on the Summary tab ' +
            'and add/edit images that need to be reprinted from there. '}
            buttonCancelText={'Ok'}
            onCloseClick={this.onCloseAlertClick}
        />
    );
  }

  onShowImagesButtonClick = () => {
    const { selectedItems } = this.state;
    const order = selectedItems[0];
    const { id } = order;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/images`,
      search: `orderId=${id}`,
      state: { search: search },
    });
  };

  onAddNotesButtonClick = () => {
    this.setState({
      isOrderNoteFormModalOpen: true,
    });
  };

  onShowNotesButtonClick = () => {
    const { selectedItems } = this.state;
    const order = selectedItems[0];
    const { id } = order;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/notes`,
      search: `orderId=${id}`,
      state: { search: search },
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const { selectedItems } = this.state;
    const orderItem = selectedItems[0];
    const { id } = orderItem;
    const tabs = ORDER_TABS;
    const currentTab = tabs[index];

    switch (currentTab) {
      case TABS.NOTES:
        this.setState({
          isLoading: true,
        });
        getAllOrderNotes(user, id)
          .then(notes => {
            this.setState({
              currentTabIndex: index,
              notes: notes,
              isLoading: false,
            });
          })
          .catch(error => {
            const errorText = JSON.stringify(propz.get(error, ['response'], ''));
            console.error(errorText);
            this.setState({
              isLoadingResult: false,
              isResultDownloadModalOpen: false,
              isErrorModalOpen: true,
              errorMessage: errorText,
            });
            console.error('error');
            console.error(errorText);
          });
        break;
      default:
        this.setState({
          currentTabIndex: index,
        });
    }
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
    });
  };

  onDownloadCSVOrdersToServer = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { userId } = user;

    this.setState({
      isResultDownloadModalOpen: true,
      isLoadingResult: true,
    });
    const columnID = '"ObjectId", "userId"\n';
    const fields = selectedItems.map(selectedItem => `"${selectedItem.id}", "${userId}"`).join('\n');
    const csv = columnID + fields;
    const todayDataFormatted = Moment(new Date()).format('YYYY-MM-DD-HH-mm');
    const fileName = `orders-${todayDataFormatted}`;
    const file = new File([csv], `${fileName}.csv`, { type: 'text/csv' });
    const formData = new FormData();
    formData.append('file', file);

    const uploadType = CSV_TYPE.ORDERS;
    downloadCSVOrdersToServer(user, formData, uploadType)
      .then(data => {
        const isErrorResultUpload = propz.get(data, ['isError'], '');
        console.log('isError');
        console.log(isErrorResultUpload);
        if (typeof data.order !== 'undefined') {
          const xml = new Blob([data.order], { type: 'text/xml' });
          if (window.navigator.msSaveBlob) {
            // IE 10+
            window.navigator.msSaveBlob(xml, `${fileName}.xml`);
          } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              /*global URL:true*/
              const url = URL.createObjectURL(xml);
              link.setAttribute('href', url);
              link.setAttribute('download', `${fileName}.xml`);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          this.setState({
            isLoadingResult: false,
          });
        }
        if (typeof data.result !== 'undefined') {
          const resultDownloadText = data.result;
          this.setState({
            isLoadingResult: false,
            resultDownloadText: resultDownloadText,
          });
        } else {
          this.setState({
            isResultDownloadModalOpen: false,
          });
        }
      })
      .catch(error => {
        const errorText = JSON.stringify(propz.get(error, ['response'], ''));
        console.error(errorText);
        this.setState({
          isLoadingResult: false,
          isResultDownloadModalOpen: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
        console.error('error');
        console.error(errorText);
      });
  };

  onPrintOrdersToServer = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { userId } = user;

    this.setState({
      isResultDownloadModalOpen: true,
      isLoadingResult: true,
    });
    const columnID = '"ObjectId", "userId"\n';
    const fields = selectedItems.map(selectedItem => `"${selectedItem.id}", "${userId}"`).join('\n');
    const csv = columnID + fields;
    const todayDataFormatted = Moment(new Date()).format('YYYY-MM-DD-HH-mm');
    const fileName = `orders-${todayDataFormatted}`;
    const file = new File([csv], `${fileName}.csv`, { type: 'text/csv' });
    const formData = new FormData();
    formData.append('file', file);

    let resultDownloadText;

    const uploadType = CSV_TYPE.ORDERS_PRINT;
    downloadCSVOrdersToServer(user, formData, uploadType)
      .then(data => {
        const isErrorResultUpload = propz.get(data, ['isError'], '');
        console.log('isError');
        console.log(isErrorResultUpload);

        if (typeof data.result !== 'undefined') {
          resultDownloadText = data.result;
        }
        this.setState({
          isLoadingResult: false,
          resultDownloadText: resultDownloadText,
        });

        const url = `${window.apiBase}/admin/orderPdf/${data.taskId}`;
        window.open(url, '_blank');
      })
      .catch(error => {
        const errorText = JSON.stringify(propz.get(error, ['response'], ''));
        console.error(errorText);
        this.setState({
          isLoadingResult: false,
          isResultDownloadModalOpen: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
        console.error('error');
        console.error(errorText);
      });
  };

  getTabs(): React.ReactNode {
    const { currentTabIndex, orderImages, selectedItems, notes } = this.state;
    const tabs = ORDER_TABS;
    const currentTab = tabs[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <OrderSummary orderItem={selectedItems[0]} orderImages={orderImages} />;
      case TABS.NOTES:
        return <OrderNotesSummary notes={notes} />;
      default:
        return <div />;
    }
  }

  isDeleteActive(selectedItems) {
    return selectedItems.every(item => item.orderStatus === ORDER_STATUS.NEW);
  }

  openUploadCSVFormClick = () => {
    this.setState({
      isUploadCSVFormModalOpen: true,
    });
  };

  onUploadCSVFormCancelButtonClick = () => {
    this.setState({
      isLoading: true,
      isUploadCSVFormModalOpen: false,
    });
    this.setItems();
  };

  onShowStatusImportOrders = (isErrorStatus, responseText) => {
    this.setState({
      isLoading: true,
      isUploadCSVFormModalOpen: false,
    });
    if (isErrorStatus) {
      console.error(responseText);
      this.setState({
        isLoading: false,
        isErrorModalOpen: true,
        errorMessage: responseText,
      });
    } else {
      if (typeof responseText !== 'undefined') {
        this.setState({
          isLoading: false,
          isResultDownloadModalOpen: true,
          resultDownloadText: responseText,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderUploadCSVFormModal(): React.ReactNode {
    const { isUploadCSVFormModalOpen } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isUploadCSVFormModalOpen}>
        <OrdersImportStatusForm
          user={user}
          onCancel={this.onUploadCSVFormCancelButtonClick}
          onShowStatusUploadOrders={this.onShowStatusImportOrders}
        />
      </SimpleModal>
    );
  }

  renderSummary(): React.ReactNode {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a order above</div>;
    }

    const tabs = this.getTabs();
    const currentTab = ORDER_TABS[currentTabIndex];
    const isShowImages = currentTab === TABS.SUMMARY;
    const isShowNotes = currentTab === TABS.NOTES;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={ORDER_TABS} currentTabIndex={currentTabIndex}>
        {isShowImages && (
          <Button onClick={this.onShowImagesButtonClick} text={'Show images'} customClass={'mt-3 mr-3 mb-3'} />
        )}
        {isShowNotes && (
          <Button onClick={this.onAddNotesButtonClick} text={'Add note'} customClass={'mt-3 mr-3 mb-3'} />
        )}
        {isShowNotes && (
          <Button onClick={this.onShowNotesButtonClick} text={'Edit notes'} customClass={'mt-3 mr-3 mb-3'} />
        )}
        {tabs}
      </GenericSummary>
    );
  }

  renderGrid(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      autocompleteSearchFunctions,
      autocompleteGetElementsTitles,
      autocompleteDefaultTexts,
    } = this.state;

    const { user } = this.props;

    const isDownloadOrderActive = selectedItems.every(
      order => order.orderStatus === ORDER_STATUS.PAID || order.orderStatus === ORDER_STATUS.REPRINT
    );

    const actionItems = [
      {
        itemText: 'Create order',
        onItemClick: this.onCreateOrderClick,
        isActive: selectedItems.length === 0,
      },
      {
        itemText: 'Edit order',
        onItemClick: this.onEditOrderClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete order' : 'Delete orders',
        onItemClick: this.showConfirmationModal,
        isActive: selectedItems.length > 0 && this.isDeleteActive(selectedItems),
      },
      {
        itemText: selectedItems.length === 1 ? 'Download order' : 'Download orders',
        onItemClick: this.onDownloadCSVOrdersToServer,
        isActive: isDownloadOrderActive && selectedItems.length > 0,
      },
      {
        itemText: 'Print order',
        onItemClick: this.onPrintOrdersToServer,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: 'Import orders status',
        onItemClick: this.openUploadCSVFormClick,
        isActive: selectedItems.length === 0,
      },
    ];

    const filterOptions = {
      orderStatus: getSelectOptionForOrderStatus(),
      deliveryType: getSelectOptionForDeliveryType(),
      orderSource: getSelectOptionForOrderSource(),
    };

    const sumFields = [
      {
        field: 'orderAmount',
        text: `Grand Total: ${CURRENCY_SYMBOL.POUND}`,
      },
      {
        field: 'totalOfSales',
        text: `Total of Sales: ${CURRENCY_SYMBOL.POUND}`,
      },
      {
        field: 'deliveryAmount',
        text: `Total Postage: ${CURRENCY_SYMBOL.POUND}`,
      },
    ];

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        sumFields={sumFields}
        user={user}
        autocompleteSearchFunctions={autocompleteSearchFunctions}
        autocompleteGetElementsTitles={autocompleteGetElementsTitles}
        onTableAutocompleteFilterChange={this.onTableAutocompleteFilterChange}
        autocompleteDefaultTexts={autocompleteDefaultTexts}
      />
    );
  }

  render() {
    const {
      isLoading,
      isOrderFormModalOpen,
      isConfirmationModalOpen,
      isErrorModalOpen,
      isUploadCSVFormModalOpen,
      isOrderNoteFormModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isOrderFormModalOpen ||
      isConfirmationModalOpen ||
      isErrorModalOpen ||
      isUploadCSVFormModalOpen ||
      isOrderNoteFormModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderFormModal()}
        {this.renderNotesFormModal()}
        {this.renderUploadCSVFormModal()}
        {this.renderConfirmationModal()}
        {this.renderResultDownloadModal()}
        {this.renderSendingError()}
        {this.renderSendingAlertReprintError()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
