import * as React from 'react';
import { Component } from 'react';
import { getFromHistory } from '../../../helpers/history';
import { History } from 'history';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import './ResetPassword.scss';
import { resetPassword, resetRequestPassword } from '../../../services/public/password';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { resetPasswordSchoolApp, resetRequestPasswordSchoolApp } from '../../../helpers/squadApi';

interface Props {
  history: History;
  isSchoolApp?: boolean;
}

interface State {
  secretKey: string;
  isResetPasswordModalOpen: boolean;
  isChangePasswordModalOpen: boolean;
  promoEmailAgreement: boolean;
}

export class ResetPassword extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      secretKey: undefined,
      isResetPasswordModalOpen: false,
      isChangePasswordModalOpen: false,
      promoEmailAgreement: undefined,
    };
  }
  componentDidMount(): void {
    const { history } = this.props;
    const secretKey = getFromHistory(history, 'secretKey');
    this.setState({
      secretKey,
    });
  }

  redirectToLoginPage = (): void => {
    const { history } = this.props;
    const { promoEmailAgreement } = this.state;

    history.push({
      pathname: '/login',
      search: '',
      state: { promoEmailAgreement },
    });
  };

  onChangePasswordFormSubmit = (data): void => {
    const { secretKey } = this.state;
    const { isSchoolApp } = this.props;
    const { password, promoEmailAgreement } = data;

    const resetPasswordPromise = isSchoolApp
      ? resetPasswordSchoolApp(password, secretKey)
      : resetPassword(password, secretKey);

    resetPasswordPromise.then(res => {
      this.setState({
        isChangePasswordModalOpen: true,
        promoEmailAgreement,
      });
    });
  };

  onResetPasswordFormSubmit = (data): void => {
    const { isSchoolApp } = this.props;

    const resetRequestPasswordPromise = isSchoolApp
      ? resetRequestPasswordSchoolApp(data.email)
      : resetRequestPassword(data.email);

    resetRequestPasswordPromise.then(res => {
      this.setState({
        isResetPasswordModalOpen: true,
      });
    });
  };

  renderResetPasswordModal(): React.ReactNode {
    const { isResetPasswordModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isResetPasswordModalOpen}
        title={'Info'}
        body={'Please check your email inbox, and click the link in the email you received to reset your password.'}
        buttonText={'Ok'}
        onButtonClick={this.redirectToLoginPage}
      />
    );
  }

  renderChangePasswordModal(): React.ReactNode {
    const { isChangePasswordModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isChangePasswordModalOpen}
        title={'Info'}
        body={'Password changed'}
        buttonText={'Ok'}
        onButtonClick={this.redirectToLoginPage}
      />
    );
  }

  render() {
    const { secretKey } = this.state;
    const { isSchoolApp } = this.props;

    return (
      <div className={'bResetPasswordForm'}>
        {this.renderResetPasswordModal()}
        {this.renderChangePasswordModal()}
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="offset-xl-3 col-xl-6">
                <div className="mt-3 mb-3">
                  {secretKey ? (
                    <ChangePasswordForm
                      redirectToLoginPage={this.redirectToLoginPage}
                      onSubmit={this.onChangePasswordFormSubmit}
                    />
                  ) : (
                    <ResetPasswordForm
                      redirectToLoginPage={this.redirectToLoginPage}
                      onSubmit={this.onResetPasswordFormSubmit}
                      isSchoolApp={isSchoolApp}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
