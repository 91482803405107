import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  getPagesCount,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import {
  addCustomer,
  createCustomerNotificationChannel,
  deleteCustomer,
  deleteNotificationChannel,
  getAllCustomers,
  getCustomers,
  getCustomersCount,
  sendCustomerNotification,
  updateCustomer,
} from '../../../../../../services/superadmin/customers';
import { Customer } from '../../../../../../models/customers';
import { CustomerForm } from './CustomerForm/CustomerForm';
import { ResizablePanel } from '../../../../../../components/ResizablePanel/ResizablePanel';
import { GenericSummary } from '../../../../../../components/GenericSummary/GenericSummary';
import { CUSTOMER_TABS } from '../../../../../../consts/customer';
import { TABS } from '../../../../../../consts/common';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { CustomerPermissionsSummary } from './CustomerPermissionsSummary/CustomerPermissionsSummary';
import { Button } from '../../../../../../components/Button/Button';
import * as propz from 'propz';
import { CustomerNotificationChannelsForm } from './CustomerNotificationChannels/CustomerNotificationChannelsForm/CustomerNotificationChannelsForm';
import { CustomerNotificationChannelsSummary } from './CustomerNotificationChannelsSummary/CustomerNotificationChannelsSummary';
import { CustomerAddressSummary } from './CustomerAddressSummary/CustomerAddressSummary';
import { VOUCHER_DISCOUNT_TYPE } from '../../../../../../consts/voucher';
import { addVoucher } from '../../../../../../services/superadmin/vouchers';
import { CustomerVoucherForm } from './CustomerVoucherForm/CustomerVoucherForm';
import { CustomSendTestNotificationForm } from './CustomerNotificationChannels/CustomerSendNotificationForm/CustomerSendTestNotificationForm';
import { CustomNotificationWizzard } from '../../../../../../components/CustomNotificationWizzard/CustomNotificationWizzard';
import { sendCustomNotification } from '../../../../../../services/superadmin/customerNotifications';
import { addOffer } from '../../../../../../services/superadmin/offers';
import { CustomerOfferForm } from './CustomerOfferForm/CustomerOfferForm';
import {
  EMPTY_STRINGS_IN_EDITOR,
  HTML_FIRST_PART_PAGE_CODE,
  HTML_LAST_PART_PAGE_CODE,
} from '../../../../../../consts/htmlEditor';
import { CUSTOM_NOTIFICATION_TYPE, CUSTOM_NOTIFICATION_TYPE_ARRAY } from '../../../../../../consts/customNotification';
import { uploadFile } from '../../../../../../services/file';
import { LIMIT } from '../../../../../../consts/table';
import {
  getPromoEmailUpdatedAt,
  getPromoEmail,
  getCustomerStudents,
  getCustomerStudentSchools,
} from '../../../../../../helpers/accessor';
import { CustomerStudentsSummary } from './CustomerStudentsSummary/CustomerStudentsSummary';
import { getSelectOptionForYesOrNo } from '../../../../../../helpers/select';
import { CustomerOrdersSummary } from './CustomerOrdersSummary/CustomerOrdersSummary';
import { getAllOrders } from '../../../../../../services/superadmin/orders';
import { Order } from '../../../../../../models/orders';
import { deleteImages } from '../../../../../../services/superadmin/images';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Email',
    field: 'email',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['email'],
  },
  {
    text: 'First name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName'],
  },
  {
    text: 'Last name',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName'],
  },
  {
    text: 'Promo email',
    field: 'promoEmail',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getPromoEmail,
  },
  {
    text: 'Schools',
    field: 'customerStudentSchools',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getCustomerStudentSchools,
  },
  {
    text: 'Students',
    field: 'customerStudents',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getCustomerStudents,
  },
  {
    text: 'Promo email updated at',
    field: 'promoEmailUpdated',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getPromoEmailUpdatedAt,
  },
];

interface State {
  items: Customer[];
  currentPage: number;
  selectedItems: Customer[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isConfirmationModalOpen: boolean;
  isCustomerFormModalOpen: boolean;
  isNotificationChannelsFormModalOpen: boolean;
  isSendNotificationFormModalOpen: boolean;
  isConfirmationRemoveChannelModalOpen: boolean;
  currentTabIndex: number;
  checkedChannelId: string;
  isVoucherFormModalOpen: boolean;
  isOfferFormModalOpen: boolean;
  isNotificationModalOpen: boolean;
  customerId: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isLoadingFile: boolean;

  // content                                // carried because it is necessary to save the state of the content after errors from the server
  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
  messagePushTitle: string;
  messagePushBody: string;

  isEmailEditorEnabled: boolean;
  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;
  orders: Order[];
  isRawEditor: boolean;

  isNotificationConfirmationModalOpen: boolean;
  notificationConfirmationMessage: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Customers extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isConfirmationModalOpen: false,
      isCustomerFormModalOpen: false,
      isNotificationChannelsFormModalOpen: false,
      isSendNotificationFormModalOpen: false,
      isConfirmationRemoveChannelModalOpen: false,
      isVoucherFormModalOpen: false,
      isOfferFormModalOpen: false,
      isNotificationModalOpen: false,
      currentTabIndex: 0,
      checkedChannelId: '',
      customerId: propz.get(this.props.location, ['state', 'customerId']),
      isErrorModalOpen: false,
      errorMessage: '',
      isLoadingFile: false,
      isRawEditor: false,

      // content
      messageSmsText: '',
      messageEmailSubject: '',
      messageEmailBody: '',
      messageAttachments: [],
      messagePushTitle: '',
      messagePushBody: '',

      isEmailEditorEnabled: true,
      isSmsEditorEnabled: true,
      isPushEditorEnabled: true,
      orders: [],

      isNotificationConfirmationModalOpen: false,
      notificationConfirmationMessage: '',
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;
    const { customerId } = this.state;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getCustomers(user, serverQueryFilter);
    const getItemsCountPromise = getCustomersCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      if (typeof customerId !== 'undefined') {
        const customer = items.find(item => item.id === customerId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof customer !== 'undefined' ? [customer] : [],
          customerId: undefined,
        });

        //clear history
        const state = { ...(history.location.state as any) };
        delete state.customerId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
        });
      }

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    const tabs = CUSTOMER_TABS;
    const currentTabIndex = tabs.findIndex(tab => tab === TABS.PERMISSIONS);

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllCustomers(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
    });
  };

  onCloseNotificationConfirmationModalClick = () => {
    this.setState({
      isNotificationConfirmationModalOpen: false,
      notificationConfirmationMessage: '',
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  hideConfirmationRemoveChannelModal = () => {
    this.setState({
      isConfirmationRemoveChannelModalOpen: false,
    });
  };

  onDeleteNotificationChannelClick = () => {
    const { selectedItems, checkedChannelId } = this.state;
    const { user } = this.props;
    const customer = selectedItems[0];

    this.setState({
      isLoading: true,
      isConfirmationRemoveChannelModalOpen: false,
    });

    deleteNotificationChannel(user, checkedChannelId, customer.id).then(res => {
      this.setState({
        checkedChannelId: '',
      });
      this.setItems();
    });
  };

  onDeleteCustomerClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    Promise.all(selectedItems.map(selectedItem => deleteCustomer(user, selectedItem.id))).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
      });

      this.setItems();
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderNotificationConfirmationModal(): React.ReactNode {
    const { notificationConfirmationMessage, isNotificationConfirmationModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isNotificationConfirmationModalOpen}
        title={'Success'}
        body={notificationConfirmationMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseNotificationConfirmationModalClick}
      />
    );
  }

  renderConfirmationRemoveCustomerModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove ${selectedItems.length === 1 ? 'customer' : 'customers'}?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteCustomerClick}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  renderConfirmationRemoveChannelModal(): React.ReactNode {
    const { isConfirmationRemoveChannelModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationRemoveChannelModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove notification channel ?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteNotificationChannelClick}
        onCloseClick={this.hideConfirmationRemoveChannelModal}
      />
    );
  }

  onCreateCustomerClick = () => {
    this.setState({
      isCustomerFormModalOpen: true,
    });
  };

  onEditCustomerClick = () => {
    this.setState({
      isCustomerFormModalOpen: true,
    });
  };

  onSubmitForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    const { phone, phoneCode, ...rest } = data;

    const phoneToPost = `${phoneCode}${phone}`;
    const dataToTransfer = {
      ...rest,
      phone: phoneToPost,
    };

    if (selectedItems.length === 1) {
      const customerUpdated = selectedItems[0];
      const { id } = customerUpdated;

      updateCustomer(user, id, dataToTransfer)
        .then(res => {
          this.setState({
            isCustomerFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setItems();
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          this.setState({
            isCustomerFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
            isLoading: false,
          });
          console.error('error');
          console.error(error);
        });
    } else {
      addCustomer(user, dataToTransfer)
        .then(res => {
          const lastPage = getPagesCount(total + 1, LIMIT);
          this.setState({
            isCustomerFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setCurrentPageParams(lastPage);
          this.setItems();
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          this.setState({
            isCustomerFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
            isLoading: false,
          });
          console.error('error');
          console.error(error);
        });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      isCustomerFormModalOpen: false,
    });
  };

  renderFormModal(): React.ReactNode {
    const { isCustomerFormModalOpen, selectedItems } = this.state;

    if (selectedItems.length === 1) {
      const customer = selectedItems[0];
      return (
        <SimpleModal isOpen={isCustomerFormModalOpen} title={'Edit customer'}>
          <CustomerForm customer={customer} onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isCustomerFormModalOpen} title={'Create customer'}>
          <CustomerForm onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
        </SimpleModal>
      );
    }
  }

  getCheckedChannelId = channelId => {
    if (channelId === this.state.checkedChannelId) {
      this.setState({
        checkedChannelId: '',
      });
    } else {
      this.setState({
        checkedChannelId: channelId,
      });
    }
  };

  onSubmitNotificationChannelForm = data => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    const customer = selectedItems[0];
    const { id } = customer;

    createCustomerNotificationChannel(user, id, data).then(res => {
      this.setState({
        customerId: undefined,
        selectedItems: [customer],
        checkedChannelId: '',
        isNotificationChannelsFormModalOpen: false,
      });
      this.setItems();
    });
  };

  onCancelNotificationChannelsButtonClick = () => {
    this.setState({
      isNotificationChannelsFormModalOpen: false,
    });
  };

  onSendNotificationForm = data => {
    const { selectedItems, checkedChannelId } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    const customer = selectedItems[0];
    const customerId = customer.id;

    sendCustomerNotification(user, customerId, checkedChannelId, data).then(res => {
      this.setState({
        isSendNotificationFormModalOpen: false,
        selectedItems: [customer],
      });
      this.setItems();
    });
  };

  onCancelSendNotificationButtonClick = () => {
    this.setState({
      isSendNotificationFormModalOpen: false,
    });
  };

  renderNotificationChannelsFormModal(): React.ReactNode {
    const { isNotificationChannelsFormModalOpen, selectedItems } = this.state;
    if (selectedItems.length === 1) {
      const customer = selectedItems[0];
      return (
        <SimpleModal isOpen={isNotificationChannelsFormModalOpen} title={'Create notification channels'}>
          <CustomerNotificationChannelsForm
            notificationChannels={customer.notificationChannels}
            onCancel={this.onCancelNotificationChannelsButtonClick}
            onSubmit={this.onSubmitNotificationChannelForm}
          />
        </SimpleModal>
      );
    }
  }

  renderSendTestNotificationFormModal(): React.ReactNode {
    const { isSendNotificationFormModalOpen, selectedItems } = this.state;
    if (selectedItems.length === 1) {
      return (
        <SimpleModal isOpen={isSendNotificationFormModalOpen} title={'Send a test notification'}>
          <CustomSendTestNotificationForm
            onCancel={this.onCancelSendNotificationButtonClick}
            onSubmit={this.onSendNotificationForm}
          />
        </SimpleModal>
      );
    }
  }

  renderGrid(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
    } = this.state;

    const { user } = this.props;

    const actionItems = [
      {
        itemText: 'Create customer',
        onItemClick: this.onCreateCustomerClick,
        isActive: selectedItems.length === 0,
      },
      {
        itemText: 'Edit customer',
        onItemClick: this.onEditCustomerClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete customer' : 'Delete customers',
        onItemClick: this.showConfirmationModal,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Create voucher',
        onItemClick: this.onCreateVoucherClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Create offer',
        onItemClick: this.onCreateOfferClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Send notifications',
        onItemClick: this.onSendNotificationsButtonClick,
        isActive: selectedItems.length > 0,
      },
    ];

    const filterOptions = {
      promoEmail: getSelectOptionForYesOrNo(),
    };

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        user={user}
      />
    );
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const tabs = CUSTOMER_TABS;
    const currentTab = tabs[index];
    const { selectedItems } = this.state;
    const customerId = selectedItems[0];

    switch (currentTab) {
      case TABS.ORDERS:
        this.setState({
          isLoading: true,
        });

        const where = {
          customerId: {
            $in: customerId.id,
          },
        };

        getAllOrders(user, where).then(orders => {
          this.setState({
            currentTabIndex: index,
            orders: orders,
            isLoading: false,
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index,
        });
    }
  };

  getTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems, checkedChannelId, orders } = this.state;

    const tabs = CUSTOMER_TABS;
    const currentTab = tabs[currentTabIndex];
    const [customer] = selectedItems;

    switch (currentTab) {
      case TABS.PERMISSIONS:
        return <CustomerPermissionsSummary customer={customer} />;
      case TABS.NOTIFICATION_CHANNELS:
        return (
          <CustomerNotificationChannelsSummary
            customer={customer}
            getCheckedChannelId={this.getCheckedChannelId}
            checkedChannelId={checkedChannelId}
          />
        );
      case TABS.NOTIFICATIONS:
        this.onViewNotificationsClick();
        return <div />;
      case TABS.ADDRESS_BOOK:
        return <CustomerAddressSummary customer={customer} />;
      case TABS.STUDENTS:
        return <CustomerStudentsSummary customer={customer} />;
      case TABS.ORDERS:
        return <CustomerOrdersSummary orders={orders} />;
      default:
        return <div />;
    }
  }

  onEditPermissionsButtonClick = () => {
    const { selectedItems } = this.state;
    const [customer] = selectedItems;
    const { id } = customer;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/permissions`,
      search: `customer=${id}`,
      state: { search: search },
    });
  };

  onViewNotificationsClick = () => {
    const { selectedItems } = this.state;
    const [customer] = selectedItems;
    const { id } = customer;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/notifications`,
      search: `customer=${id}`,
      state: { search: search },
    });
  };

  onEditStudentsButtonClick = () => {
    const { selectedItems } = this.state;
    const [customer] = selectedItems;
    const { id } = customer;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/students`,
      search: `customer=${id}`,
      state: { search: search },
    });
  };

  onCreateNotificationChannelsButtonClick = () => {
    this.setState({
      isNotificationChannelsFormModalOpen: true,
    });
  };

  onRemoveNotificationChannelsButtonClick = () => {
    this.setState({
      isConfirmationRemoveChannelModalOpen: true,
    });
  };

  onSendTestNotificationButtonClick = () => {
    this.setState({
      isSendNotificationFormModalOpen: true,
    });
  };

  renderSummary(): React.ReactNode {
    const { selectedItems, currentTabIndex, checkedChannelId } = this.state;
    const isOneItemSelected = selectedItems.length === 1;
    const isExistCheckedChannelId = typeof checkedChannelId !== 'undefined' && checkedChannelId !== '';

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a customer above</div>;
    }

    const tabs = this.getTabs();
    const currentTab = CUSTOMER_TABS[currentTabIndex];
    const isPermissionsTab = currentTab === TABS.PERMISSIONS;
    const isNotificationChannelsTab = currentTab === TABS.NOTIFICATION_CHANNELS;
    const isStudentsTab = currentTab === TABS.STUDENTS;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={CUSTOMER_TABS} currentTabIndex={currentTabIndex}>
        {isPermissionsTab && (
          <Button onClick={this.onEditPermissionsButtonClick} text={'Edit permissions'} customClass={'mr-3 mt-3'} />
        )}
        {isNotificationChannelsTab && (
          <Button
            onClick={this.onCreateNotificationChannelsButtonClick}
            text={'Create notification channel'}
            customClass={'mt-3 mr-3'}
          />
        )}
        {isNotificationChannelsTab && (
          <Button
            onClick={this.onRemoveNotificationChannelsButtonClick}
            disabled={!isExistCheckedChannelId}
            text={'Remove notification channel'}
            customClass={'mt-3 mr-3'}
          />
        )}
        {isNotificationChannelsTab && (
          <Button
            onClick={this.onSendTestNotificationButtonClick}
            disabled={!isExistCheckedChannelId}
            text={'Send a test notification'}
            customClass={'mt-3'}
          />
        )}
        {isStudentsTab && (
          <Button onClick={this.onEditStudentsButtonClick} text={'Edit students'} customClass={'mt-3'} />
        )}
        {tabs}
      </GenericSummary>
    );
  }

  onCreateOfferClick = () => {
    this.setState({
      isOfferFormModalOpen: true,
    });
  };

  onCancelCreateOfferButtonClick = () => {
    this.setState({
      isOfferFormModalOpen: false,
    });
  };

  onSubmitCreateOfferForm = data => {
    const { user, history } = this.props;
    const { productListPrice, ...rest } = data;

    const dataToTransfer = {
      ...rest,
      productOffers: data.productOffers.map(productOffer => ({
        ...productOffer,
        price: Number(productOffer.price),
      })),
    };

    this.setState({
      isLoading: true,
    });

    addOffer(user, dataToTransfer).then(offer => {
      this.setState({
        isOfferFormModalOpen: false,
      });

      history.push({
        pathname: '/offers',
      });
    });
  };

  onCreateVoucherClick = () => {
    this.setState({
      isVoucherFormModalOpen: true,
    });
  };

  onCancelCreateVoucherButtonClick = () => {
    this.setState({
      isVoucherFormModalOpen: false,
    });
  };

  onSendNotificationsButtonClick = () => {
    this.setState({
      isNotificationModalOpen: true,
    });
  };

  clearNotificationContent = () => {
    this.setState({
      messageSmsText: '',
      messageEmailSubject: '',
      messageEmailBody: '',
      messagePushTitle: '',
      messagePushBody: '',
      messageAttachments: [],

      isEmailEditorEnabled: true,
      isSmsEditorEnabled: true,
      isPushEditorEnabled: true,
    });
  };

  onCancelSendNotificationsButtonClick = () => {
    this.clearNotificationContent();
    this.setState({
      isLoadingFile: false,
      isNotificationModalOpen: false,
    });
  };

  onIsEditorChange = (type: string, value: boolean) => {
    switch (true) {
      case type === CUSTOM_NOTIFICATION_TYPE.EMAIL:
        if (value) {
          this.setState({
            isEmailEditorEnabled: value,
          });
        } else {
          this.setState({
            isEmailEditorEnabled: value,
            messageAttachments: [],
            messageEmailBody: '',
          });
        }
        break;
      case type === CUSTOM_NOTIFICATION_TYPE.SMS:
        this.setState({
          isSmsEditorEnabled: value,
        });
        break;
      case type === CUSTOM_NOTIFICATION_TYPE.PUSH:
        this.setState({
          isPushEditorEnabled: value,
        });
        break;
    }
  };

  // message content callbacks
  onEmailSubjectChange = event => {
    this.setState({
      messageEmailSubject: event.target.value,
    });
  };

  onEmailBodyChange = (body: any) => {
    this.setState({
      messageEmailBody: body,
    });
  };

  onSmsTextChange = event => {
    this.setState({
      messageSmsText: event.target.value,
    });
  };

  onPushTitleChange = event => {
    this.setState({
      messagePushTitle: event.target.value,
    });
  };

  onPushBodyChange = event => {
    this.setState({
      messagePushBody: event.target.value,
    });
  };

  onEmailAttachmentChange = (event): void => {
    const { user } = this.props;
    const attachments = [...this.state.messageAttachments];
    this.setState({
      isLoadingFile: true,
    });
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const fileName = event.target.files[0];

    uploadFile(user, formData)
      .then(data => {
        const url = data.url;
        const urlFormatted = url.replace(/ /g, '%20');
        attachments.push({
          fileName: fileName.name,
          url: urlFormatted,
        });
        this.setState({
          messageAttachments: attachments,
          isLoadingFile: false,
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isErrorModalOpen: true,
          errorMessage: errorText,
          isLoadingFile: false,
        });
        console.error('error');
        console.error(error);
      });
  };

  onRemoveAttachment = (url: string) => {
    const attachments = [...this.state.messageAttachments];
    const attachmentIndex = attachments.findIndex(attachment => attachment.url === url);

    attachments.splice(attachmentIndex, 1);
    this.setState({
      messageAttachments: attachments,
    });
  };

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.url} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  onSendNotificationModalClick = () => {
    const {
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      messageAttachments,
      selectedItems,
    } = this.state;

    const customerIds = selectedItems.map(customer => customer.id);

    let newMessageEmailBody;
    const emptyStrings = [...EMPTY_STRINGS_IN_EDITOR];
    const emptyEmailBody = emptyStrings.find(string => messageEmailBody === string);

    if (typeof emptyEmailBody !== 'undefined') {
      //  because   "<p><br></p>" === ''  in editor
      newMessageEmailBody = messageAttachments.length > 0 ? this.getAttachmentAsLinksString() : '';
    } else {
      newMessageEmailBody = messageEmailBody + this.getAttachmentAsLinksString();
    }

    const messageHTMLEmailBody =
      newMessageEmailBody !== ''
        ? `${HTML_FIRST_PART_PAGE_CODE} ${newMessageEmailBody} ${HTML_LAST_PART_PAGE_CODE}`
        : '';

    const data = {
      content: {
        sms: {
          text: messageSmsText,
        },
        email: {
          subject: messageEmailSubject,
          body: messageHTMLEmailBody,
        },
        push: {
          title: messagePushTitle,
          body: messagePushBody,
        },
      },
      affectedCustomerIds: customerIds,
    };

    if (!isSmsEditorEnabled) {
      delete data.content.sms;
    }
    if (!isEmailEditorEnabled) {
      delete data.content.email;
    }
    if (!isPushEditorEnabled) {
      delete data.content.push;
    }

    this.onSubmitNotificationClick(data);
  };

  onSubmitNotificationClick = data => {
    const { user } = this.props;

    let anyUserHasSMS = false;
    let anyUserHasEmail = false;
    let anyUserHasPush = false;

    const usersWithSMS = [];
    const usersWithEmail = [];
    const usersWithPush = [];

    this.state.selectedItems.forEach(item => {
      const customerChannelTypes = item.notificationChannels.map(channel => channel.type);
      if (customerChannelTypes.includes(CUSTOM_NOTIFICATION_TYPE.SMS)) {
        anyUserHasSMS = true;
        usersWithSMS.push(item);
      }
      if (customerChannelTypes.includes(CUSTOM_NOTIFICATION_TYPE.EMAIL)) {
        anyUserHasEmail = true;
        usersWithEmail.push(item);
      }
      if (
        customerChannelTypes.some(
          type => type === CUSTOM_NOTIFICATION_TYPE.ANDROID || type === CUSTOM_NOTIFICATION_TYPE.IOS
        )
      ) {
        anyUserHasPush = true;
        usersWithPush.push(item);
      }
    });

    const isSmsEditorEnabled = this.state.isSmsEditorEnabled && anyUserHasSMS;
    const isEmailEditorEnabled = this.state.isEmailEditorEnabled && anyUserHasEmail;
    const isPushEditorEnabled = this.state.isPushEditorEnabled && anyUserHasPush;

    if (!isSmsEditorEnabled && !isEmailEditorEnabled && !isPushEditorEnabled) {
      this.setState({
        isErrorModalOpen: true,
        errorMessage: 'No users meet the criteria for notification.',
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    const notificationsData = [];
    if (isSmsEditorEnabled) {
      notificationsData.push({ channelType: 'SMS', users: usersWithSMS });
    }
    if (isEmailEditorEnabled) {
      notificationsData.push({ channelType: 'Email', users: usersWithEmail });
    }
    if (isPushEditorEnabled) {
      notificationsData.push({ channelType: 'Push', users: usersWithPush });
    }

    const sendNotificationsPromises = notificationsData.map(({ channelType, users }) => {
      const affectedCustomerIds = users.map(user => user.id);
      const notificationData = {
        ...data,
        affectedCustomerIds,
      };
      return sendCustomNotification(user, notificationData);
    });

    Promise.all(sendNotificationsPromises)
      .then(() => {
        this.setState({
          isLoading: false,
          isNotificationModalOpen: false,
          isNotificationConfirmationModalOpen: true,
          notificationConfirmationMessage: 'Notifications have been successfully sent.',
        });
        this.clearNotificationContent();
        this.setItems();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
        console.error('error');
        console.error(errorText);
      });
  };

  onSubmitCreateVoucherForm = data => {
    const { user, history } = this.props;
    const { discount, scope, code, description, startDate, endDate, isOneOffVoucher } = data;
    const { discountType, minOrderAmount, discountAmount, product, priceType } = discount;
    const { scopeType, customers, purchaseSource } = scope;

    const dataToTransfer = {
      code: code,
      description: description,
      isOneOffVoucher: isOneOffVoucher,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      discount: {
        minOrderAmount: Number(minOrderAmount),
        discountType: discountType,
      },
      scope: {
        scopeType: scopeType,
        purchaseSource: purchaseSource,
        customers: customers,
      },
    };

    const isDiscountTypeFreePostage = discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE;
    const isDiscountTypeProductDiscount = discountType === VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT;

    if (!isDiscountTypeFreePostage) {
      propz.set(dataToTransfer, ['discount', 'discountAmount'], Number(discountAmount));
      propz.set(dataToTransfer, ['discount', 'priceType'], priceType);
    }

    if (isDiscountTypeProductDiscount) {
      propz.set(dataToTransfer, ['discount', 'product'], product);
    }

    this.setState({
      isLoading: true,
    });

    addVoucher(user, dataToTransfer).then(voucher => {
      this.setState({
        isVoucherFormModalOpen: false,
      });

      history.push({
        pathname: '/vouchers',
      });
    });
  };

  renderCreateVoucherFormModal(): React.ReactNode {
    const { isVoucherFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isVoucherFormModalOpen}>
        <CustomerVoucherForm
          customers={selectedItems}
          user={user}
          onCancel={this.onCancelCreateVoucherButtonClick}
          onSubmit={this.onSubmitCreateVoucherForm}
        />
      </SimpleModal>
    );
  }

  renderCreateOfferFormModal(): React.ReactNode {
    const { isOfferFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isOfferFormModalOpen}>
        <CustomerOfferForm
          customers={selectedItems}
          user={user}
          onCancel={this.onCancelCreateOfferButtonClick}
          onSubmit={this.onSubmitCreateOfferForm}
        />
      </SimpleModal>
    );
  }

  onRawChange = event => {
    const { value } = event.target;
    if (value === 'richText') {
      this.setState({
        isRawEditor: false,
        messageEmailBody: '',
      });
    } else {
      this.setState({
        isRawEditor: true,
        messageEmailBody: '',
      });
    }
  };

  renderCustomNotificationWizzard(): React.ReactNode {
    const { isNotificationModalOpen } = this.state;
    if (!isNotificationModalOpen) {
      return null;
    }

    const {
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      isPushEditorEnabled,
      isSmsEditorEnabled,
      isEmailEditorEnabled,
      messageAttachments,
      isErrorModalOpen,
      isNotificationConfirmationModalOpen,
      isLoadingFile,
      isRawEditor,
    } = this.state;
    return (
      <CustomNotificationWizzard
        onCancelClick={this.onCancelSendNotificationsButtonClick}
        isOpen={isNotificationModalOpen}
        onSendNotificationModalClick={this.onSendNotificationModalClick}
        onEmailSubjectChange={this.onEmailSubjectChange}
        onEmailBodyChange={this.onEmailBodyChange}
        onSmsTextChange={this.onSmsTextChange}
        onPushTitleChange={this.onPushTitleChange}
        onPushBodyChange={this.onPushBodyChange}
        onIsEditorChange={this.onIsEditorChange}
        onRemoveAttachment={this.onRemoveAttachment}
        onEmailAttachmentChange={this.onEmailAttachmentChange}
        messageSmsText={messageSmsText}
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messageAttachments={messageAttachments}
        messagePushTitle={messagePushTitle}
        messagePushBody={messagePushBody}
        isEmailEditorEnabled={isEmailEditorEnabled}
        isSmsEditorEnabled={isSmsEditorEnabled}
        isPushEditorEnabled={isPushEditorEnabled}
        isError={isErrorModalOpen}
        isLoadingFile={isLoadingFile}
        isRawEditor={isRawEditor}
        onRawEditorChange={this.onRawChange}
      />
    );
  }

  render() {
    const {
      isLoading,
      isConfirmationModalOpen,
      isCustomerFormModalOpen,
      isNotificationChannelsFormModalOpen,
      isVoucherFormModalOpen,
      isNotificationModalOpen,
      isErrorModalOpen,
      isNotificationConfirmationModalOpen,
      isOfferFormModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isConfirmationModalOpen ||
      isCustomerFormModalOpen ||
      isNotificationChannelsFormModalOpen ||
      isVoucherFormModalOpen ||
      isNotificationModalOpen ||
      isOfferFormModalOpen ||
      isErrorModalOpen ||
      isNotificationConfirmationModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderConfirmationRemoveCustomerModal()}
        {this.renderConfirmationRemoveChannelModal()}
        {this.renderFormModal()}
        {this.renderSendTestNotificationFormModal()}
        {this.renderNotificationChannelsFormModal()}
        {this.renderCreateVoucherFormModal()}
        {this.renderCreateOfferFormModal()}
        {this.renderCustomNotificationWizzard()}
        {this.renderSendingError()}
        {this.renderNotificationConfirmationModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
