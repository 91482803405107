import * as React from 'react';
import { Button } from '../../../../../components/Button/Button';
import { Address } from '../../../../../models/customers';

interface Props {
  setPrimaryDeliveryAddress: (addressId: string) => void;
  setPrimaryBillingAddress: (addressId: string) => void;
  onSelectDeliveryAddress: (index: number) => void;
  onSelectBillingAddress: (index: number) => void;
  addressBook: Address[];
}

export function CustomerAddressBook(props: Props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="eHomeAddressBookWrapper">
            <div className="eHomeAddressBookTitle">Address book</div>
            {props.addressBook.map((address, index) => (
              <div key={address.id} className="eHomeAddress">
                <div>Name: {address.firstName}</div>
                <div>Surname: {address.lastName}</div>
                <div>Email: {address.email}</div>
                <div>Phone: {address.phone}</div>
                <div>Street and number: {address.address1}</div>
                <div>City: {address.town}</div>
                <div>County: {address.region}</div>
                <div>Postcode: {address.postcode}</div>
                <div>Country: {address.country}</div>
                <div className="checkbox mb-3 eHomeAddressBookCheckbox">
                  <input
                    type="checkbox"
                    checked={address.isDeliveryPrimary}
                    onChange={() => props.setPrimaryDeliveryAddress(address.id)}
                  />
                  {' Make primary delivery address'}
                </div>
                <div className="checkbox mb-3 eHomeAddressBookCheckbox">
                  <input
                    type="checkbox"
                    checked={address.isBillingPrimary}
                    onChange={() => props.setPrimaryBillingAddress(address.id)}
                  />
                  {' Make primary billing address'}
                </div>
                <div className={'eHomeAddressBookButton'}>
                  <Button onClick={() => props.onSelectDeliveryAddress(index)} text={'Select as delivery address'} />
                </div>
                <div className={'eHomeAddressBookButton'}>
                  <Button onClick={() => props.onSelectBillingAddress(index)} text={'Select as billing address'} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
