import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Profile } from '../../../../../models/profile';
import * as Yup from 'yup';
import { checkAvailability } from '../../../../../services/public/register';
import {getPhoneAndCode, getPhoneWithCode, getPhoneWithoutFirstZero} from '../../../../../helpers/phone';
import { useState } from 'react';
import * as propz from 'propz';
import { Switch } from '../../../../../components/Switch/Switch';
import { NOTIFICATION_CHANNELS_TYPE } from '../../../../../consts/notificationChannels';
import { getSelectCountryOptions } from '../../../../../helpers/select';

interface Props {
  profile: Profile;
  onSubmit: (data) => void;
  onCancel: () => void;
}

export function CustomerMyDetailsForm(props: Props) {
  const { onSubmit, profile, onCancel } = props;

  const phoneObj = getPhoneAndCode(profile);

  const [phoneCode, setPhoneCode] = useState(phoneObj.code); // only needed for checkAvailability phone
  const emailChannels = profile.notificationChannels.filter(
    channel => channel.type === NOTIFICATION_CHANNELS_TYPE.EMAIL
  );
  const isExistEmailChannels = emailChannels.length > 0;
  const smsChannels = profile.notificationChannels.filter(channel => channel.type === NOTIFICATION_CHANNELS_TYPE.SMS);
  const isExistSmsChannels = smsChannels.length > 0;

  const myDetailsInitialValue = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.email,
    phoneCode: phoneCode,
    phone: propz.get(phoneObj, ['phone'], ''),
    promoEmail: profile.agreement.promoEmail,
    isExistEmailChannels: isExistEmailChannels,
    isExistSmsChannels: isExistSmsChannels,
  };

  const ProfileSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .required('Required')
      .email('Must be valid email')
      .test('email', 'Duplicate email', value => {
        const currentEmail = profile.email;
        if (currentEmail !== value) {
          return checkAvailability({ email: value }).then(res => {
            return res.isAvailable;
          });
        } else {
          return true;
        }
      }),
    phoneCode: Yup.string().required('Required'),
    phone: Yup.string()
      .required('Required')
      .test('phone', 'Duplicate phone or incorrect symbol', function(value) {
        const phone = getPhoneWithoutFirstZero(value);
        const phoneForCheck = `${phoneCode}${phone}`;
        const currentPhone = profile.phone;
        if (phoneForCheck !== currentPhone) {
          const phoneRegExp = /^\+\d+$|^\+\d+#\d+$|^\d+#\d+$|^\d+$/;
          const result = phoneRegExp.test(phone);
          return result
            ? checkAvailability({ phone: phoneForCheck }).then(res => {
                return res.isAvailable;
              })
            : false;
        } else {
          return false;
        }
      }),
    promoEmail: Yup.string().required('Required'),
  });

  return (
    <div className="bCustomerMyDetailsForm">
      <Formik
        initialValues={myDetailsInitialValue}
        validationSchema={ProfileSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form>
            <div className="eCustomerMyDetailsFormInputWrapper form-check">
              <div className="eCustomerMyDetailsFormInputWrapper">
                <label className="mPrimaryText eCustomerMyDetailsFormLabel">Name:</label>
                <Field name="firstName" className="eCustomerMyDetailsFormInput" />
                <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
              </div>

              <div className="eCustomerMyDetailsFormInputWrapper">
                <label className="mPrimaryText eCustomerMyDetailsFormLabel">Surname:</label>
                <Field name="lastName" disabled className="eCustomerMyDetailsFormInput" />
                <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
              </div>

              <div className="eCustomerMyDetailsFormInputWrapper">
                <label className="mPrimaryText eCustomerMyDetailsFormLabel">Email Address:</label>
                <Field name="email" className="eCustomerMyDetailsFormInput" />
                <ErrorMessage component="div" className="alert alert-danger" name="email" />
              </div>

              <div className="eCustomerMyDetailsFormInputWrapper">
                <label className="mPrimaryText eCustomerMyDetailsFormLabel">Phone code</label>
                <Field
                  name="phoneCode"
                  component="select"
                  className="eCustomerMyDetailsFormInput"
                  onChange={event => {
                    setPhoneCode(event.target.value);
                    setFieldValue('phoneCode', event.target.value);
                  }}
                >
                  {getSelectCountryOptions()}
                </Field>
                <div className="eCustomerMyDetailsFormInputWrapper">
                  <label className="mPrimaryText eCustomerMyDetailsFormLabel">Contact number:</label>
                  <Field name="phone" className="eCustomerMyDetailsFormInput" placeholder="Phone" />
                  <ErrorMessage component="div" className="alert alert-danger" name="phone" />
                </div>
              </div>

              <div className="eCustomerMyDetailsFormInputWrapper">
                <Field name="isExistEmailChannels">
                  {({ field }) => <Switch {...field} text={'Email notifications'} customClass="mb-3" />}
                </Field>
              </div>
              <div className="eCustomerMyDetailsFormInputWrapper">
                <Field name="isExistSmsChannels">
                  {({ field }) => <Switch {...field} text={'SMS notifications'} customClass="mb-3" />}
                </Field>
              </div>

              <div className="eCustomerMyDetailsFormInputWrapper">
                <label className="mPrimaryText eCustomerMyDetailsFormLabel">Marketing Emails</label>
                <Field
                  name="promoEmail"
                  component="select"
                  className="eCustomerMyDetailsFormInput"
                  onChange={event => {
                    setFieldValue('promoEmail', event.target.value === 'true');
                  }}
                >
                  <option value="true">{'I agree to receive'}</option>
                  <option value="false">{'I disagree to receive'}</option>
                </Field>
              </div>

              <button onClick={onCancel} className={'eCustomerMyDetailsFormButtonCancel'}>
                Cancel
              </button>

              <button type={'submit'} className={'eCustomerMyDetailsFormButton'}>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
