import { USER_ROLE_TYPE } from '../types/user';

export const USER_TYPE: { [prop: string]: USER_ROLE_TYPE } = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
  CUSTOMER: 'CUSTOMER',
};

export const USER_TYPE_SERVER_TO_CLIENT_MAPPING = {
  SUPERADMIN: 'Super admin',
  ADMIN: 'Admin',
  USER: 'User',
  ACCOUNT_MANAGER: 'Account manager',
  CUSTOMER: 'Customer',
};

export const ROLE = {
  MANAGER: 'MANAGER',
  PARENT: 'PARENT',
  STUDENT: 'STUDENT',
  TOURNAMENT_FOLLOWER: 'TOURNAMENT_FOLLOWER',
  TEACHER: 'TEACHER',
  COACH: 'COACH',
  ADMIN: 'ADMIN',
  GOVERNOR: 'GOVERNOR',
};

export const ROLE_SERVER_TO_CLIENT_MAPPING = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  PARENT: 'Parent',
  STUDENT: 'Student',
  TOURNAMENT_FOLLOWER: 'Tournament follower',
  TEACHER: 'Teacher',
  COACH: 'Coach',
  GOVERNOR: 'Trustee/Governor',
};
