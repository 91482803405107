import * as React from 'react';

export function EmptyMainComponent() {
  return (
    <div className={'mt-3'}>
      <h3>Welcome to Administration terminal.</h3>
      <p>Please choose an item from the menu on the left to start.</p>
    </div>
  );
}
