import * as React from 'react';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrdersSiteLink } from '../../../helpers/link';
import { IMAGES_COUNT_IN_SLIDER_FRAME } from '../../../consts/slider';
import './MainSlider.css';
import { FONT_COLOR } from '../../../consts/fontColor';

interface Props {
  images: any[];
  delay: number;
}

interface State {
  currentSlideIndex: number;
  timerId: any;
}

export class MainSlider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
      timerId: undefined,
    };
  }

  componentDidMount() {
    const timerId = setInterval(() => {
      this.toggleNextSlide();
    }, this.props.delay);

    this.setState({ timerId });
  }

  componentWillUnmount() {
    if (this.state.timerId) {
      clearInterval(this.state.timerId);
    }
  }

  getImages() {
    const { images } = this.props;
    return images.map((item, index) => {
      const isShowing = this.state.currentSlideIndex === index ? ' mShowing' : '';
      const isLinkExist = item.link !== '' && typeof item.link !== 'undefined';
      return isLinkExist ? (
        <a key={`main_slider_image_${index}`} href={`${getOrdersSiteLink()}${item.link}`} target="_blank">
          <img className={'eSliderImagesItem mTransitionImage' + isShowing} src={item.pic} alt="slide" />
        </a>
      ) : (
        <img className={'eSliderImagesItem mTransitionImage' + isShowing} src={item.pic} alt="slide" />
      );
    });
  }

  toggleNextSlide = () => {
    const previousSlideIndex = this.state.currentSlideIndex;
    const totalSlidesCount = this.props.images.length;
    const nextSlideIndex = (previousSlideIndex + 1) % totalSlidesCount;
    this.setState({ currentSlideIndex: nextSlideIndex });
  };

  togglePreviousSlide = () => {
    let currentSlideIndex = this.state.currentSlideIndex;
    if (currentSlideIndex === 0) {
      currentSlideIndex = this.props.images.length;
    }
    const totalSlidesCount = this.props.images.length;
    const previousSlideIndex = (currentSlideIndex - 1) % totalSlidesCount;

    this.setState({ currentSlideIndex: previousSlideIndex });
  };

  setSlideByDot = (slideIndex: number) => {
    this.setState({ currentSlideIndex: slideIndex });
  };

  getDots = () => {
    const { images } = this.props;
    return images.map((item, index) => {
      const isActive = this.state.currentSlideIndex === index ? ' mActive' : '';
      return (
        <button
          className={'eSliderDotsItem' + isActive}
          onClick={() => this.setSlideByDot(index)}
          key={`dot_${index}`}
        ></button>
      );
    });
  };

  render() {
    const { images } = this.props;
    const isSingleFrameSlider = images.length <= IMAGES_COUNT_IN_SLIDER_FRAME.MAIN_SLIDER;
    return (
      <div className="bSliderWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              {images.length === 0 ? null : (
                <div className="bSlider">
                  {!isSingleFrameSlider ? (
                    <>
                      <div className="eSliderContainer">
                        {this.getImages()}
                        <button className="eSliderArrowPrevious" onClick={this.togglePreviousSlide}>
                          <FontAwesomeIcon size={'5x'} icon="chevron-left" color={FONT_COLOR.MAIN_COLOR} />
                        </button>
                        <button className="eSliderArrowNext" onClick={this.toggleNextSlide}>
                          <FontAwesomeIcon size={'5x'} icon="chevron-right" color={FONT_COLOR.MAIN_COLOR} />
                        </button>
                      </div>
                      <div className="eSliderDots">{this.getDots()}</div>
                    </>
                  ) : (
                    <div className="eSliderContainer mMarginBottom20">{this.getImages()}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
