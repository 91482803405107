import axios from 'axios';
import { Order } from '../../models/orders';

export function updateOneTimeCodeOrderStatusToCanceled(
  username: string,
  password: string,
  basketId: string,
  orderId: string
) {
  const config = {};

  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/orders/${orderId}/cancel`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function updateOneTimeCodeOrderStatusToPaid(
  username: string,
  password: string,
  basketId: string,
  orderId: string,
  data
) {
  const config = {};

  return axios
    .post(`${window.apiBase}/public/${username}/${password}/basket/${basketId}/orders/${orderId}/paid`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getOneTimeCodeOrder(username: string, password: string, orderId: string) {
  const config = {};

  return axios.get(`${window.apiBase}/public/${username}/${password}/orders/${orderId}`, config).then(response => {
    return response.data;
  });
}

export function getOneTimeCodeOrderImages(username: string, password: string, orderId: string, limit: number = 50) {
  const filter = JSON.stringify({
    limit,
  });

  const config = {
    params: {
      filter,
    },
  };

  return axios
    .get(`${window.apiBase}/public/${username}/${password}/orders/${orderId}/images`, config)
    .then(response => response.data);
}

export function getOrderBySessionId(sessionId: string): Promise<Order> {
  return axios.get(`${window.apiBase}/public/orders/${sessionId}`).then(response => {
    return response.data;
  });
}
