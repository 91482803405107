import * as React from 'react';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  selectedItems: any[];
}

export const PdfIcon: FunctionComponent<Props> = props => {
  const buttonClassName =
    props.selectedItems.length > 0
      ? 'btn-outline-primary'
      : 'btn-outline-secondary';

  return (
    <div className={'d-inline-block ml-2'}>
      <button
        className={`btn btn-sm ${buttonClassName}`}
        onClick={event => {
          console.log('pdf');
        }}
      >
        <FontAwesomeIcon icon={['far', 'file-pdf']} className="mr-2" />
        <span className="mr-2">Download as pdf</span>
      </button>
    </div>
  );
};

PdfIcon.displayName = 'PdfIcon';
