import * as React from 'react';
import { FunctionComponent } from 'react';
import { SelectOption } from 'Src/helpers/table';
import './MultiselectField.css';

interface Props {
  items: SelectOption[];
  field: string;
  value: string;
  onChange: (event, value: string) => void;
  onEnterPress?: (event) => void;
}

const MAX_SIZE = 3;

export const MultiSelectField: FunctionComponent<Props> = props => {
  const { onEnterPress } = props;

  const options = props.items.map(item => {
    return (
      <option key={item.value} value={item.value}>
        {item.text}
      </option>
    );
  });

  const value = props.value;

  const itemsCount = props.items.length;
  const size = itemsCount < MAX_SIZE ? itemsCount : MAX_SIZE;

  return (
    <select
      multiple={true}
      value={value}
      className="form-control mOverflowYAuto mMaxWidth250 mMinWidth170"
      onChange={event => props.onChange(event, props.field)}
      onKeyDown={onEnterPress}
      size={size}
    >
      {options}
    </select>
  );
};

MultiSelectField.displayName = 'MultiSelectField';
