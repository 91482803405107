import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  schoolForms: any[];
}

export const SchoolFormsSummary: FunctionComponent<Props> = props => {
  const { schoolForms } = props;

  const rows = schoolForms.map((schoolForm, index) => {
    return (
      <tr key={`school_forms_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{schoolForm.name}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No forms</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th />
          <th>Name</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

SchoolFormsSummary.displayName = 'SchoolFormsSummary';
