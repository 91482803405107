import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import * as Promise from 'bluebird';
import { parse } from 'query-string';
import { ProductTypeFilter } from './ProductTypeFilter/ProductTypeFilter';
import { Loader } from '../../../components/Loader/Loader';
import {
  getAllProducts,
  getProductsByProductType,
  getAllProductsCount,
  getProductsByProductTypeCount,
} from '../../../services/public/images';
import { LIMIT_DOWNLOAD_IMAGES } from '../../../consts/images';
import { ProductType } from '../../../models/productTypes';
import './Products.scss';

interface Props {
  history: History;
  location: Location;
  productTypes: ProductType[];
}

interface State {
  selectedProductTypeId: string;
  //TODO: replace any type after making server workings
  products: any[];
  productsCount: number;
  skip: number;
  isLoading: boolean;
}

export class Products extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedProductTypeId: '',
      products: [],
      productsCount: 0,
      skip: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState(
        {
          skip: 0,
        },
        () => {
          this.setItems();
        }
      );
    }
  }

  setItems() {
    this.setState({ isLoading: true });

    const { history } = this.props;
    const search = parse(history.location.search) as any;
    const { productType: productTypeId } = search;

    const { skip } = this.state;

    const filter = {
      skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
    };

    const getProductsPromise =
      typeof productTypeId === 'undefined' ? getAllProducts(filter) : getProductsByProductType(productTypeId, filter);
    const getProductsCountPromise =
      typeof productTypeId === 'undefined' ? getAllProductsCount() : getProductsByProductTypeCount(productTypeId);
    const promises = [getProductsPromise, getProductsCountPromise];

    Promise.all(promises).then(([products, count]) => {
      const { count: productsCount } = count;

      this.setState({
        products: products,
        productsCount,
        selectedProductTypeId: productTypeId,
        skip: skip + LIMIT_DOWNLOAD_IMAGES,
        isLoading: false,
      });
    });
  }

  onProductTypeFilterClick = (productTypeId: string) => {
    const isProductTypeExist = typeof productTypeId !== 'undefined';

    if (isProductTypeExist) {
      this.props.history.push({
        pathname: 'products',
        search: `productType=${productTypeId}`,
      });
    } else {
      this.props.history.push('products');
    }
  };

  onLoadMoreClick = () => {
    const { selectedProductTypeId, skip } = this.state;

    const filter = {
      skip: skip,
      limit: LIMIT_DOWNLOAD_IMAGES,
    };

    const getProductsPromise =
      typeof selectedProductTypeId === 'undefined'
        ? getAllProducts(filter)
        : getProductsByProductType(selectedProductTypeId, filter);

    getProductsPromise.then(products => {
      this.setState(prevState => {
        return {
          products: [...prevState.products, ...products],
          skip: skip + LIMIT_DOWNLOAD_IMAGES,
        };
      });
    });
  };

  render() {
    const { products, selectedProductTypeId, productsCount, skip, isLoading } = this.state;
    const { productTypes } = this.props;

    const isLoadMoreExist = skip < productsCount;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="bProductsPageWrapper">
        <div className="container">
          <div className="row">
            <div className={'col-lg-3 col-xl-2'}>
              <ProductTypeFilter
                onClick={this.onProductTypeFilterClick}
                selectedProductTypeId={selectedProductTypeId}
                productTypes={productTypes}
              />
            </div>
            <div className={'col-lg-9 col-xl-10'}>
              <div className="row justify-content-center">
                {products.length === 0 ? (
                  <div className="eProductsPageNoProductsText">
                    No products have been added for the selected product type yet.
                  </div>
                ) : (
                  products.map(product => (
                    <div className={'col-lg-6 col-xl-6'}>
                      <div className="eProductsPageProduct" key={`productsPage_product_${product.Id}`}>
                        <div className="eProductsPageProductImageWrapper">
                          <span className="eProductImageHelper" />
                          <img
                            className="eProductsPageProductImage"
                            src={`${window.apiFile}/storage/images/${product.ticket}`}
                            alt="Image of product"
                          />
                        </div>
                        <div className="eProductsPageProductFooter">
                          <div className="eProductsPageProductTitle">{product.name}</div>
                          <div className="eProductsPageProductDescription">{product.description}</div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {isLoadMoreExist && (
                  <div className={'bLoadMoreButton'} onClick={this.onLoadMoreClick}>
                    LOAD MORE
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
