import * as React from 'react';
import { Component } from 'react';

interface Props {
  tabs: string[];
}

interface State {
  selectedTab: number;
}

export class CustomNotificationPreviewTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { selectedTab: 0 };
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      selectedTab: index,
    });
  };

  getTabs = () => {
    const { selectedTab } = this.state;

    const tabs = this.props.tabs.map((tab, index) => {
      const tabClassName = selectedTab === index ? 'nav-link active' : 'nav-link';

      return (
        <li key={`tab_${index}`} className="nav-item">
          <a className={tabClassName} href="" onClick={event => this.onTabClick(event, index)}>
            {tab}
          </a>
        </li>
      );
    });

    return <ul className="nav nav-tabs">{tabs}</ul>;
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <div>
        {this.getTabs()}
        <div>{this.props.children[selectedTab]}</div>
      </div>
    );
  }
}
