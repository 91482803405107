import * as React from 'react';
import { OneTimeCodeViewWaiting } from './OneTimeCodeViewWaiting';
import { OneTimeCodeView } from './OneTimeCodeView';
import { EmptyComponent } from '../../../components/EmptyComponent/EmptyComponent';
import './OneTimeCode.scss';
import { Component } from 'react';
import { History, Location } from 'history';
import { subscribe } from '../../../services/public/subscribe';
import { AppOneTimeCode } from '../AppOrders';

interface Props {
  isAuthorizedOneTimeCode: boolean;
  tickets: any[];
  selectedTicketIds: any[];
  history: History;
  location: Location;
  oneTimeCode: AppOneTimeCode;
  onGalleryImageClick: (ticket: any) => void;
  setBasketId: (basketId: string) => void;
  updateTickets: (tickets: any[]) => void;
  setTicketsCount: () => void;
}

export class OneTimeCode extends Component<Props> {
  componentDidMount() {
    const { setTicketsCount } = this.props;
    const { oneTimeCode } = this.props;
    const { ticketsCount } = oneTimeCode;

    if (ticketsCount === 0) {
      setTicketsCount();
    }
  }

  onFormSubmit = (values): void => {
    const { oneTimeCode } = this.props;
    const { username, password } = oneTimeCode;
    const data = {
      email: values.email.toLowerCase(),
      permissions: [
        {
          username: username,
          password: password,
        },
      ],
    };
    subscribe(data).then(res => {
      const { history } = this.props;
      history.push('/oneTimeCodeWaitingReady');
    });
  };

  render() {
    const {
      isAuthorizedOneTimeCode,
      tickets,
      selectedTicketIds,
      onGalleryImageClick,
      oneTimeCode,
      history,
      location,
      setBasketId,
    } = this.props;

    const { ticketsCount } = oneTimeCode;
    const isTicketsEmpty = ticketsCount === 0;

    switch (true) {
      case isAuthorizedOneTimeCode && isTicketsEmpty:
        return <OneTimeCodeViewWaiting history={history} location={location} />;
      case isAuthorizedOneTimeCode && !isTicketsEmpty:
        return (
          <OneTimeCodeView
            oneTimeCode={oneTimeCode}
            tickets={tickets}
            onGalleryImageClick={onGalleryImageClick}
            selectedTicketIds={selectedTicketIds}
            history={history}
            location={location}
            setBasketId={setBasketId}
          />
        );
      default:
        console.error('Not view');
        return <EmptyComponent />;
    }
  }
}
