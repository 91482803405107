import { AppUser } from '../../views/App/App';
import axios from 'axios';

export function deleteSchoolImages(user: AppUser, imageIds) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    data: { imageIds },
  };

  return axios.delete(`${window.apiBase}/admin/schoolImages`, config).then(response => {
    return response.data;
  });
}
