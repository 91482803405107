import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  tabs: string[];
  currentTabIndex: number;
  onTabClick: (event, index) => void;
  customClass?: string;
}

export const Tabs: FunctionComponent<Props> = props => {
  const { tabs: tabsPros, currentTabIndex, onTabClick, customClass } = props;
  const tabs = tabsPros.map((tab, index) => {
    const linkClassName = currentTabIndex === index ? 'nav-link active' : 'nav-link';
    const tabClassName = typeof customClass !== 'undefined' ? `${customClass} nav-item` : 'nav-item';

    return (
      <li key={`tab_${index}`} className={tabClassName}>
        <a
          className={linkClassName}
          href=""
          onClick={event => {
            onTabClick(event, index);
          }}
        >
          {tab}
        </a>
      </li>
    );
  });

  return <ul className="nav nav-tabs">{tabs}</ul>;
};

Tabs.displayName = 'Tabs';
