import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as classNames from 'classnames';
import { getFrom, getTo } from 'Src/helpers/table';
import { SimpleModal } from '../../SimpleModal/SimpleModal';
import { AppUser } from '../../../views/App/App';
import { ALL_LIMIT } from '../../../consts/table';
import './SelectAllCheckbox.css';

interface Props {
  user: AppUser;
  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  isChecked: boolean;
  totalCount: number;
  currentPage: number;
  selectedItems: any[];
  isSelectAllDisabled?: boolean;
}

interface State {
  isChecked: boolean;
  isMenuOpen: boolean;
  isConfirmationModalOpen?: boolean;
}

export class SelectAllCheckbox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: props.isChecked,
      isMenuOpen: false,
      isConfirmationModalOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isChecked !== this.props.isChecked) {
      this.setState({
        isChecked: nextProps.isChecked,
      });
    }
  }

  onCheckedChange = () => {
    const { isChecked } = this.state;

    if (isChecked) {
      this.props.onUnselectAllClick();
    } else {
      this.setState(
        {
          isMenuOpen: true,
          isChecked: true,
        },
        () => {
          document.addEventListener('click', this.closeMenu);
        }
      );
    }
  };

  closeMenu = () => {
    this.setState(
      {
        isMenuOpen: false,
        isChecked: false,
      },
      () => {
        document.removeEventListener('click', this.closeMenu);
      }
    );
  };

  onSelectAllOnPageClick = event => {
    event.preventDefault();
    this.props.onSelectAllOnPageClick();
    this.setState({ isMenuOpen: false });
    document.removeEventListener('click', this.closeMenu);
  };

  onSelectAllClick = event => {
    event.preventDefault();
    const { totalCount, user } = this.props;
    const soft = propz.get(user, ['config', 'gridLimits', 'soft']);

    if (totalCount > soft) {
      this.setState({
        isConfirmationModalOpen: true,
        isMenuOpen: false,
      });
    } else {
      this.setState({ isMenuOpen: false });
      this.selectAll();
    }
  };

  selectAll = () => {
    this.props.onSelectAllClick();
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
    document.removeEventListener('click', this.closeMenu);
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen } = this.state;
    const { totalCount, user } = this.props;
    const soft = propz.get(user, ['config', 'gridLimits', 'soft']);
    const hard = propz.get(user, ['config', 'gridLimits', 'hard']);

    if (totalCount > soft && totalCount < hard) {
      return (
        <SimpleModal
          isOpen={isConfirmationModalOpen}
          title={'Confirmation'}
          body={`You're about to select ${totalCount} items. It may take some time to perform. Would you like to continue?`}
          buttonText={'Continue'}
          buttonCancelText={'Cancel'}
          onButtonClick={this.selectAll}
          onCloseClick={this.hideConfirmationModal}
        />
      );
    }
    if (totalCount > hard) {
      return (
        <SimpleModal
          isOpen={isConfirmationModalOpen}
          title={'Confirmation'}
          body={`The number of items selected exceed the maximum limit. ${hard} items will be selected. Would you like to continue?`}
          buttonText={'Continue'}
          buttonCancelText={'Cancel'}
          onButtonClick={this.selectAll}
          onCloseClick={this.hideConfirmationModal}
        />
      );
    }
  }

  renderDropdownList() {
    const { isMenuOpen } = this.state;
    const { totalCount, currentPage, isSelectAllDisabled } = this.props;

    const pageCount = totalCount === 0 ? 0 : getTo(totalCount, currentPage) - getFrom(totalCount, currentPage) + 1;

    const listClassNames = classNames({
      'dropdown-menu': true,
      show: isMenuOpen,
      eSelectAllCheckbox: true,
    });

    return (
      <div className={listClassNames}>
        <a
          className={isSelectAllDisabled ? 'dropdown-item disabled' : 'dropdown-item'}
          onClick={this.onSelectAllOnPageClick}
        >
          {isSelectAllDisabled ? 'Select this page' : `Select this page (${pageCount})`}
        </a>
        <a className={isSelectAllDisabled ? 'dropdown-item disabled' : 'dropdown-item'} onClick={this.onSelectAllClick}>
          {isSelectAllDisabled ? 'Select all' : `Select all (${totalCount})`}
        </a>
      </div>
    );
  }

  render() {
    const timestamp = Number(new Date());
    const { selectedItems } = this.props;
    return (
      <>
        {this.renderConfirmationModal()}
        <div className="d-inline form-check mr-3">
          <input
            type="checkbox"
            checked={this.state.isChecked}
            onChange={this.onCheckedChange}
            id={`selectAllCheckbox_${timestamp}`}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor={`selectAllCheckbox_${timestamp}`}>
            {`Selected: ${selectedItems.length}`}
          </label>
          {this.renderDropdownList()}
        </div>
      </>
    );
  }
}
