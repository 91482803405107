import * as React from 'react';
import { Component } from 'react';
import { SidebarItemExpandable } from './Sidebar';
import { SidebarItem } from './SidebarItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SidebarItemsProps {
  item: SidebarItemExpandable;
}

interface SidebarItemsState {
  isOpen: boolean;
}

export class SidebarItems extends Component<
  SidebarItemsProps,
  SidebarItemsState
> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onToggle = event => {
    event.preventDefault();

    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { item } = this.props;
    const { title, items, isVisible } = item;

    if (!isVisible) {
      return null;
    }

    return (
      <>
        <div className="eSidebarItemExpandable" onClick={this.onToggle}>
          <FontAwesomeIcon
            icon={isOpen ? 'chevron-down' : 'chevron-right'}
            className="eSidebarItemExpandableIcon"
          />
          <div className="eSidebarItemExpandableTitle">{title}</div>
        </div>
        {isOpen && (
          <ul className={'nav flex-column'}>
            {items.map((item, index) => (
              <li key={`sidebar_expandable_item_${index}`} className="nav-item">
                <SidebarItem item={item} />
              </li>
            ))}
          </ul>
        )}
      </>
    );
  }
}
