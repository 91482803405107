import * as React from 'react';
import './PhotoSlider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FONT_COLOR } from '../../../../consts/fontColor';
import { ImagePhotoSlider } from './ImagePhotoSlider/ImagePhotoSlider';

interface Props {
  images: any[];
  onImageClick: (selectedTicket: any) => void;
  selectedImageId: any;
  showConfirmationModal?: (index) => void;
  isDeleteIconVisible: boolean;
}

export function PhotoSlider(props: Props) {
  const { images, onImageClick, selectedImageId, showConfirmationModal, isDeleteIconVisible } = props;

  return (
    <div className={'bPhotoSlider'}>
      <div className={'ePhotoSliderArrow'}>
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon="caret-up" color={FONT_COLOR.MAIN_COLOR} />
      </div>
      {images.map((image, index) => (
        <ImagePhotoSlider
          image={image}
          imageIndex={index}
          selectedImageId={selectedImageId}
          onImageClick={onImageClick}
          key={image.imageId}
          showConfirmationModal={showConfirmationModal}
          isDeleteIconVisible={isDeleteIconVisible}
        />
      ))}
      <div className={'ePhotoSliderArrow'}>
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon="caret-down" color={FONT_COLOR.MAIN_COLOR} />
      </div>
    </div>
  );
}
