import * as React from 'react';
import { Component } from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { History, Location } from 'history';
import { getCustomerBasket, updateCustomerBasketItemQuantity } from '../../../../services/customer/basket';
import { AppCustomer } from '../../../AppOrders/AppOrders';
import { VoucherForm } from '../../CommonComponents/VoucherForm/VoucherForm';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { getCustomerVoucher } from '../../../../services/customer/vouchers';
import { CURRENCY_SYMBOL } from '../../../../consts/common';
import { checkCustomerSession } from '../../../../services/customer/customer';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../consts/common';
import { Button } from 'Src/components/Button/Button';
import './CustomerBasket.scss';

interface Props {
  customer: AppCustomer;
  history: History;
  location: Location;
  setBasketCount: (count: number) => void;
  onLogoutClick: () => void;
}

interface State {
  galleryImages: any[];
  basketItems: any[];
  orderAmount: number;
  deliveryAmount: number;
  isVoucherModalOpen: boolean;
  voucherText: string;
  isVoucherEnterButtonClicked: boolean;
  voucherSaving: number;
  specialOffersSaving: number;
  totalWithoutSpecialOffers: number;
  isBasketContainsHistoricImages: boolean;
  isHistoricImagesInfoModalOpen: boolean;
}

export class CustomerBasket extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      galleryImages: [],
      basketItems: [],
      orderAmount: 0.0,
      deliveryAmount: 0.0,
      isVoucherModalOpen: false,
      voucherText: '',
      isVoucherEnterButtonClicked: false,
      voucherSaving: 0.0,
      specialOffersSaving: 0.0,
      totalWithoutSpecialOffers: 0.0,
      isBasketContainsHistoricImages: false,
      isHistoricImagesInfoModalOpen: false,
    };
  }

  componentDidMount() {
    const { customer, onLogoutClick, setBasketCount } = this.props;
    const { sessionKey } = customer;
    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    getCustomerBasket(customer).then(basket => {
      const {
        items,
        orderAmount,
        deliveryAmount = 0,
        galleryImages,
        specialOffersSaving,
        totalWithoutSpecialOffers,
        voucherSaving,
      } = basket;

      let isBasketContainsHistoricImages = false;

      const basketItemsUpdated = items.map(item => {
        const isImageHistoric = this.isImageHistoric(item);

        if (isImageHistoric) {
          isBasketContainsHistoricImages = true;
        }

        return { ...item, isImageHistoric };
      });

      this.setState({
        galleryImages,
        basketItems: basketItemsUpdated,
        orderAmount: orderAmount.toFixed(2),
        deliveryAmount: deliveryAmount.toFixed(2),
        specialOffersSaving: specialOffersSaving.toFixed(2),
        totalWithoutSpecialOffers: totalWithoutSpecialOffers.toFixed(2),
        voucherSaving: voucherSaving.toFixed(2),
        isBasketContainsHistoricImages,
      });
      setBasketCount(basket.items.length);
    });
  }

  isImageHistoric = image => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currenYear = today.getFullYear();
    const prevYear = currenYear - 1;

    const isNowSecondTerm = currentMonth >= 0 && currentMonth <= 7;
    const isNowFirstTerm = !isNowSecondTerm;

    const currentAcademicYearStart = isNowFirstTerm ? new Date(currenYear, 8, 1) : new Date(prevYear, 8, 1);

    const imageDate = new Date(image.jobDate);

    const isImageHistoric = imageDate < currentAcademicYearStart;

    return isImageHistoric;
  };

  onPlusClick = (item: any, index: number) => {
    const { customer } = this.props;
    const { imageId, productId, quantity } = item;
    const nextQuantity = quantity + 1;
    updateCustomerBasketItemQuantity(customer, { imageId, productId, quantity: nextQuantity }).then(basket => {
      const { orderAmount, specialOffersSaving, totalWithoutSpecialOffers, voucherSaving, deliveryAmount } = basket;
      const basketItems = [...this.state.basketItems];
      basketItems[index].quantity = nextQuantity;
      this.setState({
        basketItems,
        orderAmount: orderAmount.toFixed(2),
        specialOffersSaving: specialOffersSaving.toFixed(2),
        totalWithoutSpecialOffers: totalWithoutSpecialOffers.toFixed(2),
        voucherSaving: voucherSaving.toFixed(2),
        deliveryAmount: deliveryAmount.toFixed(2),
      });
    });
  };

  onMinusClick = (item: any, index: number) => {
    const { customer, setBasketCount } = this.props;
    const { imageId, productId, quantity } = item;
    const nextQuantity = quantity - 1;
    if (nextQuantity >= 0) {
      updateCustomerBasketItemQuantity(customer, { imageId, productId, quantity: nextQuantity }).then(basket => {
        const { orderAmount, specialOffersSaving, totalWithoutSpecialOffers, voucherSaving, deliveryAmount } = basket;
        const basketItems = [...this.state.basketItems];
        basketItems[index].quantity = nextQuantity;
        const basketItemsFiltered = basketItems.filter(item => item.quantity !== 0);
        const isBasketContainsHistoricImages = basketItemsFiltered.some(item => item.isImageHistoric === true);

        this.setState({
          basketItems: basketItemsFiltered,
          orderAmount: orderAmount.toFixed(2),
          specialOffersSaving: specialOffersSaving.toFixed(2),
          totalWithoutSpecialOffers: totalWithoutSpecialOffers.toFixed(2),
          voucherSaving: voucherSaving.toFixed(2),
          deliveryAmount: deliveryAmount.toFixed(2),
          isBasketContainsHistoricImages,
        });
        setBasketCount(basketItemsFiltered.length);
      });
    }
  };

  onVoucherClick = () => {
    this.setState({
      isVoucherModalOpen: true,
    });
  };

  onGalleryButtonClick = () => {
    this.props.history.push('/customer/photoGallery');
  };

  onCheckoutButtonClick = () => {
    const { isBasketContainsHistoricImages } = this.state;

    if (isBasketContainsHistoricImages) {
      this.setState({
        isHistoricImagesInfoModalOpen: true,
      });
    } else {
      this.checkout();
    }
  };

  getYear = (date: Date) => {
    return Moment(date).format('YYYY');
  };

  renderVoucherModal = () => {
    const { isVoucherModalOpen, voucherText, isVoucherEnterButtonClicked } = this.state;
    return (
      <SimpleModal isOpen={isVoucherModalOpen}>
        <VoucherForm
          onCancel={this.onVoucherCancelClick}
          onSubmit={this.onVoucherEnterClick}
          onOk={this.onVoucherOkClick}
          text={voucherText}
          isEnterButtonClicked={isVoucherEnterButtonClicked}
          {...this.props}
        />
      </SimpleModal>
    );
  };

  onVoucherEnterClick = (data: any) => {
    const { customer } = this.props;
    const { code } = data;

    getCustomerVoucher(customer, code).then(basket => {
      const { voucher, voucherSaving, orderAmount } = basket;
      const { text } = voucher;

      this.setState({
        voucherText: text,
        isVoucherEnterButtonClicked: true,
        voucherSaving: voucherSaving.toFixed(2),
        orderAmount: orderAmount.toFixed(2),
      });
    });
  };

  onVoucherCancelClick = () => {
    this.setState({
      isVoucherModalOpen: false,
    });
  };

  onVoucherOkClick = () => {
    this.setState({
      isVoucherModalOpen: false,
      isVoucherEnterButtonClicked: false,
      voucherText: '',
    });
  };

  renderHistoricImagesInfoModal = () => {
    const { isHistoricImagesInfoModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isHistoricImagesInfoModalOpen}>
        <p>
          Your order contains historic image(s) that were taken prior to this academic year. Please make sure you would
          like to proceed with the selected image(s).
        </p>
        <p>
          Press <span className={'mBasketBoldPrimaryText'}>CHECKOUT</span> to proceed with the order.
        </p>
        <p>
          Press <span className={'mBasketBoldPrimaryText'}>CANCEL</span> to get back to your basket and amend your
          products selection.
        </p>
        <div className={'d-flex justify-content-end'}>
          <Button onClick={this.closeHistoricImagesInfoModal} text={'CANCEL'} customClass={'mt-3 mr-3 btn-secondary'} />
          <Button onClick={this.checkout} text={'CHECKOUT'} customClass={'mt-3 btn btn-primary'} />
        </div>
      </SimpleModal>
    );
  };

  checkout = () => {
    const { basketItems } = this.state;

    const isSchoolDeliveryAvailable = basketItems.some(item => {
      return item.isSchoolDeliveryAvailable;
    });

    if (isSchoolDeliveryAvailable) {
      this.props.history.push('/customer/deliveryOptions');
    } else {
      this.props.history.push('/customer/homeDelivery');
    }
  };

  closeHistoricImagesInfoModal = () => {
    this.setState({
      isHistoricImagesInfoModalOpen: false,
    });
  };

  render() {
    const {
      basketItems,
      orderAmount,
      deliveryAmount,
      voucherSaving,
      specialOffersSaving,
      totalWithoutSpecialOffers,
    } = this.state;
    const total = (orderAmount - deliveryAmount).toFixed(2);
    const isBasketItemsExist = basketItems.length !== 0;
    const isSpecialOffersSavingExist = specialOffersSaving > 0;
    const isVoucherSavingExist = voucherSaving > 0;

    return (
      <div className="bBasketWrapper">
        {/*Place for render modal windows*/}
        {this.renderVoucherModal()}
        {this.renderHistoricImagesInfoModal()}
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-xl-12">
              <div className="bBasket">
                <div className="eBasketTitle">YOUR BASKET</div>
                {!isBasketItemsExist && <div className="eBasketText">Your basket is empty</div>}
                <div className="row justify-content-center">
                  <div className="eBasketItems">
                    {isBasketItemsExist &&
                      basketItems.map((item, index) => (
                        <div className="eBasketItem" key={item.id}>
                          <div
                            className={
                              item.isImageHistoric ? 'eBasketImageWrapper mPinkBackground' : 'eBasketImageWrapper'
                            }
                          >
                            <img
                              className="eBasketItemImage"
                              src={`${window.apiFile}/storage/images/${item.ticketId}`}
                            />
                          </div>
                          <div
                            className={item.isImageHistoric ? 'eBasketPhotoInfo mPinkBackground' : 'eBasketPhotoInfo'}
                          >
                            <div className="eBasketItemText">Class name: {item.classname}</div>
                            <div className="eBasketItemText">Photo year: {this.getYear(item.jobDate)}</div>
                            <div className="eBasketItemText">Product: {item.productName}</div>
                          </div>
                          <div
                            className={
                              item.isImageHistoric
                                ? 'eBasketPhotoQuantityPrice mPinkBackground'
                                : 'eBasketPhotoQuantityPrice'
                            }
                          >
                            <div className="eBasketItemText">Price</div>
                            <div className="eBasketItemText">{`${CURRENCY_SYMBOL.POUND}${(
                              item.productPriceSetPrice * item.quantity
                            ).toFixed(2)}`}</div>
                            <div className="eBasketItemText">Quantity</div>
                            <div className="eBasketItemQuantity">
                              <div className="eBasketItemQuantityChange" onClick={() => this.onMinusClick(item, index)}>
                                -
                              </div>
                              <div className="eBasketItemText">{item.quantity}</div>
                              <div className="eBasketItemQuantityChange" onClick={() => this.onPlusClick(item, index)}>
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="eBasketFooter">
                    <div className="eBasketFooterLeft">
                      {isBasketItemsExist && (
                        <div className="eBasketFooterText mCursorPointer" onClick={this.onVoucherClick}>
                          Voucher
                        </div>
                      )}
                      <button className="eBasketFooterButton" onClick={this.onGalleryButtonClick}>
                        Gallery
                      </button>
                    </div>
                    <div className="eBasketFooterRight">
                      {(isSpecialOffersSavingExist || isVoucherSavingExist) && (
                        <div className="eBasketFooterText mTextBold">{`Subtotal: ${CURRENCY_SYMBOL.POUND}${totalWithoutSpecialOffers}`}</div>
                      )}
                      {isSpecialOffersSavingExist && (
                        <div className="eBasketFooterText mTextBold">{`Special offer saving: ${CURRENCY_SYMBOL.POUND}${specialOffersSaving}`}</div>
                      )}
                      {isVoucherSavingExist && (
                        <div className="eBasketFooterText mTextBold">{`Voucher saving: ${CURRENCY_SYMBOL.POUND}${voucherSaving}`}</div>
                      )}
                      <div className="eBasketFooterText mTextBold mTextPrimary">{`Total: ${CURRENCY_SYMBOL.POUND}${total}`}</div>
                      <button
                        className="eBasketFooterButton"
                        onClick={this.onCheckoutButtonClick}
                        disabled={!isBasketItemsExist}
                      >
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
