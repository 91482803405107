export const NOTIFICATION_TABS = ['Summary'];

export const NOTIFICATION_DELIVERY_STATUS = {
  NEW: 'NEW',
  UNSUPPORTED_CHANNEL_TYPE: 'UNSUPPORTED_CHANNEL_TYPE',
  SENDING: 'SENDING', // sending in progress. There can be a lot of channels, so this action can take some time
  SENT: 'SENT', // everything sent
  DELIVERED: 'DELIVERED', // delivered by at least one channel
  READ: 'READ', // read by user at least on one channel
  ERROR: 'ERROR', // something bad happened - cannot send
  NOT_SENT: 'NOT_SENT', // notification was not sent with some reason
};

export const NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'New',
  UNSUPPORTED_CHANNEL_TYPE: 'Bad channel',
  SENDING: 'Sending',
  SENT: 'Sent',
  DELIVERED: 'Delivered',
  READ: 'Read',
  ERROR: 'Error',
  NOT_SENT: 'Not sent',
};

export const NOTIFICATION_DELIVERY_STATUS_FOR_SELECT = {
  SENT: 'SENT',
  ERROR: 'ERROR',
  OTHER: 'OTHER',
};

export const NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING_FOR_SELECT = {
  SENT: 'Sent',
  ERROR: 'Error',
  OTHER: 'Other',
};
