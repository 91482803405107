import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { JobImage } from 'Src/models/images';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  image: JobImage;
}

const JobImageSchema = Yup.object().shape({
  student: Yup.object().shape({
    class: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
  }),
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export function JobImageForm(props: Props) {
  const { image, onSubmit, onCancel } = props;

  const jobImageForm = {
    student: {
      class: image.student.class,
      firstName: image.student.firstName,
      lastName: image.student.lastName,
    },
    username: image.username,
    password: image.password,
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={jobImageForm} validationSchema={JobImageSchema} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Username</label>
                    <Field name="username" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="username" />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <Field name="password" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="password" />
                  </div>

                  <div className="form-group">
                    <label>Student class</label>
                    <Field name="student.class" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="student.class" />
                  </div>

                  <div className="form-group">
                    <label>Student first name</label>
                    <Field name="student.firstName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="student.firstName" />
                  </div>

                  <div className="form-group">
                    <label>Student last name</label>
                    <Field name="student.lastName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="student.lastName" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
