import * as React from 'react';
import { HTMLEditorQuill } from '../../../../../../../../components/HTMLEditorQuill/HTMLEditorQuill';
import { Loader } from '../../../../../../../../components/Loader/Loader';

interface Props {
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (body: any) => void;
  onEmailAttachmentChange: (event) => void;
  onRemoveAttachment: (event) => void;
  isError: boolean;
  isLoadingFile: boolean;

  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments: any[];
  isRawEditor: boolean;
  onRawEditorChange: (event) => void;
}

const FILE_NAME_MAX_LENGTH = 15;

function ImageWizardEditor(props: Props) {
  const getFileName = (fileName: string): string => {
    if (fileName.length <= FILE_NAME_MAX_LENGTH) {
      return fileName;
    }
    const fileNameExtension = fileName.substr(fileName.length - 5, 5);
    const fileNameCropped = fileName.substr(0, FILE_NAME_MAX_LENGTH);

    return `${fileNameCropped}...${fileNameExtension}`;
  };

  const renderAttachments = () => {
    const { isError, messageAttachments, onRemoveAttachment } = props;

    return messageAttachments.map((attachment, index) => {
      const fileName = attachment.fileName;
      const attachmentFileName = getFileName(fileName);

      return (
        <div key={`attachment_${index}`}>
          {attachmentFileName}
          <button
            type="button"
            className="close"
            disabled={isError}
            onClick={() => onRemoveAttachment(attachment.externalFileId)}
          >
            <span>&times;</span>
          </button>
        </div>
      );
    });
  };

  const {
    onEmailSubjectChange,
    onEmailBodyChange,
    onEmailAttachmentChange,
    messageEmailSubject,
    messageEmailBody,
    isError,
    isLoadingFile,
    onRawEditorChange,
    isRawEditor,
  } = props;

  return (
    <div className="bMessageTextForm">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="bMessageTextFormEmailEditor border border-primary rounded">
              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onEmailSubjectChange}
                  value={messageEmailSubject}
                />
              </div>

              <div className="form-group">
                <label>Editor</label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isRawEditor"
                    id="richText"
                    value="richText"
                    checked={!isRawEditor}
                    onChange={onRawEditorChange}
                  />
                  <label className="form-check-label" htmlFor="richText">
                    Rich text
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isRawEditor"
                    id="htmlCode"
                    value="htmlCode"
                    checked={isRawEditor}
                    onChange={onRawEditorChange}
                  />
                  <label className="form-check-label" htmlFor="htmlCode">
                    HTML code
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>Body</label>
                {isRawEditor ? (
                  <div>
                    <textarea
                      onChange={event => onEmailBodyChange(event.target.value)}
                      value={messageEmailBody}
                      className="form-control"
                      rows={8}
                    />
                  </div>
                ) : (
                  <HTMLEditorQuill onEmailBodyChange={onEmailBodyChange} value={messageEmailBody} isEnabled={true} />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="attachment">Attachments</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="attachment"
                  onChange={onEmailAttachmentChange}
                  disabled={isError}
                />
                {renderAttachments()}
                {isLoadingFile && <Loader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageWizardEditor;
