import { AppUser } from '../../views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from '../../consts/table';

export function getPriceSets(user: AppUser, packageId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios
    .get(`${window.apiBase}/admin/packages/${packageId}/priceSets`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllPriceSets(user: AppUser, packageId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios
    .get(`${window.apiBase}/admin/packages/${packageId}/priceSets`, config)
    .then(response => {
      return response.data;
    });
}

export function getPriceSetsCount(user: AppUser, packageId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios
    .get(
      `${window.apiBase}/admin/packages/${packageId}/priceSets/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function addPriceSet(user: AppUser, packageId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(
      `${window.apiBase}/admin/packages/${packageId}/priceSets`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function updatePriceSet(
  user: AppUser,
  packageId: string,
  priceSetId: string,
  data
) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(
      `${window.apiBase}/admin/packages/${packageId}/priceSets/${priceSetId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function deletePriceSet(
  user: AppUser,
  packageId: string,
  priceSetId: string
) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(
      `${window.apiBase}/admin/packages/${packageId}/priceSets/${priceSetId}`,
      config
    )
    .then(response => {
      return response.data;
    });
}
