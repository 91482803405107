import * as React from 'react';
import { FunctionComponent } from 'react';
import { getIsNoAutoAcceptAvailable } from '../../../../../../../helpers/accessor';

interface Props {
  schoolUsers: any[];
}

export const SchoolUserSummary: FunctionComponent<Props> = props => {
  const { schoolUsers } = props;

  const rows = schoolUsers.map((schoolUser, index) => {
    return (
      <tr key={`school_users_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{schoolUser.firstName}</td>
        <td>{schoolUser.lastName}</td>
        <td>{schoolUser.email}</td>
        <td>{schoolUser.phone}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'school_users_empty_row'}>
      <td>No users</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th />
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

SchoolUserSummary.displayName = 'SchoolUserSummary';
