import { AppUser } from '../../views/App/App';
import axios from 'axios';

export function sendAdminNotification(user: AppUser, adminId: string, channelId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(
      `${window.apiBase}/admin/admins/${adminId}/notifications/channels/${channelId}/sendNotification`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function sendAdminUploadFileNotification(user: AppUser, schoolId: string, fileName: string[]) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/schools/${schoolId}/uploadFile`, { fileName }, config).then(response => {
    return response.data;
  });
}
