export const WORLD_PAY_RESULT = {
  CANCELED: 'canceled',
  PAID: 'paid',
};

export const PAY360_RESULT = {
  CANCELED: 'CANCELED',
  PAID: 'PAID',
  NEW: 'NEW',
  TIMEOUT: 'TIMEOUT',
};

export const PAYMENT_CHANNEL = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
};

export const BUYER_TYPE = {
  CUSTOMER: 'CUSTOMER',
  ONE_TIME_CODE: 'ONE_TIME_CODE',
};
