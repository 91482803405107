import { AppUser } from '../../views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from '../../consts/table';

export function getStudents(user: AppUser, schoolId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/students`, config).then(response => {
    return response.data;
  });
}

export function getAllStudents(user: AppUser, schoolId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/students`, config).then(response => {
    return response.data;
  });
}

export function getSchoolStudentsCount(user: AppUser, schoolId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/schools/${schoolId}/students/count`, config).then(response => {
    return response.data;
  });
}

export function addSchoolStudents(user: AppUser, schoolId: string, formId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/admin/schools/${schoolId}/forms/${formId}/students`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolStudent(user: AppUser, schoolId: string, formId: string, studentId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/admin/schools/${schoolId}/forms/${formId}/students/${studentId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolStudent(user: AppUser, schoolId: string, formId: string, studentId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/schools/${schoolId}/forms/${formId}/students/${studentId}`, config)
    .then(response => {
      return response.data;
    });
}

export function approveStudent(user: AppUser, schoolId: string, formId: string, studentId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/admin/schools/${schoolId}/forms/${formId}/students/${studentId}/approve`, data, config)
    .then(response => {
      return response.data;
    });
}
