import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { ALL_LIMIT } from 'Src/consts/table';

export function getSchoolTypes(user: AppUser, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios
    .get(`${window.apiBase}/admin/schoolTypes`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolTypes(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios
    .get(`${window.apiBase}/admin/schoolTypes`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTypesCount(user: AppUser, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios
    .get(`${window.apiBase}/admin/schoolTypes/count`, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolType(user: AppUser, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/admin/schoolTypes`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolType(user: AppUser, schoolTypeId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/admin/schoolTypes/${schoolTypeId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolType(user: AppUser, schoolTypeId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/schoolTypes/${schoolTypeId}`, config)
    .then(response => {
      return response.data;
    });
}
