import axios from 'axios';
import { AppOneTimeCode } from '../../views/AppOrders/AppOrders';
import { ALL_LIMIT } from 'Src/consts/table';

export function getAllProductTypes() {
  const config = {
    params: {
      filter: {
        limit: ALL_LIMIT,
        skip: 0,
      },
    },
  };

  return axios.get(`${window.apiBase}/public/productTypes`, config).then(response => {
    return response.data;
  });
}

export function getAllProducts(filter?) {
  const config = {
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/public/products`, config).then(response => {
    return response.data;
  });
}

export function getProductsByProductType(productTypeId, filter?) {
  const config = {
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/public/productTypes/${productTypeId}/products`, config).then(response => {
    return response.data;
  });
}

export function getAllProductsCount() {
  return axios.get(`${window.apiBase}/public/products/count`, {}).then(response => {
    return response.data;
  });
}

export function getProductsByProductTypeCount(productTypeId) {
  return axios.get(`${window.apiBase}/public/productTypes/${productTypeId}/products/count`, {}).then(response => {
    return response.data;
  });
}

export function getImagePriceSetProductsPublic(imageId: string) {
  const config = {};

  return axios.get(`${window.apiBase}/public/images/${imageId}`, config).then(response => {
    return response.data;
  });
}

export function getImageProductsByProductType(imageId: string, productTypeId: string) {
  const config = {};

  return axios
    .get(`${window.apiBase}/public/images/${imageId}/productTypes/${productTypeId}/products`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllImageProducts(imageId: string) {
  const config = {};

  return axios.get(`${window.apiBase}/public/images/${imageId}/products `, config).then(response => {
    return response.data;
  });
}

export function getTicketsCount(oneTimeCode: AppOneTimeCode, filter?) {
  const { username, password } = oneTimeCode;

  const config = {
    params: {
      filter,
    },
  };

  return axios
    .post(`${window.apiBase}/public/images/newAvailable/count`, { username, password }, config)
    .then(response => {
      return response.data;
    });
}

export function getJobs(oneTimeCode: AppOneTimeCode) {
  const { username, password } = oneTimeCode;

  const config = {};

  return axios.post(`${window.apiBase}/public/images/jobs`, { username, password }, config).then(response => {
    return response.data;
  });
}
