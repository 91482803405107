export const CS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export const CSV_TYPE = {
  JOBS: '_importJobs',
  PRE_REGISTRATIONS: '_importPreRegs',
  ORDERS: '_exportOrders',
  ORDERS_PRINT: '_printOrders',
  ORDERS_IMPORT_STATUS: '_importOrdersStatus',
  STUDENTS_IMPORT: '_importStudents',
  S3_PRE_REGISTRATIONS: 'S3_importPreRegs',
  S3_JOBS: 'S3_importJobs',
  S3_SCHOOL_JOBS: 'S3_importSchoolJobs',
  S3_ORDERS: 'S3_exportOrders',
  S3_ORDERS_IMPORT_STATUS: 'S3_importOrdersStatus',
  S3_ORDERS_IMPORT_IMAGES: 'S3_importDownloadableImages',
};

export const CSV_SUBTYPE = {
  LAST: '_last',
  ALL: '_all',
};
