import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Package } from 'Src/models/packages';
import { Product } from '../../../../../../../../models/products';
import { CURRENCY_SYMBOL } from '../../../../../../../../consts/common';
import MaskedInput from 'react-text-mask';
import { getCurrencyMask } from '../../../../../../../../helpers/currencyMask';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  priceSet?: any;
  packageItem: Package;
  products: Product[];
}

const PackagePriceSetSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      pricing: Yup.object().shape({
        price: Yup.string()
          .required('Required')
          .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
      }),
    })
  ),
});

export function PackagePriceSetForm(props: Props) {
  const { priceSet, onSubmit, onCancel, packageItem, products: productsData } = props;

  const { products } = packageItem;

  const isPackagePriceSetExist = typeof priceSet !== 'undefined';

  let packagePriceSetForm;

  if (isPackagePriceSetExist) {
    const { products: priceSetProducts } = priceSet;

    packagePriceSetForm = {
      name: priceSet.name,
      description: priceSet.description,
      products: products.map(product => {
        const result = priceSetProducts.find(priceSetProduct => priceSetProduct.productId === product.productId);
        const productDataFound = productsData.find(productData => productData.id === product.productId);
        const productTypeName = propz.get(productDataFound, ['productType', 'name']);
        const productTypeId = propz.get(productDataFound, ['productType', 'typeId']);
        const productTypeOrder = propz.get(productDataFound, ['productType', 'order']);
        if (typeof result !== 'undefined') {
          const { pricing } = result;
          const { price, deliveryCharge } = pricing;
          return {
            productId: product.productId,
            name: product.name,
            productTypeName: productTypeName,
            productTypeId: productTypeId,
            productTypeOrder: productTypeOrder,
            pricing: { price: price, deliveryCharge: deliveryCharge },
          };
        } else {
          const { pricing } = productDataFound;
          const { price, deliveryCharge } = pricing;
          return {
            productId: product.productId,
            name: product.name,
            productTypeName: productTypeName,
            productTypeId: productTypeId,
            productTypeOrder: productTypeOrder,
            pricing: { price: price, deliveryCharge: deliveryCharge },
          };
        }
      }),
    };
  } else {
    packagePriceSetForm = {
      name: '',
      description: '',
      products: products.map(product => {
        const productDataFound = productsData.find(productData => productData.id === product.productId);
        const productTypeName = propz.get(productDataFound, ['productType', 'name']);
        const productTypeId = propz.get(productDataFound, ['productType', 'typeId']);
        const productTypeOrder = propz.get(productDataFound, ['productType', 'order']);
        const { pricing } = productDataFound;
        const { price, deliveryCharge } = pricing;
        return {
          productId: product.productId,
          name: product.name,
          productTypeId: productTypeId,
          productTypeName: productTypeName,
          productTypeOrder: productTypeOrder,
          pricing: { price: price, deliveryCharge: deliveryCharge },
        };
      }),
    };
  }

  function getProductPrice(productId: string): number {
    const product = productsData.find(productData => productData.id === productId);
    const productPrice = propz.get(product, ['pricing', 'price'], 0);
    return productPrice;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={packagePriceSetForm} validationSchema={PackagePriceSetSchema} onSubmit={onSubmit}>
            {({ touched, errors, values, setFieldValue, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Name</label>
                    <Field name="name" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="name" />
                  </div>

                  <div className="form-group">
                    <label>Description</label>
                    <Field name="description" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="description" />
                  </div>

                  {products.map((product, index) => (
                    <div key={product.productId}>
                      <div className={''}>{product.name}</div>
                      <div key={`base_price_${product.productId}`} className="form-group">
                        <div className="row mb-2">
                          <label className="col-7 col-form-label text-secondary">
                            {`List price: ${CURRENCY_SYMBOL.POUND}${getProductPrice(
                              product.productId
                            )}, offered price: `}
                          </label>

                          <div className="input-group col-4">
                            <div className="input-group-prepend">
                              <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                            </div>
                            <Field name={`products.${index}.pricing.deliveryCharge`}>
                              {({ field }) => (
                                <MaskedInput
                                  type="text"
                                  className="form-control"
                                  mask={getCurrencyMask}
                                  onChange={event => {
                                    setFieldValue(`products.${index}.pricing.price`, event.target.value);
                                  }}
                                  value={values.products[index].pricing.price}
                                />
                              )}
                            </Field>
                          </div>
                        </div>

                        <ErrorMessage
                          component="div"
                          className="alert alert-danger"
                          name={`products.${index}.pricing.price`}
                        />
                      </div>
                    </div>
                  ))}

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
