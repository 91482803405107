import * as React from 'react';
import { FunctionComponent } from 'react';
import './SelectField.css';
import { SelectOption } from 'Src/helpers/table';

interface Props {
  items: SelectOption[];
  field: string;
  value: string;
  onChange: (event, value: string) => void;
  onEnterPress?: (event) => void;
  isEmptyOptionRequired?: boolean;
}

export const SelectField: FunctionComponent<Props> = props => {
  const { onEnterPress } = props;

  const options = props.items.map(item => {
    return (
      <option key={item.value} value={item.value}>
        {item.text}
      </option>
    );
  });

  const emptyOption = <option value={''} />;

  return (
    <select
      value={props.value}
      className="form-control mMaxWidth250"
      onChange={event => props.onChange(event, props.field)}
      onKeyDown={onEnterPress}
    >
      {props.isEmptyOptionRequired && emptyOption}
      {options}
    </select>
  );
};

SelectField.displayName = 'SelectField';
