import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Sliders } from './Sliders';
import { SliderImages } from './SliderImages/SliderImages';

export const SlidersRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/sliders'} render={props => <Sliders {...props} user={allProps.user} />} />

      <Route exact path={'/sliders/images'} render={props => <SliderImages {...props} user={allProps.user} />} />
    </Switch>
  );
};

SlidersRouting.displayName = 'SlidersRouting';
