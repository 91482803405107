import * as React from 'react';
import { Customer } from '../../../../../../../models/customers';
import * as propz from 'propz';

interface Props {
  customer: Customer;
}

export function CustomerPermissionsSummary(props: Props) {
  const { customer } = props;
  const { permissions } = customer;

  const rows = permissions.map((permission, index) => {
    const username = propz.get(permission, ['username'], '');
    const password = propz.get(permission, ['password'], '');

    return (
      <tr key={`customer_permissions_summary_${index}`}>
        <td>{username}</td>
        <td>{password}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mt-3'}>
      <thead>
        <tr>
          <th>Username</th>
          <th>Password</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
