import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from '../../../../../components/Button/Button';
import { Switch } from '../../../../../components/Switch/Switch';
import * as Yup from 'yup';
import { YUP_MUST_BE_MORE_DETAILS, YUP_REQUIRED } from '../../../../../consts/yup';

interface Props {
  onSubmitConfirmDetailsFormClick: (data) => void;
  onCancel: () => void;
}

export function CustomerConfirmDetailsForm(props: Props) {
  const { onSubmitConfirmDetailsFormClick, onCancel } = props;

  const ConfirmDetailsSchema = Yup.object().shape({
    relation: Yup.string()
      .test('relation', YUP_REQUIRED, function(value) {
        const isParentOrGuardian = this.from[0].value.isParentOrGuardian;
        const isValueExist = typeof value !== 'undefined' && value !== '';

        return isValueExist || isParentOrGuardian;
      })
      .test('relation', YUP_MUST_BE_MORE_DETAILS, function(value) {
        const isParentOrGuardian = this.from[0].value.isParentOrGuardian;
        const isValueContainsYes = /^ *yes *$/i.test(value);

        return !isValueContainsYes || (isValueContainsYes && isParentOrGuardian);
      }),
  });

  const confirmDetailsForm = {
    isConfirmLegallyEntitled: false,
    isLegalCarer: false,
    isParentOrGuardian: false,
    relation: '',
    requestComment: '',
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={confirmDetailsForm}
            onSubmit={onSubmitConfirmDetailsFormClick}
            validationSchema={ConfirmDetailsSchema}
          >
            {({ handleSubmit, values }) => (
              <Form>
                <div className="form-group form-check">
                  <label>The system has detected some of the details you have entered do not match.</label>
                  <Field name="isConfirmLegallyEntitled">
                    {({ field }) => (
                      <Switch
                        {...field}
                        text={'Please confirm you are legally entitled to view the images'}
                        customClass="mb-3"
                      />
                    )}
                  </Field>
                  <label>Relationship to student:</label>
                  <Field name="isParentOrGuardian">
                    {({ field }) => <Switch {...field} text={'Parent / Guardian'} customClass="mb-3" />}
                  </Field>
                  <div className="form-group">
                    <label>Relation - Please state</label>
                    <Field name="relation" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="relation" />
                  </div>
                  <Field name="isLegalCarer">
                    {({ field }) => <Switch {...field} text={'Legal carer'} customClass="mb-3" />}
                  </Field>
                  <div className="form-group">
                    <label>Other - Please state</label>
                    <Field name="requestComment" className="form-control mb-3" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    disabled={!values.isConfirmLegallyEntitled}
                    text={'Continue'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
