import * as React from 'react';
import { FunctionComponent } from 'react';
import './CustomNotificationPreviewEmail.css';

interface Props {
  messageEmailSubject: string;
  messageEmailBody: string;
  showTitle?: boolean;
  messageAttachments: any[];
}

export const CustomNotificationPreviewEmail: FunctionComponent<Props> = props => {
  const renderBody = (): React.ReactNode => {
    if (
      typeof props.messageEmailBody === 'undefined' ||
      typeof props.messageEmailBody === 'object' ||
      props.messageEmailBody.length === 0
    ) {
      return null;
    }
    return <div className={'bEmailPreview'} dangerouslySetInnerHTML={{ __html: props.messageEmailBody }} />;
  };

  const isAttachmentExist = props.messageAttachments.length > 0;
  const renderAttachments = (): React.ReactNode => {
    return props.messageAttachments.map((attachment, index) => {
      return (
        <div key={`attachment_${index}`}>
          <a href={attachment.url} target="_blank">
            {attachment.fileName}
          </a>
        </div>
      );
    });
  };

  return (
    <div className="card border-success h-100">
      {props.showTitle && <h5 className="card-header text-white eGeneralMessagePreviewEmailHeader">Email preview</h5>}
      <div className="card-body">
        <h5 className="card-title">{props.messageEmailSubject}</h5>
        {renderBody()}
        {isAttachmentExist && <div>Attachments: {renderAttachments()}</div>}
      </div>
    </div>
  );
};
