import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import './PublicOffers.scss';
import { AppOneTimeCode } from '../AppOrders';
import { getPublicOffers, addPublicOfferProduct } from '../../../services/public/offers';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { PRICE } from '../../../consts/offer';
import { getBasket } from '../../../services/public/basket';

interface Props {
  history: History;
  location: Location;
  oneTimeCode: AppOneTimeCode;
  setBasketCount: (count: any) => void;
}

interface State {
  products: any;
  isModalOpen: boolean;
}

export class PublicOffers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      products: [],
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { oneTimeCode, setBasketCount } = this.props;

    getBasket(oneTimeCode).then(basket => {
      const { items, selectedImageId, selectedProductId } = basket;
      setBasketCount(items.length);

      getPublicOffers(selectedImageId, selectedProductId).then(products => {
        this.setState({
          products,
        });
      });
    });
  }

  onCheckoutClick = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.history.push('/basket');
  };

  onContinueClick = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.history.push('/photoGallery');
  };

  renderModal() {
    const { isModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isModalOpen}
        body={`WOULD YOU LIKE TO PROCEED TO CHECKOUT OR CONTINUE SHOPPING?`}
        buttonText={'CHECKOUT'}
        buttonCancelText={'CONTINUE SHOPPING'}
        onButtonClick={this.onCheckoutClick}
        onCloseClick={this.onContinueClick}
        customClass={`bPublicOfferModal`}
      />
    );
  }

  onAddButtonClick = (product: any) => {
    const { oneTimeCode, setBasketCount } = this.props;
    const { imageId, offerProductId, offerId } = product;

    addPublicOfferProduct(oneTimeCode, {
      imageId,
      productId: offerProductId,
      offerId,
    }).then(basket => {
      setBasketCount(basket.items.length);
      this.setState({
        isModalOpen: true,
      });
    });
  };

  onNextButtonClick = () => {
    this.props.history.push('/basket');
  };

  getDiscount = (product: any): any => {
    const { offerPriceType } = product;
    if (offerPriceType === PRICE.AMOUNT) {
      return (product.price - product.offerPrice).toFixed(2);
    }
    return ((product.price * product.offerPrice) / 100).toFixed(2);
  };

  getPriceWithDiscount = (product: any) => {
    const { offerPriceType } = product;
    if (offerPriceType === PRICE.AMOUNT) {
      return product.offerPrice.toFixed(2);
    }
    return (product.price - this.getDiscount(product)).toFixed(2);
  };

  render() {
    const { products } = this.state;
    return (
      <div className="bOfferWrapper">
        {this.renderModal()}
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="eOfferTitle">SPECIAL OFFERS</div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row">
                {products.length === 0
                  ? null
                  : products.map(product => (
                      <div className="col-xl-6" key={product.offerProductId}>
                        <div className="bOfferProduct">
                          <div className="eOfferProductImageWrapper">
                            <img
                              className="eOfferProductImage"
                              src={`${window.apiFile}/storage/images/${product.ticket}`}
                              alt="Image of product"
                            />
                          </div>
                          <div className="eOfferProductFooter">
                            <div className="eOfferProductTitle">{`Add this ${product.name} for`}</div>
                            <div className="eOfferProductPrice">{`£${this.getPriceWithDiscount(product)}`}</div>
                            <div className="eOfferProductDiscount">{`*you save £${this.getDiscount(product)}`}</div>
                            <button
                              className="eOfferProductFooterButton"
                              onClick={() => this.onAddButtonClick(product)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="eOfferNextButtonWrapper">
                <button className="eOfferNextButton" onClick={this.onNextButtonClick}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
